export const emptySport = {
	id: '',
	defaultName: '',
	name: {
		srb: '',
		en: '',
		de: '',
	},

	icons: {
		web: '',
		terminal: '',
		mobile: '',
		list: '',
	},
	special: false,
	idParent: null,
};
