import React, { FC, useState, useEffect, SetStateAction, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../../../../hooks/useNotifications';
import './styles.css';

export interface Tag {
	id: string;
	name: string;
	color: string;
	canBeFirst?: boolean;
	type: 'math' | 'logical' | 'compare' | 'bool' | 'props' | 'val';
	disableList: string[];
}

interface Props {
	playerProps: string[];
	addedTags: Tag[];
	setAddedTags: Dispatch<SetStateAction<Tag[]>>;
	formula: string;
	withBoundary?: boolean;
}

export const FormulaBuilder: FC<Props> = ({ playerProps, addedTags, setAddedTags, formula, withBoundary = false }) => {
	const { t } = useTranslation();

	let options = [...values, ...math, ...compare, ...bool, ...logical];
	if (withBoundary) options.push(...boundary);

	const [tags, setTags] = useState<Tag[]>(options);

	const [lastAdded, setLastAdded] = useState<Tag | null>(null);

	useEffect(() => {
		const props: Tag[] = playerProps.map((prop) => ({
			id: `{${prop}}`,
			name: prop,
			color: 'blue',
			type: 'props',
			disableList: ['props', 'bool', 'val'],
			canBeFirst: true,
		}));

		const allTags = [...props, ...tags];
		const added = formula
			.split(' ')
			.filter((item) => !!item)
			.map((item) => {
				const tagItem = allTags.find((tag) => tag.id === item);
				return tagItem
					? tagItem
					: ({
							id: item,
							name: item,
							color: 'blue',
							type: 'props',
							canBeFirst: true,
							disableList: ['props', 'bool', 'val'],
					  } as Tag);
			});

		setAddedTags(added);
		setTags((prevTags) => [...props, ...prevTags]);
	}, [playerProps, formula]);

	useEffect(() => {
		if (addedTags.length) setLastAdded(addedTags[addedTags.length - 1]);
		else setLastAdded(null);
	}, [addedTags]);

	const addTag = (tag: Tag) => {
		setAddedTags((prevTags) => [...prevTags, tag]);
	};

	const undo = () => {
		if (!lastAdded) return;
		setAddedTags((prevAdded) => prevAdded.filter((_, i) => i !== prevAdded.length - 1));
	};

	return (
		<div className="formula_builder">
			<div className="tags_wrapper">
				{tags.map((tag, i) => (
					<Tag key={`${tag.id}:${i}`} tag={tag} addTag={addTag} lastAdded={lastAdded} />
				))}
			</div>
			{addedTags.length ? (
				<>
					<div className="status_symbol tooltip_right tag_undo" onClick={undo}>
						<i className="mdi mdi-undo-variant blueish_text"></i>
						<span className="tooltip_content blueish">{t('general.undo')}</span>
					</div>
					<div className="added_tags_wrapper">
						{addedTags.map((item, i) => (
							<div key={`${item.id}:${i}`} className={`tag ${item.color}`}>
								{item.name}
							</div>
						))}
					</div>
					<div className="added_tags_text_wrapper">{addedTags.map((tag) => tag.id).join(' ')}</div>
				</>
			) : null}
		</div>
	);
};

interface TagProps {
	tag: Tag;
	lastAdded: Tag | null;
	addTag: (tag: Tag) => void;
}

export const Tag: FC<TagProps> = ({ tag, lastAdded, addTag }) => {
	const { color, name, disableList, canBeFirst } = tag;

	let disabled = lastAdded ? disableList.includes(lastAdded?.type) : !canBeFirst;

	return (
		<div className={`tag ${color} ${disabled ? 'disabled' : ''}`} onClick={() => addTag(tag)}>
			{name}
		</div>
	);
};

const values: Tag[] = [
	{
		id: '{value}',
		name: 'value',
		type: 'val',
		color: 'dark_green',
		disableList: ['val', 'bool', 'logical', 'props'],
		canBeFirst: true,
	},
];
const math: Tag[] = [
	{
		id: '+',
		name: '+',
		type: 'math',
		color: 'green',
		disableList: ['math', 'logical', 'bool', 'compare'],
	},
	{
		id: '-',
		name: '-',
		type: 'math',
		color: 'green',
		disableList: ['math', 'logical', 'bool', 'compare'],
	},
];
const logical: Tag[] = [
	{
		id: '&&',
		name: 'AND',
		type: 'logical',
		color: 'dark_blue',
		disableList: ['math', 'logical', 'compare'],
	},
	{
		id: '||',
		name: 'OR',
		type: 'logical',
		color: 'dark_blue',
		disableList: ['math', 'logical', 'compare'],
	},
];

const compare: Tag[] = [
	{
		id: '<',
		name: '<',
		type: 'compare',
		color: 'orange',
		disableList: ['math', 'logical', 'bool', 'compare'],
	},
	{
		id: '>',
		name: '>',
		type: 'compare',
		color: 'orange',
		disableList: ['math', 'logical', 'bool', 'compare'],
	},
	{
		id: '==',
		name: '==',
		type: 'compare',
		color: 'orange',
		disableList: ['math', 'logical', 'bool', 'compare'],
	},
	{
		id: '<=',
		name: '<=',
		type: 'compare',
		color: 'orange',
		disableList: ['math', 'logical', 'bool', 'compare'],
	},
	{
		id: '>=',
		name: '>=',
		type: 'compare',
		color: 'orange',
		disableList: ['math', 'logical', 'bool', 'compare'],
	},
];

const bool: Tag[] = [
	{
		id: '==1',
		name: 'is',
		type: 'bool',
		color: 'red',
		disableList: ['math', 'logical', 'bool', 'compare', 'val'],
	},
	{
		id: '==0',
		name: 'is not',
		type: 'bool',
		color: 'red',
		disableList: ['math', 'logical', 'bool', 'compare', 'val'],
	},
];

const boundary: Tag[] = [
	{
		id: 'value>boundary',
		name: 'value > boundary',
		type: 'val',
		color: 'dark_green',
		disableList: ['val', 'bool', 'logical', 'props'],
		canBeFirst: true,
	},
	{
		id: 'value<boundary',
		name: 'value < boundary',
		type: 'val',
		color: 'red',
		disableList: ['val', 'bool', 'logical', 'props'],
		canBeFirst: true,
	},
	{
		id: 'value=boundary',
		name: 'value = boundary',
		type: 'val',
		color: 'red',
		disableList: ['val', 'bool', 'logical', 'props'],
		canBeFirst: true,
	},
	{
		id: 'value<=boundary',
		name: 'value <= boundary',
		type: 'val',
		color: 'red',
		disableList: ['val', 'bool', 'logical', 'props'],
		canBeFirst: true,
	},
	{
		id: 'value>=boundary',
		name: 'value >= boundary',
		type: 'val',
		color: 'red',
		disableList: ['val', 'bool', 'logical', 'props'],
		canBeFirst: true,
	},
];
