import { axiosInstance, Response } from './../config';

export const extensions = {
	getExtensions(planId: string): Response {
		return axiosInstance.get(`/administration/offerplans/extensions/${planId}`).then((res) => res.data);
	},
	createExtension(planId: string) {
		return axiosInstance.post(`/administration/offerplans/extensions/${planId}`).then((res) => {
			return res.data;
		});
	},
	removeExtension(extensionId: string) {
		return axiosInstance.delete(`/administration/offerplans/extensions/${extensionId}`).then((res) => {
			return res.data;
		});
	},
	sendExtension(extensionId: string) {
		return axiosInstance.post(`/administration/offerplans/extensions/send/${extensionId}`).then((res) => {
			return res.data;
		});
	}
};
