import React, { useState, useEffect, FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { useAbility } from '../../casl/AbilityContext';

interface Props {
	redirect?: string;
	path: string;
	component: FunctionComponent<any>;
	subject?: string;
	bypassCasl?: boolean;
}

export default ({ redirect = '/', subject = 'offer', bypassCasl = false, ...props }: Props) => {
	const { ability } = useAbility();
	const [can, setCan] = useState(true);

	useEffect(() => {
		if (subject && !bypassCasl) setCan(ability.can('read', subject));
	}, []);

	return can ? <Route {...props} /> : <Redirect to={redirect} />;
};
