import React, { FC, useState, useEffect, Fragment } from 'react';
import Modal from '../index';
import { Divider } from './Divider';
import { ParameterRow } from './ParameterRow';
import { AvailableOption, Company, SelectedOption } from './types';
import api from '../../../api';
import { useTranslation } from 'react-i18next';
import NotificationModal from '../../general/NotificationModal';
import Delete from '../../../utils/svg/Delete';

interface Props {
	close: () => void;
	allOptions: AvailableOption[];
	selectedOptions: SelectedOption[];
	setParameters: (list: SelectedOption[]) => void;
	parameterType: string;
}

export const ParametersModal: FC<Props> = ({
	close,
	allOptions,
	selectedOptions: initialSelected,
	setParameters,
	parameterType,
}) => {
	const { t } = useTranslation();
	const [companies, setCompanies] = useState<Company[]>([]);
	const [selectedOptions, selectOptions] = useState(initialSelected);

	const [companyNameForReset, setCompanyNameForReset] = useState('');
	const [companyIdForReset, setCompanyIdForReset] = useState<string | null>('');

	useEffect(() => {
		(async () => {
			const data = await api.geo.getCompanies();
			const mapped: Company[] = data.map(({ id, name }: { id: string; name: string }) => ({ id, name }));
			setCompanies(mapped);
		})();
	}, []);

	const select = (idCompany: string | null, id: number) => {
		const name = allOptions.find((option) => option.id === id)?.name || '';
		const exists = selectedOptions.find((option) => option.idCompany === idCompany);
		const newOption = { id, idCompany, name, idClient: null };
		if (exists) {
			selectOptions(selectedOptions.map((option) => (option.idCompany === idCompany ? newOption : option)));
		} else selectOptions([...selectedOptions, newOption]);
	};

	const reset = (idCompany: string | null, companyName: string) => {
		setCompanyIdForReset(idCompany);
		setCompanyNameForReset(companyName);
	};

	const resetConfirmed = (idCompany: string | null) => {
		selectOptions(selectedOptions.filter((option) => option.idCompany !== idCompany));
		setCompanyIdForReset(idCompany);
		setCompanyNameForReset('');
	};

	const translateTypeMap = {
		conditions: 'conditions.condition',
		limits: 'limits.limit',
		authorizations: 'authParams.authParam',
	};

	return (
		<Fragment>
			<Modal
				opened={true}
				size={'lg'}
				align={'start'}
				direction={'column'}
				zIndex={'over_2'}
				cancelAction={close}
				body={
					<div className="mid_col direction_column padding_left_50 padding_top_50 padding_right_50 padding_bottom_50">
						<ParameterRow
							companyName={t('general.global')}
							companyId={null}
							allOptions={allOptions}
							selected={selectedOptions.find(({ idCompany }) => idCompany === null)?.id || ''}
							select={select}
							reset={reset}
						/>
						<Divider />
						{companies.map((company) => (
							<ParameterRow
								key={company.id}
								companyId={company.id}
								companyName={company.name}
								allOptions={allOptions}
								selected={selectedOptions.find(({ idCompany }) => idCompany === company.id)?.id || ''}
								select={select}
								reset={reset}
							/>
						))}
					</div>
				}
				footer={
					<Fragment>
						<button className="btn md round outline toggle_modal_12" onClick={close}>
							{t('general.cancel')}
						</button>
						<button
							className={`btn md round green toggle_modal_12`}
							onClick={() => {
								setParameters(selectedOptions);
								close();
							}}
						>
							{t('general.save')}
						</button>
					</Fragment>
				}
			></Modal>
			{!!companyNameForReset && (
				<NotificationModal
					opened={true}
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={() => {
						setCompanyIdForReset('');
						setCompanyNameForReset('');
					}}
					confirmAction={() => {
						resetConfirmed(companyIdForReset);
					}}
					zIndex={'over_3'}
				>
					<div className="context_icon margin_top_40">
						<Delete />
					</div>
					<h2 className="modal_message capitalize text_center">{t('general.areSure')}</h2>
					<p className="text_center margin_top_10">
						{t('tournamentsAdministration.areYouSure', {
							entity: t(translateTypeMap[parameterType]),
							companyName: companyNameForReset,
						})}
					</p>
				</NotificationModal>
			)}
		</Fragment>
	);
};
