import { axiosInstance } from './../config';
import { id } from '../../types/general';
import { ItemIds } from './../../types/providers';

export const sports = {
	linkSports(providerKey: id, data: ItemIds[]) {
		return axiosInstance.post(`/mappings/map-multiple/${providerKey}/data/sports`, data).then((res) => {
			return res.status;
		});
	}
};
