import React, { FC } from 'react';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import { ParameterGroups } from '../../../parameters/geo/components';
import { GeoContent } from '../../../parameters/geo/components/GeoContent';
import { GeoProvider } from '../../../parameters/geo/context';
import { useOnlineUser } from '../../context/OnlineUserContext';
import { SubHeader } from '../SubHeader';
import { UserGeoHeader } from './UserGeoHeader';

export const Parameters: FC = () => {
	const { onlineUser } = useOnlineUser();
	const { uid } = onlineUser;

	return (
		<div className="main_content w_1800">
			<SubHeader />
			<GeoProvider user={uid}>
				<UserGeoHeader />
				<div className="parameters flex_direction_row">
					<ParameterGroups />
					<GeoContent />
				</div>
			</GeoProvider>
		</div>
	);
};
