import React, { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectedTournament } from '../../../types';
import { useFormContext, useWatch } from 'react-hook-form';
import { TournamentsSelector } from './TournamentsSelector';
import Button from '../../../../../../components/general/Button';
import { useAxios } from '../../../../../../hooks/useAxios';
import api from '../../../../../../api';
import { Sport } from '../../../../sports/types';
import { SportIcon } from '../../../../sports/components/SportIcon';

export const CombinedTournaments: FC = () => {
	const { t } = useTranslation();
	const name = 'blockCombinationTournaments';

	const [modalOpened, setModalOpen] = useState(false);

	const { getValues, setValue, control } = useFormContext();
	const fields: SelectedTournament[] = getValues(name);
	const sport = getValues('sport');

	const { data: sports } = useAxios<Sport[]>({
		apiFunction: async function getSports() {
			return await api.sports.getList('ALL');
		},
		initialData: [],
		dependencies: [],
	});

	const formTournaments = useWatch({
		control,
		name,
		defaultValue: fields,
	});

	const setTournaments = (tournaments: SelectedTournament[]) => {
		setValue(name, tournaments);
		closeModal();
	};

	const removeTournament = (id: string) => {
		setValue(
			name,
			fields.filter((field) => field.id !== id)
		);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	return (
		<>
			<div className="divider_horizontal small label_left ltr_direction margin_bottom_10">
				<div className="div_label">
					<span className="padding_right_10">{t('tournamentsAdministration.addTournaments')}</span>
					<Button type="button" className="blue" name={'+'} onClick={() => setModalOpen(true)} />
				</div>
				<div className="thin_line"></div>
			</div>

			{formTournaments.map(({ id, defaultName, category, sport }) => {
				const sportItem = sports.find((item) => item.id === sport.id)!;
				return (
					<div key={id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<div className="label margin_left_10" style={{ display: 'flex', alignItems: 'center' }}>
							{sportItem ? <SportIcon sport={sportItem} tooltip="right" /> : null}
							<span className="margin_left_20"> {`${defaultName} (${category.name})`}</span>
						</div>

						<span
							className="mdi mdi-close tooltip_left text_redish small"
							onClick={() => removeTournament(`${id}`)}
						>
							<span className="tooltip_content redish">{t('tournamentsAdministration.remove')}</span>
						</span>
					</div>
				);
			})}

			{modalOpened ? (
				<TournamentsSelector
					defaultSport={sport}
					setTournaments={setTournaments}
					selected={formTournaments}
					closeModal={closeModal}
					sports={sports}
				/>
			) : null}
		</>
	);
};
