import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MultiSelectDropdown from '../../../../../components/general/MultiSelectDropdown';
import { PlayerPropsScore } from '../../types';

interface Props {
	playerPropsScore: PlayerPropsScore[];
	selectedPropsScores: string[];
	selectPropsScores: (scores: string[]) => void;
}
export const SelectPlayerPropsScore: FC<Props> = ({ playerPropsScore, selectedPropsScores, selectPropsScores }) => {
	const { t } = useTranslation();
	const { getValues } = useFormContext();
	const id = getValues('id');

	return (
		<div className={`${id ? 'disabled' : ''}`}>
			<MultiSelectDropdown
				title={t('general.propsScore')}
				marginRight={'0'}
				options={playerPropsScore}
				selectedOptions={selectedPropsScores}
				selectOption={selectPropsScores}
				withSearch
			/>
		</div>
	);
};
