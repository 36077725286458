import { useState } from 'react';
import useForce from './useForce';

export default function useLoadingList() {
	const [ loading, setLoading ] = useState<string[]>([]);
	const { key, rerender } = useForce();

	const startLoading = (id: string) => {
		loading.push(id);
		setLoading(loading);
		rerender();
	};

	const stopLoading = (id: string) => {
		const index = loading.indexOf(id);
		loading.splice(index, 1);
		setLoading(loading);
		rerender();
	};

	return {
		loading,
		setLoading,
		startLoading,
		stopLoading,
		key
	};
}
