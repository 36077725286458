import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from '../../../../../components/Dates/DateRange';
import MultiSelectDropdown from '../../../../../components/general/MultiSelectDropdown';
import { derivedTicketStatuses, getTicketStatusAdditionalData } from '../../../../reports/tickets/helpers';
import { FilterOptions } from '../../../../internet/transactions/components/Filters';
import { Statuses } from '../../../../reports/tickets/hooks/types';
import { SportFilter } from '../../../../pairing/components';
import { Sport } from '../../../../administration/sports/types';
import { ModeSwitch } from './ModeSwitch';

interface Props {
	sports: Sport[];
	selectedSport: string;
	setSelectedSport: (sport: string) => void;
	selectedTicketStatuses: string[];
	setSelectedTicketStatuses: (statuses: string[]) => void;
	startDate: Date | null;
	setStartDate: (date: Date | null) => void;
	endDate: Date | null;
	setEndDate: (date: Date | null) => void;
	ticketTypes: Statuses;
	toggleType: (type: string) => void;
	loadTickets: () => void;
	accountType: Statuses;
	disabled?: boolean;
	unionStatusesOperator: boolean;
	toggleStatusesOperator: () => void;
}

export const Filters: FC<Props> = ({
	sports,
	selectedSport,
	setSelectedSport,
	selectedTicketStatuses,
	setSelectedTicketStatuses,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	ticketTypes,
	toggleType,
	loadTickets,
	accountType,
	disabled,
	unionStatusesOperator,
	toggleStatusesOperator
}) => {
	const { t } = useTranslation();

	const ticketStatusesOptions = [
		...derivedTicketStatuses.map((status) => {
			const { name, id } = getTicketStatusAdditionalData(status);
			return { id, name };
		}),
	];

	return (
		<div className="subheading z_9">
			<SportFilter
				margin="0"
				sports={sports}
				direction="left"
				selectedSport={selectedSport}
				selectSport={setSelectedSport}
				listClass="padding_top_5 padding_bottom_5"
			/>
			<div className="margin_left_0">
				<MultiSelectDropdown
					title={t('general.ticketStatuses')}
					options={ticketStatusesOptions}
					selectedOptions={selectedTicketStatuses}
					selectOption={setSelectedTicketStatuses}
					marginRight={'10'}
					withSearch
				>
					<ModeSwitch
						checked={unionStatusesOperator}
						toggle={toggleStatusesOperator}
						leftLabel={t('reports.tickets.intersection')}
						rightLabel={t('reports.tickets.union')}
					/>
				</MultiSelectDropdown>
			</div>

			<FilterOptions title={t('general.ticket')} options={ticketTypes} setFilter={toggleType} />
			<FilterOptions title={t('general.account')} options={accountType} setFilter={toggleType} />

			<DateRange
				endDate={endDate}
				startDate={startDate}
				setEndDate={setEndDate}
				setStartDate={setStartDate}
				className="margin_left_15"
			/>
			<button
				className={`btn md blue margin_left_25 ${disabled ? 'disabled' : ''}`}
				onClick={() => {
					loadTickets();
				}}
			>
				{t('general.filter')}
			</button>
		</div>
	);
};
