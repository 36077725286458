import React, { useState, useEffect, useRef, Dispatch, SetStateAction, FocusEvent, Fragment } from 'react';
import { LangForm, AdministrationModal } from '../../administration/components';
import { TournamentForm } from '../../administration/tournaments/components/TournamentForm';
import { temporaryTournament, temporarySpecialTournament } from '../../../models/tournamentsAdministration';
import { TournamentItem } from '../../administration/tournaments/types';
import { CategoryItem } from '../../administration/categories/types';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { useTranslation } from 'react-i18next';
import { Item } from '../types';
import Fuse from 'fuse.js';
import { select as focusInput } from '../../../utils';
import { useModels } from '../../../hooks/useModels';

interface Props {
	setTournaments: Dispatch<SetStateAction<TournamentItem[]>>;
	selectTournament: Dispatch<SetStateAction<string>>;
	tournaments: TournamentItem[];
	categories: CategoryItem[];
	providerItem: Item;
	select: (type: string) => void;
	specTornament?: boolean;
}

const fuseOptions = {
	includeScore: true,
	isCaseSensitive: false,
	threshold: 0.5,
	distance: 99999999,
	includeMatches: true,
	minMatchCharLength: 4,
	keys: ['defaultName'],
};

export default ({
	tournaments,
	setTournaments,
	selectTournament,
	providerItem,
	categories,
	select,
	specTornament = false,
}: Props) => {
	const { t } = useTranslation();
	const { mapRegular, mapExtended } = useModels();
	const [category, setCategory] = useState('');
	const { special_tournament } = providerItem;
	const { tournament } = providerItem;

	const mapped = specTornament ? special_tournament.mapped : tournament.mapped;
	const mappedTo = specTornament ? special_tournament.mappedTo : tournament.mappedTo;
	const name = specTornament ? mappedTo.name : tournament.name;

	const temp = specTornament ? { ...temporarySpecialTournament } : { ...temporaryTournament };

	const [temporary, setTemporary] = useState<TournamentItem>({ ...temp });
	const [editing, edit] = useState<TournamentItem | null>(null);
	const [searchQuery, setSearchQuery] = useState<string>('');

	const [fuzzyTournaments, setFuzzyTournaments] = useState<TournamentItem[] | null>(null);

	useEffect(() => {
		if (mapped) {
			const tournament = tournaments.find((tour) => tour.id === mappedTo.id);
			selectTournament(mappedTo.id);
			setSearchQuery(`${mappedTo.name} (${tournament?.category.defaultName})`);
			setCategory(tournament?.category.defaultName || '-');
		} else {
			setCategory(tournament?.category.name || '-');
		}
	}, []);

	const handleChange = (item: TournamentItem) => {
		selectTournament(`${item.id}`);
		setSearchQuery(`${item.defaultName} (${item.category.defaultName})`);
		specTornament ? select('competitor') : null;
	};
	const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
		e.target.setAttribute('autocomplete', 'off');
		selectTournament('');
		setSearchQuery('');
		setOpen(true);
		handleFuzzy();
	};

	const handleFuzzy = () => {
		const fuse = new Fuse(tournaments, fuseOptions);
		const { name } = specTornament ? providerItem.special_tournament : providerItem.tournament;
		const results = fuse.search(name).map((res) => res.item);
		setFuzzyTournaments(results);
		specTornament ? focusInput('local-tournament') : focusInput('special-tournament');
		setOpen(true);
	};

	const updateCreatedTournament = (tournament: TournamentItem) => {
		const newTournament = { ...tournament, defaultName: tournament.name.en };
		const newTournaments = tournaments.map((item) => (item.id === tournament.id ? newTournament : item));
		setTemporary(newTournament);
		setSearchQuery(`${tournament.name.en} (${tournament.category.defaultName})`);
		setTournaments(newTournaments);
		selectTournament(`${tournament.id}`);
	};

	const importTournament = () => {
		if (providerItem) {
			const importing = {
				...temporary,
				name: {
					srb: '',
					en: name,
					de: '',
				},
			};
			edit(importing);
		}
	};

	// Track state of dropdown
	const dropdownRef = useRef<HTMLDivElement>(null);

	const [open, setOpen] = useState(false);
	const dropdownClass = open ? 'src_results toggle' : `src_results`;
	useOnClickOutside(dropdownRef, () => setOpen(false));

	const warningClass = mapped ? '' : 'has_warning';

	const placeholder = specTornament ? 'tournamentsPairing.specialTournament' : 'tournamentsPairing.localTournaments';

	return (
		<Fragment>
			<div className="search_dropdown_wrapper medium margin_bottom_20">
				{/* Serach  */}
				<div className="search_dropdown col-5" ref={dropdownRef}>
					<input
						className="src medium full_w"
						placeholder={t(placeholder)}
						type="text"
						id={`${specTornament} ? "special-tournament" : "local-tournament"`}
						value={searchQuery}
						onFocus={handleFocus}
						onChange={(e) => {
							setFuzzyTournaments(null);
							setSearchQuery(e.target.value);
						}}
					/>

					<div className={dropdownClass}>
						{!fuzzyTournaments
							? tournaments
									.filter(
										(item) =>
											item.defaultName.toLowerCase().includes(searchQuery.toLowerCase()) && item.defaultName
									)
									.map((item) => (
										<div
											key={item.id}
											className="list_item"
											onClick={() => {
												handleChange(item);
												setOpen(false);
											}}
										>
											{`${item.defaultName.toUpperCase()} (${item.category.defaultName})`}
										</div>
									))
							: fuzzyTournaments.map((item) => (
									<div
										key={item.id}
										className="list_item"
										onClick={() => {
											handleChange(item);
											setOpen(false);
										}}
									>
										{`${item.defaultName.toUpperCase()} (${item.category.defaultName})`}
									</div>
							  ))}
					</div>
				</div>
				{/* actions */}
				<div className="actions col-2 justify_center">
					<i className="create mdi mdi-magnify tooltip_left" onClick={handleFuzzy}>
						<span className="tooltip_content blue">{t('general.fuzzySearch')}</span>
					</i>
					<i className="import mdi mdi-chevron-double-left tooltip_right" onClick={() => importTournament()}>
						<span className="tooltip_content blue">{t('tournamentsPairing.import')}</span>
					</i>
				</div>
				{/* Provider Category  */}
				<div className={`col-5 input_label medium direction_column  tooltip_left ${warningClass} `}>
					{!specTornament ? (
						<small>{t('tournamentsPairing.single')}</small>
					) : (
						<small>{t('tournamentsPairing.specialTournament')}</small>
					)}
					<strong className="">{`${name ?? ''}  ${category ? `(${category})` : ''}`}</strong>
					{!mapped && <span className="tooltip_content redish">{t('tournamentsPairing.notMapped')}</span>}
				</div>
			</div>
			{editing ? (
				<AdministrationModal
					editing={{
						...editing,
						name: mapRegular(editing.name),
						shortName: mapRegular(editing.shortName),
						description: mapExtended(editing.description),
					}}
					onSubmit={updateCreatedTournament}
					onClose={() => edit(null)}
					size={'xl'}
					sectionClass={'admin_tour'}
					zIndex={'over_2'}
				>
					<Fragment>
						<LangForm
							col="8"
							colPosition="left"
							colBorder="right"
							padding={[40, 20, 0, 30]}
							direction="column"
							sports={null}
							withDescription
						/>

						<TournamentForm categories={categories} />
					</Fragment>
				</AdministrationModal>
			) : null}
		</Fragment>
	);
};
