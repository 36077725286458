import React, { FC, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../utils/getDate';

interface Props {
	name: string;
	setName: Dispatch<SetStateAction<string>>;

	prematchMax: string;
	setPrematchMax: Dispatch<SetStateAction<string>>;
	prematchMaxSingle: string;
	setPrematchMaxSingle: Dispatch<SetStateAction<string>>;
	liveMax: string;
	setLiveMax: Dispatch<SetStateAction<string>>;
	liveMaxSingle: string;
	setLiveMaxSingle: Dispatch<SetStateAction<string>>;

	userCreated: string | undefined;
	created: string | undefined;
	userUpdated: string | undefined;
	updated: string | undefined;
	referentCurrency: string;
}

export const Informations: FC<Props> = ({
	name,
	setName,
	prematchMax,
	setPrematchMax,
	prematchMaxSingle,
	setPrematchMaxSingle,
	liveMax,
	setLiveMax,
	liveMaxSingle,
	setLiveMaxSingle,
	userCreated,
	created,
	userUpdated,
	updated,
	referentCurrency,
}) => {
	const { t } = useTranslation();

	const timeFormat = 'date.month.year hours:minutes';

	const handleInput = (event: React.ChangeEvent<HTMLInputElement>, action: Dispatch<SetStateAction<string>>) => {
		const value = event.target.value;
		if (+value < 0 || isNaN(+value)) return;
		// Prevent input field to 2 decimal places
		const [_, decimals] = value.split('.');
		if (decimals?.length > 2) return;
		action(value);
	};

	return (
		<div className="direction_row sticky_top_0_z10 white_bg ">
			<div className="col-3 direction_colum padding_20">
				<div className="row margin_bottom_20">
					<div className="column text-right">
						<label className="label" htmlFor="limit_name">
							{t('limits.name')}
						</label>
					</div>
					<div className="column">
						<input
							id="limit_name"
							className="normal right_input"
							type="text"
							onChange={(event) => setName(event.target.value)}
							value={name}
						/>
					</div>
				</div>
				<div className="row margin_bottom_20">
					<div className="column text-right">
						<label className="label" htmlFor="prematch_max">
							{t('limits.prematchMax')}
						</label>
					</div>
					<div className="row">
						<input
							id="prematch_max"
							className="normal right_input w_120"
							type="text"
							value={prematchMax ? prematchMax : ''}
							onChange={(event) => handleInput(event, setPrematchMax)}
						/>
						<span className="align_center font_11px margin_left_10 padding_right_10">{referentCurrency}</span>
					</div>
				</div>
				<div className="row margin_bottom_20">
					<div className="column text-right">
						<label className="label" htmlFor="prematch_max_single">
							{t('limits.prematchMaxSingle')}
						</label>
					</div>
					<div className="row">
						<input
							id="prematch_max_single"
							className="normal right_input w_120"
							type="text"
							value={prematchMaxSingle ? prematchMaxSingle : ''}
							onChange={(event) => handleInput(event, setPrematchMaxSingle)}
						/>
						<span className="align_center font_11px margin_left_10 padding_right_10">{referentCurrency}</span>
					</div>
				</div>
			</div>
			<div className="col-3 direction_colum padding_20">
				<div className="row margin_bottom_20">
					<div className="column text-right">
						<label className="label" htmlFor="live_max">
							{t('limits.liveMax')}
						</label>
					</div>
					<div className="row">
						<input
							id="live_max"
							className="normal right_input w_120"
							type="text"
							value={liveMax ? liveMax : ''}
							onChange={(event) => handleInput(event, setLiveMax)}
						/>
						<span className="align_center font_11px margin_left_10 padding_right_10">{referentCurrency}</span>
					</div>
				</div>
				<div className="row margin_bottom_20">
					<div className="column text-right">
						<label className="label" htmlFor="live_max_single">
							{t('limits.liveMaxSingle')}
						</label>
					</div>
					<div className="row">
						<input
							id="live_max_single"
							className={`normal right_input w_120`}
							type="text"
							value={liveMaxSingle ? liveMaxSingle : ''}
							onChange={(event) => handleInput(event, setLiveMaxSingle)}
						/>
						<span className="align_center font_11px margin_left_10 padding_right_10">{referentCurrency}</span>
					</div>
				</div>
			</div>

			<div className="col-3 direction_colum padding_left_20 padding_right_20 padding_top_20">
				<div className="row margin_bottom_20">
					<div className="column text-right">
						<label className="label" htmlFor="created">
							{t('limits.created')}
						</label>
					</div>
					<div className="column">
						<label id="created">{created ? formatDate(created.slice(0, -1), timeFormat) : '-'}</label>
					</div>
				</div>

				<div className="row margin_bottom_20">
					<div className="column text-right">
						<label className="label" htmlFor="created_by">
							{t('limits.createdBy')}
						</label>
					</div>
					<div className="column">
						<label id="created_by">{userCreated ? userCreated : '-'}</label>
					</div>
				</div>
			</div>
			<div className="col-3 direction_colum padding_left_20 padding_right_20 padding_top_20">
				<div className="row margin_bottom_20">
					<div className="column text-right">
						<label className="label" htmlFor="changed">
							{t('limits.changed')}
						</label>
					</div>
					<div className="column">
						<label id="changed">{updated ? formatDate(updated.slice(0, -1), timeFormat) : '-'}</label>
					</div>
				</div>

				<div className="row margin_bottom_20">
					<div className="column text-right">
						<label className="label" htmlFor="changed_by">
							{t('limits.changedBy')}
						</label>
					</div>
					<div className="column">
						<label id="changed_by">{userUpdated ? userUpdated : '-'}</label>
					</div>
				</div>
			</div>
		</div>
	);
};
