type Instructions = {
	[key: string]: any;
};

export function remap<Received, Wanted>(instructions: Instructions, inclusive?: boolean) {
	return (props: Received): Wanted => {
		const remapped = {} as Instructions;

		for (let key in props) {
			const data = props[key];
			let prop = instructions[key];

			if (!prop && inclusive) continue;
			else if (!prop) prop = key;
			remapped[prop] = data;
		}

		return remapped as Wanted;
	};
}
