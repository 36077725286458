import React, { useEffect } from 'react';

export default function useOnClickOutside(ref: React.RefObject<HTMLDivElement>, handler: () => void) {
	useEffect(
		() => {
			const listener = (event: any) => {
				// Do nothing if clicking ref's element or descendent elements
				if (!ref.current || ref.current.contains(event.target)) {
					return;
				}

				handler();
			};

			document.addEventListener('mousedown', listener);

			return () => {
				document.removeEventListener('mousedown', listener);
			};
		},
		[ ref, handler ]
	);
}
