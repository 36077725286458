import React, { useState, useRef } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface Props {
	languages: string[];
	selectedLang: string;
	selectLang: (lang: string) => void;
}

export default ({ languages, selectedLang, selectLang }: Props) => {
	// Track state of dropdown
	const [open, setOpen] = useState(false);
	const dropdownClass = open ? 'select_region drop_menu down toggle' : 'select_region drop_menu down ';

	// Create dropdownRef
	const dropdownRef = useRef<HTMLDivElement>(null);

	// Call useOnClickOutside custom hook
	useOnClickOutside(dropdownRef, () => setOpen(false));

	return (
		<div ref={dropdownRef} className={dropdownClass} onClick={() => setOpen(!open)}>
			<span className="tooltip_content" />

			<span className="drop_label">{selectedLang}</span>
			<div className="drop_list left_0 ">
				{languages.map((item, index) => (
					<div
						key={index}
						className="drop_list_item font_400"
						onClick={() => {
							selectLang(item);
							setOpen(false);
						}}
					>
						{item.toUpperCase()}
					</div>
				))}
			</div>
		</div>
	);
};
