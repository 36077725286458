import { useState } from 'react';
import { timeSort } from '../tournaments/hooks/utils';
import { Item } from '../types';

export default function <ItemType extends Item>(initial: ItemType[]) {
	const [list, setList] = useState<ItemType[]>(initial || []);

	/**
	 * Returns an item from the list
	 *
	 * @param { ItemType } id
	 * @returns { ItemType }
	 */
	const getItem = (id: string) => list.find((item) => item.id === id);

	/**
	 * Returns and removes item from the list
	 *
	 * @param { string } id Id of the item to be extracted
	 * @returns { ItemType }
	 */
	const extract = (id: string): ItemType | undefined => {
		// let targetItem: ItemType | undefined = undefined;
		const index = getIndex(list, id);
		const [targetItem] = list.splice(index, 1);
		return targetItem;
	};

	/**
	 * Adds new item to the list
	 *
	 * @param { ItemType } item
	 * @returns { Options } options object for chaining
	 */
	const add = (item: ItemType, sorted: boolean | string = false) => {
		list.push(item);
		if (sorted) timeSort(list, 'timeString');
	};

	const options = {
		list,
		get: getItem,
		set: setList,
		extract,
		add,
	};

	return options;
}

/**
 * Helper function for getting the index of the object
 * in the array
 *
 * @param list
 * @param id
 */
function getIndex(list: Item[], id: string): number {
	for (let i = 0; i < list.length; i++) {
		const item = list[i];
		if (item.id === id) return i;
	}

	return -1;
}
