import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../../api';
import { Switch } from '../../../../../../components/general/Switch';
import { formatDate } from '../../../../../../utils/getDate';
import Modal from '../../../../../../../src/components/Modals';
import DeleteIcon from '../../../../../../utils/svg/Delete';
import { Voucher } from '../../../types';
import { statusColor } from '../../../helpers';
import { useNotify } from '../../../../../../hooks/useNotifications';
import getErrorText from '../../../../../../utils/getErrorMsg';

interface Props {
	voucher: Voucher;
	seriesId: string;
}

export const TableRow: FC<Props> = ({ voucher, seriesId }) => {
	const { t } = useTranslation();
	const [showActive, setShowActive] = useState(
		voucher?.status === 'ACTIVE' || voucher?.status === 'CREATED' ? true : false
	);
	const { error } = useNotify();
	const [voucherStatus, setVoucherStatus] = useState(voucher?.status);
	const [isDisabled, setIsDisabled] = useState(false);

	const toggleIsVoucherActive = async () => {
		try {
			if (showActive) setModalOpened(true);
		} catch (err) {
			error(getErrorText(err) || 'toast.error.defaultText');
		}
	};
	const [modalOpened, setModalOpened] = useState(false);

	const cancelVoucher = async () => {
		try {
			setIsDisabled(true);
			const data = await api.vouchers.cancelVoucher(seriesId, voucher.id.toString());
			setShowActive((showActive) => !showActive);
			setVoucherStatus(data?.status);
			setModalOpened(false);
		} catch (err) {
			error(getErrorText(err) || 'toast.error.defaultText');
		} finally {
			setIsDisabled(false);
		}
	};
	const { id, dtActivated, idTransaction, number, pin, amount, sequence, dtCanceled, userCanceled, email } = voucher;

	return (
		<>
			<tr onClick={() => null}>
				<td>{number}</td>
				<td>{id}</td>
				<td className="text_right">{amount}</td>

				<td>{email}</td>
				<td>
					{dtActivated !== null ? formatDate(dtActivated || '', 'date.month.year | hours:minutes:seconds') : null}
				</td>
				{/* <td>{userActivated}</td> */}
				<td>{idTransaction}</td>
				<td>{sequence.substring(0, sequence.length - 5)}</td>
				<td>{pin}</td>
				<td>
					{dtCanceled !== null ? formatDate(dtCanceled || '', 'date.month.year | hours:minutes:seconds') : null}
				</td>
				<td>{userCanceled}</td>
				<td>
					<span className={`tag_item light tooltip_right ${statusColor[voucherStatus]}`}>{voucherStatus}</span>
				</td>
				<td className="actions_cell">
					{showActive ? (
						<div className="td_actions">
							<div className="tbl_btn red">
								<i className="mdi mdi-close md tooltip_left" onClick={() => toggleIsVoucherActive()}></i>
							</div>
						</div>
					) : null}
				</td>
			</tr>
			{/* Cancel voucher modal */}
			<Modal
				opened={modalOpened}
				size={'sm_320'}
				align={'start'}
				direction={'column'}
				cancelAction={() => setModalOpened(false)}
				body={
					<Fragment>
						<div className="context_icon margin_top_40">
							<DeleteIcon />
						</div>
						<h2 className="modal_message capitalize text_center">{t('general.areSure')}</h2>
						<p className="text_center margin_top_10">{t('vouchers.areYouSureCancelVoucher')}</p>
					</Fragment>
				}
				footer={
					<Fragment>
						<button className="btn md round outline" onClick={() => setModalOpened(false)}>
							{t('general.cancel')}
						</button>

						{!isDisabled ? (
							<>
								<button
									className={`${isDisabled ? 'disabled' : ''} btn md round red`}
									onClick={() => cancelVoucher()}
								>
									{t('general.yes')}
								</button>
							</>
						) : (
							<button disabled={true} className={`btn md round red`} onClick={() => {}}>
								<i className="mdi mdi-rotate-right mdi-spin" />
							</button>
						)}
					</Fragment>
				}
			/>
		</>
	);
};
export default TableRow;
