import React, { FC, useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import Dropdown from '../../../../../components/general/Dropdown';
import { Item } from '../../../../../types/general';
import { useOnlineUser } from '../../../context/OnlineUserContext';

interface Props {
	edit: boolean;
}

export const ChangeUserBaseLocation: FC<Props> = ({ edit }) => {
	const { t } = useTranslation();
	const { onlineUser } = useOnlineUser();
	const { company } = onlineUser;

	const { control, setValue, getValues } = useFormContext();
	const idGeolocation = getValues('idGeolocation');

	const geoId = useWatch({
		control,
		name: 'idGeolocation',
		defaultValue: idGeolocation,
	});

	const [locations, setLocations] = useState<Item[]>([]);
	const [selectedLocation, setSelectedLocation] = useState('');

	useEffect(() => {
		if (!company?.id) return;
		(async function getGroupsAndLocations() {
			try {
				const locationsData = (await api.geo.getEntitySubNodeByType(company.id, 'COMPANY', 'LOCATION')) || [];
				setLocations(locationsData.map(({ id, name }) => ({ id, name })));
				const location = locationsData.find((location) => location.id === geoId);
				setSelectedLocation(location!.id);
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);

	useEffect(() => {
		if (!locations.length) return;
		const location = locations.find((location) => location.id === geoId);
		setSelectedLocation(location!.id);
	}, [locations, geoId]);

	const handleChange = (id: string) => {
		setSelectedLocation(id);
		setValue('idGeolocation', id);
	};

	return (
		<div className={`form_row read_only}`}>
			<label className="input_label col-3 font_700">{t('usersAdministrations.baseLocation')}</label>
			<div className={`col-6 ${!edit ? 'disabled_V' : ''}`}>
				<Dropdown
					items={locations}
					selectedItem={selectedLocation}
					selectItem={handleChange}
					withSearch
					listClass={'left_0'}
					fixedTitle={!selectedLocation ? t('general.location') : ''}
					margins={['0', '0', '0', '0']}
					icon={edit ? 'mdi mdi-menu-down' : undefined}
					highlightLabel={!!selectedLocation}
					className="flex_justify_start"
				/>
			</div>
		</div>
	);
};
