// Event live statuses
export const LIVE = 'LIVE';
export const ANNOUNCED = 'ANNOUNCED';
export const DISABLED = 'DISABLED';
export const ENABLED = 'ENABLED';
// Market statuses
export const RESET = 'RESET';
export const CLOSED = 'CLOSED';
export const ACTIVE = 'ACTIVE';
export const REMOVED = 'REMOVED';
export const CANCELED = 'CANCELED';
export const INTERRUPTED = 'INTERRUPTED';
export const UNDEFINED = 'UNDEFINED';
export const TEMPORARY_REMOVED = 'TEMPORARY_REMOVED';

// Event provider statuses
export const NOT_RUNNING = 'NOT_RUNNING';
export const RUNNING = 'RUNNING';
export const STOPPED = 'STOPPED';
export const NOT_FINISHED = 'NOT_FINISHED';
export const FINISHED = 'FINISHED';

// Period Sports
export const TABLE_TENNIS = 'tt';
export const BASEBALL = 'bs';
export const SNOOKER = 'sn';
