import React, { FC } from 'react';
import { ModalSize } from '../../../Modals/types';
import { navigationItems, Tab } from '../helpers';

interface Props {
	tab: Tab;
	setTab: (id: Tab) => void;
	setModalSize: (size: ModalSize) => void;
	setDirection: (direction: string) => void;
}

export const Navigation: FC<Props> = ({ tab, setTab, setModalSize, setDirection }) => {
	const handleClick = (id: Tab, size: ModalSize | undefined, direction: string | undefined) => {
		setTab(id);
		setModalSize(size || 'md');
		setDirection(direction || 'column');
	};

	return (
		<>
			{navigationItems.map(({ id, text, modalSize, direction }) => (
				<div
					key={id}
					onClick={() => handleClick(id, modalSize, direction)}
					className={`tab_nav_item ${tab === id ? 'active' : ''}`}
				>
					{text}
				</div>
			))}
		</>
	);
};
