import React from 'react';

// Local props for the component
interface Props {
	expanded: boolean;
	toggle: () => void;
}

export default ({ expanded, toggle }: Props) => {
	const buttonClass = !expanded ? 'accordion_button toggle' : 'accordion_button';

	return (
		<div className="accordion_section z_9">
			<div className={buttonClass} onClick={toggle}>
				<i className="mdi mdi-chevron-up" />
				<i className="mdi mdi-chevron-down" />
			</div>
			<div className="line" />
		</div>
	);
};
