import React, { FC, ChangeEvent } from 'react';
import { useGeo } from '../../context';

interface Props {
	locked: string;
	rowItem: { id: string; value: any; inherited: any };
}

export const GeoCsv: FC<Props> = ({ locked, rowItem }) => {
	const { updateGeoParameters } = useGeo();
	const { id, value, inherited } = rowItem;
	const inputValue = locked ? inherited : value;

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		updateGeoParameters(id, e.currentTarget.value);
	};

	return (
		<div className="value_content">
			<input
				name={id}
				type="text"
				id={`csv-${id}`}
				onChange={handleChange}
				className="normal full_w"
				value={inputValue === null ? '' : inputValue}
			/>
		</div>
	);
};
