import { riskGroup, riskGroupUser, userType } from '../../pages/risk-management/risk-groups/types';
import { axiosInstance } from '../config';

export const risk_groups = {
	getAllRiskGroups(): Promise<riskGroup[]> {
		return axiosInstance.get(`/risk/risk_groups/`).then((res) => res.data);
	},
	getRiskGroups(idCompany: string): Promise<riskGroup[]> {
		return axiosInstance.get(`/risk/risk_groups/?idCompany=${idCompany}`).then((res) => res.data);
	},

	createRiskGroup(riskGroupData: riskGroup): Promise<riskGroup> {
		return axiosInstance.post('/risk/risk_groups/', riskGroupData).then((res) => res.data);
	},

	deleteRiskGroup(riskGroupId: number): Promise<riskGroup> {
		return axiosInstance.delete(`/risk/risk_groups/${riskGroupId}/`).then((res) => res.data);
	},

	getRiskGroupUsers(riskGroupId: number): Promise<riskGroupUser[]> {
		return axiosInstance.get(`/risk/risk_groups/${riskGroupId}/users/`).then((res) => res.data);
	},

	addRiskGroupUser(user: userType): Promise<userType> {
		return axiosInstance.post('/risk/risk_groups/add_users/', user).then((res) => res.data);
	},

	deleteRiskGroupUser(user: userType): Promise<userType> {
		return axiosInstance.post('/risk/risk_groups/remove_users/', user).then((res) => res.data);
	},
};
