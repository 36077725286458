import { useState, useRef, FormEvent } from 'react';

/**
 * Search hook which returns Search component and search value
 *
 * @param { string | undefined } startValue
 * @returns { [ () => JSX.Element, string ] }
 */
export default (
	handleSearch: (val: string) => void,
	startValue: string = '',
	opened: boolean = false,
	closeable: boolean = true
) => {
	// Define search value with the start value and define updater function
	const [value, setSearchValue] = useState<string>(startValue);

	// Create search ref
	const searchRef = useRef<HTMLInputElement>(null);

	// Function for focusing the input
	const focusInput = () => {
		searchRef && searchRef.current && searchRef.current.focus();
	};

	// Function for updating the search value
	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		const val = `${e.currentTarget.value}`;
		setSearchValue(val);
		handleSearch(val);
	};

	// Toggle class on search input
	const [toggled, setToggled] = useState(opened);

	// Function which adds the toggle class to the search
	// input and focuses it after 100ms
	const open = () => {
		setToggled(true);
		focusInput();
	};

	// Close the search and clear the value
	const close = () => {
		setToggled(false);
		setSearchValue('');
		handleSearch('');
	};

	return { searchRef, value, handleChange, toggled, open, close, closeable };
};
