import React, { Fragment, ReactNode } from 'react';

interface Props {
	loaded?: boolean;
	error?: string;
	children?: ReactNode | ReactNode[];
}

export default ({ loaded, error, children }: Props) => {
	if (error)
		return (
			<div className="loader activate" style={{ zIndex: 9 }}>
				{error}
			</div>
		);

	if (loaded) return <Fragment>{children}</Fragment>;

	return (
		<div data-testid="simple-loader-gif" className="loader activate" style={{ zIndex: 9 }}>
			<img src="/assets/img/loader.gif" className="loader_img" />
		</div>
	);
};
