import { ExtensionItem, EventItem } from './../types';
import _ from 'lodash';
import { PlanItem } from '../types';

/**
    * Separate plans by status flag
    * 
    * @param { plans } plans
    * @returns inCreation && active object, list of finished plans
    */
export function separatePlans(plans: PlanItem[]) {
	// extract inCreation plan
	let inCreation = plans.find((plan) => plan.status === 'in-creation' || plan.status === 'locked');

	// extract active plan
	let active = plans.find((plan) => plan.status === 'active');

	// Extract month string from endDate and add it to finished plans objects
	let finishedPlans = plans
		.filter((plan) => plan.status === 'finished')
		.map((plan) => ({ ...plan, month: plan.dateEnd.substring(0, 7) }));

	// sort plans by mont in desending order
	sortByMonth(finishedPlans);

	// lodash’s groupBy function to the finished plans array using the property month
	let groupedByMonth = _.groupBy(finishedPlans, 'month');

	// loadsh mapValues function that takes our grouped by month plans
	// and go trough every month array
	//to remove month flag from finished plan items using lodash omit
	let finishedPlansWithoutMonth = _.mapValues(groupedByMonth, (byMonthArr) =>
		byMonthArr.map((planInMonth) => _.omit(planInMonth, 'month'))
	);
	// transform result object to array of key/values
	let finished = Object.entries(finishedPlansWithoutMonth);

	return {
		inCreation,
		active,
		finished
	};
}

// Sort finished plans by month
const sortByMonth = (plans: PlanItem[]) => {
	plans.sort((a: any, b: any) => {
		const planA = a.month;
		const planB = b.month;

		if (planA < planB) return 1;
		if (planA > planB) return -1;
		return 0;
	});
};

// Sort extensions by number
export const sortByNumber = (extensions: ExtensionItem[]) => {
	extensions.sort((a: ExtensionItem, b: ExtensionItem) => {
		const extensionA = a.number;
		const extensionB = b.number;

		if (extensionA < extensionB) return 1;
		if (extensionA > extensionB) return -1;
		return 0;
	});
};

// Sort events by start time
export const sortByStartTime = (events: EventItem[], direction: string) => {
	events.sort(function(a: EventItem, b: EventItem) {
		let eventA = new Date(a.scheduled);
		let eventB = new Date(b.scheduled);
		if (direction === 'ascending') return eventA > eventB ? 1 : -1;
		if (direction === 'descending') return eventA < eventB ? 1 : -1;
		return 0;
	});
};

// Sort events by inOffer status
export const sortByOfferStatus = (events: EventItem[], direction: string) => {
	events.sort(function(a: EventItem, b: EventItem) {
		let eventA = a.inOffer;
		let eventB = b.inOffer;
		if (direction === 'ascending') return Number(eventA) - Number(eventB);
		if (direction === 'descending') return Number(eventB) - Number(eventA);
		return 0;
	});
};

// Sort events by antepost status
export const sortByAntepostStatus = (events: EventItem[], status: string) => {
	events.sort(function(a: EventItem, b: EventItem) {
		if (status === 'antepost') return +b.isAntepost - +a.isAntepost || +b.canBeAntepost - +a.canBeAntepost;
		if (status === 'notAntepost') return +a.isAntepost - +b.isAntepost || +b.canBeAntepost - +a.canBeAntepost;
		if (status === 'regular') return +a.canBeAntepost - +b.canBeAntepost || +b.isAntepost - +a.isAntepost;
		return 0;
	});
};

// Sort events by inOffer status
export const sortByTournament = (events: EventItem[], direction: string) => {
	events.sort(function(a: EventItem, b: EventItem) {
		let eventA = a.tournament.name;
		let eventB = b.tournament.name;
		if (direction === 'ascending') return eventA > eventB ? 1 : -1;
		if (direction === 'descending') return eventB > eventA ? 1 : -1;
		return 0;
	});
};

// Format pland date string
export function formatPlanDate(dateStart: string, dateEnd: string) {
	const startDate = dateStart.split('-').reverse().join('.').slice(0, 6);
	const endDate = dateEnd.split('-').reverse().join('.').slice(0, 6);
	return `${startDate} - ${endDate}`;
}

// Format extension date string
export function formatExtensionDate(providedDate: string | null = null): string {
	// Zero prefix
	const zp = (num: number) => (num > 9 ? num : `0${num}`);

	const d = providedDate ? new Date(providedDate) : new Date();

	const day = zp(d.getDate());
	const mon = zp(d.getMonth() + 1);

	const result = `${day}.${mon}`;
	return result;
}
