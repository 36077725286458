import React, { useState, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

// Local interface for the component
interface Props {
	path: string;
	svg?: string;
	icon?: string;
	children?: ReactNode;
	text?: string;
}

export default ({ svg, icon, path, children, text }: Props) => {
	const [ expanded, toggleExpanded ] = useState<boolean>(false);
	const toggleClass: string = expanded ? 'submenu toggle' : 'submenu';

	const expand = (e: React.SyntheticEvent<HTMLElement>) => {
		e.preventDefault();
		toggleExpanded(!expanded);
	};

	return (
		<li className="submenu_toggle">
			<NavLink className="nav_item" to={path} onClick={expand}>
				{svg ? (
					<span className="svg_icon">
						<svg className="e_ico">
							<use xlinkHref={'/assets/svg/svg_sprite.svg#' + svg} />
						</svg>
					</span>
				) : (
					<i className={'mdi mdi-' + icon} />
				)}
				<span className="label">{text}</span>
				<i className="mdi mdi-menu-down cert" />
			</NavLink>
			<ul className={toggleClass}>{children}</ul>
		</li>
	);
};
