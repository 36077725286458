import React, { FC, useState, Dispatch, SetStateAction, useEffect } from 'react';

import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import { formatDate } from '../../../../../utils/getDate';

// Local interface for the component
interface Props {
	scheduled: string;
	editing: boolean;
	eventId: string;
	setEditing: Dispatch<SetStateAction<boolean>>;
	refresh: () => void;
}

export const EditTime: FC<Props> = ({ scheduled, editing, setEditing, eventId, refresh }) => {
	const { t } = useTranslation();
	const [startDate, setStartDate] = useState(new Date());
	const [disabledTime, setDisabledTime] = useState<boolean>(false);

	useEffect(() => {
		setStartDate(new Date(`${scheduled}Z`));
	}, [scheduled]);

	const handleStartDate = (date: Date) => {
		setStartDate(date);
	};

	const confirm = async () => {
		try {
			setDisabledTime(true);
			await api.liveEvents.updateEventStartTime(eventId, startDate.toISOString());
			setEditing(false);
			refresh();
		} catch (err) {
			console.log(err);
		} finally {
			setDisabledTime(false);
		}
	};

	const [date, time] = formatDate(`${startDate.toISOString().slice(0, -1)}`, 'date.month.year', 'hours:minutes');

	return (
		<>
			{editing ? (
				<div className="one_line_form edit_time_V">
					<DatePicker
						open
						selected={startDate}
						onChange={handleStartDate}
						showTimeSelect
						timeFormat="HH:mm"
						timeIntervals={15}
						timeCaption="time"
						dateFormat="dd.MM.yy HH:mm"
						calendarClassName={'date-and-time'}
					/>
					<button
						className={`one_line_form_action md btn blue tooltip_left ${disabledTime ? 'disabled' : ''} `}
						onClick={confirm}
					>
						<i className="mdi mdi-check-all" />
						<span className="tooltip_content">{t('general.save')}</span>
					</button>
				</div>
			) : (
				<span className="parameter">
					<span className="time">{date}</span>
					<span className="date">{time}</span>
					<button
						style={{ borderRadius: '4px !important' }}
						className="sm btn blue tooltip_left"
						onClick={() => setEditing(true)}
					>
						<i className="mdi mdi-pencil" />
						<span className="tooltip_content">{t('general.edit')}</span>
					</button>
				</span>
			)}
		</>
	);
};
