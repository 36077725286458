import { groupBy, isEmpty } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import { Loader } from '../../../../../components/general/Loader';
import Modal from '../../../../../components/Modals';
import { useNotify } from '../../../../../hooks/useNotifications';
import { Sport } from '../../../../administration/sports/types';
import { Dropdown } from '../../../../pairing/components';
import { TemplateItem } from '../../../templates/types';
import { DailyExportTemplate, SportTemplate } from '../../types';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';

interface Props {
	close: (template: DailyExportTemplate | null) => void;
	dailyExportTemplate: DailyExportTemplate | null;
	time: boolean;
}

export const DailyOfferModal: FC<Props> = ({ close, dailyExportTemplate = null, time = false }) => {
	const { t } = useTranslation();
	const [loaded, setLoaded] = useState(false);

	const defaultSelectedSports = dailyExportTemplate
		? dailyExportTemplate.sportTemplateMap.map(({ idSport }) => idSport)
		: [];
	const defaultSportTemplateMap = dailyExportTemplate ? dailyExportTemplate?.sportTemplateMap : [];
	const defaultSelectedPrintTemplate = dailyExportTemplate ? dailyExportTemplate.idListExportTemplate : null;

	const [sports, setSports] = useState<Sport[]>([]);
	const [selectedSports, setSelectedSports] = useState<string[]>(defaultSelectedSports);

	const [groupedTemplates, setGroupedTemplates] = useState<{ [sportId: string]: TemplateItem[] }>({});
	const [sportTemplateMap, setSportTemplateMap] = useState<SportTemplate[]>(defaultSportTemplateMap);

	const [printTemplates, setPrintTemplates] = useState<{ id: number; name: string }[]>([]);
	const [selectedPrintTemplate, setSelectedPrintTemplate] = useState<number | null>(defaultSelectedPrintTemplate);

	const [disabled, setDisabled] = useState<boolean>(false);

	const { success, warn } = useNotify();

	const selectSport = (sportId: string) => {
		if (selectedSports.includes(sportId)) {
			setSelectedSports(selectedSports.filter((id) => id !== sportId));
		} else {
			setSelectedSports([...selectedSports, sportId]);
		}
	};

	const selectTemplate = (idSport: string, idTemplate: string) => {
		if (sportTemplateMap.find(({ idSport: id }) => idSport === id)) {
			setSportTemplateMap((sportTemplateMap) =>
				sportTemplateMap.map((sportTemplate) =>
					sportTemplate?.idSport === idSport ? { idSport, idTemplate } : sportTemplate
				)
			);
		} else setSportTemplateMap([...sportTemplateMap, { idSport, idTemplate }]);
	};

	const selectPrintTemplate = (printTemplateId: number | null) => {
		setSelectedPrintTemplate(printTemplateId);
	};

	useEffect(() => {
		(async () => {
			try {
				const printTemplates = await api.print.getPrintTemplates();
				setPrintTemplates(printTemplates.map(({ id, name }) => ({ id, name })));
				if (!isEmpty(printTemplates)) {
					if (dailyExportTemplate && dailyExportTemplate?.idListExportTemplate != null) {
						selectPrintTemplate(dailyExportTemplate.idListExportTemplate);
					} else selectPrintTemplate(printTemplates[0]?.id || null);
				}
				const sportsData = await api.sports.getList('REGULAR');
				setSports(sportsData);
				const { templates } = await api.templates.getTemplates({ limit: 1000 });
				const grouped = groupBy(templates, (template) => template?.sport?.id);
				setGroupedTemplates(grouped);
			} catch (error) {
			} finally {
				setLoaded(true);
			}
		})();
	}, []);

	const save = async () => {
		if (!selectedPrintTemplate) return warn(t('toast.warn.selectPrintTemplate'));
		const sportTemplates = sportTemplateMap.filter(({ idSport }) => selectedSports.includes(idSport));
		//edit
		if (dailyExportTemplate != null) {
			try {
				setDisabled(true);
				const params = {
					id: dailyExportTemplate.id,
					idListExportTemplate: selectedPrintTemplate,
					sportTemplateMap: sportTemplates,
					default: dailyExportTemplate.default,
				};
				const updated = await (time
					? api.timeExport.updateTimeTemplate(params)
					: api.dailyExport.updateDailyTemplate(params));
				success(
					t('toast.success.updateSuccessful', {
						entity: time ? t('listBuilder.export.timeOfferTemplate') : t('listBuilder.export.dailyOfferTemplate'),
					})
				);
				close(updated);
			} catch (err) {
				console.error(err);
				warn(
					t('toast.warn.updateProblem', {
						entity: time ? t('listBuilder.export.timeOfferTemplate') : t('listBuilder.export.dailyOfferTemplate'),
					})
				);
			} finally {
				setDisabled(false);
			}
		}

		//create
		else {
			try {
				setDisabled(true);
				const params = {
					idListExportTemplate: selectedPrintTemplate,
					sportTemplateMap: sportTemplates,
					default: false,
				};
				const created = await (time
					? api.timeExport.createTimeTemplate(params)
					: api.dailyExport.createDailyTemplate(params));
				success(t('toast.success.creationSuccessful', { entity: t('listBuilder.export.dailyOfferTemplate') }));
				close(created);
			} catch (err) {
				console.error(err);
				warn(t('toast.warn.createProblem', { entity: t('listBuilder.export.dailyOfferTemplate') }));
			} finally {
				setDisabled(false);
			}
		}
	};

	return (
		<Modal
			opened={true}
			size="xxl"
			align="start"
			direction="column"
			cancelAction={() => close(null)}
			body={
				<Loader loaded={loaded}>
					<div className="margin_bottom_30 margin_top_30 direction_row align_center padding_left_20">
						<div className="label margin_right_20 font_700">{`${t(
							'listBuilder.export.selectPrintTemplate'
						)}:`}</div>
						<Dropdown
							//@ts-ignore
							items={printTemplates}
							//@ts-ignore
							selectedItem={selectedPrintTemplate}
							//@ts-ignore
							selectItem={selectPrintTemplate}
							margins={['0']}
							icon={'mdi mdi-menu-down'}
							listClass="list_6_items"
						/>
					</div>
					<table className="admin_table full_w sticky_header">
						<TableHeader />
						<tbody className="t_body">
							{!isEmpty(sports) &&
								sports?.map((sport, index) => (
									<TableRow
										key={`${sport?.id}:${index}`}
										sport={sport}
										selectedSports={selectedSports}
										selectSport={selectSport}
										templates={groupedTemplates?.[sport?.id] || []}
										selectedTemplate={
											sportTemplateMap?.find(({ idSport }) => idSport === sport?.id)?.idTemplate || ''
										}
										selectTemplate={selectTemplate}
									/>
								))}
						</tbody>
					</table>
				</Loader>
			}
			footer={
				<>
					<button className="btn md round outline toggle_modal_12" onClick={() => close(null)}>
						{t('general.cancel')}
					</button>
					<button className={`btn md round green toggle_modal_12 ${disabled ? 'disabled' : ''}`} onClick={save}>
						{t('general.save')}
					</button>
				</>
			}
		></Modal>
	);
};
