import i18n from '../../../localisation/i18n';

export interface EventStatusAdditionalDataMap {
	[key: string]: EventStatusAdditionalData;
}

export type EventStatusAdditionalData = {
	color: string;
	sign: string;
	icon: string;
	name: string;
	id: string;
	order: number;
};

export const eventStatuses: EventStatusAdditionalDataMap = {
	NOT_RUNNING: {
		id: 'NOT_RUNNING',
		color: 'blueish blue',
		sign: 'NR',
		icon: 'mdi-timer-off',
		order: 0,
		name: i18n.t('liveEventFilters.NOT_RUNNING'),
	},
	RUNNING: {
		id: 'RUNNING',
		color: 'green',
		sign: 'R',
		icon: 'mdi-play',
		order: 1,
		name: i18n.t('liveEventFilters.RUNNING'),
	},
	STOPPED: {
		id: 'STOPPED',
		color: 'redish red',
		sign: 'S',
		icon: 'mdi-stop',
		order: 2,
		name: i18n.t('liveEventFilters.STOPPED'),
	},
	NOT_FINISHED: {
		id: 'NOT_FINISHED',
		color: 'orange',
		sign: 'NF',
		icon: 'mdi-stop',
		order: 3,
		name: i18n.t('liveEventFilters.NOT_FINISHED'),
	},
	FINISHED: {
		id: 'FINISHED',
		color: 'dark',
		sign: 'F',
		icon: 'mdi-check-all',
		order: 4,
		name: i18n.t('liveEventFilters.FINISHED'),
	},
	CANCELED: {
		id: 'CANCELED',
		color: 'redish red',
		sign: '',
		icon: '',
		order: 5,
		name: i18n.t('liveEventFilters.CANCELED'),
	},
	INTERRUPTED: {
		id: 'INTERRUPTED',
		color: 'orange',
		sign: '',
		icon: '',
		order: 6,
		name: i18n.t('liveEventFilters.INTERRUPTED'),
	},
	POSTPONED: {
		id: 'POSTPONED',
		color: 'orange',
		sign: '',
		icon: '',
		order: 8,
		name: i18n.t('liveEventFilters.POSTPONED'),
	},
	ABANDONED: {
		id: 'ABANDONED',
		color: 'redish red',
		sign: '',
		icon: '',
		order: 7,
		name: i18n.t('liveEventFilters.ABANDONED'),
	},
	DELAYED: {
		id: 'DELAYED',
		color: 'orange',
		sign: '',
		icon: '',
		order: 9,
		name: i18n.t('liveEventFilters.DELAYED'),
	},
};

const emptyData: EventStatusAdditionalData = {
	id: '',
	color: '',
	sign: '',
	icon: '',
	name: '',
	order: 100,
};

export const getEventStatusAdditionalData = (status: string) => {
	const eventAdditionalData = eventStatuses[status] || { ...emptyData, name: status, id: status };
	return eventAdditionalData;
};
