import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../../utils/getDate';
import { toMoneyFormat } from '../../../../../../utils/toFixed';
import { LockedDeposit } from '../../types';

interface Props {
	lockedDeposit: LockedDeposit;
	openConfirmModal: (depositId: number) => void;
}
export const TableRow: FC<Props> = ({ lockedDeposit, openConfirmModal }) => {
	const { t } = useTranslation();

	const { id, dtUpdated, dtCreated, depositAmount, unlockedAmount, targetAmount } = lockedDeposit;

	const [created] = formatDate(dtCreated, 'date.month.year | hours:minutes:seconds');
	const [updated] = formatDate(dtUpdated, 'date.month.year | hours:minutes:seconds');

	const clickHandler = () => {
		openConfirmModal(id);
	};
	return (
		<tr>
			<td className="text_right">{toMoneyFormat(depositAmount)}</td>
			<td className="text_right">{toMoneyFormat(unlockedAmount)}</td>
			<td className="text_right">{toMoneyFormat(targetAmount)}</td>
			<td className="time_stamp">
				<span className="date ">{created}</span>
			</td>
			<td className="time_stamp">
				<span className="date ">{updated}</span>
			</td>
			<td className="text_center padding_right_35" style={{ paddingRight: '29px !important' }}>
				{
					<div className="plan_ico info">
						<i className="mdi mdi-lock-outline  tooltip_left" onClick={clickHandler}>
							<span className="tooltip_content">{t('usersAdministrations.unlockDeposit')}</span>
						</i>
					</div>
				}
			</td>
		</tr>
	);
};
