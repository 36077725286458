import { BasicFlowItem, BasicFlowSumItem } from './../../pages/reports/basic-report/types';

import { axiosInstance } from '../config';
import { fileNameFromHeaders } from '../../utils/getExportFileName';

interface Params {
	offerPlan: string | null;
	dateFrom: string | null;
	dateTo: string | null;
	idCompany: string | null;
	idRegion: string | null;
	idGroup: string | null;
	locationIds: string[] | null;
	showBy: string;
	isLive: boolean | null;
	providerIds: string[] | null;
	skip: number;
	limit: number;
	transactionType?: string[];
}
export const basicReport = {
	getBasicflowItems(
		params: Params,
		version?: string
	): Promise<{ total: number; items: BasicFlowItem[]; totalSums: BasicFlowSumItem[] }> {
		return axiosInstance.post(`/reporting/basic${version ?? ''}`, { ...params }).then((res) => res.data);
	},
	getBasicflowItemsExport(params: Params, version?: string): Promise<any> {
		return axiosInstance.post(`/reporting/basic/download${version ?? ''}`, { ...params }).then((res) => {
			return [fileNameFromHeaders(res.headers, 'basic-reports'), res.data];
		});
	},
};
