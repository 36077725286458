import React, { FC, useState, Fragment, useMemo } from 'react';
import { PlayerPropsScore, SpecialOddType } from '../../types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Dropdown } from '../../../../pairing/components';
import { OddLocalization } from '../../../../../components/MarketsAdministration/Modal/OddTypes/OddLocalization';
import Modal from '../../../../../components/Modals';
import { FormulaBuilder, Tag } from './FormulaBuilder';
import { useNotify } from '../../../../../hooks/useNotifications';

interface Props {
	odd: SpecialOddType;
	editOdd: (odd: SpecialOddType) => void;
	selectedScores: PlayerPropsScore[];
}

export const OddModal: FC<Props> = ({ odd, editOdd, selectedScores }) => {
	const { t } = useTranslation();
	const { warn } = useNotify();
	const [formulaBuilderOpened, setOpenFormulaBuilder] = useState(false);
	const [addedTags, setAddedTags] = useState<Tag[]>([]);

	const simpleFormula = formulaItems.find((item) => item.id === odd.formula);

	const [selectedFormula, setSelectedFormula] = useState(simpleFormula ? odd.formula : 'value<boundary');

	const selectFormula = (id: string | number) => {
		setSelectedFormula(`${id}`);
		editOdd({ ...odd, formula: `${id}` });
	};

	const { getValues } = useFormContext();
	const hasBoundaries: boolean = getValues('hasBoundaries');
	const playerProps: boolean = getValues('playerProps');

	const handleKeyboardCode = (e: React.ChangeEvent<HTMLInputElement>) => {
		editOdd({ ...odd, keyboardCode: e.target.value });
	};

	const saveFormula = () => {
		const lastAddedType = addedTags[addedTags.length - 1].type;
		if (!['val', 'bool', 'props'].includes(lastAddedType)) return warn('specials.finishExpression');

		editOdd({ ...odd, formula: addedTags.map((tag) => tag.id).join(' ') });
		setOpenFormulaBuilder(false);
	};

	const scores = useMemo(() => {
		return selectedScores.map((score) => score.keyword);
	}, [selectedScores]);

	return (
		<Fragment>
			<div className="margin_bottom_10 margin_top_20  padding_left_25 align_center">
				<div className="align_center width_60_perc">
					<div className="label col-6">{`${t('specialMarkets.keyboardCode')}:`}</div>
					<div className="col-6 margin_right_25">
						<input
							className="col-12 normal text_center"
							style={{ height: '28px' }}
							name="order"
							type="text"
							value={odd.keyboardCode}
							onChange={handleKeyboardCode}
						/>
					</div>
				</div>
				<div className="align_center  width_40_perc"></div>
			</div>
			{hasBoundaries && !playerProps && simpleFormula ? (
				<div className="margin_bottom_20 margin_top_20  padding_left_25 align_center">
					<div className="align_center col-12">
						<div className="label col-3">{`${t('specialMarkets.formula')}:`}</div>
						<div className="col-9 margin_left_20 margin_right_45 align_center ">
							<span className="col-1">{t('specialMarkets.value')}</span>
							<span className="col-3 margin_left_10">
								<Dropdown
									items={formulaItems}
									fontSize={'font_24px'}
									selectItem={selectFormula}
									selectedItem={selectedFormula}
									margins={['0', '10', '0', '10']}
								/>
							</span>

							<span className="col-1">{t('specialMarkets.boundary')}</span>
						</div>
					</div>
				</div>
			) : null}
			{(playerProps && selectedScores.length) || (!playerProps && !simpleFormula) ? (
				<div className="margin_bottom_20 margin_top_20  padding_left_25 align_center">
					<div className="align_center col-12">
						<div className="label col-3">{`${t('specialMarkets.formula')}:`}</div>
						<div className="col-9 margin_left_20 margin_right_45 align_center ">
							<span className="col-1" onClick={() => setOpenFormulaBuilder(true)}>
								<i className="mdi mdi-pencil lg tooltip_left" style={{ fontSize: '18px', color: 'green' }}></i>
							</span>
							<span className="col-11">{odd.formula}</span>
						</div>
					</div>
				</div>
			) : null}
			<OddLocalization odd={odd} editOdd={editOdd} langDirection="down" />
			{formulaBuilderOpened ? (
				<Modal
					opened={true}
					size={'lg'}
					align={'start'}
					direction={'column'}
					cancelAction={() => setOpenFormulaBuilder(false)}
					header={<h2 className="modal_title padding_left_25">Formula builder</h2>}
					body={
						<FormulaBuilder
							playerProps={scores}
							addedTags={addedTags}
							setAddedTags={setAddedTags}
							formula={odd.formula}
							withBoundary={!simpleFormula}
						/>
					}
					footer={
						<Fragment>
							<button className="btn md round outline " onClick={() => setOpenFormulaBuilder(false)}>
								{t('general.cancel')}
							</button>
							<button className={`btn md round green`} onClick={saveFormula}>
								{t('general.save')}
							</button>
						</Fragment>
					}
				/>
			) : null}
		</Fragment>
	);
};

const formulaItems = [
	{ id: 'value>boundary', name: '>' },
	{ id: 'value<boundary', name: '<' },
	{ id: 'value=boundary', name: '==' },
	{ id: 'value<=boundary', name: '<=' },
	{ id: 'value>=boundary', name: '>=' },
];
