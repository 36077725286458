import React, { FC, useEffect, useState } from "react"
import api from "../../../../../api"
import { Markets } from "./Markets"
import { Sport } from "../../../../administration/sports/types";
import { useTranslation } from "react-i18next";

interface Props {
  data: any;
  setData: any;
}

type SelectedSport = {
  sport: string;
  markets: string[];
}

export const Mix: FC<Props> = ({ data, setData }) => {
  const { i18n } = useTranslation();
  const { language: ln } = i18n;

  const [sports, setSports] = useState<Sport[]>([])
  const [selectedSports, setSelectedSports] = useState<SelectedSport[]>(data)
  const [selectedSport, setSelectedSport] = useState(null)

  const selectSport = (id: string) => {
	setSelectedSports(selectedSports => [...selectedSports, { sport: id, markets: [] }])
  }

  const removeSport = (id: string) => {
	if (selectedSport === id) {
	  setSelectedSport(null)
	}
	setSelectedSports(selectedSports => [...selectedSports.filter(selected => selected.sport !== id)])
  }

  const moveUpSport = (id: string) => {
	setSelectedSports(selectedSports => {
	  const index = selectedSports.findIndex(s => s.sport === id);
	  if (index === 0) return [...selectedSports];

	  const copy = [...selectedSports];
	  [copy[index], copy[index - 1]] = [copy[index - 1], copy[index]]

	  return copy;
	})
  }

  const moveDownSport = (id: string) => {
	setSelectedSports(selectedSports => {
	  const index = selectedSports.findIndex(s => s.sport === id);
	  if (index === selectedSports.length - 1) return [...selectedSports];

	  const copy = [...selectedSports];
	  [copy[index], copy[index + 1]] = [copy[index + 1], copy[index]]

	  return copy;
	})
  }

  useEffect(() => {
	async function fetchData() {
	  const sports = await api.sports.getList();
	  setSports(sports)
	}
	fetchData()
  }, [])

  useEffect(() => {
	setData(selectedSports)
  }, [selectedSports])

  return (
	<div className="flex_direction_row">
	  <div className="col-6">
		<div className="flex_direction_row">
		  <div className="col-6">
			<table className="admin_table full_w">
			  <thead className="t_head">
				<tr>
				  <th
					colSpan={2}
				  >
					Sport
				  </th>
				</tr>
			  </thead>
			  <tbody className="t_body">
				{sports.filter(sport => !selectedSports.find(s => sport.id === s.sport)).map((sport) => (
				  <tr key={sport.id}>
					<td>{sport.name?.[ln] || sport.defaultName}</td>
					<td className="actions_cell" width={50}>
					  <div className="td_actions">
						<div className="tbl_btn green">
						  <i onClick={() => { selectSport(sport.id) }} className="mdi mdi-chevron-right" />
						</div>
					  </div>
					</td>
				  </tr>
				))}
			  </tbody>
			</table>
		  </div>
		  <div className="col-6">
			<table className="admin_table full_w">
			  <thead className="t_head">
				<tr>
				  <th
					colSpan={5}
				  >
					Sport
				  </th>
				</tr>
			  </thead>
			  <tbody className="t_body">
				{selectedSports.map(id => sports.find(sport => sport.id === id.sport)).filter(sport => sport).map((sport, i) => (
				  <tr key={sport.id}>
					<td className="actions_cell" width={50}>
					  <div className="td_actions">
						<div className="tbl_btn green">
						  <i onClick={() => { removeSport(sport.id) }} className="mdi mdi-chevron-left" />
						</div>
					  </div>
					</td>
					<td>{sport.name?.[ln] || sport.defaultName}</td>
					<td className="actions_cell" width={50}>
					  <div className="td_actions">
						{i > 0 && (
						  <div className="tbl_btn green">
							<i onClick={() => { moveUpSport(sport.id) }} className="mdi mdi-chevron-up" />
						  </div>
						)}
					  </div>

					</td>
					<td className="actions_cell" width={50}>
					  <div className="td_actions">
						{i < selectedSports.length - 1 && (
						  <div className="tbl_btn green">
							<i onClick={() => { moveDownSport(sport.id) }} className="mdi mdi-chevron-down" />
						  </div>
						)}
					  </div>

					</td>
					<td className="actions_cell" width={50}>
					  <div className="td_actions">
						{!selectedSport && (
						  <div className="tbl_btn green">
							<i onClick={() => { setSelectedSport(sport.id) }} className="mdi mdi-pencil" />
						  </div>
						)}
						{selectedSport === sport.id && (
						  <div className="tbl_btn green">
							<i onClick={() => { setSelectedSport(null) }} className="mdi mdi-pencil" />
						  </div>
						)}
					  </div>

					</td>
				  </tr>
				))}
			  </tbody>
			</table>
		  </div>
		</div>
	  </div>
	  {selectedSport ? <Markets data={data} setData={setData} sport={selectedSport} /> : <div className="col-6"></div>}
	</div>
  )
}