import React, { FC, SetStateAction, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelectDropdown from '../../../../../components/general/MultiSelectDropdown';
import { Item } from '../../../../../types/general';

interface Props {
	locations: Item[];
	selectedLocations: string[];
	selectLocations: Dispatch<SetStateAction<string[]>>;
	isDisabled?: boolean;
}

export const LocationsDropdown: FC<Props> = ({ selectedLocations, selectLocations, locations, isDisabled }) => {
	const { t } = useTranslation();

	return (
		<MultiSelectDropdown
			title={t('general.locations')}
			marginRight={'20'}
			options={locations}
			selectedOptions={selectedLocations}
			selectOption={selectLocations}
			withSearch
			isDIsabled={isDisabled}
		/>
	);
};
