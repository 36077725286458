import React, { ReactNode, useMemo, useState } from 'react';
import { useLayout } from './LayoutContext';

const rightPanelClass = 'right_pannel_toggle';
const leftPanelClass = 'left_pannel_toggle';

interface Props {
	panel?: boolean;
	left?: boolean;
	flex?: boolean;
	resetError?: () => void;
	children?: ReactNode;
	padding?: string;
	customClasses?: string;
	customScrollClasses?: string;
	overflow_x?: boolean;
	isMobileView?: boolean;
	widthClass?: string;
	overflow_x_scroll?: boolean;
	overflow_x_full_w?: boolean;
}

export default ({
	panel,
	left,
	flex,
	children,
	padding = '',
	customClasses = '',
	customScrollClasses = '',
	overflow_x,
	isMobileView,
	widthClass = '',
	overflow_x_scroll,
	overflow_x_full_w,
}: Props) => {
	const { panelToggled } = useLayout();
	const toggleClass = left ? leftPanelClass : rightPanelClass;
	let className = panel && panelToggled ? `scroll_content ${toggleClass}` : 'scroll_content';
	className = flex ? `${className} flex_column` : className;
	const overflow_X = overflow_x ? 'overflow_x' : '';
	const overflowXScroll = overflow_x_scroll ? 'overflow_x_scroll' : '';
	const overflowXfullW = overflow_x_full_w ? 'overflow_x_full_w' : '';

	const isMobileView_scroll = isMobileView ? `${widthClass}` : '';
	const isMobileView_width = isMobileView ? `${widthClass}` : '';

	return (
		<div className={`main_content ${customClasses} ${isMobileView_scroll}`}>
			<div
				id="infinite_scroll"
				className={`scroll_container ${padding} ${customScrollClasses} ${overflow_X} ${overflowXfullW} ${isMobileView_width}`}
			>
				<div data-testid="scroll-content" className={`${className} ${isMobileView_width} ${overflowXScroll}`}>
					{children}
				</div>
			</div>
		</div>
	);
};
