export interface MarketStatuses {
	[key: string]: { color: string; text: string; sign: string };
}

export const marketStatuses: MarketStatuses = {
	// CANCELED: {
	// 	color: 'redish',
	// 	sign: 'S',
	// 	text: 'Ponisti igre',
	// },
	TEMPORARY_REMOVED: {
		color: 'blue',
		sign: 'L',
		text: 'Zakljucaj igre',
	},
	ACTIVE: {
		color: 'green',
		sign: 'U',
		text: 'Odkljucaj igre',
	},
	REMOVED: {
		color: 'redish',
		sign: 'D',
		text: 'Disable igre',
	},
};

export const marketFilters = [
	{
		id: 'all',
		name: 'SVE',
	},
	{
		id: 'CLOSED REMOVED TEMPORARY_REMOVED CANCELED',
		name: 'L, S, K, D - Inactive',
	},
	{
		id: 'ACTIVE',
		name: 'A - Aktivne',
	},
	{
		id: 'TEMPORARY_REMOVED',
		name: 'L, D - Zakljucane',
	},

	{
		id: 'CLOSED',
		name: 'K - Zavrsene',
	},
	{
		id: 'CANCELED',
		name: 'S - Ponistene',
	},
];

export const periodsMap: { [key: string]: string } = {
	UNSPECIFIED: 'UNSPECIFIED',
	NOT_STARTED_YET: 'Not Started',

	FIRST_HALF: '1st Half',
	SECOND_HALF: '2nd Half',
	HALF_TIME: 'Half-Time',

	FIRST_QUARTER: '1st Quarter',
	SECOND_QUARTER: '2nd Quarter',
	THIRD_QUARTER: '3rd Quarter',
	FOURTH_QUARTER: '4th Quarter',

	FIRST_QUARTER_END: '1st Q End',
	SECOND_QUARTER_END: '2nd Q End',
	THIRD_QUARTER_END: '3rd Q End',
	FOURTH_QUARTER_END: '4th Q End',

	FIRST_PERIOD: '1st Period',
	SECOND_PERIOD: '2nd Period',
	THIRD_PERIOD: '3rd Period',

	FIRST_PERIOD_END: '1st P End',
	SECOND_PERIOD_END: '2nd P End',
	THIRD_PERIOD_END: '3rd P End',

	OVERTIME: 'Overtime',
	PENALTIES: 'Penalties',
	FIRST_EXTRA_TIME: '1st ET',
	SECOND_EXTRA_TIME: '2nd ET',
	EXTRA_TIME_HALF_TIME: 'ET-HT',
	EXTRA_TIME_FINISHED: 'ET Finished',
	AWAITING_EXTRA_TIME: 'Awaiting Extra Time',
	AWAITING_PENALTIES: 'Awaiting Penalties',

	// Tenis/Volleyball specific
	FIRST_SET: '1st Set',
	SECOND_SET: '2nd Set',
	THIRD_SET: '3rd Set',
	FOURTH_SET: '4th Set',
	FIFTH_SET: '5th Set',

	FIRST_SET_END: '1st Set End',
	SECOND_SET_END: '2nd Set End',
	THIRD_SET_END: '3rd Set End',
	FOURTH_SET_END: '4th Set End',
	FIFTH_SET_END: '5th Set End',

	FIRST_SET_TIEBREAK: '1st Set TB',
	SECOND_SET_TIEBREAK: '2nd Set TB',
	THIRD_SET_TIEBREAK: '3rd Set TB',
	FOURTH_SET_TIEBREAK: '4th Set TB',
	FIFTH_SET_TIEBREAK: '5th Set TB',

	WO_P1: 'WO_P1',
	WO_P2: 'WO_P2',

	FINISHED: 'Finished',
	INTERRUPTED: 'Interrupted',
	ABANDONED: 'Abandoned',
	RETIRED: 'Retired',
	PAUSED: 'Paused',
	DELAYED: 'Delayed',
};
