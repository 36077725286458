import React, { FC, useMemo, useState } from 'react';
import { useTicket } from '../../context/TicketContext';
import { formatDate } from '../../../../../utils/getDate';
import { useData } from '../../../../../context/DataProvider';
import { TicketEvent } from '../../../../../pages/risk-management/controller/types';
import { toFixed } from '../../../../../utils/toFixed';
import { EditOdd } from '../actions/EditOdd';
import { EventInfo } from '../actions/EventInfo';
import { FullOdd, PeriodScore, Result } from '../../types';
import { ACTIVE } from '../../../../../consts/strings';
import { useTranslation } from 'react-i18next';
import { CurrentScore, Score } from '../../../../Events/Modal/types';
import { finalResultCalcParams } from '../../../../../pages/results/helpers';
import { isEmpty } from 'lodash';

interface Props {
	event: TicketEvent;
	systemIndex: number;
	eventIndex: number;
	similarEvent?: boolean;
	currentResults?: Result | null;
	currentTime?: string | null;
	showEvents?: 'showAuthorizedEvents' | 'showOriginalEvents';
}
export const EventRow: FC<Props> = ({
	event,
	systemIndex,
	eventIndex,
	similarEvent = false,
	currentResults = null,
	currentTime = null,
	showEvents,
}) => {
	const { t } = useTranslation();
	const { marketsMap } = useData();
	const { eventsAdditionalData, selectedOdd, selectOdd, screen, similarTicketShown } = useTicket();
	const { id, list_code, live, utc_scheduled, sport, competitors, odds, similar } = event;
	const [date, time] = formatDate(utc_scheduled, 'date.month.', 'hours:minutes');
	const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];
	const [eventInfoModal, setEventInfoModal] = useState(false);

	const results = currentResults || eventsAdditionalData?.[id]?.result || null;

	const extractData = (odd: string) => {
		const [splited] = odd.split('|');
		const marketId = odd.split(':', 2).join(':');
		const marketName = marketsMap[sport.id]?.markets?.markets[marketId]?.name || '';
		const oddtype = marketsMap[sport.id]?.markets?.markets[marketId]?.oddtypes[splited] || {};

		const currentOddType =
			eventsAdditionalData?.[id]?.[oddtype.idMappedOddtype] || eventsAdditionalData?.[id]?.[oddtype.id];

		const currentValue =
			currentOddType &&
			currentOddType.oddtype_status === 1 &&
			currentOddType.market_status === ACTIVE &&
			(currentOddType.manual_status === null || currentOddType.manual_status === ACTIVE)
				? currentOddType.value
				: null;
		return { marketId, oddtype, marketName, currentValue };
	};

	const fullOdds: FullOdd[] = useMemo(() => {
		return Object.keys(odds).map((odd) => {
			const { oddtype, marketName, marketId, currentValue } = extractData(odd);
			const similarValue: number | null = event.similar?.odds?.[odd]?.value || null;
			return {
				...odds[odd],
				...oddtype,
				id: odd,
				marketName,
				marketId,
				eventId: event.id,
				systemIndex,
				eventIndex,
				currentValue,
				similarValue,
			};
		});
	}, [odds, eventsAdditionalData, similar]);

	const similarTicketOdds: FullOdd[] = useMemo(() => {
		return Object.keys(event?.similar?.odds || [])
			.filter((odd) => odds[odd] == undefined)
			.map((odd) => {
				const { oddtype, marketName, marketId, currentValue } = extractData(odd);
				return {
					...event.similar!.odds[odd],
					...oddtype,
					id: odd,
					marketName,
					marketId,
					eventId: event.id,
					systemIndex,
					eventIndex,
					currentValue,
				};
			});
	}, [odds, similar]);

	const periodSymbol = finalResultCalcParams?.[sport?.id]?.period || 'P';

	const playTime = event?.bet_score?.current_time?.split(':')[0] || null;
	const playTimePeriods = event?.bet_score?.result?.period_scores?.sort(
		({ period: a }: { period: number }, { period: b }: { period: number }) => a - b
	);

	const eventInfo = () => {
		setEventInfoModal(true);
	};

	const playTimeScore: CurrentScore | null = event?.bet_score?.result?.current_score || null;

	const playTimePoints: CurrentScore | null = event?.bet_score?.result?.current_points || null;

	const showCurrentStake = screen === 'cashout-offer' || screen === 'cashout-risk';
	return (
		<div className="table_row">
			<div className="cell ticket_code">{list_code}</div>
			<div className="cell icon_single_col">
				{live ? (
					<svg className="svg_icon live">
						<use xlinkHref="/assets/svg/sprite_mb.svg#live_ico"></use>
					</svg>
				) : null}
			</div>
			<div className="cell ticket_event_timestamp_col">
				<div className="timestamp">
					<span className="period">{date}</span>
					<div className="divider"></div>
					<span className="period">{time}</span>
				</div>
			</div>
			<div className="cell sport_icon">
				<div className="sport tooltip_left">
					<svg className="e_ico">
						<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${sport.id}`}></use>
					</svg>
					<span className="tooltip_content">{sport.name}</span>
				</div>
			</div>
			<div className="cell competetors_col">
				<div className="competetors vert">
					<span className="team_home">{home}</span>
					{away ? (
						<>
							<span className="separator"></span>
							<span className="team_away">{away}</span>
						</>
					) : null}
				</div>
			</div>
			<div className="cell result_col">
				{!isEmpty(results?.currentScore) && (
					<div className="result_wrap vert strong margin_right_15">
						<span className="period_label"></span>
						<span className="team_home">{results?.currentScore?.homeScore}</span>
						<span className="separator">:</span>
						<span className="team_away">{results?.currentScore?.awayScore}</span>
					</div>
				)}

				{results?.periodScores
					?.sort((a: { period: number; score: any }, b: { period: number; score: any }) => a.period - b.period)
					?.map(({ period, score: { homeScore, awayScore } }: PeriodScore) => (
						<div key={period} className="result_wrap vert margin_right_15">
							<span className="period_label font_10px">{`${periodSymbol}${period}`}</span>
							<span className="team_home">{homeScore}</span>
							<span className="separator">:</span>
							<span className="team_away">{awayScore}</span>
						</div>
					))}
				{currentTime && currentTime !== '0' ? (
					<span
						className={`strong padding_left_5 ${!isEmpty(results?.periodScores) ? 'col_border_left' : ''} `}
					>{`${currentTime}\``}</span>
				) : null}
			</div>
			{live && (
				<div className="cell result_col">
					{!isEmpty(playTimeScore) && (
						<div className="result_wrap vert strong margin_right_15">
							<span className="period_label"></span>
							<span className="team_home">{playTimeScore?.home_score}</span>
							<span className="separator">:</span>
							<span className="team_away">{playTimeScore?.away_score}</span>
						</div>
					)}
					{playTimePeriods?.map(
						({ period, score: { home_score, away_score } }: { period: number; score: Score }) => (
							<div key={period} className="result_wrap vert margin_right_15">
								<span className="period_label font_10px">{`${periodSymbol}${period}`}</span>
								<span className="team_home">{home_score}</span>
								<span className="separator">:</span>
								<span className="team_away">{away_score}</span>
							</div>
						)
					)}
					{playTime != null && (
						<span className={`${!isEmpty(playTimePeriods) ? 'col_border_left' : ''} padding_left_5`}>
							{' '}
							{`${playTime}\``}
						</span>
					)}

					{playTimePoints ? (
						<div className="result_wrap vert">
							<span className="period_label"></span>
							<span className="fr font_700 padding_left_5 font_13px col_border_left w_40">
								{playTimePoints?.home_score}
							</span>
							<span className="fr font_700 padding_left_5 font_13px col_border_left w_40">
								{playTimePoints?.away_score}
							</span>
						</div>
					) : null}
				</div>
			)}
			<div className="cell type_long">
				{fullOdds.map(({ marketName, special_value }, i) => (
					<div key={`${systemIndex}:${eventIndex}:${marketName}:${i}`} className="item_wrap">
						{`${marketName} ${special_value ? `| ${special_value}` : ''}`}
					</div>
				))}
				{similarTicketShown &&
					!similarEvent &&
					similarTicketOdds.map(({ marketName, special_value }, i) => (
						<div key={`${systemIndex}:${eventIndex}:${marketName}:${i}`} className="item_wrap">
							{`${marketName} ${special_value ? `| ${special_value}` : ''}`}
						</div>
					))}
			</div>
			<div className="cell type_short">
				{fullOdds.map(({ name, description, bonus_type }, i) => {
					const namePreview = bonus_type ? `B${name}` : name;
					return (
						<div key={`${systemIndex}:${eventIndex}:${name}:${i}`} className="item_wrap" title={description}>
							<strong>{namePreview}</strong>
						</div>
					);
				})}
				{similarTicketShown &&
					!similarEvent &&
					similarTicketOdds.map(({ name, description, bonus_type }, i) => {
						const namePreview = bonus_type ? `B${name}` : name;
						return (
							<div key={`${systemIndex}:${eventIndex}:${name}:${i}`} className="item_wrap" title={description}>
								<strong>{namePreview}</strong>
							</div>
						);
					})}
			</div>
			<div className="cell icon_single_col"></div>
			{fullOdds.map(({ voided }, index) =>
				voided ? (
					<i className="mdi mdi-backup-restore tooltip_left" key={index}>
						<span className="tooltip_content">{t('live.voidedOdd')}</span>
					</i>
				) : null
			)}
			<div className="cell stake row" style={{ maxWidth: 160 }}>
				{fullOdds.map((odd, i) => {
					const color = odd.result === 1 ? 'green' : odd.result === 0 ? 'red' : odd.result === -1 ? 'yellow' : '';
					return (
						<div key={`${systemIndex}:${eventIndex}:${odd.value}:${i}`} className="item_wrap">
							<button
								className={`stake_btn ${showEvents == 'showOriginalEvents' ? 'disabled' : ''} ${color}`}
								onClick={() => !similarEvent && selectOdd({ ...odd })}
							>
								{odd?.voided ? `1.00` : toFixed(odd.value)}
							</button>
							{similarTicketShown && !similarEvent ? (
								<>
									<span>{` | `}</span>
									<button className={`stake_btn disabled ${color}`}>
										{odd.similarValue ? toFixed(odd.similarValue) : ' - '}
									</button>
								</>
							) : null}
						</div>
					);
				})}
				{similarTicketShown &&
					!similarEvent &&
					similarTicketOdds.map((odd, i) => {
						const color =
							odd.result === 1 ? 'green' : odd.result === 0 ? 'red' : odd.result === -1 ? 'yellow' : '';
						return (
							<div key={`${systemIndex}:${eventIndex}:${odd.value}:${i}`} className="item_wrap">
								<>
									<button className={`stake_btn disabled ${color}`}> - </button>
								</>
								<span>{` | `}</span>
								<button className={`stake_btn disabled ${color}`}>{toFixed(odd.value)}</button>
							</div>
						);
					})}
			</div>
			{showCurrentStake ? (
				<div className="cell stake_gray">
					{fullOdds.map((odd, i) => {
						return (
							<div key={`${systemIndex}:${eventIndex}:${odd.currentValue}:${i}`} className="item_wrap">
								{odd.currentValue ? toFixed(odd.currentValue) : ''}
							</div>
						);
					})}
				</div>
			) : null}
			<div className="cell stake_gray hide_desktop info_cell">
				<i onClick={() => eventInfo()} className="mdi mdi-information-outline icon_info" data-testid="edit-icon" />{' '}
			</div>

			{selectedOdd && screen === 'tickets' ? <EditOdd odd={selectedOdd} /> : null}
			{eventInfoModal ? (
				<EventInfo
					home={home}
					away={away}
					setEventInfoModal={setEventInfoModal}
					sport={sport}
					list_code={list_code}
					date={date}
					time={time}
					fullOdds={fullOdds}
				/>
			) : null}
		</div>
	);
};
