import { Condition } from '../../pages/parameters/conditions/types';
import { axiosInstance, Response } from './../config';

export const conditions = {
	getConditions(offset: number = 0, limit: number = -1): Promise<Condition[]> {
		return axiosInstance
			.get(`administration/conditions/?offset=${offset}&limit=${limit}`)
			.then((response) => response.data.conditions);
	},

	getConditionById(id_condition: number): Promise<Condition> {
		return axiosInstance.get(`administration/conditions/${id_condition}`).then((response) => response.data);
	},

	createCondition(condition: Condition): Response {
		return axiosInstance.post(`administration/conditions/`, condition).then((response) => response.data);
	},

	updateCondition(condition: Condition): Response {
		return axiosInstance
			.put(`administration/conditions/${condition.id}/`, condition)
			.then((response) => response.data);
	},
};
