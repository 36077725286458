import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import api from '../../../../../api';
import { Sport } from "../../../../administration/sports/types";

interface Props {
	data: any;
	setData: any;
}

export const Announcement: FC<Props> = ({ data, setData }) => {
	const { i18n } = useTranslation();
	const { language: ln } = i18n;

	const [sports, setSports] = useState<Sport[]>([])
	const [selectedSports, setSelectedSports] = useState<string[]>(data)

	const selectSport = (id: string) => {
		setSelectedSports(selectedSports => [...selectedSports, id])
	}

	const removeSport = (id: string) => {
		setSelectedSports(selectedSports => [...selectedSports.filter(selected => selected !== id)])
	}

	useEffect(() => {
		async function fetchData() {
			const sports = await api.sports.getList();
			setSports(sports)
		}
		fetchData()
	}, [])

	useEffect(() => {
		setData(selectedSports)
	}, [selectedSports])

	return (
		<div className="flex_direction_row">
			<div className="col-6">
				<table className="admin_table full_w">
					<thead className="t_head">
						<tr>
							<th
								colSpan={2}
							>
								Sport
							</th>
						</tr>
					</thead>
					<tbody className="t_body">
						{sports.filter(sport => !selectedSports.includes(sport.id)).map((sport) => (
							<tr key={sport.id}>
								<td>{sport.name?.[ln] || sport.defaultName}</td>
								<td className="actions_cell" width={50}>
									<div className="td_actions">
										<div className="tbl_btn green">
											<i onClick={() => { selectSport(sport.id) }} className="mdi mdi-chevron-right" />
										</div>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="col-6">
				<table className="admin_table full_w">
					<thead className="t_head">
						<tr>
							<th
								colSpan={2}
							>
								Sport
							</th>
						</tr>
					</thead>
					<tbody className="t_body">
						{selectedSports.map(id => sports.find(sport => sport.id === id)).filter(sport => sport).map(sport => (
							<tr key={sport.id}>
								<td className="actions_cell" width={50}>
									<div className="td_actions">
										<div className="tbl_btn green">
											<i onClick={() => { removeSport(sport.id) }} className="mdi mdi-chevron-left" />
										</div>
									</div>
								</td>
								<td>{sport.name?.[ln] || sport.defaultName}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}