import i18n from '../../../../localisation/i18n';
import { RadioOption } from './types';

export const idCard = 'idCard';
export const passport = 'passport';

export const genderOptions: RadioOption[] = [
	{
		label: i18n.t('usersAdministrations.male'),
		value: 'Male',
	},
	{
		label: i18n.t('usersAdministrations.female'),
		value: 'Female',
	},
	{
		label: i18n.t('usersAdministrations.other'),
		value: 'Other',
	},
];

//online user profile status
export const REGISTERED = 'REGISTERED';
export const ACTIVE = 'ACTIVE';
export const DELETED = 'DELETED';
export const BLOCKED = 'BLOCKED';
export const INACTIVE = 'INACTIVE';
export const SUSPENDED_BY_USER = 'SUSPENDED_BY_USER';
