import React, { ReactNode } from 'react';
import LoaderWithCatch from './LoaderWithCatch';
import useLoaded from './useLoaded';

interface Props {
	loaded?: boolean;
	allLoaded?: any[];
	error?: string | null | ReactNode;
	children?: ReactNode;
}

/**
 * This component is used to provide allLoaded ability to the LoaderWithCatch using useLoaded hook.
 * Hooks are not allowed in the class components and refactor of the LoaderWithCatch would
 * be time consuming, therefore this component is created ;-)
 */
export default function Loader({ loaded, allLoaded, error, children }: Props) {
	loaded = useLoaded(allLoaded || [ loaded ]);

	return <LoaderWithCatch loaded={loaded} error={error} children={children} />;
}
