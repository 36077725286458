import React, { useState, useEffect, useMemo, FC } from 'react';
import api from '../../../../api';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce/lib';
import { usePagination } from '../../../../hooks';
import { useAxios } from '../../../../hooks/useAxios';
import Modal from '../../../../components/Modals/index';
import { EventItemExtended, SelectedEvent } from '../types';
import { Search, useSearch } from '../../../../components/Search';
import { SportFilter } from '../../../../pages/pairing/components';
import { Header, PageFooter } from '../../../../components/layout';
import { SimpleLoader } from '../../../../components/general/Loader';
import { Sport } from '../../../../pages/administration/sports/types';

interface Props {
	defaultSport: { id: string; defaultName: string };
	setTournaments: (items: SelectedEvent[]) => void;
	selected: SelectedEvent[];
	closeModal: () => void;
	sports: Sport[];
}

export const EventSelector: FC<Props> = ({ defaultSport, setTournaments, selected, closeModal, sports }) => {
	const { t } = useTranslation();

	const [selectedEvents, setSelectedEvents] = useState(selected);
	const [selectedSport, selectSport] = useState<string>(defaultSport.id || 'fb');

	const [searchValue, setSearchValue] = useState<string>('');
	const searchOptions = useSearch(setSearchValue);
	const [debouncedText] = useDebounce(searchValue, 600);

	const { from, setTotal, perPage, setPerPage, ...pagination } = usePagination(30);

	const {
		data: { total, events },
		loading,
	} = useAxios<{
		total: number;
		events: EventItemExtended[];
	}>({
		apiFunction: async function getTour() {
			if (!selectedSport) return [];

			return await api.events.getGlobalEvents({
				sportId: selectedSport,
				q: debouncedText.length > 2 ? debouncedText : '',
				offset: from,
				limit: perPage,
			});
		},

		initialData: { total: 0, events: [] },
		dependencies: [debouncedText, selectedSport, perPage, from],
	});

	useEffect(() => {
		setTotal(total);
	}, [total]);

	const check = (event: EventItemExtended) => {
		const { id, competitors, tournament, category, sport } = event;
		const selected = {
			id,
			competitors,
			tournament: { id: tournament.id, defaultName: tournament.name },
			category,
			sport,
		};
		setSelectedEvents([...selectedEvents, selected]);
	};

	const uncheck = (id: string) => {
		setSelectedEvents((prev) => prev.filter((item) => item.id !== id));
	};

	const filteredEvents = useMemo(() => {
		if (!events || !events.length) return [];
		return events.filter((event) => !selectedEvents.some((item) => event.id === item.id));
	}, [events, selectedEvents]);

	return (
		<Modal
			size="xxl"
			opened={true}
			align={'start'}
			direction={'row'}
			zIndex={'over_3'}
			header={
				<Header
					panel
					leftTool={
						<SportFilter
							sports={sports}
							selectedSport={selectedSport}
							selectSport={selectSport}
							direction="left"
							margin="left_0"
						/>
					}
				>
					<Search message={t('offersPlan.3characters')} {...searchOptions} />
				</Header>
			}
			body={
				<SimpleLoader loaded={!loading}>
					<table className="admin_table sticky_header full_w">
						<TableHeader />
						<tbody className="t_body">
							{selectedEvents.map((selected) => (
								<SelectedTableRow key={selected.id} item={selected} uncheck={uncheck} />
							))}

							{selectedEvents.length ? (
								<div className="divider_horizontal small label_left ltr_direction">
									<div className="div_label">{t('eventsAdministration.selectEvents')}</div>
									<div className="thin_line"></div>
								</div>
							) : null}

							{filteredEvents.map((event) => (
								<TableRow key={event.id} item={event} check={check} />
							))}
						</tbody>
					</table>
				</SimpleLoader>
			}
			footer={
				<>
					<PageFooter removeSize={false} {...pagination} />
					<button className="btn md round outline" onClick={() => closeModal()}>
						{t('general.cancel')}
					</button>

					<button type="submit" className="btn md round green" onClick={() => setTournaments(selectedEvents)}>
						{t('general.save')}
					</button>
				</>
			}
		/>
	);
};

const TableHeader: FC = ({}) => {
	const { t } = useTranslation();

	return (
		<thead className="t_head">
			<tr data-testid="head-row">
				<th>
					<div className="resort_coulmn"></div>
				</th>
				<th colSpan={3} className="text_center">
					{t('eventList.events')}
				</th>
				<th>{t('eventList.tournament')}</th>
				<th>{t('eventList.category')}</th>
				<th className="text_center">{t('eventList.sport')}</th>
			</tr>
		</thead>
	);
};

interface RowProps {
	item: EventItemExtended;
	check: (tournament: EventItemExtended) => void;
}

export const TableRow: FC<RowProps> = ({ item, check }) => {
	const { competitors, sport, category, tournament } = item;
	const [{ name: homeName }, { name: awayName } = { name: '' }] = competitors || [{}, {}];

	return (
		<tr data-testid="body-row">
			<td className="">
				<div className="checkbox_custom chek_only tooltip_right">
					<input type="checkbox" checked={false} onChange={() => {}} onClick={() => check(item)} />
					<i className="mdi mdi-checkbox-blank-outline unchecked" />
					<i className="mdi mdi-checkbox-marked checked" />
					<span className="tooltip_content">{'select'}</span>
				</div>
			</td>
			<td className="font_700 text_right" onClick={() => check(item)}>
				{homeName}
			</td>
			<td className="text_center vs" onClick={() => check(item)}>
				{awayName && 'vs'}
			</td>
			<td className="font_700 " onClick={() => check(item)}>
				{awayName}
			</td>
			<td>{tournament.name}</td>
			<td>{category.name}</td>
			<td className="text_center">
				<div className="svg_icon tooltip_left">
					<svg className="e_ico">
						<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${sport.id}`} />
					</svg>
					<span className="tooltip_content">{sport.name}</span>
				</div>
			</td>
		</tr>
	);
};

interface SelectedRowProps {
	item: SelectedEvent;
	uncheck: (id: string) => void;
}

export const SelectedTableRow: FC<SelectedRowProps> = ({ item, uncheck }) => {
	const { id, competitors, tournament, sport, category } = item;
	const [{ name: homeName }, { name: awayName } = { name: '' }] = competitors || [{}, {}];

	return (
		<tr data-testid="body-row">
			<td className="">
				<div className="checkbox_custom chek_only tooltip_right">
					<input type="checkbox" checked={true} onChange={() => {}} onClick={() => uncheck(id)} />
					<i className="mdi mdi-checkbox-blank-outline unchecked" />
					<i className="mdi mdi-checkbox-marked checked" />
					<span className="tooltip_content">{'select'}</span>
				</div>
			</td>
			<td className="font_700 text_right" onClick={() => uncheck(id)}>
				{homeName}
			</td>
			<td className="text_center vs" onClick={() => uncheck(id)}>
				{awayName && 'vs'}
			</td>
			<td className="font_700 " onClick={() => uncheck(id)}>
				{awayName}
			</td>
			<td>{tournament.defaultName}</td>
			<td>{category.name}</td>
			<td className="text_center">
				<div className="svg_icon tooltip_left">
					<svg className="e_ico">
						<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${sport.id}`} />
					</svg>
					<span className="tooltip_content">{sport.name}</span>
				</div>
			</td>
		</tr>
	);
};
