import React, { useState, useEffect, useMemo, FC } from 'react';
import api from '../../../../../../api';
import { useGeo } from '../../../context';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../../../../components/layout';
import Modal from '../../../../../../components/Modals/index';
import { useNotify } from '../../../../../../hooks/useNotifications';
import { SimpleLoader } from '../../../../../../components/general/Loader';
import { prepareFiltersDefaultState, useReferenceModalFilters } from './useModalFilters';
import { ModalHeaderFilters } from './ModalHeaderFilters';

interface Props {
	close: () => void;
	specifics: any;
	rowItem: { id: string; value: any; inherited: any };
}

export const ReferenceModal: FC<Props> = ({ specifics, close, rowItem }) => {
	const { t } = useTranslation();
	const { warn, notify } = useNotify();

	const [loading, setLoading] = useState(true);
	const { geoParameters, updateGeoParameters } = useGeo();

	const { id, value, inherited } = rowItem;
	const { value_rule, delimiter, uri, headers, data_type } = specifics;

	const { filters, dispatch, prepareFiltersForApi } = useReferenceModalFilters(
		prepareFiltersDefaultState(specifics.filters)
	);

	const [filtersList, setFiltersList] = useState<any>({});

	const oneAllowed = data_type === 'modal';
	const disabledList = value_rule === 'APPEND' && delimiter ? (inherited ? inherited.split(delimiter) : []) : [];

	const [items, setItems] = useState<any[]>([]);
	const [selectedItems, setSelectedItems] = useState<any[]>([]);

	useEffect(() => {
		(async function getData() {
			try {
				const { values, filterValues } = (await api.geo.getReferenceFieldData(
					{ idGeoEntity: geoParameters.id },
					uri
				)) as any;
				setItems(values);
				setFiltersList(filterValues);
				const selectedIds = value?.split(delimiter) || [];

				const selected = values.filter(
					(item: any) =>
						selectedIds.some((refField: string) => item.refField === refField) ||
						disabledList.some((refField: string) => item.refField === refField)
				);
				setSelectedItems(selected);
				setLoading(false);
			} catch (err: any) {
				let errorText = t('toast.error.defaultText');
				if (err.response && err.response.data)
					errorText = err.response.data.detail[0].msg || err.response.data.detail;

				notify(errorText, 'error', { autoClose: 3000, style: { backgroundColor: '#fd9800' } });

				setTimeout(() => {
					close();
				}, 500);
			}
		})();
	}, []);

	const filterItems = async () => {
		try {
			const { values } = (await api.geo.getReferenceFieldData(
				{ idGeoEntity: geoParameters.id, filters: prepareFiltersForApi() },
				uri
			)) as any;
			setItems(values);
		} catch (err: any) {
			let errorText = t('toast.error.defaultText');
			if (err.response && err.response.data) errorText = err.response.data.detail[0].msg || err.response.data.detail;

			notify(errorText, 'error', { autoClose: 3000, style: { backgroundColor: '#fd9800' } });
		}
	};

	const filteredItems = useMemo(() => {
		if (!items || !items.length) return [];
		return items.filter((item) => !selectedItems.some(({ refField }) => item.refField === refField));
	}, [items, selectedItems]);

	const check = (item: any) => {
		const selectedArray = selectedItems.filter((item) => !disabledList.includes(item.refField));
		if (selectedArray.length && oneAllowed) return warn('toast.warn.oneAllowed');
		setSelectedItems([...selectedItems, item]);
	};

	const uncheck = (refField: string) => {
		setSelectedItems((prev) => prev.filter((item) => item.refField !== refField));
	};

	const handleVerify = async () => {
		try {
			let param = selectedItems.filter((item) => !disabledList.includes(item.refField));

			param = param.length
				? oneAllowed
					? param[0].refField
					: param.map(({ refField }) => refField).join(delimiter)
				: null;

			await api.geo.verifyReferenceFieldData({ idGeoEntity: geoParameters.id, refFields: param }, uri);

			updateGeoParameters(id, param);
			close();
		} catch (err: any) {
			let errorText = t('toast.error.defaultText');
			if (err.response && err.response.data) errorText = err.response.data.detail[0].msg || err.response.data.detail;
			notify(errorText, 'error', { autoClose: 3000, style: { backgroundColor: '#fd9800' } });
		}
	};

	return (
		<Modal
			size="xxl"
			opened={true}
			align={'start'}
			direction={'row'}
			zIndex={'over_3'}
			bodyClass="display_block_V"
			header={
				<Header
					panel
					leftTool={
						!loading ? (
							<ModalHeaderFilters filters={filters} filtersList={filtersList} dispatch={dispatch} />
						) : undefined
					}
				>
					<button onClick={filterItems} className="btn lg blue full_w margin_0">
						{t('general.filter')}
					</button>
				</Header>
			}
			body={
				<SimpleLoader loaded={!loading}>
					<table className="admin_table  full_w ">
						<TableHeader headers={headers} />
						<tbody className="t_body">
							{selectedItems.map((selected) => (
								<SelectedTableRow
									key={selected.refField}
									item={selected}
									uncheck={uncheck}
									headers={headers}
									disabledList={disabledList}
								/>
							))}

							{selectedItems.length ? (
								<tr className="text_right" style={{ backgroundColor: '#4E6375' }}>
									<td width={100} className=""></td>
									{headers.map((header: any, i: number) => (
										<td key={`divider:${header.attribute}:${i}`} className="text_center"></td>
									))}
								</tr>
							) : null}

							{items && items.length
								? filteredItems.map((item) => (
										<TableRow key={item.refField} item={item} check={check} headers={headers} />
								  ))
								: null}
						</tbody>
					</table>
				</SimpleLoader>
			}
			footer={
				<>
					<button className="btn md round outline" onClick={() => close()}>
						{t('general.cancel')}
					</button>

					<button type="submit" className="btn md round green" onClick={handleVerify}>
						{t('general.save')}
					</button>
				</>
			}
		/>
	);
};

const TableHeader: FC<{ headers: any }> = ({ headers }) => {
	return (
		<thead className="t_head">
			<tr data-testid="head-row">
				<th>
					<div className="resort_coulmn"></div>
				</th>
				{headers.map((header: any, i: number) => (
					<th key={`header:${header.display}:${i}`} className="text_center">
						{header.display}
					</th>
				))}
			</tr>
		</thead>
	);
};

interface RowProps {
	item: any;
	check: (item: any) => void;
	headers: any;
}

export const TableRow: FC<RowProps> = ({ item, check, headers }) => {
	const { t } = useTranslation();
	return (
		<tr data-testid="body-row">
			<td className="" width={100}>
				<div className="checkbox_custom chek_only tooltip_right">
					<input type="checkbox" checked={false} onChange={() => {}} onClick={() => check(item)} />
					<i className="mdi mdi-checkbox-blank-outline unchecked" />
					<i className="mdi mdi-checkbox-marked checked" />
					<span className="tooltip_content">{t('general.check')}</span>
				</div>
			</td>
			{headers.map((header: any, i: number) => (
				<td key={`items:${header.attribute}:${i}`} className="text_center">
					{item[header.attribute]}
				</td>
			))}
		</tr>
	);
};

interface SelectedRowProps {
	item: any;
	uncheck: (refField: string) => void;
	headers: any;
	disabledList: string[];
}

export const SelectedTableRow: FC<SelectedRowProps> = ({ item, uncheck, headers, disabledList }) => {
	const { t } = useTranslation();
	const disabled = disabledList.includes(item['refField']) ? 'disabled' : '';
	return (
		<tr data-testid="body-row">
			<td className={disabled} width={100}>
				<div className="checkbox_custom chek_only tooltip_right">
					<input type="checkbox" checked={true} onChange={() => {}} onClick={() => uncheck(item.refField)} />
					<i className="mdi mdi-checkbox-blank-outline unchecked" />
					<i className="mdi mdi-checkbox-marked checked" />
					<span className="tooltip_content">{t('general.uncheck')}</span>
				</div>
			</td>
			{headers.map((header: any, i: number) => (
				<td key={`selected:${header.attribute}:${i}`} className="text_center">
					{item[header.attribute]}
				</td>
			))}
		</tr>
	);
};
