import { Item } from './types';

/**
 * Takes a list of items and the path to the item
 * and returns item with provided path from the list
 * 
 * @param { Item[] } items List of tree items
 * @param { string } path Path to the item
 * @returns { Item }
 */
export default (items: Item[], path: string) => {
	// Split the path into the array of keys
	const keys = path.split('.');

	// Iterate through the items using keys array and return wanted item
	return keys.reduce((itemsOrItem: any, key: string) => {
		if (Array.isArray(itemsOrItem)) return itemsOrItem[+key];
		else return itemsOrItem.items[+key];
	}, items);
};
