import {
	MixEvent,
	MixSpecial,
	MixSpecialModel,
} from '../../pages/prematch/controller/components/OddsController/Specials/SpecialMix/types';
import { axiosInstance } from '../config';

interface ActivationProps {
	idParentEvent: string;
	idOfferPlan: string;
	mixEventIds: string[];
	active: boolean;
}

export const mixSpecial = {
	getMixModel(idParentEvent: string, idOfferPlan?: string, lang: string = 'en', platform: string = 'web'): Promise<MixSpecialModel> {
		return axiosInstance
			.get(`/administration/specials/mix/models?idParentEvent=${idParentEvent}&lang=${lang}&platform=${platform}&idOfferPlan=${idOfferPlan || ''}`)
			.then((res) => res.data);
	},

	getMixSpecials(idParentEvent: string, idOfferPlan?: string): Promise<MixEvent[]> {
		return axiosInstance
			.get(`/administration/specials/mix?idParentEvent=${idParentEvent}&idOfferPlan=${idOfferPlan || ''}`)
			.then((res) => res.data.events);
	},

	create(props: MixSpecial) {
		return axiosInstance.post('/administration/specials/mix', props).then((res) => res.data);
	},

	update(props: MixSpecial) {
		return axiosInstance.put('/administration/specials/mix', props).then((res) => res.data);
	},

	activate(props: ActivationProps): Promise<MixEvent[]> {
		return axiosInstance.post('/administration/specials/mix/active', props).then((res) => res.data.events);
	},
};
