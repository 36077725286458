import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

interface Props {
	keyboardCode: string;
	deleteCode: (name: string) => void;
}

export const KeyboardCodeItem: FC<Props> = ({ keyboardCode, deleteCode }) => {
	const { t } = useTranslation();

	return (
		<>
			<div className="col-6 text_center padding_bottom_20 align_center  padding_left_25 padding_right_25">
				<div>{keyboardCode}</div>
				<span
					className="mdi mdi-close tooltip_left text_redish small"
					onClick={() => deleteCode(keyboardCode)}
					style={{ display: 'block', marginLeft: 'auto' }}
				>
					<span className="tooltip_content redish">{t('marketsAdministrations.deleteCode')}</span>
				</span>
			</div>
		</>
	);
};
