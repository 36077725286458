import { useEffect, useState } from 'react';
import io from 'socket.io-client';
const msgParser = require('socket.io-msgpack-parser');

export function useSocketInstance(
	useMsgParser: boolean,
	link: string,
	path: string = '',
	name: string = '',
	transports: string[] | null = null
) {
	const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null);

	const [connected, setConnected] = useState(false);
	const additionalSettings = useMsgParser ? { parser: msgParser } : {};
	console.log('useMsgParser :>> ', useMsgParser);
	console.log('link :>> ', link);
	let parameters: {
		reconnection: boolean;
		path: string;
		transports?: string[];
		additionalSettings?: {};
	} = {
		reconnection: false,
		path,
		...additionalSettings,
	};

	if (transports) {
		parameters = {
			...parameters,
			transports,
		};
	}

	const initSocket = () => {
		const newSocket = io(link, parameters);
		newSocket.on('disconnect', (reason: string) => {
			console.log(`${name} Socket disconnected. Reason: ${reason} - ${new Date()}`);
			if (reason != 'io client disconnect') setTimeout(() => initSocket(), 500);
			setConnected(false);
		});

		newSocket.on('connect', () => {
			console.log(`${name} Socket connected! - ${new Date()}`);
			setConnected(true);
		});

		newSocket.on('connect_error', (error = '') => {
			console.log(`${name} Socket connect error: ${error} \n Socket will reconnect! - ${new Date()}`);
			setTimeout(() => initSocket(), 500);
			setConnected(false);
		});

		newSocket.on('error', (error = '') => {
			console.log(`${name} Socket error! ${error} - ${new Date()}`);
		});

		newSocket.on('close', () => {
			console.log(`${name} Socket closed! - ${new Date()}`);
		});

		newSocket.on('reconnect', () => {
			console.log(`${name} Socket reconnecting! - ${new Date()}`);
		});
		setSocket(newSocket);
	};

	useEffect(() => {
		initSocket();
	}, []);

	return {
		socket,
		connected,
	};
}
