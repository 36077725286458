// Import config
import { axiosInstance } from './../config';
// Import utils
import createMultipart from '../../pages/administration/utils/createMultipart';
// Import types
import { CategoryItem } from '../../pages/administration/categories/types';

export const categories = {
	getCategories(
		sportId: string = '',
		limit: number = 1000,
		skip: number = 0,
		q: string = '',
		connected: boolean = false,
		is_checked: boolean = false,
		id_offer_plan?: string
	) {
		return axiosInstance
			.get(`/administration/categories/`, {
				params: { limit, sportId, skip, q, connected, is_checked, id_offer_plan },
			})
			.then((res) => res.data);
	},
	createCategory(category: CategoryItem, sportID: string): Promise<CategoryItem> {
		return axiosInstance
			.post(`/administration/categories/?sport_id=${sportID}`, createMultipart(category), {
				headers: {
					'content-type': 'multipart/form-data',
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	updateCategory(category: CategoryItem): Promise<CategoryItem> {
		return axiosInstance
			.put(`/administration/categories/` + category.id, createMultipart(category), {
				headers: {
					'content-type': 'multipart/form-data',
				},
			})
			.then((res) => {
				return res.data;
			});
	},
};
