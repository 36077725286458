import React, { FC, useEffect } from 'react';
import { Loader } from '../../../components/general/Loader';
import { useLayout } from '../../../components/layout';
import { useOnlineUser } from '../context/OnlineUserContext';
import { Details } from './details';
import Limits from './limits';
import { Notifications } from './notifications';
import { Parameters } from './parameters';
import { Tickets } from './tickets';
import { Transactions } from './transactions';
import { Wallets } from './wallets';
import { Bonuses } from './bonuses';
import { Documents } from './documents';
import Loging from './loging';

export const MainContent: FC = () => {
	const { selectedTab, loaded } = useOnlineUser();
	const { toggleSidebar } = useLayout();

	useEffect(() => {
		toggleSidebar();
	}, []);

	const component = () => {
		switch (selectedTab) {
			case 'limits':
				return <Limits />;
			case 'notifications':
				return <Notifications />;
			case 'parameters':
				return <Parameters />;
			case 'tickets':
				return <Tickets />;
			case 'transactions':
				return <Transactions />;
			case 'wallets':
				return <Wallets />;
			case 'bonuses':
				return <Bonuses />;
			case 'documents':
				return <Documents />;
			case 'loging':
				return <Loging />;
			default:
				return <Details />;
		}
	};

	return <Loader loaded={loaded}>{component()}</Loader>;
};
