import React, { FC, useState, useEffect, useContext, createContext } from 'react';
import { MarketItemMap, MarketsMap, OddTypeItem } from '../types/markets';
import api from '../api';
import { Provider } from '../pages/system/integrations/types';
import { LocalisationExtended, LocalisationMap } from '../pages/administration/Translations/types';
import { Tag } from '../pages/risk-management/tags/types';
import { riskGroup } from '../pages/risk-management/risk-groups/types';
import { cloneDeep } from 'lodash';

export interface DataContextProps {
	marketsMap: MarketsMap;
	defaultMarketsMap: MarketsMap;
	providersMap: Provider[];
	localisationMap: LocalisationMap;
	eventStatuses: string[];
	riskTags: Tag[];
	setRiskTags: (tags: Tag[]) => void;
	riskGroups: riskGroup[];
	favoriteTips: {};
	getTips: (sportId: string) => string[];
	cityTimeZone: {
		id: string;
		name: string;
	}[];
}

const dataContextDefaultValues: DataContextProps = {
	marketsMap: {},
	defaultMarketsMap: {},
	providersMap: [],
	localisationMap: { regular: {}, extended: {} } as LocalisationMap,
	eventStatuses: [],
	riskTags: [],
	setRiskTags: () => {},
	riskGroups: [],
	favoriteTips: {},
	getTips: (sportId: string) => [],
	cityTimeZone: [],
};

const DataContext = createContext(dataContextDefaultValues);

const DataProvider: FC = (props) => {
	const [marketsMap, setMarketsMap] = useState({} as MarketsMap);
	const [defaultMarketsMap, setDefaultMarketsMap] = useState({} as MarketsMap);
	const [favoriteTips, setFavoriteTips] = useState({});

	const [providersMap, setProviders] = useState<Provider[]>([]);
	const [localisationMap, setLocalisationMap] = useState({ regular: {}, extended: {} } as LocalisationMap);
	const [eventStatuses, setEventStatuses] = useState<string[]>([]);
	const [riskTags, setRiskTags] = useState<Tag[]>([]);
	const [riskGroups, setRiskGroups] = useState<riskGroup[]>([]);
	const [cityTimeZone, setCityTimeZone] = useState(
		[] as {
			id: string;
			name: string;
		}[]
	);

	const mapForDropdown = (items: string[]) => {
		return items.map((name) => ({ id: name, name: name.split('_').join(' ') }));
	};

	useEffect(() => {
		(async function getData() {
			try {
				const { regular, extended } = await api.translations.getLocalisationMap();

				setLocalisationMap({ regular, extended: withoutValues(extended) });

				const { riskTags } = await api.risk.getTags();
				setRiskTags(riskTags);

				const riskGroups = await api.risk_groups.getAllRiskGroups();
				setRiskGroups(riskGroups);

				const providerData = await api.integrations.getProviders();
				const marketsData = await api.risk.getMappedMarkets();
				const eventStatuses = await api.events.statuses();
				const cityTimeZoneArray = await api.timezones.getTimeZones();

				const sityZonesMap = mapForDropdown(cityTimeZoneArray);

				setCityTimeZone(sityZonesMap);

				setMarketsMap(marketsData);

				let tempMarketsData = cloneDeep(marketsData);

				for (let key1 in tempMarketsData) {
					let markets = tempMarketsData[key1]?.markets?.markets;

					for (let key2 in markets) {
						let market = markets[key2];
						if (market.default === false) {
							delete tempMarketsData[key1].markets.markets[key2];
						}
					}
				}

				for (let sport in tempMarketsData) {
					const sportMarkets = tempMarketsData[sport]?.markets?.markets as MarketItemMap;

					const marketsDataOrderArr = Object.values(sportMarkets)
						.filter((markets: any) => markets.id.startsWith('o'))
						.sort((a: any, b: any) => a.order - b.order)
						.slice(0, 3);

					const oddtypes: string[] = [];
					const oddtypeNames: string[] = [];

					for (const marketOdds in marketsDataOrderArr) {
						const marketOddTypes = Object.values(marketsDataOrderArr[marketOdds].oddtypes).sort(
							(a: any, b: any) => a.order - b.order
						);

						marketOddTypes.forEach((oddType) => {
							const oddTypeObject = oddType as OddTypeItem;

							if (oddTypeObject.id.startsWith('o')) {
								oddtypes.push(oddTypeObject.id);
								oddtypeNames.push(oddTypeObject.name);
							}
						});
					}

					favoriteTips[sport] = {
						oddtypes,
						oddtypeNames,
					};
				}
				(favoriteTips['all'] = {
					oddtypes: [
						'owp:fs:1',
						'owp:fs:X',
						'owp:fs:2',
						'orb:fs:1',
						'orb:fs:2',
						'obm:fs:1',
						'obm:fs:2',
						'omm:fs:1',
						'omm:wm:X',
						'omm:fs:2',
						'obx:fs:1',
						'obx:wm:X',
						'obx:fs:2',
						'obs:fs:1',
						'obs:fs:2',
						'oaf:fs:1',
						'oaf:fs:2',
						'odt:fs:1',
						'odt:fs:2',
						'osn:fs:1',
						'osn:fs:2',
						'ott:fs:1',
						'ott:fs:2',
						'ovb:fs:1',
						'ovb:fs:2',
						'ohb:fs:1',
						'ohb:fs:X',
						'ohb:fs:2',
						'oih:fs:1',
						'oih:fs:X',
						'oih:fs:2',
						'otn:fs:1',
						'otn:fs:2',
						'obb:fs:1',
						'obb:fs:X',
						'obb:fs:2',
						'oft:fs:1',
						'oft:fs:X',
						'oft:fs:2',
						'ofb:fs:1',
						'ofb:fs:X',
						'ofb:fs:2',
					],
					oddtypeNames: ['1', 'X', '2'],
				}),
					setDefaultMarketsMap(tempMarketsData);

				setProviders(providerData);
				setEventStatuses(eventStatuses);
			} catch (err) {
				console.error(err);
			}
		})();
	}, []);

	// Remove unesesary "string" from extended locale data
	const withoutValues = (locale: LocalisationExtended) => {
		let extended = {} as LocalisationExtended;
		for (let lang in locale) {
			let item = {};
			for (let media in locale[lang]) {
				item[media] = '';
			}
			extended[lang] = item;
		}
		return extended;
	};

	const getTips = (sportId: string): string[] => {
		if (!sportId)
			return Object.values(favoriteTips)
				.map(({ oddtypes }: any) => oddtypes)
				.flat();
		else return favoriteTips[sportId]?.oddtypes || [sportId];
	};

	return (
		<DataContext.Provider
			value={{
				marketsMap,
				defaultMarketsMap,
				providersMap,
				localisationMap,
				eventStatuses,
				riskTags,
				setRiskTags,
				riskGroups,
				favoriteTips,
				getTips,
				cityTimeZone,
			}}
			{...props}
		/>
	);
};

const useData = () => useContext(DataContext);

export { DataProvider, useData };
