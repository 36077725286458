import React, { FC, useMemo, SetStateAction, Dispatch } from 'react';
import MultiSelectDropdown from '../../../../../components/general/MultiSelectDropdown';
import { useTranslation } from 'react-i18next';

interface Props {
	selectedTransactionTypes: string[];
	selectTransactionTypes: (types: string[]) => void;
	transactionTypes: string[];
}

export const TransactionTypesDropdown: FC<Props> = ({
	selectedTransactionTypes,
	selectTransactionTypes,
	transactionTypes,
}) => {
	const { t } = useTranslation();

	const options = useMemo(() => transactionTypes.map((type) => ({ id: type, name: type })), [transactionTypes]);
	return (
		<MultiSelectDropdown
			title={t('transactions.type')}
			marginRight={'10'}
			options={options}
			selectedOptions={selectedTransactionTypes}
			selectOption={selectTransactionTypes}
			withSearch
		/>
	);
};
