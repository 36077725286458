import React, { FC, useEffect, useState, useMemo } from 'react';
import api from '../../../api';
import { UserSum } from '../users/types';
import { Table } from './components/Table';
import { useFilter } from './hooks/useFilters';
import { useTranslation } from 'react-i18next';
import { UserInfo } from './components/UserInfo';
import { FooterSum } from './components/FooterSum';
import { formatDate } from '../../../utils/getDate';
import usePagination from '../../../hooks/usePagination';
import { LocationsDropdown } from './components/Filters';
import { Application, Transaction, TransactionsFilters } from './types';
import { DateRange } from '../../../components/Dates/DateRange';
import { Search, useSearch } from '../../../components/Search';
import { AmountFilter } from './components/Filters/AmountFilter';
import { ProvidersDropdown } from './components/Filters/ProvidersDropdown';
import { Header, PageContentWrapper, PageFooter, useLayout } from '../../../components/layout';
import { FilterOptions, TransactionTypesDropdown, TransactionSubTypesDropdown } from './components/Filters';
import { useNotify } from '../../../hooks/useNotifications';
import MultiSelectDropdown from '../../../components/general/MultiSelectDropdown';
import { FooterDropdown } from '../../risk-management/controller/components/general';
import { emailIsValid } from '../../../utils/validateEmail';
import { Switch } from '../../../components/general/Switch';
import { Loader, useLoaded } from '../../../components/general/Loader';
import { Height } from '../../administration/live/components/Height';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import { INTERNET_TRANSACTIONS } from '../../../consts/userConfig';
import { UserConfigProvider, useUserConfig } from '../../../context/UserConfigProvider';
import { wrapComponent } from '../../../utils/wrapComponent';
import getErrorText from '../../../utils/getErrorMsg';
import { TicketItemExtended } from '../../reports/tickets/types';
import { TicketModal } from '../../../components/Modals/TicketModal';
import { useData } from '../../../context/DataProvider';
import Dropdown from '../../../components/general/Dropdown';

interface Props {
	userEmail?: string;
	setSums?: (sums: any) => void;
	// multi names for the same cell in table header - pass name of the cell
	// data to show: username or ticketID
	showUserName?: boolean;
}

const Transactions: FC<Props> = ({ userEmail = '', setSums = () => {}, children, showUserName = true }) => {
	const { t } = useTranslation();
	const { cityTimeZone } = useData();

	const [transactions, setTransactions] = useState<Transaction[]>([]);
	const [transactionsFilters, setTransactionsFilters] = useState({} as TransactionsFilters);
	const [userSum, setUserSum] = useState({} as UserSum);
	const [loaded, setLoaded] = useState(false);
	const [selectedTicket, setSelectedTicket] = useState<TicketItemExtended | null>(null);

	// Filters
	const { accountTypes, toggleType } = useFilter();

	const [filterOpen, setFilterOpen] = useState(false);
	const toggleFilter = () => {
		setFilterOpen((filter) => !filter);
	};

	const { closeSidebar } = useLayout();

	const [selectedLocations, selectLocations] = useState<string[]>([]);
	const [selectedTransactionTypes, selectTransactionTypes] = useState<string[]>([]);
	const [selectedTransactionSubTypes, selectTransactionSubTypes] = useState<string[]>([]);
	const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
	const [selectedIssuers, setSelectedIssuers] = useState<string[]>([]);
	const [selectedApps, setSelectedApps] = useState<string[]>([]);

	const [totalIn, setTotalIn] = useState(0);
	const [totalOut, setTotalOut] = useState(0);

	const [amountIn, setAmountIn] = useState('');
	const [amountOut, setAmountOut] = useState('');
	const [selectedAmountInSign, selectAmountInSign] = useState('more');
	const [selectedAmountOutSign, selectAmountOutSign] = useState('less');

	const [email, setEmail] = useState<string>(userEmail);
	const searchOptions = useSearch(setEmail, '', true);
	const [transId, setTranasId] = useState('');
	const searchOptionsTransactionId = useSearch(setTranasId, '', true);

	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);

	const [timeZone, setTimeZone] = useState('Europe/Belgrade');

	// Default table sort
	const [orderBy, setOrderBy] = useState('createdDesc');
	const [searched, setSearched] = useState(false);

	// Hide-Show null transactions
	const [excludeZeroTransactions, setExcludeZeroTransactions] = useState(true);

	const toggleShowNullTransactions = () => {
		setExcludeZeroTransactions((excludeZeroTransactions) => !excludeZeroTransactions);
	};

	// Pagination
	const itemsPerPage = userEmail ? 15 : 20;
	const { from, setTotal, perPage, setPerPage, ...pagination } = usePagination(itemsPerPage);

	const { error } = useNotify();

	useEffect(() => {
		closeSidebar();
		userEmail && setPerPage(itemsPerPage);
		// Cash filter lists to use in dropdowns
		(async function getTransactionsFilters() {
			try {
				setLoaded(false);
				const data = await api.transactions.getTransactionsFilters();
				setTransactionsFilters(data);
				selectTransactionTypes(['all', ...data.type]);
				selectTransactionSubTypes(['all', ...data.subtype]);
				setSelectedIssuers(['all', ...data.issuer]);
				setSelectedProviders(['all', ...data.providers.map((item: any) => item.id)]);
			} catch (err) {
				error(getErrorText(err) || 'toast.error.defaultText');
			} finally {
				setLoaded(true);
			}
		})();
	}, []);

	const getTransactions = async () => {
		const isValidEmail = emailIsValid(email.trim());
		let transactionId: string = '';

		try {
			setLoaded(false);
			if (isValidEmail && !userEmail) {
				const userData = await api.transactions.getUserByEmail(email.trim());
				setUserSum(userData);
			} else {
				setUserSum({} as UserSum);
				transactionId = userEmail ? transId : email;
			}

			const dateFrom = startDate
				? formatDate(`${startDate.toISOString().slice(0, -1)}`, 'year-month-date')?.find((date) => date)
				: null;
			const dateTo = endDate
				? formatDate(`${endDate.toISOString().slice(0, -1)}`, 'year-month-date')?.find((date) => date)
				: null;

			const transactionType = selectedTransactionTypes.includes('all') ? null : selectedTransactionTypes;
			const transactionSubtype = selectedTransactionSubTypes.includes('all') ? null : selectedTransactionSubTypes;
			const transactionIssuer = selectedIssuers.includes('all') ? null : selectedIssuers;
			const externalProviders = selectedProviders.includes('all') ? null : selectedProviders;
			const externalProviderApps = selectedApps.filter((item) => item !== 'all');

			const locations = selectedLocations.includes('all') ? null : selectedLocations;

			const amountFiltersIn =
				selectedAmountInSign === 'less'
					? { from: '', to: amountIn }
					: selectedAmountInSign === 'more'
					? { from: amountIn, to: '' }
					: { from: amountIn, to: amountIn };

			const amountFiltersOut =
				selectedAmountOutSign === 'less'
					? { from: '', to: amountOut }
					: selectedAmountOutSign === 'more'
					? { from: amountOut, to: '' }
					: { from: amountOut, to: amountOut };

			const {
				total,
				transactions,
				amountIn: totalIn,
				amountOut: totalOut,
				sums,
			} = await api.transactions.getTransactions({
				dateFrom,
				dateTo,
				transactionType,
				transactionSubtype,
				externalProviders,
				externalProviderApps,
				transactionIssuer,
				locations,
				accountTypes: activeAccountTypes(),
				skip: from,
				limit: perPage,
				inFrom: (amountIn && amountFiltersIn.from) || null,
				inTo: (amountIn && amountFiltersIn.to) || null,
				outFrom: (amountOut && amountFiltersOut.from) || null,
				outTo: (amountOut && amountFiltersOut.to) || null,
				email: userEmail ? email : !transactionId ? email.trim() : '',
				sortBy: [orderBy],
				is_user_preview: !!userEmail,
				transactionId: userEmail ? transId : transactionId,
				excludeZeroTransactions: excludeZeroTransactions,
				timezone: timeZone,
			});

			if (userEmail) {
				setSums(sums);
			} else {
				setTotalIn(totalIn);
				setTotalOut(totalOut);
			}
			setTransactions(transactions);
			setTotal(total);
			setSearched(true);
		} catch (err) {
			error(getErrorText(err) || 'toast.error.defaultText', { position: 'top-center' });
		} finally {
			setLoaded(true);
		}
	};

	useEffect(() => {
		if (userEmail && perPage > 15) return;
		// Get new transactions when we click pagination number, but prevent this hook execute on page load
		if (transactions.length || userEmail) getTransactions();
	}, [excludeZeroTransactions, from, orderBy, perPage]);

	// Helper method to make list of all active account types that will be passed as filters
	const activeAccountTypes = () => {
		let typesArray = [];
		for (const key in accountTypes) {
			if (accountTypes[key].active) typesArray.push(key);
		}
		return typesArray;
	};

	const appsItems = useMemo(() => {
		if (!transactionsFilters.providers) return [];
		setSelectedApps([]);
		let apps: Application[] = [];
		selectedProviders.forEach((externalProvider) => {
			if (externalProvider === 'all') return;
			const provider = transactionsFilters.providers.find((item) => item.id === externalProvider)!;
			apps = [...apps, ...provider.applications];
		});
		return apps;
	}, [selectedProviders]);

	const isMobileView = useCheckMobileScreen();

	const loading = useLoaded([transactions], true);

	const { columns, visibleColumns, setVisibleColumns } = useUserConfig();

	const tools = {
		leftTool: (
			<>
				<span
					className="tool_item tooltip_right margin_right_10 mobile_de_dumb_toggle_trigger"
					onClick={toggleFilter}
				>
					<i className="mdi mdi-tune"></i>
					<span className="tooltip_content">{t('transactions.toggleFilter')}</span>
				</span>
				<FilterOptions title={t('general.account')} options={accountTypes} setFilter={toggleType} />
				{/* <FilterOptions title={t('general.report')} options={reportTypes} setFilter={toggleType} /> */}
				<LocationsDropdown selectedLocations={selectedLocations} selectLocations={selectLocations} />
				<TransactionTypesDropdown
					transactionTypes={transactionsFilters.type || []}
					selectedTransactionTypes={selectedTransactionTypes}
					selectTransactionTypes={selectTransactionTypes}
				/>
				<TransactionSubTypesDropdown
					transactionSubTypes={transactionsFilters.subtype || []}
					selectedTransactionSubTypes={selectedTransactionSubTypes}
					selectTransactionSubTypes={selectTransactionSubTypes}
				/>
				<MultiSelectDropdown
					title={t('transactions.issuer')}
					marginRight={'10'}
					options={transactionsFilters?.issuer?.map((type) => ({ id: type, name: type })) || []}
					selectedOptions={selectedIssuers}
					selectOption={setSelectedIssuers}
					withSearch
				/>
			</>
		),
		rightTool: (
			<>
				<Switch
					checked={excludeZeroTransactions}
					toggle={toggleShowNullTransactions}
					name={t('usersAdministrations.transactions')}
					onLabel={t('usersAdministrations.excludeNullTransactions')}
					offLabel={t('usersAdministrations.includeNullTransactions')}
					className={`margin_right_10 ${loaded ? '' : 'disabled'} `}
				/>
				<DateRange startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
				<Dropdown
					items={cityTimeZone.map(({ id, name }) => ({ id: `${id}`, name }))}
					selectItem={setTimeZone}
					selectedItem={timeZone}
					margins={['0', '0', '0', '4']}
					icon={'mdi mdi-menu-down'}
					withSearch
				/>
				{!userEmail ? (
					<Search
						placeholder={`${t('transactions.userEmail')} / ${t('transactions.id')}`}
						{...searchOptions}
						onEnter={getTransactions}
					/>
				) : (
					<Search
						placeholder={`${t('transactions.id')}`}
						{...searchOptionsTransactionId}
						onEnter={getTransactions}
					/>
				)}
			</>
		),
		customTools: (
			<>
				<div className="filter_section col-2 padding_30 max_width_100">
					<ProvidersDropdown
						providers={transactionsFilters.providers || []}
						selectedProviders={selectedProviders}
						selectProviders={setSelectedProviders}
					/>
				</div>
				<div className="filter_section col-2 padding_30 max_width_100">
					<MultiSelectDropdown
						title={t('transactions.gameApps')}
						marginRight={'10'}
						options={appsItems}
						selectedOptions={selectedApps}
						selectOption={setSelectedApps}
						withSearch
					/>
				</div>
				<div className="filter_section col-2 padding_30 max_width_100">
					<AmountFilter
						key="in"
						selectedSign={selectedAmountInSign}
						selectSign={selectAmountInSign}
						amount={amountIn}
						setAmount={setAmountIn}
						placeholder="IN"
						disabled={!!amountOut}
						onEnter={getTransactions}
					/>
				</div>

				<div className="filter_section col-2 padding_30 max_width_100">
					<AmountFilter
						key="out"
						selectedSign={selectedAmountOutSign}
						selectSign={selectAmountOutSign}
						amount={amountOut}
						setAmount={setAmountOut}
						placeholder="OUT"
						disabled={!!amountIn}
						onEnter={getTransactions}
					/>
				</div>
				<div className="filter_section col-2 padding_30 max_width_100"></div>
			</>
		),
		buttons: (
			<>
				{!filterOpen && (
					<button onClick={getTransactions} className={`btn md blue ${loaded ? '' : 'disabled'} `}>
						{t('general.search')}
					</button>
				)}
				<MultiSelectDropdown
					title={t('general.display')}
					options={columns}
					selectedOptions={visibleColumns}
					selectOption={setVisibleColumns}
					direction={'right_0'}
				/>
			</>
		),
	};
	return (
		<Loader loaded={loading}>
			{children}
			<div className={'mobile_de_filter_wrapper'}>
				<Header
					widthClass={'w_2200'}
					hideLogo={true}
					leftToolClass={'padding_right_0'}
					leftTool={tools.leftTool}
					rightTool={tools.rightTool}
					customTools={tools.customTools}
				>
					{tools.buttons}
				</Header>
				<div className={`mobile_de_filter w_2200 ${filterOpen ? 'toggle' : ''}`}>
					{tools.customTools}
					<div className="filter_section col-2 padding_30">
						<button
							onClick={() => {
								getTransactions();
								setFilterOpen(false);
							}}
							className={`btn lg blue full_w margin_0 ${loaded ? '' : 'disabled'} `}
						>
							{t('general.search')}
						</button>
					</div>
				</div>
			</div>
			<PageContentWrapper
				flex
				customClasses={userEmail ? 'transactions-table-height-override' : ''}
				customScrollClasses={userEmail ? 'transactions-table-height-override' : ''}
				isMobileView={isMobileView}
				widthClass={'w_2200'}
			>
				{loaded ? (
					<>
						{Object.keys(userSum).length ? <UserInfo userSum={userSum} /> : null}
						<Table
							transactions={transactions}
							selectSort={setOrderBy}
							selectedSort={orderBy}
							searched={searched}
							showUserName={showUserName}
							selectTicket={setSelectedTicket}
						/>
					</>
				) : (
					<Height>
						<Loader />
					</Height>
				)}

				{selectedTicket ? (
					<TicketModal
						selectedTicket={selectedTicket}
						selectTicket={setSelectedTicket}
						screen={'tickets'}
						closeModal={() => {}}
					/>
				) : null}
			</PageContentWrapper>

			<PageFooter
				numberOfItems={!userEmail ? <FooterDropdown perPage={perPage} setPerPage={setPerPage} /> : undefined}
				{...pagination}
			>
				{!userEmail ? <FooterSum amountIn={totalIn} amountOut={totalOut} currency={''} /> : null}
			</PageFooter>
		</Loader>
	);
};

const UserConfigProviderForGlobalTransactions: FC = ({ children }) => {
	return <UserConfigProvider initTable={INTERNET_TRANSACTIONS}>{children}</UserConfigProvider>;
};

export const GlobalTransactions: FC<Props> = (props) => {
	return (
		<UserConfigProvider initTable={INTERNET_TRANSACTIONS}>
			<Transactions {...props} />
		</UserConfigProvider>
	);
};

export default wrapComponent<typeof Transactions>(Transactions, UserConfigProviderForGlobalTransactions);
