import React, {
	FC,
	useState,
	Fragment,
	useEffect,
	useMemo,
	ReactNode,
	FunctionComponentElement,
	Dispatch,
	SetStateAction,
} from 'react';
// import { useTranslation } from 'react-i18next';
import { useData } from '../../../../context/DataProvider';
import Dropdown from '../../../../components/general/Dropdown';
import { Search, useSearch } from '../../../../components/Search';
import { Odd } from './Odd';
import { Divider } from './Divider';
import { SportDropdown } from '../general/SportDropdown';
import { MarketItemType, OddTypeItem } from '../../../../types/markets';
import { useDebounce } from 'use-debounce';
import { isEmpty } from 'lodash';

interface Props {
	middleControls: FunctionComponentElement<ReactNode>;
	rightSide: FunctionComponentElement<ReactNode>;
	selectedOddtypes: OddTypeItem[];
	setSelectedOddtypes: Dispatch<SetStateAction<OddTypeItem[]>>;
	specialIds: string[];
}

export const OddsLists: FC<Props> = ({
	middleControls,
	rightSide,
	selectedOddtypes,
	setSelectedOddtypes,
	specialIds,
}) => {
	const { defaultMarketsMap } = useData();
	const [markets, setMarkets] = useState<MarketItemType[]>([]);
	const [searchValue, setSearchValue] = useState('');
	const [debouncedText] = useDebounce(searchValue.trim(), 600);
	const [selectedSport, selectSport] = useState<string>('');
	const [gameType, setGameType] = useState<'o' | 'l'>('o');
	const searchOptions = useSearch(setSearchValue);

	useEffect(() => {
		if (selectedSport) {
			const filtered =
				defaultMarketsMap && defaultMarketsMap[selectedSport]
					? Object.values(defaultMarketsMap[selectedSport].markets.markets)
					: [];
			setMarkets(filtered);
		}
	}, [selectedSport, defaultMarketsMap]);

	const selectOdd = (odd: OddTypeItem) => {
		setSelectedOddtypes([...selectedOddtypes, odd]);
	};

	const deselectOdd = (odd: OddTypeItem) => {
		const filtered = selectedOddtypes.filter(({ id: oddtypeId }) => oddtypeId !== odd.id);
		setSelectedOddtypes(filtered);
	};

	const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const selected: OddTypeItem[] = [];
			filteredMarkets.forEach(({ oddtypes }) => {
				Object.values(oddtypes).forEach((oddtype) => {
					selected.push({ ...oddtype });
				});
			});
			setSelectedOddtypes(selected);
		} else {
			setSelectedOddtypes([]);
		}
	};

	const filteredMarkets = useMemo(() => {
		const text = debouncedText.trim().toLowerCase();
		const searched = [] as MarketItemType[];
		markets
			.filter((market) => market.id.startsWith(gameType))
			.forEach((market) => {
				const tempOddtypes = {};
				for (const [id, oddtype] of Object.entries(market.oddtypes)) {
					if (
						!specialIds.some((specialId) => specialId === id) &&
						(market.name.toLowerCase().includes(text) ||
							oddtype.name.toLowerCase().includes(text) ||
							oddtype.description.toLowerCase().includes(text))
					) {
						tempOddtypes[id] = oddtype;
					}
				}
				if (!isEmpty(tempOddtypes)) {
					searched.push({ ...market, oddtypes: tempOddtypes });
				}
			});
		return searched;
	}, [markets, gameType, debouncedText, specialIds]);

	return (
		<Fragment>
			<div className="direction_row col_border_bottom">
				<div className="col-6 direction_colum padding_left_20 padding_right_20 padding_top_20">
					<div className="direction_row">
						<div className="checkbox_custom check_left light margin_left_10 width_15_perc">
							<input type="checkbox" id="all_left" step="" onChange={handleAllChange} />
							<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
							<i className="mdi mdi-checkbox-marked checked"></i>
							<label htmlFor="all_left" className="left">
								All
							</label>
						</div>
						<div className="width_15_perc z_9">
							<SportDropdown
								margins={['8', '30', '0', '0']}
								selectItem={(sport) => {
									selectSport(`${sport}`);
								}}
								selectedItem={selectedSport}
								icon="mdi mdi-menu-down"
							/>
						</div>

						<Dropdown
							items={[
								{ id: 'o', name: 'Prematch' },
								{ id: 'l', name: 'Live' },
							]}
							margins={['8', 'auto', '0', '0']}
							selectItem={(type) => {
								setGameType(type as 'o' | 'l');
							}}
							selectedItem={gameType}
							icon={'mdi mdi-menu-down'}
							className={'z_9'}
						/>
						<Search {...searchOptions} />
					</div>
				</div>
			</div>
			<div className="direction_row y_auto_overflow">
				<div className="checkbox_list col-6 direction_colum padding_20 col_border_right y_auto_overflow rtl_direction min_height_oddtypes_list">
					{filteredMarkets &&
						filteredMarkets.map((market) => {
							return (
								<Fragment key={market.id}>
									<Divider marketId={market.id} marketName={market.name} />
									{Object.values(market.oddtypes)
										.sort((a, b) => a.order - b.order)
										.map((oddtype) => {
											return (
												<Odd
													key={oddtype.id}
													id={oddtype.id}
													name={oddtype.name}
													description={oddtype.description}
													order={oddtype.order}
													sportId={selectedSport}
													selected={selectedOddtypes.some((element) => element.id === oddtype.id)}
													selectOdd={selectOdd}
													deselectOdd={deselectOdd}
												/>
											);
										})}
								</Fragment>
							);
						})}
				</div>
				{middleControls}
				<div className="checkbox_list col-6 direction_colum padding_15 padding_left_20 y_auto_overflow min_height_oddtypes_list">
					{rightSide}
				</div>
			</div>
		</Fragment>
	);
};
