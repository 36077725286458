import React, { useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import Dropdown from '../../../components/general/Dropdown';
import Modal from '../../../components/Modals';
import { useAxios } from '../../../hooks/useAxios';
import { RoleTemplate } from '../roles/types';

interface Props {
	close: () => void;
	confirm: (template: RoleTemplate) => void;
}
export const RoleTemplateModal: FC<Props> = ({ close, confirm }) => {
	const { t } = useTranslation();
	const { data: roleTemplates } = useAxios({
		apiFunction: async function getRoleTemplates() {
			return await api.SSO.getRoleTemplates();
		},
		initialData: [],
		dependencies: [],
	});

	useEffect(() => {
		setSelectedTemplate(roleTemplates?.[0]);
	}, [roleTemplates]);

	const [selectedTemplate, setSelectedTemplate] = useState<RoleTemplate | null>(null);

	const selectTemplate = (id: string) => {
		const item = roleTemplates.find((temp) => temp.id === +id)!;
		setSelectedTemplate(item);
	};
	if (!selectedTemplate) return null;
	return (
		<Modal
			opened={true}
			size="sm_360"
			align="start"
			direction="column"
			cancelAction={close}
			zIndex={'over_2'}
			header={<h2 className="modal_title padding_left_20">{t('roles.applyTemplate')}</h2>}
			body={
				<div className="tab_content_wrap">
					<div className="tab_content active">
						<div className="align_center padding_right_50">
							<span className="margin_left_20 ">{t('general.template')}</span>
							<div>
								<Dropdown
									items={roleTemplates.map((temp) => ({ id: `${temp.id}`, name: temp.name }))}
									selectedItem={`${selectedTemplate!.id}`}
									selectItem={selectTemplate}
									listClass={'left_0'}
									margins={['0', '0', '0', '10']}
									icon="mdi mdi-menu-down"
									highlightLabel
								/>
							</div>
						</div>
					</div>
				</div>
			}
			footer={
				<>
					<button className="btn md round outline " onClick={close}>
						{t('general.cancel')}
					</button>
					<button className={`btn md round green`} onClick={() => confirm(selectedTemplate)}>
						{t('general.confirm')}
					</button>
				</>
			}
		/>
	);
};
