import { useMemo } from 'react';
import { useData } from '../../../context/DataProvider';

export function useRiskTag(id: string | null | undefined) {
	const { riskTags } = useData();

	const tag = useMemo(() => {
		return riskTags.find((tag) => tag.code === id);
	}, [id, riskTags]);

	return tag;
}
