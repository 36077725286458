import React, { FormEvent, Fragment } from 'react';

// Local interface for the component
interface Props {
	name: string;
	value: string;
	update: (name: string, event: File | string) => void;
}

export default ({ name, value, update }: Props) => {
	const link = getFileURL(value);

	const setFile = (e: FormEvent<HTMLInputElement>) => {
		update(name, getFile(e));
	};

	return (
		<Fragment>
			{link ? (
				<img src={link} className="td_flag" />
			) : (
				<svg className="e_ico">
					<use xlinkHref="/assets/svg/svg_sprite.svg#17_plus_circle" />
				</svg>
			)}
			<input type="file" accept="image/*" name={name} onChange={setFile} />
		</Fragment>
	);
};

/**
 * Axcepts a file or a string
 * If file is provided returns the URL of that file
 * If string is provided it returns it
 * @param { File | string } file
 * @returns { string }
 */
const getFileURL = (file: File | string) => {
	if (file && typeof file === 'object') {
		return URL.createObjectURL(file);
	} else return file;
};

/**
 * Axcepts an event and returns a file from that event or a string
 * @param { HTMLInputElement | string } file
 * @returns { File | string }
 */
const getFile = (e: FormEvent<HTMLInputElement>) => {
	if (e.currentTarget.files && e.currentTarget.files.length) return e.currentTarget.files[0];
	return '';
};
