import React from 'react';
import { useTranslation } from 'react-i18next';
import { host } from '../../api/host';

export default () => {
	const { t } = useTranslation();

	const handleLogin = () => {
		window.location.href = `${host}/oauth/login`;
	};

	return (
		<div className="login_box">
			<button onClick={handleLogin} className="btn_green btn_full_w ">
				{t('login.signin')}
			</button>
		</div>
	);
};
