export const REQUESTED = 0;
export const PLACED = 1;
export const REFUNDED = 2;
export const INVALID = 5;
export const DELETED = 6;
export const WITHDRAWN = 8;
export const EXPIRED = 9;

export const REQUESTED_AUTH = 0;
export const REJECTED_AUTH = 1;
export const ACCEPTED_AUTH = 2;
export const MODIFIED_AUTH = 3;
export const SYSTEM_CLOSED_AUTH = 6;

export const WITHDRAWN_RES = -1;
export const LOSING_RES = 0;
export const NOT_RES = null;
export const WINNING_RES = 1;
