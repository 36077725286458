import { useEffect, useRef } from 'react';

export function useInterval(callback: any, delay: number | null) {
	const savedCallback = useRef(callback);

	useEffect(() => {
		savedCallback.current = callback;
	});

	useEffect(() => {
		const tick = () => savedCallback.current();

		if (typeof delay === 'number') {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}
