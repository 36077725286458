import { axiosInstance } from '../config';
import { Notification, NotificationEvent } from '../../pages/internet/system-notifications/types';

export const systemNotifications = {
	createNotification(name: string): Promise<Notification> {
		return axiosInstance.post('/administration/notifications/', { name }).then((res) => res.data);
	},

	getNotificationGroups(name: string): Promise<Notification[]> {
		return axiosInstance.post('/administration/notifications/filter/', { name }).then((res) => res.data);
	},

	notificationTypes(): Promise<string[]> {
		return axiosInstance('/administration/notifications/notification_types/').then((res) => res.data);
	},

	updateNotificationGroupName(id: number, name: string) {
		return axiosInstance.put('/administration/notifications/', { id, name }).then((res) => res.data);
	},

	deleteNotificationGroup(id: number) {
		return axiosInstance.delete(`/administration/notifications/${id}`).then((res) => res.data);
	},

	updateNotificationEvent(event: NotificationEvent) {
		return axiosInstance.put(`/administration/notifications/notification_event/`, event).then((res) => res.data);
	},

	getNotificationFields(notificationType: string) {
		return axiosInstance
			.get(`/administration/notifications/notification_fields/${notificationType}`)
			.then((res) => res.data);
	},
};
