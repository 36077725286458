import { DailyExportTemplate } from '../../pages/Lists/daily-offer/types';
import { axiosInstance } from '../config';

interface CreateParams {
	idListExportTemplate: number;
	sportTemplateMap: {
		idSport: string;
		idTemplate: string;
	}[];
	default: boolean;
}

export const timeExport = {
	getTimeTemplates(): Promise<DailyExportTemplate[]> {
		return axiosInstance.get('/lists_export_administration/offer_template/time').then((res) => res.data);
	},

	getTimeTemplate(templateId: number): Promise<DailyExportTemplate> {
		return axiosInstance
			.get(`/lists_export_administration/offer_template/time/${templateId}`)
			.then((res) => res.data);
	},

	createTimeTemplate(params: CreateParams): Promise<DailyExportTemplate> {
		return axiosInstance.post('/lists_export_administration/offer_template/time', params).then((res) => res.data);
	},

	updateTimeTemplate(params: DailyExportTemplate): Promise<DailyExportTemplate> {
		return axiosInstance.put('/lists_export_administration/offer_template/time', params).then((res) => res.data);
	},

	deleteTimeTemplate(templateId: number): Promise<DailyExportTemplate> {
		return axiosInstance
			.delete(`/lists_export_administration/offer_template/time?id=${templateId}`)
			.then((res) => res.data);
	},

	setDefault(templateId: number): Promise<DailyExportTemplate[]> {
		return axiosInstance
			.post(`/lists_export_administration/offer_template/time/set_default?id=${templateId}`)
			.then((res) => res.data);
	},
};
