import React, { FC, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { sortIconHelper, sortTypeHelper } from '../../../../utils/sorting';
import { useData } from '../../../../context/DataProvider';
import { PrematchEvent } from '../types';

interface Props {
	selectedSport: string;
	sortedByCode: string;
	sortByCode: Dispatch<SetStateAction<string>>;
	sortedByStart: string;
	sortByStart: Dispatch<SetStateAction<string>>;
	sortedByTournaments: string;
	sortByTournaments: Dispatch<SetStateAction<string>>;
	selectModalEvent: ((event: PrematchEvent) => void) | undefined;
	disabled?: boolean;
}

export const TableHeader: FC<Props> = ({
	selectedSport,
	sortedByCode,
	sortByCode,
	sortByStart,
	sortedByStart,
	sortedByTournaments,
	sortByTournaments,
	selectModalEvent,
	disabled,
}) => {
	const { t } = useTranslation();
	const sportId = !selectedSport ? 'fb' : selectedSport;
	const { favoriteTips } = useData();

	//Default array in this format is temporary until we have all sports in system
	const { oddtypeNames = ['-', '-', '-'] } = favoriteTips[sportId] || {};

	return (
		<thead className={`t_head ${disabled ? 'disabled' : ''}`}>
			<tr>
				<th scope="col">
					<span className="label display_inline">{t('general.code')}</span>
					<i
						data-testid="resortClass"
						className={`mdi ${sortIconHelper[sortedByCode]}`}
						onClick={() => sortByCode(sortTypeHelper[sortedByCode])}
					/>
				</th>
				<th scope="col">
					<span className="label display_inline">{t('general.start')}</span>
					<i
						data-testid="resortClass"
						className={`mdi ${sortIconHelper[sortedByStart]}`}
						onClick={() => sortByStart(sortTypeHelper[sortedByStart])}
					/>
				</th>

				<th colSpan={3} className="text_center">
					&nbsp;
				</th>
				<th scope="col" className="z_3 text_center">
					{t('general.sport')}
				</th>
				<th scope="col">
					<span className="label display_inline">{t('general.tournament')}</span>
					<i
						data-testid="resortClass"
						className={`mdi ${sortIconHelper[sortedByTournaments]}`}
						onClick={() => sortByTournaments(sortTypeHelper[sortedByTournaments])}
					/>
				</th>
				<th className="z_3">{t('general.status')}</th>

				{!selectModalEvent
					? oddtypeNames.map((name: string, i: number) => <th key={`${name}:${i}`}>{name}</th>)
					: null}

				<th className="z_2" title={t('general.active')}>
					A
				</th>
				<th className="z_2" title={t('general.top')}>
					{t('general.top')}
				</th>
				<th className="z_2">{t('general.flags')}</th>
				<th className="z_4 text_center">{t('general.provider')}</th>
			</tr>
		</thead>
	);
};
