import React, { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
	name: string;
	label: string;
	editable: boolean;
	edit: boolean;
	openEditEmailModal: (showModal: boolean) => void;
	showBtn: boolean;
	newEmail: string;
}

export const TextFieldWithButton: FC<Props> = ({ name, label, editable, edit, openEditEmailModal, showBtn }) => {
	const { register } = useFormContext();
	const { t } = useTranslation();

	return (
		<div className={`form_row ${!(editable && edit) ? 'read_only' : ''}`}>
			<label htmlFor={name} className="input_label col-3 ">
				{label}
			</label>
			<div className="col-6">
				<input
					type="text"
					id={name}
					className="normal focus full_w"
					name={name}
					ref={register}
					disabled={!editable || !edit}
				/>
			</div>
			{showBtn ? (
				<>
					<button className="red btn md outline" type="button" onClick={() => openEditEmailModal(true)}>
						{t('general.updateEmail')}
					</button>
				</>
			) : null}
		</div>
	);
};
