import { PeriodScore } from './types';

const HALFTIME = 'HT';
const THIRD = 'T';
const QUARTER = 'Q';
const SET = 'S';
const INNING = 'I';

const HALFTIME_NAME = 'half';
const THIRD_NAME = 'third';
const QUARTER_NAME = 'quarter';
const SET_NAME = 'set';
const INNING_NAME = 'inning';

export const finalResultCalcParams: FinalResultCalcParams = {
	fb: {
		halfTimeAt: 1,
		withOvertimes: true,
		withPenalties: true,
		regularTimePeriodsUntil: 2,
		calcFinalScore: 'additionOfPeriods',
		period: HALFTIME,
		periodName: HALFTIME_NAME,
	},
	bb: {
		halfTimeAt: 2,
		withOvertimes: true,
		withPenalties: false,
		regularTimePeriodsUntil: 4,
		calcFinalScore: 'additionOfPeriods',
		period: QUARTER,
		periodName: QUARTER_NAME,
	},
	tn: {
		halfTimeAt: 1,
		withOvertimes: false,
		withPenalties: false,
		regularTimePeriodsUntil: 5,
		calcFinalScore: 'incrementByPeriods',
		period: SET,
		periodName: SET_NAME,
	},
	tt: {
		halfTimeAt: 1,
		withOvertimes: false,
		withPenalties: false,
		regularTimePeriodsUntil: 5,
		calcFinalScore: 'incrementByPeriods',
		period: SET,
		periodName: SET_NAME,
	},
	vb: {
		halfTimeAt: 1,
		withOvertimes: false,
		withPenalties: false,
		regularTimePeriodsUntil: 5,
		calcFinalScore: 'incrementByPeriods',
		period: SET,
		periodName: SET_NAME,
	},
	hb: {
		halfTimeAt: 1,
		withOvertimes: true,
		withPenalties: true,
		regularTimePeriodsUntil: 2,
		calcFinalScore: 'additionOfPeriods',
		period: HALFTIME,
		periodName: HALFTIME_NAME,
	},
	ih: {
		halfTimeAt: 1,
		withOvertimes: true,
		withPenalties: true,
		regularTimePeriodsUntil: 3,
		calcFinalScore: 'additionOfPeriods',
		period: THIRD,
		periodName: THIRD_NAME,
	},
	rb: {
		halfTimeAt: 1,
		withOvertimes: false,
		withPenalties: false,
		regularTimePeriodsUntil: 2,
		calcFinalScore: 'additionOfPeriods',
		period: HALFTIME,
		periodName: HALFTIME_NAME,
	},
	bs: {
		halfTimeAt: 1,
		withOvertimes: false,
		withPenalties: false,
		regularTimePeriodsUntil: 9,
		calcFinalScore: 'additionOfPeriods',
		period: INNING,
		periodName: INNING_NAME,
	},
	af: {
		halfTimeAt: 2,
		withOvertimes: true,
		withPenalties: false,
		regularTimePeriodsUntil: 4,
		calcFinalScore: 'additionOfPeriods',
		period: HALFTIME,
		periodName: HALFTIME_NAME,
	},
	ft: {
		halfTimeAt: 1,
		withOvertimes: true,
		withPenalties: true,
		regularTimePeriodsUntil: 2,
		calcFinalScore: 'additionOfPeriods',
		period: HALFTIME,
		periodName: HALFTIME_NAME,
	},
	bv: {
		halfTimeAt: 1,
		withOvertimes: false,
		withPenalties: false,
		regularTimePeriodsUntil: 3,
		calcFinalScore: 'incrementByPeriods',
		period: SET,
		periodName: SET_NAME,
	},
	wp: {
		halfTimeAt: 2,
		withOvertimes: false,
		withPenalties: true,
		regularTimePeriodsUntil: 4,
		calcFinalScore: 'additionOfPeriods',
		period: HALFTIME,
		periodName: HALFTIME_NAME,
	},
};

export const calculateFinalScore = (periods: PeriodScore[], sportId: string) => {
	if (!finalResultCalcParams[sportId]) return '';
	const { calcFinalScore } = finalResultCalcParams[sportId];

	const sortedPeriods = [...periods]
		.filter(({ score }) => score.home_score !== null && score.away_score !== null)
		.sort((a, b) => a.period - b.period);

	if (calcFinalScore === 'additionOfPeriods') return calcFinalScoreByAdditionOfPeriods(sortedPeriods);
	else return calcFinalScoreWithIncrementByPeriods(sortedPeriods);
};

export const calcFinalScoreByAdditionOfPeriods = (periods: PeriodScore[]) => {
	let home = 0;
	let away = 0;
	periods.forEach(({ score }) => {
		home = home + (score.home_score || 0);
		away = away + (score.away_score || 0);
	});
	return `${home} : ${away}`;
};
export const calcFinalScoreWithIncrementByPeriods = (periods: PeriodScore[]) => {
	let home = 0;
	let away = 0;
	periods.forEach(({ score }) => {
		home = score.home_score! > score.away_score! ? home + 1 : home;
		away = score.away_score! > score.home_score! ? away + 1 : away;
	});
	return `${home} : ${away}`;
};

interface FinalResultCalcParams {
	[key: string]: {
		halfTimeAt: number;
		withOvertimes: boolean;
		withPenalties: boolean;
		regularTimePeriodsUntil: number;
		calcFinalScore: 'additionOfPeriods' | 'incrementByPeriods';
		period: string;
		periodName: string;
	};
}
