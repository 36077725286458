interface Helper {
	[key: string]: string;
}

// Sort actions
export const descending = 'descending';
export const ascending = 'ascending';
export const unset = 'unset';

export const sortIconHelper: Helper = {
	unset: 'mdi-menu-swap',
	descending: 'mdi-menu-down',
	ascending: 'mdi-menu-up',
};
export const sortTypeHelper: Helper = {
	unset: 'descending',
	descending: 'ascending',
	ascending: 'descending',
};
