import React, { ReactNode } from 'react';

// Local interface for the component
interface Props {
   handleClick: () => void;
   svg?: string;
   icon?: string;
   children?: ReactNode;
   text?: string;
}

export default ({ handleClick, svg, icon, children, text }: Props) => (
   <li>
      <span className='nav_item' onClick={handleClick}>
         {svg ? (
            <span className='svg_icon'>
               <svg className='e_ico'>
                  <use xlinkHref={'/assets/svg/svg_sprite.svg#' + svg} />
               </svg>
            </span>
         ) : (
            <i className={'mdi mdi-' + icon} />
         )}
         <span className='label'>{text ? text : children}</span>
      </span>
   </li>
);
