import { useTranslation } from 'react-i18next';
import { useNotify } from './useNotifications';

export const useCopyToClipboard = () => {
	const { t } = useTranslation();
	const { notify } = useNotify();

	const copy = (value: string, color = '#7fbd22') => {
		navigator.clipboard.writeText(value);
		notify(`${t('toast.success.copied')} ${value}`, 'success', { style: { backgroundColor: color } });
	};

	return { copy };
};
