import React from 'react';
import { TicketStatus } from '../../types';
import { useTranslation } from 'react-i18next';

interface Props {
	ticketFilters: TicketStatus;
	toggleFilters: (status: string) => void;
	isDisabled?: boolean;
}

export default ({ ticketFilters, toggleFilters, isDisabled }: Props) => {
	const { t } = useTranslation();
	return (
		<div className={`payment_status_filter checkbox_filter margin_right_10 ${isDisabled ? 'disabled' : ''}`}>
			<span className="filter_label align_right margin_left_20">
				{t('general.tickets')}
				<br />
				{t('general.status')}
			</span>
			{Object.keys(ticketFilters)
				.filter((filter) => filter !== '7')
				.map((status) => {
					const { color, sign, text, active } = ticketFilters[status];
					return (
						<div key={status} className="checkbox_button_group">
							<div className={`checkbox_custom chek_only medium tooltip_right margin_right_5 ${color}`}>
								<input
									type="checkbox"
									id="for_label_name"
									checked={active}
									onChange={() => toggleFilters(status)}
								/>

								<span className="check_cover unchecked">{sign}</span>
								<span className="check_cover checked">{sign}</span>
								<span className="tooltip_content">{text}</span>
							</div>
						</div>
					);
				})}
		</div>
	);
};
