import { AutomaticFilters, PayInType, TicketStatus, AuthStatus } from '../types';

export const defaultAuthStatuses: AuthStatus = {
	0: {
		color: 'yellow',
		sign: 'R',
		text: 'requested',
		active: true,
	},
	2: {
		color: 'green',
		sign: 'A',
		text: 'accepted',
		active: true,
	},

	1: {
		color: 'red',
		sign: 'D',
		text: 'denied',
		active: true,
	},
	3: {
		color: 'orange',
		sign: 'M',
		text: 'modified',
		active: true,
	},
	6: {
		color: 'gray',
		sign: 'C',
		text: 'system_closed',
		active: true,
	},
};

export const defaultTicketStatuses: TicketStatus = {
	0: {
		color: 'yellow',
		sign: 'R',
		text: 'requested',
		active: true,
	},
	1: {
		color: 'blue',
		sign: 'P',
		text: 'placed',
		active: true,
	},
	2: {
		color: 'yellow',
		sign: 'RE',
		text: 'refunded',
		active: true,
	},

	5: {
		color: 'gray',
		sign: 'I',
		text: 'invalid',
		active: true,
	},
	6: {
		color: 'red',
		sign: 'D',
		text: 'deleted',
		active: true,
	},
	8: {
		color: 'blue',
		sign: 'W',
		text: 'withdrawn',
		active: true,
	},
	9: {
		color: 'red',
		sign: 'E',
		text: 'expired',
		active: true,
	},
};

export const defaultTicketType: TicketStatus = {
	REGULAR: {
		color: 'redish',
		sign: 'R',
		text: 'regular',
		active: true,
	},
	LIVE: {
		color: 'green',
		sign: 'L',
		text: 'live',
		active: true,
	},
};
export const defaultPayInType: PayInType = {
	LANDBASE: {
		color: 'redish',
		sign: 'LB',
		text: 'landbase',
		active: true,
	},
	ONLINE: {
		color: 'green',
		sign: 'O',
		text: 'online',
		active: true,
	},
};

export const defaultAutomaticFilters: AutomaticFilters = {
	autoAssign: {
		value: '0',
		active: false,
	},

	autoAuthorize: {
		value: '1000',
		active: false,
	},
	autoReject: {
		value: '5000',
		active: false,
	},
};

export const REQUESTED = {
	ASC: 'requestedAsc',
	DESC: 'requestedDesc',
};

export const AUTHORISED = {
	ASC: 'authorisedAsc',
	DESC: 'authorisedDesc',
};
