import axios from 'axios';
import { axiosInstance, Response } from './../config';
import { id } from '../../types/general';

const CancelToken = axios.CancelToken;
let cancel: any;

export const categories = {
	getCategories(sportId: string, providerId: id): Response {
		cancel && cancel();
		return axiosInstance
			.get(`/mappings/data/categories`, {
				params: { sportId: sportId, providerId: providerId },
				cancelToken: new CancelToken(function executor(c) {
					cancel = c;
				}),
			})
			.then((res) => res.data);
	},
	linkCategories(providerId: id, localItemId: id, providerItemId: id) {
		return axiosInstance.post(`/mappings/map/${providerId}/data/categories`, {
			localItemId: localItemId,
			providerItemId: providerItemId,
		});
	},
	unpairCategories(providerId: id, providerItemId: id) {
		const encodedId = encodeURIComponent(encodeURIComponent('' + providerItemId));
		return axiosInstance.delete(`/mappings/map/${providerId}/data/categories/${encodedId}`);
	},
	importCategories(providerId: id, providerItemId: id) {
		return axiosInstance.post(`/mappings/import/${providerId}/data/categories`, {
			providerItemId: providerItemId,
		});
	},
	getProviderCategories(providerId: id, sportId: string) {
		return axiosInstance
			.get(`/mappings/items/${providerId}/data/categories?id_sport=${sportId}`)
			.then((res) => res.data);
	},
};
