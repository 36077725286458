import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../../pairing/components';

import { useGeo } from '../../context';

interface Props {
	locked: string;
	rowItem: { id: string; value: any; inherited: any };
	specifics: any;
}
export const GeoSelect: FC<Props> = ({ locked, rowItem, specifics }) => {
	const { t } = useTranslation();
	const { updateGeoParameters } = useGeo();
	const { id, value, inherited } = rowItem;
	const defaultSelected = locked ? inherited : value;

	return (
		<div className={`value_content flex_direction_row ${locked ? 'disabled' : ''}`}>
			<Dropdown
				items={...specifics.choices.map((choice: string) => ({ id: choice, name: choice }))}
				margins={['0', '0', '0', '0']}
				selectItem={(param) => {
					updateGeoParameters(id, param);
				}}
				selectedItem={defaultSelected}
				icon={'mdi mdi-menu-down'}
				nonSelectedString={t('general.selectOption')}
				withSearch
			/>
		</div>
	);
};
