import React, { useState, useRef, Dispatch, SetStateAction } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface Props {
	languages: string[];
	media: string[];
	selectedLang: string;
	selectedMedia: string;
	selectLang: Dispatch<SetStateAction<string>>;
	selectMedia: (id: string) => void;
	marginTop: string;
	direction?: string;
	showMedia?: boolean;
}

export default ({
	languages,
	media,
	selectedLang,
	selectedMedia,
	selectLang,
	selectMedia,
	marginTop,
	direction = 'up',
	showMedia = true,
}: Props) => {
	// Track state of dropdown
	const [open, setOpen] = useState(false);
	const dropdownClass = open ? 'select_region drop_menu toggle' : 'select_region drop_menu down';

	// Create dropdownRef
	const dropdownRef = useRef<HTMLDivElement>(null);

	// Call useOnClickOutside custom hook
	useOnClickOutside(dropdownRef, () => setOpen(false));

	return (
		<div className={`tab_nav padding_left_20 padding_right_20 margin_top_${marginTop}`}>
			{/* Select language dropdown */}
			<div ref={dropdownRef} className={`${direction} ${dropdownClass}`} onClick={() => setOpen(!open)}>
				<i className=" mdi mdi-earth tooltip_right">
					<span className="tooltip_content">{selectedLang}</span>
				</i>
				<span className="drop_label">{selectedLang}</span>
				<div className="drop_list left_0 list_6_items">
					{languages.map((item, index) => (
						<div
							key={index}
							className="drop_list_item font_400"
							onClick={() => {
								selectLang(item);
								setOpen(false);
							}}
						>
							{item.toUpperCase()}
						</div>
					))}
				</div>
			</div>

			{/* Media List */}
			{showMedia
				? media.map((item, index) => {
						let active = item === selectedMedia ? 'tab_nav_item active' : 'tab_nav_item';
						return (
							<div key={index} className={active} onClick={() => selectMedia(item)}>
								{item}
							</div>
						);
				  })
				: null}
		</div>
	);
};
