import React, { FC, useState, KeyboardEvent, useEffect } from 'react';
import api from '../../../../../api';
import { PlayersList } from './PlayersList';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/Modals';
import { CompetitorItem, CompetitorItemExtended } from '../../types';

import { ModalSize } from '../../../../../components/Modals/types';
import { Tab } from '../../../../../components/Events/Modal/helpers';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { Sport } from '../../../sports/types';
import { useNotify } from '../../../../../hooks/useNotifications';
import getErrorText from '../../../../../utils/getErrorMsg';

interface Props {
	editing: CompetitorItem;
	onSubmit: (values: any) => void;
	onClose: () => void;
	sports: Sport[];
}

export const CompetitorsModal: FC<Props> = ({ editing, onClose, onSubmit, children, sports }) => {
	const { t, i18n } = useTranslation();
	const { language: ln } = i18n;

	const [competitor, setCompetitor] = useState<CompetitorItemExtended | null>(null);

	const [tab, setTab] = useState<Tab>('general');
	const [modalSize, setModalSize] = useState<ModalSize>('xl');

	const { error } = useNotify();

	useEffect(() => {
		if (editing.id) {
			(async function getSingleCompetitor() {
				try {
					const [competitor, players] = await Promise.all([
						api.competitors.getCompetitor(`${editing.id}`),
						api.competitors.getCompetitorPlayers(`${editing.id}`),
					]);
					setCompetitor({
						...editing,
						...competitor,
						players: players,
					});
				} catch (err) {
					error(getErrorText(err) || 'toast.error.defaultText');
				}
			})();
		} else {
			setCompetitor({ ...editing, players: [] });
		}
	}, [editing]);

	const methods = useForm<CompetitorItemExtended>({
		defaultValues: { ...competitor },
		shouldUnregister: false,
	});

	const onSubmitForm = (data: SubmitHandler<CompetitorItemExtended>) => {
		onSubmit(data);
		onClose();
	};

	function onKeyDown(event: KeyboardEvent<HTMLFormElement>) {
		//@ts-ignore
		if ((event.charCode || event.keyCode) === 13 && event?.target?.type !== 'textarea') {
			event.preventDefault();
		}
	}

	// Prevent form submit if required fields not populated
	const { sport, name } = methods.watch() as any;
	const isDisabled = !sport?.id || !name[ln];
	const disableClass = isDisabled ? 'disabled' : '';

	// Tab switcher
	const component = () => {
		switch (tab) {
			case 'players':
				return <PlayersList sports={sports} />;
			default:
				return children;
		}
	};

	return (
		<>
			{competitor ? (
				<Modal
					size={modalSize}
					align={'start'}
					direction={'row'}
					opened={true}
					sectionClass={'admin_tour '}
					zIndex={'over_1'}
					cancelAction={() => onClose()}
				>
					<FormProvider {...methods}>
						<form
							className="modal_content fadeInDown animated faster"
							onSubmit={methods.handleSubmit(onSubmitForm)}
							onKeyDown={onKeyDown}
						>
							<div className="modal_head">
								<div className={`tab_nav padding_left_20 padding_right_20 `}>
									<div
										onClick={() => {
											setTab('general');
											setModalSize('xl');
										}}
										className={`tab_nav_item ${tab === 'general' ? 'active' : ''}`}
									>
										{t('modalNav.general')}
									</div>

									<div
										onClick={() => {
											setTab('players');
											setModalSize('md');
										}}
										className={`tab_nav_item ${tab === 'players' ? 'active' : ''}`}
									>
										{t('playersAdministration.shortTitle')}
									</div>
								</div>
							</div>
							<div data-testid="modal-body" className="modal_body content_start direction_row">
								{component()}
							</div>

							<div data-testid="modal-footer" className={`modal_footer col_border_top`}>
								<button className="btn md round outline" onClick={() => onClose()}>
									{t('general.cancel')}
								</button>

								<button type="submit" className={`btn md round green ${disableClass}`} disabled={isDisabled}>
									{t('general.save')}
								</button>
							</div>
						</form>
					</FormProvider>
				</Modal>
			) : null}
		</>
	);
};
