import React, { useState, useEffect, useRef, Dispatch, SetStateAction, FocusEvent, Fragment } from 'react';
import { LangForm, AdministrationModal } from '../../administration/components';
import { emptyCompetitor } from '../../../models/competitorsAdministration';
import { CompetitorItem } from '../../administration/competitors/types';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce/lib';
import { Item } from '../types';
import api from '../../../api';
import { useModels } from '../../../hooks/useModels';
import { debounce } from 'lodash';

interface Props {
	selectCompetitor: Dispatch<SetStateAction<CompetitorItem | null>>;
	providerItem: Item;
	type: string;
	select: (type: string) => void;
}

export default ({ providerItem, selectCompetitor, type, select }: Props) => {
	const { t } = useTranslation();
	const { mapRegular } = useModels();

	const { sport, competitors: providerCompetitors } = providerItem;
	const index = type === 'home' ? 0 : 1;
	const { mapped, mappedTo, name } = providerCompetitors[index];

	const [competitors, setCompetitors] = useState<CompetitorItem[]>([]);
	const [fuzzyCompetitors, setFuzzyCompetitors] = useState<CompetitorItem[] | null>(null);

	const [isOpen, openModal] = useState<CompetitorItem | null>(null);

	const [searchQuery, setSearchQuery] = useState<string>('');
	const [debouncedText] = useDebounce(searchQuery, 400);

	const warningClass = mapped ? '' : 'has_warning';

	const [temporary, setTemporary] = useState<CompetitorItem>({
		...emptyCompetitor,
		type,
		sport: sport.mappedTo,
	});

	// Track state of dropdown
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	const dropdownClass = open ? 'src_results toggle' : `src_results`;
	useOnClickOutside(dropdownRef, () => setOpen(false));

	useEffect(() => {
		try {
			(async function getData() {
				if (providerItem && mapped) {
					const data = await api.competitors.getCompetitor(mappedTo.id);
					setSearchQuery(data.defaultName);
					selectCompetitor(data);
				}
			})();
		} catch (error) {}
	}, []);

	useEffect(() => {
		try {
			(async function getData() {
				if (debouncedText.length < 3) {
					setCompetitors([]);
					return;
				}
				const { competitors } = await api.competitors.getCompetitors(sport.mappedTo.id, debouncedText);
				setCompetitors([...competitors, temporary]);
			})();
		} catch (error) {
			console.error();
		}
	}, [debouncedText]);

	const handleChange = (item: CompetitorItem) => {
		selectCompetitor(item);
		setSearchQuery(item.defaultName);
		select(type);
	};

	const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
		event.target.setAttribute('autocomplete', 'off');
		setOpen(true);
		setSearchQuery('');
		selectCompetitor(null);
		handleFuzzy();
	};

	const handleFuzzy = async () => {
		try {
			const data = await api.competitors.getFuzzyCompetitors(sport.mappedTo.id, name);
			setFuzzyCompetitors(data);
			setOpen(true);
		} catch (error) {}
	};

	const updateCreatedCompetitor = (competitor: CompetitorItem) => {
		const newCompetitor = { ...competitor, defaultName: competitor.name.en };
		const newCompetitors = competitors.map((item) => (item.id === competitor.id ? newCompetitor : item));
		setTemporary(newCompetitor);
		setSearchQuery(competitor.name.en);
		setCompetitors(newCompetitors);
		selectCompetitor(newCompetitor);
		select(type);
	};

	const importCompetitor = () => {
		if (providerItem) {
			const importing = {
				...temporary,
				name: {
					srb: '',
					en: name,
					de: '',
				},
			};
			openModal(importing);
		}
	};

	return (
		<Fragment>
			<div className="search_dropdown_wrapper medium margin_bottom_20">
				{/* Serach  */}
				<div className="search_dropdown col-5 " ref={dropdownRef}>
					<input
						id={type}
						className="src medium full_w"
						placeholder={t('competitorsPairing.localCompetitors')}
						type="text"
						value={searchQuery}
						onFocus={handleFocus}
						onChange={(e) => {
							setFuzzyCompetitors(null);
							setSearchQuery(e.target.value);
						}}
					/>

					<div className={dropdownClass}>
						{!fuzzyCompetitors
							? competitors
									.filter(
										(item) =>
											item.defaultName.toLowerCase().includes(searchQuery.toLowerCase()) && item.defaultName
									)
									.map((item) => (
										<div
											key={item.id}
											className="list_item"
											onClick={() => {
												handleChange(item);
												setOpen(false);
											}}
										>
											{`${item.defaultName.toUpperCase()}`}
										</div>
									))
							: fuzzyCompetitors.map((item) => (
									<div
										key={item.id}
										className="list_item"
										onClick={() => {
											handleChange(item);
											setOpen(false);
										}}
									>
										{`${item.defaultName.toUpperCase()}`}
									</div>
							  ))}
					</div>
				</div>
				{/* actions */}
				<div className="actions col-2 justify_center">
					<i className="create mdi mdi-magnify tooltip_left" onClick={debounce(handleFuzzy, 800)}>
						<span className="tooltip_content blue">{t('general.fuzzySearch')}</span>
					</i>
					<i className="import mdi mdi-chevron-double-left tooltip_right" onClick={() => importCompetitor()}>
						<span className="tooltip_content blue">{t('competitorsPairing.import')}</span>
					</i>
				</div>
				{/* Provider Category  */}
				<div className={`col-5 input_label medium tooltip_left direction_column ${warningClass}`}>
					<small>{`${type} Competitor`}</small>
					<strong className="">{name}</strong>
					{!mapped && <span className="tooltip_content redish">{t('competitorsPairing.notMapped')}</span>}
				</div>
			</div>
			{isOpen ? (
				<AdministrationModal
					editing={{
						...isOpen,
						name: mapRegular(isOpen.name),
						shortName: mapRegular(isOpen.shortName),
					}}
					onSubmit={updateCreatedCompetitor}
					onClose={() => openModal(null)}
					size={'md'}
					sectionClass={'admin_tour'}
					zIndex={'over_2'}
				>
					<LangForm
						col="12"
						colPosition="left"
						colBorder="right"
						padding={[30, 20, 0, 30]}
						direction="column"
						sports={null}
					/>
				</AdministrationModal>
			) : null}
		</Fragment>
	);
};
