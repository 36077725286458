import { isEqual, isObject } from 'lodash';

export const getDifferences: any = (oldObj: any, newObj: any) => {
	const oldKeys = isObject(oldObj) ? Object.keys(oldObj || {}) : [];
	const newKeys = isObject(newObj) ? Object.keys(newObj || {}) : [];

	const mergedKeys = [...new Set([...oldKeys, ...newKeys])];
	return mergedKeys.reduce((diff, key) => {
		if (isObject(newObj?.[key]) || isObject(oldObj?.[key])) {
			if (isEqual(newObj?.[key], oldObj?.[key])) return diff;
			return { ...diff, [key]: getDifferences(oldObj?.[key], newObj?.[key]) };
		}
		if (newObj?.[key] === oldObj?.[key]) return diff;
		if (newObj?.[key] === null) {
			return {
				...diff,
				[key]: `${oldObj?.[key]} -> null`,
			};
		}
		return {
			...diff,
			[key]: `${oldObj?.[key]} -> ${newObj?.[key]}`,
		};
	}, {});
};
