import React, { FC, useEffect } from 'react';
import { useGeo } from '../context';
import { HorizontalTab } from '../types';
import icons from '../../../../consts/icons';
import { TreeItem } from './Tree/GeoTreeItem';
import { Context, Item, useTreeOptions } from '../../../../components/general/Tree';
import { debounce } from 'lodash';

export const ParameterGroups: FC = () => {
	const { selectGroup, selectDevice, horizontalTabs, saveWarning, modified, user } = useGeo();

	const treeOptions = useTreeOptions();

	useEffect(() => {
		const { items, expanded, active, parentKey } = transformTabsData(horizontalTabs);
		treeOptions.updateItems(items).updateExpanded(expanded).updateActive([active]);
		if (active && parentKey) {
			select(active.split(':')[0], parentKey);
		}
	}, [horizontalTabs]);

	const select = (key: string, parentKey: string) => {
		selectDevice({ key: parentKey, items: [] });
		selectGroup({ key, items: [] });
	};

	const treeHandler = async (ctx: Context) => {
		const { item } = ctx;
		const { type } = item;
		if (modified) return saveWarning();
		switch (type) {
			case 'horizontal':
				return handleEntity(ctx, select);

			case 'vertical':
				return handleEntity(ctx, select, 'vertical');

			default:
				return;
		}
	};

	const wrapperClass = user ? 'vertical_navigation sticky' : 'vert_nav';
	return (
		<div className={wrapperClass} style={{ width: '260px', overflow: 'auto' }}>
			<div className="tree_view">
				{treeOptions.items.map((item: Item, index: number) => {
					const ctx = treeOptions.generateContext(`${index}`, item);

					return (
						<TreeItem
							index={index}
							key={item.id}
							nested={false}
							context={ctx}
							handleChange={debounce(treeHandler, 400)}
							generateContext={treeOptions.generateContext}
						/>
					);
				})}
			</div>
			<div className="empty_div"></div>
		</div>
	);
};

export async function handleEntity(
	ctx: Context,
	select: (key: string, parentKey: string) => void,
	type: string = 'horizontal'
) {
	const { item, isExpanded, expand, contract, target, activate } = ctx;
	const { items, key, parent } = item;
	if (target === 'expand') {
		if (isExpanded) contract();
		else if (items && items.length) expand();
	} else {
		if (type !== 'horizontal') {
			select(key, parent);
			activate();
		}
	}
}

export const transformTabsData = (tabs: HorizontalTab[]) => {
	const expanded: string[] = [];
	let active = '';
	let parentKey = '';

	const items: Item[] = tabs.map(({ key: parrentKey, items: groups }, i: number) => {
		if (groups.length && i === 0) expanded.push(`${i}`);
		return {
			id: `${parrentKey}:${i}`,
			key: parrentKey,
			name: parrentKey.split('_').join(' '),
			type: 'horizontal',
			expandIcon: icons.arrowRight,
			contractIcon: icons.arrowDown,
			expandable: true,
			items: groups.map(({ key }, j: number) => {
				if (i == 0 && j === 0) {
					active = `${key}:${i}:${j}`;
					parentKey = parrentKey;
				}
				return {
					id: `${key}:${i}:${j}`,
					key,
					name: key.split('_').join(' '),
					parent: parrentKey,
					type: 'vertical',
					icon: icons.circle,
					expandIcon: icons.circle,
					contractIcon: icons.circle,
					expandable: false,
					active: j === 0,
					items: [],
				};
			}),
		};
	});
	return { items, expanded, active, parentKey };
};
