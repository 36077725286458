import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import { useNotify } from '../../../hooks/useNotifications';

interface Props {
	userUid: string;
}
export const ForceUserAuth: FC<Props> = ({ userUid }) => {
	const { t } = useTranslation();
	const [forceAuth, setAuthForce] = useState<boolean>(false);
	const [forceAuthDisabled, setAuthForceDisabled] = useState<boolean>(false);
	const [forceLiveAuth, setLiveAuthForce] = useState<boolean>(false);
	const [forceLiveAuthDisabled, setLiveAuthForceDisabled] = useState<boolean>(false);

	const { error } = useNotify();

	useEffect(() => {
		if (!userUid) return;
		(async function getUserRiskParams() {
			try {
				const data = await api.geo.getEntityParametersForUser(userUid, 'RISK', 'AUTOMATIC_RISK');
				if (data.entity_params.RISK_FORCE_AUTHORISATION !== null) {
					setAuthForce(data.entity_params.RISK_FORCE_AUTHORISATION);
				} else if (data.inherited_params.RISK_FORCE_AUTHORISATION !== null) {
					setAuthForce(data.inherited_params.RISK_FORCE_AUTHORISATION);
				}
				if (data.entity_params.RISK_FORCE_LIVE_AUTHORISATION !== null) {
					setLiveAuthForce(data.entity_params.RISK_FORCE_LIVE_AUTHORISATION);
				} else if (data.inherited_params.RISK_FORCE_LIVE_AUTHORISATION !== null) {
					setLiveAuthForce(data.inherited_params.RISK_FORCE_LIVE_AUTHORISATION);
				}
			} catch (err) {
				console.error(err);
			}
		})();
	}, [userUid]);

	const toggleAuth = async () => {
		try {
			setAuthForceDisabled(true);
			await api.risk.forceAuth(userUid);
			setAuthForce(!forceAuth);
		} catch (err) {
			error('toast.error.userAutomaticRiskGenericError');
		} finally {
			setAuthForceDisabled(false);
		}
	};

	const toggleLiveAuth = async () => {
		try {
			setLiveAuthForceDisabled(true);
			await api.risk.forceLiveAuth(userUid);
			setLiveAuthForce(!forceLiveAuth);
		} catch (err) {
			error('toast.error.userAutomaticRiskGenericError');
		} finally {
			setLiveAuthForceDisabled(false);
		}
	};

	return (
		<>
			<div className={`ticket_info_item ${forceAuthDisabled ? 'disabled' : ''}`}>
				<div className="auto_open margin_right_30">
					<div className="checkbox_custom tiny_label dark">
						<input
							type="checkbox"
							id="forceUserAuth"
							checked={forceAuth}
							onClick={toggleAuth}
							onChange={() => {}}
						/>
						<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
						<i className="mdi mdi-checkbox-marked checked"></i>
						<label htmlFor="forceUserAuth" className="right ">
							{t('general.forcePrematchAuth')}
						</label>
					</div>
				</div>
			</div>
			<div className={`ticket_info_item ${forceLiveAuthDisabled ? 'disabled' : ''}`}>
				<div className="auto_open margin_right_30">
					<div className="checkbox_custom tiny_label dark">
						<input
							type="checkbox"
							id="forceUserLiveAuth"
							checked={forceLiveAuth}
							onClick={toggleLiveAuth}
							onChange={() => {}}
						/>
						<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
						<i className="mdi mdi-checkbox-marked checked"></i>
						<label htmlFor="forceUserLiveAuth" className="right ">
							{t('general.forceLiveAuth')}
						</label>
					</div>
				</div>
			</div>
		</>
	);
};
