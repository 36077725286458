import React, { FC, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useGeo } from '../../context';
import Button from '../../../../../components/general/Button';
import LangDropdown from '../../../../../components/general/LangDropdown';
import getErrorText from '../../../../../utils/getErrorMsg';
import api from '../../../../../api';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface Props {
	locked: string;
	rowItem: { id: string; value: any; inherited: any };
	specifics: any;
}

export const GeoDescription: FC<Props> = ({ locked, rowItem, specifics }) => {
	const { t } = useTranslation();
	const { updateGeoParameters, geoParameters, updateDescriptionGeoParameters } = useGeo();
	const { id, value, inherited } = rowItem;

	const { uri, lang_uri } = specifics;
	const [parameters, setParameters] = useState<string[][]>();
	const [description, setDescription] = useState<{ [key: string]: string }>(value);
	const [languages, setLanguages] = useState<string[]>([]);
	const [selectedLang, setSelectedLang] = useState<string>('');
	const [errorMessageParam, setErrorMessageParam] = useState('');
	const [errorMessageLang, setErrorMessageLang] = useState('');
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

	useEffect(() => {
		const descriptionValue = locked ? inherited : value;
		let data = descriptionValue?.[selectedLang] || description?.[selectedLang] || '';
		parameters?.forEach(([_, val]) => {
			data = data.replaceAll(
				`\${${val}}`,
				`<a class="wysiwyg-mention" data-mention data-value="{${val}}">\${${val}}</a>`
			);
		});

		const blocksFromHtml = htmlToDraft(data);
		const { contentBlocks, entityMap } = blocksFromHtml;
		const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

		setEditorState(EditorState.createWithContent(contentState));
	}, [selectedLang, value, inherited, locked]);

	const handleEditorOnChange = (state: EditorState) => {
		let markdownValue = state ? draftToHtml(convertToRaw(state.getCurrentContent())) : '';

		parameters?.forEach(([_, val]) => {
			markdownValue = markdownValue.replaceAll(
				new RegExp(`<a[^>]* data-value="\\{${val}\\}">\\\${${val}}<\\/a>`, 'g'),
				`\${${val}}`
			);
		});

		const newDescription = { ...description };
		newDescription[selectedLang] = markdownValue;
		updateDescriptionGeoParameters(id, newDescription);
		setDescription(newDescription);
		setEditorState(state);
	};

	useEffect(() => {
		(async function getData() {
			try {
				const subgroupArr = uri.split('/');
				const subgroup = subgroupArr[subgroupArr.length - 1];
				const data = await api.geo.getPaymentProvidersParams(subgroup);

				setParameters(data);
				setErrorMessageParam('');
			} catch (err) {
				setErrorMessageParam(getErrorText(err) || t('toast.error.defaultText'));
			}
		})();
	}, [specifics, geoParameters.id]);

	useEffect(() => {
		(async function getData() {
			try {
				const data: { values: string[][] } = await api.geo.getReferenceFieldData(
					{ idGeoEntity: geoParameters.id },
					lang_uri
				);
				const dataLanguages = data.values.map((val) => val[0]);
				setLanguages(dataLanguages);
				setSelectedLang(dataLanguages[0]);
				setErrorMessageLang('');
			} catch (err) {
				setErrorMessageLang(getErrorText(err) || t('toast.error.defaultText'));
			}
		})();
	}, [specifics, geoParameters.id]);

	const suggestions = useMemo(() => {
		let items = [] as any[];
		parameters &&
			parameters.forEach(([name, value]) => {
				items.push({
					text: name,
					value: `{${value}}`,
				});
			});
		return items;
	}, [parameters]);

	const mapRegular = (data: { [key: string]: string }) => {
		const mapped = {} as { [key: string]: string };
		languages.forEach((lang) => {
			mapped[lang] = data?.[lang] || '';
		});
		return { ...data, ...mapped };
	};

	const fillRegular = (regular: any) => {
		let filled = { ...regular };

		for (const x in filled) {
			filled[x] = filled[x] ? filled[x] : regular[selectedLang];
		}
		return filled;
	};

	const fillLocalization = () => {
		const newDescription = fillRegular(mapRegular({ [selectedLang]: description[selectedLang] }));
		setDescription(newDescription);
		updateGeoParameters(id, newDescription);
	};

	const selectLang = (lang: string) => {
		setSelectedLang(lang);
	};

	return (
		<div className={`value_content ${locked ? 'disabled' : ''}`}>
			{!errorMessageParam && !errorMessageLang ? (
				<>
					<div className="col-12 flex_direction_row">
						<div className="col-3 acc_label">
							<div className="row_title">{t('geo.language')}:</div>
						</div>
						<div>
							<LangDropdown languages={languages} selectedLang={selectedLang} selectLang={selectLang} />
						</div>
					</div>
					<div className="col-12 editor">
						<Editor
							placeholder={t('geo.descriptionPlaceholder')}
							editorState={editorState}
							onEditorStateChange={handleEditorOnChange}
							editorClassName="rich-text-editor"
							stripPastedStyles
							toolbar={{
								options: ['inline', 'blockType', 'list', 'link', 'history', 'textAlign'],
								inline: {
									options: ['bold', 'italic'],
								},
							}}
							mention={{
								separator: ' ',
								trigger: '$',
								suggestions: suggestions,
							}}
							toolbarCustomButtons={[
								<div className="margin_top_5">
									<Button
										type="button"
										name={t('general.fillEmpty')}
										className="btn sm blue"
										onClick={() => fillLocalization()}
									></Button>
								</div>,
							]}
						/>
					</div>
				</>
			) : (
				<p>{errorMessageParam || errorMessageLang}</p>
			)}
		</div>
	);
};
