import React, { Dispatch, SetStateAction, FC } from 'react';
import { Tab, TabItem } from '../../types';
import { navigationTabs } from '../../helpers';

interface Props {
	tab: Tab;
	setTab: (tab: Tab) => void;
	className?: string;
}

export const Navigation: FC<Props> = ({ tab, setTab, className }) => {
	const select = (selected: Tab) => {
		setTab(selected);
	};

	return (
		<div className={`tab_nav padding_left_20 padding_right_20 ${className}`}>
			{navigationTabs.map(({ id, text }) => (
				<div key={id} onClick={() => select(id)} className={`tab_nav_item ${id === tab ? 'active' : ''}`}>
					{text}
				</div>
			))}
		</div>
	);
};
