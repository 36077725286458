import React, { createContext, FC, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api';
import { useNotify } from '../hooks/useNotifications';
import { Item } from '../types/general';

export interface GeoFiltersContextProps {
	companies: Item[];
	regions: Item[];
	groups: Item[];
	locations: Item[];
	selectedCompany: string;
	selectCompany: (companyId: string) => void;
	selectedRegion: string;
	selectRegion: (regionId: string) => void;
	selectedGroup: string;
	selectGroup: (groupId: string) => void;
	selectedLocations: string[];
	selectLocations: (locationsIds: string[]) => void;
	clearAll: () => void;
}

const geoFiltersContextDefault: GeoFiltersContextProps = {
	companies: [],
	regions: [],
	groups: [],
	locations: [],
	selectedCompany: '',
	selectCompany: () => {},
	selectedRegion: '',
	selectRegion: () => {},
	selectedGroup: '',
	selectGroup: () => {},
	selectedLocations: [],
	selectLocations: () => {},
	clearAll: () => {},
};

const GeoFiltersContext = createContext(geoFiltersContextDefault);

const GeoFiltersProvider: FC = (props) => {
	const { t } = useTranslation();
	const [companies, setCompanies] = useState<Item[]>([]);
	const [regions, setRegions] = useState<Item[]>([]);
	const [groups, setGroups] = useState<Item[]>([]);
	const [locations, setLocations] = useState<Item[]>([]);
	const [selectedCompany, setSelectedCompany] = useState('');
	const [selectedRegion, setSelectedRegion] = useState('');
	const [selectedGroup, setSelectedGroup] = useState('');
	const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

	const COMPANY = 'COMPANY';
	const REGION = 'REGION';
	const GROUP = 'GROUP';
	const LOCATION = 'LOCATION';

	const { warn } = useNotify();

	useEffect(() => {
		loadCompanies();
	}, []);

	const loadCompanies = async () => {
		try {
			const data = (await api.geo.getEntity(COMPANY)) || [];
			setCompanies(data.map(({ id, name }) => ({ id, name })));
		} catch (error) {
			console.error(error);
			warn(t('toast.warn.loadProblem', { entity: t('geo.companies') }), { autoClose: 5000 });
		}
	};

	const loadRegions = async (companyId: string) => {
		try {
			const data = await api.geo.getEntitySubNodeByType(companyId, COMPANY, REGION);
			setRegions(data.map(({ id, name }) => ({ id, name })));
		} catch (error) {
			console.error(error);
			warn(t('toast.warn.loadProblem', { entity: t('geo.regions') }), { autoClose: 5000 });
		}
	};

	const loadGroups = async (regionId: string) => {
		try {
			const data = await api.geo.getEntitySubNodeByType(regionId, REGION, GROUP);
			setGroups(data.map(({ id, name }) => ({ id, name })));
		} catch (error) {
			console.error(error);
			warn(t('toast.warn.loadProblem', { entity: t('geo.groups') }), { autoClose: 5000 });
		}
	};

	const loadLocations = async (groupId: string) => {
		try {
			const data = await api.geo.getEntitySubNodeByType(groupId, GROUP, LOCATION);
			setLocations(data.map(({ id, name }) => ({ id, name })));
		} catch (error) {
			console.error(error);
			warn(t('toast.warn.loadProblem', { entity: t('geo.locations') }), { autoClose: 5000 });
		}
	};

	const clearRegions = () => {
		setRegions([]);
		setSelectedRegion('');
	};

	const clearGroups = () => {
		setGroups([]);
		setSelectedGroup('');
	};

	const clearLocations = () => {
		setLocations([]);
		setSelectedLocations([]);
	};

	const clearAll = () => {
		clearLocations();
		clearGroups();
		clearRegions();
		setSelectedCompany('');
	};

	const selectCompany = (companyId: string) => {
		setSelectedCompany(companyId);
		clearRegions();
		clearGroups();
		clearLocations();
		loadRegions(companyId);
	};

	const selectRegion = (regionId: string) => {
		setSelectedRegion(regionId);
		clearGroups();
		clearLocations();
		loadGroups(regionId);
	};

	const selectGroup = (groupId: string) => {
		setSelectedGroup(groupId);
		clearLocations();
		loadLocations(groupId);
	};

	const selectLocations = (locationsIds: string[]) => {
		setSelectedLocations(locationsIds);
	};

	return (
		<GeoFiltersContext.Provider
			value={{
				companies,
				regions,
				groups,
				locations,
				selectedCompany,
				selectCompany,
				selectedRegion,
				selectRegion,
				selectedGroup,
				selectGroup,
				selectedLocations,
				selectLocations,
				clearAll,
			}}
			{...props}
		></GeoFiltersContext.Provider>
	);
};

const useGeoFilters = () => useContext(GeoFiltersContext);

export { GeoFiltersProvider, useGeoFilters };
