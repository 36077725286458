import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
	name: string;
	label: string;
	editable: boolean;
	edit: boolean;
	isInvalidID?: boolean;
}

export const TextField: FC<Props> = ({ name, label, editable, edit, isInvalidID }) => {
	const { register } = useFormContext();

	return (
		<div className={`form_row ${!(editable && edit) ? 'read_only' : ''} `}>
			<label htmlFor={name} className="input_label col-3 ">
				{label}
			</label>
			<div className="col-6">
				<input
					type="text"
					id={name}
					className={`normal focus full_w ${isInvalidID ? 'red_hot_border' : ''}`}
					name={name}
					ref={register}
					disabled={!editable || !edit}
				/>
			</div>
		</div>
	);
};
