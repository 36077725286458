import React from 'react';
import LoginForm from '../components/Login/LoginForm';

export default () => {
	// Get random background image
	const backgroundImage: string = randomBackground();

	// Render the component
	return (
		<div className="login_wrapper" style={{ backgroundImage }}>
			<div className="login_dialog">
				<div className="login_logo">
					<img src="/img/alea_login_logo.png" alt="Alea Control" />
				</div>

				{/* Login form */}
				<LoginForm />

				<p className="copyrights">
					Alea control management system <br />
					Always be in control
				</p>
			</div>
		</div>
	);
};

// Backgound names
const backgrounds: string[] = [
	'bg_soccer_01',
	'bg_soccer_02',
	'bg_soccer_03',
	'bg_tennis_01',
	'bg_tennis_02',
	'bg_odbojka_02',
	'bg_odbojka_01',
	'bg_basketball_03',
	'bg_basketball_01',
	'bg_basketball_02',
	'bg_aFootball_01',
	'bg_aFootball_02',
	'bg_vaterpolo_01'
];

// Get image url based on random index
const randomBackground: () => string = () => {
	const random = (Math.random() * 13) | 0;
	return `url(/img/bg_img/${backgrounds[random]}.jpg)`;
};
