import React, { useEffect, useState, FC } from 'react';
import { EventRow } from './EventRow';
import { EventRowDiff } from './EventRowDiff';

import { useTicket } from '../../context/TicketContext';
import { TicketEvent } from '../../../../../pages/risk-management/controller/types';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import { CurrentScore, PeriodScore, Result } from '../../types';
import { SimpleLoader } from '../../../../general/Loader';

interface Props {
	showEvents: 'showAuthorizedEvents' | 'showOriginalEvents' | 'showDiff';
}

type SystemType = {
	events: TicketEvent[];
	required: number[];
};

type SystemsType = SystemType[];

export const EventsTableBody: FC<Props> = ({ showEvents }) => {
	const { t } = useTranslation();
	const { ticket, similarTicket, similarTicketShown } = useTicket();
	const [results, setResults] = useState<{ [eventId: string]: Result | null }>({});
	const [currentTimes, setCurrentTimes] = useState<{ [eventId: string]: string | null }>({});
	const [resultsLoaded, setResultsLoaded] = useState(false);

	const mapper = (systems: SystemsType) => {
		return systems.map(({ events, required }) => ({
			required,
			events: events.map((event) => {
				const similar = similarTicket?.systems
					.map(({ events }) => events)
					.flat()
					.find((flatEvent) => event.id === flatEvent.id);
				return {
					...event,
					similar,
				};
			}),
		}));
	};

	let systems = mapper(showEvents === 'showAuthorizedEvents' ? ticket.systems : ticket.riskCopyTicket.systems);

	const events = ticket.systems.map(({ events }) => events).flat();

	useEffect(() => {
		(async () => {
			setResultsLoaded(false);
			try {
				const additionalData: {
					[eventId: string]: {
						result: { currentScore: CurrentScore; periodScores: PeriodScore[] } | null;
						currentTime: string;
					};
				} = await api.risk.getEventsAdditionalData(events.map(({ id }) => id));
				const scores = {};
				const times = {};
				for (const [eventId, data] of Object.entries(additionalData)) {
					scores[eventId] = {
						currentScore: data?.result?.currentScore || null,
						periodScores: data?.result?.periodScores,
					};
					if (data?.currentTime) {
						times[eventId] = data.currentTime?.split(':')[0] || null;
					}
				}
				setResults(scores);
				setCurrentTimes(times);
			} catch (err) {
				console.error(err);
			} finally {
				setResultsLoaded(true);
			}
		})();
	}, []);

	const similarTicketEvents = similarTicket?.systems
		.map(({ events }) => events)
		.flat()
		.filter(({ id }) => !events.find((event) => event.id === id));

	return (
		<SimpleLoader loaded={resultsLoaded}>
			{systems.map(({ events, required }, systemIndex) => (
				<div key={systemIndex} className="system">
					{events.map((event, eventIndex) =>
						showEvents !== 'showDiff' ? (
							<EventRow
								key={event.id}
								event={event}
								systemIndex={systemIndex}
								eventIndex={eventIndex}
								currentResults={results[event.id]}
								currentTime={currentTimes[event.id] || null}
								showEvents={showEvents}
							/>
						) : (
							<EventRowDiff
								key={event.id}
								event={event}
								systemIndex={systemIndex}
								eventIndex={eventIndex}
								systemsOriginal={systems}
							/>
						)
					)}

					<div className="system_label">
						<div className="sys_left"></div>
						<div className="label">{`${required}/${events.length}`}</div>
						<div className="sys_right"></div>
					</div>
				</div>
			))}
			{similarTicketShown && similarTicketEvents?.length ? (
				<div className="system">
					{similarTicketEvents.map((event, eventIndex) => (
						<EventRow
							key={event.id}
							event={event}
							systemIndex={systems.length + 1}
							eventIndex={eventIndex}
							similarEvent={true}
						/>
					))}
					<div className="system_label">
						<div className="sys_left"></div>
						<div className="label">{t('reports.tickets.aditionalEventsSimilar')}</div>
						<div className="sys_right"></div>
					</div>
				</div>
			) : null}
		</SimpleLoader>
	);
};
