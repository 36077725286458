import { axiosInstance } from '../config';
import { Email, EmailData, EmailProperies } from '../../pages/internet/email-service/types';

export const emailService = {
	getEmailData() {
		return axiosInstance.get('/administration/email_administration/').then((res) => res.data);
	},

	getEmailDataByID(id: number): Promise<Email> {
		return axiosInstance.get(`/administration/email_administration/${id}`).then((res) => res.data);
	},

	getEmailPropByEmailType(emailType: string): Promise<EmailProperies> {
		return axiosInstance
			.get('/administration/email_administration/properties/', { params: { email_type: emailType } })
			.then((res) => res.data);
	},

	createEmail(email: Email): Promise<EmailData> {
		return axiosInstance.post(`/administration/email_administration/`, email).then((res) => res.data);
	},

	updateEmail(id: number, email: Email): Promise<EmailData> {
		return axiosInstance.put(`/administration/email_administration/${id}`, email).then((res) => res.data);
	},

	deleteEmailByEmailID(id: number) {
		return axiosInstance.delete(`/administration/email_administration/${id}`).then((res) => res.data);
	},

	previewEmailByEmailIdAndLang(id: number, lang: string) {
		return axiosInstance.post(`/administration/email_administration/${id}/preview`, {
			templateId: id,
			language: lang,
		});
	},

	sendEmailToAddress(id: number, lang: string, email: string, instanceCode: string) {
		return axiosInstance.post(`/administration/email_administration/${id}/send_email`, {
			templateId: id,
			language: lang,
			receiver: email,
			instanceCode
		});
	},

	getEmailTypes() {
		return axiosInstance.get('/administration/email_administration/email_types').then((res) => res.data);
	},
};
