import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface AppendProps {
	label: string;
	rowItem: { id: string; value: any; inherited: any };
}

export const AppendPreview: FC<AppendProps> = ({ label, rowItem }) => {
	const { t } = useTranslation();
	const { inherited } = rowItem;
	return (
		<div className="accordion_item" style={{ paddingBottom: 0, borderBottom: 'none', marginBottom: '-10px' }}>
			<div className="accordion_header form_row" style={{ paddingBottom: 0 }}>
				<div className="acc_label" style={{ opacity: 0 }}>
					<div className={`row_title`}>{label}</div>
				</div>

				<div className="option disabled">
					<label className="switch" style={{ opacity: 0 }}>
						<input type="checkbox" className="switch_mark" />
						<span className="slider"></span>
						<span className="checkbox_label">
							<span className="on_label">{t('geo.special')}</span>
							<span className="off_label">{t('geo.inherited')}</span>
						</span>
					</label>
				</div>

				<div className="value_content">
					<input
						type="text"
						readOnly
						value={inherited == null ? '' : inherited}
						className={`normal  full_w disabled`}
					/>
				</div>
			</div>
		</div>
	);
};
