import { axiosInstance } from '../config';
import { TransactionsType } from '../../pages/reports/transactions/types';

export const transactionsReport = {
	getTransactionsReport(params: TransactionsType): Promise<any> {
		return axiosInstance.post(`/reporting/transaction_report`, params).then((res) => res.data);
	},

	getTransactionType(): Promise<string[]> {
		return axiosInstance.get('/reporting/transaction_types').then((res) => res.data);
	},
};
