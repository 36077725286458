import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import Dropdown from '../../../../../components/general/Dropdown';
import NotificationModal from '../../../../../components/general/NotificationModal';
import Modal from '../../../../../components/Modals';
import { useUser } from '../../../../../context/UserProvider';
import { useNotify } from '../../../../../hooks/useNotifications';
import Delete from '../../../../../utils/svg/Delete';
import { toMoneyFormat } from '../../../../../utils/toFixed';
import { useOnlineUser } from '../../../context/OnlineUserContext';

interface Props {
	depositWithdrawData: any;
	refetchWalletBalance: () => void;
	close: () => void;
}

export const DepositWithdrawModal: FC<Props> = ({ depositWithdrawData, close, refetchWalletBalance }) => {
	const { t } = useTranslation();
	const { success, error } = useNotify();
	const { walletType, transactionSubtypes } = depositWithdrawData;
	const [reservationData, setReservationData] = useState<any>(null);
	const [isDisabled, setIsDisabled] = useState(false);

	const { user } = useUser();
	const {
		onlineUser: { currency, id },
		refetchUser,
	} = useOnlineUser();

	const [transactionType, setTransactionType] = useState('DEPOSIT');
	const [transactionSubtype, setTransactionSubtype] = useState(transactionSubtypes[0]);

	const [amount, setAmount] = useState('');
	const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		const [_, decimals] = value.split('.');
		if (decimals?.length > 2) return;
		if (value && +value < 1) return;
		setAmount(value);
	};

	const createDepositWithdraw = async () => {
		try {
			setIsDisabled(true);
			await api.users.createDepositWithdraw({
				operator: { uuid: user?.sub, email: user?.email },
				reservation_data: reservationData,
			});

			success('toast.success.transactionCreated');
			refetchUser();
			refetchWalletBalance();
			close();
		} catch (err: any) {
			const errMsg = err?.response?.data?.detail || t('toast.error.defaultText');
			error(errMsg);
			setIsDisabled(false);
		}
	};

	const disabled = !amount;
	return (
		<Modal
			opened={true}
			size="md"
			align="start"
			direction="column"
			cancelAction={close}
			header={<h2 className="modal_title padding_left_20">{walletType}</h2>}
			body={
				<div className="tab_content_wrap">
					<div className="tab_content active">
						<div className="align_center padding_right_50">
							<span className="margin_left_20 ">{t('transactions.typeShort')}</span>
							<div>
								<Dropdown
									items={['DEPOSIT', 'WITHDRAWAL'].map((type: string) => ({ id: type, name: type }))}
									selectedItem={transactionType}
									selectItem={setTransactionType}
									listClass={'left_0'}
									margins={['0', '0', '0', '10']}
									icon="mdi mdi-menu-down"
									highlightLabel={!!transactionType}
								/>
							</div>
						</div>

						<div className="align_center padding_right_50">
							<span className="margin_left_20 ">{t('transactions.subTypeShort')}</span>
							<div>
								<Dropdown
									items={transactionSubtypes.map((type: string) => ({ id: type, name: type }))}
									selectedItem={transactionSubtype}
									selectItem={setTransactionSubtype}
									listClass={'left_0'}
									margins={['0', '0', '0', '10']}
									icon="mdi mdi-menu-down"
									highlightLabel={!!transactionSubtype}
								/>
							</div>
						</div>
						<div className="flex_column margin_top_20 margin_left_20">
							<span className="margin_bottom_5">{t('general.amount')}</span>
							<input
								type="number"
								step="any"
								min={1}
								className="col-7 normal text_center"
								value={amount}
								onChange={handleAmountChange}
							/>
						</div>
					</div>
					{reservationData && (
						<NotificationModal
							opened={true}
							cancelText={t('general.cancel')}
							confirmText={t('general.confirm')}
							cancelAction={() => setReservationData(null)}
							confirmAction={createDepositWithdraw}
							zIndex={'over_3'}
							isDisabled={isDisabled}
						>
							<div className="context_icon margin_top_40">
								<Delete />
							</div>
							<h2 className="modal_message capitalize text_center">{t('general.areSure')}</h2>
							<p className="text_center margin_top_10">
								{t('transactions.depositWithdrawCheckMsg', {
									type:
										reservationData.transactionType === 'DEPOSIT'
											? t('general.deposit')
											: t('general.withdraw'),
									amount: toMoneyFormat(+amount),
									currency,
									subType: reservationData.transactionSubtype,
									direction:
										reservationData.transactionType === 'DEPOSIT' ? t('general.to') : t('general.from'),
								})}
							</p>
						</NotificationModal>
					)}
				</div>
			}
			footer={
				<>
					<button className="btn md round outline " onClick={close}>
						{t('general.cancel')}
					</button>
					<button
						className={`btn md round green ${disabled ? 'disabled' : ''}`}
						disabled={disabled}
						onClick={() =>
							setReservationData({
								idUser: id,
								idProvider: null,
								idApplication: null,
								transactionType,
								transactionSubtype,
								transactionIssuer: null,
								currency,
								amountIn: transactionType === 'DEPOSIT' ? amount : 0,
								amountOut: transactionType === 'WITHDRAWAL' ? amount : 0,
								ticketId: null,
								externalTransactionId: null,
								accountType: walletType,
							})
						}
					>
						{t('transactions.createTransaction')}
					</button>
				</>
			}
		/>
	);
};
