import React, { FC, useState } from 'react';
import { Transaction } from '../../types';
import { formatDate } from '../../../../../utils/getDate';
import { toMoneyFormat } from '../../../../../utils/toFixed';
import { useUserConfig } from '../../../../../context/UserConfigProvider';
import { INTERNET_TRANSACTIONS_COLUMNS } from '../../../../../consts/userConfig';
import api from '../../../../../api';
import { TicketItemExtended } from '../../../../reports/tickets/types';
import { CANCELED_REQUEST } from '../../../../../api/config';
import { useNotify } from '../../../../../hooks/useNotifications';
import getErrorText from '../../../../../utils/getErrorMsg';
import { useTranslation } from 'react-i18next';

interface Props {
	transaction: Transaction;
	showUsername: boolean;
	selectTicket: React.Dispatch<React.SetStateAction<TicketItemExtended | null>>;
}
export const TableRow: FC<Props> = ({ transaction, showUsername, selectTicket }) => {
	const { isColumnVisible } = useUserConfig();
	const { t } = useTranslation();
	const { warn } = useNotify();

	const [disabledRowClick, setDisabledRowClick] = useState(false);

	const {
		id,
		ticketId,
		username,
		accountType,
		amountIn,
		amountOut,
		application,
		transactionType,
		transactionIssuer,
		currency,
		providerCode,
		dtCreated,
		transactionSubtype,
		previousWalletBalance,
		backofficeUserEmail,
	} = transaction;

	const [created] = formatDate(dtCreated, 'date.month.year | hours:minutes:seconds');

	const handleSelect = async () => {
		if (!ticketId) return;
		try {
			setDisabledRowClick(true);
			const ticketData = await api.tickets.getHistoryTicket(ticketId);

			selectTicket(ticketData);
		} catch (error: any) {
			if (error === CANCELED_REQUEST) return;
			warn(getErrorText(error) || 'toast.error.defaultText');
		} finally {
			setDisabledRowClick(false);
		}
	};

	return (
		<tr className={`${disabledRowClick ? 'disabled' : ''}`}>
			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.ID) && <td className="">{id}</td>}
			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.USER) && <td>{showUsername ? username : ticketId}</td>}
			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.AGENT) && <td>{backofficeUserEmail}</td>}
			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.CREATED_AT) && (
				<td className="time_stamp">
					<span className="date ">{created}</span>
				</td>
			)}
			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.ACCOUNT_TYPE) && <td className="">{accountType}</td>}
			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.CURRENCY) && <td className="">{currency}</td>}
			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.TYPE) && <td className="">{transactionType}</td>}
			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.SUB_TYPE) && <td className="">{transactionSubtype}</td>}
			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.PAYMENT_PROVIDER) && <td className="">{transactionIssuer}</td>}

			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.GAME_PROVIDER) && <td className="">{providerCode}</td>}
			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.APPLICATION) && <td className="">{application}</td>}

			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.TICKET) && transactionSubtype === 'BETTING' && ticketId && (
				<td onClick={handleSelect}>
					<div className="tbl_btn  green">
						<i className="mdi mdi-eye md tooltip_left">
							<span className="tooltip_content green">{t('general.openTicket')}</span>
						</i>
					</div>
				</td>
			)}

			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.TICKET) && (transactionSubtype !== 'BETTING' || !ticketId) && (
				<td></td>
			)}

			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.AMOUNT_IN) && (
				<td className="text_right">{toMoneyFormat(amountIn)}</td>
			)}
			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.AMOUNT_OUT) && (
				<td className="text_right">{toMoneyFormat(amountOut)}</td>
			)}
			{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.PREVIOUS_WALLET_BALANCE) && (
				<td className="text_right">{toMoneyFormat(previousWalletBalance || 0)}</td>
			)}
		</tr>
	);
};
