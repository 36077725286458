import axios from 'axios';
import { axiosInstance, Response } from './../config';
import { id } from '../../types/general';

const CancelToken = axios.CancelToken;
let cancel: any;

export const competitors = {
	getCompetitors(sportId: string, providerId: string, categoryId: string, tournamentId: string): Response {
      cancel && cancel();
		return axiosInstance
			.get(`/mappings/data/competitors`, {
				params: {
					sportId: sportId,
					providerId: providerId,
					categoryId: categoryId,
					tournamentId: tournamentId
				},
				cancelToken: new CancelToken(function executor(c) {
					cancel = c;
				})
			})
			.then((res) => res.data);
	},
	linkCompetitors(providerId: id, localItemId: id, providerItemId: id) {
		return axiosInstance.post(`/mappings/map/${providerId}/data/competitors`, {
			localItemId: localItemId,
			providerItemId: providerItemId
		});
	},
	unpairCompetitors(providerId: id, providerItemId: id) {
		const encodedId = encodeURIComponent(encodeURIComponent('' + providerItemId));
		return axiosInstance.delete(`/mappings/map/${providerId}/data/competitors/${encodedId}`);
	},
	importCompetitors(providerId: id, providerItemId: id) {
		return axiosInstance.post(`/mappings/import/${providerId}/data/competitors`, {
			providerItemId: providerItemId
		});
	}
};
