import React, { useState, useEffect, FC, useMemo } from 'react';
import { Player } from '../../types';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import { PlayersSelector } from './PlayersSelector';
import { useFormContext, useWatch } from 'react-hook-form';
import Button from '../../../../../components/general/Button';
import { Sport } from '../../../sports/types';
import { SportIcon } from '../../../sports/components/SportIcon';

export const PlayersList: FC<{ sports: Sport[] }> = ({ sports }) => {
	const { t } = useTranslation();
	const { getValues, setValue, control } = useFormContext();

	const { id } = getValues();
	const players: Player[] = getValues('players');
	const sport = getValues('sport');

	const formPlayers = useWatch({
		control,
		name: 'players',
		defaultValue: players,
	});

	const setPlayers = (players: Player[]) => {
		setValue('players', players);
		closeModal();
	};

	const removePlayer = (id: string) => {
		setValue(
			'players',
			players.filter((field) => field.id !== id)
		);
	};

	const resetPlayers = () => {
		setValue('players', []);
	};

	useEffect(() => {
		if (formPlayers.length) {
			const sportId = formPlayers[0].id.split(':')[1];
			if (sportId !== sport.id) resetPlayers();
		}
	}, [sport]);

	const [modalOpened, setModalOpen] = useState(false);

	const closeModal = () => {
		setModalOpen(false);
	};

	const sportItem = useMemo(() => {
		return sports.find((item) => item.id === sport.id);
	}, [sport]);

	return (
		<>
			<SimpleBar
				className="col-12 mid_col direction_column padding_left_10 padding_right_10 padding_top_10 "
				style={{ overflowY: 'auto', overflowX: 'hidden' }}
			>
				<>
					{sport.id ? (
						<div className="divider_horizontal small label_left ltr_direction margin_bottom_10">
							<div className="div_label">
								<span className="padding_right_10">{t('playersAdministration.addPlayer')}</span>
								<Button type="button" className="blue" name={'+'} onClick={() => setModalOpen(true)} />
							</div>
							<div className="thin_line"></div>
						</div>
					) : (
						<h3 className="text_center">{t('playersAdministration.selectSportFirst')}</h3>
					)}

					{formPlayers.map(({ id, name }) => (
						<div key={id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<div className="label margin_left_10" style={{ display: 'flex', alignItems: 'center' }}>
								{sportItem ? <SportIcon sport={sportItem} tooltip="right" /> : null}
								<span className="margin_left_20"> {`${name}`}</span>
							</div>

							<span className="mdi mdi-close tooltip_left text_redish small" onClick={() => removePlayer(id)}>
								<span className="tooltip_content redish">{t('playersAdministration.removeCompetitor')}</span>
							</span>
						</div>
					))}
				</>
			</SimpleBar>

			{modalOpened && sportItem ? (
				<PlayersSelector
					setPlayers={setPlayers}
					selected={formPlayers}
					closeModal={closeModal}
					defaultSport={sportItem}
				/>
			) : null}
		</>
	);
};
