import React, { CSSProperties, FC } from 'react';
import { useRiskTag } from './useRiskTag';

interface Props {
	idTag: string;
}

export const RiskTagCell: FC<Props> = ({ idTag }) => {
	const tag = useRiskTag(idTag);
	if (!tag) return <td></td>;
	const style: CSSProperties = { backgroundColor: tag.color };

	return (
		<td className="text_right">
			<div className="status_item flex_justify_center ">
				<div className="status_dot blue tooltip_right" style={style}>
					<span className="tooltip_content">{tag?.name}</span>
				</div>
			</div>
		</td>
	);
};
