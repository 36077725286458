import React, { FC, useEffect, useState, useMemo, ChangeEvent } from 'react';
import { useGeo } from '../../context';
import { float, integer, validateRows } from './validators';
import { chunkArray } from '../../../../../utils/chunkArray';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import { Item } from '../../../../../types/general';
import Dropdown from '../../../../../components/general/Dropdown';
import { useNotify } from '../../../../../hooks/useNotifications';
import getErrorText from '../../../../../utils/getErrorMsg';

interface Props {
	locked: string;
	rowItem: { id: string; value: any; inherited: any };
}

export const CsvTable: FC<Props> = ({ locked, rowItem }) => {
	const { t } = useTranslation();
	const { id, value, inherited } = rowItem;
	const { fieldMap, updateGeoParameters, geoParameters } = useGeo();
	const { delimiter, headers } = fieldMap[id].field_type_specifics;
	const { error } = useNotify();

	const [options, setOptions] = useState<Item[]>([]);
	const [newRowOption, setNewRowOption] = useState('');

	const [rows, setRows] = useState<string[][]>([]);
	const [isValidCsv, setIsValidCsv] = useState(true);

	const [headings, dataTypes] = useMemo(() => {
		const headings: string[] = [];
		const dataTypes: string[] = [];
		headers.forEach((header: { [key: string]: string }) => {
			const [headerLabel, dataType] = Object.entries(header)[0];
			headings.push(headerLabel);
			dataTypes.push(dataType);
		});
		return [headings, dataTypes];
	}, [headers]);

	useEffect(() => {
		const item = dataTypes.find((type) => typeof type === 'object') as any;
		if (item) {
			(async function getData() {
				try {
					const { values } = await api.geo.getCsvFieldData({ idGeoEntity: geoParameters.id }, item.uri);
					const formatedOptions = values.map(([id, name]: string[]) => ({ id, name }));
					setOptions(formatedOptions);
					if (formatedOptions.length) setNewRowOption(formatedOptions[0].id);
				} catch (err) {
					error(getErrorText(err) || 'toast.error.defaultText');
				}
			})();
		}
	}, [dataTypes]);

	useEffect(() => {
		const val = locked ? inherited : value;
		if (typeof val === 'string' && val !== '') {
			const rowsArray = chunkArray(val.split(delimiter), headers.length);
			setIsValidCsv(validateRows(rowsArray, headings, dataTypes));
			setRows(rowsArray);
		} else setRows([]);
	}, [value, locked, inherited]);

	const updateCsvString = (rows: string[][]) => {
		const rowsString = rows
			.reduce((prev, curr) => [...prev, ...curr.map((item) => `${item}${delimiter}`)], [])
			.join('')
			.slice(0, -1);
		updateGeoParameters(id, rowsString);
	};

	const updateTable = (value: string, rowIndex: number, columnIndex: number) => {
		let newRows = [...rows];
		newRows[rowIndex][columnIndex] = value;
		updateCsvString(newRows);
	};

	const addRow = (index: number | null = null) => {
		const newRow = dataTypes.map((type) => {
			switch (true) {
				case type === 'int':
					return '1';
				case type === 'float':
					return '1.01';
				case type === 'bool':
					return 'false';
				case type === 'percentage':
					return '0.01';
				case type === 'text':
					return '';
				case typeof type === 'object':
					return newRowOption;
				default:
					return '1';
			}
		});
		let newRows = [...rows];

		if (typeof index === 'number') {
			newRows.splice(index + 1, 0, newRow);
		} else {
			newRows = [...rows, newRow];
		}

		updateCsvString(newRows);
	};

	const deleteRow = (rowIndex: number) => {
		const newRows = rows.filter((_, i) => i !== rowIndex);
		updateCsvString(newRows);
	};

	const disableClass = locked ? 'disabled' : '';

	return (
		<div className="accordion_collapse">
			<div className="range_title_option"></div>
			<div className="range_input padding_top_20 padding_bottom_20">
				{isValidCsv || !value || locked ? (
					<>
						<table className={`admin_table full_w ${disableClass} `}>
							<thead className="t_head">
								<tr>
									{headings.map((heading, i) => (
										<th key={heading} className="font_400">
											{`${heading} ${dataTypes[i] === 'percentage' ? '%' : ''}`}
										</th>
									))}
									<th className="font_400 text_right">{t('general.delete')}</th>
									<th className="font_400 text_right"></th>
								</tr>
							</thead>
							<tbody>
								{rows.map((row, rowIndex) => (
									<tr key={`${id}:${rowIndex}`} className="csv_row">
										{row.map((rowItem, index) => (
											<RowItem
												key={`${rowIndex}:${index}`}
												value={rowItem}
												rowIndex={rowIndex}
												columnIndex={index}
												dataType={dataTypes[index]}
												updateTable={updateTable}
												selectOptions={options}
											/>
										))}
										<td className="td_input text_right">
											<i
												className="mdi mdi-close-circle redish_text_hover"
												onClick={() => deleteRow(rowIndex)}
											></i>
										</td>
										<td className="td_input text_right insert_row_v  tooltip_top">
											<i
												className="mdi mdi-table-row-plus-after blue_text_hover"
												onClick={() => addRow(rowIndex)}
											>
												<span className="tooltip_content">{t('geo.insertRow')}</span>
											</i>
										</td>
									</tr>
								))}
							</tbody>
						</table>

						{!locked ? (
							<div
								className={`full_w align_center flex_justify_center padding_6 margin_top_${
									newRowOption ? '100' : '10'
								}`}
							>
								<button className="btn light_blue" onClick={() => addRow()}>
									{t('general.newRow')}
								</button>
							</div>
						) : null}
					</>
				) : (
					<h3 className="full_w align_center flex_justify_center padding_6">{t('geo.wrongCsv')}</h3>
				)}
			</div>
		</div>
	);
};

interface RowProps {
	value: any;
	dataType: string;
	rowIndex: number;
	columnIndex: number;
	updateTable: (value: string, rowIndex: number, columnIndex: number) => void;
	selectOptions: Item[];
}

const RowItem: FC<RowProps> = ({ value, dataType, rowIndex, columnIndex, updateTable, selectOptions }) => {
	const update = (val: string) => {
		updateTable(val, rowIndex, columnIndex);
	};

	const component = () => {
		switch (true) {
			case dataType === 'float':
				return <Number value={value} update={update} validator={float} />;
			case dataType === 'int':
				return <Number value={value} update={update} validator={integer} />;
			case dataType === 'bool':
				return <Checkbox value={value} update={update} />;
			case dataType === 'percentage':
				return <Percentage value={value} update={update} validator={float} />;
			case dataType === 'text':
				return <TextInput value={value} update={update} />;
			case typeof dataType === 'object':
				return <Select value={value} update={update} options={selectOptions} />;
			default:
				return null;
		}
	};
	return <>{component()}</>;
};

interface NumberProps {
	value: string;
	validator: any;
	update: (value: string) => void;
}

const Number: FC<NumberProps> = ({ value, update, validator }) => {
	const errorClass = value === '' ? 'error_input_V' : '';

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		let value = e.currentTarget.value;

		const isValid = validator(+value);
		if (isValid) update(`${value}`);
	};

	return (
		<td className="td_input">
			<input
				className={errorClass}
				type="number"
				value={value}
				onChange={handleChange}
				onWheel={(e) => e.currentTarget.blur()}
			/>
		</td>
	);
};

interface TextProps {
	value: string;
	update: (value: string) => void;
}

const TextInput: FC<TextProps> = ({ value, update }) => {
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		update(value);
	};

	return (
		<td className="td_input">
			<input type="text" value={value} onChange={handleChange} onWheel={(e) => e.currentTarget.blur()} />
		</td>
	);
};

interface CheckboxProps {
	value: string;
	update: (value: string) => void;
}

const Checkbox: FC<CheckboxProps> = ({ value, update }) => {
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		update(`${e.currentTarget.checked}`);
	};
	return (
		<td className="td_input text_center">
			<div className="checkbox_custom chek_only tooltip_right">
				<input type="checkbox" placeholder="nesto" checked={value === 'true'} onChange={handleChange} />
				<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
				<i className="mdi mdi-checkbox-marked checked"></i>
			</div>
		</td>
	);
};

const Percentage: FC<NumberProps> = ({ value, update, validator }) => {
	const errorClass = value === '' ? 'error_input_V' : '';

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		let value = e.currentTarget.value;
		const isValid = validator(+value);
		if (isValid) update(value ? `${+value / 100}` : '');
	};

	return (
		<td className="td_input">
			<input
				className={errorClass}
				type="number"
				onChange={handleChange}
				onWheel={(e) => e.currentTarget.blur()}
				value={value !== '' && value !== null && value !== undefined ? Math.round(+value * 10000) / 100 : ''}
			/>
		</td>
	);
};

interface SelectProps {
	value: string;
	update: (value: string) => void;
	options: Item[];
}

const Select: FC<SelectProps> = ({ value, update, options }) => {
	const { t } = useTranslation();
	return (
		<td className="td_input">
			<Dropdown
				listClass={'left_0 z_10 maxHeight_156'}
				items={options}
				margins={['0', '0', '0', '0']}
				selectItem={(param) => {
					update(param);
				}}
				selectedItem={value}
				icon={'mdi mdi-menu-down'}
				nonSelectedString={t('general.selectOption')}
				disableScroll
			/>
		</td>
	);
};
