import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import { AvailableOption, ParameterType, SelectedOption } from './types';

export function useParamProps() {
	const { t } = useTranslation();
	const [paramType, setParamType] = useState<ParameterType>('');
	const [allOptions, setAllOptions] = useState<AvailableOption[]>([]);
	const [selectedOptions, selectOptions] = useState<SelectedOption[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const { getValues, setValue } = useFormContext();

	const setParameters = (list: SelectedOption[]) => {
		setValue(`parameters.${paramType}`, list);
	};

	const apiCalls = {
		conditions: api.conditions.getConditions,
		limits: api.limits.getLimits,
		authorizations: api.authParams.getAuthParams,
	};

	const handleOnClick = async (type: ParameterType) => {
		try {
			setLoading(true);
			const data = await apiCalls[type]();
			const all = data.map(({ id, name }: { id: string; name: string }) => ({ id, name }));
			setAllOptions(all);
			selectOptions(getValues(`parameters.${type}`));
			setParamType(type);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const closeParametersModal = () => {
		setAllOptions([]);
		selectOptions([]);
		setParamType('');
	};

	const getGlobal = (type: ParameterType) => {
		const values = getValues(`parameters.${type}`) as SelectedOption[];
		const name = values && values.find((element) => !element.idCompany)?.name;
		return name ? name : t('general.noneSelected');
	};

	return {
		paramType,
		allOptions,
		selectedOptions,
		setParameters,
		handleOnClick,
		closeParametersModal,
		getGlobal,
		loading,
		setLoading,
	};
}
