import React, { FC } from 'react';
import InPortal from '../../../InPortal';
import { useTranslation } from 'react-i18next';
import { FullOdd } from '../../types';
import { toFixed } from '../../../../../utils/toFixed';
import { IdName } from '../../../../../pages/risk-management/controller/types';

interface Props {
	home: string;
	away?: string;
	sport?: IdName;
	list_code?: number;
	date: string;
	time: string;
	fullOdds: FullOdd[];
	setEventInfoModal: (hideModal: boolean) => void;
}

export const EventInfo: FC<Props> = ({ home, away, sport, list_code, date, time, fullOdds, setEventInfoModal }) => {
	const { t } = useTranslation();

	return (
		<InPortal>
			<div className={`modal_cover sm over_1 toggle`}>
				<div className="modal_content fadeInDown animated faster">
					<div className="modal_head">
						<div className="modal_title padding_10 justify_center">Event info</div>
					</div>
					{/* Body */}
					<div className="modal_body  direction_column padding_10">
						<div className="form_row justify_center">
							<strong className="uppercase">{home}</strong>
							{away ? (
								<>
									<strong className="uppercase">&nbsp; vs &nbsp; </strong>{' '}
									<strong className="uppercase">{away}</strong>
								</>
							) : null}
							<div className="sport tooltip_left">
								{' '}
								<svg className="e_ico">
									<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${sport?.id}`}></use>
								</svg>
								<span className="tooltip_content">{sport?.name}</span>
							</div>
						</div>
						<div className="form_row justify_center">
							<label>
								{t('general.listCode')}: {list_code}
							</label>
						</div>
						<div className="form_row justify_center">
							<label>
								{t('general.dateAndTime')}: {date} | {time}{' '}
							</label>
						</div>
						<div className="form_row justify_center">
							<strong>
								{fullOdds.map(({ marketName, special_value }, i) => (
									<div key={`${i}:${i}:${marketName}:${i}`} className="item_wrap">
										{`${marketName} ${special_value ? `| ${special_value}` : ''}`}
									</div>
								))}
							</strong>
						</div>
						<div className="form_row justify_center">
							<div className="cell stake_gray">
								<div className="cell type_short">
									{fullOdds.map(({ name, description, bonus_type }, i) => {
										const namePreview = bonus_type ? `B${name}` : name;
										return (
											<div key={`${i}:${i}:${name}:${i}`} className="item_wrap" title={description}>
												<label>{t('general.odd')}: </label>
												<strong>{namePreview}</strong>
											</div>
										);
									})}
								</div>
							</div>
						</div>
						<div className="form_row justify_center">
							<label>{t('general.stake')}: </label>
							<div className="cell stake row" style={{ maxWidth: 160 }}>
								{fullOdds.map((odd, i) => {
									const color =
										odd.result === 1 ? 'green' : odd.result === 0 ? 'red' : odd.result === -1 ? 'yellow' : '';
									return (
										<div key={`${i}:${i}:${odd.value}:${i}`} className="item_wrap">
											<button className={`stake_btn ${color}`}>
												{odd?.voided ? `1.00` : toFixed(odd.value)}
											</button>
										</div>
									);
								})}
							</div>
						</div>
					</div>

					<div className="modal_footer col_border_top">
						<button className="btn md round outline" onClick={() => setEventInfoModal(false)}>
							{t('general.close')}
						</button>
					</div>
				</div>
			</div>
		</InPortal>
	);
};
