import React, { useState, FC } from 'react';

import api from '../../../../../api';
import { useTranslation } from 'react-i18next';
import { useTicket } from '../../context/TicketContext';
import { useAxios } from '../../../../../hooks/useAxios';
import { Node } from '../../../../../pages/parameters/geo/types';
import { useRiskTag } from '../../../../../pages/online-user/components/useRiskTag';
import { openInNewTab } from '../../../../../utils/newTab';
import useCheckMobileScreen from '../../../../../hooks/useCheckMobileScreen';
import { formatDate } from '../../../../../utils/getDate';

interface Props {
	showEvents: 'showAuthorizedEvents' | 'showOriginalEvents' | 'showDiff';
	setShowEvents: (events: any) => void;
}

export const TicketSubHeader: FC<Props> = ({ showEvents, setShowEvents }) => {
	const { t } = useTranslation();
	const { ticket, similarTicket, similarTicketShown } = useTicket();
	const { userInfo, riskManager, idLocation, idUser, fullUserInfo, operator, payoutOperator, paidOut, dtPaidOut } =
		ticket;
	const dtPaidOutTime = paidOut && dtPaidOut ? formatDate(dtPaidOut, 'date.month', 'hours:minutes:seconds') : [];

	const { screen } = useTicket();

	const { data: location } = useAxios<any>({
		apiFunction: async function getLocation() {
			if (fullUserInfo && fullUserInfo.geolocation) return fullUserInfo.geolocation;
			return await api.geo.getSingleEntity(idLocation);
		},
		initialData: {} as Node,
		dependencies: [ticket],
	});

	const cashierOrUserLabel = idUser ? t('general.user') : t('general.cashier');
	const cashierOrUserName = idUser ? userInfo?.email : operator;
	const tag = useRiskTag(fullUserInfo?.riskTag) || null;

	const openUserDetails = () => {
		if (idUser && fullUserInfo?.uid)
			openInNewTab(`${window.location.origin}/internet/user/${fullUserInfo.uid}/tickets`);
	};

	const showCurrenStake = screen === 'cashout-offer' || screen === 'cashout-risk';

	const isMobileView = useCheckMobileScreen();
	const bottomMargin = isMobileView ? 'margin_bottom_13' : 'margin_bottom_4';

	return (
		<div className="ticket_subheader  height_auto">
			{!showCurrenStake ? (
				<div className={`ticket_info_item ${ticket.authStatus !== 3 ? 'hidden_content' : ''}`}>
					<div className={`tab_nav  padding_left_20 padding_right_20 ${bottomMargin}`}>
						<div
							className={`tab_nav_item ${showEvents === 'showAuthorizedEvents' ? 'active' : ''}`}
							onClick={() => setShowEvents('showAuthorizedEvents')}
						>
							AUTORIZOVAN
						</div>
						<div
							className={`tab_nav_item ${showEvents === 'showOriginalEvents' ? 'active' : ''}`}
							onClick={() => setShowEvents('showOriginalEvents')}
						>
							ORIGINALAN
						</div>
						<div
							className={`tab_nav_item ${showEvents === 'showDiff' ? 'active' : ''}`}
							onClick={() => setShowEvents('showDiff')}
						>
							SHOW DIFF
						</div>
					</div>
				</div>
			) : null}

			<div className="ticket_info_item">
				<div className="label">{t('general.location')}:</div>
				<div className="content">
					{location?.shortCode || ''}
					<small>{location?.name || ''}</small>
				</div>
			</div>

			<div className="ticket_info_item">
				<div className="label">
					{t('reports.tickets.paidInBy')}
					{` ${cashierOrUserLabel}`}:
				</div>
				<div className="content">
					{idUser ? (
						<span className="font_700 risk_online_user_name tooltip_right" onClick={openUserDetails}>
							{cashierOrUserName}
							<span className="tooltip_content">Open user details</span>
						</span>
					) : (
						<span>{cashierOrUserName}</span>
					)}
				</div>
				{tag ? (
					<div className="status_item margin_left_20">
						<div className="status_dot green tooltip_right" style={{ backgroundColor: tag.color }}>
							<span className="tooltip_content">{tag.name}</span>
						</div>
					</div>
				) : null}
				{fullUserInfo?.idRiskGroup && (
					<>
						<div className="label margin_left_20">{t('general.riskGroup')}:</div>
						<div className="content">
							<span>{`${fullUserInfo.idRiskGroup} | ${fullUserInfo?.riskGroupName}`}</span>
						</div>
					</>
				)}
			</div>

			{paidOut ? (
				<>
					<div className="ticket_info_item">
						<div className="label">
							{t('reports.tickets.paidOutBy')}
							{payoutOperator ? ` ${cashierOrUserLabel}` : ''}:
						</div>
						<div className="content">{payoutOperator || t('reports.tickets.paidOutByServer')}</div>
					</div>
					{dtPaidOutTime?.length ? (
						<div className="ticket_info_item">
							<div className="label">{t('reports.tickets.paitOutDateAndTime')}:</div>
							<div className="content">{dtPaidOutTime?.length ? dtPaidOutTime.join(' | ') : null}</div>
						</div>
					) : null}
				</>
			) : null}
			<div className="ticket_info_item">
				<div className="label">{t('risk.bookmaker')}:</div>
				<div className="content">
					{riskManager?.email || ''}
					{similarTicketShown ? (
						<span className="similar_ticket_field"> | {similarTicket?.riskManager?.email || ' - '}</span>
					) : null}
				</div>
			</div>
		</div>
	);
};
