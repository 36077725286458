import { isEmpty } from 'lodash';
import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import NotificationModal from '../../../../../components/general/NotificationModal';
import { useNotify } from '../../../../../hooks/useNotifications';
import { formatDate } from '../../../../../utils/getDate';
import Delete from '../../../../../utils/svg/Delete';
import { toMoneyFormat } from '../../../../../utils/toFixed';
import { useOnlineUser } from '../../../context/OnlineUserContext';
import { Reservation as ReservationType } from '../types';
import { CreateReservation } from './CreateReservation';
import { UpdateTime } from './UpdateTime';

interface Props {
	reservation: ReservationType | null;
	updateReservation: (reservation: ReservationType | null) => void;
}

export const Reservation: FC<Props> = ({ reservation, updateReservation }) => {
	const {
		onlineUser: { uid, currency, internetBalance, email },
		refetchUser,
	} = useOnlineUser();
	const { t } = useTranslation();
	const {
		dtCreated = '',
		reservedAmount = 0,
		dtAvailable = '',
		dtExpires = '',
		pin,
		idLocation,
		payoutType,
	} = reservation || {};
	const [locationName, setLocationName] = useState('');
	const [address, setAddress] = useState('');

	const [reservationModal, setReservationModal] = useState(false);
	const [updateTimeModal, setUpdateTimeModal] = useState(false);
	const [confirmModal, setConfirmModal] = useState(false);

	useEffect(() => {
		(async () => {
			if (!idLocation) return;
			const location = await api.geo.getSingleEntity(idLocation);
			setLocationName(location?.name || '');
			//@ts-ignore
			setAddress(`${location?.address || ''}${location?.city ? `, ${location?.city}` : ''}`);
		})();
	}, [reservation]);

	const { success, warn } = useNotify();

	const openReservationModal = () => {
		if (!isEmpty(reservation)) return warn(t('toast.warn.reservationExists'), { autoClose: 5000 });
		setReservationModal(true);
	};

	const closeReservationModal = () => {
		setReservationModal(false);
	};

	const openUpdateModal = () => {
		setUpdateTimeModal(true);
	};

	const closeUpdateModal = () => {
		setUpdateTimeModal(false);
	};

	const openConfirmModal = () => {
		setConfirmModal(true);
	};

	const closeConfirmModal = () => {
		setConfirmModal(false);
	};

	const [isDisabled, setIsDisabled] = useState(false);
	const cancelReservation = async () => {
		if (!reservation) return;
		try {
			setIsDisabled(true);
			await api.users.cancelReservation(uid);
			updateReservation(null);
			refetchUser();
			success(t('toast.success.reservationCanceled'));
		} catch {
			warn(t('toast.warn.reservationCancelationFailed'));
		} finally {
			setIsDisabled(false);
			closeConfirmModal();
		}
	};

	const [manualReservation, setManualReservation] = useState<ReservationType | null>(null);

	const handleManualReservationPayout = async () => {
		try {
			await api.users.manualReservationPayout(uid);
			success(
				`${t('reports.reservations.successfulPayout')} - ${email} ${
					reservedAmount > internetBalance ? toMoneyFormat(internetBalance) : toMoneyFormat(reservedAmount)
				} ${currency}`,
				{ autoClose: 5000 }
			);
			setManualReservation(null);
			refetchUser();
			updateReservation(null);
		} catch (err: any) {
			const errMsg = err?.response?.data?.detail || t('toast.error.defaultText');
			warn(errMsg);
		}
	};

	const timeFormat = 'hours:minutes | date.month.year.';

	return (
		<div className="shrink_1366 flex_direction_row padding_20">
			<div className="col-12 padding_right_40 padding_top_40">
				<table className="admin_table sticky_header full_w">
					<thead className="t_head">
						<tr>
							<th scope="col">{t('usersAdministrations.created')}</th>
							<th scope="col">{t('usersAdministrations.payoutType')}</th>
							<th scope="col">{t('usersAdministrations.reservedAmount')}</th>
							<th scope="col">{t('usersAdministrations.availableFrom')}</th>
							<th scope="col">{t('usersAdministrations.expires')}</th>
							<th scope="col">{t('general.location')}</th>
							<th scope="col">{t('usersAdministrations.address')}</th>
							<th scope="col">{t('usersAdministrations.code')}</th>
							<th scope="col" className="text_right">
								<button className="btn red toggle_modal_11" onClick={openReservationModal}>
									{t('usersAdministrations.createReservation')}
								</button>
							</th>
						</tr>
					</thead>

					{!isEmpty(reservation) ? (
						<tbody className="t_body">
							<tr>
								<td>{dtCreated ? formatDate(dtCreated, timeFormat) : ''}</td>
								<td>{payoutType}</td>
								<td>{toMoneyFormat(reservedAmount)}</td>
								<td>{dtAvailable ? formatDate(dtAvailable, timeFormat) : ''}</td>
								<td>{dtExpires ? formatDate(dtExpires, timeFormat) : ''}</td>
								<td>{locationName}</td>
								<td>{address}</td>
								<td>{pin}</td>
								<td className="action text_right">
									{payoutType === 'BANK' && new Date(dtAvailable + 'Z') < new Date() ? (
										<i
											className="mdi mdi-cash tooltip_left green_01_text_hover margin_right_10 font_32px"
											onClick={() => setManualReservation(reservation)}
										>
											<span className="tooltip_content">{t('usersAdministrations.reservationPayout')}</span>
										</i>
									) : null}
									<i
										className="mdi mdi-pencil tooltip_left green_01_text_hover margin_right_10"
										onClick={openUpdateModal}
									>
										<span className="tooltip_content">{t('usersAdministrations.editAvailable')}</span>
									</i>
									<i
										className="mdi mdi-close-circle tooltip_left redish_text_hover"
										onClick={openConfirmModal}
									>
										<span className="tooltip_content">{t('general.cancel')}</span>
									</i>
								</td>
							</tr>
						</tbody>
					) : null}
					{reservationModal && (
						<CreateReservation close={closeReservationModal} updateReservation={updateReservation} />
					)}
					{updateTimeModal && (
						<UpdateTime
							initialAvailableTime={dtAvailable}
							initialExpirationTime={dtExpires}
							close={closeUpdateModal}
							updateReservation={updateReservation}
						/>
					)}
					{confirmModal && (
						<NotificationModal
							opened={true}
							cancelText={t('general.cancel')}
							confirmText={t('general.confirm')}
							cancelAction={closeConfirmModal}
							confirmAction={cancelReservation}
							isDisabled={isDisabled}
						>
							<div className="context_icon margin_top_40">
								<Delete />
							</div>
							<h4 className="modal_message capitalize text_center padding_10 font_13px">
								{t('usersAdministrations.confirmReservationCancel', {
									pin: reservation?.pin || '',
									created: formatDate(
										reservation?.dtCreated.toString().slice(0, -1) || '',
										'date.month.year. hours:minutes'
									),
									amount: toMoneyFormat(reservation?.reservedAmount || 0) || '',
								})}
							</h4>
						</NotificationModal>
					)}

					{manualReservation ? (
						<NotificationModal
							opened={true}
							cancelText={t('general.cancel')}
							confirmText={t('general.confirm')}
							cancelAction={() => setManualReservation(null)}
							confirmAction={handleManualReservationPayout}
						>
							<div className="context_icon margin_top_40">
								<Delete />
							</div>
							<h4 className="modal_message capitalize text_center padding_10 font_13px">
								{t('usersAdministrations.confirmManualPayout', {
									reservedAmount: toMoneyFormat(manualReservation?.reservedAmount || 0) || '',
									currency,
								})}
							</h4>
						</NotificationModal>
					) : null}
				</table>
			</div>
		</div>
	);
};
