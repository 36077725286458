import React, { FC, useState } from 'react';
import { SelectedEvent } from '../types';
import Button from '../../../general/Button';
import { useTranslation } from 'react-i18next';
import { EventSelector } from './EventSelector';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';
import { Sport } from '../../../../pages/administration/sports/types';
import api from '../../../../api';
import { SportIcon } from '../../../../pages/administration/sports/components/SportIcon';

export const CombinedEvents: FC = () => {
	const { t } = useTranslation();
	const name = 'blockCombinationEvents';

	const [modalOpened, setModalOpen] = useState(false);

	const { getValues, setValue, control } = useFormContext();
	const fields: SelectedEvent[] = getValues(name);
	const sport = getValues('sport');

	const { data: sports } = useAxios<Sport[]>({
		apiFunction: async function getSports() {
			return await api.sports.getList('ALL');
		},
		initialData: [],
		dependencies: [],
	});

	const formEvents = useWatch({
		control,
		name,
		defaultValue: fields,
	});

	const setEvents = (tournaments: SelectedEvent[]) => {
		setValue(name, tournaments);
		closeModal();
	};

	const removeEvent = (id: string) => {
		setValue(
			name,
			fields.filter((field) => field.id !== id)
		);
	};

	const closeModal = () => {
		setModalOpen(false);
	};
	if (!sports.length) return null;
	return (
		<>
			<div className="divider_horizontal small label_left ltr_direction margin_bottom_10  margin_top_20">
				<div className="div_label">
					<span className="padding_right_20">{t('eventsAdministration.addEvents')}</span>
					<Button type="button" className="blue" name={'+'} onClick={() => setModalOpen(true)} />
				</div>
				<div className="thin_line"></div>
			</div>

			{formEvents.map(({ id, competitors, tournament, category, sport }) => {
				const [{ name: homeName }, { name: awayName } = { name: '' }] = competitors || [{}, {}];
				const sportItem = sports.find((item) => item.id === sport.id)!;
				return (
					<div key={id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<div className="label margin_left_10" style={{ display: 'flex', alignItems: 'center' }}>
							<SportIcon sport={sportItem} tooltip="right" />

							<span className="margin_left_20" title={category.name}>
								{`${homeName}${awayName && '-'}${awayName} (${tournament.defaultName})`}
							</span>
						</div>

						<span className="mdi mdi-close tooltip_left text_redish small" onClick={() => removeEvent(`${id}`)}>
							<span className="tooltip_content redish">{t('eventsAdministration.remove')}</span>
						</span>
					</div>
				);
			})}

			{modalOpened ? (
				<EventSelector
					defaultSport={sport}
					setTournaments={setEvents}
					selected={formEvents}
					closeModal={closeModal}
					sports={sports}
				/>
			) : null}
		</>
	);
};
