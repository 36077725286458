import { useState } from 'react';

/**
 * Provides boolean value and a function to toggle it
 *
 * @param { boolean } isToggled starting value ( true | false)
 * @returns { [boolean, () => void,()=>void] }
 */
export default (isToggled: boolean): [boolean, () => void, () => void, () => void] => {
	const [toggled, setToggled] = useState(isToggled);
	const toogle = () => {
		setToggled(!toggled);
	};

	const open = () => {
		setToggled(true);
	};

	const close = () => {
		setToggled(false);
	};

	return [toggled, toogle, close, open];
};
