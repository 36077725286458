import React, { FC, SetStateAction, Dispatch, useMemo } from 'react';
import MultiSelectDropdown from '../../../../../components/general/MultiSelectDropdown';
import { useTranslation } from 'react-i18next';

interface Props {
	transactionSubTypes: string[];
	selectedTransactionSubTypes: string[];
	selectTransactionSubTypes: (subtypes: string[]) => void;
}

export const TransactionSubTypesDropdown: FC<Props> = ({
	selectedTransactionSubTypes,
	selectTransactionSubTypes,
	transactionSubTypes,
}) => {
	const { t } = useTranslation();

	const options = useMemo(() => transactionSubTypes.map((type) => ({ id: type, name: type })), [transactionSubTypes]);
	return (
		<MultiSelectDropdown
			title={t('transactions.subType')}
			marginRight={'10'}
			options={options}
			selectedOptions={selectedTransactionSubTypes}
			selectOption={selectTransactionSubTypes}
			withSearch
		/>
	);
};
