import { BonusType, Currency, Status, UserBonus } from './../../pages/internet/bonuses/types';

import { axiosInstance } from './../config';

interface Filters {
	skip?: number;
	limit?: number;
	queryString?: string;
	status?: Status[];
	bonusType?: BonusType[];
	currency?: Currency[];
}

export const userBonuses = {
	getBonuses({ skip, limit }: { skip: number; limit: number }): Promise<{
		total: number;
		bonuses: UserBonus[];
	}> {
		return axiosInstance.get(`/administration/bonuses/?skip=${skip}&limit=${limit}`).then((res) => res.data);
	},

	getFilteredBonuses(filters: Filters): Promise<{
		total: number;
		bonuses: UserBonus[];
	}> {
		return axiosInstance.post('/administration/bonuses/filter/', filters).then((res) => res.data);
	},

	createBonus(bonus: UserBonus): Promise<UserBonus> {
		return axiosInstance.post(`/administration/bonuses/`, bonus).then((res) => res.data);
	},
	updateBonus(bonus: UserBonus): Promise<UserBonus> {
		return axiosInstance.put(`/administration/bonuses/`, bonus).then((res) => res.data);
	},
	deleteBonus(id: number): Promise<UserBonus> {
		return axiosInstance.delete(`/administration/bonuses/${id}`).then((res) => res.data);
	},
	getPaymentProviders(): Promise<string[]> {
		return axiosInstance.get(`/administration/bonuses/payment_providers`).then((res) => res.data);
	},
};
