import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SportIcon } from '../../../../administration/sports/components/SportIcon';
import { Sport } from '../../../../administration/sports/types';
import { TemplateItem } from '../../../templates/types';
import Dropdown from '../../../../../components/general/Dropdown';

interface Props {
	sport: Sport;
	selectedSports: string[];
	selectSport: (sportId: string) => void;
	templates: TemplateItem[];
	selectedTemplate: string;
	selectTemplate: (sportId: string, templateId: string) => void;
}

export const TableRow: FC<Props> = ({
	sport,
	selectedSports,
	selectSport,
	templates = [],
	selectedTemplate = '',
	selectTemplate,
}) => {
	const { i18n, t } = useTranslation();
	const { language: ln } = i18n;
	const disabled = isEmpty(templates);
	return (
		<tr>
			<td width={150}>
				<div className={`checkbox_custom chek_only tooltip_right ${disabled ? 'disabled' : ''}`}>
					<input
						type="checkbox"
						className={`${disabled ? 'disabled' : ''}`}
						checked={selectedSports.includes(sport?.id)}
						onChange={() => {}}
						onClick={() => !disabled && selectSport(sport?.id)}
					/>
					<i className="mdi mdi-checkbox-blank-outline unchecked" />
					<i className="mdi mdi-checkbox-marked checked" />
					<span className="tooltip_content">{'select'}</span>
				</div>
			</td>
			<td width={50} className="small">
				<SportIcon sport={sport} tooltip="left" />
			</td>
			<td width={300}>{sport?.name?.[ln] || sport?.defaultName}</td>
			<td width={300}>
				<div className="justify_center">
					{isEmpty(templates) ? (
						<span className="font_200 font_11px">{t('listBuilder.export.noTemplates')}</span>
					) : (
						<Dropdown
							items={templates.map((template) => ({
								...template,
								name: template?.name?.[ln] || '',
							}))}
							selectedItem={selectedTemplate}
							selectItem={(id) => selectTemplate(sport?.id, id)}
							margins={['0', '0', '0', '0']}
							nonSelectedString={t('listBuilder.export.selectTemplate')}
							icon={'mdi mdi-menu-down'}
						/>
					)}
				</div>
			</td>
			<td></td>
		</tr>
	);
};
