import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

// Import utils
import history from '../history';

// Import layouts
import { DefaultLayout } from '../../layouts';
import Login from '../../pages/Login';

export default () => (
	<Router history={history}>
		<DefaultLayout>
			<Switch>
				<Route path="*" component={Login} />
			</Switch>
		</DefaultLayout>
	</Router>
);
