import React, { useEffect, ReactNode } from 'react';
import { useKeyboardListener } from '../../hooks/useKeyboardListener';
import InPortal from '../Modals/InPortal';
import { ModalSize } from '../Modals/types';

interface Props {
	opened: boolean;
	cancelText?: string;
	confirmText?: string;
	cancelAction?: () => void;
	confirmAction?: () => void;
	children?: ReactNode;
	zIndex?: string;
	loading?: boolean;
	size?: ModalSize;
	btnColor?: string;
	isDisabled?: boolean;
}

export default ({
	opened,
	children,
	cancelText,
	confirmText,
	cancelAction,
	confirmAction,
	zIndex,
	loading,
	size = 'sm',
	btnColor,
	isDisabled,
}: Props) => {
	useEffect(() => {
		const el = document.getElementById('confirm');
		el?.focus();
	}, []);

	const disableClass = loading ? 'disabled' : '';

	useKeyboardListener('Escape', () => cancelAction?.());
	return opened ? (
		<InPortal>
			<div className={`modal_cover ${size} toggle ${zIndex}`}>
				<div className="modal_content fadeInDown animated faster">
					{/* Header */}
					{cancelAction ? (
						<button data-testid="close-btn" className="btn transparent close " onClick={cancelAction}>
							<i className="mdi mdi-close" />
						</button>
					) : null}

					{/* Body */}
					<div className="modal_body content_start direction_column">{children}</div>

					{/* Footer */}
					<div data-testid="modal-footer" className="modal_footer">
						{cancelText && (
							<button onClick={cancelAction} className={`btn lg round outline ${disableClass}`}>
								{cancelText}
							</button>
						)}

						{confirmText && (
							<>
								{!isDisabled ? (
									<>
										<button
											disabled={isDisabled}
											id="confirm"
											onClick={confirmAction}
											className={`btn lg round  ${btnColor ?? 'light_blue'}  ${disableClass}`}
										>
											{loading ? <i className="mdi mdi-rotate-right mdi-spin" /> : confirmText}
										</button>
									</>
								) : (
									<button disabled={true} className={`btn md round green`} onClick={() => {}}>
										<i className="mdi mdi-rotate-right mdi-spin" />
									</button>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</InPortal>
	) : null;
};
