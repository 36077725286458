import React, { FC } from 'react';
import Modal from './index';

interface Props {
	close: () => void;
	url: string;
	className?: string;
}

export const ImagePreview: FC<Props> = ({ close, url, className = '' }) => {
	return (
		<Modal
			opened={true}
			size={'xxl'}
			align={'center'}
			direction={'column'}
			cancelAction={close}
			sectionClass={className}
			body={
				<div className="col-12 justify_center align_center ">
					<img className="add_document_preview" src={url} />
				</div>
			}
		/>
	);
};

export const IFramePreview: FC<Props> = ({ close, url, className = '' }) => {
	return (
		<Modal
			opened={true}
			size={'xxl'}
			align={'center'}
			direction={'column'}
			cancelAction={close}
			sectionClass={className}
			body={
				<div className="col-12 justify_center align_center ">
					<iframe className="add_iframe_preview" src={url} />
				</div>
			}
		/>
	);
};
