import { useEffect, useMemo, useState } from 'react';
import { several } from '../types/general';
import { useData } from '../context/DataProvider';
import { userBonusEmptyModel } from '../models/userBonus';
import { EmptyPromotionModel } from '../models/promotions';

import { emptySport } from '../models/sportAdministrations';
import { MarketItem } from '../types/MarketsAdministration';
import { Sport } from '../pages/administration/sports/types';
import { EmptyUserBonus } from '../pages/internet/bonuses/types';
import { EmptyPromotion } from '../pages/administration/promotions/types';

import { SpecialMarket, SpecialOddType } from '../pages/administration/special-markets/types';
import { Localisation, LocalisationExtended } from '../pages/administration/Translations/types';
import { emptyMarket, emptySpecialMarket, emptySpecialOdd } from '../models/marketsAdministrations';
import { CategoryItem } from '../pages/administration/categories/types';
import { emptyCategory } from '../models/categoriesAdministration';
import { TournamentItemExtended } from '../pages/administration/tournaments/types';
import { emptyTournament } from '../models/tournamentsAdministration';
import { CompetitorItemExtended } from '../pages/administration/competitors/types';
import { emptyCompetitor } from '../models/competitorsAdministration';
import { TableItem } from '../pages/Lists/Tables/types';
import { emptyTable, emptyTemplate } from '../models/listAdministrations';
import { TemplateItem } from '../pages/Lists/templates/types';

export function useModels() {
	const { localisationMap } = useData();

	const [languages, setLanguages] = useState<string[]>([]);
	const [media, setMedia] = useState<string[]>([]);
	const [selectedLang, selectLang] = useState('en');
	const [selectedMedia, selectMedia] = useState('web');

	const { regular, extended } = useMemo(() => {
		const { regular = {}, extended = {} } = localisationMap || {};
		const name: Localisation = Object.keys(regular).reduce((prev, curr) => ({ ...prev, [curr]: '' }), {});
		return { regular: name, extended };
	}, [localisationMap]);

	useEffect(() => {
		if (!Object.keys(extended).length) return;
		// Extract and init languages
		let languagesArray = Object.keys(extended);
		setLanguages(languagesArray);

		// Extract and init media types
		let mediaArray = Object.keys(extended.en);
		setMedia(mediaArray);
		selectMedia(mediaArray[0]);
	}, [extended, regular]);

	// Merge existing data for keys that are in regular localisation map
	const mapRegular = (data: Localisation) => {
		const mapped = {} as Localisation;
		for (let lang in regular) {
			mapped[lang] = data?.[lang] || '';
		}
		return { ...data, ...mapped };
	};

	// Merge existing data for keys that are in extended localisation map
	const mapExtended = (data: LocalisationExtended) => {
		const mapped = {} as LocalisationExtended;
		for (let lang in extended) {
			let item: Record<string, string> = {};
			for (let media in extended[lang]) {
				item[media] = data?.[lang]?.[media] || '';
			}
			mapped[lang] = item;
		}

		return { ...data, ...mapped };
	};

	const fillRegular = (regular: several) => {
		let filled = { ...regular };

		for (const x in filled) {
			filled[x] = filled[x] ? filled[x] : regular[selectedLang];
		}
		return filled;
	};

	const fillExtended = (extended: several) => {
		let filled = { ...extended };
		for (const x in filled) {
			for (const y in filled[x]) {
				filled[x][y] = filled[x][y] ? filled[x][y] : extended[selectedLang][selectedMedia];
			}
		}
		return filled;
	};

	const sportModel: Sport = { ...emptySport, name: regular };

	const categoryModel: CategoryItem = {
		...emptyCategory,
		name: mapRegular(emptyCategory.name),
		shortName: mapRegular(emptyCategory.shortName),
	};

	const tournamentModel: TournamentItemExtended = {
		...emptyTournament,
		name: mapRegular(emptyTournament.name),
		shortName: mapRegular(emptyTournament.shortName),
		description: extended,
	};

	const competitorModel: CompetitorItemExtended = {
		...emptyCompetitor,
		name: mapRegular(emptyCompetitor.name),
		shortName: mapRegular(emptyCompetitor.shortName),
	};

	const tableModel: TableItem = {
		...emptyTable,
		name: mapRegular(emptyTable.name),
		label: mapRegular(emptyTable.label),
		note: mapRegular(emptyTable.note),
	};

	const templateModel: TemplateItem = {
		...emptyTemplate,
		name: mapRegular(emptyTemplate.name),
	};

	const marketModel: MarketItem = { ...emptyMarket, localisation: extended };
	const specialMarket: SpecialMarket = { ...emptySpecialMarket, localisation: extended };

	const userBonus: EmptyUserBonus = {
		...userBonusEmptyModel,
		name: regular,
		description: extended,
		unlockDescription: extended,
	};

	const promotionModel: EmptyPromotion = {
		...EmptyPromotionModel,
		name: regular,
		description: regular,
	};

	const specialOdd: SpecialOddType = {
		...emptySpecialOdd,
		name: extended,
		description: extended,
	};

	return {
		sportModel,
		categoryModel,
		tournamentModel,
		competitorModel,
		tableModel,
		templateModel,
		marketModel,
		specialMarket,
		specialOdd,
		userBonus,
		promotionModel,
		regular,
		extended,
		languages,
		selectedLang,
		selectLang,
		media,
		selectMedia,
		selectedMedia,
		mapRegular,
		fillRegular,
		mapExtended,
		fillExtended,
	};
}
