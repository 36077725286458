import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
	label: string;
	name: string;
}

export const RHFCheckbox: FC<Props> = ({ label, name }) => {
	const { register, control, getValues } = useFormContext();
	const value = getValues(name);
	const checked = useWatch({
		control,
		name,
		defaultValue: value,
	});

	return (
		<div className="row margin_bottom_20 double_row">
			<div className="column text-right">
				<div className="label">{label}</div>
			</div>
			<div className="column flex">
				<div className="checkbox_custom chek_only tooltip_right">
					<input name={name} type="checkbox" ref={register} />
					{!checked ? <i className="mdi mdi-checkbox-blank-outline unchecked" /> : null}
					{checked ? <i className="mdi mdi-checkbox-marked checked" /> : null}
				</div>
			</div>
		</div>
	);
};
