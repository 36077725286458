import React, { CSSProperties, FC } from 'react';
import { Item } from '../../types';
import { formatDate } from '../../../../utils/getDate';
import { usePairing } from '../../_framework';
import { ProviderCell } from './ProviderCell';
import { isEmpty } from 'lodash';

interface Props {
	item: Item;
	style: CSSProperties;
	index: number;
	setIndex: (i: number) => void;
	provider: string;
}

export const LocalItem: FC<Props> = ({ item, style, index, setIndex, provider }) => {
	const { id, startTime, listCode, sport, tournament, competitors, category, linkedProviders } = item;
	const { localItem, setLocalItem, localFuzzy, setLocalFuzzy } = usePairing();
	const [date, time] = formatDate(startTime, 'date.month', 'hours:minutes');

	const [{ name: home }, { name: away }] = competitors || [{}, {}];

	const handleSelect = () => {
		if (id === localFuzzy.id) {
			setLocalFuzzy({} as Item);
			setLocalItem({} as Item);
		} else {
			setLocalFuzzy(item);
			setLocalItem(item);
			setIndex(index);
		}
	};

	const selectedClass = id === localItem.id ? ' selected' : ' ';

	const itemClass = `list_item_content listitem_target ${selectedClass}`;
	const tournamentName = `${tournament?.name || ''} (${category?.name || ''}) `;

	const otherProviders = linkedProviders?.filter((linkedProvider) => linkedProvider !== provider);

	return (
		<div
			id={id}
			className={`list_item ${selectedClass} ${!isEmpty(otherProviders) ? 'bg_row_color_green' : ''}`}
			style={style}
		>
			<div className="sport_icon">
				<div className="svg_icon tooltip_right">
					<svg className="e_ico">
						<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${sport.id}`}></use>
					</svg>
					<span className="tooltip_content">{sport.name}</span>
				</div>
			</div>
			<div className="tournament font_14px" title={tournamentName}>
				{tournamentName}
			</div>
			<div className="match_id">{listCode}</div>

			{away ? (
				<>
					<div className={`${itemClass}`} onClick={handleSelect}>
						<div className="home_team font_14px" title={home}>
							{home}
						</div>
						<div className="vs">vs</div>
						<div className="away_team font_14px" title={away}>
							{away}
						</div>
					</div>
				</>
			) : (
				<div className={`${itemClass}`} onClick={handleSelect}>
					<div className="home_team font_14px" title={home}>
						{home}
					</div>
				</div>
			)}

			<div className="event_start">
				<div className="event_date">{date}</div>
				<span className="divider"></span>
				<div className="event_time">{time}</div>
			</div>
			<ProviderCell providerData={linkedProviders} otherProviders={otherProviders} />

			<div
				className="checkbox_custom chek_only"
				onClick={() => {
					if (id === localItem.id) {
						setLocalItem({} as Item);
						setLocalFuzzy({} as Item);
					} else {
						setLocalItem(item);
						setIndex(index);
					}
				}}
			>
				<input type="checkbox" checked={id === localItem.id} onChange={() => {}} />
				<i className="mdi mdi-radiobox-blank unchecked"></i>
				<i className="mdi mdi-radiobox-marked checked"></i>
			</div>
		</div>
	);
};
