import React, { Fragment, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

interface Props {
	clickHandler: () => void;
	global: string;
	loading?: boolean;
}

export const ParameterRow: FC<Props> = ({ clickHandler, global, loading }) => {
	const { t } = useTranslation();
	return (
		<Fragment>
			<div className="tbl_btn green">
				<i
					data-testid="edit-icon"
					className={`mdi mdi-pencil tooltip_right margin_left_10 ${loading ? 'disabled' : ''}`}
					onClick={clickHandler}
				>
					<span className="tooltip_content">{t('general.edit')}</span>
				</i>
			</div>
			<div>
				<span className="font_400">{t('general.global')}</span> - <span className="font_600">{global}</span>
			</div>
		</Fragment>
	);
};
