import React, { useState, useEffect, useRef, Fragment } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface DropdownItem {
	id: string;
	name: string;
	className?: string;
	color?: string;
}

interface Props {
	items: DropdownItem[];
	selectedItem: string;
	selectItem: (id: string) => void;
	withSearch?: boolean;
	icon?: string;
	margins: string[];
	fixedTitle?: string;
	listClass?: string;
	direction?: string;
	nonSelectedString?: string;
	disableScroll?: boolean;
	fontSize?: string;
	highlightLabel?: boolean;
	className?: string;
	disabledList?: string[];
	setItemColor?: boolean;
	zIndex?: string;
	width?: string;
	maxWidth?: string;
	isDisabled?: boolean;
}

export default ({
	items = [],
	selectItem,
	direction = 'down',
	selectedItem,
	withSearch,
	icon,
	margins,
	fixedTitle,
	listClass = 'left_0',
	nonSelectedString = 'No items',
	disableScroll,
	fontSize,
	highlightLabel = false,
	className = '',
	disabledList = [],
	setItemColor,
	zIndex,
	width,
	maxWidth,
	isDisabled = false,
}: Props) => {
	const [searchQuery, setSearchQuery] = useState('');
	const [itemDisplay, setItemDisplay] = useState('');
	const [open, setOpen] = useState(false);

	const [top, right, bottom, left] = margins;

	const inputElement = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const selected: DropdownItem | undefined = items.find((item) => item.id === selectedItem);
		setItemDisplay(selected ? selected.name : nonSelectedString);
	}, [selectedItem, items]);

	const handleChange = (id: string) => {
		selectItem(id);
		setSearchQuery('');
	};

	useEffect(() => {
		setTimeout(() => {
			!disableScroll &&
				open &&
				selectedItem &&
				document.getElementById(selectedItem.toString())?.scrollIntoView({ behavior: 'auto', block: 'center' });
		}, 200);
	}, [open]);

	const handleOpen = () => {
		setOpen(!open);
		setTimeout(() => {
			inputElement && inputElement.current && inputElement.current.focus();
		}, 200);
	};

	// Track state of dropdown

	const dropdownClass = open
		? `select_region drop_menu  margin_top_${top} margin_right_${right} margin_bottom_${bottom} margin_left_${left} ${direction} toggle`
		: `select_region drop_menu  margin_top_${top} margin_right_${right} margin_bottom_${bottom} margin_left_${left}`;

	// Create dropdownRef
	const dropdownRef = useRef<HTMLDivElement>(null);

	// Call useOnClickOutside custom hook
	useOnClickOutside(dropdownRef, () => setOpen(false));

	return (
		<div ref={dropdownRef} className={`${dropdownClass} ${className} ${isDisabled ? 'disabled' : ''}`}>
			{setItemColor && selectedItem ? (
				<div className={`socket_status`}>
					<i
						style={{ color: `${items.find((tag) => tag.id === selectedItem)?.color}`, margin: 0 }}
						className="mdi mdi-checkbox-blank-circle"
					></i>
					<span className="status_label padding_right_10"></span>
				</div>
			) : null}

			<span
				className={`drop_label ${fontSize}`}
				onClick={handleOpen}
				style={{ color: `${highlightLabel ? '#19a6ea' : 'inherit'}` }}
			>
				{fixedTitle || itemDisplay}
				{icon && <i className={icon} />}
			</span>
			<div
				className={`drop_list ${listClass} ${withSearch ? 'with_src' : ''} padding_bottom_120_mobile bg_white`}
				style={{ width: width, maxWidth: maxWidth }}
			>
				<Fragment>
					{withSearch && (
						<div className="drop_list_item src">
							<input
								type="text"
								placeholder="pretraga"
								value={searchQuery}
								ref={inputElement}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</div>
					)}
					{items
						.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
						.map((item) => (
							<div
								id={item.id.toString()}
								key={item.id}
								className={` ${maxWidth ? 'ellipsis_dropdown' : ''} drop_list_item font_400 ${
									item.className
								}  ${selectedItem === item.id ? 'selected' : ''} ${
									disabledList.includes(item.id) ? 'disabled' : ''
								} ${zIndex}`}
								style={{ borderLeft: setItemColor ? `5px solid ${item?.color}` : 'none' }}
								onClick={() => {
									handleChange(item.id);
									setOpen(false);
								}}
							>
								{item.name.toUpperCase()}
							</div>
						))}
				</Fragment>
			</div>
		</div>
	);
};
