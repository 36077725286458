import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../utils/getDate';
import { SingleSeriesVoucher } from '../../types';

interface Props {
	voucher: SingleSeriesVoucher;
}

export const InfoHeader: FC<Props> = ({ voucher }) => {
	const { t } = useTranslation();

	const {
		dtActive,
		dtExpire,
		userCreated,
		dtCreated,
		userExported,
		dtExported,
		userCanceled,
		dtCanceled,
		activated,
		activatedAmount,
	} = voucher;

	const getDateOrEmpty = (date: string) => {
		if (date) {
			return formatDate(date || '', 'date.month.year | hours:minutes:seconds');
		}
		return '';
	};

	return (
		<div className="status_row">
			<div className="col-5 border_divider">
				<div className="info_row">
					<div className="col-3"></div>
					<small className="col-4 info flex_justify_center gray_text">{t('vouchers.created')}</small>
					<small className="col-4 info flex_justify_end gray_text">{t('vouchers.createdBy')}</small>
				</div>
				<div className="info_row">
					<div className="col-3 label">
						{`${t('vouchers.active')}`}:
						<small className="gray_text padding_left_5">{getDateOrEmpty(dtActive ?? '')}</small>
					</div>
					<div className="col-4 info flex_justify_center">{getDateOrEmpty(dtCreated ?? '')}</div>
					<div className="col-4 info flex_justify_end">{userCreated}</div>
				</div>
				<div className="info_row">
					<div className="col-3 label">
						{`${t('vouchers.expire')}`}:
						<small className="gray_text padding_left_5">{getDateOrEmpty(dtExpire ?? '')}</small>
					</div>
				</div>
			</div>
			<div className="col-3 border_divider">
				<div className="info_row">
					<small className="col-5 info flex_justify_center gray_text">{t('vouchers.exported')}</small>
					<small className="col-5 info flex_justify_end gray_text">{t('vouchers.exportedBy')}</small>
				</div>
				<div className="info_row">
					<div className="col-5 label flex_justify_center">{getDateOrEmpty(dtExported ?? '')}</div>
					<div className="col-5 info flex_justify_end">{userExported}</div>
				</div>
			</div>
			<div className="col-3 border_divider">
				<div className="info_row">
					<small className="col-5 info flex_justify_center gray_text">{t('vouchers.canceled')}</small>
					<small className="col-5 info flex_justify_end gray_text">{t('vouchers.canceledBy')}</small>
				</div>
				<div className="info_row">
					<div className="col-5 label flex_justify_center">{getDateOrEmpty(dtCanceled ?? '')}</div>
					<div className="col-5 info flex_justify_end">{userCanceled}</div>
				</div>
			</div>
			<div className="col-2">
				<div className="info_row">
					<small className="col-5 info flex_justify_center gray_text">{t('vouchers.activated')}</small>
					<small className="col-5 info flex_justify_center gray_text">{t('vouchers.activatedAmount')}</small>
				</div>
				<div className="info_row">
					<div className="col-5 label flex_justify_center">{activated}</div>
					<div className="col-5 info flex_justify_center">{activatedAmount}</div>
				</div>
			</div>
		</div>
	);
};
