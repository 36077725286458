import axios from 'axios';
import { axiosInstance, Response } from './../config';
import { id } from '../../types/general';

export const tournaments = {
	getTournaments(providerId: string, categoryId: string): Response {
		return axiosInstance
			.get(`/mappings/data/tournaments`, {
				params: { providerId: providerId, categoryId: categoryId },
			})
			.then((res) => res.data);
	},
	linkTournaments(providerId: id, localItemId: id, providerItemId: id) {
		return axiosInstance.post(`/mappings/map/${providerId}/data/tournaments`, {
			localItemId: localItemId,
			providerItemId: providerItemId,
		});
	},
	linkSpecialTournaments(providerId: id, localItemId: id, providerItemId: id, specialTournamentId: id) {
		return axiosInstance.post(`/mappings/map/${providerId}/data/special_tournaments`, {
			localItemId: localItemId,
			providerItemId: providerItemId,
			specialTournamentId,
		});
	},
	unpairTournaments(providerId: id, providerItemId: id) {
		const encodedId = encodeURIComponent(encodeURIComponent('' + providerItemId));
		return axiosInstance.delete(`/mappings/map/${providerId}/data/tournaments/${encodedId}`);
	},
	importTournaments(providerId: id, providerItemId: id) {
		return axiosInstance.post(`/mappings/import/${providerId}/data/tournaments`, {
			providerItemId: providerItemId,
		});
	},
	getProviderTournaments(providerId: id, sportId: string, catId: string) {
		return axiosInstance
			.get(`/mappings/items/${providerId}/data/tournaments?id_sport=${sportId}&provider_id_category=${catId}`)
			.then((res) => res.data);
	},
	getProviderTournamentsNoCategory(providerId: id, sportId: string) {
		return axiosInstance
			.get(`/mappings/items/${providerId}/data/tournaments?id_sport=${sportId}`)
			.then((res) => res.data);
	},
	getLocalTournaments(providerId: id, sportId: string, catId: string) {
		return axiosInstance
			.get(`/mappings/items/${providerId}/data/tournaments?id_sport=${sportId}&id_category=${catId}`)
			.then((res) => res.data);
	},
};
