import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortCell } from '../../../../reports/tickets/components/SortCell';
import { useUserConfig } from '../../../../../context/UserConfigProvider';
import { INTERNET_TRANSACTIONS_COLUMNS } from '../../../../../consts/userConfig';

interface Props {
	selectedSort: string;
	selectSort: (sort: string) => void;
	showUsername: boolean;
}

export const TableHeader: FC<Props> = ({ selectSort, selectedSort, showUsername }) => {
	const { t } = useTranslation();
	const { isColumnVisible } = useUserConfig();

	return (
		<thead className="t_head">
			<tr>
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.ID) && (
					<SortCell
						ascending="idAsc"
						descending="idDesc"
						label={t('transactions.id')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.USER) &&
					(showUsername ? (
						<SortCell
							ascending="userAsc"
							descending="userDesc"
							label={t('transactions.user')}
							selectedSort={selectedSort}
							selectSort={selectSort}
						/>
					) : (
						<td className="label font_700">{t('transactions.ticketId')}</td>
					))}
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.AGENT) && (
					<td className="label font_700">{t('transactions.agent')}</td>
				)}
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.CREATED_AT) && (
					<SortCell
						ascending="createdAsc"
						descending="createdDesc"
						label={t('general.createdAt')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.ACCOUNT_TYPE) && (
					<SortCell
						ascending="accountTypeAsc"
						descending="accountTypeDesc"
						label={t('transactions.accountType')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.CURRENCY) && (
					<SortCell
						ascending="ccyAsc"
						descending="ccyDesc"
						label={t('transactions.currencyShort')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.TYPE) && (
					<SortCell
						ascending="typeAsc"
						descending="typeDesc"
						label={t('transactions.typeShort')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.SUB_TYPE) && (
					<SortCell
						ascending="subTypeAsc"
						descending="subTypeDesc"
						label={t('transactions.subTypeShort')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.PAYMENT_PROVIDER) && (
					<SortCell
						ascending="transactionIssuerAsc"
						descending="transactionIssuerDesc"
						label={t('transactions.paymentProvider')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.GAME_PROVIDER) && (
					<SortCell
						ascending="gameProviderAsc"
						descending="gameProviderDesc"
						label={t('transactions.gameProvider')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.APPLICATION) && (
					<SortCell
						ascending="applicationAsc"
						descending="applicationDesc"
						label={t('transactions.application')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}

				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.TICKET) && (
					<td className="label font_700">{t('general.ticket')}</td>
				)}

				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.AMOUNT_IN) && (
					<SortCell
						ascending="amountInAsc"
						descending="amountInDesc"
						label={t('transactions.amountIn')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.AMOUNT_OUT) && (
					<SortCell
						ascending="amountOutAsc"
						descending="amountOutDesc"
						label={t('transactions.amountOut')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}
				{isColumnVisible(INTERNET_TRANSACTIONS_COLUMNS.PREVIOUS_WALLET_BALANCE) && (
					<td className="font_700 text_right">{t('transactions.previousWalletBalance')}</td>
				)}
			</tr>
		</thead>
	);
};
