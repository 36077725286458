import React, { FC, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import api from '../../../../../api';
import Button from '../../../../../components/general/Button';
import Dropdown from '../../../../../components/general/Dropdown';
import { SimpleLoader } from '../../../../../components/general/Loader';
import NotificationModal from '../../../../../components/general/NotificationModal';
import { Header, PageContentWrapper, PageFooter, useLayout } from '../../../../../components/layout';
import { usePagination } from '../../../../../hooks';
import useCheckMobileScreen from '../../../../../hooks/useCheckMobileScreen';
import Delete from '../../../../../utils/svg/Delete';
import { Height } from '../../../../administration/live/components/Height';
import { csvToArray } from '../../../../online-support/users';
import { InputFilter } from '../../../../reports/tickets/components/InputFilter';
import { FooterDropdown } from '../../../../risk-management/controller/components/general';
import { statusesArray } from '../../helpers';
import { SingleSeriesVoucher, Voucher } from '../../types';
import { InfoHeader } from './InfoHeader';
import { SubHeader } from './SubHeader';
import TableHeader from './table/TableHeader';
import TableRow from './table/TableRow';
import getErrorText from '../../../../../utils/getErrorMsg';
import { useNotify } from '../../../../../hooks/useNotifications';

type ActionType = 'SET_DEFAULT' | 'DELETE' | 'CANCEL';
interface Props {
	id_series: string;
	status_series: string;
}

export const Vouchers: FC<RouteComponentProps<Props>> = ({
	match: {
		params: { id_series, status_series },
	},
}) => {
	const { t } = useTranslation();
	const [loaded, setLoaded] = useState(false);
	const { toggleSidebar } = useLayout();
	const { error } = useNotify();

	useEffect(() => {
		toggleSidebar();
	}, []);

	const [action, setAction] = useState<ActionType | null>(null);

	//Filter
	const [universalSearch, setUniversalSearch] = useState('');
	const [orderBy, setOrderBy] = useState('');
	const [searchData, setSearchData] = useState(false);
	const [status, setStatus] = useState<string>('');
	const clearFilter = () => {
		setStatus('');
		setUniversalSearch('');
	};

	// Pagination
	const { from, setTotal, perPage, setPerPage, ...pagination } = usePagination(20);

	// Export
	const [exportData, setExportData] = useState('');
	const [fileName, setFileName] = useState('');
	const excelRef = useRef<any>();
	const [fileUrl, setFileURL] = useState<any>('');

	const [disabledExportBtn, setDisabledExportBtn] = useState(false);
	const [disabledDelete, setDisabledeElete] = useState(false);

	async function getExportData() {
		try {
			setDisabledExportBtn(true);
			const [fileName, data] = await api.vouchers.exportSingleSeries(id_series);
			setExportData(data);
			console.log(fileName);
			setFileName(fileName);

			const fileUrl = buildURI(data);
			console.log(fileUrl);
			setFileURL(fileUrl);

			excelRef?.current?.click();
			window.URL.revokeObjectURL(fileUrl);
			setFileURL('');
		} catch (err) {
			error(getErrorText(err) || 'toast.error.defaultText');
		} finally {
			setExportData('');
			setFileName('');
			setDisabledExportBtn(false);
		}
	}
	// Modal
	const closeConfirmModal = () => {
		setAction(null);
	};
	const DELETE = 'DELETE';

	const handleDelete = () => {
		setAction(DELETE);
	};

	const buildURI = (data: any) => {
		const blob = new Blob([data]);

		const URL = window.URL || window.webkitURL;
		return URL.createObjectURL(blob);
	};

	// Api calls
	const [vouchers, setVouchers] = useState<Voucher[]>([]);
	const [voucher, setVoucher] = useState<SingleSeriesVoucher>({});

	const cancelSingleSeries = async () => {
		try {
			setDisabledeElete(true);
			const { vouchers, ...rest } = await api.vouchers.cancelSingleSeries(id_series);
			setVouchers([]);
			setVouchers(vouchers ?? []);
			setVoucher(rest);
		} catch (err) {
			error(getErrorText(err) || 'toast.error.defaultText');
		} finally {
			closeConfirmModal();
			setDisabledeElete(false);
		}
	};
	useEffect(() => {
		(async () => {
			try {
				setLoaded(true);
				const payload = {
					orderBy: orderBy !== '' ? [orderBy] : undefined,
					status: status !== '' ? [status] : undefined,
					limit: perPage,
					offset: from,
					query: universalSearch ?? undefined,
				};
				const { vouchers, count, ...rest } = await api.vouchers.getSingleSeries(payload, id_series);
				setVouchers(vouchers ?? []);
				setVoucher(rest);

				setTotal(count ?? 0);
			} catch (err) {
				error(getErrorText(err) || 'toast.error.defaultText');
			} finally {
				setLoaded(false);
			}
		})();
	}, [orderBy, perPage, from, searchData, status]);

	const items = statusesArray[status_series].map((item: any) => ({
		id: item,
		name: item,
	}));

	const isMobileView = useCheckMobileScreen();

	return (
		<>
			<Header
				widthClass={'w_1800'}
				title={t('vouchers.title')}
				leftTool={
					<>
						<Button
							onClick={() => getExportData()}
							name={t('general.export')}
							className={`md outline light_blue ${disabledExportBtn ? 'disabled' : ''}`}
						/>
						{exportData ? <a download={fileName} ref={excelRef} target="_blank" href={fileUrl}></a> : null}
						{voucher?.status == 'ACTIVE' || voucher?.status == 'CREATED' ? (
							<Button onClick={() => handleDelete()} name={t('general.cancel')} className={'md outline red'} />
						) : null}
					</>
				}
			/>

			<PageContentWrapper isMobileView={isMobileView} widthClass={'w_1800'}>
				<div className="col-12">
					<div className="col-12">
						<SubHeader voucher={voucher} />
						<InfoHeader voucher={voucher} />
						<div className="subheading flex_justify_end">
							<Dropdown
								items={[{ id: '', name: t('general.clearSelected') }, ...items]}
								selectedItem={status}
								selectItem={setStatus}
								listClass={'left_0'}
								icon={'mdi mdi-menu-down'}
								highlightLabel={!!status}
								margins={['0', '20', '0', '0']}
								fixedTitle={!status ? t('general.status') : ''}
								withSearch
							/>
							<div className="form_col">
								<InputFilter
									type="text"
									value={universalSearch}
									setValue={setUniversalSearch}
									onEnter={() => setSearchData((data) => !data)}
									placeholder={t('general.universalSearch')}
									marginRight={10}
								/>
							</div>
							<Button onClick={clearFilter} name={t('general.clear')} className={'md outline red'} />
						</div>
					</div>
					<div className="col-12">
						<table className="admin_table sticky_header full_w">
							<TableHeader selectedSort={orderBy} selectSort={setOrderBy} loaded={loaded} />

							<tbody className="t_body">
								{!loaded ? (
									vouchers?.map((voucher, index) => {
										return <TableRow key={index} voucher={voucher} seriesId={id_series} />;
									})
								) : (
									<Height>
										<SimpleLoader />
									</Height>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</PageContentWrapper>
			<PageFooter numberOfItems={<FooterDropdown perPage={perPage} setPerPage={setPerPage} />} {...pagination} />
			{action ? (
				<NotificationModal
					opened={true}
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={closeConfirmModal}
					confirmAction={cancelSingleSeries}
					zIndex={'over_3'}
					btnColor="red"
					loading={disabledDelete}
				>
					<div className="context_icon margin_top_40">
						<Delete />
					</div>
					<h2 className="modal_message capitalize text_center">{t('general.areSure')}</h2>
					<p className="text_center margin_top_10">{t('vouchers.areYouSureCancel')}</p>
				</NotificationModal>
			) : null}
		</>
	);
};
