import React, { FC } from 'react';

interface Props {
	marketId: string;
	marketName: string;
}

export const Divider: FC<Props> = ({ marketId, marketName }) => {
	return (
		<div key={marketId} className="divider_horizontal small label_left ltr_direction">
			<div className="div_label">{marketName}</div>
			<div className="thin_line"></div>
		</div>
	);
};
