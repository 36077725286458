import React, { FC } from 'react';

interface NavigationProps {
	tabs: {
		id: string | number;
		name: string;
	}[];
	selectedTab: string | number;
	selectTab: any;
	paddingLeft?: number;
	paddingRight?: number;
	className?: string;
}

export const Navigation: FC<NavigationProps> = ({
	tabs,
	selectedTab,
	selectTab,
	paddingLeft,
	paddingRight,
	className = '',
}) => {
	return (
		<div
			className={`tab_nav ${paddingLeft ? `padding_left_${paddingLeft}` : ''} ${
				paddingRight ? `padding_right_${paddingRight}` : ''
			} ${className}`}
		>
			{tabs?.map(({ id, name }) => (
				<div
					key={id}
					className={`tab_nav_item ${id === selectedTab ? 'active' : ''} `}
					onClick={() => {
						selectTab(id as 'BANK' | 'LOCATION');
					}}
				>
					{name}
				</div>
			))}
		</div>
	);
};
