import React, { useState, FC, Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import { OddModal } from './OddModal';
import { PlayerPropsScore, SpecialOddType } from '../../types';
import { useTranslation } from 'react-i18next';
import Modals from '../../../../../components/Modals';
import { useFormContext, useWatch } from 'react-hook-form';
import { useModels } from '../../../../../hooks/useModels';
import Button from '../../../../../components/general/Button';
import { DragDropContext, Droppable, DropResult, Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { MarketsOddsModal } from '../../../../../components/MarketsAdministration/Modal/OddTypes/OddTypeForm';
import { OddTypeItem } from '../../../../../types/MarketsAdministration';
import { cloneDeep } from 'lodash';

interface Props {
	canAddNewTypes?: boolean;
	selectedScores: PlayerPropsScore[];
}

export const OddsTable: FC<Props> = ({ canAddNewTypes = false, selectedScores }) => {
	const { t, i18n } = useTranslation();
	const { language: ln } = i18n;
	const { specialOdd } = useModels();

	const [selectedOdd, selectOdd] = useState<SpecialOddType | OddTypeItem | null>(null);

	const { getValues, control, setValue } = useFormContext();

	const odds: SpecialOddType[] = getValues('oddtypes');
	const defaultMarket: boolean = getValues('default');

	const oddtypes = useWatch({
		control,
		name: 'oddtypes',
		defaultValue: odds,
	});

	const handleUpdate = () => {
		if (!selectedOdd!.id) {
			setValue('oddtypes', [...oddtypes, { ...selectedOdd, id: `tempId-${uuid().slice(0, 8)}` }]);
		} else {
			setValue(
				'oddtypes',
				oddtypes.map((item) => (item.id === selectedOdd!.id ? selectedOdd : item))
			);
		}

		selectOdd(null);
	};

	const deleteOdd = (id: string) => {
		setValue(
			'oddtypes',
			oddtypes.filter((field) => field.id !== id)
		);
	};

	// helper function to swap dragged items
	const reorder = (odds: SpecialOddType[], startIndex: number, endIndex: number) => {
		const newOdds = [...odds];
		const [removed] = newOdds.splice(startIndex, 1);
		newOdds.splice(endIndex, 0, removed);

		return newOdds;
	};

	const handleDragEnd = ({ source, destination }: DropResult) => {
		if (!destination) {
			return;
		}
		const items = reorder(oddtypes, source.index, destination.index);
		setValue('oddtypes', items);
	};

	const disabled = !selectedOdd?.name[ln].web || !selectedOdd.riskFactor || selectedOdd.bettingDelay === '';

	return (
		<Fragment>
			<div className="dragable_table market_sorting_tbl margin_top_15 ">
				{/* Header */}
				<Header>
					{canAddNewTypes ? (
						<Button
							type="button"
							className="blue"
							name={t('general.addNew')}
							onClick={() => selectOdd(cloneDeep({ ...specialOdd }))}
						/>
					) : null}
				</Header>

				{/* Draggable odd types list */}
				<DragDropContext onDragEnd={handleDragEnd}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div
								className={snapshot.isDraggingOver ? 'tbl_body drop_tbl_body' : 'tbl_body'}
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{oddtypes.map((item, index) => (
									<Draggable key={item.id} draggableId={item.id} index={index}>
										{(provided) => (
											<Row
												provided={provided}
												item={item}
												selectOdd={selectOdd}
												deleteOdd={deleteOdd}
												key={`${item.id}-${index}`}
												preventDelete={!defaultMarket}
											/>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
			{/* Odd types modal*/}
			{selectedOdd ? (
				<Modals
					opened={true}
					size={'md'}
					align={'start'}
					direction={'column'}
					cancelAction={() => selectOdd(null)}
					header={
						<h2 className="modal_title padding_left_25">
							{`${t('general.odd')} -`}
							<strong className="font_700 padding_left_10">{selectedOdd.name[ln].web}</strong>
						</h2>
					}
					body={
						canAddNewTypes ? (
							<OddModal
								odd={selectedOdd as SpecialOddType}
								editOdd={selectOdd}
								selectedScores={selectedScores}
							/>
						) : (
							<MarketsOddsModal
								odd={selectedOdd as OddTypeItem}
								editOdd={selectOdd}
								defaultMarket={defaultMarket}
							/>
						)
					}
					footer={
						<Fragment>
							<button className="btn md round outline " onClick={() => selectOdd(null)}>
								{t('general.cancel')}
							</button>
							<button className={`btn md round green ${disabled ? 'disabled' : ''}`} onClick={handleUpdate}>
								{t('general.save')}
							</button>
						</Fragment>
					}
				/>
			) : null}
		</Fragment>
	);
};

const Header: FC = ({ children }) => {
	const { t } = useTranslation();

	return (
		<div className="tbl_head tbl_row">
			<div className="tbl_label col_1">{t('general.column')}</div>
			<div className="tbl_label col_2">{t('marketsAdministrations.oddTypeId')}</div>
			<div className="tbl_label col_2" style={{ marginLeft: '110px' }}>
				{children}
			</div>
		</div>
	);
};

interface RowProps {
	item: SpecialOddType;
	provided: DraggableProvided;
	deleteOdd: (id: string) => void;
	selectOdd: (odd: SpecialOddType) => void;
	preventDelete: boolean;
}
const Row: FC<RowProps> = ({ provided, item, selectOdd, deleteOdd, preventDelete }) => {
	const { t, i18n } = useTranslation();
	const { language: ln } = i18n;
	const idName = item.id.startsWith('tempId') ? t('general.tempId') : item.id;

	return (
		<div className="tbl_row" ref={provided.innerRef} {...provided.draggableProps}>
			<div className="tbl_label col_1 font_600 stake_name" onClick={() => selectOdd(item)}>
				{item.name[ln]?.web || ''}
			</div>
			<div className="tbl_label col_2" onClick={() => selectOdd(item)}>
				{idName}
			</div>
			<div className="tbl_action">
				<i className="mdi mdi-drag-horizontal tooltip_left margin_right_10" {...provided.dragHandleProps}>
					<span className="tooltip_content">{t('general.draggItem')}</span>
				</i>

				{preventDelete ? (
					<span className="mdi mdi-close tooltip_left text_redish small" onClick={() => deleteOdd(item.id)}>
						<span className="tooltip_content redish">{t('marketsAdministrations.deleteOdd')}</span>
					</span>
				) : null}
			</div>
		</div>
	);
};
