import { CategoryItem } from '../pages/administration/categories/types';

export const emptyCategory: CategoryItem = {
	id: '',
	defaultName: '',
	name: {
		srb: '',
		en: '',
		de: ''
	},
	shortName: {
		srb: '',
		en: '',
		de: ''
	},
	sport: {
		id: '',
		defaultName: ''
	},
	icons: {
		web: '',
		terminal: '',
		mobile: '',
		list: ''
	}
};

export const temporaryCategory: CategoryItem = {
	id: 'temp',
	defaultName: '',
	name: {
		srb: '',
		en: '',
		de: ''
	},
	shortName: {
		srb: '',
		en: '',
		de: ''
	},
	sport: {
		id: 'tempSport',
		defaultName: ''
	},
	icons: {
		web: '',
		terminal: '',
		mobile: '',
		list: ''
	}
};
