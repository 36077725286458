import { isArray, isEmpty } from 'lodash';
import {
	ODDS_CHANGE,
	BET_ODDS,
	EVENT_CHANGE,
	EVENT_CHANGE_LIVE,
	EVENT_CHANGE_PREMATCH,
	OFFER_PLAN_UPDATE,
	BONUS_ODDS_CHANGE,
	EVENT_RESULT,
	NEW_EVENT,
	PREMATCH_EVENT_RESULT,
	BET_STOP,
	BET_RESULT,
	PREMATCH_BET_RESULT,
	ANNOUNCED_LIVE_EVENT,
} from '../consts/messages';

const remapEventInfo = (message: { [key: string]: any }): { [key: string]: any } => {
	const {
		SId: id_sport,
		TId: id_tournament,
		EId: id_event,
		L: live,
		UTC: utc_scheduled,
		CT: current_time,
		TS: time_stopped,
		StT: stoppage_time,
		ReT: remaining_time,
		CP: current_period,
		...rest
	} = message;
	return {
		id_sport,
		id_tournament,
		id_event,
		live,
		utc_scheduled,
		current_time,
		time_stopped,
		stoppage_time,
		remaining_time,
		current_period,
		...rest,
	};
};

const remapEventChange = (message: { [key: string]: any }): { [key: string]: any } => {
	const remappedInfo = remapEventInfo(message);
	const {
		A: active,
		BC: bet_count,
		RBC: result_bet_count,
		LS: live_status,
		AMC: active_market_count,
		AOC: active_oddtype_count,
		MB: manually_blocked,
		S: status,
		BS: bet_status,
		...rest
	} = remappedInfo;
	return {
		active,
		bet_count,
		result_bet_count,
		live_status,
		active_market_count,
		active_oddtype_count,
		manually_blocked,
		status,
		bet_status,
		...rest,
	};
};

const remapEventChangePrematchLive = (message: { [key: string]: any }) => {
	const remappedEventChange = remapEventChange(message);
	const {
		L: live,
		UTC: utc_scheduled,
		LC: list_code,
		Co,
		To,
		Ca,
		Sp,
		OP,
		CS,
		PId: id_parent,
		STy: special_type,
		M,
		...rest
	} = remappedEventChange;

	const competitors = isArray(Co) ? Co.map((competitor) => remapLocalization(competitor)) : Co;
	const tournament = remapLocalization(To);
	const category = remapLocalization(Ca);
	const sport = remapLocalization(Sp);

	const odds_provider = remapIdName(OP);
	const current_score = remapScore(CS);

	const markets = isArray(M) ? M.map((market) => remapMarket(market)) : [];

	return {
		live,
		utc_scheduled,
		list_code,
		competitors,
		tournament,
		category,
		sport,
		odds_provider,
		current_score,
		special_type,
		markets,
		...rest,
	};
};

const remapEventResult = (message: { [key: string]: any }) => {
	const remappedInfo = remapEventInfo(message);
	const { CS, PS, St, PSt: period_stats, CGS, current_points: initial_points, ...rest } = remappedInfo;
	const current_score = remapScore(CS);
	const period_scores = isArray(PS) ? PS.map((periodScore) => remapPeriodScore(periodScore)) : [];
	const current_gamescore = remapScore(CGS);
	const stats = remapStats(St);
	const current_points = remapScore(initial_points);
	return {
		current_score,
		period_scores,
		stats,
		period_stats,
		current_gamescore,
		current_points,
		...rest,
	};
};

const remapOddsChange = (message: { [key: string]: any }) => {
	const { SId: id_sport, TId: id_tournament, EId: id_event, L: live, S: status, M, ...rest } = message;
	const markets = isArray(M) ? M.map((market) => remapMarket(market)) : [];
	return {
		id_sport,
		id_tournament,
		id_event,
		live,
		status,
		markets,
		...rest,
	};
};

const remapOfferPlanUpdate = (message: { [key: string]: any }) => {
	const { PId: id_provider, OPId: id_offer_plan, ...rest } = message;
	return {
		id_provider,
		id_offer_plan,
		...rest,
	};
};

const remapPrematchResult = (message: { [key: string]: any }) => {
	const remappedInfo = remapEventInfo(message);
	const { resultsMain: results_main, ...rest } = remappedInfo;
	return {
		results_main,
		...rest,
	};
};

const remapBetResult = (message: { [key: string]: any }) => {
	const { SId: id_sport, TId: id_tournament, EId: id_event, L: live, S: status, M, ...rest } = message;
	const markets = isArray(M) ? M.map((market) => remapMarket(market)) : [];
	return {
		id_sport,
		id_tournament,
		id_event,
		live,
		status,
		markets,
		...rest,
	};
};

//helpers
const remapMarket = (market: { [key: string]: any }) => {
	if (isEmpty(market)) return market;
	const { MId: id_market, Me: meta, S: status, OT, ...rest } = market;
	const oddtypes = isArray(OT) ? OT.map((oddtype) => remapOddtype(oddtype)) : [];
	return {
		id_market,
		meta,
		status,
		oddtypes,
		...rest,
	};
};

const remapOddtype = (oddtype: { [key: string]: any }) => {
	if (isEmpty(oddtype)) return oddtype;
	const {
		OId: id_oddtype,
		O: odd,
		S: status,
		MS: manual_status,
		R: result,
		SE: settlement,
		SV: special_value,
		Me: meta,
		...rest
	} = oddtype;
	return {
		id_oddtype,
		odd,
		status,
		manual_status,
		result,
		settlement,
		special_value,
		meta,
		...rest,
	};
};

const remapIdName = (data: { [key: string]: any }) => {
	if (isEmpty(data)) return data;
	const { Id: id, N: name, ...rest } = data;
	return {
		id,
		name,
		...rest,
	};
};

const remapScore = (score: { [key: string]: any }) => {
	if (isEmpty(score)) return score;
	const { HS: home_score, AS: away_score, ...rest } = score || {};
	return {
		home_score,
		away_score,
		...rest,
	};
};

const remapPeriodScore = (periodScore: { [key: string]: any }) => {
	if (isEmpty(periodScore)) return periodScore;
	const { P: period, S, ...rest } = periodScore || {};
	const score = remapScore(S);
	return {
		period,
		score,
		...rest,
	};
};

const remapStats = (stats: { [key: string]: any }) => {
	if (isEmpty(stats)) return stats;
	const {
		Pos: possession,
		FG: first_goal,
		FGT: first_goal_time,
		LG: last_goal,
		LGT: last_goal_time,
		WAT: win_after_turnover,
		Pe: penalties,
		MPe: missed_penalties,
		CaR: cards_red,
		CaY: cards_yellow,
		Co: corners,
		...rest
	} = stats;
	return remapStatsFields({
		possession,
		first_goal,
		first_goal_time,
		last_goal,
		last_goal_time,
		win_after_turnover,
		penalties,
		missed_penalties,
		cards_red,
		cards_yellow,
		corners,
		...rest,
	});
};

const statsScoreFields = [
	'penalties',
	'missed_penalties',
	'cards_red',
	'cards_yellow',
	'corners',
	'suspends',
	'attacks',
	'time_outs',
	'shots',
	'longest_streaks',
	'dangerous_attacks',
	'shots_on_goal',
	'shots_off_goal',
	'substitutions',
	'offsides',
	'ball_possessions',
	'goal_outs',
	'three_points',
	'two_points',
	'free_throws',
	'aces',
	'double_faults',
	'win_1st_serves',
	'break_point_conversions',
	'points_on_serve',
	'batters',
	'remaining_bowls',
	'dismissals',
	'penalty_runs',
	'kills',
];

const remapStatsFields = (stats: { [key: string]: any }) => {
	const keys = Object.keys(stats);
	const updatedStats = { ...stats };
	statsScoreFields.forEach((field) => {
		if (keys.includes(field)) {
			updatedStats[field] = remapScore(stats[field]);
		}
	});
	return updatedStats;
};

const remapLocalization = (data: { [key: string]: any }) => {
	if (data == null) return data;
	const remappedIdName = remapIdName(data);
	const { Lz: localisation, ...rest } = remappedIdName;
	return {
		localisation,
		...rest,
	};
};

const minifiedMappers = {
	[ODDS_CHANGE]: remapOddsChange,
	[BET_ODDS]: remapOddsChange,
	[BET_STOP]: remapOddsChange,
	[BET_RESULT]: remapBetResult,
	[PREMATCH_BET_RESULT]: remapBetResult,
	[EVENT_CHANGE]: remapEventChange,
	[NEW_EVENT]: remapEventChangePrematchLive,
	[ANNOUNCED_LIVE_EVENT]: remapEventChangePrematchLive,
	[BONUS_ODDS_CHANGE]: remapOddsChange,
	[OFFER_PLAN_UPDATE]: remapOfferPlanUpdate,
	[EVENT_CHANGE_LIVE]: remapEventChangePrematchLive,
	[EVENT_CHANGE_PREMATCH]: remapEventChangePrematchLive,
	[EVENT_RESULT]: remapEventResult,
	[PREMATCH_EVENT_RESULT]: remapPrematchResult,
};
export const remapMinified = (type: string, message: { [key: string]: any }) => {
	const mapper = minifiedMappers[type];
	if (!mapper) {
		console.log('Unidentified message for socket minification! Message type: ' + type);
		return message;
	}
	return mapper(message);
};
