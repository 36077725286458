import React, { useState, useEffect, useMemo, FC } from 'react';
import api from '../../../../../api';
import { Player } from '../../types';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce/lib';
import { usePagination } from '../../../../../hooks';
import Modal from '../../../../../components/Modals';
import { useAxios } from '../../../../../hooks/useAxios';
import { SportFilter } from '../../../../pairing/components';
import { Player as FullPlayer } from '../../../players/types';
import { Search, useSearch } from '../../../../../components/Search';
import { Header, PageFooter } from '../../../../../components/layout';
import { Sport } from '../../../sports/types';
import { SportIcon } from '../../../sports/components/SportIcon';

interface Props {
	closeModal: () => void;
	selected: Player[];
	setPlayers: (items: Player[]) => void;
	defaultSport: Sport;
}

export const PlayersSelector: FC<Props> = ({ defaultSport, setPlayers, selected, closeModal }) => {
	const { t } = useTranslation();

	const [selectedPlayers, setSelectedPlayers] = useState(selected);

	const [searchValue, setSearchValue] = useState<string>('');
	const searchOptions = useSearch(setSearchValue);
	const [debouncedText] = useDebounce(searchValue, 600);

	const { from, setTotal, perPage, setPerPage, ...pagination } = usePagination(20);

	const {
		data: { total, players },
	} = useAxios<{
		total: number;
		players: FullPlayer[];
	}>({
		apiFunction: async function getPlayers() {
			if (!defaultSport.id) return { total: 0, players: [] };
			return await api.players.getPlayers({
				sportId: defaultSport.id,
				q: debouncedText.length > 2 ? debouncedText : '',
				limit: perPage,
				skip: from,
			});
		},

		initialData: { total: 0, players: [] },
		dependencies: [debouncedText, perPage, from],
	});

	useEffect(() => {
		setTotal(total);
	}, [total]);

	const check = (player: FullPlayer) => {
		const { id, defaultName } = player;
		const selected = {
			id: `${id}`,
			name: defaultName,
		};
		setSelectedPlayers([...selectedPlayers, selected]);
	};

	const uncheck = (id: string) => {
		setSelectedPlayers((prev) => prev.filter((item) => item.id !== id));
	};

	const filteredPlayers = useMemo(() => {
		if (!players || !players.length) return [];
		return players.filter((tour) => !selectedPlayers.some((item) => tour.id === item.id));
	}, [players, selectedPlayers]);

	return (
		<Modal
			size="xxl"
			opened={true}
			align={'start'}
			direction={'row'}
			zIndex={'over_3'}
			header={
				<Header
					panel
					leftTool={
						<SportFilter
							margin="left_0"
							direction="left"
							selectSport={() => {}}
							selectedSport={defaultSport.id}
							sports={[defaultSport]}
						/>
					}
				>
					<Search message={t('offersPlan.3characters')} {...searchOptions} />
				</Header>
			}
			body={
				<div className="flex direction_column">
					{selectedPlayers.length ? (
						<table className="admin_table sticky_header full_w">
							<TableHeader />
							<tbody className="t_body">
								{selectedPlayers.map((selected) => (
									<SelectedTableRow key={selected.id} item={selected} uncheck={uncheck} sport={defaultSport} />
								))}
							</tbody>
						</table>
					) : null}
					{filteredPlayers.length ? (
						<table className="admin_table sticky_header full_w">
							<TableHeader />
							<tbody className="t_body">
								{filteredPlayers.map((player) => (
									<TableRow key={player.id} item={player} check={check} sport={defaultSport} />
								))}
							</tbody>
						</table>
					) : null}
				</div>
			}
			footer={
				<>
					<PageFooter removeSize={false} {...pagination} />
					<button className="btn md round outline" onClick={() => closeModal()}>
						{t('general.cancel')}
					</button>

					<button type="submit" className="btn md round green" onClick={() => setPlayers(selectedPlayers)}>
						{t('general.save')}
					</button>
				</>
			}
		/>
	);
};

const TableHeader: FC = ({}) => {
	const { t } = useTranslation();

	return (
		<thead className="t_head">
			<tr data-testid="head-row">
				<th>
					<div className="resort_coulmn"></div>
				</th>
				<th>{t('playersAdministration.name')}</th>
				<th className="text_center">{t('general.sport')}</th>
			</tr>
		</thead>
	);
};

interface RowProps {
	item: FullPlayer;
	check: (competitor: FullPlayer) => void;
	sport: Sport;
}

export const TableRow: FC<RowProps> = ({ item, check, sport }) => {
	const { defaultName } = item;

	return (
		<tr data-testid="body-row">
			<td className="">
				<div className="checkbox_custom chek_only tooltip_right">
					<input type="checkbox" checked={false} onChange={() => {}} onClick={() => check(item)} />
					<i className="mdi mdi-checkbox-blank-outline unchecked" />
					<i className="mdi mdi-checkbox-marked checked" />
					<span className="tooltip_content">{'select'}</span>
				</div>
			</td>
			<td width={800} onClick={() => check(item)}>
				{defaultName}
			</td>

			<td className="text_center">
				<SportIcon sport={sport} tooltip="left" />
			</td>
		</tr>
	);
};

interface SelectedRowProps {
	item: Player;
	uncheck: (id: string) => void;
	sport: Sport;
}

export const SelectedTableRow: FC<SelectedRowProps> = ({ item, uncheck, sport }) => {
	const { id, name } = item;

	return (
		<tr data-testid="body-row">
			<td className="">
				<div className="checkbox_custom chek_only tooltip_right">
					<input type="checkbox" checked={true} onChange={() => {}} onClick={() => uncheck(id)} />
					<i className="mdi mdi-checkbox-blank-outline unchecked" />
					<i className="mdi mdi-checkbox-marked checked" />
					<span className="tooltip_content">{'select'}</span>
				</div>
			</td>
			<td width={800} onClick={() => uncheck(id)}>
				{name}
			</td>

			<td className="text_center">
				<SportIcon sport={sport} tooltip="left" />
			</td>
		</tr>
	);
};
