import { CompetitorItemExtended } from './../competitors/types';

// takes data recived from formik and produce newCompetitorItem in correct format to send with our post call
export default (competitor: CompetitorItemExtended) => {
	return {
		...competitor,
		id: competitor.id,
		sportId: competitor.sport.id,
		tournaments: competitor.tournaments ? competitor.tournaments.map((tournament) => tournament.id) : [],
		players: competitor.players.map((player) => player.id),
	};
};
