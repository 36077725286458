import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	addTooltip: string;
	removeTooltip: string;
	add: () => void;
	remove: () => void;
}

export const MiddleControls: FC<Props> = ({ add, remove, addTooltip, removeTooltip }) => {
	const { t } = useTranslation();
	return (
		<div className="middle_controls">
			<div className="control_option blue_hover tooltip_left" onClick={add}>
				<i className="mdi mdi-chevron-right"></i>
				<span className="tooltip_content">{addTooltip}</span>
			</div>

			<div className="control_option red_hover tooltip_left" onClick={remove}>
				<i className="mdi mdi-chevron-left"></i>
				<span className="tooltip_content">{removeTooltip}</span>
			</div>
		</div>
	);
};
