import { FB, INTERNET, LOYALTY, SUM, DAILY } from './actions';
import { State, Action } from './types';

export const initialState: State = {
	accountTypes: {
		FB: {
			color: 'blueish',
			sign: 'F',
			active: true,
			tooltip: 'Freebet',
		},
		INTERNET: {
			color: 'blueish',
			sign: 'I',
			active: true,
			tooltip: 'Internet',
		},
		LOYALTY: {
			color: 'blueish',
			sign: 'L',
			active: false,
			tooltip: 'Loyalty',
		},
	},
	reportTypes: {
		SUM: {
			color: 'green',
			sign: 'S',
			active: true,
			tooltip: 'Sum',
		},
		DAILY: {
			color: 'green',
			sign: 'D',
			active: true,
			tooltip: 'Daily',
		},
	},
};

export function reducer(state: State, action: Action): State {
	const { reportTypes, accountTypes } = state;
	switch (action) {
		case FB:
			return {
				...state,
				accountTypes: {
					...accountTypes,
					FB: { ...accountTypes.FB, active: !accountTypes.FB.active },
				},
			};

		case INTERNET:
			return {
				...state,
				accountTypes: {
					...accountTypes,
					INTERNET: { ...accountTypes.INTERNET, active: !accountTypes.INTERNET.active },
				},
			};
		case LOYALTY:
			return {
				...state,
				accountTypes: {
					...accountTypes,
					LOYALTY: { ...accountTypes.LOYALTY, active: !accountTypes.LOYALTY.active },
				},
			};
		case SUM:
			return {
				...state,
				reportTypes: {
					...reportTypes,
					SUM: { ...reportTypes.SUM, active: !reportTypes.SUM.active },
				},
			};
		case DAILY:
			return {
				...state,
				reportTypes: {
					...reportTypes,
					DAILY: { ...reportTypes.DAILY, active: !reportTypes.DAILY.active },
				},
			};

		default:
			return state;
	}
}
