import React, { FC, useEffect, useState } from 'react';
import api from '../../../../api';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../general/Dropdown';
import { useFormContext, useWatch } from 'react-hook-form';
import { MarketItem, OddTypeItem } from '../../../../types/MarketsAdministration';
import { SimpleLoader } from '../../../general/Loader';

interface Props {
	odd: OddTypeItem;
	editOdd: (odd: OddTypeItem) => void;
	disabled: string;
}

export const MapPrematchToLive: FC<Props> = ({ odd, editOdd, disabled }) => {
	const { t, i18n } = useTranslation();
	const [markets, setMarkets] = useState<MarketItem[]>([]);
	const [selected, setSelected] = useState<MarketItem | null>(null);
	const { getValues, control } = useFormContext();
	const sport = getValues('sport');
	const [loading, setLoading] = useState(false);

	const selectedSport: { id: string; name: string } = useWatch({
		control: control,
		name: 'sport',
		defaultValue: sport,
	});

	useEffect(() => {
		if (!selectedSport) return;
		(async function initMarkets() {
			try {
				setLoading(true);
				const data = await api.markets.getMarkets({ sportId: selectedSport.id, isLive: true });
				setMarkets(data.markets);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	useEffect(() => {
		if (!markets.length) return;
		if (odd.idMappedOddtype) {
			const [sportPart, marketPart] = odd.idMappedOddtype.split(':');
			const market = markets.find((item) => item.id === `${sportPart}:${marketPart}`);
			if (market) setSelected(market);
		}
	}, [markets]);

	const selectMarket = (id: string) => {
		const market = markets.find((item) => item.id === id);
		setSelected(market!);
	};

	const selectOdd = (id: string) => {
		editOdd({ ...odd, idMappedOddtype: id });
	};

	return (
		<SimpleLoader loaded={!loading}>
			<div className={`row margin_bottom_10 margin_top_20 ${disabled}`}>
				<div className="align_center margin_left_20">
					<Dropdown
						withSearch
						direction="up"
						listClass={'left_0'}
						selectedItem={selected?.id || ''}
						selectItem={selectMarket}
						icon={'mdi mdi-menu-down'}
						highlightLabel={!!selected}
						margins={['0', '20', '0', '0']}
						fixedTitle={!selected ? t('marketsAdministrations.selectMarket') : ''}
						items={markets
							.sort((a, b) => a.order! - b.order!)
							.map((item) => ({ id: item.id, name: item.localisation[i18n.language].web }))}
					/>
					{selected && !disabled ? (
						<button
							className="btn sm  margin_0"
							onClick={() => {
								setSelected(null);
								editOdd({ ...odd, idMappedOddtype: null });
							}}
							type="button"
						>
							{t('clear')}
						</button>
					) : null}
				</div>
			</div>
			{selected ? (
				<div className={`row margin_bottom_20 margin_top_0 ${disabled}`}>
					<div className="align_center margin_left_20">
						<Dropdown
							withSearch
							direction="up"
							listClass={'left_0'}
							selectedItem={odd.idMappedOddtype || ''}
							selectItem={selectOdd}
							icon={'mdi mdi-menu-down'}
							highlightLabel={!!odd.idMappedOddtype}
							margins={['0', '20', '0', '0']}
							fixedTitle={!odd.idMappedOddtype ? t('marketsAdministrations.selectOdd') : ''}
							items={selected.oddtypes
								.sort((a, b) => a.order! - b.order!)
								.map((item) => ({ id: item.id, name: item.description[i18n.language].web }))}
						/>
						{odd.idMappedOddtype && !disabled ? (
							<button
								className="btn sm  margin_0"
								onClick={() => {
									editOdd({ ...odd, idMappedOddtype: null });
								}}
								type="button"
							>
								{t('clear')}
							</button>
						) : null}
					</div>
				</div>
			) : null}
		</SimpleLoader>
	);
};
