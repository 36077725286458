import React, { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTicket } from '../../context/TicketContext';
import { defaultTicketStatuses } from '../../../../../pages/risk-management/controller/utils/filtersDefaultStatuses';
import InPortal from '../../../InPortal';
import { TicketHistory } from '../../../../../pages/reports/tickets/types';
import { TicketEvent } from '../../../../../pages/risk-management/controller/types';
import { formatDate } from '../../../../../utils/getDate';
import i18n from '../../../../../localisation/i18n';
import { useData } from '../../../../../context/DataProvider';
import { LOSING_RES, PLACED, WINNING_RES } from '../../../../../consts/statuses';
import { extendedStatuses, TicketStatusData } from '../../utils';
import { isEmpty } from 'lodash';

export const TicketChanges = () => {
	const { t } = useTranslation();
	const { ticket, similarTicket, similarTicketShown } = useTicket();
	const [openTimeline, setTimelineOpen] = useState(false);

	let { text, color } = defaultTicketStatuses?.[ticket.status] || { text: 'unknown', color: 'yellow' };
	let { text: similarTicketText, color: similarTicketColor } = defaultTicketStatuses?.[similarTicket?.status || 0];

	if (ticket?.status === PLACED) {
		let data: TicketStatusData | null = null;
		if (ticket?.resultStatus === WINNING_RES) {
			data = extendedStatuses?.['WINNING'];
		} else if (ticket?.resultStatus === LOSING_RES) {
			data = extendedStatuses?.['LOSING'];
		}
		if (ticket?.paidOut) {
			data = extendedStatuses?.['PAID_OUT'];
		}
		if (ticket?.paidOut && ticket?.winType === 'ERROR') {
			data = extendedStatuses?.['PAID_OUT_ERROR'];
		}
		if (ticket?.winType === 'CASHBACK') {
			data = extendedStatuses?.['CASHBACK'];
		}
		if (!isEmpty(data)) {
			text = data?.text || '';
			color = data?.color || '';
		}
	}

	if (ticket?.cashout?.payoutStatus === 'ACCEPTED') {
		text = t('cashout.shortTitle');
		color = 'green';
	}

	return (
		<div className="ticket_info_item flex_direction_row flex_justify_start align_center">
			<div className={`status ${color}`}>{text}</div>
			{similarTicketShown ? (
				<div className={`margin_left_10 disabled status ${similarTicketColor}`}>{similarTicketText}</div>
			) : null}
			<i className="mdi mdi-clock icon_option tooltip_right" onClick={() => setTimelineOpen(true)}>
				<span className="tooltip_content">{t('reports.tickets.changesPreview')}</span>
			</i>
			{openTimeline ? <ChangesModal close={() => setTimelineOpen(false)} /> : null}
		</div>
	);
};

export const ChangesModal = ({ close }: { close: () => void }) => {
	const { t } = useTranslation();
	const { ticket, events } = useTicket();
	return (
		<InPortal>
			<div className={`modal_cover xxl over_1 toggle`}>
				<div className="modal_content fadeInDown animated faster">
					<button className="btn transparent close" onClick={close}>
						<i className="mdi mdi-close"></i>
					</button>

					<div className="modal_head">
						<h2 className="modal_title padding_left_20">
							<strong className="padding_right_10 uppercase">{ticket.barcode} - </strong>
							{t('reports.tickets.timeline')}
						</h2>
					</div>
					{/* Body */}
					<div className="modal_body flex_justify_start direction_column padding_20">
						<div className="timeline">
							{ticket?.ticketHistory?.length
								? ticket.ticketHistory.map((item) => <Row key={item.id} historyItem={item} events={events} />)
								: null}
						</div>
					</div>

					<div className="modal_footer col_border_top"></div>
				</div>
			</div>
		</InPortal>
	);
};

interface Props {
	historyItem: TicketHistory;
	events: TicketEvent[];
}
export const Row: FC<Props> = ({ historyItem, events }) => {
	const { marketsMap } = useData();
	const { dtCreated, userCreated, change, id } = historyItem;
	const { oddChanges, statusChanges, message } = change || {};

	const [date, time] = formatDate(dtCreated, 'date.month.year', 'hours:minutes:seconds');

	const resultStatusMap = {
		1: 'WINNING',
		0: 'LOSING',
	};

	const changeItems = useMemo(() => {
		if (!change) return [];
		let items = [];

		if (oddChanges) {
			for (const eventId in oddChanges) {
				const event = events.find((item) => item.id === eventId)!;
				const [{ name: home }, { name: away } = { name: '' }] = event.competitors || [{}, {}];

				for (const oddId in oddChanges[eventId]) {
					const odd = oddChanges[eventId][oddId];
					const [splited] = oddId.split('|');
					const marketId = oddId.split(':', 2).join(':');
					const oddtypeName = marketsMap[event.sport.id]?.markets?.markets[marketId]?.oddtypes[splited].name || {};

					if (odd.from.value !== odd.to.value) {
						const action = `${event.list_code} - ${home}${away ? ` - ${away}` : ''} odd ${oddtypeName} changed`;
						const state = `From ${odd.from.value} to ${odd.to.value}`;
						items.push({ action, state });
					}
					if (odd.from.result !== odd.to.result) {
						const action = `${event.list_code} - ${home}${
							away ? ` - ${away}` : ''
						} odd ${oddtypeName} status changed`;

						const state = `From ${oddResultStatusMap[
							odd.from.result !== null ? odd.from.result : 'wait'
						].name.toUpperCase()} to ${oddResultStatusMap[
							odd.to.result !== null ? odd.to.result : 'wait'
						].name.toUpperCase()}`;

						items.push({ action, state });
					}
				}
			}
		}

		if (statusChanges) {
			let from = {
				text: 'not resulted',
			};
			let to = {
				text: 'not resulted',
			};
			if (statusChanges?.result_status?.from != null) {
				from = extendedStatuses?.[resultStatusMap[statusChanges.result_status.from]];
			}
			if (statusChanges?.result_status?.to != null) {
				to = extendedStatuses?.[resultStatusMap[statusChanges.result_status.to]];
			}
			if (statusChanges?.status?.from != null) {
				from = defaultTicketStatuses?.[statusChanges.status.from];
			}
			if (statusChanges?.status?.to != null) {
				to = defaultTicketStatuses?.[statusChanges.status.to];
			}

			const action = `Ticket status changed`;
			const state = `From ${from.text.toUpperCase()} to ${to.text.toUpperCase()}`;
			items.push({ action, state });
		}

		return items;
	}, [historyItem]);

	return (
		<div className="timeline_wrapper_item margin_bottom_20">
			<div className="timeline_item" style={{ flexBasis: '30%', alignItems: 'flex-start' }}>
				<div className="time_point">
					<div className="point"></div>
				</div>
				<div className="date date_height">{date}</div>
				<div className="time date_height">{time}</div>
				<div className="person">{userCreated}</div>
			</div>
			<div className="changes_wrapper" title={message}>
				{changeItems.map((item, i) => (
					<div key={`${id}:${i}`} className="timeline_item" style={{ justifyContent: 'space-evenly' }}>
						<div className="action" style={{ width: '400px' }}>
							{item.action}
						</div>
						<div className="state" style={{ width: '250px' }}>
							{item.state}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export const oddResultStatusMap = {
	'1': { color: 'green', name: i18n.t('reports.tickets.winning') },
	'0': { color: 'red', name: i18n.t('reports.tickets.losing') },
	'-1': { color: 'yellow', name: i18n.t('reports.tickets.storno') },
	wait: { color: '', name: i18n.t('reports.tickets.onWait') },
};
