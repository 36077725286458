import { axiosInstance } from './../config';
import { PrintTemplate } from '../../pages/Lists/print-administration/types';
import { PrintTemplateGeneratorState } from './../../pages/Lists/print-administration/types';
import { ExportParams, SpecialExportParams } from '../../pages/Lists/builder/types';

export const print = {
	getPrintTemplates(): Promise<PrintTemplate[]> {
		return axiosInstance.get('/lists_export_templates/').then((res) => res.data);
	},

	updatePrintTemplate(template: PrintTemplate): Promise<PrintTemplate> {
		return axiosInstance
			.put(`/lists_export_templates/`, {
				...template,
			})
			.then((res) => {
				return res.data;
			});
	},
	addPrintTemplate(newTemplate: PrintTemplate): Promise<PrintTemplate> {
		return axiosInstance
			.post(`/lists_export_templates/`, {
				...newTemplate,
			})
			.then((res) => {
				return res.data;
			});
	},
	deletePrintTemplate(templateId: string): Promise<PrintTemplate> {
		return axiosInstance.delete(`/lists_export_templates/${templateId}`).then((res) => {
			return res.data;
		});
	},

	generateTemplateFromBaseData(params: PrintTemplateGeneratorState): Promise<PrintTemplate[]> {
		return axiosInstance.get('/lists_export_templates/template', { params }).then((res) => res.data);
	},

	mainListExportLinks(params: ExportParams): Promise<{ lists: { [key: string]: string | null } }> {
		return axiosInstance.get('lists/list_export', { params }).then((res) => res.data);
	},
	contentsListExportLinks(params: ExportParams): Promise<{ [key: string]: { [key: string]: string | null } }> {
		return axiosInstance.get('lists/table_of_contents', { params }).then((res) => res.data);
	},

	timeOfferListExportLinks(params: ExportParams): Promise<{ [key: string]: { [key: string]: string | null } }> {
		return axiosInstance.post('lists/time_offer', params).then((res) => res.data);
	},

	timezones(): Promise<string[]> {
		return axiosInstance.get('base/timezones').then((res) => res.data);
	},

	specialsExport(params: SpecialExportParams) {
		return axiosInstance.get('/lists/special_export', { params }).then((res) => res.data);
	},
};
