import React, { useEffect, useState } from 'react';
import api from '../../../../api';
import { bonusTabs } from '../../utils';
import { SubHeader } from '../SubHeader';
import { GeoBonuses } from './GeoBonuses';
import { useTranslation } from 'react-i18next';
import Delete from '../../../../utils/svg/Delete';
import { ActivatedBonuses } from './ActivatedBonuses';
import { ActivatedBonus, UserBonuses } from './types';
import { useNotify } from '../../../../hooks/useNotifications';
import { Navigation } from '../../../../components/Navigation';
import { useOnlineUser } from '../../context/OnlineUserContext';
import { SimpleLoader } from '../../../../components/general/Loader';
import NotificationModal from '../../../../components/general/NotificationModal';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';

export const Bonuses = () => {
	const { t, i18n } = useTranslation();
	const { language: ln } = i18n;

	const {
		onlineUser: { uid },
	} = useOnlineUser();

	const { error, success } = useNotify();
	const [loaded, setLoaded] = useState(false);

	const [selectedTab, setSelectedTab] = useState('geo');
	const [bonuses, setBonuses] = useState<UserBonuses | null>(null);
	const [bonusForUnlock, selectUnlockBonus] = useState<ActivatedBonus | null>(null);

	useEffect(() => {
		(async () => {
			try {
				const data = await api.users.getBonuses(uid);
				setBonuses(data);
			} catch (err) {
				error('toast.error.userBonusesLoadError', { autoClose: 5000 });
			} finally {
				setLoaded(true);
			}
		})();
	}, []);

	const handleUnlock = async () => {
		try {
			const bonuses = await api.users.unlockBonus(uid, bonusForUnlock!.idUserBonus);
			setBonuses(bonuses);
			success('toast.success.bonusUnlocked');
			selectUnlockBonus(null);
		} catch (err: any) {
			let errorText = 'toast.error.defaultText';
			if (err.response && err.response.data) errorText = err.response.data.detail[0].msg || err.response.data.detail;
			error(errorText);
		}
	};

	const showGeoBonuses = selectedTab === 'geo' && bonuses?.availableBonuses;
	const showActiveBonuses = selectedTab === 'active' && bonuses?.activatedBonuses;

	return (
		<div className="main_content w_1800" data-simplebar data-simplebar-auto-hide="false">
			<SubHeader />

			<div style={{ padding: '20px', width: '293px' }}>
				<Navigation tabs={bonusTabs} selectedTab={selectedTab} selectTab={setSelectedTab} />
			</div>

			<SimpleLoader loaded={loaded}>
				{showGeoBonuses ? <GeoBonuses availableBonuses={bonuses.availableBonuses} /> : null}
				{showActiveBonuses ? (
					<ActivatedBonuses activatedBonuses={bonuses.activatedBonuses} selectUnlockBonus={selectUnlockBonus} />
				) : null}
			</SimpleLoader>

			{bonusForUnlock ? (
				<NotificationModal
					opened={true}
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={() => selectUnlockBonus(null)}
					confirmAction={() => handleUnlock()}
				>
					<div className="context_icon margin_top_40">
						<Delete />
					</div>
					<h2 className="modal_message capitalize text_center">{t('general.areSure')}</h2>
					<p className="text_center margin_top_10">{`${t('internet.userBonuses.unlockConfirm')} ${
						bonusForUnlock.name?.[ln] || ''
					}`}</p>
				</NotificationModal>
			) : null}
		</div>
	);
};
