import React, { FC } from 'react';
import { CustomOddtypesLimit } from '../../types';
import { useTranslation } from 'react-i18next';

interface Props extends CustomOddtypesLimit {
	selected: boolean;
	select: (id: string) => void;
	deselect: (id: string) => void;
	referentCurrency?: string;
}

export const CustomOdd: FC<Props> = ({
	name,
	idOddtype,
	prematchMax,
	prematchMaxSingle,
	liveMax,
	liveMaxSingle,
	description,
	select,
	selected,
	deselect,
	referentCurrency,
}) => {
	const { t } = useTranslation();
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.target.checked ? select(idOddtype) : deselect(idOddtype);
	};

	const isLive = idOddtype.startsWith('l');
	return (
		<div className="checkbox_custom check_left light ltr_direction">
			<input
				type="checkbox"
				id={`custom_${idOddtype}`}
				step=""
				className="col-1"
				checked={selected}
				onChange={handleChange}
			/>
			<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
			<i className="mdi mdi-checkbox-marked checked"></i>
			<label htmlFor={`custom_${idOddtype}`} className="left space_between col-11">
				<span className="col-2">{name}</span>
				<div className="svg_icon col-1 margin_right_5">
					<svg className="e_ico">
						<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${idOddtype.split(':')[0].slice(1)}`} />
					</svg>
					{isLive ? (
						<i className="mdi mdi-play-circle tooltip_left live_event green_text">
							<span className="tooltip_content">{t('general.live')}</span>
						</i>
					) : (
						<div className="svg_icon"></div>
					)}
				</div>
				<span className="col-5" title={description}>
					{description && description.length > 40 ? description.slice(0, 37) + '...' : description}
				</span>
				<span className="col-1 justify_center" title="Prematch max">
					{prematchMax ? prematchMax : '-'}
				</span>
				<span className="col-1 justify_center" title="Prematch max single">
					{prematchMaxSingle ? prematchMaxSingle : '-'}
				</span>
				<span className="col-1 justify_center" title="Live max">
					{liveMax ? liveMax : '-'}
				</span>
				<span className="col-1 justify_center" title="Live max single">
					{liveMaxSingle ? liveMaxSingle : '-'}
				</span>
				<span className="col-1 justify_center" title="currency">
					{referentCurrency}
				</span>
			</label>
		</div>
	);
};
