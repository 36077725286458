// Ticket type
export const regular = 'regular';
export const live = 'live';
// Pay in type
export const internet = 'internet';
export const cashier = 'cashier';
export const terminal = 'terminal';
// Account type
export const FB = 'FB';
export const INTERNET = 'INTERNET';
