import { cloneDeep } from 'lodash';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LangMedia from '../../../general/LangMedia';
import { FillEmpty } from '../../../general/FillEmpty';
import { useModels } from '../../../../hooks/useModels';
import { OddTypeItem } from '../../../../types/MarketsAdministration';
import { SpecialOddType } from '../../../../pages/administration/special-markets/types';

interface Props {
	odd: OddTypeItem | SpecialOddType;
	editOdd: (odd: OddTypeItem | SpecialOddType) => void;
	langDirection?: 'up' | 'down';
}

export const OddLocalization: FC<Props> = ({ odd, editOdd, langDirection = 'up' }) => {
	const { t } = useTranslation();

	const { languages, media, selectedLang, selectedMedia, selectLang, selectMedia, fillExtended } = useModels();

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let newName = cloneDeep({ ...odd.name });
		newName[selectedLang][selectedMedia] = e.target.value;
		editOdd({ ...odd, name: newName });
	};

	const handleDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		let newDescription = cloneDeep({ ...odd.description });
		newDescription[selectedLang][selectedMedia] = e.target.value;
		editOdd({ ...odd, description: newDescription });
	};

	const [fillActive, setFillActive] = useState(false);

	const fillLocalization = () => {
		setFillActive(false);
		editOdd({ ...odd, description: fillExtended(odd.description), name: fillExtended(odd.name) });
	};

	return (
		<>
			<LangMedia
				languages={languages}
				media={media}
				selectedLang={selectedLang}
				selectedMedia={selectedMedia}
				selectLang={selectLang}
				selectMedia={selectMedia}
				marginTop={'10'}
				direction={langDirection}
			/>

			<div className="tab_content_wrap margin_top_0">
				<div className="tab_content  market_padding active">
					<input
						className="normal  text_center"
						placeholder={t('marketsAdministrations.oddName')}
						onFocus={() => setFillActive(true)}
						onChange={handleNameChange}
						value={(selectedMedia && selectedLang && odd && odd.name[selectedLang][selectedMedia]) || ''}
					/>
					{fillActive &&
					(odd.description[selectedLang][selectedMedia] || odd.name[selectedLang][selectedMedia]) ? (
						<FillEmpty styles={{ marginLeft: '270px' }} onClick={fillLocalization} />
					) : null}

					<textarea
						className="invisible padding_left_25 padding_top_15"
						style={{ resize: 'none' }}
						name="description"
						onFocus={() => setFillActive(true)}
						placeholder={t('marketsAdministrations.oddDescription')}
						cols={30}
						rows={7}
						onChange={handleDescription}
						value={(selectedMedia && selectedLang && odd && odd.description[selectedLang][selectedMedia]) || ''}
					/>
				</div>
			</div>
		</>
	);
};
