import { Item } from './types';

// Returns the first array item which has provided id
export function find(list: Item[], itemId: string) {
	return list.find(({ id }) => id === itemId);
}

// Extract array item by it's id
type extracted = [Item | undefined, Item[]];
export function extract(list: Item[], itemId: string): extracted {
	const extracted = list.find(({ id }) => id === itemId);
	const updatedList = list.filter(({ id }) => id !== itemId);

	return [extracted, updatedList];
}

// Filter list based on the search value
export function filter(list: Item[], searchValue: string) {
	return list.filter(({ name, tournament, id }) => {
		const search = searchValue.toLowerCase();
		return `${id} ${name} ${tournament.name}`.toLowerCase().includes(search);
	});
}

export function filterPaired(list: Item[], searchValue: string) {
	return list.filter(({ name, tournament, pairedWith }) => {
		const search = searchValue.toLowerCase();
		return `${name} ${tournament.name} ${pairedWith[0].tournament.name} ${pairedWith[0].id}`
			.toLowerCase()
			.includes(search);
	});
}

// Sorts the list based on the provided property (name by default)
export function sort(list: Item[], prop: string = 'name') {
	const arr = [...list];

	arr.sort((item1, item2) => {
		const a = item1[prop];
		const b = item2[prop];

		if (a > b) return 1;
		else if (a < b) return -1;
		else return 0;
	});

	return arr;
}

// Sorts the list based on the provided time string property (startTime by default)
export function timeSort(list: Item[], prop: string = 'startTime') {
	const arr = [...list];

	arr.sort(function (item1, item2) {
		const a = item1[prop];
		const b = item2[prop];
		return Date.parse(a) - Date.parse(b);
	});

	return arr;
}
