import React, { useState, useEffect, useContext, createContext, FC, useCallback } from 'react';
import { LiveEvent, LiveEventExtended } from '../types';
import { LiveContextTypes } from './types';
import { useFilter } from '../hooks/useFilter';
import { useRealtime } from '../../../../hooks/useRealtime';
import api from '../../../../api';
import { remap, cleanEmpty, parse } from '../../../../utils';
import { descending, unset, ascending } from '../../../../utils/sorting';
import usePagination from '../../../../hooks/usePagination';
import { useDebounce } from 'use-debounce/lib';
import { PlanItem } from '../../../OffersPlan/types';
import { useData } from '../../../../context/DataProvider';
import { Sport } from '../../sports/types';
import { useLayout } from '../../../../components/layout';
import { Provider } from '../../../system/integrations/types';
import { useNotify } from '../../../../hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import getErrorText from '../../../../utils/getErrorMsg';
import { checkPeriod, checkTimeDifference, useCheckNonActiveEvents } from '../hooks/useCheckNonActiveEvents';

const LiveContext = createContext<LiveContextTypes>({} as LiveContextTypes);

const LiveProvider: FC = (props) => {
	const [events, setEvents] = useState<LiveEvent[]>([]);
	const [event, setEvent] = useState({} as LiveEventExtended);
	const [selectedEventId, setSelectedEventId] = useState('');
	const [selected, setSelected] = useState<string | null>(null);

	const { error: errNotify, success: successNotify } = useNotify();
	const { t } = useTranslation();

	const [selectedPlan, setSelectedPlan] = useState<PlanItem | null>(null);
	const [sports, setSports] = useState<Sport[]>([]);
	const [sport, setSport] = useState('');
	const [provider, setProvider] = useState('');
	const [searchValue, setSearch] = useState('');
	const [debouncedText] = useDebounce(searchValue, 600);

	const [sortedByStart, sortByStart] = useState(ascending);
	const [sortedByBets, sortByBets] = useState(unset);
	const [sortedByCode, sortByCode] = useState(unset);
	const [isDisabled, setIsDisabled] = useState(false);
	const [sortedByTournaments, sortByTournaments] = useState(unset);
	const [orderBy, setOrderBy] = useState('startTimeAsc');

	const { eventStatuses } = useData();
	const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

	const [providerChangeActive, setProviderChangeActive] = useState<string[]>([]);

	const { openSidebarOverContent, closeSidebarOverContent, closeSidebar } = useLayout();

	const [replayModalIsOpen, setReplayModalIsOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());

	useEffect(() => {
		(async function getSports() {
			try {
				const data = await api.sports.getList();
				setSports([
					{
						id: 'all',
						defaultName: 'All',
					},
					...data,
				]);
				setSport('all');
				openSidebarOverContent();
				closeSidebar();
			} catch (err) {
				errNotify(getErrorText(err) || 'toast.error.defaultText');
			}
		})();
		return () => {
			closeSidebarOverContent();
		};
	}, []);

	useEffect(() => {
		setSelectedStatuses(['all', ...eventStatuses]);
	}, [eventStatuses]);

	const { filters, dispatch } = useFilter();

	// Pagination
	const pagination = usePagination(20);
	const { from, setTotal, setPerPage, perPage } = pagination;

	const newEventUpdate = useCallback((prevData: { total: number; events: LiveEvent[] }, update) => {
		try {
			const parsed = parse(update);
			if (!parsed) return prevData;
			const formatted = formatNewEvent(parsed.data);
			let newEvent = cleanEmpty(formatted);
			newEvent = { ...newEvent, socketEvent: true };
			const events = [newEvent, ...prevData.events.filter((event) => event.listCode !== newEvent.listCode)];
			return { total: prevData.total, events } as { total: number; events: LiveEvent[] };
		} catch (error) {
			console.warn(error);
			return prevData;
		}
	}, []);

	const new_event = newEventUpdate;
	const announced_live_event = newEventUpdate;

	/**
	 * Socket update function
	 */
	const updateFunction = useCallback((prevData: any, update) => {
		const parsed = parse(update);
		if (!parsed) return prevData;
		const formatted: any = formatEvent(parsed?.data);

		if (prevData.events) {
			const newEvents = prevData.events.map((event: any) => {
				if (event.id !== formatted.id) return event;

				return {
					...event,
					...cleanEmpty(formatted),
					resultBetCount: event.resultBetCount,
					eventUpdateTimeStamp: Date.now(),
					updateDelayed: false,
				};
			});
			return { ...prevData, events: newEvents };
		}
		return prevData;
	}, []);

	const event_change = updateFunction;
	const event_change_live = updateFunction;
	const event_result = updateFunction;
	const bet_count = updateFunction;

	const event_provider_change = useCallback((prevData: any, update) => {
		const parsed = parse(update);
		if (!parsed) return prevData;
		const { id_event, odds_provider: oddsProvider, other_providers: otherProviders } = parsed.data;
		setTimeout(() => {
			setProviderChangeActive((prevProviderChangeActive) =>
				prevProviderChangeActive?.filter((item) => item !== id_event)
			);
		}, 500);
		if (prevData.events) {
			const newEvents = prevData.events.map((event: any) => {
				if (event.id !== id_event) return event;

				return { ...event, oddsProvider, otherProviders };
			});

			return { ...prevData, events: newEvents };
		}

		return prevData;
	}, []);

	const result_bet_count = useCallback((prevData: any, update) => {
		const parsed = parse(update);
		if (!parsed) return prevData;
		const formatted: any = formatEvent(parsed?.data);

		if (prevData.events) {
			const newEvents = prevData.events.map((event: any) => {
				if (event.id !== formatted.id) return event;
				return { ...event, ...cleanEmpty(formatted) };
			});
			return { ...prevData, events: newEvents };
		}
		return prevData;
	}, []);
	/**
	 * Fetch events and subscribe to changes
	 */
	const { data, error, loading, refresh, setData } = useRealtime<{ total: number; events: LiveEvent[] }>({
		default: {} as { total: number; events: LiveEvent[] },
		init: async ([offerPlanId, filters]) => {
			if (!offerPlanId || !eventStatuses.length || !sports.length) return {};

			const { liveStatuses } = filters;
			const liveEventStatus = [];
			const eventStatus = selectedStatuses.includes('all') ? eventStatuses : selectedStatuses;

			for (const key in liveStatuses) {
				if (liveStatuses[key].active) liveEventStatus.push(key);
			}

			try {
				setIsDisabled(true);
				const data = await api.liveEvents.getLiveEvents({
					offerPlanId,
					q: debouncedText,
					limit: perPage,
					skip: from,
					orderBy,
					eventStatus,
					liveEventStatus,
					providerId: provider,
					sportId: sport === 'all' ? '' : sport,
				});
				const currentDate = Date.now();
				const mappedEvents = data?.events?.map((event: any) => {
					const eventTime =
						event.eventUpdateTimeStamp ?? new Date(event.oddsDataUpdated + 'Z').getTime() ?? Date.now();
					if (
						checkPeriod(event.status, event.liveStatus, event.period) &&
						checkTimeDifference(currentDate, eventTime as number)
					) {
						return { ...event, status: 'STOPPED', updateDelayed: true };
					} else {
						return event;
					}
				});
				return { ...data, events: mappedEvents };
			} catch (ex) {
				return [];
			} finally {
				setIsDisabled(false);
			}
		},
		dependencies: [
			selectedPlan?.id,
			filters,
			from,
			debouncedText,
			orderBy,
			perPage,
			sport,
			provider,
			selectedStatuses,
		],
		join: [
			'alea.event_change',
			'alea.bet_count',
			'alea.result_bet_count',
			'alea.new_event',
			'alea.announced_live_event',
			'alea.event_result',
			'alea.event_change_live',
			'alea.event_provider_change',
		],
		listen: {
			event_change,
			bet_count,
			result_bet_count,
			new_event,
			announced_live_event,
			event_result,
			event_change_live,
			event_provider_change,
		},
	});

	useEffect(() => {
		const filteredData = data?.events?.filter(({ status }) => {
			if (selectedStatuses.includes(status)) {
				return true;
			} else {
				return false;
			}
		});
		setEvents(filteredData ?? []);
		setTotal(data.total);
	}, [data]);

	useCheckNonActiveEvents(setData);

	useEffect(() => {
		if (sortedByStart === descending) setOrderBy('startTimeDesc');
		if (sortedByStart === ascending) setOrderBy('startTimeAsc');
		if (sortedByStart !== unset) {
			sortByCode(unset);
			sortByBets(unset);
			sortByTournaments(unset);
		}
	}, [sortedByStart]);

	useEffect(() => {
		if (sortedByBets === descending) setOrderBy('betCountDesc');
		if (sortedByBets === ascending) setOrderBy('betCountAsc');
		if (sortedByBets !== unset) {
			sortByStart(unset);
			sortByCode(unset);
			sortByTournaments(unset);
		}
	}, [sortedByBets]);

	useEffect(() => {
		if (sortedByCode === descending) setOrderBy('listCodeDesc');
		if (sortedByCode === ascending) setOrderBy('listCodeAsc');
		if (sortedByCode !== unset) {
			sortByStart(unset);
			sortByBets(unset);
			sortByTournaments(unset);
		}
	}, [sortedByCode]);

	useEffect(() => {
		if (sortedByTournaments === descending) setOrderBy('tournamentDesc');
		if (sortedByTournaments === ascending) setOrderBy('tournamentAsc');
		if (sortedByTournaments !== unset) {
			sortByStart(unset);
			sortByBets(unset);
			sortByCode(unset);
		}
	}, [sortedByTournaments]);

	const [highlight, setHighlight] = useState(true);
	const toggleHighlight = () => setHighlight((highlight) => !highlight);

	const changeActiveProvider = async (idEvent: string, providerData: Provider) => {
		try {
			setProviderChangeActive((prevProviderChangeActive) => [...prevProviderChangeActive, idEvent]);
			await api.liveEvents.changeEventProivder(idEvent, providerData.id, true);

			setData({
				...data,
				events: events.map((item) =>
					idEvent === item.id ? { ...item, oddsProvider: { id: providerData.id, name: providerData.name } } : item
				),
			});
		} catch (err) {
			errNotify('toast.error.providerChange');
		}
	};

	const replayMsgsExefeed = async (date: string) => {
		try {
			setIsDisabled(true);

			await api.liveEvents.replayMsgsExefeed(date);

			successNotify(t('toast.success.liveReplayExefeedMessages'));
			setReplayModalIsOpen(false);
		} catch (err: any) {
			const errorText =
				err?.response?.data.detail?.[0]?.msg || err?.response?.data?.detail || t('toast.error.defaultText');
			errNotify(errorText);
		} finally {
			setIsDisabled(false);
		}
	};

	const context: LiveContextTypes = {
		error,
		loading,
		refresh,
		sports,
		event,
		events,
		setEvents,
		setEvent,
		selectedEventId,
		setSelectedEventId,
		selected,
		setSelected,
		selectedStatuses,
		setSelectedStatuses,

		selectPlan: setSelectedPlan,
		selectedPlan,

		changeActiveProvider,

		itemsNumber: perPage,
		setItemsNumber: setPerPage,
		pagination,

		sport,
		setSport,

		provider,
		setProvider,

		searchValue,
		setSearch,

		highlight,
		toggleHighlight,

		filters,
		dispatch,

		sortedByStart,
		sortedByBets,
		sortByStart,
		sortByBets,
		sortedByCode,
		sortByCode,
		sortedByTournaments,
		sortByTournaments,
		setData,
		providerChangeActive,
		isDisabled,
		replayMsgsExefeed,
		replayModalIsOpen,
		setReplayModalIsOpen,
		selectedDate,
		setSelectedDate,
	};
	return <LiveContext.Provider value={context} {...props} />;
};

const useLive = () => useContext(LiveContext);

export { LiveProvider, useLive };

export const formatEvent = remap(
	{
		current_period: 'period',
		id_event: 'id',
		live_status: 'liveStatus',
		utc_scheduled: 'scheduled',
		status: 'status',
		bet_count: 'betCount',
		result_bet_count: 'resultBetCount',
		list_code: 'listCode',
		current_score: 'currentScore',
		current_points: 'currentPoints',
		active_market_count: 'activeMarketCount',
		manually_blocked: 'manuallyBlocked',
		odds_data_updated: 'oddsDataUpdated',
	},
	true
);
const formatNewEvent = remap({
	current_period: 'period',
	id_event: 'id',
	live_status: 'liveStatus',
	utc_scheduled: 'scheduled',
	status: 'status',
	bet_count: 'betCount',
	result_bet_count: 'resultBetCount',
	list_code: 'listCode',
	current_score: 'currentScore',
	current_points: 'currentPoints',
	active_market_count: 'activeMarketCount',
	manually_blocked: 'manuallyBlocked',

	odds_data_updated: 'oddsDataUpdated',
});
