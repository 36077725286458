import React from 'react';
import { defaultCashoutStatuses } from '../../../../../pages/cashout-management/controller/utils/filtersDefaultStatuses';
import { useTicket } from '../../context/TicketContext';

export const CashoutStatuses = () => {
	const {
		ticket: { cashoutData },
	} = useTicket();

	const { color: authColor = '', text: authText = '' } = defaultCashoutStatuses[cashoutData?.authStatus!] || {};
	const { color: payoutColor = '', text: payoutText = '' } = defaultCashoutStatuses[cashoutData?.payoutStatus!] || {};

	return (
		<>
			<div className="ticket_info_item">
				<div className="label">{'AUTH STATUS'}:</div>
				<div className="content margin_top_5">
					<div className={`status ${authColor}`}>{authText}</div>
				</div>
			</div>
			<div className="ticket_info_item">
				<div className="label">{'PAYOUT STATUS'}:</div>

				<div className="content margin_top_5">
					<div className={`status ${payoutColor}`}>{payoutText}</div>
				</div>
			</div>
		</>
	);
};
