import React, { FC } from 'react';
import { OddTypeItem } from '../../../../types/markets';

interface Props {
	id: string;
	name: string;
	order: number;
	sportId: string;
	description: string;
	selected: boolean;
	selectOdd: (odd: OddTypeItem) => void;
	deselectOdd: (odd: OddTypeItem) => void;
}

export const Odd: FC<Props> = ({ id, name, sportId, order, description, selected, selectOdd, deselectOdd }) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.target.checked
			? selectOdd({ id, name, order, description })
			: deselectOdd({ id, name, order, description });
	};
	return (
		<div className="checkbox_custom check_left light ltr_direction">
			<input type="checkbox" id={id} step="" onChange={handleChange} checked={selected} />
			<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
			<i className="mdi mdi-checkbox-marked checked"></i>
			<label htmlFor={id} className="left space_between">
				<span className="width_15_perc">{name}</span>
				<div className="svg_icon width_5_perc">
					<svg className="e_ico">
						<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${sportId}`} />
					</svg>
				</div>
				<span className="width_80_perc" title={description ? description : ''}>
					{description && description.slice(0, 75)}
				</span>
			</label>
		</div>
	);
};

{
}
