import React from 'react';
import { useTranslation } from 'react-i18next';
import useCheckMobileScreen from '../../../../../hooks/useCheckMobileScreen';
import { toMoneyFormat } from '../../../../../utils/toFixed';
import { InfoRow } from '../../InfoRow';

import { TransactionSums } from '../types';

export const StatsBar = ({
	depositIn = 0,
	depositOut = 0,
	betIn = 0,
	betOut = 0,
	gamingIn = 0,
	gamingOut = 0,
}: TransactionSums) => {
	const { t } = useTranslation();

	const totalIn = betIn + gamingIn;
	const totalOut = betOut + gamingOut;

	return (
		<div className="status_row w_1800" style={{ paddingLeft: '137px' }}>
			<div className="col-3">
				<InfoRow label={t('usersAdministrations.depositIn')} info={toMoneyFormat(depositIn)} />
				<InfoRow label={t('usersAdministrations.depositOut')} info={toMoneyFormat(depositOut)} />
				<InfoRow label={t('usersAdministrations.difference')} info={toMoneyFormat(depositIn - depositOut)} />
			</div>

			<div className="col-3">
				<InfoRow label={t('usersAdministrations.betIn')} info={toMoneyFormat(betIn)} />
				<InfoRow label={t('usersAdministrations.betOut')} info={toMoneyFormat(betOut)} />
				<InfoRow label={t('usersAdministrations.difference')} info={toMoneyFormat(betIn - betOut)} />
			</div>

			<div className="col-3">
				<InfoRow label={t('usersAdministrations.gamingIn')} info={toMoneyFormat(gamingIn)} />
				<InfoRow label={t('usersAdministrations.gamingOut')} info={toMoneyFormat(gamingOut)} />
				<InfoRow label={t('usersAdministrations.difference')} info={toMoneyFormat(gamingIn - gamingOut)} />
			</div>

			<div className="col-3">
				<InfoRow label={t('usersAdministrations.totalIn')} info={toMoneyFormat(totalIn)} />
				<InfoRow label={t('usersAdministrations.totalOut')} info={toMoneyFormat(totalOut)} />
				<InfoRow label={t('usersAdministrations.difference')} info={toMoneyFormat(totalIn - totalOut)} />
			</div>
		</div>
	);
};
