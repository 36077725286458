import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useGeo } from '../../context';

interface Props {
	locked: string;
	rowItem: { id: string; value: any; inherited: any };
}
export const GeoRadio: FC<Props> = ({ locked, rowItem }) => {
	const { t } = useTranslation();
	const { updateGeoParameters } = useGeo();
	const { id, value, inherited } = rowItem;
	const checked = locked ? inherited : value;

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const val = +e.currentTarget.value;
		updateGeoParameters(id, !!val);
	};

	return (
		<div className={`value_content flex_direction_row ${locked ? 'disabled' : ''}`}>
			<div className="radio_custom col-3">
				<input type="radio" id={`${id}-1`} name={id} checked={checked === true} onChange={handleChange} value={1} />
				<i className="mdi mdi-radiobox-blank unchecked"></i>
				<i className="mdi mdi-radiobox-marked checked"></i>

				<label htmlFor={`${id}-1`} className="right">
					{t('general.allowed')}
				</label>
			</div>

			<div className="radio_custom col-3">
				<input
					name={id}
					value={0}
					type="radio"
					id={`${id}-2`}
					onChange={handleChange}
					checked={checked === false}
				/>
				<i className="mdi mdi-radiobox-blank unchecked"></i>
				<i className="mdi mdi-radiobox-marked checked"></i>

				<label htmlFor={`${id}-2`} className="right">
					{t('general.notAllowed')}
				</label>
			</div>
		</div>
	);
};
