import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../utils/getDate';
import { AvailableBonus, AvailableBonuses } from './types';

interface Props {
	availableBonuses: AvailableBonuses;
}

export const GeoBonuses: FC<Props> = ({ availableBonuses }) => {
	const { activeBonuses, finishedBonuses, expiredBonuses } = availableBonuses;

	const geoBonuses = useMemo(() => {
		return [
			...activeBonuses.map((bonus) => ({ ...bonus, flag: 'active' })),
			...finishedBonuses.map((bonus) => ({ ...bonus, flag: 'finished' })),
			...expiredBonuses.map((bonus) => ({ ...bonus, flag: 'expired' })),
		];
	}, [availableBonuses]);

	return (
		<>
			<table className="admin_table sticky_footer top_60 full_w ibet_users_table">
				<TableHeader />
				<tbody className="t_body">
					{geoBonuses.map((bonus) => (
						<TableRow key={bonus.id} bonus={bonus} />
					))}
				</tbody>
			</table>
			<div className="empty_div"></div>
		</>
	);
};

export const TableHeader = () => {
	const { t } = useTranslation();
	return (
		<thead className="t_head">
			<tr>
				<th scope="col" className="text_center">
					{t('internet.userBonuses.code')}
				</th>
				<th scope="col">{t('internet.userBonuses.created')}</th>
				<th scope="col" className="text_center">
					{t('general.name')}
				</th>
				<th scope="col">{t('internet.userBonuses.type')}</th>
				<th scope="col">{t('internet.userBonuses.status')}</th>
				<th scope="col">{t('general.wallet')}</th>

				<th scope="col">{t('internet.userBonuses.applied')}</th>
				<th scope="col">{t('internet.userBonuses.canBeApplied')}</th>
				<th scope="col">{t('internet.userBonuses.dateFrom')}</th>
				<th scope="col">{t('internet.userBonuses.dateTo')}</th>
				<th scope="col">{t('internet.userBonuses.currency')}</th>
				<th scope="col" className="text_center"></th>
			</tr>
		</thead>
	);
};

interface RowProps {
	bonus: AvailableBonus;
}
export const TableRow: FC<RowProps> = ({ bonus }) => {
	const { i18n } = useTranslation();
	const { language: ln } = i18n;

	const { status, dtFrom, dtUntil, dtCreated, applied, canBeApplied } = bonus;
	const { bonusCode, bonusType, name, currency, payoutWalletType, flag } = bonus;

	const dateCreated = formatDate(dtCreated, 'date.month.year ');
	const dateFrom = formatDate(dtFrom, 'date.month.year hours:minutes');
	const dateTo = formatDate(dtUntil, 'date.month.year hours:minutes');

	return (
		<tr>
			<td width={100} className="text_center">
				{bonusCode}
			</td>
			<td width={100}>{dateCreated}</td>
			<td className="text_center">{name?.[ln] || ''}</td>
			<td width={150}>{bonusType}</td>
			<td width={100}>{status}</td>
			<td width={150}>{payoutWalletType}</td>
			<td width={150}>{applied}</td>
			<td width={150}>{canBeApplied}</td>
			<td width={150}>{dateFrom}</td>
			<td width={150}>{dateTo}</td>
			<td width={30}>{currency}</td>
			<td width={200} className="text_center">
				{flag?.toUpperCase()}
			</td>
		</tr>
	);
};
