import React from 'react';
import { useTranslation } from 'react-i18next';

const TableHeader = () => {
	const { t, i18n } = useTranslation();

	return (
		<thead className="t_head">
			<tr data-testid="head-row">
				<th>
					<span>{t('usersAdministrations.inLastNumberOfDays')}</span>
				</th>
				<th>
					<span>{t('general.balance')}</span>
				</th>
				<th>
					<span> {t('currencies.currency')}</span>
				</th>
				<th>
					<span>{t('usersAdministrations.dateCreated')}</span>
				</th>
				<th>
					<span>{t('usersAdministrations.timeCreated')}</span>
				</th>
				<th></th>
			</tr>
		</thead>
	);
};

export default TableHeader;
