import React, { FunctionComponentElement, ReactNode } from 'react';
import { useKeyboardListener } from '../../hooks/useKeyboardListener';
import InPortal from './InPortal';
import { ModalSize } from './types';

interface Props {
	opened: boolean;
	header?: FunctionComponentElement<ReactNode>;
	body?: FunctionComponentElement<ReactNode>;
	footer?: FunctionComponentElement<ReactNode> | boolean;
	children?: FunctionComponentElement<ReactNode>;
	size: ModalSize;
	align: string;
	direction: string;
	footerSize?: string;
	sectionClass?: string;
	cancelAction?: () => void;
	zIndex?: string;
	hideFooterBorder?: boolean;
	bodyClass?: string;
	avoidPortal?: boolean;
}

export default ({
	opened,
	cancelAction,
	header,
	body,
	footer,
	children,
	size,
	align,
	direction,
	sectionClass,
	footerSize,
	hideFooterBorder,
	zIndex,
	bodyClass = '',
	avoidPortal = false,
}: Props) => {
	useKeyboardListener('Escape', () => cancelAction?.());
	return opened ? (
		<InPortal avoidPortal={avoidPortal}>
			<div data-testid="modal" className={`modal_cover toggle ${size} ${sectionClass} ${zIndex}`}>
				<div className="modal_content fadeInDown animated faster  w_1800 x_scroll__m_0 margin_left_64_mobile">
					{/* Header */}
					{cancelAction && (
						<button
							data-testid="close-modal"
							className="btn transparent close closeBtnMobile"
							onClick={cancelAction}
							style={{ zIndex: 101 }}
						>
							<i className="mdi mdi-close" />
						</button>
					)}

					{header && <div className="modal_head">{header}</div>}

					{/* Body */}
					{body && (
						<div
							data-testid="modal-body"
							className={`modal_body content_${align} direction_${direction} ${bodyClass} w_1800 `}
						>
							{body}
						</div>
					)}

					{/* Footer */}
					{footer && (
						<div
							data-testid="modal-footer"
							className={`modal_footer ${hideFooterBorder ? '' : 'col_border_top'} ${footerSize}`}
						>
							{footer}
						</div>
					)}

					{children && children}
				</div>
			</div>
		</InPortal>
	) : null;
};
