import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
	name: string;
}

export const RHFCheckbox: FC<Props> = ({ name }) => {
	const { register, control, getValues } = useFormContext();
	const value = getValues(name);
	const checked = useWatch({
		control,
		name,
		defaultValue: value,
	});

	return (
		<div className="checkbox_custom chek_only tooltip_right">
			<input name={name} type="checkbox" ref={register} />
			{!checked ? <i className="mdi mdi-checkbox-blank-outline unchecked" /> : null}
			{checked ? <i className="mdi mdi-checkbox-marked checked" /> : null}
		</div>
	);
};
