import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import getErrorText from '../../../../utils/getErrorMsg';
import { Loader, useLoaded } from '../../../../components/general/Loader';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import { useOnlineUser } from '../../context/OnlineUserContext';
import { Header, PageFooter } from '../../../../components/layout';
import Button from '../../../../components/general/Button';
import { PageContentWrapper } from '../../../../components/layout';
import TableHeader from './components/TableHeader';
import TableRow from './components/TableRow';
import { NotificationData } from './type';
import { NotificationModal } from './components/Modal/NotificationModal';
import { usePagination } from '../../../../hooks';
import { FooterDropdown } from '../../../risk-management/controller/components/general';
import { Height } from '../../../administration/live/components/Height';
import { useNotify } from '../../../../hooks/useNotifications';
import { ascending, descending, unset } from '../../../../utils/sorting';

export const Notifications: FC = () => {
	const { t } = useTranslation();

	const { onlineUser } = useOnlineUser();
	const [notifications, setNotifications] = useState<NotificationData[]>([]);
	const [notificationsToResend, setNotificationsToResend] = useState<NotificationData>();
	const [showNewMessageModal, setShowNewMessageModal] = useState(false);
	const { error } = useNotify();

	// Pagination
	const { from, setTotal, setPerPage, perPage, total, ...pagination } = usePagination(15);

	const loading = useLoaded([notifications], true);
	const [loaded, setLoaded] = useState(false);
	const isMobileView = useCheckMobileScreen();

	const [orderByDateSent, setOrderByDateSent] = useState(unset);
	const [orderByDateExpire, setOrderByDateExpire] = useState(unset);
	const [orderBy, setOrderBy] = useState(['DATE_TIME_SENT_DESC']);

	const getUserNotifications = async () => {
		try {
			setLoaded(false);
			const data = await api.notifications.getUserNotifications(onlineUser.id, from, perPage, orderBy);
			setNotifications(data?.listInboxMessages);
			setTotal(data?.total);
		} catch (err) {
			error(getErrorText(err) || 'toast.error.defaultText');
		} finally {
			setLoaded(true);
		}
	};

	useEffect(() => {
		getUserNotifications();
	}, [onlineUser, from, perPage, orderBy]);

	useEffect(() => {
		if (orderByDateSent === descending) setOrderBy(['DATE_TIME_SENT_ASC']);
		if (orderByDateSent === ascending) setOrderBy(['DATE_TIME_SENT_DESC']);
		if (orderByDateSent !== unset) {
			setOrderByDateExpire(unset);
		}
	}, [orderByDateSent]);

	useEffect(() => {
		if (orderByDateExpire === descending) setOrderBy(['DATE_TIME_EXPIRE_ASC']);
		if (orderByDateExpire === ascending) setOrderBy(['DATE_TIME_EXPIRE_DESC']);
		if (orderByDateExpire !== unset) {
			setOrderByDateSent(unset);
		}
	}, [orderByDateExpire]);

	const tools = {
		leftTools: (
			<Button
				className="btn md outline light_blue"
				onClick={() => setShowNewMessageModal(true)}
				name={t('general.newMessage')}
			/>
		),
	};

	return (
		<Loader loaded={loading}>
			<Header
				title={t('userNotifications.title')}
				widthClass="w_1800"
				zIndex="z_3"
				panel
				hideLogo
				leftTool={tools.leftTools}
			/>
			<PageContentWrapper
				customClasses={'notifications-table-height-override'}
				customScrollClasses={'notifications-table-height-override'}
				isMobileView={isMobileView}
				widthClass={'w_1800'}
			>
				{loaded ? (
					<>
						<table className="admin_table autorisation full_w sticky_header w_1800">
							<TableHeader
								orderByDateSent={orderByDateSent}
								setOrderByDateSent={setOrderByDateSent}
								orderByDateExpire={orderByDateExpire}
								setOrderByDateExpire={setOrderByDateExpire}
							/>
							<tbody className="t_body">
								{notifications.map((message) => (
									<TableRow
										key={message.id}
										item={message}
										resend={() => {
											setNotificationsToResend(message);
											setShowNewMessageModal(true);
										}}
									/>
								))}
							</tbody>
						</table>
					</>
				) : (
					<Height>
						<Loader />
					</Height>
				)}
			</PageContentWrapper>
			<PageFooter
				numberOfItems={<FooterDropdown perPage={perPage} setPerPage={setPerPage} />}
				{...pagination}
				total={total}
			></PageFooter>
			{showNewMessageModal && (
				<NotificationModal
					notificationsToResend={notificationsToResend}
					closeModal={() => {
						setNotificationsToResend(undefined);
						setShowNewMessageModal(false);
					}}
					updateNotifications={getUserNotifications}
				/>
			)}
		</Loader>
	);
};
