import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import { Sport } from '../../pages/administration/sports/types';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { SportType } from '../../api/administrations/sports';
import { useNotify } from '../../hooks/useNotifications';

interface Props {
	handleSport: Dispatch<SetStateAction<string>>;
	selected?: string;
	sportList?: SportType;
}

export default ({ handleSport, selected, sportList = 'REGULAR' }: Props) => {
	const [sports, setSports] = useState<Sport[]>([]);
	const { error } = useNotify();

	// Destructure translation
	const { t, i18n } = useTranslation();
	const { language: ln } = i18n;

	useEffect(() => {
		(async function getSports() {
			try {
				const data = await api.sports.getList(sportList);
				setSports(data);
			} catch (err: any) {
				const errorText =
					err?.response?.data.detail?.[0]?.msg || err?.response?.data?.detail || t('toast.error.defaultText');
				error(errorText);
			}
		})();
	}, []);

	// Toggle markets dropdown
	const [open, setOpen] = useState(false);
	const dropdownClass = open ? 'toggle' : '';

	// Create dropdownRef
	const dropdownRef = useRef<HTMLDivElement>(null);

	// Call useOnClickOutside custom hook
	useOnClickOutside(dropdownRef, () => setOpen(false));

	return (
		<div className="resort_coulmn">
			<span className="cell_short tooltip_left font_700 text_center">
				{t('general.s')}
				<span className="tooltip_content font_400">{t('general.sport')}</span>
			</span>

			<i
				ref={dropdownRef}
				className={`mdi mdi-dots-vertical drop_menu down left select_region ${dropdownClass}`}
				onClick={() => setOpen(!open)}
			>
				<div className="drop_list">
					<div
						className="drop_list_item"
						onClick={() => {
							handleSport('');
							setOpen(false);
						}}
					>
						{t('general.allSportsDropdown')}
					</div>
					{sports.map((sport) => (
						<div
							key={sport.id}
							className={`drop_list_item ${selected && selected === sport.id ? 'selected' : ''}`}
							onClick={() => {
								handleSport(`${sport.id}`);
								setOpen(false);
							}}
						>
							{sport.name?.[ln] || sport.defaultName}
						</div>
					))}
				</div>
			</i>
		</div>
	);
};
