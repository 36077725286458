import {
	EventSpecial,
	EventSpecialData,
	EventSpecialModel,
} from '../../pages/prematch/controller/components/OddsController/Specials/EventSpecial/types';
import { axiosInstance } from '../config';

export const specialEvents = {
	getEventModels({
		idParentEvent,
		idOfferPlan,
		lang = 'en',
		platform = 'web',
	}: {
		idParentEvent: string;
		idOfferPlan?: string;
		lang?: string;
		platform?: string;
	}): Promise<EventSpecialModel> {
		return axiosInstance
			.get(
				`/administration/specials/event_special/models?idParentEvent=${encodeURIComponent(
					idParentEvent
				)}&lang=${lang}&platform=${platform}&idOfferPlan=${idOfferPlan || ''}`
			)
			.then((res) => res.data);
	},

	getEventData(idParentEvent: string, idOfferPlan?: string): Promise<EventSpecialData> {
		return axiosInstance
			.get(
				`/administration/specials/event_special?idParentEvent=${encodeURIComponent(idParentEvent)}&idOfferPlan=${
					idOfferPlan || ''
				}`
			)
			.then((res) => res.data);
	},

	create(eventSpecial: EventSpecial) {
		return axiosInstance.post('/administration/specials/event_special', eventSpecial).then((res) => res.data);
	},

	update(eventSpecial: EventSpecial) {
		return axiosInstance.put('/administration/specials/event_special', eventSpecial).then((res) => res.data);
	},

	active(props: { idParentEvent: string; idOfferPlan?: string; active: boolean }) {
		return axiosInstance.post('/administration/specials/event_special/active', props).then((res) => res.data);
	},
};
