import { divide } from 'lodash';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../../../utils/getDate';
import { ChangeItem } from '../../../types';
import { ChangeDetailsModal } from './ChangeDetailsModal';

interface Props {
	changeItem: ChangeItem;
}

export const TimelineItem: FC<Props> = ({ changeItem }) => {
	const { t } = useTranslation();
	const {
		dtCreated,
		userCreated,
		change: { changes },
	} = changeItem;

	const [modalOpened, setModalOpened] = useState(false);
	const [date, time] = formatDate(dtCreated, 'date.month.year.', 'hours:minutes');

	// const changedFields = Object.keys(changes).join('  |  ');

	const openModal = () => {
		setModalOpened(true);
	};

	const closeModal = () => {
		setModalOpened(false);
	};
	return (
		<>
			<div className="timeline_item margin_top_5 margin_bottom_5 padding_left_20 col_border_bottom">
				<div className="time_point">
					<div className="point"></div>
				</div>
				<div className="date padding_left_6 padding_right_6">{date}</div>
				<div className="time">{time}</div>
				<div className="person">{userCreated}</div>

				<div className="wide_description" style={{ overflow: 'hidden' }}>
					{Object.entries(changes).map(([field, { from, to }], index) => (
						<div className=" description wide_description" key={index}>
							<div className="description changes col_border_right">
								<div className="description_flex">{field}</div>
							</div>
							<div className="w_250 padding_left_6 padding_right_6">
								<div className="description w_50">{`${t('usersAdministrations.fromChange')}: ${from}`}</div>
							</div>
							<div className="w_250">
								<div className="description w_50 padding_left_6 padding_right_6">
									{' '}
									{`${t('usersAdministrations.toChange')}: ${to}`}
								</div>
							</div>
						</div>
					))}
				</div>

				<div className="person">
					<button className="btn lg_32 blue outline" onClick={openModal}>
						{t('usersAdministrations.viewChanges')}
					</button>
				</div>
			</div>
			{modalOpened ? <ChangeDetailsModal changeItem={changeItem} close={closeModal} /> : null}
		</>
	);
};
