import React, { FC } from 'react';
import { LockedDeposits, Wallet } from '../types';
import { Deposits } from './Deposits';
import { WalletBalance } from './WalletBalance';

interface Props {
	wallets?: Wallet[];
	refetchWalletBalance: () => void;
	lockedDeposits: LockedDeposits | null;
}

export const CentralInfo: FC<Props> = ({ wallets = [], refetchWalletBalance, lockedDeposits }) => {
	const walletsMap: { [key: string]: { name: string; order: number } } = {
		FB: {
			name: 'FreeBet',
			order: 1,
		},
		INTERNET: {
			name: 'Internet',
			order: 0,
		},
	};

	return (
		<div className="shrink_1280 flex_direction_row padding_top_20">
			{!lockedDeposits?.lockedDeposits.length ? <div className="col-3"></div> : null}

			{wallets
				?.sort(
					({ walletType: a }, { walletType: b }) => (walletsMap[a]?.order ?? 100) - (walletsMap[b]?.order ?? 100)
				)
				?.map(({ id, balance = 0, currency = '', walletType = '' }, index) => (
					<WalletBalance
						id={id}
						key={index}
						name={walletsMap[walletType]?.name || walletType}
						currency={currency}
						balance={balance}
						blocked={0}
						walletType={walletType}
						refetchWalletBalance={refetchWalletBalance}
					/>
				))}
			{lockedDeposits?.lockedDeposits.length ? (
				<Deposits deposits={lockedDeposits} refetchWalletBalance={refetchWalletBalance} />
			) : (
				<div className="col-1"></div>
			)}
		</div>
	);
};
