import React, { FC, FunctionComponentElement, ReactNode, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toMoneyFormat } from '../../../../../utils/toFixed';
import { useTicket } from '../../context/TicketContext';
import { Field } from '../fields/Field';
import { calculateTaxForPlayedTicket } from './calculateTaxForPlayedTicket';
import { FieldMap } from '../../../../../pages/parameters/geo/types';
import api from '../../../../../api';
import { useAxios } from '../../../../../hooks/useAxios';
import useCheckMobileScreen from '../../../../../hooks/useCheckMobileScreen';

interface Props {
	actions: FunctionComponentElement<ReactNode>;
	readOnly?: boolean;
	showEvents: 'showAuthorizedEvents' | 'showOriginalEvents' | 'showDiff';
}

export const TicketFooter: FC<Props> = ({ actions, readOnly = false, children, showEvents }) => {
	const { t } = useTranslation();
	const { ticket, riskManagerMessage, updateRiskManagerMessage, similarTicket, similarTicketShown } = useTicket();

	// GET fieldMap for Taxes
	const { data: fieldMap } = useAxios<FieldMap>({
		apiFunction: async function getFieldMap() {
			return await api.geo.getFieldMap();
		},
		initialData: {},
		dependencies: [],
	});

	let {
		amount,
		currency,
		maxGain,
		maxQuota,
		combinations,
		operaterMessage,
		cashoutData,
		cashout,
		gain,
		status,
		bonus,
		resultedQuota: resultedFromTicket,
		walletType,
		taxParameters,
		taxData,
		promotionAmount,
	} = ticket;

	const riskCopyTicket = ticket.riskCopyTicket;

	if (showEvents === 'showOriginalEvents') {
		amount = riskCopyTicket.amount;
		currency = riskCopyTicket.currency;
		maxGain = riskCopyTicket.max_gain;
		maxQuota = riskCopyTicket.max_quota;
		combinations = riskCopyTicket.combinations;
		bonus = riskCopyTicket.bonus;
		gain = riskCopyTicket.gain;
		status = riskCopyTicket.status;
		walletType = riskCopyTicket.walletType;
		taxParameters = riskCopyTicket.tax_parameters;
		taxData = riskCopyTicket.tax_data;
	}

	const operatorMessage = cashoutData?.operatorMessage || operaterMessage;
	const resultedQuota = cashoutData?.resultedQuota || resultedFromTicket;

	const ACCEPTED = 'ACCEPTED';

	//TAXES//

	const [payInTax, setPayInTax] = useState(0);
	const [payInTaxCopyTicket, setPayInTaxCopyTicket] = useState(0);
	const [payInTaxSimilarTicket, setPayInTaxSimilarTicket] = useState(0);
	const [cashoutTax, setCashoutTax] = useState(0);
	const [similarTicketCashoutTax, setSimilarTicketCashoutTax] = useState(0);

	const _taxParameters = {
		DEDUCTIBLE_PAY_IN_AMOUNT: taxParameters?.deductible_pay_in_amount,
		DEDUCTIBLE_PAY_OUT_AMOUNT: taxParameters?.deductible_pay_out_amount,
		EXCLUDE_PAY_IN_FROM_PAY_OUT_TAX: taxParameters?.exclude_pay_in_from_pay_out_tax,
		PAY_IN_TAX_LEVELS: taxParameters?.pay_in_tax_levels,
		PAY_OUT_TAX_LEVELS: taxParameters?.pay_out_tax_levels,
	};

	const _taxParametersSimilarTicket = {
		DEDUCTIBLE_PAY_IN_AMOUNT: similarTicket?.taxParameters?.deductible_pay_in_amount,
		DEDUCTIBLE_PAY_OUT_AMOUNT: similarTicket?.taxParameters?.deductible_pay_out_amount,
		EXCLUDE_PAY_IN_FROM_PAY_OUT_TAX: similarTicket?.taxParameters?.exclude_pay_in_from_pay_out_tax,
		PAY_IN_TAX_LEVELS: similarTicket?.taxParameters?.pay_in_tax_levels,
		PAY_OUT_TAX_LEVELS: similarTicket?.taxParameters?.pay_out_tax_levels,
	};

	const payInAmount = taxData?.original_amount ?? amount;

	useEffect(() => {
		if (taxData?.original_amount) setPayInTax(taxData?.original_amount - amount);
	}, [taxData]);

	useEffect(() => {
		if (showEvents === 'showDiff') {
			if (riskCopyTicket.tax_data?.original_amount) {
				setPayInTaxCopyTicket(riskCopyTicket.tax_data?.original_amount - riskCopyTicket.amount);
			}
		}
	}, [showEvents]);

	useEffect(() => {
		if (similarTicketShown) {
			if (similarTicket?.taxData?.original_amount) {
				setPayInTaxSimilarTicket(similarTicket?.taxData.original_amount - similarTicket?.amount);
			}
			if (similarTicket?.cashout && similarTicket.cashout.originalAmount) {
				setSimilarTicketCashoutTax(similarTicket?.cashout?.originalAmount - similarTicket.cashout.amount);
			}
		}
	}, [similarTicketShown]);

	useEffect(() => {
		if (cashout && cashout.originalAmount) {
			setCashoutTax(cashout.originalAmount - cashout.amount);
		}
	}, [cashout]);

	const taxOfMaxGainPlacedTicked = calculateTaxForPlayedTicket(
		{ maxValue: maxGain, minValue: 0 },
		amount,
		_taxParameters,
		{
			parameterMappings: fieldMap,
		}
	);

	const taxOfMaxGain =
		gain && taxData?.original_gain ? taxData?.original_gain - gain : taxOfMaxGainPlacedTicked.maxTax;

	const taxOfMaxGainOriginalTicket = calculateTaxForPlayedTicket(
		{ maxValue: riskCopyTicket?.max_gain, minValue: 0 },
		riskCopyTicket?.amount,
		_taxParameters,
		{
			parameterMappings: fieldMap,
		}
	);

	const taxOfMaxGainSimilarTicket = calculateTaxForPlayedTicket(
		{ maxValue: similarTicket?.maxGain ?? 0, minValue: 0 },
		similarTicket?.amount ?? 0,
		_taxParametersSimilarTicket,
		{
			parameterMappings: fieldMap,
		}
	);

	const _maxGain = gain ? gain : maxGain - taxOfMaxGainPlacedTicked.maxTax;

	return (
		<div className="ticket_footer padding_top_10">
			<div className="info_wrap x_scroll_ls">
				<Field
					label={t('reports.tickets.payin')}
					value={payInAmount}
					similarValue={
						similarTicketShown
							? similarTicket?.taxData?.original_amount
							: riskCopyTicket?.tax_data?.original_amount
					}
					moneyFormat={true}
					currency={true}
					showEvents={showEvents}
				>
					<span className="padding_left_20">
						{walletType === 'FB' ? (
							<svg className="svg_icon freebet_icon green_text ">
								<use xlinkHref="/assets/svg/sprite_mb.svg#freebet_icon"></use>
							</svg>
						) : null}
					</span>
				</Field>
				<Field
					label={t('reports.tickets.livePayment')}
					value={cashoutData?.livePayment || null}
					similarValue={similarTicketShown ? similarTicket?.cashoutData?.livePayment : riskCopyTicket?.livePayment}
					moneyFormat={true}
					currency={true}
					showEvents={showEvents}
				/>

				<Field
					label={t('reports.tickets.payInTax')}
					value={payInTax}
					similarValue={similarTicketShown ? payInTaxSimilarTicket : payInTaxCopyTicket}
					moneyFormat={true}
					currency={true}
					showEvents={showEvents}
				/>

				<Field
					label={t('reports.tickets.bonus')}
					value={`${((bonus || 0) * 100).toFixed(2)}%`}
					similarValue={
						similarTicketShown
							? `${((similarTicket?.bonus || 0) * 100).toFixed(2)}%`
							: `${((riskCopyTicket?.bonus || 0) * 100).toFixed(2)}%`
					}
					moneyFormat={false}
					currency={false}
					showEvents={showEvents}
				/>

				<Field
					label={t('reports.tickets.possibleWin')}
					value={_maxGain}
					similarValue={similarTicketShown ? similarTicket?.maxGain : riskCopyTicket?.max_gain}
					moneyFormat={true}
					currency={true}
					showEvents={showEvents}
				/>
				{!cashout ? (
					<Field
						label={t('reports.tickets.payOutTax')}
						value={taxOfMaxGain}
						similarValue={
							similarTicketShown ? taxOfMaxGainSimilarTicket.maxTax : taxOfMaxGainOriginalTicket.maxTax
						}
						moneyFormat={true}
						currency={true}
						showEvents={showEvents}
					/>
				) : (
					<Field
						label={t('reports.tickets.payOutTax')}
						value={cashoutTax}
						similarValue={similarTicketShown ? similarTicketCashoutTax : cashoutTax}
						moneyFormat={true}
						currency={true}
						showEvents={showEvents}
					/>
				)}

				<Field
					label={t('reports.tickets.maxQuota')}
					value={maxQuota}
					similarValue={similarTicketShown ? similarTicket?.maxQuota : riskCopyTicket?.max_quota}
					moneyFormat={true}
					currency={false}
					showEvents={showEvents}
				/>
				{showEvents === 'showAuthorizedEvents' ? (
					<>
						<Field
							label={t('reports.tickets.winQuota')}
							value={resultedQuota != undefined ? resultedQuota : null}
							similarValue={similarTicket?.resultedQuota != undefined ? similarTicket?.resultedQuota : null}
							moneyFormat={true}
							currency={false}
						/>

						<Field
							label={t('reports.tickets.activeQuota')}
							value={0}
							similarValue={0}
							moneyFormat={true}
							currency={false}
						/>

						<Field
							label={t('reports.tickets.app')}
							value={promotionAmount ?? 0}
							similarValue={similarTicket?.promotionAmount ?? 0}
							moneyFormat={true}
							currency={true}
						/>
					</>
				) : null}

				<Field
					label={t('reports.tickets.combinations')}
					value={combinations}
					similarValue={similarTicketShown ? similarTicket?.combinations : riskCopyTicket?.combinations}
					moneyFormat={false}
					currency={false}
					showEvents={showEvents}
				/>
				{cashout?.payoutStatus === ACCEPTED ? (
					<>
						<div className="footer_info_item">
							<div className="label">{t('cashout.shortTitle')}:</div>
							<div className="content">{toMoneyFormat(cashout?.amount || cashout.approvedAmount || 0)}</div>
						</div>
						{gain != null ? (
							<div className="footer_info_item">
								<div className="label no_border">{t('cashout.greenWin')}:</div>
								<div className="content no_border">
									{toMoneyFormat(gain - (cashout?.approvedAmount ?? cashout?.amount ?? 0))}
									<span className="currency padding_left_5"> {currency}</span>
								</div>
							</div>
						) : null}
					</>
				) : null}
				{status === 7 ? (
					<div className="footer_info_item">
						<div className="label">{t('reports.tickets.gain')}:</div>
						<div className="content">{toMoneyFormat(gain || 0)}</div>
					</div>
				) : null}
				{cashoutData ? (
					<>
						<div className="footer_info_item">
							<div className="label">{t('reports.tickets.requestedCashout')}:</div>
							<div className="content">
								{toMoneyFormat(cashoutData?.cashoutOriginalAmount || 0)}
								<span className="currency padding_left_5"> {currency}</span>
							</div>
						</div>
						<div className="footer_info_item">
							<div className="label no_border">{t('cashout.greenWin')}:</div>
							<div className="content no_border">
								{cashout?.payoutStatus === ACCEPTED && gain != null
									? toMoneyFormat(gain - (cashout?.approvedAmount ?? cashoutData?.cashoutAmount ?? 0))
									: '-'}
								<span className="currency padding_left_5"> {currency}</span>
							</div>
						</div>
					</>
				) : null}
				{children}
			</div>
			<div className="action_wrap">
				<div className="col-4 padding_right_10">
					<input
						className="normal full_w"
						placeholder={t('reports.tickets.bookmakerMessage')}
						value={riskManagerMessage}
						onChange={(event) => updateRiskManagerMessage(event.target.value)}
						type="text"
					/>
				</div>
				<div className="col-5 padding_right_10">
					<input
						className="normal full_w"
						value={operatorMessage || ''}
						readOnly
						type="text"
						placeholder={t('reports.tickets.operatorMessage')}
					/>
				</div>
				{actions}
			</div>
		</div>
	);
};
