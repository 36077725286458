import { PlayerPropsScore, SpecialMarket } from './../../pages/administration/special-markets/types';
import { axiosInstance } from './../config';
import { MarketItem } from './../../types/MarketsAdministration';
import { OddUpdateType } from './../../pages/prematch/controller/types';

export const markets = {
	getMarkets({
		sportId = '',
		q = '',
		offset = 0,
		limit = 0,
		isLive = false,
	}: {
		sportId?: string | number;
		q?: string;
		offset?: number;
		limit?: number;
		isLive?: boolean;
	}) {
		return axiosInstance
			.get(`/administration/markets/?sportId=${sportId}&q=${q}&offset=${offset}&limit=${limit}&isLive=${isLive}`)
			.then((res) => res.data);
	},
	editMarket(market: MarketItem) {
		return axiosInstance
			.put(`/administration/markets/${market.id}`, {
				...market,
			})
			.then((res) => {
				return res.data;
			});
	},
	addMarket(newMarket: any) {
		return axiosInstance
			.post(`/administration/markets/`, {
				...newMarket,
			})
			.then((res) => {
				return res.data;
			});
	},
	addSpecialMarket(market: SpecialMarket): Promise<SpecialMarket> {
		return axiosInstance.post(`/administration/markets/special/create`, market).then((res) => {
			return res.data;
		});
	},
	editSpecialMarket(market: SpecialMarket) {
		return axiosInstance.put(`/administration/markets/special/update`, market).then((res) => {
			return res.data;
		});
	},
	deleteMarket(marketId: string) {
		return axiosInstance.delete(`/administration/markets/${encodeURIComponent(marketId)}`).then((res) => {
			return res.data;
		});
	},

	// Transfer to prematch
	updatePrematchOdds(eventId: string, odds: OddUpdateType[]) {
		return axiosInstance.put(`/administration/events/odd/${eventId}`, [...odds]).then((res) => {
			return res.data;
		});
	},
	disableMarket(id_event: string, market_key: string, enabled: boolean) {
		return axiosInstance
			.post(`/administration/events/market/${id_event}/status?market_key=${market_key}&enabled=${enabled}`)
			.then((res) => {
				return res.data;
			});
	},
	getPlayerPropsScores(sportId: string): Promise<{ playerPropsScores: PlayerPropsScore[] }> {
		return axiosInstance
			.get(`/administration/markets/player_props_scores?id_sport=${sportId}`)
			.then((res) => res.data);
	},
};
