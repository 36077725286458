import React, { FC, useState, useEffect, useContext, createContext, CSSProperties } from 'react';
import { isEqual } from 'lodash';
import api from '../../../../api';
import { FullOdd } from '../types';
import { useTranslation } from 'react-i18next';
import { useAxios } from '../../../../hooks/useAxios';
import { useNotify } from '../../../../hooks/useNotifications';
import { TicketItemExtended } from '../../../../pages/reports/tickets/types';
import { TicketEvent } from '../../../../pages/risk-management/controller/types';
import { CashoutItem } from '../../../../pages/cashout-management/controller/types';
import { useData } from '../../../../context/DataProvider';
import { toMoneyFormat } from '../../../../utils/toFixed';
import getErrorText from '../../../../utils/getErrorMsg';

export interface TicketProps {
	styles?: CSSProperties;
	selectedTicket: TicketItemExtended;
	selectTicket: (ticket: TicketItemExtended | null) => void;
	screen: 'tickets' | 'cashout-offer' | 'cashout-risk' | 'cashout-history';
	closeModal?: (ticket: any) => void;
	authorizeCashout?: (ticket: CashoutItem, action: 'approve' | 'reject') => void;
	similarTicketInitialShown?: boolean;
}

export interface TicketContextProps {
	close: () => void;
	screen: 'tickets' | 'cashout-offer' | 'cashout-risk' | 'cashout-history';
	modified: boolean;
	events: TicketEvent[];
	styles?: CSSProperties;
	eventsAdditionalData: any;
	ticket: TicketItemExtended;
	selectedOdd: FullOdd | null;
	acceptTicketChanges: () => void;
	dismissTicketChanges: () => void;
	stornoTicket: (message: string) => void;
	selectOdd: (odd: FullOdd | null) => void;
	updateOdd: (result: number | null, value: number) => void;
	cashoutValue: string;
	cashoutTime: string;
	riskManagerMessage: string;
	updateCashoutAmount: (value: string) => void;
	updateCashoutTime: (value: string) => void;
	updateRiskManagerMessage: (value: string) => void;
	offerCashout: () => void;
	authorize: (action: 'approve' | 'reject') => void;
	similarTicket: TicketItemExtended | null;
	similarTicketShown: boolean;
	showSimilarTicket: (shown: boolean) => void;
	isDisabled?: boolean;
}

const ticketContextDefaultValues: TicketContextProps = {
	events: [],
	modified: false,
	screen: 'tickets',
	styles: undefined,
	selectedOdd: null,
	close: () => {},
	selectOdd: () => {},
	updateOdd: () => {},
	stornoTicket: () => {},
	eventsAdditionalData: {},
	acceptTicketChanges: () => {},
	dismissTicketChanges: () => {},
	ticket: {} as TicketItemExtended,
	cashoutValue: '',
	cashoutTime: '',
	riskManagerMessage: '',
	updateCashoutAmount: () => {},
	updateCashoutTime: () => {},
	updateRiskManagerMessage: () => {},
	offerCashout: () => {},
	authorize: () => {},
	similarTicket: null,
	similarTicketShown: false,
	showSimilarTicket: () => {},
	isDisabled: false,
};

const TicketContext = createContext(ticketContextDefaultValues);

const TicketProvider: FC<TicketProps> = (props) => {
	const { t } = useTranslation();
	const { marketsMap } = useData();
	const { warn, error, success } = useNotify();

	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	const { selectTicket, selectedTicket, styles, closeModal, authorizeCashout, screen } = props;
	const [events, setEvents] = useState<TicketEvent[]>([]);
	const [ticket, setTicket] = useState(props.selectedTicket);
	const [selectedOdd, selectOdd] = useState<FullOdd | null>(null);

	const [cashoutValue, setCashoutValue] = useState('');
	const [cashoutTime, setCashoutTime] = useState('');
	const [riskManagerMessage, setRiskManagerMessage] = useState(
		ticket?.cashoutData?.riskManagerMessage || ticket?.riskManagerMessage || ''
	);
	const [similarTicket, setSimilarTicket] = useState<TicketItemExtended | null>(null);
	const [similarTicketShown, setSimilarTicketShown] = useState(props.similarTicketInitialShown || false);
	const updateCashoutAmount = (value: string) => {
		setCashoutValue(value);
	};

	const updateCashoutTime = (value: string) => {
		setCashoutTime(value);
	};

	const updateRiskManagerMessage = (value: string) => {
		setRiskManagerMessage(value);
	};

	const offerCashout = async () => {
		try {
			setIsDisabled(true);
			var dtExpires = new Date();
			dtExpires.setSeconds(dtExpires.getSeconds() + +cashoutTime);

			// cashoutValue is string like "7,938.14"  and because of that it was impossible to convert it to number
			const _cashoutValue = cashoutValue.replace(',', '');

			await api.tickets.manualCashoutOffer(ticket.id, +_cashoutValue, dtExpires.toISOString());
			success('reports.tickets.offerManualCashoutSuccess');
			selectTicket(null);
		} catch (err) {
			error(getErrorText(err) || 'toast.error.defaultText');
		} finally {
			setIsDisabled(false);
		}
	};

	useEffect(() => {
		(async () => {
			const { selectedTicket } = props;
			setTicket(selectedTicket);
			setEvents(selectedTicket.systems.map(({ events }) => events).flat());
			if (selectedTicket?.cashoutData?.cashoutAmount) {
				updateCashoutAmount(
					`${toMoneyFormat(
						selectedTicket.cashoutData?.approvedAmount || selectedTicket.cashoutData?.cashoutAmount
					)}`
				);
			}
			if (selectedTicket.similarityValue && selectedTicket.similarTicket) {
				const similarTicketData = await api.tickets.getHistoryTicket(selectedTicket.similarTicket);
				setSimilarTicket(similarTicketData);
			} else setSimilarTicket(null);
		})();
	}, [props.selectedTicket]);

	const { data: eventsAdditionalData } = useAxios<any>({
		apiFunction: async function getData() {
			if (!events.length || screen === 'cashout-history' || screen === 'tickets') return [];
			const payload = events.map((event) => {
				const oddtypes = Object.keys(event.odds).map((odd) => {
					const [splited] = odd.split('|');
					const marketId = odd.split(':', 2).join(':');
					const idMappedOddtype =
						marketsMap[event.sport.id]?.markets?.markets[marketId]?.oddtypes[splited].idMappedOddtype || null;
					return { idOddtype: odd, idMappedOddtype };
				});
				return { idEvent: event.id, oddtypes };
			});

			return await api.tickets.eventsOddtypesCurrentDetails({ events: payload });
		},
		initialData: {},
		dependencies: [events],
	});

	const updateOdd = (newResult: number | null, newValue: number) => {
		const { id, bonus_type, special_value, voided, eventId } = selectedOdd!;

		const systems = ticket.systems.map((system) => {
			return {
				...system,
				events: system.events.map((event) => {
					if (event.id !== eventId) return event;
					let newOdds = { ...event.odds };
					newOdds[id] = { bonus_type, special_value, voided, value: newValue, result: newResult };
					return { ...event, odds: newOdds };
				}),
			};
		});

		setTicket((ticket) => ({ ...ticket, systems }));
	};

	const acceptTicketChanges = async () => {
		try {
			const data = await api.tickets.modifyTicket(ticket);
			selectTicket({ ...ticket, ...data });
			success('reports.tickets.successTicketChanges');
		} catch (err) {
			warn(getErrorText(err) || 'toast.error.defaultText');
		}
	};

	const stornoTicket = async (message: string) => {
		try {
			const data = await api.tickets.stornoTicket(ticket.id, message);
			selectTicket({ ...ticket, ...data });
		} catch (err) {
			warn(getErrorText(err) || 'toast.error.defaultText');
		}
	};

	const dismissTicketChanges = () => {
		setTicket(selectedTicket);
	};

	const close = () => {
		if (!selectedOdd) {
			if (closeModal) closeModal(ticket);
			selectTicket(null);
		}
	};

	const authorize = (action: 'approve' | 'reject') => {
		const cashoutAmount = ticket.cashoutData?.cashoutAmount!;

		// cashoutValue is string like "7,938.14"  and because of that it was impossible to convert it to number
		const _cashoutValue = cashoutValue.replace(',', '');

		if (!_cashoutValue || +_cashoutValue === 0) return warn(`reports.tickets.authCashoutErrorMessage`);

		if (+_cashoutValue > cashoutAmount)
			return warn(t('reports.tickets.authCashoutValidationError', { entity: cashoutAmount }));

		if (authorizeCashout) {
			authorizeCashout({ ...ticket.cashoutData!, riskManagerMessage, approvedAmount: +_cashoutValue }, action);
		}
	};

	const showSimilarTicket = (shown: boolean) => {
		setSimilarTicketShown(shown);
	};

	const modified = !isEqual(selectedTicket, ticket);

	return (
		<TicketContext.Provider
			value={{
				ticket,
				styles,
				close,
				events,
				modified,
				selectOdd,
				selectedOdd,
				updateOdd,
				acceptTicketChanges,
				dismissTicketChanges,
				eventsAdditionalData,
				screen: screen,
				stornoTicket,
				cashoutValue,
				cashoutTime,
				riskManagerMessage,
				updateCashoutTime,
				updateCashoutAmount,
				updateRiskManagerMessage,
				offerCashout,
				authorize,
				similarTicket,
				similarTicketShown,
				showSimilarTicket,
				isDisabled,
			}}
			{...props}
		/>
	);
};

const useTicket = () => useContext(TicketContext);

export { TicketProvider, useTicket };
