import { Currency } from '../../pages/administration/currencies/types';
import { axiosInstance } from '../config';

export const currencies = {
	getCurrencies(): Promise<Currency[]> {
		return axiosInstance.get(`administration/currency_conversions/`).then((res) => res.data);
	},

	updateCurrency(currency: Currency): Promise<Currency> {
		return axiosInstance.post(`administration/currency_conversions/`, currency).then((response) => response.data);
	},
};
