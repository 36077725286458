import React from 'react';
import TreeItem from './Item';
import { Item, Context, Options } from '../types';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';

interface Props {
	type?: string;
	options: Options;
	noDrop?: boolean;
	handleChange: (ctx: Context) => void;
	handleDrag?: (result: DropResult, provided: ResponderProvided) => void;
}

// Fallback function for preventing error if handleDrag() is not provided
const fallBackHandleDrag = () => {};

export default (props: Props) => {
	const { handleChange, handleDrag, options, noDrop, type } = props;
	const { items, generateContext, sortable = false } = options;

	// Generate list of tree items
	const treeItems = items.map((item: Item, index: number) => {
		const ctx = generateContext(`${index}`, item);

		return (
			<TreeItem
				index={index}
				key={item.id}
				nested={false}
				context={ctx}
				type={type}
				draggable={sortable}
				handleChange={handleChange}
				generateContext={generateContext}
			/>
		);
	});

	// In case the tree needs to use external drag context
	if (noDrop)
		return (
			<Droppable droppableId={'tree'} type="tree">
				{(droppable) => (
					<div ref={droppable.innerRef} {...droppable.droppableProps} className="tree_view">
						{treeItems}

						{/* Placeholder for reordering */}
						{droppable.placeholder}
					</div>
				)}
			</Droppable>
		);

	// Return the tree with its own drag context
	return (
		<DragDropContext onDragEnd={handleDrag || fallBackHandleDrag}>
			<Droppable droppableId={'tree'} type="tree">
				{(droppable) => (
					<div ref={droppable.innerRef} {...droppable.droppableProps} className="tree_view">
						{treeItems}

						{/* Placeholder for reordering */}
						{droppable.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};
