import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/general/Button';
import Modal from '../../../../components/Modals';
import { useUser } from '../../../../context/UserProvider';
import { RoleTemplateModal } from '../../users/RoleTemplateModal';
import { ModalRoles, RoleTemplate } from '../types';

interface Props {
	close: () => void;
	initialRoles: ModalRoles;
	confirm: (template: ModalRoles) => void;
	disableButton?: boolean;
}

export const RolesModal: FC<Props> = ({ close, initialRoles, confirm, disableButton }) => {
	const { t } = useTranslation();
	const { roles: superUserRoles } = useUser();
	const onUser = initialRoles.name === undefined;

	const [tab, setTab] = useState<string>(Object.keys(superUserRoles!)?.[0]);
	const [roles, setRoles] = useState(initialRoles);
	const [name, setName] = useState(initialRoles.name || '');

	const [roleTemplatesModalOpened, setRoleTemplatesModalOpened] = useState(false);

	const save = () => {
		confirm({ ...roles, name });
	};

	const applyTemplate = (template: RoleTemplate) => {
		const { id, name, ...rest } = template;
		setRoles({ ...roles, ...rest });
		setRoleTemplatesModalOpened(false);
	};

	return (
		<>
			<Modal
				size={'xxl'}
				align={'start'}
				direction={'row'}
				opened={true}
				sectionClass={'admin_tour full_height'}
				zIndex={'over_1'}
				cancelAction={close}
			>
				<div className="modal_content fadeInDown animated faster">
					<div className="modal_head">
						<div className={`tab_nav padding_left_20 padding_right_20 `}>
							{Object.keys(superUserRoles!).map((roleGroup) => (
								<div
									key={roleGroup}
									onClick={() => setTab(roleGroup)}
									className={`tab_nav_item ${tab === roleGroup ? 'active' : ''}`}
								>
									{roleGroup}
								</div>
							))}
						</div>
						<div className="col_border_bottom padding_bottom_12">
							<Button
								name={t('roles.pickTemplate')}
								className="blue sm margin_right_80"
								onClick={() => setRoleTemplatesModalOpened(true)}
							/>
						</div>
					</div>
					{!onUser ? (
						<input
							type="text"
							className="lg normal margin_left_20"
							value={name}
							onChange={(e) => setName(e.currentTarget.value)}
							placeholder={t('Template name')}
							style={{ width: '200px' }}
						/>
					) : null}
					<div data-testid="modal-body" className="modal_body content_start direction_row">
						<div
							className={`padding_left_40 padding_right_40 margin_top_20`}
							style={{ flexWrap: 'wrap', overflow: 'auto' }}
						>
							{superUserRoles![tab].map((role: string) => {
								const checked = roles[tab].includes(role);
								return (
									<div key={role} className="checkbox_custom col-4 padding_right_10 ">
										<input
											id={role}
											type="checkbox"
											name={role}
											checked={checked}
											onChange={(e) =>
												setRoles({
													...roles,
													[tab]: e.target.checked
														? [...roles[tab], role]
														: roles[tab].filter((rol: string) => rol !== role),
												})
											}
										/>
										<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
										<i className="mdi mdi-checkbox-marked checked"></i>
										<label htmlFor={role} className="right ">
											{role.split('_').join(' ')}
										</label>
									</div>
								);
							})}
						</div>
					</div>

					<div data-testid="modal-footer" className={`modal_footer col_border_top`}>
						<button className="btn md round outline" onClick={() => close()}>
							{t('general.cancel')}
						</button>

						<button
							type="submit"
							className={`btn md round green ${!name && !onUser ? 'disabled' : ''}`}
							onClick={save}
							disabled={(!name && !onUser) || disableButton}
						>
							{t('general.save')}
						</button>
					</div>
				</div>
			</Modal>
			{roleTemplatesModalOpened ? (
				<RoleTemplateModal close={() => setRoleTemplatesModalOpened(false)} confirm={applyTemplate} />
			) : null}
		</>
	);
};
