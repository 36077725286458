import { PairedItem, LocalItem, ProvidedItem, Item } from '../../types';

/**
 *
 * @param startTime start time string `2019-09-02 17:00:00`
 * @returns { { date: string, time: string } }
 */
function convertStartTime(timeString = '') {
	const rgx: any = /20(\d+)-(\d+)-(\d+)\s?(\d{2}:\d{2}):00/g;
	const groups = rgx.exec(timeString);

	if (!groups) return;

	const [_, y, m, d, time]: string[] = groups;
	return { date: `${d}.${m}.${y}.`, time };
}

/**
 * Checks does the local item has paired item for provided providerId.
 * If it has, returns its id as pairedId and name as pairedName,
 * otherwise it returns null
 *
 * @param { LocalItem } item
 * @param { string } providerId
 * @returns { { pairedId: string, pairedName: string } | null }
 */
const getPairedValues = (item: Item, providerId: string) => {
	for (const pairedItem of item.pairedWith) {
		let { id: pairedId, name: pairedName, startTime = '', provider, category } = pairedItem;

		const pairedStartTime = convertStartTime(startTime);

		if (provider.id === providerId) {
			return { pairedId, pairedName, pairedStartTime, categoryName: category.name };
		}
	}

	return null;
};

/**
 * Takes API response data and separates them into
 * paired, local and provided lists
 *
 * @param { any } data
 * @param providerId
 */
export const separateIntoLists = (data: any = {}, providerId: string) => {
	const { localData = [], providerData = [] } = data;

	const pairedIDs: string[] = [];
	const paired: Item[] = [];
	const local: Item[] = [];

	// If providerId is not provided, dont execute forEach
	// so arrays are not populated
	providerId &&
		localData.forEach(({ startTime = '', ...item }: Item) => {
			startTime = convertStartTime(startTime);
			const pairedValues = getPairedValues(item, providerId);

			if (!pairedValues) local.push({ ...item, startTime });
			else {
				paired.push({ ...item, startTime, ...pairedValues });
				pairedIDs.push(pairedValues.pairedId);
			}
		});

	const provided: ProvidedItem[] = providerData
		.filter((item: ProvidedItem) => !pairedIDs.includes(item.id))
		.map(({ startTime = '', ...item }: Item) => ({
			...item,
			startTime: convertStartTime(startTime),
		}));

	return { paired, local, provided };
};

/**
 * Filters out all list items which name property
 * doesn't match the provided value
 *
 * @param { any } list
 * @param { string } value
 */
export const filter = (list: any[], value: string) =>
	list.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase()));

/**
 * Sorts items by the provided property (defaults to the name property)
 *
 * @param { "name" | "defaultName" | undefined } prop
 * @returns { Options } options object for chaining
 */
export function sort(list: any[], prop: string = 'name') {
	return list.sort((item1, item2) => {
		const a = item1[prop];
		const b = item2[prop];

		if (a > b) return 1;
		else if (a < b) return -1;
		else return 0;
	});
}

export function timeSort(list: Item[], prop: string = 'startTime') {
	return list.sort(function (item1, item2) {
		const a = item1[prop];
		const b = item2[prop];
		return Date.parse(a) - Date.parse(b);
	});
}
