import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Delete from '../../../../../utils/svg/Delete';
import NotificationModal from '../../../../general/NotificationModal';
import { useTicket } from '../../context/TicketContext';

export const ManualOfferButton = () => {
	const { t } = useTranslation();
	const { offerCashout, cashoutValue, cashoutTime, isDisabled } = useTicket();
	const [open, setOpen] = useState(false);

	// cashoutValue is string like "7,938.14"  and because of that it was impossible to convert it to number
	const _cashoutValue = cashoutValue.replace(',', '');

	return (
		<div className="padding_10 flex_direction_row flex_justify_end margin_left_auto">
			<button
				className={`btn lg  padding_left_60 padding_right_60 margin_right_0 ${
					!cashoutValue || +_cashoutValue === 0 || !cashoutTime ? 'disabled' : 'green'
				}`}
				onClick={() => setOpen(true)}
				disabled={!cashoutValue || !cashoutTime}
			>
				{t('reports.tickets.offerCashout')}
			</button>
			{open ? (
				<NotificationModal
					opened={true}
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={() => setOpen(false)}
					confirmAction={offerCashout}
					zIndex={'over_3'}
					isDisabled={isDisabled}
				>
					<div className="context_icon margin_top_40">
						<Delete />
					</div>
					<h2 className="modal_message capitalize text_center" style={{ lineHeight: 'normal' }}>
						{t('reports.tickets.manualOfferMsg')}
					</h2>
					<p className="text_center margin_top_10">{`${t('reports.tickets.cashoutAmount')}: ${cashoutValue}`}</p>
					<p className="text_center margin_top_10">{`${t('reports.tickets.cashoutTime')}: ${cashoutTime} ${t(
						'general.seconds'
					)}`}</p>
				</NotificationModal>
			) : null}
		</div>
	);
};
