import React, { Dispatch, FC, SetStateAction, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { IDCard, Passport } from '../types';
import { idCard, passport } from '../utils';
import { TextField } from './TextField';

interface Props {
	edit: boolean;
	documentType: IDCard | Passport;
	setDocumentType: (type: IDCard | Passport) => void;
}

export const DocumentSelect: FC<Props> = ({ edit, documentType, setDocumentType }) => {
	const { t } = useTranslation();

	return (
		<Fragment>
			<div className={`form_row ${!edit ? 'read_only' : ''}`}>
				<label htmlFor="document_select" className="input_label col-3">
					{t('usersAdministrations.documentType')}
				</label>
				<div className="col-6">
					<select
						className="normal focus full_w"
						name="document_select"
						id="document_select"
						disabled={!edit}
						onChange={(event) => setDocumentType(event.target.value as IDCard | Passport)}
						value={documentType}
					>
						<option value={idCard}>{t('usersAdministrations.idCard')}</option>
						<option value={passport}>{t('usersAdministrations.passport')}</option>
					</select>
				</div>
			</div>
			{documentType === idCard && (
				<TextField name={'idCard'} label={`${t('usersAdministrations.idCard')}`} editable={true} edit={edit} />
			)}
			{documentType === passport && (
				<TextField name={'passport'} label={`${t('usersAdministrations.passport')}`} editable={true} edit={edit} />
			)}
		</Fragment>
	);
};
