import React, { FC, useState } from 'react';
import { ProfileActions } from './components/ProfileActions';
import { useOnlineUser } from '../../context/OnlineUserContext';
import { FormUserInfo } from './components/FormUserInfo';
import { ActionsTimeline } from './components/ActionsTimeline';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';

export const Details: FC = () => {
	const { onlineUser } = useOnlineUser();
	const [edit, setEdit] = useState(false);

	const [watcher, refresh] = useState(Date.now());

	const reset = () => {
		refresh(Date.now());
	};
	const [isDisabled, setIsDisabled] = useState(false);

	return (
		<div className="main_content w_1800" data-simplebar data-simplebar-auto-hide="false">
			{onlineUser.uid ? (
				<FormUserInfo edit={edit} setEdit={setEdit} watcher={watcher} setIsDisabled={setIsDisabled} />
			) : null}
			<ProfileActions edit={edit} setEdit={setEdit} reset={reset} isDisabled={isDisabled} />
			<ActionsTimeline changeHistory={onlineUser?.changeHistory} />
		</div>
	);
};
