import { useData } from '../../context/DataProvider';
import { chain } from 'lodash';

export interface TransformedMarket<T> {
	marketId: string;
	marketName: string;
	oddtypes: T[];
}

export function useOddtypesTransform<T extends { idOddtype: string }>(oddtypes: T[]) {
	const { marketsMap } = useData();
	const extended = oddtypes.map((oddtype) => {
		const idParts = oddtype.idOddtype.split(':');
		const marketId = idParts[0] + ':' + idParts[1];
		return { ...oddtype, marketId };
	});
	const returnVal = chain(extended)
		.groupBy((x) => x.marketId)
		.map((value, key) => {
			const sportId = key.split(':')[0].slice(1);
			const marketName = marketsMap[sportId]?.markets?.markets[key].name;
			return {
				marketId: key,
				marketName,
				oddtypes: value,
			};
		})
		.value() as TransformedMarket<T>[];
	return returnVal;
}
