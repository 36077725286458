import React, { FC, Dispatch, SetStateAction } from 'react';
import { Sport } from '../sports/types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../pairing/components';

interface Props {
	sports: Sport[];
	selectSport?: Dispatch<SetStateAction<string>>;
	callback?: () => void;
}

export const SelectSport: FC<Props> = ({ sports, callback }) => {
	const { i18n } = useTranslation();
	const { language: ln } = i18n;

	const { getValues, setValue, control } = useFormContext();
	const { id: initialId, sport } = getValues();
	const selectedSport = useWatch({ control, name: 'sport', defaultValue: sport });

	const handleSelect = (sportId: string) => {
		const { defaultName = '', name = {} } = sports?.find((sport) => sport.id === sportId) || {};
		setValue('sport.id', sportId);
		setValue('sport.defaultName', name?.[ln] || defaultName);
		setValue('sport.name', name?.[ln] || defaultName);
		if (callback) callback();
	};

	const dropdownClass = initialId ? 'disabled' : '';

	return (
		<div className="col-7 margin_left_auto margin_right_auto">
			<Dropdown
				items={sports.map(({ id, defaultName }) => ({ id, name: defaultName }))}
				selectedItem={selectedSport.id}
				selectItem={handleSelect}
				margins={['0', '0', '0', '0']}
				icon={'mdi mdi-menu-down'}
				nonSelectedString={'Select sport'}
				withSearch={true}
				className={dropdownClass}
				listClass={'list_6_items'}
			/>
		</div>
	);
};
