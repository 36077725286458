import React from 'react';

interface Props {
	id: string;
	name: string;
	startTime?: any;
	target: string | null;
	pairWith: (localId: string) => void;
	setTarget: React.Dispatch<React.SetStateAction<string>>;
	competitors: { id: string; name: string }[];
}

export default ({ id, target, pairWith, startTime, setTarget, competitors }: Props) => {
	const selected = id === target;
	const selectedClass = selected ? ' selected' : ' ';
	const itemClass = `list_item_content listitem_target ${selectedClass}`;

	const [day, month, year] = startTime ? startTime.date.split('.') : [];

	const [{ name: home }, { name: away }] = competitors || [{}, {}];

	return (
		<div className={`list_item ${selectedClass}`}>
			{away ? (
				<div className={`${itemClass}`}>
					<div className="home_team" title={home}>
						{home}
					</div>
					<div className="vs">vs</div>
					<div className="away_team" title={away}>
						{away}
					</div>
				</div>
			) : (
				<div className={`${itemClass}`}>
					<div className="home_team" title={home}>
						{home}
					</div>
				</div>
			)}

			{startTime && (
				<div className="event_start">
					<div className="event_date">{`${day}.${month}.${year.slice(2)}`}</div>
					<span className="divider"></span>
					<div className="event_time">{startTime.time}</div>
				</div>
			)}

			<div className="checkbox_custom chek_only">
				<input type="checkbox" checked={selected} onChange={() => (id === target ? setTarget('') : pairWith(id))} />
				<i className="mdi mdi-radiobox-blank unchecked"></i>
				<i className="mdi mdi-radiobox-marked checked"></i>
			</div>
		</div>
	);
};
