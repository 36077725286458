import { useState, useEffect } from 'react';

type Data = null | Object;

/**
 * Tracks list of response values and returns true
 * when all values are positive
 *
 * @param { Array } dataList list of values to track
 * @returns { boolean }
 */
export default (dataList: any[] = [], initial: boolean = false): boolean => {
	// Set loaded to false and create start time snapshot
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (!initial || !loaded) {
			setLoaded(false);
			let allLoaded = true;
			let timeout: NodeJS.Timeout;

			// Loop through the dataList and check are
			// all values positive which means everything is loaded
			for (const data of dataList) if (!data) allLoaded = false;
			if (allLoaded) timeout = setTimeout(() => setLoaded(true), 300);

			// Clear timeout if component is unmounted before timeout is executed
			return () => timeout && clearTimeout(timeout);
		}
	}, dataList);

	return loaded;
};
