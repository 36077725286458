import React, { FC, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import Dropdown from '../../../../components/general/Dropdown';
import { TournamentItem } from '../../../administration/tournaments/types';
import { PlanItem } from '../../../OffersPlan/types';

interface Props {
	selectedSport: string;
	selectedTournament: string;
	selectTournament: (id: string) => void;
	margins?: string[];
	disableAll?: boolean;
	isDisabled?: boolean;
	selectedPlan?: PlanItem | null | undefined;
}
export const TournamentsDropdown: FC<Props> = ({
	selectedSport,
	selectTournament,
	selectedTournament,
	margins = ['0', '20', '0', '0'],
	disableAll = false,
	isDisabled,
	selectedPlan = null,
}) => {
	const { t } = useTranslation();
	const [tournaments, setTournaments] = useState([] as TournamentItem[]);
	useEffect(() => {
		if (!selectedSport) return;
		(async function initTournaments() {
			try {
				const { tournaments = [] } =
					(await api.tournaments.getTournaments({
						sportId: selectedSport,
						offerPlanId: selectedPlan?.id ?? null,
					})) || {};
				setTournaments(tournaments);
				selectTournament('');
			} catch (error) {
				console.error(error);
			}
		})();
	}, [selectedSport, selectedPlan]);

	const mapped = useMemo(
		() => tournaments.map(({ id, defaultName }) => ({ id: `${id}`, name: defaultName })),
		[tournaments, selectedTournament]
	);

	const items = disableAll ? mapped : [{ id: '', name: t('general.all') }, ...mapped];

	return (
		<Dropdown
			items={items}
			selectedItem={selectedTournament}
			selectItem={selectTournament}
			withSearch
			listClass={'right_0'}
			fixedTitle={!selectedTournament ? t('general.tournament') : ''}
			margins={margins}
			icon={'mdi mdi-menu-down'}
			highlightLabel={!!selectedTournament}
			isDisabled={isDisabled}
		/>
	);
};
