import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AddKeyboardCode } from './AddKeyboardCode';
import { KeyboardCodeItem } from './KeyboardCodeItem';
import { OddLocalization } from './OddLocalization';
import { OddTypeItem } from '../../../../types/MarketsAdministration';
import { MapPrematchToLive } from './MapPrematchToLive';

interface Props {
	odd: OddTypeItem;
	editOdd: (odd: OddTypeItem) => void;
	defaultMarket: boolean;
}

export const MarketsOddsModal: FC<Props> = ({ odd, editOdd, defaultMarket }) => {
	const { t } = useTranslation();

	const [keyboardCodes, setKeyboardCodes] = useState(odd.alternativeKeyboardCodes || []);
	const [cashout, setCashout] = useState(false);

	useEffect(() => {
		setKeyboardCodes(odd.alternativeKeyboardCodes || []);
		setCashout(!!odd.allowedForCashout);
	}, [odd]);

	const handleKeyboardCode = (e: React.ChangeEvent<HTMLInputElement>) => {
		editOdd({ ...odd, keyboardCode: e.target.value });
	};
	const handleRiskFactor = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		// Prevent input field to 2 decimal places
		const [_, decimals] = value.split('.');
		if (decimals?.length > 2) return;

		if (value && (+value < 0 || +value > 1)) return;

		editOdd({ ...odd, riskFactor: e.target.value ? +e.target.value : '' });
	};

	const handleDelay = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		if (value && +value < 0) return;

		editOdd({ ...odd, bettingDelay: e.target.value ? +e.target.value : '' });
	};

	const handleCashout = (e: React.ChangeEvent<HTMLInputElement>) => {
		editOdd({ ...odd, allowedForCashout: e.currentTarget.checked });
	};

	const addNewCode = (codeName: string) => {
		editOdd({ ...odd, alternativeKeyboardCodes: [...keyboardCodes, codeName] });
	};

	const deleteCode = (codeName: string) => {
		editOdd({ ...odd, alternativeKeyboardCodes: keyboardCodes.filter((code) => code !== codeName) });
	};

	const disabled = !defaultMarket ? 'disabled' : '';

	return (
		<>
			{disabled ? <p className={'padding_left_25'}>{t('marketsAdministrations.grayed')}</p> : null}
			<div className={`margin_bottom_20 margin_top_20  padding_left_25 align_center ${disabled}`}>
				<div className="align_center width_50_perc">
					<div className="label col-6">{`${t('specialMarkets.keyboardCode')}:`}</div>
					<div className="col-3 margin_right_25">
						<input
							className="col-12 normal text_center"
							style={{ height: '28px' }}
							name="order"
							type="text"
							value={odd.keyboardCode}
							onChange={handleKeyboardCode}
						/>
					</div>
				</div>

				<div className="align_center width_50_perc">
					<div className="label col-4">{`${t('specialMarkets.formula')} :`}</div>
					<div className="col-6 margin_right_25">
						<input
							className="col-12 normal text_center disabled"
							style={{ height: '28px' }}
							name="order"
							type="text"
							value={odd.formula}
							disabled
						/>
					</div>
				</div>
			</div>
			<div className={`margin_bottom_10 margin_top_20  padding_left_25 align_center ${disabled}`}>
				<div className="align_center width_50_perc">
					<div className="label col-6">{`${t('marketsAdministrations.riskFaktor')} :`}</div>
					<div className="col-3 margin_right_25">
						<input
							className="col-12 normal text_center"
							style={{ height: '28px' }}
							name="order"
							type="number"
							value={odd.riskFactor}
							onChange={handleRiskFactor}
						/>
					</div>
				</div>

				<div className="align_center width_50_perc">
					<div className="label col-5">{`${t('marketsAdministrations.delay')} :`}</div>
					<div className="col-6 margin_right_25">
						<input
							className="col-8 normal text_center"
							style={{ height: '28px' }}
							name="order"
							type="number"
							value={odd.bettingDelay}
							onChange={handleDelay}
						/>
					</div>
				</div>
			</div>

			<div className={`margin_bottom_20 margin_top_10  padding_left_25 ${disabled}`}>
				<div className="align_center col-10">
					<div className="align_center  width_50_perc">
						<div className="label col-8">{t('marketsAdministrations.allowCashout')}</div>
						<div className="checkbox_custom chek_only tooltip_right col-4 flex_justify_space_around ">
							<input name="allowedForCashout" type="checkbox" checked={cashout} onChange={handleCashout} />
							{!cashout ? <i className="mdi mdi-checkbox-blank-outline unchecked" /> : null}
							{cashout ? <i className="mdi mdi-checkbox-marked checked" /> : null}
						</div>
					</div>
				</div>
			</div>

			<div className="divider_horizontal small label_left  padding_left_25 col-10">
				<div className="div_label">{t('marketsAdministrations.alternativeCodes')}</div>
				<div className="thin_line"></div>
			</div>

			<AddKeyboardCode addNewCode={addNewCode} keyboardCodes={keyboardCodes} disabled={disabled} />

			<div className={`align_center col-12 flex ${disabled}`} style={{ flexWrap: 'wrap' }}>
				{keyboardCodes.map((code, i) => (
					<KeyboardCodeItem key={`${code}:${i}`} keyboardCode={code} deleteCode={deleteCode} />
				))}
			</div>

			<div className="divider_horizontal small label_left  padding_left_25 col-10">
				<div className="div_label">{t('marketsAdministrations.mapPrematch')}</div>
				<div className="thin_line"></div>
			</div>

			<MapPrematchToLive odd={odd} editOdd={editOdd} disabled={disabled} />

			<OddLocalization odd={odd} editOdd={editOdd} />
		</>
	);
};
