import React, { FC, createContext, useContext, useMemo, useState, useEffect } from 'react';
export * from 'use-emitter';
import { EmitterProvider } from 'use-emitter';
import { ws, useMsgPack } from '../api/host';
import { useSocketInstance } from '../hooks/useSocketInstance';

interface SocketContext extends SocketIOClient.Socket {
	connected: boolean;
	rooms: string[];
	id: string;
	join: (room: string) => void;
	leave: (room: string) => void;
}

const Context = createContext({} as SocketContext);

export const useSocket = () => useContext(Context);

export const SocketProvider: FC = ({ children }) => {
	const { socket, connected } = useSocketInstance(useMsgPack, ws, '', 'MAIN', ['websocket']);

	// Close connection when context is unmounted
	useEffect(() => {
		() => void !socket?.disconnected && socket?.close();
	}, [socket]);

	/**
	 * Listeners and properties
	 */
	const [id, setId] = useState('');
	const [rooms, setRooms] = useState([] as string[]);

	// useEffect(() => {
	// 	socket.on('rooms', setRooms);
	// 	socket.on('connect', setId);
	// 	socket.emit('rooms');
	// }, []);

	/**
	 * Custom methods
	 */
	const join = (room: string) => socket && room && socket.emit('join', room, setRooms);
	const leave = (room: string) => socket && room && socket.emit('leave', room, setRooms);

	// Combine socket properties
	const context = { id, join, leave, rooms, ...socket, connected };

	return (
		//@ts-ignore
		<Context.Provider value={context}>
			<EmitterProvider emitter={socket}>{children}</EmitterProvider>
		</Context.Provider>
	);
};
