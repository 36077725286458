import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatDate } from '../../../../../utils/getDate';

interface Props {
	name: string;
	label: string;
}

export const TimeField: FC<Props> = ({ name, label }) => {
	const { getValues } = useFormContext();

	const raw = getValues(name);
	const value = raw ? formatDate(raw, 'date.month.year hours:minutes')[0] : '';
	return (
		<div className="form_row read_only">
			<label htmlFor={name} className="input_label col-3 ">
				{label}
			</label>
			<div className="col-6">
				<input
					type="text"
					id={name}
					className="normal focus full_w"
					name={name}
					value={value}
					onChange={() => {}}
					disabled={true}
				/>
			</div>
		</div>
	);
};
