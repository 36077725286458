export default (providedDate: string | null = null): string => {
	// Zero prefix
	const zp = (num: number) => (num > 9 ? num : `0${num}`);

	const d = providedDate ? new Date(providedDate + 'Z') : new Date();

	const h = zp(d.getHours());
	const m = zp(d.getMinutes());
	const s = zp(d.getSeconds());

	const day = zp(d.getDate());
	const mon = zp(d.getMonth() + 1);
	const yea = d.getFullYear();

	const result = `${day}.${mon}.${yea}. ${h}:${m}`;
	return result;
};

export function formatDate(providedDate: string, ...querys: string[]): string[] {
	try {
		// Zero prefix
		const zp = (num: number) => (num > 9 ? num : `0${num}`);
		const d = providedDate ? new Date(providedDate + 'Z') : new Date();
		const weekdays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

		const dateProps: any = {
			hours: zp(d.getHours()),
			minutes: zp(d.getMinutes()),
			seconds: zp(d.getSeconds()),
			date: zp(d.getDate()),
			month: zp(d.getMonth() + 1),
			year: d.getFullYear(),
			day: weekdays[d.getDay()],
		};

		return querys.map((query) =>
			query.replace(/(\w+)/g, (_, prop) => {
				return dateProps[prop];
			})
		);
	} catch {
		return [];
	}
}

export const isInFuture = (date: Date) => {
	return date.getTime() > Date.now();
};

export const utcToLocal = (utcDate: Date) => {
	// Create a new Date object from the UTC date string (assuming ISO 8601 format)
	const localDate = new Date(utcDate.toISOString());

	// Get the time zone offset in minutes between UTC and local time
	const offset = localDate.getTimezoneOffset();

	// Adjust the local date by subtracting the offset (in minutes)
	localDate.setMinutes(localDate.getMinutes() - offset);

	// Return the local date object
	return localDate;
};
