import { CompetitorItemExtended } from './../pages/administration/competitors/types';

export const emptyCompetitor: CompetitorItemExtended = {
	id: '',
	sport: {
		id: '',
		defaultName: '',
	},
	tournaments: [],
	country: '',
	city: '',
	venue: '',
	defaultName: '',
	name: {
		srb: '',
		en: '',
		de: '',
	},
	shortName: {
		srb: '',
		en: '',
		de: '',
	},
	icons: {
		web: '',
		terminal: '',
		mobile: '',
		list: '',
	},
	providerOffer: 'XSC',
	providerTable: 'XSC',
	providerResult: 'XSC',
	providerOdds: 'XSC',
	timeCreation: '',
	timeModify: '',
	players: [],
};
