import React from 'react';
import { useGeo } from '../../../parameters/geo/context';
import { ModifiedActions } from '../../../parameters/geo/components/ModifiedActions';
import { SearchWrapper } from '../../../parameters/geo/components/SearchWrapper';

export const UserGeoHeader = () => {
	const { setSearch, modified } = useGeo();
	return (
		<div className="subheading z_5">
			<div className={`margin_right_auto ${modified ? 'disabled' : ''}`}>
				<SearchWrapper setSearch={setSearch} />
			</div>
			<ModifiedActions />
		</div>
	);
};
