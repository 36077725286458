import React, { FC, useMemo, Dispatch, SetStateAction, ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import { CANCELED_REQUEST } from '../../../../api/config';
import { useNotify } from '../../../../hooks/useNotifications';
import { formatDate } from '../../../../utils/getDate';
import { toMoneyFormat } from '../../../../utils/toFixed';
import { RiskTagCell } from '../../../online-user/components/RiskTagCell';
import { TicketItemExtended } from '../types';
import { useUserConfig } from '../../../../context/UserConfigProvider';
import { REPORTS_TICKETS_COLUMNS } from '../../../../consts/userConfig';
import getErrorText from '../../../../utils/getErrorMsg';
import { RiskGroupCell } from '../../../risk-management/risk-groups/RiskGroupCell';

interface Props {
	ticket: TicketItemExtended;
	selectedTicket: TicketItemExtended | null;
	selectTicket: Dispatch<SetStateAction<TicketItemExtended | null>>;
	hovered?: TicketItemExtended | null;
	setHovered: Dispatch<SetStateAction<TicketItemExtended | null>>;
}

export const TableRow: FC<Props> = ({ ticket, selectedTicket, selectTicket, hovered, setHovered }) => {
	const { warn } = useNotify();
	const { t } = useTranslation();
	const { isColumnVisible } = useUserConfig();

	let {
		id,
		amount,
		authStatus,
		currency,
		userInfo,
		waitsFor,
		livePayment,
		barcode,
		cashout,
		riskTag,
		geoEntityName,
		walletType,
		taxData,
	} = ticket;
	const { dtPlaced, gain, live, riskManager, status, maxGain, idUser, bonus, winType, riskValue, operator } = ticket;
	const { manualAuthorization, similarityValue, resultStatus, paidOut } = ticket;
	const [date] = formatDate(dtPlaced || '', 'date.month.year | hours:minutes:seconds');

	const [onElement, setOnElement] = useState(false);

	const [disabledRowClick, setDisabledRowClick] = useState<boolean>(false);

	const [fetchedTicket, setFetchedTicket] = useState<TicketItemExtended | null>(null);

	const handleSelect = async () => {
		try {
			setDisabledRowClick(true);
			const ticketData = await api.tickets.getHistoryTicket(id);
			selectTicket({
				...ticket,
				ticketHistory: ticketData.ticketHistory,
				resultedQuota: ticketData?.resultedQuota,
				fullUserInfo: ticketData.fullUserInfo,
				riskCopyTicket: ticketData.riskCopyTicket,
			});
		} catch (error: any) {
			if (error === CANCELED_REQUEST) return;
			warn(getErrorText(error) || 'toast.error.defaultText');
		} finally {
			setDisabledRowClick(false);
		}
	};

	const onEnter = async () => {
		if (selectedTicket || hovered?.id === id) return;
		try {
			setOnElement(true);
			const ticketData = await api.tickets.getHistoryTicket(id);
			setFetchedTicket({
				...ticket,
				ticketHistory: ticketData?.ticketHistory,
				resultedQuota: ticketData?.resultedQuota,
				fullUserInfo: ticketData?.fullUserInfo,
				riskCopyTicket: ticketData.riskCopyTicket,
			});
		} catch (error: any) {
			if (error === CANCELED_REQUEST) return;
			warn(getErrorText(error) || 'toast.error.defaultText');
		}
	};
	const onLeave = () => {
		if (selectedTicket) return;
		setHovered(null);
		setFetchedTicket(null);
		setOnElement(false);
	};

	useEffect(() => {
		if (onElement) setHovered(fetchedTicket);
	}, [fetchedTicket]);

	const gainValue = cashout ? (cashout.amount ? cashout.amount : cashout.approvedAmount) : gain;

	const RowSign: ReactElement = useMemo(() => {
		if (authStatus === 1) return <i className="mdi mdi-hand-left redish_text tooltip_right"> </i>;
		if (winType === 'CASHBACK')
			return (
				<div className="status_symbol tooltip_right">
					<i className="mdi mdi-undo-variant dark_green_text"></i>
				</div>
			);
		if (cashout && cashout.payoutStatus === 'ACCEPTED')
			return <i className="mdi mdi-alpha-c-circle dark_green_text tooltip_right"> </i>;
		if (paidOut) return <i className="mdi mdi-check-circle dark_green_text tooltip_right"> </i>;
		if (resultStatus === 0) return <i className="mdi mdi-alpha-p-circle redish_text tooltip_right"> </i>;
		if (resultStatus === 1) return <div className="status_symbol green tooltip_right"> </div>;
		if (status === 2) return <i className="mdi mdi-alpha-s-circle orange_text tooltip_right"> </i>;
		if (status === 9) return <i className="mdi mdi-alpha-e-circle red_text tooltip_right"> </i>;
		if (status === 8) return <i className="mdi mdi-alpha-w-circle blue_text tooltip_right"> </i>;

		if (status === 6 || status === 5)
			return (
				<div className="status_symbol gray tooltip_right">
					<i className="mdi mdi-close-octagon redish_text"></i>
				</div>
			);
		return <div className="status_symbol gray tooltip_right">{waitsFor} </div>;
	}, [ticket]);

	const AuthSign: ReactElement = useMemo(() => {
		if (authStatus === 0)
			return (
				<i className="mdi mdi-alpha-r-circle  tooltip_right">
					<span className="tooltip_content font_400 ">{t('ticketFilters.REQUESTED')}</span>
				</i>
			);
		else if (authStatus === 1)
			return (
				<i className="mdi mdi-alpha-d-circle redish_text tooltip_right">
					<span className="tooltip_content font_400 redish">{t('ticketFilters.DENIED')}</span>
				</i>
			);
		else if (authStatus === 2)
			return (
				<i className="mdi mdi-alpha-a-circle green_text tooltip_right">
					<span className="tooltip_content font_400 green">{t('ticketFilters.ACCEPTED')}</span>
				</i>
			);
		else if (authStatus === 3)
			return (
				<i className="mdi mdi-alpha-m-circle orange_text tooltip_right">
					<span className="tooltip_content font_400 orange">{t('ticketFilters.MODIFIED')}</span>
				</i>
			);
		else if (authStatus === 6)
			return (
				<i className="mdi mdi-alpha-c-circle  tooltip_right">
					<span className="tooltip_content font_400 ">{t('ticketFilters.CLOSED')}</span>
				</i>
			);
		else
			return (
				<i className="mdi mdi-alpha-e-circle red_text tooltip_right" style={{ opacity: 0 }}>
					<span className="tooltip_content font_400 red">{t('ticketFilters.EXPIRED')}</span>
				</i>
			);
	}, [ticket]);

	return (
		<tr className={`padding_10 ${disabledRowClick ? 'disabled' : ''}`}>
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.STATUS) && (
				<td onMouseEnter={onEnter} onMouseLeave={onLeave}>
					{RowSign}
				</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.SIGN) && (
				<td onClick={handleSelect}>
					<div className={`status_sign ${live ? 'green' : 'blue'}`} style={{ width: '24px' }}>
						<span className="sign">{live ? 'L' : 'R'}</span>
					</div>
				</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.LOCATION) && (
				<td onClick={handleSelect}>
					<i data-testid="edit-icon" className={`mdi mdi-${idUser ? 'cellphone' : 'desktop-mac'}`}></i>
				</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.BARCODE) && <td onClick={handleSelect}>{barcode}</td>}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.TIME) && <td onClick={handleSelect}>{date}</td>}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.SA) && <td>{AuthSign}</td>}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.PAYIN) && (
				<td className="text_right" onClick={handleSelect}>
					{toMoneyFormat(taxData?.original_amount || amount)}
				</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.CURRENCY) && <td onClick={handleSelect}>{currency}</td>}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.ACC) && (
				<td onClick={handleSelect}>
					{walletType === 'FB' ? (
						<svg className="svg_icon freebet_icon green_text ">
							<use xlinkHref="/assets/svg/sprite_mb.svg#freebet_icon"></use>
						</svg>
					) : null}
				</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.MAX_GAIN) && (
				<td className="text_right" onClick={handleSelect}>
					{toMoneyFormat(maxGain)}
				</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.GAIN) && (
				<td className="text_right" onClick={handleSelect}>
					{toMoneyFormat(gainValue || 0)}
				</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.PAYOUT) && (
				<td onClick={handleSelect} className="text_right">
					{status === 7 || cashout?.payoutStatus === 'ACCEPTED' ? toMoneyFormat(gainValue || 0) : '-'}
				</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.LIVE_PAYOUT) && (
				<td className="text_right" onClick={handleSelect}>
					{toMoneyFormat(livePayment)}
				</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.BONUS) && (
				<td className="text_right" onClick={handleSelect}>
					{bonus != null && bonus > 0 ? `${toMoneyFormat(bonus * 100)}%` : '-'}
				</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.GEO_ENTITY_NAME) && (
				<td onClick={handleSelect} title={geoEntityName || ''}>
					{geoEntityName || ''}
				</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.RISK) && (
				<td className="text_right" onClick={handleSelect}>
					{riskValue ? (
						<div className="status_item" style={{ justifyContent: 'flex-end' }}>
							<span className="status_label">{`${riskValue}`}</span>
						</div>
					) : manualAuthorization ? (
						<i className="mdi mdi-alpha-m-circle orange_text tooltip_right ">
							<span className="tooltip_content font_400 orange">{t('reports.tickets.manualAuth')}</span>
						</i>
					) : authStatus !== null ? (
						<i className="mdi mdi-alpha-f-circle redish_text tooltip_right ">
							<span className="tooltip_content font_400 redish">{t('reports.tickets.forceAuth')}</span>
						</i>
					) : null}
				</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.RISK_TAG) && (riskTag ? <RiskTagCell idTag={riskTag} /> : <td></td>)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.RISK_GROUP_ID) &&
				(userInfo?.id_risk_group ? <RiskGroupCell idRiskGroup={userInfo.id_risk_group} /> : <td></td>)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.USER) && (
				<td onClick={handleSelect}>{userInfo?.email || operator || ''}</td>
			)}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.BOOKMAKER) && <td onClick={handleSelect}>{riskManager?.name}</td>}
			{isColumnVisible(REPORTS_TICKETS_COLUMNS.SIMILARITY) && (
				<td onClick={handleSelect} className="text_center">
					{similarityValue ? similarityValue.toFixed(2) : ' - '}
				</td>
			)}
		</tr>
	);
};
