import { Limit } from '../../pages/parameters/limits/types';
import { axiosInstance } from './../config';

export const limits = {
	getLimits(offset: number = 0, limit: number = -1): Promise<Limit[]> {
		return axiosInstance
			.get(`administration/limits/?offset=${offset}&limit=${limit}`)
			.then((response) => response.data.limits);
	},

	createLimit(limit: Limit): Promise<Limit> {
		return axiosInstance.post('administration/limits/', limit).then((response) => response.data);
	},

	updateLimit(limit: Limit): Promise<Limit> {
		return axiosInstance.put(`administration/limits/${limit.id}/`, limit).then((response) => response.data);
	},
};
