import React, { useEffect, useState } from 'react';
import api from '../../../../api';
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../../../hooks/useNotifications';
import { useOnlineUser } from '../../context/OnlineUserContext';
import { LimitsData, deleteLimitType } from '../limits/types';
import Button from '../../../../components/general/Button';
import { SimpleLoader } from '../../../../components/general/Loader';
import LimitRow from './LimitRow';
import TableHeader from './TableHeader';
import Modal from '../../../../components/Modals';
import Delete from '../../../../utils/svg/Delete';
import LimitsModal from './LimitsModal';

export default function Limits() {
	const {
		onlineUser: { uid, currency, code },
	} = useOnlineUser();

	const { success, error, warn } = useNotify();
	const { t } = useTranslation();

	const [limits, setLimits] = useState<LimitsData[]>([]);
	const [isDisabled, setIsDisabled] = useState(false);

	const [limitForDelete, setLimitForDelete] = useState({} as deleteLimitType);

	const [modalIsOpen, setModalIsOpen] = useState(false);

	const [selectedLimit, setSelectedLimit] = useState<LimitsData | null>(null);

	const [loaded, setLoaded] = useState(false);
	const [closedModal, setModalIsClosed] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				const data = await api.users.getLimitsData(code);
				setLimits(data);
			} catch {
				error(t('usersAdministrations.loadingLimitsError'), { autoClose: 5000 });
			} finally {
				setLoaded(true);
			}
		})();
	}, [closedModal]);

	const editLimitData = ({ periodInDays, amount, type }: LimitsData) => {
		setSelectedLimit({ periodInDays, amount, type } as LimitsData);
	};

	const deleteLimitData = ({ code, periodInDays, type }: deleteLimitType) => {
		setModalIsOpen(true);
		setLimitForDelete({ code, periodInDays, type });
	};

	const deleteLimit = async (limitForDelete: deleteLimitType) => {
		try {
			setIsDisabled(true);

			await api.users.deleteLimitsData(limitForDelete.code, limitForDelete.periodInDays, limitForDelete.type);
			success(t('toast.success.limitDeleted'));
			setLimits(limits?.filter((limit) => limit.periodInDays !== limitForDelete.periodInDays));
		} catch {
			error('toast.error.defaultText');
		} finally {
			setIsDisabled(false);
			setModalIsOpen(false);
		}
	};

	const closeLimitModal = () => {
		setSelectedLimit(null);
	};

	return (
		<SimpleLoader loaded={loaded}>
			<h3 className="title col-4 margin_left_20 margin_bottom_20 margin_top_20">
				{t('usersAdministrations.typeDeposite')}
			</h3>

			{limits.length > 0 ? (
				<table className="admin_table sticky_header margin_left_20" style={{ width: '750px' }}>
					<TableHeader />
					<tbody className="t_body">
						{limits?.map((limit, index) => (
							<LimitRow
								key={index}
								limit={limit}
								code={code}
								currency={currency}
								deleteLimit={deleteLimitData}
								editLimit={editLimitData}
							/>
						))}
					</tbody>
				</table>
			) : (
				<h4 className="title col-4 margin_left_20 margin_bottom_20 margin_top_20">
					{t('usersAdministrations.noLimits')}
				</h4>
			)}
			{selectedLimit ? (
				<LimitsModal
					code={code}
					close={closeLimitModal}
					isDisabled={isDisabled}
					setIsDisabled={setIsDisabled}
					selectedLimit={selectedLimit}
					setSelectedLimit={setSelectedLimit}
					setModalIsClosed={setModalIsClosed}
					closedModal={closedModal}
				/>
			) : null}

			{/* Delete modal */}
			<Modal
				opened={modalIsOpen}
				size={'sm_320'}
				align={'start'}
				direction={'column'}
				cancelAction={() => setModalIsOpen(false)}
				body={
					<>
						<div className="context_icon margin_top_40">
							<Delete />
						</div>
						<h2 className="modal_message_title">{t('general.areSure')}</h2>
					</>
				}
				footer={
					<>
						<button className="btn md round outline" onClick={() => setModalIsOpen(false)}>
							{t('general.cancel')}
						</button>
						<button
							className={`btn md round red ${isDisabled ? 'disabled' : ''}`}
							onClick={() => deleteLimit(limitForDelete)}
						>
							{t('general.delete')}
						</button>
					</>
				}
			/>
		</SimpleLoader>
	);
}
