import React, { FC } from 'react';
import { isEmpty } from 'lodash';

interface Props {
	providerData?: [];
	otherProviders: never[] | undefined;
}

export const ProviderCell: FC<Props> = ({ providerData, otherProviders }) => {
	const redish = 'mdi mdi-close-circle-outline activated provider_flag--icon red';
	const green = 'mdi mdi-checkbox-marked-circle-outline activated provider_flag--icon';

	return (
		<div className="sport_icon">
			<td className="text_center">
				<div className="checkbox_custom chek_only tooltip_left text_center align_center flex_justify_end">
					<span>
						<div className="provider_flag ">
							<div>
								<i className={`${!isEmpty(otherProviders) ? green : redish}`}></i>
							</div>
						</div>
					</span>
					{providerData?.length ? (
						<span className="tooltip_content" style={{ height: 'auto' }}>
							<div className="wrapper_flags">
								{providerData?.map((item) => {
									return (
										<div key={item} className="provider">
											<div className="provider_header">
												<h3>{item}</h3>
											</div>
										</div>
									);
								})}
							</div>
						</span>
					) : null}
				</div>
			</td>
		</div>
	);
};
