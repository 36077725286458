import { id } from '../../types/general';
import { axiosInstance } from '../config';

export const lms = {
	getStreams(providerKey: id, sportId: string, dtFrom: Date, dtTo: Date) {
		return axiosInstance
			.get(`/streams/items/${providerKey}`, {
				params: {
					idSport: sportId,
					dtFrom: dtFrom,
					dtTo: dtTo,
				},
			})
			.then(({ data }) => data);
	},
	autoimport(providerKey: id, idSport: string, dtFrom: string, dtTo: string) {
		return axiosInstance.post(`/streams/autoimport/${providerKey}`, {
            idSport: idSport,
            dtFrom: dtFrom,
            dtTo: dtTo
        });
	},
	map(providerKey: id, idInternal: string, idExternal: string) {
		return axiosInstance.post(`/streams/map/${providerKey}`, {
			idInternal: idInternal,
			idExternal: idExternal,
		});
	},
	unmap(idInternal: string) {
		return axiosInstance.post(`/streams/unmap`, {
			idInternal: idInternal,
		});
	},
};
