import React from 'react';
import { deleteLimitType, LimitsData } from '../limits/types';
import { toMoneyFormat } from '../../../../utils/toFixed';
import { formatDate } from '../../../../utils/getDate';

interface Props {
	limit: LimitsData;
	currency: string;
	code: string;
	deleteLimit: ({ code, periodInDays, type }: deleteLimitType) => void;
	editLimit: ({ periodInDays, amount, type }: LimitsData) => void;
}

const LimitRow = ({ limit, currency, code, deleteLimit, editLimit }: Props) => {
	const { amount, periodInDays, dtCreated, type } = limit;

	return (
		<tr>
			<td width={100}>{periodInDays}</td>

			<td width={150}>{toMoneyFormat(amount)}</td>

			<td width={100}>{currency}</td>

			<td width={150} className="padding_left_10">
				<span className="date">{formatDate(dtCreated, 'date.month.year')}. </span>
			</td>
			<td width={150} className="padding_left_10">
				<span className="time font_700">{formatDate(dtCreated, 'hours:minutes:seconds')}</span>
			</td>

			<td className="actions_cell">
				<div className="td_actions">
					<div className="tbl_btn green">
						<i
							onClick={() => editLimit({ periodInDays, amount, type } as LimitsData)}
							className="mdi mdi-pencil"
							data-testid="edit-icon"
						/>
					</div>

					<div className="tbl_btn red">
						<i
							className="mdi mdi-close md tooltip_left"
							onClick={() => {
								deleteLimit({ code, periodInDays, type });
							}}
						></i>
					</div>
				</div>
			</td>
		</tr>
	);
};

export default LimitRow;
