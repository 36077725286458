import {
	PromotionReportData,
	TicketsPromotionData,
	UsedPromotionsData,
} from '../../pages/reports/promotions-report/types';
import { axiosInstance } from '../config';

export interface PromotionsParams {
	dtFrom: Date | null;
	dtUntil: Date | null;
	timezone: string;
	promotionType: string;
	orderBy: string;
	groupLandbase: boolean;
	showBy: string;
}

export interface UsedPromotions {
	idGeoEntity: string;
	geoEntityType: string;
	accountType: string;
	dtFrom: Date | null;
	dtUntil: Date | null;
	skip: number;
	limit: number;
	timezone: string;
	promotionType: string;
}

export const promotionsReport = {
	getPromotionsReport(params: PromotionsParams): Promise<PromotionReportData> {
		return axiosInstance.post('/reporting/promotions', params).then(({ data }) => data);
	},

	getUsedPromotions(params: UsedPromotions): Promise<UsedPromotionsData> {
		return axiosInstance.post('/reporting/promotions_used', params).then(({ data }) => data);
	},

	getPromotionsTickets(params: UsedPromotions): Promise<TicketsPromotionData> {
		return axiosInstance.post('/reporting/promotions_tickets', params).then(({ data }) => data);
	},
};
