import React, { FC, CSSProperties } from 'react';
import { Item } from '../../types';
import { usePairing } from '../../_framework';
import { formatDate } from '../../../../utils/getDate';
import { CopyWrapper } from '../../../../components/general/CopyWrapper';

interface Props {
	item: Item;
	style: CSSProperties;
	index: number;
	setIndex: (i: number) => void;
}

// Local props for the component
export const ProviderItem: FC<Props> = ({ item, style, index, setIndex }) => {
	const { id, utcScheduled, sport, tournament, category, competitors } = item;
	const { id: sportId, name: sportName } = sport.mappedTo;
	const { setProviderItem, localItem, pairProvided, providerFuzzy, setProviderFuzzy } = usePairing();
	const [date, time] = formatDate(utcScheduled, 'date.month', 'hours:minutes');
	const [{ name: home }, { name: away }] = competitors || [{}, {}];

	const selectedClass = id === providerFuzzy.id ? ' selected' : ' ';
	const itemClass = `list_item_content listitem_target ${selectedClass}`;

	const handleSelect = () => {
		if (id === providerFuzzy.id) return setProviderFuzzy({} as Item);
		setProviderFuzzy(item);
		setIndex(index);
	};

	const handleCheckbox = () => {
		if (localItem.id) {
			if (sport.mappedTo.id !== localItem.sport.id) return alert('Select event from correct sport');
			else pairProvided(localItem.id, id);
		} else setProviderItem(item);

		if (!Object.keys(providerFuzzy).length && !Object.keys(localItem).length) {
			setIndex(index);
		}
	};

	const tournamentName = `${tournament?.name || ''} (${category?.name || ''}) `;

	return (
		<div className={`list_item ${selectedClass}`} style={style}>
			<div className="checkbox_custom chek_only" onClick={handleCheckbox}>
				<input type="checkbox" checked={!!Object.keys(localItem).length} onChange={() => {}} />
				<i className="mdi mdi-chevron-left unchecked"></i>
				<i className="mdi mdi-radiobox-blank checked"></i>
			</div>

			<div className="event_start" onClick={handleSelect}>
				<div className="event_time">{time}</div>
				<span className="divider"></span>
				<div className="event_date text_left">{date}</div>
			</div>

			{away ? (
				<div className={`${itemClass}`} onClick={handleSelect}>
					<div className="home_team font_14px" title={home}>
						{home}
					</div>
					<div className="vs">vs</div>
					<div className="away_team font_14px" title={away}>
						{away}
					</div>
				</div>
			) : (
				<div className={`${itemClass}`} onClick={handleSelect}>
					<div className="home_team font_14px" title={home}>
						{home}
					</div>
				</div>
			)}

			<CopyWrapper value={id} />

			<div className="sport_icon" onClick={handleSelect}>
				<div className="svg_icon tooltip_right">
					<svg className="e_ico">
						<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${sportId}`}></use>
					</svg>
					<span className="tooltip_content">{sportName}</span>
				</div>
			</div>

			<div className="tournament font_14px" title={tournamentName} onClick={handleSelect}>
				{tournamentName}
			</div>
		</div>
	);
};
