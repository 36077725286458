import { Player, PlayerUpdatePayload } from '../../pages/administration/players/types';
import { axiosInstance } from '../config';

interface PlayersParams {
	sportId?: string;
	q?: string;
	limit?: number;
	skip?: number;
}

export const players = {
	getPlayers(params: PlayersParams): Promise<{ total: number; players: Player[] }> {
		return axiosInstance
			.get(`administration/competitors/players/`, {
				params,
			})
			.then((res) => res.data);
	},

	getPlayersForCompetitor(competitorId: string) {
		return axiosInstance.get(`/administration/competitors/${competitorId}/players`).then((res) => res.data);
	},

	createPlayer(player: PlayerUpdatePayload): Promise<Player> {
		return axiosInstance.post(`administration/competitors/players/`, player).then((response) => response.data);
	},

	updatePlayer(id: string, player: PlayerUpdatePayload): Promise<Player> {
		return axiosInstance.put(`administration/competitors/players/${id}`, player).then((response) => response.data);
	},
};
