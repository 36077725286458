import React, { useState, useEffect, useMemo, useRef, ChangeEvent, FC, ReactNode } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { useTranslation } from 'react-i18next';
import { Item, ItemExtended } from '../../types/general';

interface Props {
	title: string;
	options: Item[] | ItemExtended[];
	marginRight?: string;
	withSearch?: boolean;
	direction?: 'left_0' | 'right_0' | 'center_0' | 'down' | undefined;
	selectedOptions: string[];
	selectOption: (options: string[]) => void;
	withAll?: boolean;
	className?: string;
	isDIsabled?: boolean;
	width?: string;
	isMobileView?: boolean;
	longList?: boolean;
	live?: boolean;
	children?: ReactNode;
}

export default ({
	title,
	options,
	selectedOptions,
	selectOption,
	withSearch,
	marginRight = '0',
	direction = 'left_0',
	withAll = true,
	className = '',
	isDIsabled = false,
	width,
	isMobileView,
	longList = false,
	live,
	children,
}: Props) => {
	const { t } = useTranslation();
	const [searchQuery, setSearchQuery] = useState('');
	const inputElement = useRef<HTMLInputElement>(null);

	// Track state of dropdown
	const [open, setOpen] = useState(false);

	let dropdownClass = open ? ` drop_list ${direction} toggle ` : `drop_list ${direction} `;
	dropdownClass = withSearch ? `${dropdownClass} with_src` : dropdownClass;

	let dropdownClassMobile = open ? 'drop_list toggle' : 'drop_list';

	// Create dropdownRef
	const dropdownRef = useRef<HTMLDivElement>(null);

	// Call useOnClickOutside custom hook
	useOnClickOutside(dropdownRef, () => setOpen(false));

	const handleOpen = () => {
		setOpen(!open);
		setTimeout(() => {
			inputElement.current?.focus();
		}, 200);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
		if (id === 'all') {
			// Select/deselect all options
			if (e.target.checked) {
				selectOption(['all', ...options.map((option) => option.id)]);
			} else {
				selectOption([]);
			}
		} else {
			if (!e.target.checked) {
				// Deselect option and remove checked from all if exists
				selectOption(selectedOptions.filter((item) => item !== id && item !== 'all'));
			} else {
				// Select option and select All if we clicked last unchecked option, otherwise just select option
				if (selectedOptions.length === options.length - 1) {
					selectOption([...selectedOptions, id, 'all']);
				} else {
					selectOption([...selectedOptions, id]);
				}
			}
		}
	};

	let selectedOddArr: Item[] = [];

	for (const selectedOption of selectedOptions) {
		let filteredSelectedOdd: Item[] = options.filter((option) => option.id == selectedOption);

		selectedOddArr.push(...filteredSelectedOdd);
	}

	const filteredOptions: ItemExtended[] = useMemo(
		() =>
			longList && searchQuery.length < 3
				? selectedOddArr || []
				: options.filter((option) => option.name.toLowerCase().includes(searchQuery.toLowerCase())),
		[options, searchQuery]
	);

	return (
		<div
			ref={dropdownRef}
			className={`select_region drop_menu down margin_right_${marginRight} ${className} ${
				isDIsabled ? 'disabled' : ''
			}`}
		>
			<span className="drop_label" onClick={handleOpen}>
				{title}
				<i className=" mdi mdi-menu-down"></i>
			</span>

			<div
				className={`${isMobileView ? dropdownClassMobile : dropdownClass} padding_bottom_120_mobile`}
				style={{ width: `${isMobileView ? '300px' : width} ` }}
			>
				<>
					{withSearch && (
						<div className="drop_list_item src">
							<input
								type="text"
								placeholder={t('general.search')}
								value={searchQuery}
								ref={inputElement}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</div>
					)}
					{children && (
						<div key={`${title}:children`} className={`drop_list_item checkbox_custom`}>
							{children}
						</div>
					)}
					{!searchQuery && filteredOptions.length > 1 && withAll ? (
						<div className="drop_list_item checkbox_custom">
							<input
								type="checkbox"
								id={`${title}:all`}
								checked={selectedOptions?.includes('all')}
								onChange={(e) => {
									handleChange(e, 'all');
								}}
							/>
							<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
							<i className="mdi mdi-checkbox-marked checked"></i>
							<label htmlFor={`${title}:all`} className="right ">
								{t('general.all')}
							</label>
						</div>
					) : null}
					{filteredOptions.map(({ id, name, live }, index) => (
						<div key={`${id}:${index}`} className={`drop_list_item checkbox_custom`}>
							<input
								type="checkbox"
								id={id}
								checked={selectedOptions?.includes(id)}
								onChange={(e) => {
									handleChange(e, id);
								}}
							/>
							<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
							<i className="mdi mdi-checkbox-marked checked"></i>
							<label htmlFor={id} className="right ">
								{name}
							</label>
							{live ? <span className="padding_right_10">LIVE</span> : ''}
						</div>
					))}
				</>
			</div>
		</div>
	);
};
