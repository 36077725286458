import React, { useState, useEffect, useMemo, useRef, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useData } from '../../context/DataProvider';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { haveSameContents } from '../../utils/haveSameContents';
import { EventStatusAdditionalData, getEventStatusAdditionalData } from '../../pages/administration/Events/helpers';

interface Props {
	title: string;
	marginRight: string;
	withSearch?: boolean;
	selectedOptions: string[];
	selectOption: (options: string[]) => void;
	left?: 'left_0' | undefined;
	isDisabled?: boolean;
}

export const EventStatusFilterDropdown = ({
	title,
	selectedOptions,
	selectOption,
	withSearch,
	marginRight = '0',
	left,
	isDisabled,
}: Props) => {
	const { t } = useTranslation();
	const { eventStatuses } = useData();
	const [options, setOptions] = useState<EventStatusAdditionalData[]>([]);
	const [tempSelected, setTempSelected] = useState(selectedOptions);

	useEffect(() => {
		setTempSelected(selectedOptions);
	}, [selectedOptions]);

	const [searchQuery, setSearchQuery] = useState('');
	const inputElement = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const statuses = eventStatuses.map((status) => getEventStatusAdditionalData(status));
		setOptions([...statuses].sort((a, b) => a.order - b.order));
	}, [eventStatuses]);

	// Track state of dropdown
	const [open, setOpen] = useState(false);

	let dropdownClass = open ? ' drop_list  toggle ' : ' drop_list';
	dropdownClass = withSearch ? `${dropdownClass} ${left} with_src` : `${dropdownClass} ${left}`;

	// Create dropdownRef
	const dropdownRef = useRef<HTMLDivElement>(null);

	// Call useOnClickOutside custom hook
	useOnClickOutside(dropdownRef, () => {
		setTempSelected(selectedOptions);
		setOpen(false);
		setSearchQuery('');
	});

	const handleOpen = () => {
		setOpen(!open);
		setTimeout(() => {
			inputElement.current?.focus();
		}, 200);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
		if (id === 'all') {
			if (e.target.checked) setTempSelected(['all', ...options.map((option) => option.id)]);
			else setTempSelected([]);
		} else {
			if (!e.target.checked) setTempSelected(tempSelected.filter((item) => item !== id && item !== 'all'));
			else {
				// Select option and select All if we clicked last unchecked option, otherwise just select option
				if (tempSelected.length === options.length - 1) setTempSelected([...tempSelected, id, 'all']);
				else setTempSelected([...tempSelected, id]);
			}
		}
	};

	const filteredOptions = useMemo(
		() => options.filter((option) => option.name.toLowerCase().includes(searchQuery.toLowerCase())),
		[options, searchQuery]
	);

	const filter = () => {
		if (!haveSameContents(selectedOptions, tempSelected)) selectOption(tempSelected);
		setOpen(false);
		setSearchQuery('');
	};

	return (
		<div
			ref={dropdownRef}
			className={`select_region drop_menu down margin_right_${marginRight} ${isDisabled ? 'disabled' : ''}`}
		>
			<span className="drop_label" onClick={handleOpen}>
				{title}
				<i className=" mdi mdi-menu-down"></i>
			</span>

			<div className={dropdownClass}>
				<>
					{withSearch && (
						<div className="drop_list_item src">
							<input
								type="text"
								placeholder={t('general.search')}
								value={searchQuery}
								ref={inputElement}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</div>
					)}
					<div className="drop_list_item actions checkbox_custom z_10">
						<input
							type="checkbox"
							id={`${title}:all`}
							checked={tempSelected.includes('all')}
							onChange={(e) => {
								handleChange(e, 'all');
							}}
						/>
						<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
						<i className="mdi mdi-checkbox-marked checked"></i>
						<label htmlFor={`${title}:all`} className="right ">
							{t('general.all')}
						</label>
						<button className="btn blue margin_left_auto" onClick={filter}>
							{t('general.filter')}
						</button>
					</div>

					{filteredOptions.map(({ id, name, color }) => (
						<div key={id} className={`drop_list_item checkbox_custom ${tempSelected.includes(id) ? color : ''}`}>
							<input
								type="checkbox"
								id={id}
								checked={tempSelected.includes(id)}
								onChange={(e) => {
									handleChange(e, id);
								}}
							/>
							<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
							<i className={`mdi mdi-checkbox-marked checked ${color}_text`}></i>
							<label htmlFor={id} className="right ">
								{name}
							</label>
						</div>
					))}
				</>
			</div>
		</div>
	);
};
