import React, { FC } from 'react';
import Dropdown from '../../../../components/general/Dropdown';

interface SoundProps {
	selectedSound: string;
	selectSound: (id: string) => void;
}

export const Sounds: FC<SoundProps> = ({ selectedSound, selectSound }) => (
	<Dropdown
		fixedTitle={'Pick sound'}
		icon={'mdi mdi-menu-down'}
		listClass="left_0"
		selectedItem={selectedSound}
		margins={['0', '0', '0', '0']}
		selectItem={selectSound}
		items={[
			{ id: 'mute', name: 'mute' },
			{ id: 'believe', name: 'believe' },
			{ id: 'beyond', name: 'beyond' },
			{ id: 'clearly', name: 'clearly' },
			{ id: 'deduction', name: 'deduction' },
			{ id: 'filling', name: 'filling' },
			{ id: 'martianGun', name: 'martianGun' },
			{ id: 'percussion', name: 'percussion' },
			{ id: 'powerUp', name: 'powerUp' },
			{ id: 'slow', name: 'slow' },
			{ id: 'think', name: 'think' },
		]}
	/>
);

interface DelayProps {
	delay: number;
	setDelay: (id: number) => void;
}

export const Delay: FC<DelayProps> = ({ delay, setDelay }) => (
	<Dropdown
		fixedTitle={'Set delay'}
		icon={'mdi mdi-menu-down'}
		listClass="left_0"
		selectedItem={delay.toString()}
		margins={['0', '0', '0', '0']}
		selectItem={(val) => setDelay(parseInt(val))}
		items={[
			{ id: '1', name: '1' },
			{ id: '3', name: '3' },
			{ id: '5', name: '5' },
			{ id: '7', name: '7' },
			{ id: '9', name: '9' },
			{ id: '12', name: '12' },
			{ id: '15', name: '15' },
			{ id: '18', name: '18' },
			{ id: '20', name: '20' },
			{ id: '30', name: '30' },
			{ id: '60', name: '60' },
		]}
	/>
);

interface Props {
	perPage: number;
	setPerPage: (id: number) => void;
}

export const FooterDropdown: FC<Props> = ({ perPage, setPerPage }) => (
	<div className="pag_item">
		<Dropdown
			direction="up"
			listClass="left_0"
			selectedItem={perPage.toString()}
			margins={['0', '0', '0', '0']}
			selectItem={(val) => setPerPage(parseInt(val))}
			items={[
				{ id: '15', name: '15' },
				{ id: '20', name: '20' },
				{ id: '50', name: '50' },
				{ id: '100', name: '100' },
				{ id: '150', name: '150' },
				{ id: '200', name: '200' },
			]}
		/>
	</div>
);
