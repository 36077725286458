import React, { useState, useRef, useEffect, FC, useMemo, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { PlanItem } from '../../../OffersPlan/types';

interface Props {
	selectedPlan: PlanItem | null;
	selectPlan: (id: PlanItem | null) => void;
	setPlansLoaded?: Dispatch<SetStateAction<boolean>>;
	deselect?: boolean;
	includeTournaments: boolean;
	isDisabled?: boolean;
}

export const ResultsPlanDropdown: FC<Props> = ({
	selectedPlan,
	selectPlan,
	deselect,
	includeTournaments,
	setPlansLoaded,
	isDisabled,
}) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [plans, setPlans] = useState<PlanItem[]>([]);

	useEffect(() => {
		(async function getOfferPlans() {
			try {
				const data = (await api.plans.getPlan('unset', includeTournaments)) || [];
				const filterdData = data.filter(
					(item: PlanItem) => item.status !== 'in-creation' && item.status !== 'locked'
				);

				const active = filterdData.find((item: PlanItem) => item.status === 'active');

				if (active) {
					setPlans([active, ...filterdData.filter((item) => item.status !== 'active')].slice(0, 20));
					selectPlan(active);
				} else if (filterdData.length) {
					setPlans(filterdData.slice(0, 20));
					selectPlan(filterdData[0]);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setPlansLoaded && setPlansLoaded(true);
			}
		})();
	}, []);

	const [name, date] = useMemo(() => {
		if (!selectedPlan) return ['', t('offersPlan.noPlan')];
		const { name, dateStart, dateEnd } = selectedPlan;
		const [_, startMonth, startDay] = dateStart.split('-');
		const [__, endMonth, endDay] = dateEnd.split('-');
		return [name, `${`${startDay}.${startMonth}`} - ${`${endDay}.${endMonth}`}`];
	}, [selectedPlan]);

	const handleChange = (item: PlanItem) => {
		selectPlan(item);
		setOpen(false);
	};

	useEffect(() => {
		setTimeout(() => {
			open &&
				selectedPlan &&
				document.getElementById(selectedPlan.id)?.scrollIntoView({ behavior: 'auto', block: 'center' });
		}, 200);
	}, [open]);

	const dropdownClass = open ? `select_region drop_menu down toggle` : `select_region drop_menu down`;

	// Create dropdownRef
	const dropdownRef = useRef<HTMLDivElement>(null);

	// Call useOnClickOutside custom hook
	useOnClickOutside(dropdownRef, () => setOpen(false));
	return (
		<div ref={dropdownRef} className={`${dropdownClass} ${isDisabled ? 'disabled' : ''}`}>
			<span className="drop_label" onClick={() => setOpen(!open)}>
				<strong className="padding_right_5">{name}</strong>
				<span>{date}</span>

				<i className=" mdi mdi-menu-down " />
			</span>
			<div className="drop_list left_0 z_10">
				{deselect ? (
					<div
						className={`drop_list_item font_400`}
						onClick={() => {
							selectPlan(null);
							setOpen(false);
						}}
					>
						<strong className="padding_right_5">{t('general.clearSelected')}</strong>
					</div>
				) : null}

				{plans.map((item) => {
					const { id, name, dateStart, dateEnd } = item;
					const [_, startMonth, startDay] = dateStart.split('-');
					const [__, endMonth, endDay] = dateEnd.split('-');

					return (
						<div
							key={id}
							id={id}
							className={`drop_list_item font_400 ${selectedPlan?.id === id ? 'selected' : ''}`}
							onClick={() => {
								handleChange(item);
							}}
						>
							<strong className="padding_right_5">{name}</strong>
							<span>{`${`${startDay}.${startMonth}`} - ${`${endDay}.${endMonth}`}`}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};
