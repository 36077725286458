import { axiosInstance, Response } from './../config';
import { LocalisationMap, TranslationItem } from '../../pages/administration/Translations/types';

export const translations = {
	getTranslations({
		refLang = '',
		translationLang = '',
		orderBy = '',
		q = '',
		desc = false,
		empty = false,
		offset = 0,
		limit = 10,
	}: {
		refLang?: string;
		translationLang?: string;
		orderBy?: string;
		q?: string;
		desc?: boolean;
		empty?: boolean;
		offset?: number;
		limit?: number;
	}): Response {
		return axiosInstance
			.get(
				`/localisation/reflang/${refLang}/translate/${translationLang}?&q=${q}&offset=${offset}&limit=${limit}&orderBy=${orderBy}&desc=${desc}&empty=${empty}`
			)
			.then((res) => res.data);
	},
	editTranslation(key: string, translationItem: TranslationItem) {
		return axiosInstance
			.put(`/localisation/lang/${key}`, {
				...translationItem,
			})
			.then((res) => {
				return res.data;
			});
	},

	getLocalisationMap(): Promise<LocalisationMap> {
		return axiosInstance.get(`localisation/languages`).then((response) => response.data);
	},
};
