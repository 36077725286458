import { useEffect, useCallback, useReducer } from 'react';

import { useTranslation } from 'react-i18next';
import { useMounted } from './useMounted';
import { useNotify } from './useNotifications';

interface State<T> {
	loading: boolean;
	error: Error | null;
	data: T;
}

type Action<T> =
	| { type: 'FETCHING' }
	| { type: 'FETCHED'; payload: T }
	| { type: 'FETCH_ERROR'; payload: Error }
	| { type: 'SET_STATE'; payload: T };

const createDataReducer =
	<T,>() =>
	(state: State<T>, action: Action<T>): State<T> => {
		switch (action.type) {
			case 'FETCHING':
				return { ...state, loading: true };
			case 'FETCHED':
				return { ...state, loading: false, data: action.payload };
			case 'SET_STATE':
				return { ...state, loading: false, data: action.payload };
			case 'FETCH_ERROR':
				return { ...state, loading: false, error: action.payload };
			default:
				return state;
		}
	};

interface Config<T> {
	dependencies?: any[];
	initialData: T;
	apiFunction: () => Promise<T>;
}

export function useAxios<T>({ apiFunction, dependencies = [], initialData }: Config<T>) {
	const reducer = createDataReducer<T>();
	const { t } = useTranslation();
	const { error } = useNotify();

	const [state, dispatch] = useReducer(reducer, {
		data: initialData,
		loading: false,
		error: null,
	});

	const [mounted] = useMounted();

	const fetchData = useCallback(async () => {
		dispatch({ type: 'FETCHING' });
		try {
			const data = await apiFunction();
			if (!mounted) return;
			dispatch({ type: 'FETCHED', payload: data });
		} catch (err: any) {
			if (!mounted) return;
			dispatch({ type: 'FETCH_ERROR', payload: err });
			const errorText =
				err?.response?.data.detail?.[0]?.msg || err?.response?.data?.detail || t('toast.error.defaultText');
			error(errorText);
		}
	}, [apiFunction, dispatch, mounted]);

	const setState = (data: T | ((oldData: T) => T)) => {
		//@ts-ignore
		const update = typeof data === 'function' ? data(state.data) : data;
		dispatch({ type: 'SET_STATE', payload: update });
	};

	useEffect(() => {
		fetchData();
	}, dependencies);

	return { ...state, setState };
}
