import { IParams, SeriesVoucher, SeriesVouchersData, SingleSeriesVoucher } from '../../pages/internet/vouchers/types';
import { axiosInstance } from '../config';
import { fileNameFromHeaders } from '../../utils/getExportFileName';

export const vouchers = {
	createSeriesOfVouchers(voucher: SeriesVoucher): Promise<any> {
		return axiosInstance.post(`/vouchers/`, voucher).then((response) => response.data);
	},
	getSeriesOfVouchers(params: IParams): Promise<SeriesVouchersData> {
		return axiosInstance.post(`/vouchers/filter`, params).then((response) => response.data);
	},
	getSingleSeries(params: any, id_series: string): Promise<SingleSeriesVoucher> {
		return axiosInstance.post(`/vouchers/${id_series}`, params).then((response) => response.data);
	},
	cancelSingleSeries(id_series: string): Promise<SingleSeriesVoucher> {
		return axiosInstance.get(`/vouchers/${id_series}/cancel`).then((response) => response.data);
	},
	exportSingleSeries(id_series: string): Promise<any> {
		return axiosInstance.get(`/vouchers/${id_series}/export`, { responseType: 'blob' }).then(({ headers, data }) => {
			return [fileNameFromHeaders(headers, `vouchers-id-${id_series}`), data];
		});
	},
	cancelVoucher(id_series: string, id_voucher: string): Promise<any> {
		return axiosInstance
			.get(`/vouchers/${id_series}/vouchers/${id_voucher}/cancel`)
			.then((response) => response.data);
	},
};
