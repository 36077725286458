import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeyboardListener } from '../../../../../hooks/useKeyboardListener';
import Delete from '../../../../../utils/svg/Delete';
import NotificationModal from '../../../../general/NotificationModal';
import { useTicket } from '../../context/TicketContext';

export const HeaderActions: FC = () => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { modified, acceptTicketChanges, dismissTicketChanges, close, styles, screen, selectedOdd } = useTicket();

	const save = () => {
		acceptTicketChanges();
		setOpen(false);
	};

	useKeyboardListener(!selectedOdd ? 'Escape' : '', () => close());
	return (
		<>
			{modified && screen === 'tickets' && !styles ? (
				<>
					<button
						className="btn green transparent close tooltip_left"
						style={{ right: '65px' }}
						onClick={() => setOpen(true)}
					>
						<span className="tooltip_content green">{t('general.accept')}</span>
						<i className="mdi mdi-check-all"></i>
					</button>
					<button className="btn red transparent close tooltip_left" onClick={dismissTicketChanges}>
						<span className="tooltip_content redish">{t('general.dismiss')}</span>
						<i className="mdi mdi-close"></i>
					</button>
				</>
			) : (
				<button
					className="btn transparent close tooltip_left closeBtnMobile"
					style={{ zIndex: '10000' }}
					onClick={close}
				>
					<i className="mdi mdi-close"></i>
					<span className="tooltip_content ">{t('general.close')}</span>
				</button>
			)}
			{open ? (
				<NotificationModal
					opened={true}
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={() => setOpen(false)}
					confirmAction={save}
					zIndex={'over_3'}
				>
					<div className="context_icon margin_top_40">
						<Delete />
					</div>
					<h2 className="modal_message capitalize text_center">{t('general.areSure')}</h2>
					<p className="text_center margin_top_10">{t('reports.tickets.modifyTicketMsg')}</p>
				</NotificationModal>
			) : null}
		</>
	);
};
