import React from 'react';
import { useTranslation } from 'react-i18next';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { toMoneyFormat } from '../../../utils/toFixed';
import { useOnlineUser } from '../context/OnlineUserContext';
import { tabs } from '../utils';
import { ForceUserAuth } from './ForceUserAuth';
import { RiskTagPreview } from './RiskTagPreview';

export const Header = () => {
	const { t } = useTranslation();

	const {
		onlineUser: {
			code,
			email,
			freeBetBalance,
			internetBalance,
			reservedAmount,
			currency,
			uid,
			riskGroupName,
			idRiskGroup,
		},
		selectedTab,
		selectTab,
	} = useOnlineUser();

	useDocumentTitle(email);

	return (
		<div className="main_head w_1800">
			<div className="title_section padding_left_10 padding_right_10">
				<h2 className="subtitle">
					<small>
						User ID: <strong className="font_800">{code}</strong>
					</small>
				</h2>
				<h2 className="subtitle">{email}</h2>
			</div>

			<div className="action_tools_left">
				<div className="wrapper padding_right_0 padding_left_10">
					{tabs &&
						tabs.map((tab, index) => (
							<button
								id={`btn_${tab}`}
								key={index}
								className={`btn ${selectedTab === tab ? 'blue' : 'outline'}`}
								onClick={() => {
									selectTab(tab);
								}}
							>
								{t(`usersAdministrations.${tab}`)}
							</button>
						))}

					<div className="user_funds border_right margin_left_auto padding_left_10 padding_right_10">
						<div className="label uppercase">{t('usersAdministrations.internet')}:</div>
						<div className="info">
							{toMoneyFormat(internetBalance, '0.00')}
							<div className="currency">{currency}</div>
						</div>
					</div>

					<div className="user_funds border_right  padding_left_10 padding_right_10">
						<div className="label uppercase">{t('usersAdministrations.freebet')}:</div>
						<div className="info">
							{toMoneyFormat(freeBetBalance, '0.00')}
							<div className="currency">{currency}</div>
						</div>
					</div>

					<div className="user_funds border_right margin_right_10  padding_left_10 padding_right_10">
						<div className="label uppercase">{t('usersAdministrations.reserved')}:</div>
						<div className="info">
							{toMoneyFormat(reservedAmount, '0.00')}
							<div className="currency">{currency}</div>
						</div>
					</div>

					{idRiskGroup && (
						<div className="font_11px margin_right_10 padding_2 text_center">
							<span className="tooltip_right">
								{t('general.rg')}
								<span className="tooltip_content">{`${t('general.id')}: ${idRiskGroup} | ${t(
									'general.name'
								)}: ${riskGroupName}`}</span>
							</span>
						</div>
					)}

					<RiskTagPreview userUid={uid} />

					<ForceUserAuth userUid={uid} />
				</div>
			</div>
		</div>
	);
};
