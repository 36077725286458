import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../utils/getDate';
import { toMoneyFormat } from '../../../../utils/toFixed';
import { ActivatedBonus, ActivatedBonuses as ActivatedBonusesType } from './types';

interface Props {
	activatedBonuses: ActivatedBonusesType;
	selectUnlockBonus: (id: ActivatedBonus | null) => void;
}

export const ActivatedBonuses: FC<Props> = ({ activatedBonuses, selectUnlockBonus }) => {
	const { lockedBonuses, expiredBonuses, unlockedBonuses } = activatedBonuses;

	const activated = useMemo(() => {
		return [
			...lockedBonuses.map((bonus) => ({ ...bonus, flag: 'locked' })),
			...expiredBonuses.map((bonus) => ({ ...bonus, flag: 'expired' })),
			...unlockedBonuses.map((bonus) => ({ ...bonus, flag: 'unlocked' })),
		];
	}, [activatedBonuses]);

	return (
		<table className="admin_table sticky_footer top_60 full_w ibet_users_table">
			<TableHeader />
			<tbody className="t_body">
				{activated.map((bonus) => (
					<TableRow key={bonus.idUserBonus} bonus={bonus} selectUnlockBonus={selectUnlockBonus} />
				))}
			</tbody>
		</table>
	);
};

export const TableHeader = () => {
	const { t } = useTranslation();
	return (
		<thead className="t_head">
			<tr>
				<th scope="col" className="text_center">
					{t('internet.userBonuses.code')}
				</th>
				<th scope="col" className="text_center">
					{t('general.name')}
				</th>
				<th scope="col">{t('internet.userBonuses.type')}</th>
				<th scope="col">{t('general.wallet')}</th>

				<th scope="col" className="text_right">
					{t('internet.userBonuses.unlockedAmount')}
				</th>
				<th scope="col" className="text_right">
					{t('internet.userBonuses.targetAmount')}
				</th>
				<th scope="col" className="text_right">
					{t('internet.userBonuses.amount')}
				</th>
				<th scope="col">{t('internet.userBonuses.activatedAt')}</th>
				<th scope="col">{t('internet.userBonuses.expiresAt')}</th>
				<th scope="col">{t('internet.userBonuses.currency')}</th>
				<th scope="col" className="text_center"></th>
				<th scope="col" className="text_center"></th>
			</tr>
		</thead>
	);
};

interface RowProps {
	bonus: ActivatedBonus;
	selectUnlockBonus: (id: ActivatedBonus | null) => void;
}
export const TableRow: FC<RowProps> = ({ bonus, selectUnlockBonus }) => {
	const { t, i18n } = useTranslation();
	const { language: ln } = i18n;

	const { bonusCode, bonusType, name, currency, payoutWalletType, flag } = bonus;
	const { locked, unlockedAmount, targetAmount, amount, userActivated, userExpires } = bonus;

	const activatdAt = formatDate(userActivated, 'date.month.year hours:minutes');
	const expiresAt = formatDate(userExpires, 'date.month.year hours:minutes');

	return (
		<tr>
			<td width={100} className="text_center">
				{bonusCode}
			</td>
			<td className="text_center">{name?.[ln] || ''}</td>
			<td width={150}>{bonusType}</td>
			<td width={150}>{payoutWalletType}</td>
			<td width={150} className="text_right">
				{toMoneyFormat(unlockedAmount)}
			</td>
			<td width={150} className="text_right">
				{toMoneyFormat(targetAmount)}{' '}
			</td>
			<td width={150} className="text_right">
				{toMoneyFormat(amount)}
			</td>
			<td width={150}>{activatdAt}</td>
			<td width={150}>{expiresAt}</td>
			<td width={30}>{currency}</td>
			<td width={200} className="text_center">
				{flag?.toUpperCase()}
			</td>

			<td width={30} className="actions_cell">
				<div className="td_actions">
					{locked ? (
						<div className="tbl_btn green">
							<i className="mdi mdi-lock-open tooltip_left" onClick={() => selectUnlockBonus(bonus)}>
								<span className="tooltip_content">{t('internet.userBonuses.unlockBonus')}</span>
							</i>
						</div>
					) : null}
				</div>
			</td>
		</tr>
	);
};
