import * as yup from 'yup';

export const integer = (val: number) => {
	return yup.number().integer().min(-10000000000000000000).max(10000000000000000000).isValidSync(val);
};
export const float = (val: number) => {
	return yup
		.number()
		.min(-10000000000000000000)
		.max(10000000000000000000)
		.test('maxDigitsAfterDecimal', 'number field must have 2 digits after decimal or less', (number) =>
			/^-?\d+(\.\d{1,2})?$/.test(`${number}`)
		)
		.isValidSync(val);
};

export const validateRows = (arr: string[][], headings: string[], dataTypes: string[]) => {
	let validRowsLength = arr.every((row) => row.length === headings.length);
	if (!validRowsLength) return false;
	let validFields: boolean[] = [];

	arr.forEach((row) =>
		row.forEach((item, i) => {
			const type = dataTypes[i];
			if (type === 'int') {
				validFields.push(integer(+item) && !item.endsWith('.'));
			} else if (type === 'float') {
				validFields.push(float(+item) && !item.endsWith('.'));
			} else if (type === 'bool') {
				validFields.push(item === 'true' || item === 'false');
			} else {
				validFields.push(true);
			}
		})
	);

	return validFields.every((field) => field);
};
