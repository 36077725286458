import React, { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import api from '../../../../api';
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../../../hooks/useNotifications';
import { useOnlineUser } from '../../context/OnlineUserContext';
import { SimpleLoader } from '../../../../components/general/Loader';
import { DateRange } from '../../../../components/Dates/DateRange';
import { Header, PageFooter, PageContentWrapper } from '../../../../components/layout';
import Dropdown from '../../../../components/general/Dropdown';
import usePagination from '../../../../hooks/usePagination';
import { FooterDropdown } from '../../../risk-management/controller/components/general';
import { LoginData, UserLogs } from './types';
import { ascending, descending, unset } from '../../../../utils/sorting';
import { sortIconHelper, sortTypeHelper } from '../../../../utils/sorting';
import getDate from '../../../../utils/getDate';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';

const Loging = () => {
	const { error, warn } = useNotify();
	const { t } = useTranslation();
	const [loaded, setLoaded] = useState(false);
	const [logins, setLogins] = useState<UserLogs[] | []>([]);
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [loginStatus, setLoginStatus] = useState('ALL');
	const [sortedByLoginTime, sortByLoginTime] = useState(unset);
	const [orderBy, setOrderBy] = useState(['LOGIN_TIME_ASC']);

	// Pagination
	const itemsPerPage = 20;
	const { from, setTotal, perPage, setPerPage, ...pagination } = usePagination(itemsPerPage);

	const {
		onlineUser: { uid },
	} = useOnlineUser();

	const loginStatuses = [
		{ id: 'ALL', name: 'ALL' },
		{ id: 'SUCCESSFUL', name: 'SUCCESSFUL' },
		{ id: 'FAILED', name: 'FAILED' },
	];

	const getUserLogins = async () => {
		if (!startDate || !endDate) {
			warn(t('toast.warn.dateField'));
			return;
		}

		if (startDate && endDate) {
			if (startDate > endDate) {
				warn(t('toast.warn.dateCheck'));
				return;
			}
		}

		const data: LoginData = {
			userUuid: uid,
			orderBy,
			loginTimeFrom: startDate ? new Date(startDate.setHours(0, 0, 0, 0)).toISOString() : null,
			loginTimeTo: endDate ? new Date(endDate.setHours(23, 59, 59, 999)).toISOString() : null,
			loginStatus: loginStatus === 'ALL' ? null : loginStatus,
			skip: from,
			limit: perPage,
		};
		try {
			setLoaded(false);
			const { total, userLogsList } = await api.logins.getUserLogins(data);
			setLogins(userLogsList);
			setTotal(total);
		} catch (err: any) {
			showError(err);
		} finally {
			setLoaded(true);
		}
	};

	// Error function
	const showError = (err: any) => {
		let errorText = 'toast.error.defaultText';
		if (err?.response?.data?.detail) {
			errorText = Array.isArray(err.response.data.detail)
				? err.response.data.detail.map((e: any) => `${e.loc[1]} - ${e.msg}`).join('\n')
				: err.response.data.detail;
		}

		error(errorText, {
			position: 'top-right',
			hideProgressBar: true,
			closeOnClick: true,
			autoClose: 5000,
			style: { whiteSpace: 'pre-wrap', marginTop: '75px' },
		});
	};

	useEffect(() => {
		getUserLogins();
	}, [from, perPage, orderBy]);

	useEffect(() => {
		if (sortedByLoginTime === descending) setOrderBy(['LOGIN_TIME_DESC']);
		if (sortedByLoginTime === ascending) setOrderBy(['LOGIN_TIME_ASC']);
	}, [sortedByLoginTime]);

	const isMobileView = useCheckMobileScreen();

	const tools = {
		leftTool: (
			<>
				<div
					className="font_11px font_400 uppercase"
					style={{
						paddingLeft: '10px',
					}}
				>
					{t('usersAdministrations.loginStatus')}
				</div>
				<Dropdown
					items={loginStatuses}
					disableScroll
					selectedItem={loginStatus}
					selectItem={setLoginStatus}
					margins={['0', '10', '0', '0']}
					icon={'mdi mdi-menu-down'}
					isDisabled={!loaded}
				/>
				<DateRange startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
				<button onClick={getUserLogins} className={`btn md blue ${loaded ? '' : 'disabled'} `}>
					{t('general.search')}
				</button>
			</>
		),
	};

	return (
		<SimpleLoader loaded={loaded}>
			<PageContentWrapper flex isMobileView={isMobileView} widthClass={'w_2200'}>
				<div className={'mobile_de_filter_wrapper'}>
					<Header
						widthClass={'w_2200'}
						hideLogo={true}
						leftToolClass={'padding_right_0'}
						leftTool={tools.leftTool}
					/>
				</div>

				{logins.length > 0 ? (
					<table className="admin_table sticky_header full_w">
						<TableHeader sortedByLoginTime={sortedByLoginTime} sortByLoginTime={sortByLoginTime} />
						<tbody className="t_body">
							{logins?.map((login, index) => (
								<TableRow key={index} login={login} />
							))}
						</tbody>
					</table>
				) : null}
			</PageContentWrapper>

			<PageFooter
				numberOfItems={<FooterDropdown perPage={perPage} setPerPage={setPerPage} />}
				{...pagination}
			></PageFooter>
		</SimpleLoader>
	);
};

export default Loging;

// Table Header
interface TableHeaderProps {
	sortedByLoginTime: string;
	sortByLoginTime: Dispatch<SetStateAction<string>>;
}
const TableHeader: FC<TableHeaderProps> = ({ sortedByLoginTime, sortByLoginTime }) => {
	const { t } = useTranslation();
	return (
		<thead className="t_head">
			<tr data-testid="head-row">
				<th>
					<span>{t('usersAdministrations.userUuid')}</span>
				</th>
				<th>
					<span>{t('usersAdministrations.deviceType')}</span>
				</th>
				<th>
					<span>{t('general.id')}</span>
				</th>

				<th>
					<span>{t('usersAdministrations.loginStatus')}</span>
				</th>
				<th scope="col">
					<span className="label display_inline">{t('usersAdministrations.loginTime')}</span>
					<i
						data-testid="resortClass"
						className={`mdi ${sortIconHelper[sortedByLoginTime]}`}
						onClick={() => sortByLoginTime(sortTypeHelper[sortedByLoginTime])}
					/>
				</th>
				<th>
					<span>{t('usersAdministrations.loginType')}</span>
				</th>
				<th>
					<span> {t('usersAdministrations.ipAddress')}</span>
				</th>
			</tr>
		</thead>
	);
};

// Table Row
interface TableRowProps {
	login: UserLogs;
}
const TableRow: FC<TableRowProps> = ({ login }) => {
	const { id, userUuid, deviceType, loginStatus, loginTime, loginType, ipAddress } = login;
	const [dateFrom, timeFrom] = getDate(loginTime).split(' ');

	return (
		<tr>
			<td>{userUuid}</td>
			<td>{deviceType}</td>
			<td>{id}</td>
			<td>
				<span className={`tag_item light tooltip_right blue`}>{loginStatus}</span>
			</td>
			<td>
				<span className="date font_700">{dateFrom}</span>
				<span className="time"> {timeFrom}</span>
			</td>
			<td>
				<span className={`tag_item light tooltip_right blue`}>{loginType}</span>
			</td>
			<td>{ipAddress}</td>
		</tr>
	);
};
