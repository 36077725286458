import React, { FC } from 'react';
import { toFixed, toMoneyFormat } from '../../../../../utils/toFixed';
import { useTicket } from '../../context/TicketContext';
import { Currency } from './Currency';

interface Props {
	label: string;
	value: string | number | null;
	similarValue?: string | number | undefined | null;
	currency?: boolean;
	moneyFormat?: boolean;
	showEvents?: 'showAuthorizedEvents' | 'showOriginalEvents' | 'showDiff';
}

export const Field: FC<Props> = ({
	label = '',
	value,
	similarValue,
	currency = false,
	moneyFormat = false,
	children,
	showEvents,
}) => {
	let { similarTicketShown } = useTicket();

	let valueClass = '';
	if (similarTicketShown || showEvents === 'showDiff') {
		if ((value || 0) > (similarValue || 0)) valueClass = 'green_01_text';
		else if ((value || 0) < (similarValue || 0)) valueClass = 'red_text';
	}

	return (
		<div className="footer_info_item">
			<div className="label">{label}:</div>
			<div className="content">
				<span className={valueClass}>
					{moneyFormat ? toMoneyFormat((value || 0) as number) : value != null ? value : '-'}
				</span>

				{similarTicketShown || showEvents === 'showDiff' ? (
					<>
						<span>{` | `}</span>
						<span className="similar_ticket_field">
							{moneyFormat
								? toMoneyFormat((similarValue || 0) as number)
								: similarValue != null
								? similarValue
								: '-'}
						</span>
					</>
				) : null}

				{currency ? <Currency /> : null}
				{children}
			</div>
		</div>
	);
};
