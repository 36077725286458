import React, { useEffect, FC, Children, ReactNode, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	title?: string;
	disabled?: boolean;
}
const HeaderWrapper: FC<Props> = ({ children, title, disabled }) => {
	const [LeftSection, DaysFilter, CentralSection, RightSection] = getChildren(children);

	const { t } = useTranslation();

	// Set the title of the tab to provided title
	useEffect(() => {
		if (title) document.title = title;
	}, []);

	const disabledClass = disabled ? 'disabled' : '';

	return (
		<div className={`main_head ${disabledClass}`}>
			<div className="head_left_half">
				{title && <h1 className="title_section">{title}</h1>}
				<div className="wrapper col_border_left col_border_right">
					<Fragment>
						{LeftSection}
						<span className="section_title regular font_11px margin_left_auto">{DaysFilter}</span>
					</Fragment>
				</div>
			</div>

			<div className="head_right_half">
				<div className="wrapper flex_justify_end">{CentralSection}</div>
				<div className="head_tools">
					<div className="wrapper ">
						<Fragment>
							{RightSection}
							<span className="tool_item ntf_toggle">
								<div className="notif" />
								<i className="mdi mdi-bell" />
							</span>
						</Fragment>
					</div>
				</div>
			</div>
		</div>
	);
};
export default HeaderWrapper;

const getChildren = (children: ReactNode): ReactNode[] => {
	return Children.map(children, (c) => c) || [];
};
