import {
	PlayerProps,
	PlayerPropsEvent,
	PlayerPropsModel,
} from '../../pages/prematch/controller/components/OddsController/Specials/PlayerProps/types';
import { axiosInstance } from './../config';

interface ActivationProps {
	idParentEvent: string;
	idOfferPlan: string;
	playerIds: string[];
	active: boolean;
}

export const playerProps = {
	getEventModels({
		idParentEvent,
		idOfferPlan,
		lang = 'en',
		platform = 'web',
	}: {
		idParentEvent: string;
		idOfferPlan?: string;
		lang?: string;
		platform?: string;
	}): Promise<PlayerPropsModel> {
		return axiosInstance
			.get(
				`/administration/specials/player_props/models?idParentEvent=${encodeURIComponent(
					idParentEvent
				)}&lang=${lang}&platform=${platform}&idOfferPlan=${idOfferPlan || ''}`
			)
			.then((res) => res.data);
	},

	getEvents(idParentEvent: string, idOfferPlan: string = ''): Promise<PlayerPropsEvent[]> {
		return axiosInstance
			.get(
				`/administration/specials/player_props?idParentEvent=${encodeURIComponent(
					idParentEvent
				)}&idOfferPlan=${encodeURIComponent(idOfferPlan)}`
			)
			.then((res) => res.data);
	},

	create(playerProps: PlayerProps) {
		return axiosInstance.post('/administration/specials/player_props', playerProps).then((res) => res.data);
	},

	update(playerProps: PlayerProps) {
		return axiosInstance.put('/administration/specials/player_props', playerProps).then((res) => res.data);
	},

	active(props: ActivationProps): Promise<PlayerPropsEvent[]> {
		return axiosInstance.post('/administration/specials/player_props/active', { ...props }).then((res) => res.data);
	},
};
