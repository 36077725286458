import React, { CSSProperties, useState } from 'react';
import { useGeo } from '../context';
import SimpleBarReact from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import Delete from '../../../../utils/svg/Delete';
import NotificationModal from '../../../../components/general/NotificationModal';
import { debounce } from 'lodash';

export const ModifiedActions = () => {
	const { t } = useTranslation();
	const [modalOpened, setModalOpened] = useState(false);
	const { modified, acceptChanges, dropChanges, modifiedParams, fieldMap, initialGeoParameters, refresh } = useGeo();

	return (
		<>
			<div className="head_tools padding_left_10">
				{modified ? (
					<>
						<span
							className="tool_item green_text_hover green_bgish_hover tooltip_left"
							onClick={() => setModalOpened(true)}
						>
							<i className="mdi mdi-check-all"></i>
							<span className="tooltip_content green">{t('general.accept')}</span>
						</span>
						<span
							className="tool_item redish_text_hover redish_bgish_hover margin_right_20 tooltip_left"
							onClick={() => dropChanges()}
						>
							<i className="mdi mdi-close"></i>
							<span className="tooltip_content redish">{t('general.dismiss')}</span>
						</span>
					</>
				) : (
					<span className="tool_item  tooltip_left margin_right_20" onClick={debounce(refresh, 300)}>
						<i className="mdi mdi-refresh"></i>
						<span className="tooltip_content">{t('general.refresh')}</span>
					</span>
				)}
			</div>

			<NotificationModal
				opened={modalOpened}
				cancelAction={() => setModalOpened(false)}
				confirmAction={() => {
					acceptChanges();
					setModalOpened(false);
				}}
				cancelText={t('general.cancel')}
				confirmText={t('general.confirm')}
			>
				<div className="context_icon margin_top_40">
					<Delete />
				</div>

				<h2 className="modal_message capitalize text_center">{t('general.areSure')}</h2>
				<p className="text_center margin_top_10">{t('general.youAreChanging')}</p>

				<SimpleBarReact style={{ maxHeight: 200, overflowX: 'hidden' }} className="custom_scroll secondary_content">
					{Object.entries(modifiedParams)
						.sort((a, b) => fieldMap[a[0]].position - fieldMap[b[0]].position)
						.map((item) => {
							const [id, value] = item;
							const { entity_params, inherited_params } = initialGeoParameters;

							const inherited = inherited_params[id] !== null ? inherited_params[id] : t('general.notSet');

							const prevValue =
								entity_params[id] !== null ? `${entity_params[id]}` : `${t('geo.inherited')} (${inherited})`;

							const valuePreview =
								value !== null && value !== undefined ? `${value}` : `${t('geo.inherited')} (${inherited})`;

							return (
								<p key={id} className="text_center margin_top_10">
									{`${fieldMap[id].label} ${t('general.from')}`}
									<br />
									<span style={styles}>{prevValue} </span>
									{t('general.to')} <span style={styles}>{valuePreview}</span>
								</p>
							);
						})}
				</SimpleBarReact>
			</NotificationModal>
		</>
	);
};

const styles: CSSProperties = { fontWeight: 'bolder', color: '#46B7ED' };
