import React, { FC } from 'react';
import { Summary } from '../../users/types';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { toMoneyFormat } from '../../../../utils/toFixed';

interface Props {
	summary: Summary[];
}

export const UserBalance: FC<Props> = ({ summary }) => {
	const { t } = useTranslation();
	return (
		<div className="info_col balance">
			<div className="amount">
				<div className="info_title flex_direction_row">
					<div className="title_label flex_justify_end">{t('transactions.accountTypeShort')}</div>
					<div className="title_sub_label">{t('transactions.accountId')}</div>
				</div>
				{summary.map(({ accountType, accountId }) => (
					<div key={uuid()} className="info_row">
						<div className="info_label">{accountType}</div>
						<div className="info_content padding_left_10">{accountId}</div>
					</div>
				))}
			</div>
			<div className="amount">
				<div className="info_title padding_left_10">{t('transactions.ballance')}</div>
				{summary.map(({ balance }) => (
					<div key={uuid()} className="info_row">
						<div className="info_content">{toMoneyFormat(balance)}</div>
					</div>
				))}
			</div>
			<div className="amount">
				<div className="info_title padding_left_10">{t('transactions.payIn')}</div>
				{summary.map(({ payIn }) => (
					<div key={uuid()} className="info_row">
						<div className="info_content">{toMoneyFormat(payIn)}</div>
					</div>
				))}
			</div>
			<div className="amount">
				<div className="info_title padding_left_10">{t('transactions.win')}</div>
				{summary.map(({ win }) => (
					<div key={uuid()} className="info_row">
						<div className="info_content">{toMoneyFormat(win)}</div>
					</div>
				))}
			</div>
			<div className="amount">
				<div className="info_title padding_left_10">{t('transactions.deposit')}</div>
				{summary.map(({ deposit }) => (
					<div key={uuid()} className="info_row">
						<div className="info_content">{toMoneyFormat(deposit)}</div>
					</div>
				))}
			</div>
			<div className="amount">
				<div className="info_title padding_left_10">{t('transactions.withdrawal')}</div>
				{summary.map(({ withdrawal }) => (
					<div key={uuid()} className="info_row">
						<div className="info_content">{toMoneyFormat(withdrawal)}</div>
					</div>
				))}
			</div>
			<div className="amount">
				<div className="info_title padding_left_10">{t('transactions.reserved')}</div>
				{summary.map(({ reserved, currency }) => (
					<div key={uuid()} className="info_row">
						<div className="info_content">
							{toMoneyFormat(reserved)}{' '}
							<span className="font_400 font_11px padding_left_5">{reserved ? currency : ''}</span>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
