import { Tag } from '../../pages/risk-management/tags/types';
import { axiosInstance } from './../config';

export const tags = {
	getTags() {
		return axiosInstance(`/risk/tags?limit=1000`).then((res) => res.data);
	},

	createTag(tag: Tag) {
		return axiosInstance
			.post(`/risk/tags`, {
				...tag,
			})
			.then((res) => {
				return res.data;
			});
	},

	updateTag(tag: Tag) {
		return axiosInstance
			.put(`/risk/tags/${tag.id}`, {
				...tag,
			})
			.then((res) => {
				return res.data;
			});
	},
};
