import React, { FC, useState } from 'react';
import { Sport } from '../sports/types';
import { IconForm, SelectSport } from '.';
import { useTranslation } from 'react-i18next';
import { useModels } from '../../../hooks/useModels';
import { useFormContext, useWatch } from 'react-hook-form';
import LangMedia from '../../../components/general/LangMedia';
import { FillEmpty } from '../../../components/general/FillEmpty';

interface Props {
	col: string;
	direction: string;
	colBorder: string;
	colPosition?: string;
	sports: Sport[] | null;
	padding: number | number[];
	withDescription?: boolean;
}

export const LangForm: FC<Props> = ({
	col,
	direction,
	colBorder,
	colPosition,
	padding,
	sports,
	withDescription = false,
}) => {
	const { t } = useTranslation();
	const { getValues, setValue, control } = useFormContext();
	const { languages, media, selectedLang, selectedMedia, selectLang, selectMedia, fillRegular, fillExtended } =
		useModels();

	const nameDefault = getValues('name');

	const name = useWatch({
		control,
		name: 'name',
		defaultValue: nameDefault,
	});

	const shortNameDefault = getValues('shortName');

	const shortName = useWatch({
		control,
		name: 'shortName',
		defaultValue: shortNameDefault,
	});

	const descriptionDefault = getValues('description');

	const description = useWatch({
		control,
		name: 'description',
		defaultValue: descriptionDefault,
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: any) => {
		let newFieldValues = { ...field };
		newFieldValues[selectedLang] = e.target.value;
		setValue(e.target.name, newFieldValues);
	};
	const handleChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		let newFieldValues = { ...description };
		newFieldValues[selectedLang][selectedMedia] = e.target.value;
		setValue('description', newFieldValues);
	};

	const [fillActive, setFillActive] = useState(false);

	const fillLocalization = () => {
		setFillActive(false);
		setValue('name', fillRegular(name));
		setValue('shortName', fillRegular(shortName));
		setValue('description', fillExtended(description));
	};

	if (typeof padding === 'number') padding = [padding, padding, padding, padding];
	const [paddingTop, paddingRight, paddingBottom, paddingLeft] = padding;

	const marginBottom = withDescription ? 'margin_bottom_20' : 'margin_bottom_80';

	return (
		<div
			className={`col-${col} ${colPosition}_col direction_${direction} padding_left_${paddingLeft} padding_right_${paddingRight} padding_top_${paddingTop} padding_bottom_${paddingBottom}  col_border_${colBorder}`}
		>
			{sports && <div className="row margin_bottom_20">{<SelectSport sports={sports} />}</div>}

			{!withDescription ? (
				<LangMedia
					languages={languages}
					media={media}
					selectedLang={selectedLang}
					selectedMedia={selectedMedia}
					selectLang={selectLang}
					selectMedia={selectMedia}
					marginTop={'0'}
					showMedia={withDescription}
					direction="down"
				/>
			) : null}

			<div className={`${marginBottom} margin_top_20 padding_left_20 margin_left_auto margin_right_auto`}>
				<input
					className="normal"
					type="text"
					name="name"
					placeholder={t('general.name')}
					onFocus={() => setFillActive(true)}
					onChange={(e) => handleChange(e, name)}
					value={name?.[selectedLang] || ''}
				/>

				<input
					className=" margin_left_10"
					type="text"
					name="shortName"
					onChange={(e) => handleChange(e, shortName)}
					onFocus={() => setFillActive(true)}
					style={{ height: '32px', width: '100px' }}
					placeholder={t('general.shortName')}
					value={shortName?.[selectedLang] || ''}
				/>
			</div>

			{withDescription ? (
				<LangMedia
					languages={languages}
					media={media}
					selectedLang={selectedLang}
					selectedMedia={selectedMedia}
					selectLang={selectLang}
					selectMedia={selectMedia}
					marginTop={'0'}
					showMedia={withDescription}
					direction="down"
				/>
			) : null}

			{withDescription ? (
				<div className=" margin_bottom_100 padding_left_20 ">
					<textarea
						className="invisible  padding_top_15  padding_right_25"
						style={{ resize: 'none' }}
						name="description"
						placeholder={t('general.description')}
						cols={30}
						rows={5}
						onChange={handleChangeDescription}
						onFocus={() => setFillActive(true)}
						value={description?.[selectedLang]?.[selectedMedia] || ''}
					/>
				</div>
			) : null}

			{fillActive &&
			(name?.[selectedLang] || shortName?.[selectedLang] || description?.[selectedLang]?.[selectedMedia]) ? (
				<FillEmpty styles={{ marginLeft: '74%' }} onClick={fillLocalization} />
			) : (
				<FillEmpty styles={{ opacity: 0, pointerEvents: 'none', marginLeft: '70%' }} onClick={fillLocalization} />
			)}

			<div className="divider_horizontal small label_left" style={{ marginBottom: '20px' }}>
				<div className="div_label">Icons</div>
				<div className="thin_line"></div>
			</div>
			<IconForm />
		</div>
	);
};
