import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../../../hooks/useNotifications';
import { select } from '../../../../utils';

interface Props {
	addNewCode: (codeName: string) => void;
	keyboardCodes: string[];
	disabled: string;
}

export const AddKeyboardCode: FC<Props> = ({ addNewCode, keyboardCodes, disabled }) => {
	const { t } = useTranslation();
	const [create, setCreate] = useState(false);
	const [codeName, setCodeName] = useState('');

	const { warn } = useNotify();

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setCodeName(e.target.value);
	};

	const add = () => {
		if (keyboardCodes && keyboardCodes.length > 3) return warn(t('marketsAdministrations.maxNumberOfCodes'));
		if (keyboardCodes && keyboardCodes.includes(codeName)) return warn('marketsAdministrations.uniqueCode');
		addNewCode(codeName);
		setCreate(false);
	};

	const btnClass = 'one_line_form_action md btn  tooltip_bottom btn_list_V';
	const disableAdd = !codeName.length ? 'disabled' : '';

	return (
		<>
			{create ? (
				<div className={`new_plan_item new_list_wrapper_V ${disabled}`}>
					<div className="one_line_form">
						<input
							style={{ textTransform: 'none' }}
							id="add-new-code"
							type="text"
							maxLength={25}
							value={codeName}
							onChange={handleChange}
							placeholder={t('marketsAdministrations.enterCodeName')}
						/>
					</div>
					<button className={`${btnClass} ${disableAdd} blue`} onClick={add}>
						<i className="mdi mdi-plus"></i>
						<span className="tooltip_content">{t('general.add')}</span>
					</button>
					<button className={`${btnClass} red`} onClick={() => setCreate(false)}>
						<i className="mdi mdi-close"></i>
						<span className="tooltip_content">{t('general.close')}</span>
					</button>
				</div>
			) : (
				<div className={`align_center flex_justify_end margin_right_20 margin_bottom_10 ${disabled}`}>
					{
						<button
							className="btn tiny outline"
							style={{ height: 'auto' }}
							onClick={() => {
								setCreate(true);
								setTimeout(() => {
									select('add-new-code');
								}, 100);
							}}
						>
							{t('marketsAdministrations.newCode')}
						</button>
					}
				</div>
			)}
		</>
	);
};
