import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toMoneyFormat } from '../../../../utils/toFixed';

interface Props {
	amountIn: number;
	amountOut: number;
	currency: string;
}

export const FooterSum: FC<Props> = ({ amountIn, amountOut, currency }) => {
	const { t } = useTranslation();
	return (
		<div className="table_sum">
			<i className="mdi mdi-sigma status_icon transparent"></i>
			{amountIn ? (
				<div className="sum_item">
					<div className="sum_label">{t('transactions.amountIn')}</div>
					<div className="sum_content">
						{toMoneyFormat(amountIn)} <span className="currency">{currency}</span>
					</div>
				</div>
			) : null}
			{amountOut ? (
				<div className="sum_item">
					<div className="sum_label">{t('transactions.amountOut')}</div>
					<div className="sum_content">
						{toMoneyFormat(amountOut)} <span className="currency">{currency}</span>
					</div>
				</div>
			) : null}
		</div>
	);
};
