import { PlayerReservation, SumReservation } from '../../pages/reports/reservations/types';
import { axiosInstance } from '../config';

export interface ReservationsParams {
	idParentGeoEntity: string | null;
	payoutType: string | string[] | null;
	locationIds: string[] | null;
	active: boolean;
	skip?: number;
	limit?: number;
	datetime_from?: string | null;
	datetime_to?: string | null;
	order_by?: string;
}

export const reservations = {
	getPlayerReservations(params: ReservationsParams): Promise<PlayerReservation[]> {
		return axiosInstance
			.post('/reporting/reservations', { ...params, summarized: false, limit: 1000 })
			.then(({ data }) => data?.reservations);
	},
	getSumReservations(params: ReservationsParams): Promise<SumReservation[]> {
		return axiosInstance
			.post('/reporting/reservations', { ...params, summarized: true, limit: 1000 })
			.then(({ data }) => data);
	},

	getReservationTypes() {
		return axiosInstance.get('/administration/users/user/reservation_types').then(({ data }) => data);
	},
};
