import { FastField } from 'formik';
import React, { FormEvent, KeyboardEvent, RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	searchRef: RefObject<HTMLInputElement>;
	value: string;
	handleChange: (e: FormEvent<HTMLInputElement>) => void;
	toggled: boolean;
	open: () => void;
	close: () => void;
	message?: string;
	placeholder?: string;
	className?: string;
	closeable: boolean;
	onEnter?: () => void;
	isDisabled?: boolean;
}

export default function Search({
	searchRef,
	value,
	handleChange,
	toggled,
	open,
	close,
	message,
	placeholder,
	className,
	closeable,
	onEnter,
	isDisabled = false,
}: Props) {
	const { t } = useTranslation();

	const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode !== 13 || !onEnter) return;
		onEnter();
	};
	if (isDisabled) {
		toggled = false;
	}

	// Class created based on the toggled value
	const toggleClass = toggled ? 'search_form toggle_left  toggle' : 'search_form toggle_left';

	// Message span created based on withMessage prop and search value length
	const msgSpan = useMemo(
		() =>
			message && value.length && value.length < 3 ? (
				<span data-testid="message" className="message text_redish">
					{message}
				</span>
			) : null,
		[message, value]
	);

	return (
		<div
			data-testid="search"
			className={`${toggleClass} ${className ? className : ''}   ${isDisabled ? 'disabled' : ''}`}
		>
			{closeable ? (
				<>
					<span className="src_btn" onClick={close}>
						<i className="mdi mdi-close-circle" />
					</span>

					<span className="src_toggle" onClick={open}>
						<i className="mdi mdi-magnify" />
					</span>
				</>
			) : null}
			<input
				data-testid="search-input"
				ref={searchRef}
				value={value}
				onChange={handleChange}
				className="search_field margin_right_5"
				type="search"
				placeholder={placeholder || t('general.search')}
				onKeyUp={handleEnter}
			/>
			{msgSpan}
		</div>
	);
}
