import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortCell } from '../../../../../reports/tickets/components/SortCell';
interface Props {
	selectedSort: string;
	selectSort: (sort: string) => void;
	loaded?: boolean;
}

export const TableHeader: FC<Props> = ({ selectedSort, selectSort, loaded }) => {
	const { t } = useTranslation();
	return (
		<thead className={`t_head ${loaded ? 'disabled' : ''}`}>
			<tr>
				<SortCell
					ascending="NUMBER_ASC"
					descending="NUMBER_DESC"
					label={t('vouchers.number')}
					selectedSort={selectedSort}
					selectSort={selectSort}
				/>
				<SortCell
					ascending="ID_ASC"
					descending="ID_DESC"
					label="ID"
					selectedSort={selectedSort}
					selectSort={selectSort}
				/>
				<SortCell
					ascending="AMOUNT_ASC"
					descending="AMOUNT_DESC"
					label={t('vouchers.amount')}
					selectedSort={selectedSort}
					selectSort={selectSort}
				/>
				<th>{t('general.email')}</th>
				<th>{t('vouchers.activated')}</th>
				<th>{t('vouchers.transaction')}</th>
				<th>{t('vouchers.barcode')}</th>
				<th>{t('vouchers.pin')}</th>
				<th>{t('vouchers.canceled')}</th>
				<th>{t('vouchers.canceledBy')}</th>
				<th className="text_center">{t('vouchers.status')}</th>
				<th></th>
			</tr>
		</thead>
	);
};

export default TableHeader;
