import React, { useEffect, useState } from 'react';

// Import utils
import api from '../api';

// Import context
import { useUser, User, fakeUser } from '../context/UserProvider';
import { useAbility } from '../casl/AbilityContext';

// Import layouts
import { SimpleLoader as Loader } from '../components/general/Loader';
import PublicRouter from './components/PublicRouter';
import PrivateRouter from './components/PrivateRouter';
import en from 'date-fns/locale/en-US';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

export default () => {
	registerLocale('en-US', en);
	setDefaultLocale('en');

	const { user, login } = useUser();
	const { ability } = useAbility();

	// Loader state which is used to wrap routers becouse of UI blinking on app load
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		(async function checkIsLoggedIn() {
			try {
				// Check is user logged in and update user state/abilities
				const { status } = await api.SSO.check();
				if (status === 200) {
					const user = await api.SSO.getUser();
					const roles = await api.SSO.getUserRoles();
					const { superuser, ...rest } = roles;

					const userWithRoles: User = {
						...user,
						can: Object.keys(roles)
							.filter((role) => !!roles[role].length || superuser)
							.map((role: string) => ({ actions: ['read'], subject: role })),
					};

					ability.update(userWithRoles.can);
					login(userWithRoles, rest, superuser);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoaded(true);
			}
		})();
	}, []);

	return user ? (
		<Loader loaded={loaded}>
			<PrivateRouter />
		</Loader>
	) : (
		<Loader loaded={loaded}>
			<PublicRouter />
		</Loader>
	);
};
