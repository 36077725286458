import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../utils/getDate';

interface Props {
	startTime: string;
}

export const DateSelect: FC<Props> = ({ startTime }) => {
	const { t } = useTranslation();
	const [providerDate, providerTime] = formatDate(startTime, 'date.month.year', 'hours:minutes');

	return (
		<div className="search_dropdown_wrapper medium margin_bottom_20">
			<div className="search_dropdown col-5"></div>
			<div className="actions col-2 justify_center">&nbsp;</div>
			<div className="col-5 input_label medium direction_column ">
				<small>{t('general.dateAndTime')}</small>
				<strong className="">{`${providerDate} | ${providerTime}`}</strong>
			</div>
		</div>
	);
};
