import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import LangMedia from '../../../general/LangMedia';
import { useModels } from '../../../../hooks/useModels';
import { useFormContext, useWatch } from 'react-hook-form';

export const DescriptionForm = () => {
	const { t } = useTranslation();

	const { languages, media, selectedLang, selectedMedia, selectLang, selectMedia } = useModels();

	const { setValue, getValues, control } = useFormContext();

	const desc = getValues('description');

	const description = useWatch({
		control,
		name: 'description',
		defaultValue: desc,
	});

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(`description.${selectedLang}.${selectedMedia}`, e.target.value);
	};

	const tour = getValues('tournament');

	const tournament = useWatch({
		control,
		name: 'tournament',
		defaultValue: tour,
	});

	const tournamentDescription = tournament?.description?.[selectedLang]?.[selectedMedia] || '';
	const placeholder = tournamentDescription
		? `${t('tournamentsAdministration.tournamentDesc')} ${tournamentDescription}`
		: t('eventsAdministration.description');

	return (
		<Fragment>
			<LangMedia
				languages={languages}
				media={media}
				selectedLang={selectedLang}
				selectedMedia={selectedMedia}
				selectLang={selectLang}
				selectMedia={selectMedia}
				marginTop={'20'}
			/>
			{/* Textarea */}
			<div className="tab_content_wrap">
				<div className="tab_content active">
					<textarea
						className="invisible padding_left_20 padding_top_15"
						placeholder={placeholder}
						cols={30}
						rows={7}
						onChange={handleChange}
						value={(selectedMedia && selectedLang && description?.[selectedLang]?.[selectedMedia]) || ''}
					/>
				</div>
			</div>
		</Fragment>
	);
};
