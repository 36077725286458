import React, { useState } from 'react';
import { Item } from '../../types';
import { usePairing } from '../../_framework';
import { useTranslation } from 'react-i18next';
import Delete from '../../../../utils/svg/Delete';
import { formatDate } from '../../../../utils/getDate';
import { CopyWrapper } from '../../../../components/general/CopyWrapper';
import NotificationModal from '../../../../components/general/NotificationModal';
import { ProviderCell } from './ProviderCell';
import { isEmpty } from 'lodash';

// Local props for the component
interface Props extends Item {
	isStaged?: boolean;
	setConfirmItem?: (item: Item) => void;
	provider: string;
	isLive?: boolean;
}

export const PairedItem: React.FC<Props> = ({ provider, isStaged, setConfirmItem, isLive = true, ...item }) => {
	const { t } = useTranslation();

	const { id, listCode, name, startTime, pairedWith, sport, tournament, category, linkedProviders } = item;
	const [{ id: pairedId, name: pairedName, utcScheduled, tournament: pairedTournament }] = pairedWith;
	const { cancelPairing, unpair, loading } = usePairing();

	const [localHome, localAway] = name.split(' - ');
	const [pairedHome, pairedAway] = pairedName.split(' - ');

	const [localDate, localTime] = formatDate(startTime, 'date.month', 'hours:minutes');
	const [pairedDate, pairedTime] = formatDate(pairedWith[0].startTime || utcScheduled, 'date.month', 'hours:minutes');

	const [modalOpened, setModalOpened] = useState(false);

	const closeModal = () => {
		setModalOpened(false);
	};

	const disconnect = () => {
		if (isStaged) cancelPairing(id);
		else setModalOpened(true);
	};

	const itemClass = isStaged ? 'list_item selected' : 'list_item';

	const tournamentName = `${tournament?.name || ''} (${category?.name || ''}) `;
	const pairedTournamentName = `${pairedTournament?.name || ''} (${pairedWith[0]?.category?.name || ''})`;

	const otherProviders = linkedProviders?.filter((linkedProvider) => linkedProvider !== provider);

	return (
		<div className={itemClass} style={{ transition: 'none' }}>
			{/* Local item */}
			<div className={`local_list ${!isEmpty(otherProviders) ? 'bg_row_color_green' : ''}`}>
				<div className="sport_icon">
					<div className="svg_icon tooltip_right">
						<svg className="e_ico">
							<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${sport.id}`}></use>
						</svg>
						<span className="tooltip_content">{sport.name}</span>
					</div>
				</div>
				<div className="tournament font_14px" title={tournamentName}>
					{tournamentName}
				</div>
				<div className="match_id">{listCode}</div>

				{localAway ? (
					<>
						<div className={`list_item_content listitem_target`}>
							<div className="home_team font_14px" title={localHome}>
								{localHome}
							</div>
							<div className="vs">vs</div>
							<div className="away_team font_14px" title={localAway}>
								{localAway}
							</div>
						</div>
					</>
				) : (
					<div className={`list_item_content listitem_target`}>
						<div className="home_team font_14px" title={localHome}>
							{localHome}
						</div>
					</div>
				)}

				<div className="event_start">
					<div className="event_date">{localDate}</div>
					<span className="divider"></span>
					<div className="event_time">{localTime}</div>
				</div>
				<ProviderCell providerData={linkedProviders} otherProviders={otherProviders} />
				<div className="connect_ico">
					<i className="mdi mdi-circle"></i>
				</div>
			</div>

			{/* Connection actions */}
			<div className="connections">
				{isStaged && (
					<div className="connect" onClick={() => setConfirmItem && setConfirmItem(item)}>
						<i className="mdi mdi-check" />
					</div>
				)}
				<div className="disconnect" onClick={disconnect}>
					<i className="mdi mdi-close" />
				</div>
				<div className="line" />
			</div>

			{/* Paired item */}
			<div className="provider_list">
				<div className="connect_ico">
					<i className="mdi mdi-circle"></i>
				</div>

				<div className="event_start">
					<div className="event_time">{pairedTime}</div>
					<span className="divider"></span>
					<div className="event_date text_left">{pairedDate}</div>
				</div>

				{pairedAway ? (
					<>
						<div className={`list_item_content listitem_target`}>
							<div className="home_team font_14px" title={pairedHome}>
								{pairedHome}
							</div>
							<div className="vs">vs</div>
							<div className="away_team font_14px" title={pairedAway}>
								{pairedAway}
							</div>
						</div>
					</>
				) : (
					<div className={`list_item_content listitem_target`}>
						<div className="home_team font_14px" title={pairedHome}>
							{pairedHome}
						</div>
					</div>
				)}

				<CopyWrapper value={pairedId} />
				<div className="sport_icon">
					<div className="svg_icon tooltip_left">
						<svg className="e_ico">
							<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${sport.id}`}></use>
						</svg>
						<span className="tooltip_content">{sport.name}</span>
					</div>
				</div>
				<div className="tournament font_14px" title={pairedTournamentName}>
					{pairedTournamentName}
				</div>
			</div>
			{modalOpened ? (
				<NotificationModal
					opened
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={() => setModalOpened(false)}
					loading={loading.includes(id)}
					confirmAction={() => {
						unpair(id, pairedId, closeModal, isLive);
					}}
				>
					<div className="context_icon margin_top_40">
						<Delete />
					</div>

					<h2 data-testid="modal-msg" className="modal_message_title">
						{t('Are you sure ?')}
					</h2>
				</NotificationModal>
			) : null}
		</div>
	);
};
