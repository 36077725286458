import React, { FC } from 'react';
import { debounce } from 'lodash';

interface Props {
	checked: boolean;
	toggle: () => void;
	leftLabel: string;
	rightLabel: string;
}

export const ModeSwitch: FC<Props> = ({ checked, toggle, leftLabel, rightLabel }) => {
	return (
		<label className="switch padding_mobile">
			<input type="checkbox" checked={checked} className="switch_mark" onChange={debounce(toggle, 300)} />

			<span className="checkbox_label">
				<span className="on_label mb_green_text">{leftLabel}</span>
				<span className="off_label">{leftLabel}</span>
			</span>

			<span className="slider"></span>

			<span className="checkbox_label">
				<span className="on_label">{rightLabel}</span>
				<span className="off_label mb_green_text">{rightLabel}</span>
			</span>
		</label>
	);
};
