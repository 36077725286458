import { axiosInstance, Response } from './../config';
import { id } from '../../types/general';

interface Params {
	sportId?: string;
	providerId?: string;
	categoryId?: string;
	tournamentId?: string;
	live?: boolean;
	utcDatetimeStart?: Date;
	utcDatetimeEnd?: Date;
}
interface PlayersParams {
	localParentId: string;
	specialTournamentId: string;
	playersIds: string[];
}

export const events = {
	getEvents({
		sportId = '',
		providerId,
		categoryId = '',
		tournamentId = '',
		live = false,
		utcDatetimeStart,
		utcDatetimeEnd,
	}: Params): Response {
		return axiosInstance
			.get(`/mappings/data/events`, {
				params: {
					sportId,
					providerId,
					categoryId,
					tournamentId,
					live,
					utcDatetimeStart,
					utcDatetimeEnd,
				},
			})
			.then(({ data }) => data);
	},
	linkEvents(providerId: id, localItemId: id, providerItemId: id, isLive?: boolean) {
		return axiosInstance.post(`/mappings/map/${providerId}/data/events?live=${!!isLive}`, {
			localItemId: localItemId,
			providerItemId: providerItemId,
		});
	},
	unpairEvents(providerId: id, providerItemId: id, isLive: boolean = false) {
		const encodedId = encodeURIComponent(encodeURIComponent('' + providerItemId));
		return axiosInstance.delete(`/mappings/map/${providerId}/data/events/${encodedId}?live=${isLive}`);
	},
	importEvents(
		providerId: id,
		idProviderEvent: id,
		idTournament: string,
		idHome: string,
		idAway: string,
		isLive?: boolean,
		idSpecialTournament?: string | null
	) {
		return axiosInstance.post(
			`/mappings/import/${providerId}/data/events?live=${!!isLive}`,
			{ idProviderEvent, idTournament, idHome, idAway, idSpecialTournament },
			{
				headers: {
					'content-type': 'application/json',
				},
			}
		);
	},
	getPlanTournaments(planId: string): Response {
		return axiosInstance
			.get(`/administration/offerplans/tournaments/${planId}?structured=${true}`)
			.then(({ data }) => data);
	},

	getProviderEvents(providerId: id, params: any) {
		return axiosInstance
			.get(`/mappings/items/${providerId}/data/events`, {
				params,
			})
			.then(({ data }) => data);
	},

	pairNewPlayers(providerId: id, params: PlayersParams) {
		return axiosInstance.post(`/mappings/import/${providerId}/players`, params).then(({ data }) => data);
	},
};
