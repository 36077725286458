import React, { useState, FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ForceUserAuth } from '../../../../../pages/online-user/components/ForceUserAuth';
import { useRiskTag } from '../../../../../pages/online-user/components/useRiskTag';
import { deviceTypes } from '../../../../../pages/risk-management/controller/types';
import { formatDate } from '../../../../../utils/getDate';
import { Switch } from '../../../../general/Switch';
import { useTicket } from '../../context/TicketContext';
import { TicketChanges } from '..';
import { CashoutStatuses } from '../cashout/CashoutStatuses';
import { ForceLocationAuth } from '../actions/ForceLocationAuth';
import { isEmpty } from 'lodash';
import { CopyWrapper } from '../../../../general/CopyWrapper';
import { useNotify } from '../../../../../hooks/useNotifications';
import api from '../../../../../api';
import { useCopyToClipboard } from '../../../../../hooks/useCopyToClipboard';
import { useUser } from '../../../../../context/UserProvider';

interface Props {
	showEvents: 'showAuthorizedEvents' | 'showOriginalEvents' | 'showDiff';
}

export const TicketHeader: FC<Props> = ({ showEvents }) => {
	const { t } = useTranslation();
	const { warn } = useNotify();
	const { copy } = useCopyToClipboard();
	const { roles } = useUser();

	const { ticket, screen, similarTicket, similarTicketShown, showSimilarTicket } = useTicket();
	const {
		id,
		live,
		barcode,
		deviceInfo,
		systems,
		dtPlaced,
		dtRequested,
		idUser,
		idLocation,
		riskTag,
		riskValue,
		fullUserInfo,
		manualAuthorization,
		authStatus,
		similarityValue,
	} = ticket;
	const systemsPreview = systems
		.map(({ required, events }, i) => `S${i + 1}: ${required.sort((a, b) => a - b).join('|')}/${events.length}`)
		.join('  ');
	const similarTicketSystemsPreview = (similarTicket?.systems || [])
		.map(({ required, events }, i) => `S${i + 1}: ${required.sort((a, b) => a - b).join('|')}/${events.length}`)
		.join('  ');
	const [date, time] = formatDate(dtPlaced || dtRequested, 'date.month', 'hours:minutes:seconds');
	let [similarTicketDate, similarTicketTime] = similarTicket
		? formatDate(similarTicket!.dtPlaced || similarTicket!.dtRequested, 'date.month', 'hours:minutes:seconds')
		: ['', ''];

	const tag = useRiskTag(riskTag) || null;

	//#region PIN Code

	const [toggleButton, setToggleButton] = useState(false);
	const [pinCode, setPinCode] = useState(null);
	const [pinCodeRole] = useState(roles && roles['support'].includes('TICKET_PIN_READ'));

	//#region Promotion PIN Code
	const [togglePromotionPinButton, setPromotionPinToggleButton] = useState(false);
	const [promotionPinCode, setPromotionPinCode] = useState<string[] | null>(null);

	const [isDisabled, setIsDisabled] = useState(false);

	const toggle = async () => {
		if (!pinCode) {
			const pin = await getPinCode(ticket.barcode ?? '');
			setPinCode(pin);
		}
		setToggleButton((toggleButton) => !toggleButton);
	};
	const copyValue = () => {
		copy(pinCode || '');
	};

	const getPinCode = async (ticketId: string) => {
		try {
			if (!toggleButton) {
				setIsDisabled(true);
				return await api.tickets.getTicketPinCode(ticketId);
			}
		} catch (err: any) {
			console.log(err);
			const errorText =
				err?.response?.data.detail?.[0]?.msg ||
				err?.response?.data?.detail ||
				err?.detail ||
				t('toast.error.defaultText');
			warn(errorText);
		} finally {
			setIsDisabled(false);
		}
	};

	const togglePromotion = async () => {
		if (!promotionPinCode) {
			const promotionPin = await getPromotionPinCode(ticket.id ?? []);
			setPromotionPinCode(promotionPin);
		}
		setPromotionPinToggleButton((togglePromotionPinButton) => !togglePromotionPinButton);
	};

	const copyValuePromotion = (index: number) => {
		if (!!promotionPinCode) {
			copy(promotionPinCode[index] || '');
		}
	};

	const getPromotionPinCode = async (ticketId: string) => {
		try {
			if (!togglePromotionPinButton) {
				setIsDisabled(true);
				return await api.tickets.getTicketPromotionPinCode(ticketId);
			}
		} catch (err: any) {
			console.log(err);
			const errorText =
				err?.response?.data.detail?.[0]?.msg ||
				err?.response?.data?.detail ||
				err?.detail ||
				t('toast.error.defaultText');
			warn(errorText);
		} finally {
			setIsDisabled(false);
		}
	};

	//#endregion

	return (
		<div className={`ticket_header z_index_mobile ${togglePromotionPinButton ? 'height_400' : ''}  z_11`}>
			<div className="ticket_info_item">
				<div className="label">{t('reports.tickets.ticket')}:</div>
				<div className="content">
					{barcode}

					{live ? (
						<svg className="svg_icon live font_18px margin_left_10">
							<use xlinkHref="/assets/svg/sprite_mb.svg#live_ico"></use>
						</svg>
					) : null}

					{deviceInfo ? <small>/ {deviceTypes[deviceInfo.device_type]}</small> : <small>WEB</small>}
					<div className="status_item margin_left_20">
						<div className="flex_column">
							{tag ? (
								<div
									className="status_dot green tooltip_right margin_right_20"
									style={{ backgroundColor: tag.color }}
								>
									<span className="tooltip_content">{tag.name}</span>
								</div>
							) : null}
						</div>
						<div className="flex_column">
							{riskValue ? (
								<span className="status_label">
									{`${t('reports.tickets.riskLvl')} =  ${riskValue}`}{' '}
									{similarTicketShown ? (
										<span className="similar_ticket_field"> | {similarTicket?.riskValue}</span>
									) : (
										''
									)}
								</span>
							) : manualAuthorization ? (
								<i className="mdi mdi-alpha-m-circle orange_text tooltip_right font_20px">
									<span className="tooltip_content font_400 orange">{t('reports.tickets.manualAuth')}</span>
								</i>
							) : authStatus !== null ? (
								<i className="mdi mdi-alpha-f-circle redish_text tooltip_right font_20px">
									<span className="tooltip_content font_400 redish">{t('reports.tickets.forceAuth')}</span>
								</i>
							) : null}
						</div>
						<div className="flex_column">
							{similarityValue ? (
								<span className="status_label">
									{`${t('reports.tickets.similarityValue')} = ${similarityValue}`}
								</span>
							) : null}
						</div>
					</div>
				</div>
			</div>

			{!isEmpty(similarTicket) && showEvents !== 'showDiff' ? (
				<div className="ticket_info_item margin_top_5">
					<div className="label margin_bottom_5">
						{`${t('reports.tickets.similarTicket')}: `}{' '}
						<div className="margin_left_15 font_700">
							<CopyWrapper value={similarTicket?.barcode || ''} />
						</div>
					</div>
					<div className="content">
						<Switch
							checked={similarTicketShown}
							toggle={showSimilarTicket}
							name={t('reports.tickets.similarTicket')}
							onLabel={t('general.shown')}
							offLabel={t('general.hidden')}
							className={`${!similarTicket ? 'disabled' : ''}`}
						/>
					</div>
				</div>
			) : null}

			{screen === 'tickets' || screen === 'cashout-offer' ? <TicketChanges /> : <CashoutStatuses />}

			<div className="ticket_info_item">
				<div className="label">{t('reports.tickets.system')}:</div>
				<div className="content">
					{systemsPreview}
					{similarTicketShown ? (
						<span className="similar_ticket_field"> | {similarTicketSystemsPreview}</span>
					) : null}
				</div>
			</div>

			<div className="ticket_info_item">
				<div className="label">{t('general.dateAndTime')}:</div>
				<div className="content">
					{`${date} | ${time}`}{' '}
					{similarTicketShown ? (
						<span className="similar_ticket_field margin_left_10">
							{' '}
							{`${similarTicketDate} | ${similarTicketTime}`}{' '}
						</span>
					) : null}{' '}
				</div>
			</div>
			{screen === 'tickets' ? (
				<>
					{idUser && fullUserInfo?.uid ? <ForceUserAuth userUid={fullUserInfo.uid} /> : null}
					{idLocation && !idUser ? <ForceLocationAuth idLocation={idLocation} /> : null}
				</>
			) : null}

			{/* Ticket PIN */}
			{pinCodeRole ? (
				<div className="ticket_info_item">
					<div className="label">{t('general.pinCode')}:</div>
					<div className="content margin_top_5">
						<button
							style={{ marginLeft: '0px' }}
							className={`btn sm_32 outline,  ${!toggleButton ? 'blue' : 'red'} ${isDisabled ? 'disabled' : ''}`}
							onClick={toggle}
						>
							{!toggleButton ? <>{t('general.show')}</> : <>{t('general.hide')}</>}
						</button>
						{toggleButton && pinCode ? (
							<>
								{`| ${pinCode}`}{' '}
								<i
									style={{ color: '#6e7884', fontWeight: 'normal' }}
									className="mdi mdi-content-copy copy accordion_switch_11 tooltip_right margin_left_10"
									onClick={() => copyValue()}
								>
									<span className="tooltip_content blueish">{t('general.copy')}</span>
								</i>
							</>
						) : (
							''
						)}
					</div>
				</div>
			) : null}

			{/* Promotion PIN */}
			{pinCodeRole ? (
				<div className="ticket_info_item" style={{ position: 'relative' }}>
					<div className="label">{t('general.promotionPinCode')}:</div>
					<div className="content margin_top_5">
						<button
							style={{ marginLeft: '0px' }}
							className={`btn sm_32 outline,  ${!togglePromotionPinButton ? 'blue' : 'red'} ${
								isDisabled ? 'disabled' : ''
							}`}
							onClick={togglePromotion}
						>
							{!togglePromotionPinButton ? <>{t('general.show')}</> : <>{t('general.hide')}</>}
						</button>
						<div className="promotion-dropdown">
							{togglePromotionPinButton && promotionPinCode
								? promotionPinCode.map((pin, index) => (
										<div key={pin} className="promotion-dropdown-list">
											<div>
												{`${pin}`}
												<i
													style={{ color: '#6e7884', fontWeight: 'normal' }}
													className="mdi mdi-content-copy copy accordion_switch_11 tooltip_left margin_left_10"
													onClick={() => copyValuePromotion(index)}
												></i>
											</div>
										</div>
								  ))
								: ''}
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};
