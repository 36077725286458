import React, { useEffect, ReactNode, useState } from 'react';
import { useLive } from '../../pages/administration/live/context';
import { useLayout } from './LayoutContext';

interface Props {
	size?: 'f_small' | 'f_normal' | 'f_large';
	panel?: 'medium' | 'large';
	from?: number;
	to?: number;
	total?: number;
	setTotal?: (total: number) => void;
	prev: () => void;
	next: () => void;
	pages?: number;
	pageNumbers: number[];
	currentPage: number;
	selectPage: (page: number) => void;
	children?: ReactNode;
	numberOfItems?: ReactNode;
	removeSize?: boolean;
	disabledDropdown?: boolean;
}

export default function PageFooter({
	size = 'f_small',
	panel = 'large',
	prev,
	next,
	pageNumbers,
	currentPage,
	selectPage,
	numberOfItems,
	removeSize = true,
	total,
	children,
	disabledDropdown,
}: Props) {
	const { panelToggled, setFooterSize } = useLayout();
	const [isAndroid, setIsAndroid] = useState(true);
	const { loading } = useLive();

	// Set footer class ( 'f_small' | 'f_normal' | 'f_large' ) on the content_wrapper
	useEffect(() => {
		//const getMobileOS = () => {
		const iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
		if (iOS) setIsAndroid(false);

		//};
		setFooterSize(size);
		return () => {
			if (removeSize) setFooterSize('');
		};
	}, []);

	// Set fotter classes based on the the size and the state of the right panel
	const panelClass = panel === 'medium' ? 'offer_plan_toggle' : 'right_pannel_toggle';
	const toggleClass = panelToggled ? panelClass : '';

	if (!pageNumbers.length && !children) return null;
	return (
		<div
			className={`main_footer ${size} ${toggleClass} x_scroll_footer ${
				disabledDropdown || loading ? 'disabled' : ''
			}`}
		>
			{children}
			{(total != null && (
				<div className="pagination_wrap margin_right_auto padding_left_20 left">Total: {total}</div>
			)) ||
				null}
			{pageNumbers.length ? (
				<div className="pagination_wrap margin_left_auto padding_right_20 right">
					{/* Previous page */}
					{numberOfItems}
					<div className="pag_item" onClick={prev}>
						<i className="mdi mdi-chevron-left" />
					</div>

					{/* List of pages */}
					{pageNumbers.map((page, i) => {
						const selected = page === currentPage ? ' selected' : '';

						if (!page) return '...';
						return (
							<div key={`pag_${i}`} className={`pag_item${selected}`} onClick={() => selectPage(page)}>
								{page}
							</div>
						);
					})}

					{/* Next page */}
					<div className="pag_item" onClick={next}>
						<i className="mdi mdi-chevron-right" />
					</div>
				</div>
			) : null}
			{isAndroid ? (
				<div className="hide_desktop" style={{ width: '100%', height: '50px' }}></div>
			) : (
				<div className="hide_desktop" style={{ width: '100%', height: '110px' }}></div>
			)}
		</div>
	);
}
