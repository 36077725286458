import { axiosInstance } from './../config';
import { EventItemExtended } from '../../components/Events/Modal/types';
import { NewEvent, NewEvents } from '../../pages/OffersPlan/types';

interface Params {
	offerPlanId?: string;
	offset?: number;
	limit?: number;
	orderBy: string;
	q: string;
	localisation?: string;
	eventStatus?: string[];
	sportId: string | null;
	categoryId?: string;
	tournamentId?: string;
	date_from?: string | string[];
	date_to?: string | string[];
	oddsType: string[];
	providerId?: string;
	hasSpecials?: boolean;
	timezone?: string;
}

interface GlobalParams {
	sportId?: string;
	categoryId?: string;
	tournamentId?: string;
	orderBy?: string;
	q?: string;
	offset?: number;
	limit?: number;
	filter_date?: string;
	eventIds?: string;
	timezone?: string;
}

export type EventInfoAction =
	| 'EVENT_CREATED'
	| 'EVENT_UPDATED'
	| 'EVENT_PLAN_ADDED'
	| 'EVENT_PLAN_REMOVED'
	| 'EVENT_START_UPDATED'
	| 'EVENT_UNPAIRED'
	| 'EVENT_STATUS_UPDATED';

export const events = {
	getGlobalEvents(params: GlobalParams) {
		return axiosInstance.get(`/administration/events/`, { params }).then((res) => res.data);
	},

	getPrematchEvents(params: Params) {
		return axiosInstance.get(`/administration/events/filtered_events`, { params }).then((res) => res.data);
	},

	getSingleEvent(eventId: string) {
		return axiosInstance.get(`/administration/events/${eventId}`).then((res) => res.data);
	},
	statuses(): Promise<string[]> {
		return axiosInstance.get(`/administration/events/statuses/`).then((res) => res.data);
	},
	getEventInfo(eventId: string, action?: EventInfoAction[]) {
		return axiosInstance
			.get(`/administration/events/event_info/${eventId}`, { params: { action } })
			.then((res) => res.data);
	},

	getEvents({
		planId = '',
		extensionId = '',
		idSport,
		idOddTypes,
		q = '',
		orderBy = 'unset',
		offset = 0,
		limit = 10,
	}: {
		planId?: string;
		extensionId?: string;
		idSport: string;
		idOddTypes: string[];
		q?: string;
		orderBy?: string;
		offset?: number;
		limit?: number;
	}) {
		return axiosInstance
			.get(`/administration/offerplans/events/${planId}`, {
				params: { extensionId, idSport, idOddTypes, q, orderBy, offset, limit },
			})
			.then((res) => res.data);
	},
	addEvent(planId: string, extensionId: string = '', eventId: string, isAntepost: boolean) {
		return axiosInstance
			.post(`/administration/offerplans/events/${planId}?extensionId=${extensionId}`, {
				eventId: eventId,
				isAntepost: isAntepost,
			})
			.then((res) => {
				return res.data;
			});
	},
	addEventManually(event: NewEvent) {
		return axiosInstance.post(`/administration/events/create_event_manually/`, event).then((res) => {
			return res.data;
		});
	},
	addEventsManually(events: NewEvents) {
		return axiosInstance.post(`/administration/events/create_events_manually/`, events).then((res) => {
			return res.data;
		});
	},
	updateEvent(eventId: string, event: EventItemExtended) {
		return axiosInstance
			.put(`/administration/events/${eventId}`, {
				...event,
				blockCombinationTournaments: event.blockCombinationTournaments.map((item) => item.id),
				blockCombinationEvents: event.blockCombinationEvents.map((item) => item.id),
			})
			.then((res) => {
				return res.data;
			});
	},

	updateEventPartial(eventId: string, event: any) {
		return axiosInstance
			.put(`/administration/events/${eventId}`, {
				...event,
			})
			.then((res) => {
				return res.data;
			});
	},

	editEvent(planId: string, extensionId: string = '', eventId: string, isAntepost: boolean) {
		return axiosInstance
			.put(`/administration/offerplans/events/${planId}?extensionId=${extensionId}`, {
				eventId: eventId,
				isAntepost: isAntepost,
			})
			.then((res) => {
				return res.data;
			});
	},

	addAllEvents(planId: string, extensionId: string = '') {
		return axiosInstance
			.post(`/administration/offerplans/events/all/${planId}?extensionId=${extensionId}`)
			.then((res) => {
				return res.data;
			});
	},
	removeEvent(planId: string, extensionId: string = '', eventId: string, isAntepost: boolean) {
		return axiosInstance
			.delete(`/administration/offerplans/events/${planId}?extensionId=${extensionId}`, {
				data: {
					eventId: eventId,
					isAntepost: isAntepost,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	removeAllEvents(planId: string, extensionId: string = '') {
		return axiosInstance
			.delete(`/administration/offerplans/events/all/${planId}?extensionId=${extensionId}`)
			.then((res) => {
				return res.data;
			});
	},

	toggleActive(eventId: string, active: boolean) {
		return axiosInstance.put(`/administration/events/event/${eventId}/status?eventStatus=${active}`).then((res) => {
			return res.data;
		});
	},
	toggleTopOffer(eventId: string, topOffer: boolean) {
		return axiosInstance
			.post(`/administration/events/change_top_offer/${eventId}/?topOffer=${topOffer}`)
			.then((res) => {
				return res.data;
			});
	},

	getEventsLoads(params: any) {
		return axiosInstance.post(`/loads/events`, { ...params }).then((res) => res.data);
	},
};
