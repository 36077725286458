import { axiosInstance } from './../config';

interface IParams {
	idBackofficeUser: string;
	dtFrom?: string | Date;
	dtTo?: string | Date;
	skip?: number;
	limit?: number;
	requestUrl: string;
}

export const logs = {
	getLogs(params: IParams) {
		return axiosInstance.get(`/backoffice_logs/`, { params }).then((res) => res.data);
	},
};
