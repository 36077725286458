import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	editing: boolean;
	copy: () => void;
	data_type: string;
	descriptionShown: boolean;
	edit: (val: boolean) => void;
	showDescription: (val: boolean) => void;
}

export const GeoActions: FC<Props> = ({ descriptionShown, showDescription, data_type, copy, edit, editing }) => {
	const { t } = useTranslation();

	return (
		<div className="info">
			<i
				className="
               mdi mdi-information-outline
               info
               margin_right_6
               tooltip_left
            "
				onClick={() => showDescription(!descriptionShown)}
			>
				<span className="tooltip_content orange">{t('general.info')}</span>
			</i>

			{data_type !== 'bool' && data_type !== 'select' && data_type !== 'multi_select' ? (
				<i className="mdi mdi-content-copy copy accordion_switch_11 tooltip_left" onClick={() => copy()}>
					<span className="tooltip_content blueish">{t('general.copy')}</span>
				</i>
			) : null}

			{data_type === 'CSV' || data_type === 'BET_MEDIA' ? (
				<i className="mdi mdi-pencil edit tooltip_left" onClick={() => edit(!editing)}>
					<span className="tooltip_content green">{t('general.edit')}</span>
				</i>
			) : null}
		</div>
	);
};
