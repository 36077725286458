import { TransactionPayload } from '../../pages/online-user/components/transactions/types';
import { axiosInstance } from './../config';
import { geoAxios } from '../geo';

interface Params {
	email?: string;
	skip?: number;
	limit?: number;
	inFrom?: number | string | null;
	inTo?: number | string | null;
	outFrom?: number | string | null;
	outTo?: number | string | null;
	locations?: string[] | string | null;
	accountTypes?: string[] | string;
	transactionType?: string[] | string | null;
	transactionSubtype?: string[] | string | null;
	transactionIssuer?: string[] | string | null;
	externalProviders?: string[] | string | null;
	externalProviderApps?: string[] | string | null;
	dateFrom?: string | string[] | null;
	dateTo?: string | string[] | null;
	sortBy: string[];
	is_user_preview: boolean;
	transactionId: string | null;
	excludeZeroTransactions: boolean;
	timezone: string;
}

interface UserTransactionsProps {
	userUuid: string;
	skip: number;
	limit: number;
	internet: boolean;
	freeBet: boolean;
	dateFrom: string | null;
	dateTo: string | null;
	inOut: boolean;
	tickets: boolean;
	casino: boolean;
	bonus: boolean;
}

export const transactions = {
	getTransactions({
		email = '',
		limit,
		locations,
		transactionType,
		transactionSubtype,
		externalProviders,
		transactionIssuer,
		accountTypes,
		externalProviderApps = null,
		skip,
		inFrom,
		inTo,
		outFrom,
		outTo,
		dateFrom = null,
		dateTo = null,
		sortBy,
		is_user_preview,
		transactionId,
		excludeZeroTransactions,
		timezone,
	}: Params) {
		return axiosInstance
			.post('/administration/transactions/filter_transactions/', {
				user: email,
				accountTypes,
				locations,
				transactionType,
				transactionSubtype,
				transactionIssuer,
				externalProviders,
				externalProviderApps,
				in_amount_from: inFrom,
				in_amount_to: inTo,
				out_amount_from: outFrom,
				out_amount_to: outTo,
				date_from: dateFrom,
				date_to: dateTo,
				limit,
				skip,
				sortBy,
				isUserPreview: is_user_preview,
				transactionId,
				excludeZeroTransactions,
				timezone,
			})
			.then((res) => res.data);
	},

	getTransactionsFilters() {
		return axiosInstance.get(`/administration/transactions/transaction_filters/`).then((res) => res.data);
	},
	getLocations() {
		return geoAxios.get(`geo/?geoType=LOCATION`).then((res) => {
			return res.data;
		});
	},
	getUserByEmail(email: string) {
		const encodedEmail = encodeURIComponent(email);
		return axiosInstance
			.get(`/administration/transactions/user_transactions_summary/?user_email=${encodedEmail}`)
			.then((res) => res.data);
	},

	getUserTransactions(params: UserTransactionsProps): Promise<TransactionPayload> {
		return axiosInstance.get('/administration/transactions/user_transactions/', { params }).then((res) => res.data);
	},
};
