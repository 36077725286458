import { axiosInstance } from '../config';
import {
	AffiliatePartner,
	AfiliatePartners,
	Campaign,
	CampaingType,
	AffiliateUsers,
	AffiliateUsersData,
} from '../../pages/internet/referral-affiliate/types';

export const affiliate = {
	// Partner
	getAffiliatePartners(idCompany: string, isDeleted: null | boolean): Promise<AfiliatePartners[]> {
		if (isDeleted === false) {
			return axiosInstance
				.get(`/affiliate/partners/?idCompany=${idCompany}&isDeleted=${isDeleted}`)
				.then((res) => res.data);
		} else {
			return axiosInstance.get(`/affiliate/partners/?idCompany=${idCompany}`).then((res) => res.data);
		}
	},

	getAffiliatePartner(id_affiliate_partner: string): Promise<{ data: AfiliatePartners }> {
		return axiosInstance.get(`/affiliate/partner/${id_affiliate_partner}/`).then((res) => res.data);
	},

	createOrUpdateAffilliatePartner(affiliatePartner: AffiliatePartner): Promise<{ data: AffiliatePartner }> {
		return axiosInstance.post('/affiliate/partner/', affiliatePartner).then((res) => res.data);
	},

	deleteAffiliatePartner(id: string) {
		return axiosInstance.delete(`/affiliate/partner/${id}/`).then((res) => res.data);
	},

	// Campaign
	getCampaigns(idAffiliatePartner: string, isDeleted: null | boolean): Promise<Campaign[]> {
		if (isDeleted === false) {
			return axiosInstance
				.get(`/affiliate/campaigns/?idAffiliatePartner=${idAffiliatePartner}&isDeleted=${isDeleted}`)
				.then((res) => res.data);
		} else
			return axiosInstance
				.get(`/affiliate/campaigns/?idAffiliatePartner=${idAffiliatePartner}`)
				.then((res) => res.data);
	},

	getCampaign(idCampaign: string): Promise<CampaingType> {
		return axiosInstance.get(`/affiliate/campaign/${idCampaign}/`).then((res) => res.data);
	},

	createOrUpdateCampaign(campaign: Campaign): Promise<{ campaign: Campaign }> {
		return axiosInstance.post('/affiliate/campaign/', campaign).then((res) => res.data);
	},

	deleteCampaign(id: string) {
		return axiosInstance.delete(`/affiliate/campaign/${id}/`).then((res) => res.data);
	},

	getUsers(users: AffiliateUsers): Promise<{ total: number; affiliateCampaignUsers: AffiliateUsersData[] }> {
		return axiosInstance.post('/affiliate/users/', users).then((res) => res.data);
	},
};
