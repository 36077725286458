import React from 'react';
import { Context } from '../types';

interface Props {
	context: Context;
	handleChange: (ctx: Context) => void;
	levelClass?: string;
}

export default ({ context, handleChange, levelClass }: Props) => {
	// Destructure properties from context and item
	const { item, isActive, isExpanded } = context;
	const {
		name,
		description,
		label,
		icon,
		expandable,
		activeIcon,
		expandIcon,
		contractIcon,
		loading,
		error,
		email,
		tag,
		color,
		firstName,
		lastName,
		id,
		affiliate,
	} = item;
	// Class for selected non-expandable item
	const activeClass = isActive ? 'tree_item_cont active' : 'tree_item_cont';
	const disabledClass = loading ? 'disabled' : '';

	// Set expand icon based on the expanded state (provide fallback for contractIcon)
	const expandIconClass = isExpanded ? contractIcon || expandIcon : expandIcon;

	// Set main icon based on the active state (provide fallback for activeIcon)
	const iconClass = isActive ? activeIcon || icon : icon;

	// If handleChange is not provided set action to toggle() otherwise pass target name to handleChange()
	const action = (target: string) => {
		return () => {
			handleChange({ ...context, target });
		};
	};

	const dataQA =
		item.type === 'sport' ? 'level_first_item' : item.type === 'category' ? 'level_second_item' : 'level_third_item';

	// const tooltipClass = description || description === '' || firstName ? 'tooltip' : '';
	const tooltipClass = description ? 'tooltip_right' : firstName ? 'tooltip_bottom' : '';

	return (
		<div className={`${activeClass} ${disabledClass} ${tooltipClass}`}>
			{/* Render main icon if provided */}
			{expandable && (
				<div data-qa={levelClass} className="tree_item_ico" onClick={action('expand')}>
					<i className={`mdi mdi-${expandIconClass}`} />
				</div>
			)}
			{!(item.type === 'sport' && !isExpanded) && icon && (
				<div data-qa={dataQA} className="tree_item_ico" onClick={action('icon')}>
					<i className={`mdi mdi-${iconClass}`} />
				</div>
			)}
			{/* Render name of the item */}
			{name && (
				<div className="tree_label" onClick={action('name')}>
					{name}
				</div>
			)}
			{color && (
				<div className="status_dot green  margin_right_5" style={{ backgroundColor: color }}>
					{/* <span className="tooltip_content">{tag}</span> */}
				</div>
			)}
			{/* Render email of the item */}
			{email && (
				<div className="tree_label margin_bottom_2" onClick={action('email')}>
					{email}
				</div>
			)}

			{/* Affilliate */}

			{firstName && lastName && !description && (
				<div className="tree_label margin_bottom_2">{firstName + ' ' + lastName}</div>
			)}

			{/* Render description of the item */}
			{description && <span className={`tooltip_content blueish`}>{description}</span>}
			{firstName && !affiliate && (
				<span className={`tooltip_content blueish`}>
					{id ? `ID - ${id}` : ''}
					<br />
					{'NAME - ' + firstName + ' ' + lastName}
					{tag && (
						<>
							<br />
							TAG - {tag}
						</>
					)}
				</span>
			)}

			{/* Render loader */}
			{loading && (
				<div className="tree_item_ico">
					<i className="mdi mdi-rotate-right mdi-spin" />
				</div>
			)}
			{/* Render error */}
			{error && (
				<div className="tree_item_ico tooltip_left">
					<i className="mdi mdi-progress-alert text_red" />
					<span className="tooltip_content red">No Data</span>
					<div />
				</div>
			)}
			{/* Render label */}
			{label && <div className="events_no">{label}</div>}
		</div>
	);
};
