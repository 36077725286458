import { isEmpty } from 'lodash';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LockedDeposits } from '../types';
import { TableRow } from './table/TableRow';
import TableHeader from './table/TableHeader';
import NotificationModal from '../../../../../components/general/NotificationModal';
import Delete from '../../../../../utils/svg/Delete';
import api from '../../../../../api';
import { useNotify } from '../../../../../hooks/useNotifications';
import { useOnlineUser } from '../../../context/OnlineUserContext';
import LockPlan from '../../../../../utils/svg/LockPlan';
import Unlock from '../../../../../utils/svg/Unlock';
import { toMoneyFormat } from '../../../../../utils/toFixed';

interface Props {
	refetchWalletBalance: () => void;
	deposits: LockedDeposits;
}

export const Deposits: FC<Props> = ({ deposits, refetchWalletBalance }) => {
	const {
		onlineUser: { uid },
	} = useOnlineUser();

	const { success, warn } = useNotify();
	const { t } = useTranslation();

	const [depositId, setDepositId] = useState<number | null>(null);

	const [confirmModal, setConfirmModal] = useState(false);

	const [disabledModal, setDisabledModal] = useState(false);

	const openConfirmModal = (depositId: number) => {
		setDepositId(depositId);
		setConfirmModal(true);
	};

	const closeConfirmModal = () => {
		setDepositId(null);
		setConfirmModal(false);
	};
	const unlockDeposit = async () => {
		if (depositId === null) return;
		try {
			setDisabledModal(true);
			await api.users.unlockDeposit(uid, depositId);

			refetchWalletBalance();
			success(t('toast.success.depositUnlocked'));
		} catch {
			warn(t('toast.warn.depositUnlockFailed'));
		} finally {
			closeConfirmModal();
			setDisabledModal(false);
		}
	};

	return (
		<div className="col-6 padding_20">
			<h3 className="title text_left col-12 padding_right_10">{t('usersAdministrations.lockedDeposits')}</h3>
			<div
				className="flex_direction_row col-12"
				style={{
					height: 'fit-content',
					maxHeight: '400px',
					overflowY: 'auto',
					marginTop: '12px',
					width: 'fit-content',
				}}
			>
				<table className="admin_table sticky_header full_w">
					{/* Table Header */}
					<TableHeader />

					{/* Table Body */}
					{!isEmpty(deposits) ? (
						<tbody className="t_body">
							{deposits.lockedDeposits?.map((deposit) => (
								<>
									<TableRow key={deposit.id} lockedDeposit={deposit} openConfirmModal={openConfirmModal} />
								</>
							))}
						</tbody>
					) : null}

					{/* Modal */}
					{confirmModal && (
						<NotificationModal
							opened={true}
							cancelText={t('general.cancel')}
							confirmText={t('general.confirm')}
							cancelAction={closeConfirmModal}
							confirmAction={unlockDeposit}
							loading={disabledModal}
						>
							<div className="context_icon margin_top_40">
								<Unlock />
							</div>
							<h4 className="modal_message capitalize text_center padding_10 font_13px">
								{t('usersAdministrations.confirmUnlockDeposit')}
							</h4>
						</NotificationModal>
					)}
				</table>
			</div>
			<h3 className="title text_right col-12 padding_right_10 margin_top_10 tooltip_right">
				{t('general.total')}: {toMoneyFormat(deposits.total)}{' '}
				<span className="tooltip_content">{t('usersAdministrations.totalAmount')}</span>
			</h3>
		</div>
	);
};
