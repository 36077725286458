import { CashoutStatus } from '../types';

export const defaultCashoutStatuses: CashoutStatus = {
	SENT: {
		color: 'blue',
		sign: 'S',
		text: 'SENT',
		active: true,
	},
	ACCEPTED: {
		color: 'green',
		sign: 'A',
		text: 'ACCEPTED',
		active: true,
	},
	DENIED: {
		color: 'red',
		sign: 'D',
		text: 'DENIED',
		active: true,
	},
	EXPIRED: {
		color: 'yellow',
		sign: 'E',
		text: 'EXPIRED',
		active: true,
	},
};

export const defaultTicketType: CashoutStatus = {
	REGULAR: {
		color: 'redish',
		sign: 'R',
		text: 'regular',
		active: true,
	},
	LIVE: {
		color: 'green',
		sign: 'L',
		text: 'live',
		active: true,
	},
};

export const CREATED = {
	ASC: 'createdAsc',
	DESC: 'createdDesc',
};

export const AUTHORISED = {
	ASC: 'authorisedAsc',
	DESC: 'authorisedDesc',
};
