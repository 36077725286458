import { CashFlowItem, CashFlowSumItem } from './../../pages/reports/cash-flow/types';
import { axiosInstance } from '../config';
import { fileNameFromHeaders } from '../../utils/getExportFileName';

interface Params {
	dateFrom: string | null;
	dateTo: string | null;
	idCompany: string | null;
	idRegion: string | null;
	idGroup: string | null;
	locationIds: string[] | null;
	showBy: string;
	isLive: boolean | null;
	providerIds: string[] | null;
	skip: number;
	limit: number;
	transactionTypes: string[] | null;
}
export const cashflow = {
	getCashflowItems(params: Params, version?: string): Promise<{ total: number; items: CashFlowItem[]; totalSums: CashFlowSumItem[] }> {
		return axiosInstance.post(`/reporting/cash_flow${version ?? ''}`, { ...params }).then((res) => res.data);
	},
	getCashflowItemsExport(params: Params, version?: string): Promise<any> {
		return axiosInstance.post(`/reporting/cash_flow/download${version ?? ''}`, { ...params }).then((res) => {
			return [fileNameFromHeaders(res.headers, 'cash-flow'), res.data];
		});
	},
};
