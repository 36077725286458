import React from 'react';
import { useTranslation } from 'react-i18next';

export const CustomOddHeader = () => {
	const { t } = useTranslation();

	return (
		<div
			className="checkbox_custom check_left light ltr_direction font_10px font_600"
			style={{ pointerEvents: 'none' }}
		>
			<div style={{ width: '24px' }}></div>
			<label className="left col-11">
				<span className="col-2">{t('general.name')}</span>
				<div className=" col-1">{t('general.sport')}</div>
				<span className="col-5 justify_center" title="Description">
					{t('general.description')}
				</span>
				<span className="col-1 justify_center" title="Prematch max">
					{t('limits.prematchMax')}
				</span>
				<span className="col-1 justify_center" title="Prematch max single">
					{t('limits.prematchMaxSingle')}
				</span>
				<span className="col-1 justify_center" title="Live max">
					{t('limits.liveMax')}
				</span>
				<span className="col-1 justify_center" title="Live max single">
					{t('limits.liveMaxSingle')}
				</span>
				<span className="col-1 justify_center" title="currency">
					{t('general.currency')}
				</span>
			</label>
		</div>
	);
};
