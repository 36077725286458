import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	competitors: { id: string; name: string }[];
}

export default ({ competitors }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="event_host margin_top_20" style={{ height: '150px' }}>
			<div className="labels">
				<div className="host_label">{t('general.home')}</div>
				<div className="guest_label">{t('general.away')}</div>
			</div>

			<div className="event_players dumb_target_swithc_host ">
				<div className="host"> {competitors?.[0]?.name || ''} </div>
				<span>{competitors?.[1] ? 'vs' : ''}</span>
				<div className="guest">{competitors?.[1]?.name || ''} </div>
			</div>
		</div>
	);
};
