import React, { FC, ChangeEvent, KeyboardEvent } from 'react';

type AmountHelper = {
	[key: string]: string;
};

const amountHelper: AmountHelper = {
	less: '<',
	equally: '=',
	more: '>',
};

interface Props {
	amount: string;
	selectedSign: string;
	selectSign: (sign: string) => void;
	setAmount: (amount: string) => void;
	placeholder: string;
	disabled: boolean;
	onEnter?: () => void;
	width?: string;
}

export const AmountFilter: FC<Props> = ({
	selectedSign,
	selectSign,
	amount,
	setAmount,
	placeholder,
	disabled,
	onEnter,
	width = '65px',
}) => {
	const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode !== 13 || !onEnter) return;
		onEnter();
	};

	const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const amountSign = e.target.value;
		selectSign(amountSign);
	};
	return (
		<div
			className={`input_form_group flex_direction_row w_radio_select margin_right_10 ${disabled ? 'disabled' : ''}`}
		>
			{Object.keys(amountHelper).map((item) => (
				<div key={`${item}-${placeholder}`} className="radio_button_group radio_select">
					<div className="radio_custom chek_only light tooltip_bottom ">
						<input
							type="radio"
							name={placeholder}
							id={placeholder}
							value={item}
							onChange={handleChange}
							checked={selectedSign === item}
						/>

						<span className="check_cover unchecked"> {amountHelper[item]}</span>
						<span className="check_cover checked"> {amountHelper[item]} </span>

						<span className="tooltip_content">{item}</span>
					</div>
				</div>
			))}

			<input
				className="payment_amount"
				style={{ width: width }}
				placeholder={placeholder}
				type="number"
				value={amount}
				disabled={disabled}
				onChange={(e) => setAmount(e.target.value)}
				onKeyUp={handleEnter}
			/>
		</div>
	);
};
