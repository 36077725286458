import React, { FC } from 'react';
import { Action, State } from './useModalFilters';
import Dropdown from '../../../../../../components/general/Dropdown';
import MultiSelectDropdown from '../../../../../../components/general/MultiSelectDropdown';
import { useTranslation } from 'react-i18next';

interface Props {
	filters: State;
	filtersList: any;
	dispatch: (action: Action) => void;
}

export const ModalHeaderFilters: FC<Props> = ({ filters, filtersList, dispatch }) => {
	const { t } = useTranslation();
	return (
		<>
			{Object.values(filters)
				.sort((a, b) => a.position - b.position)
				.map((filter, i) => {
					const { type, filterKey, value, displayName } = filter;
					switch (type) {
						case 'multi_select':
							return (
								<div key={i} className="margin_right_20">
									<MultiSelectDropdown
										title={displayName}
										options={filtersList[filterKey].map(([id, name]: string[]) => ({
											id,
											name,
										}))}
										selectedOptions={value}
										selectOption={(options) => dispatch({ type: filterKey, payload: options })}
										marginRight={'10'}
										withSearch
									/>
								</div>
							);
						case 'select':
							return (
								<div key={i} className="margin_right_20">
									<Dropdown
										items={[
											{ id: '', name: t('general.clearSelected') },
											...filtersList[filterKey].map(([id, name]: string[]) => ({
												id,
												name,
											})),
										]}
										selectedItem={value}
										fixedTitle={!value ? displayName : ''}
										selectItem={(id) => dispatch({ type: filterKey, payload: id })}
										margins={['0']}
										icon={'mdi mdi-menu-down'}
									/>
								</div>
							);
						case 'input':
							return (
								<div key={i} className="margin_right_20">
									<input
										id={filterKey}
										type="text"
										value={value}
										placeholder={displayName}
										className="normal focus col-10"
										onChange={(e) => dispatch({ type: filterKey, payload: e.currentTarget.value })}
									/>
								</div>
							);
						default:
							return null;
					}
				})}
		</>
	);
};
