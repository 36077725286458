import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import { formatDate } from '../../../../../utils/getDate';
import { toMoneyFormat } from '../../../../../utils/toFixed';
import { useOnlineUser } from '../../../context/OnlineUserContext';
import { PeriodSum } from '../types';

export const StatsBar: FC = () => {
	const {
		onlineUser: { uid = '' },
	} = useOnlineUser();
	const { t } = useTranslation();

	const [periods, setPeriods] = useState<PeriodSum[]>([]);
	//useState hook is used in case this sums page gets extended at some point to more time intervals
	const [queryPeriods, setQueryPeriods] = useState(['TODAY', 'LAST_WEEK', 'LAST_TWO_WEEKS', 'LAST_FOUR_WEEKS']);

	useEffect(() => {
		(async () => {
			const data = await api.users.getTicketSummary({
				user_uuid: uid || '',
				querySummaryPeriods: queryPeriods,
			});
			setPeriods(data);
		})();
	}, []);

	const today = {
		...(periods?.find(({ period }) => period === 'today') || {}),
		date: formatDate(dateInPast(0), 'date.month')[0],
		label: t('usersAdministrations.today'),
	};

	const lastWeek = {
		...(periods?.find(({ period }) => period === 'last_week') || {}),
		date: formatDate(dateInPast(7), 'date.month')[0],
		label: t('usersAdministrations.lastWeek'),
	};
	const last2Weeks = {
		...(periods?.find(({ period }) => period === 'last_two_weeks') || {}),
		date: formatDate(dateInPast(14), 'date.month')[0],
		label: t('usersAdministrations.last2Weeks'),
	};
	const last4Weeks = {
		...(periods?.find(({ period }) => period === 'last_four_weeks') || {}),
		date: formatDate(dateInPast(28), 'date.month')[0],
		label: t('usersAdministrations.last4Weeks'),
	};

	const periodsData = [today, lastWeek, last2Weeks, last4Weeks];

	function dateInPast(days: number) {
		const now = new Date();
		return new Date(now.setDate(now.getDate() - days)).toString();
	}
	return (
		<div className="status_row">
			<div className="col-4 border_divider">
				<div className="info_row">
					<div className="col-5"></div>
					<small className="col-4 info flex_justify_end gray_text">{t('general.payIn')}</small>
					<small className="col-2 info flex_justify_end gray_text">{t('usersAdministrations.tickets')}</small>
				</div>

				{periodsData.map(({ label = '', date = '', amountIn = 0, totalTickets = 0 }, index) => (
					<div className="info_row" key={index}>
						<div className="col-5 label">
							{label}:<small className="gray_text padding_left_5">{date ? `(${date})` : ''}</small>
						</div>
						<div className="col-4 info flex_justify_end">{toMoneyFormat(amountIn)}</div>
						<div className="col-2 info flex_justify_end">{totalTickets}</div>
					</div>
				))}
			</div>
			<div className="col-2 border_divider">
				<div className="info_row">
					<small className="col-6 info flex_justify_end gray_text">{t('general.livePayIn')}</small>
					<small className="col-3 info flex_justify_end gray_text">{t('usersAdministrations.tickets')}</small>
				</div>

				{periodsData.map(({ amountLivePayment = 0, livePaymentTickets = 0 }, index) => (
					<div className="info_row" key={index}>
						<div className="col-6 label flex_justify_end">{toMoneyFormat(amountLivePayment)}</div>
						<div className="col-3 info flex_justify_end">{livePaymentTickets}</div>
					</div>
				))}
			</div>
			<div className="col-2 border_divider">
				<div className="info_row">
					<small className="col-6 info flex_justify_end gray_text">{t('general.win')}</small>
					<small className="col-3 info flex_justify_end gray_text">{t('usersAdministrations.tickets')}</small>
				</div>
				{periodsData.map(({ amountOut = 0, wonTickets = 0 }, index) => (
					<div className="info_row" key={index}>
						<div className="col-6 label flex_justify_end">{toMoneyFormat(amountOut)}</div>
						<div className="col-3 info flex_justify_end">{wonTickets}</div>
					</div>
				))}
			</div>
			<div className="col-4 ">
				<div className="info_row">
					<small className="col-5 info flex_justify_end gray_text">{t('usersAdministrations.difference')}</small>
					<small className="col-4 info flex_justify_end gray_text">
						{t('usersAdministrations.averageEvents')}
					</small>
					<small className="col-2 info flex_justify_end gray_text">{t('usersAdministrations.averageOdds')}</small>
				</div>
				{periodsData.map(({ diff = 0, averageEventNumber = 0, averageMaxQuota = 0 }, index) => (
					<div className="info_row" key={index}>
						<div className="col-5 label flex_justify_end">{toMoneyFormat(diff)}</div>
						<div className="col-4 info flex_justify_end">{averageEventNumber.toFixed(1)}</div>
						<div className="col-2 info flex_justify_end">{toMoneyFormat(averageMaxQuota)}</div>
					</div>
				))}
			</div>
		</div>
	);
};
