import { useState, useEffect, useMemo } from 'react';
import { scrollTo } from '../utils/scrollTo';
import { useLocalStorage } from './useLocalStorage';
import { usePrevious } from './usePrevious';

interface Options {
	maxPages?: number;
	startIndex?: number;
}

export interface Output {
	from: number;
	to: number;
	total: number;
	setTotal: (total: number) => void;
	prev: () => void;
	next: () => void;
	pageNumbers: number[];
	currentPage: number;
	selectPage: (page: number) => void;
	perPage: number;
	setPerPage: (value: number) => void;
}

export default function usePagination(perPageDefault: number, options?: Options): Output {
	const { startIndex = 0, maxPages = 5 } = options || {};

	const [perPage, setPerPage] = useLocalStorage('itemsPerPage', perPageDefault);
	const [from, setFrom] = useState(startIndex);
	const [to, setTo] = useState(startIndex + perPage);
	const [total, setTotal] = useState<number>(0);

	const [pages, setPages] = useState<number[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(0);

	const prevTotal = usePrevious(total);

	useEffect(() => {
		if (typeof total !== 'number') return;

		if (prevTotal && prevTotal < total) {
			const numberOfPages = Math.ceil(total / perPage);

			const pages = [];

			for (let i = 1; i < numberOfPages + 1; i++) pages.push(i);

			setFrom((currentPage - 1) * perPage);
			setPages(pages);
		} else {
			const numberOfPages = Math.ceil(total / perPage);
			const current = Math.ceil(startIndex / perPage);
			const pages = [];

			for (let i = 1; i < numberOfPages + 1; i++) pages.push(i);

			setFrom(startIndex);
			setPages(pages);
			setCurrentPage(current || 1);
		}
	}, [total]);

	useEffect(() => {
		const numberOfPages = Math.ceil(total / perPage);
		const current = Math.ceil(startIndex / perPage);
		const pages = [];

		for (let i = 1; i < numberOfPages + 1; i++) pages.push(i);

		setFrom(startIndex);
		setPages(pages);
		setCurrentPage(current || 1);
	}, [perPage]);

	const pageNumbers = useMemo(() => truncate(pages, currentPage, maxPages), [currentPage, pages]);

	// Scroll to the top - to resolve problem with items table stay at the bottom
	useEffect(() => {
		scrollTo();
	}, [currentPage, pages, perPage, total]);

	const prev = () => {
		if (from - perPage < 0) return;

		setFrom(from - perPage);
		setTo(to - perPage);
		setCurrentPage(currentPage - 1);
	};

	const next = () => {
		if (from + perPage >= total) return;

		setFrom(from + perPage);
		setTo(to + perPage);
		setCurrentPage(currentPage + 1);
	};

	const selectPage = (page: number) => {
		const from = (page - 1) * perPage;
		const to = from + perPage;
		setFrom(from);
		setTo(to);
		setCurrentPage(page);
	};

	return { from, to, prev, next, total, setTotal, pageNumbers, currentPage, selectPage, perPage, setPerPage };
}

// Helper function for truncating pages array
const truncate = (arr: number[], current: number, size: number) => {
	if (arr.length <= size) return arr;
	let index = current - 1;

	const [first] = arr;
	const last = arr[arr.length - 1];

	if (index < 2) return [...arr.slice(0, size), 0, last];
	if (index + 2 >= arr.length) return [first, 0, ...arr.slice(arr.length - size, arr.length)];

	const start = index - 2;
	const middle = arr.slice(start, start + size);

	if (index + 3 >= arr.length) return [first, 0, ...middle];
	if (index < 3) return [...middle, 0, last];
	return [first, 0, ...middle, 0, last];
};
