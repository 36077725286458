import { useReducer } from 'react';

export interface State {
	[key: string]: { type: FilterType; position: number; displayName: string; value: any; filterKey: string };
}

export interface Action {
	type: string;
	payload: any;
}

export type FilterType = 'multi_select' | 'select' | 'input';

export function reducer(state: State, action: Action): State {
	const { type, payload } = action;
	return { ...state, [type]: { ...state[type], value: payload } };
}

export const useReferenceModalFilters = (defaultState: State) => {
	const [filters, dispatch] = useReducer(reducer, defaultState);

	const prepareFiltersForApi = () => {
		const data = {} as State;

		Object.keys(filters).forEach((key) => {
			const { type, value } = filters[key];
			data[key] = value ? (type === 'multi_select' ? formatMultiSelectArray(value) : value) : null;
		});

		return { ...data };
	};

	return { filters, dispatch, prepareFiltersForApi };
};

const formatMultiSelectArray = (value: string[]) => {
	if (!value.length) return null;
	else return value.filter((val) => val !== 'all');
};

export const prepareFiltersDefaultState = (filters: State): State => {
	let filtersState = {} as State;
	Object.keys(filters).forEach((key: string) => {
		const item = filters[key];
		filtersState[key] = { ...item, value: item.type === 'multi_select' ? [] : '', filterKey: key };
	});

	return { ...filtersState };
};
