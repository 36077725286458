import { useState } from 'react';
import { Item, Context } from './types';
import traverse from './traverse';

interface Settings {
	closeAll?: boolean;
	sortable?: boolean;
}

export default (settings: Settings = {}) => {
	// Destructure settings with default values
	const { closeAll = false, sortable = false } = settings;

	// Tree items
	const [items, updateItems] = useState<Item[]>([]);

	// State which holds expanded items
	const [expanded, updateExpanded] = useState<string[]>([]);

	// State which holds active items
	const [active, updateActive] = useState<string[]>([]);

	// Helper function for updating specific property of the item
	const updateProperty = (path: string, property: string, value: string | Item[] | boolean) => {
		const newData = [...items];
		const target = traverse(newData, path);
		target[property] = value;
		updateItems(newData);
	};

	// Generate context by binding path to provided methods
	// Each method on the context object returns context for chaining purposes
	const generateContext = (path: string, item: Item, parent: Context | null = null) => {
		const ctx = {
			// Return original item
			item,
			// Return path of the item (0.0.1)
			path,
			// Return parent context
			get parent(): Context {
				return parent || ctx;
			},
			// Return list of all tree items
			get items() {
				return items;
			},
			// Check is item expanded
			get isExpanded() {
				return expanded.includes(path);
			},
			// Add path to the expanded array
			expand: () => {
				updateExpanded([...expanded, path]);
				return ctx;
			},
			// If closeAll is not enabled, remove only provided path from expanded array, otherwise
			// remove each item from the expanded array which starts with the path (all children)
			contract: () => {
				if (!closeAll) updateExpanded(expanded.filter((expandedPath) => expandedPath !== path));
				else updateExpanded(expanded.filter((expandedPath) => !expandedPath.startsWith(path)));
				return ctx;
			},
			// If item is not expanded expand it, if it is contract it
			toggle: () => {
				ctx.isExpanded ? ctx.contract() : ctx.expand();
				return ctx;
			},
			// Check is items path in the expanded array
			get isActive() {
				return active.includes(item.id);
			},
			// Add path to active array or add it as only item
			activate: (only: boolean = true) => {
				only ? updateActive([item.id]) : updateActive([...active, item.id]);
				return ctx;
			},
			// Remove path from active array
			deactivate: () => {
				updateActive(active.filter((activeId) => activeId !== item.id));
				return ctx;
			},
			// Change name of the item
			setName: (name: string) => {
				updateProperty(path, 'name', name);
				return ctx;
			},
			// Change icon of the item
			setIcon: (icon: string) => {
				updateProperty(path, 'icon', icon);
				return ctx;
			},
			// Change items of the item
			setItems: (items: Item[]) => {
				updateProperty(path, 'items', items);
				return ctx;
			},
			// Remove the error and start the loader
			startLoader() {
				updateProperty(path, 'error', false);
				updateProperty(path, 'loading', true);
				return ctx;
			},
			// Remove the error and the loader
			stopLoader() {
				updateProperty(path, 'error', false);
				updateProperty(path, 'loading', false);
				return ctx;
			},
			// Remove the loader and set the error
			setError() {
				updateProperty(path, 'loading', false);
				updateProperty(path, 'error', true);
				return ctx;
			},
			setLabel(label: string) {
				updateProperty(path, 'label', label);
				return ctx;
			},
			// Update any property of an item
			updateProperty(property: string, value: string) {
				updateProperty(path, property, value);
				return ctx;
			},
		};
		return ctx;
	};

	// Options object for the tree and parent component
	const options = {
		items,
		updateItems(items: Item[]) {
			updateItems(items);
			return options;
		},
		expanded,
		updateExpanded(expanded: string[]) {
			updateExpanded(expanded);
			return options;
		},
		active,
		updateActive(active: string[]) {
			updateActive(active);
			return options;
		},
		closeAll,
		sortable,
		generateContext,
	};

	// Return the Tree component and options object
	return options;
};
