import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import Modal from '../../../../../components/Modals';
import { ImagePreview } from '../../../../../components/Modals/ImagePreview';
import { useNotify } from '../../../../../hooks/useNotifications';
import { VerificationData } from '../types';

interface Props {
	close: () => void;
	updateVerificationData: (data: VerificationData | null) => void;
	isIdVerification: boolean;
	uid: string;
}

export const AddDocuments: FC<Props> = ({ close, updateVerificationData, isIdVerification, uid }) => {
	const { t } = useTranslation();

	const [idCard, setIdCard] = useState<File | null>(null);
	const [idCardBack, setIdCardBack] = useState<File | null>(null);
	const [selfie, setSelfie] = useState<File | null>(null);

	const [previewUrl, setPreviewUrl] = useState<string | null>(null);

	const onFileChange = (e: ChangeEvent<HTMLInputElement>, setter: (file: File | null) => void) => {
		const file = e?.target?.files?.[0] || null;
		setter(file);
	};

	const { success, warn } = useNotify();

	const save = async () => {
		try {
			const data = await api.users.uploadVerificationData(uid, {
				front_facing_image: selfie,
				userIdBackImage: !isIdVerification ? idCard : idCardBack,
				userIdImage: idCard,
			});
			updateVerificationData(data);
			success(t('toast.success.dataUploaded'));
			close();
		} catch (err: any) {
			const errorText =
				err?.response?.data.detail?.[0]?.msg || err?.response?.data?.detail || t('toast.error.defaultText');
			warn(errorText);
		}
	};

	const saveButtonDisabled = isIdVerification
		? idCard === null || idCardBack === null || selfie === null
			? 'disabled'
			: ''
		: idCard === null || selfie === null
		? 'disabled'
		: '';
	return (
		<Modal
			opened={true}
			size={'lg'}
			align={'center'}
			direction={'column'}
			cancelAction={close}
			body={
				<div className="col-12 align_center padding_top_30 flex_column">
					<div className="flex_direction_row col-8 margin_top_10 align_center">
						<span className="col-5">
							{' '}
							{isIdVerification ? t('usersAdministrations.idImage') : t('usersAdministrations.passportImage')}
						</span>
						<div className="margin_left_10 align_center col-7">
							<input
								className="file_upload"
								type="file"
								accept="image/*"
								onChange={(event) => onFileChange(event, setIdCard)}
							/>
						</div>
						<i
							className={`mdi mdi-eye font_20px margin_left_10 `}
							onClick={() => {
								idCard && setPreviewUrl(URL.createObjectURL(idCard));
							}}
						></i>
					</div>
					{isIdVerification ? (
						<div className="flex_direction_row col-8 margin_top_10 align_center">
							<span className="col-5">{t('usersAdministrations.idBackImage')}</span>
							<div className="margin_left_10 align_center col-7">
								<input
									className="file_upload"
									type="file"
									accept="image/*"
									onChange={(event) => onFileChange(event, setIdCardBack)}
								/>
							</div>
							<i
								className={`mdi mdi-eye font_20px margin_left_10 `}
								onClick={() => {
									idCardBack && setPreviewUrl(URL.createObjectURL(idCardBack));
								}}
							></i>
						</div>
					) : null}

					<div className="flex_direction_row col-8 margin_top_10 align_center">
						<span className="col-5">{t('usersAdministrations.frontFacingImage')}</span>
						<div className="margin_left_10 align_center col-7">
							<input
								className="file_upload"
								type="file"
								accept="image/*"
								onChange={(event) => onFileChange(event, setSelfie)}
							/>
						</div>
						<i
							className={`mdi mdi-eye font_20px margin_left_10 `}
							onClick={() => {
								selfie && setPreviewUrl(URL.createObjectURL(selfie));
							}}
						></i>
					</div>
					{previewUrl ? <ImagePreview url={previewUrl} close={() => setPreviewUrl(null)} /> : null}
				</div>
			}
			footer={
				<Fragment>
					<button className="btn md round outline toggle_modal_12" onClick={close}>
						{t('general.cancel')}
					</button>
					<button className={`btn md round green toggle_modal_12 ${saveButtonDisabled}`} onClick={save}>
						{t('general.save')}
					</button>
				</Fragment>
			}
		/>
	);
};
