export const ODDS_CHANGE = 'odds_change';
export const BET_ODDS = 'bet_odds';
export const BET_STOP = 'bet_stop';
export const BET_RESULT = 'bet_result';
export const PREMATCH_BET_RESULT = 'prematch_bet_result';
export const NEW_EVENT = 'new_event';
export const EVENT_CHANGE = 'event_change';
export const EVENT_CHANGE_LIVE = 'event_change_live';
export const EVENT_CHANGE_PREMATCH = 'event_change_prematch';
export const OFFER_PLAN_UPDATE = 'offer_plan_update';
export const BONUS_ODDS_CHANGE = 'bonus_odds_change';
export const EVENT_RESULT = 'event_result';
export const PREMATCH_EVENT_RESULT = 'prematch_event_result';
export const ANNOUNCED_LIVE_EVENT = 'announced_live_event';
