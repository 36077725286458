import React, { useState, createContext, useContext } from 'react';

export interface ContextProps {
	sidebarToggled: boolean;
	toggleSidebar: () => void;
	panelToggled: boolean;
	togglePanel: () => void;
	footerSize: string;
	setFooterSize: (size: string) => void;
	sidebarOverContent: boolean;
	openSidebarOverContent: () => void;
	closeSidebarOverContent: () => void;
	openPanel: () => void;
	closePanel: () => void;
	closeSidebar: () => void;
}

export const ContextProps: ContextProps = {
	sidebarToggled: false,
	toggleSidebar: () => {},
	panelToggled: false,
	togglePanel: () => {},
	footerSize: '',
	setFooterSize: () => {},
	sidebarOverContent: false,
	openSidebarOverContent: () => {},
	closeSidebarOverContent: () => {},
	openPanel: () => {},
	closePanel: () => {},
	closeSidebar: () => {},
};

export const LayoutContext = createContext(ContextProps);

export const withLayoutContext = (Component: any) => (props: any) => {
	// Toggler of the left sidebar
	const [sidebarToggled, setSidebareToggled] = useState(false);

	const toggleSidebar = () => {
		setSidebareToggled(!sidebarToggled);
	};

	const closeSidebar = () => {
		setSidebareToggled(true);
	};

	// Boolean on which we determine does sidebar push main content or go over it
	const [sidebarOverContent, setSidebarOverContent] = useState(false);

	const openSidebarOverContent = () => {
		setSidebarOverContent(true);
	};

	const closeSidebarOverContent = () => {
		setSidebarOverContent(false);
	};

	// Toggler of the right panel
	const [panelToggled, setPanelToggled] = useState(false);

	const togglePanel = () => {
		setPanelToggled(!panelToggled);
	};
	const openPanel = () => {
		setPanelToggled(true);
	};
	const closePanel = () => {
		setPanelToggled(false);
	};

	// Footer options
	const [footerSize, setFooterSize] = useState('');

	// Context object
	const contextObject = {
		sidebarToggled,
		toggleSidebar,
		panelToggled,
		togglePanel,
		openPanel,
		closePanel,
		footerSize,
		setFooterSize,
		sidebarOverContent,
		openSidebarOverContent,
		closeSidebarOverContent,
		closeSidebar,
	};
	return <LayoutContext.Provider value={contextObject} children={<Component {...props} />} />;
};

export const useLayout = () => useContext(LayoutContext);
