import React, { useContext } from 'react';
import { LayoutContext } from './LayoutContext';

export default () => {
	const { panelToggled, togglePanel } = useContext(LayoutContext);
	const buttonClass = panelToggled ? 'tool_item icon_sidebar from_right active' : 'tool_item icon_sidebar from_right ';

	return (
		<span data-testid="panel-toggler" className={buttonClass} onClick={togglePanel}>
			<div className="ico_wrap">
				<div className="left_side" />
				<div className="main_view" />
				<div className="right_side" />
			</div>
		</span>
	);
};
