import React, { FC } from 'react';
import { Context, Item } from '../../../../../components/general/Tree';
import ItemToggler from '../../../../../components/general/Tree/TreeComponent/ItemToggler';
import { useGeo } from '../../context';

interface Props {
	index: number;
	context: Context;
	nested?: boolean;
	handleChange: (ctx: Context) => void;
	generateContext: (path: string, item: Item, parent: Context) => Context;
}

export const TreeItem: FC<Props> = ({ context, handleChange, generateContext }) => {
	// Destructure properties from context and item
	const { item, path, isExpanded } = context;

	const { isLoading } = useGeo();
	const { items, loading } = item;

	const levelClass =
		item.type === 'CLIENT' || item.type === 'horizontal'
			? 'level_one_item'
			: item.type === 'COMPANY' || item.type === 'vertical'
			? 'level_two_item'
			: 'level_three_item';

	const disabledClass = loading ? 'disabled' : '';

	const list = item.type === 'CLIENT' || item.type === 'horizontal' ? 'level_two_list' : 'level_three_list';

	// Create class for the subitem list based on the expanded value
	const listClass = isExpanded ? `${list} expanded` : list;

	return (
		<div className={`${levelClass} ${disabledClass} ${isLoading ? 'disabled' : ''}`}>
			<ItemToggler context={context} handleChange={handleChange} />

			{/* Loop through the subitems if they are provided */}
			{items && isExpanded && (
				<div className={`${listClass}`}>
					{items.map((item: Item, index: number) => {
						const ctx = generateContext(`${path}.${index}`, item, context);

						return (
							<TreeItem
								key={item.id}
								index={index}
								nested={true}
								context={ctx}
								handleChange={handleChange}
								generateContext={generateContext}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
};
