import {
	Promotion,
	UpdatePromotion,
	Currency,
	Status,
	PromotionType,
	uploadImg,
} from '../../pages/administration/promotions/types';

import { axiosInstance } from '../config';

interface Filters {
	skip?: number;
	limit?: number;
	queryString?: string;
	status?: Status[];
	promotionType?: PromotionType[];
	userCreated?: string;
	currency?: Currency[];
	orderBy: string[];
}

export const promotions = {
	getFilteredPromotions(filters: Filters): Promise<{
		total: number;
		promotions: Promotion[];
	}> {
		return axiosInstance.post('/administration/promotions/filter/', filters).then((res) => res.data);
	},

	createPromotion(promotion: Promotion): Promise<Promotion> {
		return axiosInstance.post(`/administration/promotions/`, promotion).then((res) => res.data);
	},

	updatePromotion(id: number, promotion: UpdatePromotion): Promise<Promotion> {
		return axiosInstance.post(`/administration/promotions/${id}/`, promotion).then((res) => res.data);
	},

	deletePromotion(id: number): Promise<Promotion> {
		return axiosInstance.delete(`/administration/promotions/${id}/`).then((res) => res.data);
	},

	uploadImage(file_data: FormData): Promise<uploadImg> {
		return axiosInstance.post('/uploads/', file_data, {}).then((res) => res.data);
	},
};
