import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useData } from '../../../../context/DataProvider';
import Dropddown from '../../../../components/general/Dropdown';
import { Provider } from '../../../system/integrations/types';

interface Props {
	selectProvider: (id: string) => void;
	selectProviderItem?: (item: Provider) => void;
	selectedProvider: string;
	connectedList?: string[];
	live?: boolean;
	disabled?: boolean;
}

export const ProvidersFilter: FC<Props> = ({
	selectProvider,
	selectedProvider,
	live = false,
	connectedList,
	selectProviderItem,
	disabled = false,
}) => {
	const { t } = useTranslation();
	const { providersMap } = useData();

	const items = useMemo(() => {
		let items = providersMap.filter((item) => item[live ? 'liveData' : 'prematchData']);

		if (connectedList) {
			return items.filter(({ id }) => connectedList.includes(id)).map(({ id }) => ({ id, name: id }));
		}
		return [{ id: '', name: t('general.all') }, ...items];
	}, [providersMap, connectedList]);

	const handleSelect = (id: string) => {
		if (connectedList && selectProviderItem) {
			const item = providersMap.find((item) => item.id === id)! as Provider;
			selectProviderItem(item);
		} else selectProvider(id);
	};

	return (
		<Dropddown
			items={items}
			listClass={`right_0 ${disabled ? 'disabled' : ''}`}
			selectItem={handleSelect}
			selectedItem={selectedProvider}
			margins={['0', '20', '0', '0']}
			fixedTitle={selectedProvider || 'Select provider'}
			icon={
				!items.length || (items.length === 1 && selectedProvider === items[0].id)
					? ''
					: `mdi mdi-numeric-${connectedList?.length}-circle font_20px padding_left_10`
			}
			isDisabled={disabled}
		/>
	);
};
