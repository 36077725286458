import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

export default (props: any) => {
	if (props.avoidPortal) return props.children;

	const [el] = useState(document.createElement('div'));

	useEffect(() => {
		document.body.appendChild(el);
		return () => {
			document.body.removeChild(el);
		};
	}, []);

	return createPortal(props.children, el);
};
