import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import Modal from '../../../../../components/Modals';
import { useInitialMount } from '../../../../../hooks';
import { useAxios } from '../../../../../hooks/useAxios';
import { useNotify } from '../../../../../hooks/useNotifications';
import { Item } from '../../../../../types/general';
import { formatDate } from '../../../../../utils/getDate';
import { ProviderData } from '../../types';

export interface ProviderEvent {
	id: string;
	utcScheduled: string;
	sport: Item;
	category: Item;
	tournament: Item;
	competitors: { id: string; name: string }[];
	matchTracker: boolean | null;
	betBuilder: boolean | null;
	stats: boolean | null;
	statsUrl: string | null;
	externalId: string | null;
}

interface Props {
	providerId: string;
	utcScheduled: string;
	home: string;
	away: string;
	updateProviderData: (selectedProviderEvent: ProviderData) => void;
	close: () => void;
}
export const ProviderFlagModal: FC<Props> = ({ providerId, utcScheduled, home, away, close, updateProviderData }) => {
	const { t } = useTranslation();
	const { warn } = useNotify();

	const initialMount = useInitialMount();

	const {
		data: { events },
		error,
		loading,
	} = useAxios<{ events: ProviderEvent[] }>({
		apiFunction: async function getProviderEventsData() {
			return await api.integrations.getProviderEvents({
				providerId,
				utcScheduled,
				home,
				away,
			});
		},
		initialData: { events: [] },
		dependencies: [],
	});

	if (error) {
		warn(t('integrations.dontProvide'));
		close();
		return null;
	}

	if (!loading && !events.length && !initialMount) {
		warn(t('integrations.noProviderEvents'));
		close();
		return null;
	}

	const handleClick = (event: ProviderEvent) => {
		const { id, matchTracker, betBuilder, stats, statsUrl } = event;
		const data = { [providerId]: { matchTracker, betBuilder, stats, statsUrl, externalId: id } };
		updateProviderData(data);
	};

	return (
		<>
			{events.length ? (
				<Modal
					size="xxxl"
					opened={true}
					align={'start'}
					direction={'row'}
					zIndex={'over_3'}
					body={
						<table className="admin_table sticky_header full_w">
							<TableHeader />
							<tbody className="t_body">
								{events.map((event) => (
									<TableRow key={event.id} event={event} handleClick={handleClick} />
								))}
							</tbody>
						</table>
					}
					footer={
						<button className="btn md round outline" onClick={close}>
							{t('general.cancel')}
						</button>
					}
				/>
			) : null}
		</>
	);
};

const TableHeader: FC = ({}) => {
	const { t } = useTranslation();

	return (
		<thead className="t_head">
			<tr data-testid="head-row">
				<th>{t('general.id')}</th>
				<th>{t('general.start')}</th>
				<th colSpan={3} className="text_center">
					&nbsp;
				</th>
				<th>{t('general.sport')}</th>
				<th>{t('general.category')}</th>
				<th>{t('tournamentsAdministration.tournamentName')}</th>
				<th className="text_center">{t('general.mt')}</th>
				<th className="text_center">{t('general.bb')}</th>
				<th className="text_center">{t('general.stats')}</th>
				<th className="text_center">{t('general.statsUrl')}</th>
				<th className="text_center">{t('general.externalId')}</th>
			</tr>
		</thead>
	);
};

interface RowProps {
	event: ProviderEvent;
	handleClick: (event: ProviderEvent) => void;
}

const TableRow: FC<RowProps> = ({ event, handleClick }) => {
	const {
		id,
		utcScheduled,
		competitors,
		category,
		tournament,
		sport,
		matchTracker,
		betBuilder,
		stats,
		statsUrl,
		externalId,
	} = event;

	const [date, time] = formatDate(utcScheduled, 'date.month', 'hours:minutes');
	const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];

	const redish = 'mdi mdi-close-circle-outline activated provider_flag--icon red font_24px';
	const green = 'mdi mdi-checkbox-marked-circle-outline activated provider_flag--icon font_24px';

	return (
		<tr data-testid="body-row" onClick={() => handleClick(event)}>
			<td>{id}</td>
			<td className="time_stamp">
				<span className="date ">{date}</span>
				<span className="time font_700">{time}</span>
			</td>
			<td className="font_700 text_right">{home}</td>
			<td className="text_center vs">{away && 'vs'}</td>
			<td className="font_700">{away && away}</td>
			<td>{sport.name}</td>
			<td>{category.name}</td>
			<td>{tournament.name}</td>
			<td className=" text_center">
				{matchTracker !== null ? <i className={`${matchTracker ? green : redish}`}></i> : '---'}
			</td>
			<td className=" text_center">
				{betBuilder !== null ? <i className={`${betBuilder ? green : redish}`}></i> : '---'}
			</td>
			<td className=" text_center">{stats !== null ? <i className={`${stats ? green : redish}`}></i> : '---'}</td>
			<td className=" text_center">{statsUrl || '---'}</td>
			<td className=" text_center">{externalId || '---'}</td>
		</tr>
	);
};
