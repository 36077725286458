export * from './utils';
export * from './types';

import React, { useState, useContext, createContext, useMemo, FC } from 'react';
import { Config, Item, PairingMethods, Context } from './types';
import { Loader } from '../../../components/general/Loader';
import { filter, filterPaired } from './utils';

/**
 * Create an empty context
 */
const PairingContext = createContext<Context>({} as Context);

/**
 * Export usePairing hook which exposes context methods
 */
export const usePairing = () => useContext(PairingContext);

/**
 * Export PairingProvider component
 */
interface Props {
	config: Config;
}
export const PairingProvider: FC<Props> = ({ config, children }) => {
	const [local, setLocal] = useState<Item[]>([]);
	const [staged, setStaged] = useState<Item[]>([]);
	const [paired, setPaired] = useState<Item[]>([]);
	const [provided, setProvided] = useState<Item[]>([]);

	const [refreshed, setRefreshed] = useState('');
	const refresh = () => setRefreshed(Date.now().toString());

	// Methods for updating local and provided data
	const updateLocal = (newList: Item[]) => setLocal(newList);
	const updateProvided = (newList: Item[]) => setProvided(newList);

	// Search value and lists filtered based on the search value
	const [searchValue, setSearchValue] = useState<string>('');

	let localList = useMemo(() => {
		if (searchValue.length < 3) return local;
		return filter(local, searchValue);
	}, [local, searchValue]);

	let pairedList = useMemo(() => {
		if (searchValue.length < 3) return paired;
		return filterPaired(paired, searchValue);
	}, [paired, searchValue]);

	const providedList = useMemo(() => {
		if (searchValue.length < 3) return provided;
		return filter(provided, searchValue);
	}, [provided, searchValue]);

	/**
	 * In case search string is id of provided item we only need to filter provided items and skip filtering other lists
	 */
	const providedIDs = useMemo(() => provided?.map(({ id }) => id), [provided]);

	if (!localList.length && !pairedList.length && providedList.length && providedIDs.includes(searchValue)) {
		localList = local;
		pairedList = paired;
	}

	// Selected items
	const [localItem, setLocalItem] = useState<Item>({} as Item);
	const [providerItem, setProviderItem] = useState<Item>({} as Item);

	// Selected  provider items that we use for fuzzy search
	const [localFuzzy, setLocalFuzzy] = useState<Item>({} as Item);
	const [providerFuzzy, setProviderFuzzy] = useState<Item>({} as Item);

	// Loading indicators for items
	const [loading, setLoading] = useState<string[]>([]);
	const startLoading = (id: string) => setLoading((loading) => [...loading, id]);
	const stopLoading = (id: string) => setLoading((loading) => loading.filter((item) => item !== id));

	// Pairing options
	const [sport, setSport] = useState<string>('');
	const [provider, setProvider] = useState<string>('');
	const [update, setUpdate] = useState(0);
	const [localCategory, setLocalCategory] = useState<string>('');
	const [localTournament, setLocalTournament] = useState<string>('');
	const [providerCategory, setProviderCategory] = useState<string>('');
	const [providerTournament, setProviderTournament] = useState<string>('');

	// Create self object which will be passed as 'this' to config methods
	type Self = PairingMethods<typeof config>;

	const self: Partial<Self> = {
		// Lists
		local,
		setLocal,
		paired,
		setPaired,
		provided,
		setProvided,
		staged,
		setStaged,

		// Selected items
		localItem,
		setLocalItem,
		providerItem,
		setProviderItem,

		// Selected fuzzy items
		localFuzzy,
		setLocalFuzzy,
		providerFuzzy,
		setProviderFuzzy,

		// Loading indicators
		loading,
		setLoading,
		startLoading,
		stopLoading,

		// Pairing options
		sport,
		provider,
		update,
		localCategory,
		localTournament,
		providerCategory,
		providerTournament,
	};

	// Spread the methods from the config object.
	// This way config methods can access each other
	for (let key in config) self[key] = config[key].bind(self as Self);

	const context: Partial<Context> = {
		// Lists
		staged,
		local: localList,
		paired: pairedList,
		provided: providedList,
		setPaired,
		setLocal,
		setProvided,
		setStaged,

		// Methods
		updateLocal,
		updateProvided,

		// Search
		searchValue,
		setSearchValue,

		// Selected items
		localItem,
		setLocalItem,
		providerItem,
		setProviderItem,

		// Selected fuzzy items
		localFuzzy,
		setLocalFuzzy,
		providerFuzzy,
		setProviderFuzzy,

		// Loading indicators
		loading,
		setLoading,
		startLoading,
		stopLoading,

		refreshed,
		refresh,

		// Pairing options
		sport,
		provider,
		update,
		localCategory,
		localTournament,
		providerCategory,
		providerTournament,
		setSport,
		setProvider,
		setUpdate,
		setLocalCategory,
		setLocalTournament,
		setProviderCategory,
		setProviderTournament,
	};

	for (let key in config) context[key] = config[key].bind(self as Self);

	if (!config) return <Loader />;
	return <PairingContext.Provider value={context as Context} children={children} />;
};
