import { TemplateItem } from './../../pages/Lists/templates/types';
import { axiosInstance } from './../config';

export const templates = {
	getTemplates({
		sportId = '',
		q = '',
		offset = 0,
		limit = 100
	}: {
		sportId?: string | number;
		q?: string;
		offset?: number;
		limit?: number;
	}) {
		return axiosInstance
			.get(`/lists/templates?sportId=${sportId}&q=${q}&offset=${offset}&limit=${limit}`)
			.then((res) => res.data);
	},
	editTemplate(template: any) {
		return axiosInstance
			.put(`/lists/templates/${template.id}`, {
				...template
			})
			.then((res) => {
				return res.data;
			});
	},
	addTemplate(newTemplate: any) {
		return axiosInstance
			.post(`/lists/templates`, {
				...newTemplate
			})
			.then((res) => {
				return res.data;
			});
	},
	deleteTemplate(templateId: string) {
		return axiosInstance.delete(`/lists/templates/${templateId}`).then((res) => {
			return res.data;
		});
	}
};
