import { AbilityBuilder } from '@casl/ability';

function subjectName(item: any) {
	if (!item || typeof item === 'string') {
		return item;
	}

	return item.__type;
}

const defaultAbility = AbilityBuilder.define({ subjectName }, (can: any) => {
	can('read', 'all');
});

export default defaultAbility;
