import React, { FC, createContext, useContext, useState, useEffect } from 'react';
import { OnlineUser } from '../types';
import { onlineUserDefault, tabs, defaultTab } from '../utils';
import api from '../../../api';

export interface OnlineUserProps {
	uid: string;
	page: string;
}

export interface OnlineUserContextProps {
	loaded: boolean;
	onlineUser: OnlineUser;
	setOnlineUser: (user: OnlineUser) => void;
	selectedTab: string;
	selectTab: (selectedTab: string) => void;
	refetchUser: () => void;
}

const onlineUserContextDefault: OnlineUserContextProps = {
	loaded: false,
	onlineUser: onlineUserDefault,
	setOnlineUser: (user: OnlineUser) => {},
	selectedTab: defaultTab,
	selectTab: () => {},
	refetchUser: () => {},
};

const OnlineUserContext = createContext(onlineUserContextDefault);

const OnlineUserProvider: FC<OnlineUserProps> = (props) => {
	const [onlineUser, setOnlineUser] = useState(onlineUserDefault);
	const [selectedTab, selectTab] = useState(props.page || defaultTab);
	const [loaded, setLoaded] = useState(false);
	const [refresh, setRefresh] = useState(Date.now());
	const refetchUser = () => {
		setRefresh(Date.now());
	};

	useEffect(() => {
		if (!loaded || selectedTab === 'details') {
			(async function load() {
				try {
					const user = await api.users.getUserInfo(props.uid);
					setOnlineUser(user);
				} catch (err) {
					console.log(err);
				} finally {
					setLoaded(true);
				}
			})();
		}
	}, [refresh, selectedTab]);

	return (
		<OnlineUserContext.Provider
			value={{ loaded, onlineUser, setOnlineUser, selectedTab, selectTab, refetchUser }}
			{...props}
		/>
	);
};

const useOnlineUser = () => useContext(OnlineUserContext);

export { OnlineUserProvider, useOnlineUser };
