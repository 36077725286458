import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortCell } from './SortCell';
import { useUserConfig } from '../../../../context/UserConfigProvider';
import { REPORTS_TICKETS_COLUMNS } from '../../../../consts/userConfig';

interface Props {
	selectedSort: string;
	selectSort: (sort: string) => void;
}

export const TableHeader: FC<Props> = ({ selectedSort, selectSort }) => {
	const { t } = useTranslation();
	const { isColumnVisible } = useUserConfig();

	return (
		<thead className="t_head">
			<tr>
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.STATUS) && (
					<td scope="col">
						{
							<SortCell
								ascending="WAITS_FOR_DESC"
								descending="WAITS_FOR_ASC"
								label={t('general.status')}
								selectedSort={selectedSort}
								selectSort={selectSort}
								tdClass="padding_0"
							/>
						}
					</td>
				)}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.SIGN) && <td scope="col"></td>}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.LOCATION) && <td scope="col"></td>}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.BARCODE) && <td scope="col">{t('general.barcode')}</td>}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.TIME) && (
					<SortCell
						ascending="DT_REQUESTED_ASC"
						descending="DT_REQUESTED_DESC"
						label={t('general.time')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.SA) && (
					<td scope="col" title={t('risk.authorizationState')}>
						{t('risk.sa')}
					</td>
				)}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.PAYIN) && (
					<SortCell
						ascending="PAY_IN_ASC"
						descending="PAY_IN_DESC"
						label={t('reports.tickets.payin')}
						selectedSort={selectedSort}
						selectSort={selectSort}
						labelClass="margin_left_auto"
					/>
				)}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.CURRENCY) && <td scope="col">{t('general.currency')}</td>}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.ACC) && <td scope="col">{'ACC'}</td>}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.MAX_GAIN) && (
					<SortCell
						ascending="MAX_GAIN_ASC-GAIN_ASC"
						descending="MAX_GAIN_DESC-GAIN_DESC"
						label={t('general.maxWin')}
						selectedSort={selectedSort}
						selectSort={selectSort}
						labelClass="margin_left_auto"
					/>
				)}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.GAIN) && (
					<SortCell
						ascending="GAIN_ASC-GAIN_ASC"
						descending="GAIN_DESC-GAIN_DESC"
						label={t('general.win')}
						selectedSort={selectedSort}
						selectSort={selectSort}
						labelClass="margin_left_auto"
					/>
				)}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.PAYOUT) && (
					<td scope="col" className="text_right">
						{
							<SortCell
								ascending="PAYOUT_ASC"
								descending="PAYOUT_DESC"
								label={t('reports.tickets.payout')}
								selectedSort={selectedSort}
								selectSort={selectSort}
								labelClass="margin_left_auto"
							/>
						}
					</td>
				)}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.LIVE_PAYOUT) && (
					<SortCell
						ascending="LIVE_PAYMENT_ASC"
						descending="LIVE_PAYMENT_DESC"
						label={t('reports.tickets.livePayment')}
						selectedSort={selectedSort}
						selectSort={selectSort}
						labelClass="margin_left_auto"
					/>
				)}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.BONUS) && (
					<SortCell
						ascending="BONUS_ASC"
						descending="BONUS_DESC"
						label={t('general.bonus')}
						selectedSort={selectedSort}
						selectSort={selectSort}
						labelClass="margin_left_auto"
					/>
				)}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.GEO_ENTITY_NAME) && <td scope="col">{t('general.location')}</td>}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.RISK) && (
					<td scope="col" className="text_center">
						{t('reports.tickets.riskLvl')}
					</td>
				)}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.RISK_TAG) && (
					<td scope="col" className="text_center">
						{t('general.tag')}
					</td>
				)}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.RISK_GROUP_ID) && <td scope="col">{t('general.rg')}</td>}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.USER) && <td scope="col">{t('general.user')}</td>}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.BOOKMAKER) && <td scope="col">{t('risk.bookmaker')}</td>}
				{isColumnVisible(REPORTS_TICKETS_COLUMNS.SIMILARITY) && (
					<td scope="col" className="text_center">
						{t('general.similarity')}
					</td>
				)}
			</tr>
		</thead>
	);
};
