import React, { useState, useEffect, FC } from 'react';
import Dropdown from '../../../../components/general/Dropdown';
import { Sport } from '../../../administration/sports/types';
import api from '../../../../api';

interface DropdownItem {
	id: string | number;
	name?: string;
}

interface Props {
	selectedItem: string | number;
	selectItem: (id: string | number) => void;
	margins: string[];
	icon?: string;
	all?: boolean;
}

export const SportDropdown: FC<Props> = ({ selectedItem, selectItem, margins, all = false, ...other }) => {
	const [items, setItems] = useState<DropdownItem[]>([]);

	useEffect(() => {
		(async () => {
			const data: Sport[] = (all ? await api.sports.getList('ALL') : await api.sports.getList()) || [];
			setItems(data.map(({ id, defaultName }) => ({ id, name: defaultName })));
			data.length && selectItem(data[0].id);
		})();
	}, []);
	return <Dropdown items={items} selectedItem={selectedItem} selectItem={selectItem} margins={margins} {...other} />;
};
