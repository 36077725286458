import i18n from '../../../localisation/i18n';
import { TabItem } from './types';

export const favoriteTips = {
	fb: {
		oddtypes: ['ofb:fs:1', 'ofb:fs:X', 'ofb:fs:2', 'ofb:tg:0-2', 'ofb:tg:3+', 'ofb:gg:GG'],
		oddtypeNames: ['1', 'X', '2', '0-2', '3+', 'GG'],
		hendikep: false,
		boundary: false,
	},
	ft: {
		oddtypes: ['oft:fs:1', 'oft:fs:X', 'oft:fs:2', 'oft:g:(-)', 'oft:g:(-)', 'oft:g:(+)'],
		oddtypeNames: ['1', 'X', '2', 'O/U', '(-)', '(+)'],
		hendikep: false,
		boundary: true,
	},
	bb: {
		oddtypes: ['obb:fs:1', 'obb:fs:X', 'obb:fs:2', 'obb:p:(-)', 'obb:p:(-)', 'obb:p:(+)'],
		oddtypeNames: ['1', 'X', '2', 'O/U', '(-)', '(+)'],
		hendikep: false,
		boundary: true,
	},
	tn: {
		oddtypes: ['otn:fs:1', 'otn:fs:2', 'otn:hg:1', 'otn:g:(-)', 'otn:g:(-)', 'otn:g:(+)'],
		oddtypeNames: ['1', '2', 'H', 'O/U', '(-)', '(+)'],
		hendikep: true,
		boundary: true,
	},
	ih: {
		oddtypes: ['oih:fs:1', 'oih:fs:X', 'oih:fs:2', 'oih:g:(-)', 'oih:g:(-)', 'oih:g:(+)'],
		oddtypeNames: ['1', 'X', '2', 'O/U', '(-)', '(+)'],
		hendikep: false,
		boundary: true,
	},
	hb: {
		oddtypes: ['ohb:fs:1', 'ohb:fs:X', 'ohb:fs:2', 'ohb:g:(-)', 'ohb:g:(-)', 'ohb:g:(+)'],
		oddtypeNames: ['1', 'X', '2', 'O/U', '(-)', '(+)'],
		hendikep: false,
		boundary: true,
	},
	vb: {
		oddtypes: ['ovb:fs:1', 'ovb:fs:2', 'ovb:h1s:1', 'ovb:p:(-)', 'ovb:p:(-)', 'ovb:p:(+)'],
		oddtypeNames: ['1', '2', 'H', 'O/U', '(-)', '(+)'],
		hendikep: true,
		boundary: true,
	},
	vf: {
		oddtypes: ['ofb:fs:1', 'ofb:fs:X', 'ofb:fs:2', 'ofb:tg:0-2', 'ofb:tg:3+', 'ofb:gg:GG'],
		oddtypeNames: ['1', 'X', '2', '0-2', '3+', 'GG'],
		hendikep: false,
		boundary: false,
	},
	tt: {
		oddtypes: ['ott:fs:1', 'ott:fs:2', 'ott:hs:1', 'ott:p:(-)', 'ott:p:(-)', 'ott:p:(+)'],
		oddtypeNames: ['1', '2', 'H', 'O/U', '(-)', '(+)'],
		hendikep: true,
		boundary: true,
	},
	sn: {
		oddtypes: ['osn:fs:1', 'osn:fs:2', 'osn:h:H1', 'osn:f:(-)', 'osn:f:(-)', 'osn:f:(+)'],
		oddtypeNames: ['1', '2', 'H', 'O/U', '(-)', '(+)'],
		hendikep: true,
		boundary: true,
	},
	dt: {
		oddtypes: ['odt:fs:1', 'odt:fs:2', 'odt:h:H1', 'odt:l:(-)', 'odt:l:(-)', 'odt:l:(+)'],
		oddtypeNames: ['1', '2', 'H', 'O/U', '(-)', '(+)'],
		hendikep: true,
		boundary: true,
	},
	af: {
		oddtypes: ['oaf:fs:1', 'oaf:fs:2', 'oaf:h:H1', 'oaf:p:(-)', 'oaf:p:(-)', 'oaf:p:(+)'],
		oddtypeNames: ['1', '2', 'H', 'O/U', '(-)', '(+)'],
		hendikep: true,
		boundary: true,
	},
	bs: {
		oddtypes: ['obs:fs:1', 'obs:fs:2', 'obs:h:H1', 'obs:p:(-)', 'obs:p:(-)', 'obs:p:(+)'],
		oddtypeNames: ['1', '2', 'H', 'O/U', '(-)', '(+)'],
		hendikep: true,
		boundary: true,
	},
	bx: {
		oddtypes: ['obx:fs:1', 'obx:wm:X', 'obx:fs:2', 'obx:tr:(-)', 'obx:tr:(-)', 'obx:tr:(+)'],
		oddtypeNames: ['1', 'X', '2', 'O/U', '(-)', '(+)'],
		hendikep: false,
		boundary: true,
	},
	mm: {
		oddtypes: ['omm:fs:1', 'omm:wm:X', 'omm:fs:2', 'omm:tr:(-)', 'omm:tr:(-)', 'omm:tr:(+)'],
		oddtypeNames: ['1', 'X', '2', 'O/U', '(-)', '(+)'],
		hendikep: false,
		boundary: true,
	},
	bm: {
		oddtypes: ['obm:fs:1', 'obm:fs:2', 'obm:hs:1', 'obm:p:(-)', 'obm:p:(-)', 'obm:p:(+)'],
		oddtypeNames: ['1', '2', 'H', 'O/U', '(-)', '(+)'],
		hendikep: true,
		boundary: true,
	},
	rb: {
		oddtypes: ['orb:fs:1', 'orb:fs:2', 'orb:h:H1', 'orb:p:(-)', 'orb:p:(-)', 'orb:p:(+)'],
		oddtypeNames: ['1', '2', 'H', 'O/U', '(-)', '(+)'],
		hendikep: true,
		boundary: true,
	},
	wp: {
		oddtypes: ['owp:fs:1', 'owp:fs:X', 'owp:fs:2', 'owp:p:(-)', 'owp:p:(-)', 'owp:p:(+)'],
		oddtypeNames: ['1', 'X', '2', 'O/U', '(-)', '(+)'],
		hendikep: false,
		boundary: true,
	},

	all: {
		oddtypes: [
			'owp:fs:1',
			'owp:fs:X',
			'owp:fs:2',
			'orb:fs:1',
			'orb:fs:2',
			'obm:fs:1',
			'obm:fs:2',
			'omm:fs:1',
			'omm:wm:X',
			'omm:fs:2',
			'obx:fs:1',
			'obx:wm:X',
			'obx:fs:2',
			'obs:fs:1',
			'obs:fs:2',
			'oaf:fs:1',
			'oaf:fs:2',
			'odt:fs:1',
			'odt:fs:2',
			'osn:fs:1',
			'osn:fs:2',
			'ott:fs:1',
			'ott:fs:2',
			'ovb:fs:1',
			'ovb:fs:2',
			'ohb:fs:1',
			'ohb:fs:X',
			'ohb:fs:2',
			'oih:fs:1',
			'oih:fs:X',
			'oih:fs:2',
			'otn:fs:1',
			'otn:fs:2',
			'obb:fs:1',
			'obb:fs:X',
			'obb:fs:2',
			'oft:fs:1',
			'oft:fs:X',
			'oft:fs:2',
			'ofb:fs:1',
			'ofb:fs:X',
			'ofb:fs:2',
		],
		oddtypeNames: ['1', 'X', '2'],
		hendikep: false,
		boundary: false,
	},
};

export function getTips(sportId: string): string[] {
	if (!sportId)
		return Object.values(favoriteTips)
			.map(({ oddtypes }) => oddtypes)
			.flat();
	else return favoriteTips[sportId]?.oddtypes || [sportId];
}

export const navigationTabs: TabItem[] = [
	{
		id: 'general',
		text: i18n.t('prematch.controller.general'),
	},
	{
		id: 'special',
		text: i18n.t('prematch.controller.special'),
	},
	{
		id: 'specialMix',
		text: i18n.t('prematch.controller.specialMix'),
	},
	{
		id: 'playerProps',
		text: i18n.t('prematch.controller.playerProps'),
	},
];
