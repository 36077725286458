import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { Header } from './components/Header';
import { MainContent } from './components/MainContent';
import { OnlineUserProvider } from './context/OnlineUserContext';

interface Props {
	uid: string;
	page: string;
}

export const OnlineUser: FC<RouteComponentProps<Props>> = ({
	match: {
		params: { uid, page },
	},
}) => {
	return (
		<OnlineUserProvider uid={uid} page={page}>
			<Header />
			<MainContent />
		</OnlineUserProvider>
	);
};
