import React, { FC, ReactNode, KeyboardEvent } from 'react';
import { Sport } from '../sports/types';
import { Player } from '../players/types';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modals';
import { User } from '../../internet/users/types';
import { CategoryItem } from '../categories/types';
import { CompetitorItem } from '../competitors/types';
import { TournamentItem } from '../tournaments/types';
import { ModalSize } from '../../../components/Modals/types';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

type FormData = TournamentItem | CategoryItem | Sport | CompetitorItem | User | Player;

// Local interface for the component
interface Props {
	editing: FormData;
	onSubmit: (values: any) => void;
	onClose: () => void;
	onDelete?: () => void;
	size: ModalSize;
	sectionClass: string;
	zIndex?: string;
	header?: ReactNode;
}

export const AdministrationModal: FC<Props> = ({
	editing,
	onClose,
	onSubmit,
	size,
	sectionClass,
	header,
	children,
}) => {
	// Destructure translsation
	const { t, i18n } = useTranslation();
	const { language: ln } = i18n;

	const methods = useForm<FormData>({
		defaultValues: { ...editing },
		shouldUnregister: false,
	});

	const onSubmitForm = (data: SubmitHandler<FormData>) => {
		onSubmit(data);
		onClose();
	};

	function onKeyDown(event: KeyboardEvent<HTMLFormElement>) {
		//@ts-ignore
		if ((event.charCode || event.keyCode) === 13 && event?.target?.type !== 'textarea') {
			event.preventDefault();
		}
	}

	const { sport, category, name } = methods.watch() as any;
	const isDisabled =
		(!sport?.id && sport?.id !== undefined) || !name[ln] || (!category?.id && category?.id !== undefined);
	const disableClass = isDisabled ? 'disabled' : '';

	return (
		<Modal
			size={size}
			align={'start'}
			direction={'row'}
			opened={true}
			sectionClass={sectionClass}
			zIndex={'over_1'}
			cancelAction={() => onClose()}
		>
			<FormProvider {...methods}>
				<form
					className="modal_content fadeInDown animated faster"
					onSubmit={methods.handleSubmit(onSubmitForm)}
					onKeyDown={onKeyDown}
				>
					{header && <div className="modal_head">{header}</div>}
					<div data-testid="modal-body" className="modal_body content_start direction_row">
						{children}
					</div>

					<div data-testid="modal-footer" className={`modal_footer col_border_top`}>
						<button className="btn md round outline" onClick={() => onClose()}>
							{t('general.cancel')}
						</button>

						<button type="submit" className={`btn md round green ${disableClass}`} disabled={isDisabled}>
							{t('general.save')}
						</button>
					</div>
				</form>
			</FormProvider>
		</Modal>
	);
};
