import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Delete from '../../../../../utils/svg/Delete';
import NotificationModal from '../../../../general/NotificationModal';
import { useTicket } from '../../context/TicketContext';

export const TicketStorno = () => {
	const { t } = useTranslation();
	const { modified, stornoTicket, ticket } = useTicket();
	const { idUser, status } = ticket;
	const [open, setOpen] = useState(false);
	const [stornoMessage, setStornoMessage] = useState('');

	const handleStornoMessage = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setStornoMessage(event.currentTarget.value);
	};

	const { cashout } = ticket;

	const stornoDisabled = cashout && cashout.payoutStatus === 'ACCEPTED';

	const storno = () => {
		if (stornoDisabled) return;
		stornoTicket(stornoMessage);
		setOpen(false);
		setStornoMessage('');
	};

	const paidOutOnLandbase = !idUser && status === 7;

	return (
		<div className="padding_10 flex_direction_row flex_justify_end">
			{!modified && !paidOutOnLandbase && status !== 2 ? (
				<button
					className={`btn lg  padding_left_60 padding_right_60 margin_right_0 ${stornoDisabled ? 'disabled' : ''}`}
					onClick={() => setOpen(true)}
				>
					{t('reports.tickets.storno')}
				</button>
			) : null}
			{open ? (
				<NotificationModal
					opened={true}
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={() => setOpen(false)}
					confirmAction={storno}
					zIndex={'over_3'}
				>
					<div className="context_icon margin_top_40">
						<Delete />
					</div>
					<h2 className="modal_message capitalize text_center" style={{ lineHeight: 'normal' }}>
						{t('reports.tickets.stornoConfirmMsg')}
					</h2>
					<textarea
						className=" margin_top_20 padding_20"
						placeholder={t('reports.tickets.stornoMsgEnter')}
						value={stornoMessage}
						onChange={handleStornoMessage}
						style={{ resize: 'none', border: 'none', outline: 'none', fontSize: '15px' }}
					/>
				</NotificationModal>
			) : null}
		</div>
	);
};
