import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import { useModels } from '../../../../../hooks/useModels';
import { useNotify } from '../../../../../hooks/useNotifications';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import LangMedia from '../../../../../components/general/LangMedia';
import { FillEmpty } from '../../../../../components/general/FillEmpty';
import { EventItemExtended } from '../../../../../components/Events/Modal/types';

interface Props {
	event: any;
	rows?: number;
}

export const EventDescription: FC<Props> = ({ event, rows = 13 }) => {
	const { t } = useTranslation();
	const { mapExtended } = useModels();
	const { warn, success } = useNotify();

	const [disabled, setDisabled] = useState<boolean>(false);

	const methods = useForm<EventItemExtended>({
		defaultValues: { ...event, description: mapExtended(event.description) },
		shouldUnregister: false,
	});

	const { languages, media, selectedLang, selectedMedia, selectLang, selectMedia, fillExtended } = useModels();

	const desc = methods.getValues('description');
	const tour = methods.getValues('tournament');

	const description = useWatch({
		control: methods.control,
		name: 'description',
		defaultValue: desc,
	});

	const tournament = useWatch({
		control: methods.control,
		name: 'tournament',
		defaultValue: tour,
	});

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		methods.setValue(`description.${selectedLang}.${selectedMedia}`, e.target.value);
	};

	const onSubmitForm = (data: EventItemExtended) => {
		updateEventDescription(data);
	};

	const updateEventDescription = async (data: EventItemExtended) => {
		const { id, scheduled, sport, competitors, tournament } = event;
		try {
			setDisabled(true);
			await api.events.updateEventPartial(event.id, {
				id,
				scheduled,
				sport,
				competitors,
				tournament,
				description: data.description,
			});
			success(t('toast.success.updateSuccessful', { entity: t('eventsAdministration.event') }));
		} catch (error) {
			warn(t('toast.warn.updateProblem', { entity: t('eventsAdministration.event') }));
		} finally {
			setDisabled(false);
		}
	};

	const [fillActive, setFillActive] = useState(false);

	const fillLocalization = () => {
		setFillActive(false);
		methods.setValue('description', fillExtended(description));
	};

	const tournamentDescription = tournament?.description?.[selectedLang]?.[selectedMedia] || '';
	const placeholder = tournamentDescription
		? `${t('tournamentsAdministration.tournamentDesc')} ${tournamentDescription}`
		: t('eventsAdministration.description');

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmitForm)}>
				{fillActive && description && description[selectedLang][selectedMedia] ? (
					<FillEmpty styles={{ marginLeft: '79%', marginBottom: '5px' }} onClick={fillLocalization} />
				) : null}

				<div className="align_center flex_justify_end padding_right_10 ">
					<button
						type="submit"
						className={`btn md round margin_top_5 margin_bottom_5  ${false ? 'disabled' : ''} ${
							disabled ? 'disabled' : ''
						}`}
					>
						{t('eventsAdministration.saveDesc')}
					</button>
				</div>
				<div className="match_events  margin_top_1 margin_bottom_1" style={{ overflowY: 'auto' }}>
					<LangMedia
						languages={languages}
						media={media}
						selectedLang={selectedLang}
						selectedMedia={selectedMedia}
						selectLang={selectLang}
						selectMedia={selectMedia}
						marginTop={'20'}
						direction={'down'}
					/>
					{/* Textarea */}
					<div className="tab_content_wrap">
						<div className="tab_content active">
							<textarea
								cols={30}
								rows={rows}
								onChange={handleChange}
								style={{ resize: 'none' }}
								onFocus={() => setFillActive(true)}
								placeholder={placeholder}
								className="invisible padding_left_20 padding_top_15"
								value={(selectedMedia && selectedLang && description?.[selectedLang]?.[selectedMedia]) || ''}
							/>
						</div>
					</div>
				</div>
			</form>
		</FormProvider>
	);
};
