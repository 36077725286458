import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const MarketOptions = () => {
	const { t } = useTranslation();
	const { register } = useFormContext();

	return (
		<>
			<div className="margin_bottom_0 margin_top_20  padding_left_25 align_center">
				<div className="align_center width_50_perc">
					<div className="label col-8">{t('specialMarkets.priority')}</div>
					<div className="col-3 margin_right_25">
						<input className="col-12" name="order" type="number" ref={register} />
					</div>
				</div>
				<div className="align_center  width_50_perc"></div>
			</div>
			<div className=" margin_bottom_20 margin_top_20  padding_left_25 align_center">
				<Checkbox label={t('specialMarkets.mix')} name="mix" />
				<Checkbox label={t('specialMarkets.hasBoundary')} name="hasBoundaries" />
			</div>
			<div className="margin_bottom_0 margin_top_20  padding_left_25 align_center">
				<Checkbox label={t('specialMarkets.isPlayerProps')} name="playerProps" />
				<Checkbox label={t('specialMarkets.splitTypes')} name="splitTypes" />
			</div>
		</>
	);
};

interface CheckboxProps {
	label: string;
	name: string;
}

export const Checkbox: FC<CheckboxProps> = ({ name, label }) => {
	const { register, control, getValues } = useFormContext();

	const value = getValues(name);
	const checked = useWatch({
		control,
		name,
		defaultValue: value,
	});

	return (
		<div className="align_center  width_50_perc">
			<div className="label col-8">{label}</div>
			<div className="checkbox_custom chek_only tooltip_right col-4 flex_justify_space_around ">
				<input name={name} type="checkbox" ref={register} />
				{!checked ? <i className="mdi mdi-checkbox-blank-outline unchecked" /> : null}
				{checked ? <i className="mdi mdi-checkbox-marked checked" /> : null}
			</div>
		</div>
	);
};
