import { useEffect } from 'react';

export const useKeyboardListener = (keyCode: string, onPressedKey: () => void) => {
	useEffect(() => {
		const onKeyDown = (e) => {
			e.key === keyCode && onPressedKey();
		};

		window.addEventListener('keydown', onKeyDown);

		return () => window.removeEventListener('keydown', onKeyDown);
	}, [keyCode]);
};
