import { useReducer } from 'react';
import { State, Action } from './types';

export const initialState: State = {
	liveStatuses: {
		DISABLED: {
			color: 'red',
			sign: 'D',
			active: true,
			rowColor: 'red',
		},
		LIVE: {
			color: 'green',
			sign: 'L',
			active: true,
			rowColor: 'green',
		},
		ANNOUNCED: {
			color: 'yellow',
			sign: 'A',
			active: true,
			rowColor: 'yellow',
		},
	},
	providerStatuses: {
		NOT_RUNNING: {
			color: 'blue',
			sign: 'NR',
			active: true,
			icon: 'mdi-timer-off',
		},
		RUNNING: {
			color: 'green',
			sign: 'R',
			active: true,
			icon: 'mdi-play',
		},
		STOPPED: {
			color: 'redish',
			sign: 'S',
			active: true,
			icon: 'mdi-stop',
		},
		NOT_FINISHED: {
			color: 'orange',
			sign: 'NF',
			active: true,
			icon: 'mdi-stop',
		},
		FINISHED: {
			color: 'dark',
			sign: 'F',
			active: true,
			icon: 'mdi-check-all',
		},
	},
};

function reducer(state: State, action: Action): any {
	const { liveStatuses, providerStatuses } = state;
	switch (action) {
		case 'DISABLED':
			return {
				...state,
				liveStatuses: {
					...liveStatuses,
					DISABLED: { ...liveStatuses.DISABLED, active: !liveStatuses.DISABLED.active },
				},
			};

		case 'LIVE':
			return {
				...state,
				liveStatuses: {
					...liveStatuses,
					LIVE: { ...liveStatuses.LIVE, active: !liveStatuses.LIVE.active },
				},
			};
		case 'ANNOUNCED':
			return {
				...state,
				liveStatuses: {
					...liveStatuses,
					ANNOUNCED: { ...liveStatuses.ANNOUNCED, active: !liveStatuses.ANNOUNCED.active },
				},
			};
		case 'NOT_RUNNING':
			return {
				...state,
				providerStatuses: {
					...providerStatuses,
					NOT_RUNNING: { ...providerStatuses.NOT_RUNNING, active: !providerStatuses.NOT_RUNNING.active },
				},
			};
		case 'RUNNING':
			return {
				...state,
				providerStatuses: {
					...providerStatuses,
					RUNNING: { ...providerStatuses.RUNNING, active: !providerStatuses.RUNNING.active },
				},
			};
		case 'STOPPED':
			return {
				...state,
				providerStatuses: {
					...providerStatuses,
					STOPPED: { ...providerStatuses.STOPPED, active: !providerStatuses.STOPPED.active },
				},
			};
		case 'NOT_FINISHED':
			return {
				...state,
				providerStatuses: {
					...providerStatuses,
					NOT_FINISHED: { ...providerStatuses.NOT_FINISHED, active: !providerStatuses.NOT_FINISHED.active },
				},
			};
		case 'FINISHED':
			return {
				...state,
				providerStatuses: {
					...providerStatuses,
					FINISHED: { ...providerStatuses.FINISHED, active: !providerStatuses.FINISHED.active },
				},
			};

		default:
			state;
	}
}

export const useFilter = () => {
	const [filters, dispatch] = useReducer(reducer, initialState);
	return { filters, dispatch };
};
