import React from 'react';
import { useTranslation } from 'react-i18next';
import SidebarDropdown from './SidebarDropdown';
import SidebarLink from './SidebarLink';

export default () => {
	const { t } = useTranslation();

	return (
		<>
			<SidebarDropdown svg="01_Administracija" path="/administration" text={t('general.administration')}>
				<SidebarLink to="/administration/events" icon="circle-medium">
					{t('eventsAdministration.shortTitle')}
				</SidebarLink>

				<SidebarLink to="/administration/competitors" icon="circle-medium">
					{t('competitorsAdministration.shortTitle')}
				</SidebarLink>

				<SidebarLink to="/administration/tournaments" icon="circle-medium">
					{t('tournamentsAdministration.shortTitle')}
				</SidebarLink>

				<SidebarLink to="/administration/categories" icon="circle-medium">
					{t('categoriesAdministration.shortTitle')}
				</SidebarLink>

				<SidebarLink to="/administration/sports" icon="circle-medium">
					{t('sportsAdministration.shortTitle')}
				</SidebarLink>

				<SidebarLink to="/administration/markets" icon="circle-medium">
					{t('marketsAdministrations.shortTitle')}
				</SidebarLink>

				<SidebarLink to="/administration/special-markets" icon="circle-medium">
					{t('specialMarkets.shortTitle')}
				</SidebarLink>

				<SidebarLink to="/administration/players" icon="circle-medium">
					{t('playersAdministration.shortTitle')}
				</SidebarLink>

				<SidebarLink to="/administration/translations" icon="circle-medium">
					{t('translationsAdministrations.shortTitle')}
				</SidebarLink>
				<SidebarLink to="/administration/externalApplications" icon="circle-medium">
					{t('externalApplicationsAdministration.shortTitle')}
				</SidebarLink>
				<SidebarLink to="/administration/applicationType" icon="circle-medium">
					{t('applicationsTypesAdministration.shortTitle')}
				</SidebarLink>
				<SidebarLink to="/administration/promotions" icon="circle-medium">
					{t('promotions.shortTitle')}
				</SidebarLink>
				<SidebarLink to="/administration/regulations" icon="circle-medium">
					{t('regulations.shortTitle')}
				</SidebarLink>
				<SidebarLink to="/administration/currencies" icon="circle-medium">
					{t('currencies.shortTitle')}
				</SidebarLink>
				<SidebarLink to="/administration/themes" icon="circle-medium">
					{t('themes.shortTitle')}
				</SidebarLink>
			</SidebarDropdown>
		</>
	);
};
