import React, { FC, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import MultiSelectDropdown from '../../../../components/general/MultiSelectDropdown';
import { TournamentItem } from '../../../administration/tournaments/types';
import { useNotify } from '../../../../hooks/useNotifications';
import getErrorText from '../../../../utils/getErrorMsg';

interface Props {
	selectedSport: string;
	selectedTournaments: string[];
	selectTournaments: (id: string[]) => void;
	direction?: 'left_0' | 'right_0';
	isMobileView?: boolean;
}
export const TournamentsDropdown: FC<Props> = ({
	selectedSport,
	selectTournaments,
	selectedTournaments,
	direction = 'right_0',
	isMobileView,
}) => {
	const { t } = useTranslation();
	const [tournaments, setTournaments] = useState([] as TournamentItem[]);
	const { error } = useNotify();

	useEffect(() => {
		if (!selectedSport) return;
		(async function initTournaments() {
			try {
				const { tournaments = [] } = (await api.tournaments.getTournaments({ sportId: selectedSport })) || {};

				const filteredTournaments = tournaments
					.filter((tour: TournamentItem) => tour.active)
					.sort(
						(a: TournamentItem, b: TournamentItem) =>
							(a.priority !== null ? a.priority : Infinity) - (b.priority !== null ? b.priority : Infinity)
					);

				setTournaments(filteredTournaments);
				selectTournaments(['all', ...filteredTournaments.map((tour: TournamentItem) => tour.id)]);
			} catch (err) {
				error(getErrorText(err) || 'toast.error.defaultText');
			}
		})();
	}, [selectedSport]);

	const items = useMemo(
		() =>
			tournaments.map(({ id, defaultName, category }) => ({
				id: `${id}`,
				name: `${defaultName} (${category.defaultName})`,
			})),
		[tournaments, selectedTournaments]
	);

	return (
		<div className={` ${isMobileView} ?'':'margin_left_0'`}>
			<MultiSelectDropdown
				title={t('general.tournaments')}
				options={items}
				selectedOptions={selectedTournaments}
				selectOption={selectTournaments}
				marginRight={'5'}
				withSearch
				direction={direction}
				isMobileView={isMobileView}
			/>
		</div>
	);
};
