import api from '../../../api';
import icons from '../../../consts/icons';
import { FieldMap, GeoSearchResponse, Node, NodeType } from './types';
import { Context, Item } from '../../../components/general/Tree';

export async function handleEntity(ctx: Context, selectNode: (node: Node | null) => void, type: string) {
	const { item, isExpanded, expand, contract, setItems, startLoader, setError, target, activate } = ctx;
	const { items, name, id, type: entityType } = item;

	if (target === 'expand') {
		if (isExpanded) contract();
		else if (items && items.length) expand();
		else {
			try {
				// Start loader and fetch data from the server
				startLoader();
				const entities = await api.geo.getEntitySubNodes(id, type);

				// If data is empty throw an error
				if (!entities.length) throw '';

				const isDevice = type === 'DEVICE';

				const entityItems: Item[] = entities.map((entity) => ({
					type,
					items: [],
					id: entity.id,
					name: entity.name,
					expandable: !isDevice,
					icon: isDevice ? icons.circle : '',
					expandIcon: isDevice ? icons.circle : icons.arrowRight,
					contractIcon: isDevice ? icons.circle : icons.arrowDown,
				}));
				setItems(entityItems).stopLoader().expand();
			} catch {
				setError();
			}
		}
	} else {
		selectNode({ id, name, entityType: entityType as NodeType });
		activate();
	}
}

export const transformGeoData = (data: GeoSearchResponse[]) => {
	const expanded: string[] = [];

	const items: Item[] = data.map(({ id, name, companies = [] }, i: number) => {
		if (companies.length) expanded.push(`${i}`);
		return {
			id: id,
			name: name,
			type: 'CLIENT',
			expandIcon: icons.arrowRight,
			contractIcon: icons.arrowDown,
			expandable: true,
			items: companies!.map(({ id, name, regions = [] }, y: number) => {
				if (regions.length) expanded.push(`${i}.${y}`);
				return {
					id: id,
					name: name,
					type: 'COMPANY',
					expandIcon: icons.arrowRight,
					contractIcon: icons.arrowDown,
					expandable: true,
					items: regions!.map(({ id, name, groups = [] }, z: number) => {
						if (groups.length) expanded.push(`${i}.${y}.${z}`);
						return {
							id: id,
							name: name,
							type: 'REGION',
							expandIcon: icons.arrowRight,
							contractIcon: icons.arrowDown,
							expandable: true,
							items: groups!.map(({ id, name, locations = [] }, k: number) => {
								if (locations.length) expanded.push(`${i}.${y}.${z}.${k}`);
								return {
									id: id,
									name: name,
									type: 'GROUP',
									expandIcon: icons.arrowRight,
									contractIcon: icons.arrowDown,
									expandable: true,
									items: locations!.map(({ id, name, devices = [] }, t: number) => {
										if (devices.length) expanded.push(`${i}.${y}.${z}.${k}.${t}`);
										return {
											id: id,
											name: name,
											type: 'LOCATION',
											expandIcon: icons.arrowRight,
											contractIcon: icons.arrowDown,
											expandable: true,
											items: devices!.map(({ id, name }) => {
												return {
													id,
													name,
													type: 'DEVICE',
													icon: icons.circle,
													expandIcon: icons.circle,
													contractIcon: icons.circle,
													expandable: false,
													items: [],
												};
											}),
										};
									}),
								};
							}),
						};
					}),
				};
			}),
		};
	});
	return { items, expanded };
};

export const formatPercentageValues = (params: { [key: string]: any }, map: FieldMap) => {
	let formatedParams = {} as { [key: string]: any };
	for (const key in params) {
		const { data_type } = map[key].field_type_specifics;
		if (data_type === 'percentage') formatedParams[key] = params[key] !== null ? params[key] * 100 : params[key];
		else formatedParams[key] = params[key];
	}
	return formatedParams;
};
