import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import FileUpload from '../../../components/general/FileUpload';

export const IconForm = () => {
	const { getValues, setValue, control } = useFormContext();
	const { icons } = getValues();

	const iconsChanged = useWatch({
		control,
		name: 'icons',
	});

	return (
		<div className="icon_form_V">
			{Object.keys(icons).map((iconName, index) => (
				<div className="row" key={index}>
					<div className={`label ${iconsChanged ? 'rerender' : ''}`}>
						<small>{iconName}</small>
					</div>
					<div className="upload_field">
						<FileUpload name={`icons.${iconName}`} value={icons[iconName]} update={setValue} />
					</div>
				</div>
			))}
		</div>
	);
};
