import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useData } from '../../../../../context/DataProvider';
import { ProviderData } from '../../types';
import './styles.css';

interface Props {
	providerData: ProviderData;
	filterBy: 'prematchData' | 'liveData';
	handleSelect: () => void;
	handleDblClick: () => void;
}

export const ProviderFlagsCell: FC<Props> = ({ providerData, filterBy, handleSelect, handleDblClick }) => {
	const { t } = useTranslation();
	const { providersMap } = useData();

	const providers = useMemo(() => {
		const providerDataArray = [];
		for (const key in providerData) {
			const provider = providersMap.find((provider) => provider.id === key);
			if (provider && provider[filterBy]) {
				providerDataArray.push({ ...providerData[key], key });
			}
		}
		return providerDataArray;
	}, [providersMap, providerData]);

	const redish = 'mdi mdi-close-circle-outline activated provider_flag--icon red';
	const green = 'mdi mdi-checkbox-marked-circle-outline activated provider_flag--icon';

	return (
		<td className="text_center" onClick={handleSelect} onDoubleClick={handleDblClick}>
			{providers.length ? (
				<div className="checkbox_custom chek_only tooltip_left text_center align_center flex_justify_end">
					<span>{providers.length}</span>
					<span className="tooltip_content" style={{ height: 'auto', top: -80 }}>
						<div className="wrapper_flags">
							{providers.map((item) => {
								const { matchTracker, betBuilder, stats, key, externalId } = item;

								return (
									<div key={key} className="provider">
										<div className="provider_header">
											<h3>{key}</h3>
										</div>

										{matchTracker !== null ? (
											<div className="provider_flag ">
												<div>{t('general.mt')}</div>
												<div>
													<i className={`${matchTracker ? green : redish}`}></i>
												</div>
											</div>
										) : null}
										{betBuilder !== null ? (
											<div className="provider_flag ">
												<div>{t('general.bb')}</div>
												<div>
													<i className={`${betBuilder ? green : redish}`}></i>
												</div>
											</div>
										) : null}
										{stats !== null ? (
											<div className="provider_flag ">
												<div>{t('general.stats')}</div>
												<div>
													<i className={`${stats ? green : redish}`}></i>
												</div>
											</div>
										) : null}
										{externalId ? (
											<div className="provider_flag" style={{ alignSelf: 'center', marginTop: 'auto' }}>
												<div>{externalId}</div>
											</div>
										) : null}
									</div>
								);
							})}
						</div>
					</span>
				</div>
			) : (
				<div className=" checkbox_custom chek_only tooltip_left text_center align_center flex_justify_end">
					<span>-</span>
				</div>
			)}
		</td>
	);
};
