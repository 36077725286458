import React, { FC, useEffect, useState } from 'react';
import { formatDate } from '../../../../../utils/getDate';
import api from '../../../../../api';
import { LiveEvent } from '../../../../administration/live/types';

interface Props {
	data: any;
	setData: any;
}

export const Event: FC<Props> = ({ data, setData }) => {
	const [events, setEvents] = useState<LiveEvent[]>([]);
	const [selectedEvents, setSelectedEvents] = useState<string[]>(data);

	useEffect(() => {
		async function fetchData() {
			const data = await api.liveEvents.getLiveEvents({
				offerPlanId: '',
				orderBy: 'startTimeAsc',
				q: '',
				limit: 1000,
				liveEventStatus: ['DISABLED', 'LIVE', 'ANNOUNCED'],
				eventStatus: ['NOT_RUNNING', 'RUNNING', 'STOPPED', 'NOT_FINISHED', 'FINISHED'],
				sportId: '',
				providerId: '',
			});
			setEvents(data.events);
		}
		fetchData();
	}, []);

	useEffect(() => {
		setData(selectedEvents);
	}, [selectedEvents]);

	const selectEvent = (id: string) => {
		setSelectedEvents((events) => [...events, id]);
	};
	const removeEvent = (id: string) => {
		setSelectedEvents((events) => [...events.filter((event) => event !== id)]);
	};

	return (
		<div>
			<table className="admin_table full_w">
				<thead className="t_head">
					<tr>
						<th>Code</th>
						<th>Start</th>
						<th>Competitors</th>
						<th>Tournament</th>
						<th></th>
					</tr>
				</thead>
				<tbody className="t_body">
					{events.map((event) => (
						<tr key={event.id}>
							<td>{event.listCode}</td>
							<td>{formatDate(event.scheduled, 'day hours:minutes')}</td>
							<td>{event.competitors.map((c) => c.name).join(' - ')}</td>
							<td>{event.tournament?.name}</td>
							<td className="actions_cell">
								<div className="td_actions">
									{!selectedEvents.includes(event.id) ? (
										<div className="tbl_btn green">
											<i
												onClick={() => {
													selectEvent(event.id);
												}}
												className="mdi mdi-plus"
											/>
										</div>
									) : (
										<div className="tbl_btn green">
											<i
												onClick={() => {
													removeEvent(event.id);
												}}
												className="mdi mdi-minus"
											/>
										</div>
									)}
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
