import React from 'react';
import { formatDate } from '../../../../utils/getDate';
import { ONLINE_SUPPORT_USERS_COLUMNS } from '../../../../consts/userConfig';
import { useUserConfig } from '../../../../context/UserConfigProvider';
import { openInNewTab } from '../../../../utils/newTab';
import { toMoneyFormat } from '../../../../utils/toFixed';
import { OnlineSupportUser } from '../types';
import { useTranslation } from 'react-i18next';

export const TableRow = ({
	username,
	idUser,
	email,
	nationalId,
	firstName,
	lastName,
	locationName,
	comment,
	onlineBalance,
	freeBetBalance,
	reservation,
	currency,
	telephone,
	city,
	address,
	addressNum,
	verified,
	uuidUser,
	created,
	isNotPolitician,
}: OnlineSupportUser) => {
	const { t } = useTranslation();
	const handleClick = () => {
		if (uuidUser) openInNewTab(`${window.location.origin}/internet/user/${uuidUser}/details`);
	};

	const [formattedDate] = formatDate(created, 'date.month.year | hours:minutes:seconds');
	const { isColumnVisible } = useUserConfig();

	let verifiedIcon = verified ? (
		<i className="mdi mdi-checkbox-marked-circle-outline activated" />
	) : (
		<i className="mdi mdi-checkbox-blank-circle-outline desabled" />
	);

	const handleClass = (type: boolean) => {
		return type ? 'mdi mdi-checkbox-blank-circle blueish_text' : 'mdi mdi-circle-outline gray_text';
	};

	return (
		<tr onDoubleClick={handleClick}>
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.ID) && <td>{idUser}</td>}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.USERNAME) && <td>{username}</td>}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.EMAIL) && <td>{email}</td>}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.POLITICIAN) && (
				<td>
					<div className="td_actions justify_center tooltip_left">
						<i className={handleClass(!isNotPolitician)} />
						<span className="tooltip_content">{t('general.politicallyActive')}</span>
					</div>
				</td>
			)}

			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.NATIONAL_ID) && <td>{nationalId}</td>}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.NAME) && <td>{`${firstName ?? ''} ${lastName ?? ''}`}</td>}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.LOCATION) && <td>{locationName}</td>}

			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.ONLINE_BALANCE) && (
				<td className="text_right">{toMoneyFormat(onlineBalance)}</td>
			)}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.FB_BALANCE) && (
				<td className="text_right">{toMoneyFormat(freeBetBalance)}</td>
			)}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.RESERVATION) && (
				<td className="text_right">{toMoneyFormat(reservation)}</td>
			)}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.CURRENCY) && <td className="text_center">{currency}</td>}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.TELEPHONE) && <td>{telephone}</td>}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.CITY) && <td>{city}</td>}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.ADDRESS) && <td>{`${address ?? ''} ${addressNum ?? ''}`}</td>}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.CREATED) && (
				<td className="text_right">
					<span className="date ">{formattedDate}</span>
				</td>
			)}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.DESCRIPTION) && <td>{comment}</td>}
			{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.VERIFIED) && <td className="text_right">{verifiedIcon}</td>}
		</tr>
	);
};
