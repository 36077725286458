import { HomeAwayResult, PeriodScore } from '../../results/types';

export interface TicketEvent {
	id: string;
	live: boolean;
	odds: { [key: string]: any };
	sport: IdName;
	category: IdName;
	tournament: IdName;
	utc_scheduled: string;
	list_code: number;
	competitors: { id: string; name: string }[];
	bet_score: { current_time: string; result: any } | null;
	similar?: TicketEvent;
	systemId?: string | null;
	riskCopyTicket?: {};
}

export interface IdName {
	id: string;
	name: string;
}

export interface TicketEventExtended extends TicketEvent {
	checked: boolean;
	odd: any;
	first: boolean;
	results: RiskResults | null;
	prematchOdds: { [key: string]: any };
	notActive?: boolean;
	isRemoved?: boolean;
	oddOriginal?: string;
	specialOriginal?: string;
}

export interface RiskResults {
	currentScore: HomeAwayResult | null;
	periodScores: PeriodScore[];
	currentGamescore: HomeAwayResult | null;
}

export interface TicketItem {
	id: string;
	barcode: string | null;
	amount: number;
	convertedAmount: number | null;
	aprovedAmount: number | null;
	currency: string;
	status: number;
	authStatus: number;
	live: boolean | null;
	idLocation: string;
	deviceInfo: Device | null;
	userInfo: { id: number; email: string; id_risk_group: number } | null;
	riskManager: { email: string; id: string; name: string };
	dtPlaced: string | null;
	dtAuthorised: string | null;
	dtRequested: string;
	gain: number | null;
	minQuota: number;
	maxQuota: number;
	maxGain: number;
	livePayment: number;
	bonus: number;

	min_quota: number;
	max_quota: number;
	max_gain: number;

	combinations: number;
	systems: { events: TicketEvent[]; required: number[] }[];
	riskCopyTicket: TicketItem;
	dt_authorised: string | null;
	idUser: string | null;
	operator: string | null;
	payoutOperator: string | null;
	operaterMessage: string | null;
	riskManagerMessage: string | null;
	systemMessage: string | null;
	riskTag: string | null;
	riskValue: number | null;
	walletType: 'INTERNET' | 'FB';
	manualAuthorization: boolean;
	similarTicket: string | null;
	similar_ticket: string | null;
	similarityValue: number | null;
	similarity_value: number | null;
	acceptChanges: boolean;
	taxParameters?: TaxesRegulativeGeoParams;
	taxData: TaxData;
	tax_data: TaxData;
	tax_parameters?: TaxesRegulativeGeoParams;
	promotionAmount: number | null;
	promotion_amount: number | null;
	promotion_codes?: [] | null | undefined;
	unique?: boolean | null;
}

export interface FormatedOdds {
	[key: string]: { name: { [key: string]: string }; keyboardCode: string };
}

export interface AuthStatus {
	[key: string]: { color: string; text: string; sign: string; active: boolean };
}

export interface TicketStatus {
	[key: string]: { color: string; text: string; sign: string; active: boolean };
}
export interface PayInType {
	[key: string]: { color: string; text: string; sign: string; active: boolean };
}
export interface AutomaticFilters {
	[key: string]: { value: string; active: boolean };
}

export interface LocationItem {
	id: string;
	name: string;
	city: string | null;
	idLandbaseParent: string | null;
}

export interface Device {
	currency: string;
	id_location: string;
	id_device: string;
	device_type: number;
	barcode_type: number;
}

export const deviceTypes: any = {
	0: 'PRESENTATION',
	1: 'POS',
	2: 'TERMINAL',
	3: 'E-TERMINAL',
};

export type TaxesRegulativeGeoParams = {
	deductible_pay_in_amount: number;
	deductible_pay_out_amount: number;
	exclude_pay_in_from_pay_out_tax?: boolean;
	pay_in_tax_levels: string;
	pay_out_tax_levels: string;
};

export type TaxData = {
	original_amount: number | null;
	original_gain: number | null;
	taxed_amount: number | null;
	taxed_gain: number | null;
	amount_tax_level: {
		tax: number | null;
		amount: number | null;
	};
	gain_tax_level: number | null;
};
