// PayIn types
export const payInTypes = [
	{
		key: 'FB',
		value: 'FREEBET',
		active: true,
	},
	{
		key: 'INTERNET',
		value: 'INTERNET',
		active: true,
	},
	{
		key: 'BONUS',
		value: 'BONUS',
		active: false,
	},
];
// Statuses
export const statusesArray = {
	ACTIVE: ['ACTIVATED', 'CANCELED', 'ACTIVE'],
	CREATED: ['ACTIVATED', 'CANCELED', 'CREATED'],
	CANCELED: ['ACTIVATED', 'CANCELED'],
	EXPIRED: ['ACTIVATED', 'CANCELED', 'EXPIRED'],
};
export const statusColor = {
	ACTIVE: 'blue',
	CREATED: 'green',
	CANCELED: 'orange',
	EXPIRED: 'redish red',
	ACTIVATED: 'gray',
};
