import {
	Regulation,
	OddtypeTaxMappings,
	OddType,
	OutcomeMapping,
	CreateOutcomeMapping,
} from '../administrations/../../pages/administration/regulations/types';
import { axiosInstance } from '../config';

interface Filters {
	queryString: string;
	live: boolean;
	prematch: boolean;
	idSport: string;
	skip: number;
	limit: number;
	taxAgency: string;
}

export const regulations = {
	getAgencies() {
		return axiosInstance.get('/administration/regulations/tax_agencies/').then((res) => res.data);
	},

	getFilteredRegulations(filters: Filters): Promise<{
		total: number;
		result: Regulation[];
	}> {
		return axiosInstance.post('/administration/regulations/filter/', filters).then((res) => res.data);
	},

	deleteOddType(id: string): Promise<Regulation> {
		return axiosInstance.delete(`/administration/regulations${id}`).then((res) => res.data);
	},

	getOddType(id: string): Promise<OddtypeTaxMappings> {
		return axiosInstance.get(`/administration/regulations/${id}`).then((res) => res.data);
	},

	createOddType(oddtypeData: OddtypeTaxMappings): Promise<OddtypeTaxMappings> {
		return axiosInstance.post('/administration/regulations/', oddtypeData).then((res) => res.data);
	},

	updateOddType(id: string, oddTypeUpdateObject: OddType): Promise<OddType> {
		return axiosInstance.post(`/administration/regulations/${id}/`, oddTypeUpdateObject).then((res) => res.data);
	},

	getOutcomeMappingTypes() {
		return axiosInstance.get('/administration/regulations/outcome_mapping_types').then((res) => res.data);
	},

	getOutcomeMapping(id: string): Promise<OutcomeMapping> {
		return axiosInstance.get(`/administration/regulations/outcome/${id}`).then((res) => res.data);
	},

	deleteOutcomeMapping(id: string): Promise<OutcomeMapping> {
		return axiosInstance.delete(`/administration/regulations/outcome/${id}`).then((res) => res.data);
	},

	createOutcomeMapping(outcomeMappingData: CreateOutcomeMapping): Promise<OutcomeMapping> {
		return axiosInstance.post('/administration/regulations/outcome', outcomeMappingData).then((res) => res.data);
	},

	updateOutcomeMapping(id: string, outcome: string): Promise<OutcomeMapping> {
		return axiosInstance
			.post(`/administration/regulations/outcome/${id}/`, { outcome: outcome })
			.then((res) => res.data);
	},
};
