import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TableHeader: FC = () => {
	const { t } = useTranslation();
	return (
		<thead className="t_head">
			<tr data-testid="head-row">
				<th>{t('general.select')}</th>
				<th colSpan={2}>{t('general.sport')}</th>
				<th className="text_center">{t('listBuilder.export.template')}</th>
				<th></th>
			</tr>
		</thead>
	);
};
