import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const TableHeader: FC = () => {
	const { t } = useTranslation();
	return (
		<thead className="t_head">
			<tr>
				<th scope="col">{t('general.amount')}</th>
				<th scope="col">{t('usersAdministrations.unlockedAmount')}</th>
				<th scope="col">{t('usersAdministrations.targetAmount')}</th>
				<th scope="col">{t('usersAdministrations.dtCreated')}</th>
				<th scope="col">{t('usersAdministrations.dtUpdated')}</th>
				<th scope="col">{t('usersAdministrations.locked')}</th>
			</tr>
		</thead>
	);
};

export default TableHeader;
