import { Market, Odd } from './types';
import { MarketsMap } from '../../types/markets';
import { sortByOrder } from '../../utils/sorting/sortByOrder';
import { isEmpty } from 'lodash';

export function separateMarkets(markets: Market[] = [], marketsMap: MarketsMap, sportId: string) {
	let separatedMarkets = [] as Market[];

	markets.forEach(({ id, odds, status }, index) => {
		// Find market data form map
		const {
			splitTypes = false,
			hasBoundaries = false,
			name = id,
			oddtypes = {},
			order = 0,
		} = marketsMap[sportId]?.markets?.markets[id] || {};
		// Return market as it is with added odds data from map
		if (!splitTypes && !hasBoundaries) {
			const fullOdds = odds.map((odd) => ({ ...odd, ...oddtypes[odd.id] }));
			sortByOrder(fullOdds);
			separatedMarkets.push({
				id,
				key: `${id}:${index}`,
				odds: fullOdds,
				status,
				name,
				splitTypes,
				hasBoundaries,
				order,
				manualStatus: null,
			});
		}

		// Make new market for each oddtype on it, also construct second oddtype as no win
		if (splitTypes) {
			const fullOdds = odds.map((odd) => ({ ...odd, ...oddtypes[odd.id.split('|')[0]] }));
			sortByOrder(fullOdds);

			fullOdds.forEach((odd, i) => {
				separatedMarkets.push({
					id,
					key: `${id}:${index}:${i}`,
					odds: [
						{ ...odd },
						{ ...odd, name: 'NO WIN', value: 1, result: odd.result === 1 ? 0 : odd.result === 0 ? 1 : null },
					],
					status,
					name,
					order,
					splitTypes,
					hasBoundaries,
					manualStatus: null,
				});
			});
		}

		// Separate oddtypes by boundaries and form each set make new market
		if (hasBoundaries && !splitTypes) {
			let fullOdds = odds.map(({ id, ...rest }) => {
				const [oddId] = id.split('|');
				const oddMap = oddtypes[oddId];
				return { ...oddMap, id, ...rest };
			});
			sortByOrder(fullOdds);

			const allBoundariesAreNumbers = fullOdds.every((odd) => !isNaN(+odd.specialValue));

			if (allBoundariesAreNumbers) {
				fullOdds.sort((a, b) => {
					if (a.specialValue > b.specialValue) return 1;
					if (a.specialValue < b.specialValue) return -1;
					return 0;
				});
			} else {
				const fullOddsWithSortKey = fullOdds.map((odd) => {
					const { specialValue } = odd;
					const [home, away] = isNaN(+odd.specialValue)
						? specialValue.match(/\d-\d/)
							? specialValue.split('-')
							: specialValue.split(':')
						: [odd.specialValue, 0];
					return { ...odd, sortKey: +away * 100 + +home };
				});

				fullOddsWithSortKey.sort((a, b) => {
					if (a.sortKey > b.sortKey) return 1;
					if (a.sortKey < b.sortKey) return -1;
					return 0;
				});

				fullOdds = fullOddsWithSortKey.map(({ sortKey, ...rest }) => ({ ...rest }));
			}

			const oddsByBoundaries = fullOdds.reduce((acc, current) => {
				acc[current.specialValue] = acc[current.specialValue] ? [...acc[current.specialValue], current] : [current];
				return acc;
			}, {});

			const oddSets = Object.values(oddsByBoundaries) as [Odd[]];
			oddSets.forEach((odds, i) => {
				separatedMarkets.push({
					id,
					key: `${id}:${index}:${i}`,
					odds,
					status,
					order,
					name: `${name}|${odds[0].specialValue}`,
					splitTypes,
					hasBoundaries,
					manualStatus: null,
				});
			});
			oddSets.forEach((oddSet) => {
				const uniqueHistorySpecValues = new Set<string>();
				oddSet?.forEach(({ specialValueHistory = null }) => {
					if (!isEmpty(specialValueHistory))
						//@ts-ignore
						for (const specialValue of Object.keys(specialValueHistory)) {
							uniqueHistorySpecValues?.add(specialValue);
						}
				});
				if (!isEmpty(uniqueHistorySpecValues)) {
					const currentSpecialValue = oddSet[0].specialValue;
					uniqueHistorySpecValues?.forEach((historySpecialValue) => {
						if (historySpecialValue !== '' && historySpecialValue != currentSpecialValue) {
							const oddsWithSpecValue = oddSet?.filter(({ specialValueHistory }) =>
								!isEmpty(specialValueHistory)
									? //@ts-ignore
									  Object.keys(specialValueHistory)?.includes(historySpecialValue)
									: false
							);
							if (!isEmpty(oddsWithSpecValue)) {
								const odds = sortByOrder(
									//@ts-ignore
									oddsWithSpecValue.map((odd) => ({
										...odd,
										value: 1,
										specialValue: +historySpecialValue,
										result: odd?.specialValueHistory?.[historySpecialValue],
										history: true,
									}))
								) as Odd[];
								separatedMarkets.push({
									id,
									key: `${historySpecialValue}:${id}:${index}`,
									odds,
									status,
									name: `${name}|${historySpecialValue}`,
									splitTypes,
									hasBoundaries,
									order,
									manualStatus: null,
								});
							}
						}
					});
				}
			});
		}
		// To be decided
		if (hasBoundaries && splitTypes) {
			console.log(name);
		}
	});

	return separatedMarkets.filter((market) => market.odds.length > 0) || [];
}

export const sortOn = <T>(prop: string, list: string[]) => {
	const order = list.reduce((obj, key, idx) => Object.assign(obj, { [key]: idx + 1 }), {});
	const getVal = (item: T) => order[item[prop]] || Infinity;

	return (a: T, b: T) => getVal(a) - getVal(b);
};

interface Styles {
	[key: string]: React.CSSProperties;
}
export const styles: Styles = {
	form: {
		justifyContent: 'flex-start',
		width: 'auto',
		marginRight: '20px',
	},
	storno: {
		justifyContent: 'flex-start',
		width: 'auto',
		marginRight: '0px',
		padding: '0px',
	},
};

export const isNumber = (n: any) => {
	return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};

export const isNumberDecimal = (n: any) => {
	return /^\d+\.\d{0,2}$/.test(n);
};
