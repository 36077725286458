// Import config
import { axiosInstance, Response } from './../config';
// Import utils
import createMultipart from '../../pages/administration/utils/createMultipart';
// Import types
import { TournamentItemExtended, NewTournamentItem } from '../../pages/administration/tournaments/types';
import { StandingsTable } from '../../pages/administration/tournaments/components/Modal/Components/Standings/types';

export const tournaments = {
	getTournaments({
		sportId = '',
		categoryId = '',
		tournamentIds = '',
		query = '',
		limit = -1,
		skip = 0,
		orderBy = 'priorityDesc',
		offerPlanId = null,
		simple = false,
	}: {
		sportId?: string;
		categoryId?: string;
		tournamentIds?: string;
		query?: string;
		limit?: number;
		skip?: number;
		orderBy?: string;
		offerPlanId?: string | null | undefined;
		simple?: boolean;
	}): Response {
		return axiosInstance
			.get(`/administration/tournaments/`, {
				params: { limit, sportId, categoryId, tournamentIds, q: query, skip, orderBy, offerPlanId, simple },
			})
			.then((res) => res.data);
	},
	getTournament(id: string): Promise<TournamentItemExtended> {
		return axiosInstance.get(`/administration/tournaments/${id}`).then((res) => res.data);
	},
	getAllTournaments(q: string, sportId: string = '', structured: string = 'structured') {
		return axiosInstance
			.get(`/administration/tournaments/`, {
				params: { limit: -1, sportId, categoryId: '', q, structured },
			})
			.then((res) => res.data);
	},
	createTournament(tournament: NewTournamentItem): Promise<TournamentItemExtended> {
		return axiosInstance
			.post(`/administration/tournaments/`, createMultipart(tournament), {
				headers: {
					'content-type': 'multipart/form-data',
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	updateTournament(tournament: NewTournamentItem, tournamentID: string | number): Promise<TournamentItemExtended> {
		return axiosInstance
			.put(`/administration/tournaments/` + tournamentID, createMultipart(tournament), {
				headers: {
					'content-type': 'multipart/form-data',
				},
			})
			.then((res) => {
				return res.data;
			});
	},

	getStandingsData(idTournament: string): Promise<StandingsTable> {
		return axiosInstance.get(`/administration/standings/${idTournament}/`).then((res) => res.data);
	},
	scrapeStandingsData(idTournament: string, id_season: string, id_phase: string): Promise<StandingsTable> {
		return axiosInstance
			.get(`/administration/standings/scrape/${idTournament}/${id_season}/${id_phase}/`)
			.then((res) => res.data);
	},

	updateStandingsTable(id: string, table: StandingsTable): Promise<StandingsTable> {
		return axiosInstance.put(`administration/standings/${id}/`, table).then((response) => response.data);
	},

	automaticScrapeTrigger(): Promise<StandingsTable> {
		return axiosInstance.get(`/administration/standings/scrape`).then((res) => res.data);
	},
};
