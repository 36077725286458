import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationData } from '../type';
import { formatDate } from '../../../../../utils/getDate';

interface Props {
	item: NotificationData;
	resend: (id: number) => void;
}

const TableRow: FC<Props> = ({ item, resend }) => {
	const { t } = useTranslation();

	const { title, sentBy, dtSent, dtExpire, isRead } = item;

	return (
		<tr>
			<td>{title}</td>
			<td>{dtSent ? formatDate(dtSent, 'date.month.year hours:minutes:seconds') : ''}</td>
			<td>{sentBy}</td>
			<td>{dtExpire ? formatDate(dtExpire, 'date.month.year hours:minutes:seconds') : ''}</td>
			<td>{isRead ? t('general.yes') : t('general.no')}</td>
			<td className="actions_cell td_actions">
				<div className="tbl_btn blue">
					<i className="mdi mdi-repeat md tooltip_left" onClick={() => item?.id && resend(item.id)}>
						<span className="tooltip_content blue">{t('general.resend')} </span>
					</i>
				</div>
			</td>
		</tr>
	);
};

export default TableRow;
