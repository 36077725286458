export const cleanEmpty = (obj: any): Partial<typeof obj> => {
	const cleaned = {} as Partial<typeof obj>;

	for (let key in obj) {
		const value = obj[key];
		if (typeof value !== 'undefined') cleaned[key] = value;
	}

	return cleaned;
};
