import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectCategory } from './SelectCategory';
import { CategoryItem } from '../../categories/types';
import { formatDate } from '../../../../utils/getDate';
import { useFormContext, useWatch } from 'react-hook-form';
import { Provider } from '../../../system/integrations/types';
import api from '../../../../api';
import { useAxios } from '../../../../hooks/useAxios';
import { SelectProvider } from './SelectProvider';

interface Props {
	categories: CategoryItem[];
}

export const TournamentForm: FC<Props> = ({ categories }) => {
	const { t } = useTranslation();

	const { register, getValues, control } = useFormContext();
	const { id, timeCreation, timeModify, active } = getValues();

	const isActive = useWatch({
		control,
		name: 'active',
		defaultValue: active,
	});

	const { data: providers } = useAxios<Provider[]>({
		apiFunction: async function getProviders() {
			return await api.integrations.getProviders();
		},
		initialData: [],
		dependencies: [],
	});

	return (
		<div className="col-7 mid_col direction_column padding_left_10 padding_right_10 padding_top_20 col_border_right">
			{id && (
				<div className="row margin_bottom_20">
					<div className="column text-right">
						<div className="label">{t('general.id')}</div>
					</div>
					<div className="column">
						<span>{id}</span>
					</div>
				</div>
			)}

			<div className="row margin_bottom_20">
				<div className="column text-right">
					<div className="label">{t('categoriesAdministration.shortTitle')}</div>
				</div>
				<div className="column">{<SelectCategory categories={categories} />}</div>
			</div>

			<div className="row margin_bottom_20">
				<div className="column text-right">
					<div className="label">{t('tournamentsAdministration.resultsChannel')}</div>
				</div>
				<div className="column">
					{
						<SelectProvider
							providers={[
								{ id: '', name: 'Clear selected' },
								...providers.map(({ id, name }) => ({
									id,
									name,
								})),
							]}
						/>
					}
				</div>
			</div>

			<div className="row margin_bottom_20">
				<div className="column text-right">
					<div className="label">{t('tournamentsAdministration.active')}</div>
				</div>
				<div className="column">
					<div className="checkbox_custom chek_only tooltip_right">
						<input name="active" type="checkbox" ref={register} />
						{!isActive ? <i className="mdi mdi-checkbox-blank-outline unchecked" /> : null}
						{isActive ? <i className="mdi mdi-checkbox-marked checked" /> : null}
					</div>
				</div>
			</div>

			<div className="row margin_bottom_20 double_row">
				<div className="column text-right">
					<div className="label"> {t('tournamentsAdministration.creationTime')}</div>
				</div>
				<div className="column">
					<span>{formatDate(timeCreation, 'date.month.year')}</span>
					<span>{formatDate(timeCreation, 'hours:minutes')}</span>
				</div>
			</div>
			<div className="row margin_bottom_20 double_row">
				<div className="column text-right">
					<div className="label"> {t('tournamentsAdministration.timeOfEditing')}</div>
				</div>
				<div className="column">
					<span>{formatDate(timeModify, 'date.month.year')}</span>
					<span>{formatDate(timeModify, 'hours:minutes')}</span>
				</div>
			</div>
		</div>
	);
};
