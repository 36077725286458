import React, { useContext, ReactNode, useMemo } from 'react';
import Sidebar from '../components/layout/Sidebar';
import { LayoutContext, withLayoutContext } from '../components/layout/LayoutContext';
import { useLocation } from 'react-router-dom';

// Local props for the component
interface Props {
	children: ReactNode;
}

const MainLayout = ({ children }: Props) => {
	const { sidebarToggled, footerSize, sidebarOverContent } = useContext(LayoutContext);
	const contentClass = sidebarToggled ? 'app_content menu_hide' : 'app_content';
	const coverContent = sidebarOverContent ? 'app_main cover_content' : 'app_main';
	const mainClass = sidebarToggled ? `${coverContent} menu_hide` : coverContent;

	const location = useLocation();

	const reportsTabStyle = useMemo(() => {
		return location?.pathname.startsWith('/reports/') ? '' : 'x_scroll__m_0';
	}, [location?.pathname]);

	return (
		<div className={`${footerSize} ${contentClass}`}>
			<Sidebar />
			<div className={`${mainClass} ${reportsTabStyle}`}>{children}</div>
		</div>
	);
};

export default withLayoutContext(MainLayout);
