import { useReducer } from 'react';
import { Statuses } from './types';
import { reducer, initialState } from './reducer';

export const useFilter = () => {
	const [typesState, dispatch] = useReducer(reducer, initialState);
	const { ticketTypes, payInTypes, accountType } = typesState;

	const toggleType = (type: string) => {
		dispatch(type);
	};

	const activeTypes = (types: Statuses) => {
		let typesArray = [];
		for (const key in types) {
			if (types[key].active) typesArray.push(key);
		}
		return typesArray;
	};

	return { ticketTypes, payInTypes, toggleType, activeTypes, accountType };
};
