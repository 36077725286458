import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import NotificationModal from '../../../../components/general/NotificationModal';
import { useNotify } from '../../../../hooks/useNotifications';
import Delete from '../../../../utils/svg/Delete';
import { DailyExportTemplate } from '../types';

type ActionType = 'SET_DEFAULT' | 'DELETE';

interface Props {
	dailyExportTemplate: DailyExportTemplate;
	printTemplates: { id: number; name: string }[];
	updateDailyExportTemplates: (newTemplates: DailyExportTemplate[]) => void;
	removeDailyExportTemplate: (templateId: number) => void;
	selectForEdit: (template: DailyExportTemplate) => void;
	time: boolean;
}

export const TableRow: FC<Props> = ({
	dailyExportTemplate,
	printTemplates,
	updateDailyExportTemplates,
	removeDailyExportTemplate,
	selectForEdit,
	time = false,
}) => {
	const { t } = useTranslation();

	const [action, setAction] = useState<ActionType | null>(null);
	const [disabledDelete, setDisabledDelete] = useState<boolean>(false);

	const {
		id = null,
		default: defaultTemplate = false,
		sportTemplateMap,
		idListExportTemplate,
	} = dailyExportTemplate || {};

	const SET_DEFAULT = 'SET_DEFAULT';
	const DELETE = 'DELETE';

	const { success, warn } = useNotify();

	const handleEdit = () => {
		selectForEdit(dailyExportTemplate);
	};
	const handleDelete = () => {
		setAction(DELETE);
	};
	const handleSetDefault = () => {
		setAction(SET_DEFAULT);
	};

	const save = async () => {
		if (typeof id !== 'number') return;
		try {
			setDisabledDelete(true);
			if (action === SET_DEFAULT) {
				try {
					const updated = await (time ? api.timeExport.setDefault(id) : api.dailyExport.setDefault(id));
					updateDailyExportTemplates(updated);
					success(t('listBuilder.export.setDailyDefaultSuccess'));
				} catch (err) {
					console.error(err);
					warn(t('listBuilder.export.setDailyDefaultProblem'));
				}
			} else if (action === DELETE) {
				try {
					await (time ? api.timeExport.deleteTimeTemplate(id) : api.dailyExport.deleteDailyTemplate(id));
					removeDailyExportTemplate(id);
					success(
						t('toast.success.deletedSuccessful', {
							entity: time
								? t('listBuilder.export.timeOfferTemplate')
								: t('listBuilder.export.dailyOfferTemplate'),
						}),
						{
							autoClose: 4500,
						}
					);
				} catch (err) {
					console.error(err);
					warn(
						t('toast.warn.deleteProblem', {
							entity: time
								? t('listBuilder.export.timeOfferTemplate')
								: t('listBuilder.export.dailyOfferTemplate'),
						}),
						{
							autoClose: 4500,
						}
					);
				}
			}
		} catch (err) {
			console.error(err);
		} finally {
			closeConfirmModal();
			setDisabledDelete(false);
		}
	};

	const closeConfirmModal = () => {
		setAction(null);
	};

	const getConfirmMessage = (action: ActionType) => {
		if (action === SET_DEFAULT) {
			return t('listBuilder.export.areYouSureDefault');
		} else if (action === DELETE) {
			return t('listBuilder.export.areYouSureDelete');
		}
	};

	const printTemplateName = printTemplates?.find(({ id }) => id === idListExportTemplate)?.name || '';

	const selectedSports = sportTemplateMap.map(({ idSport }) => idSport).join(' , ');
	return (
		<>
			<tr>
				<td width={150}>{id}</td>
				<td>{printTemplateName}</td>
				<td>{selectedSports}</td>
				<td className="text_center">
					{defaultTemplate ? (
						<i className="mdi mdi-checkbox-marked-circle-outline activated tooltip_right">
							<span className="tooltip_content">{t('general.default')}</span>
						</i>
					) : null}
				</td>

				<td className="actions_cell">
					<div className="td_actions">
						<div className="tbl_btn green margin_right_30">
							<i
								onClick={handleSetDefault}
								className={`mdi mdi-check-underline-circle-outline tooltip_left ${
									defaultTemplate ? 'disabled' : ''
								}`}
							>
								<span className="tooltip_content">{t('listBuilder.export.setDefaultDailyTemplate')}</span>
							</i>
						</div>
						<div className="tbl_btn green">
							<i onClick={handleEdit} className="mdi mdi-pencil tooltip_left">
								<span className="tooltip_content">{t('listBuilder.export.editDailyTemplate')}</span>
							</i>
						</div>
						<div className="tbl_btn red">
							<i
								onClick={handleDelete}
								className={`mdi mdi-delete tooltip_left ${defaultTemplate ? 'disabled' : ''}`}
							>
								<span className="tooltip_content">{t('listBuilder.export.deleteDailyTemplate')}</span>
							</i>
						</div>
					</div>
				</td>
			</tr>
			{action ? (
				<NotificationModal
					opened={true}
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={closeConfirmModal}
					confirmAction={save}
					zIndex={'over_3'}
					isDisabled={disabledDelete}
				>
					<div className="context_icon margin_top_40">
						<Delete />
					</div>
					<h2 className="modal_message capitalize text_center">{t('general.areSure')}</h2>
					<p className="text_center margin_top_10">{`${getConfirmMessage(action)} ${id}?`}</p>
				</NotificationModal>
			) : null}
		</>
	);
};
