import { LiveMarketItem, LiveOdd } from './types';
import { MarketsMap } from '../../../types/markets';
import { sortByOrder } from '../../../utils/sorting/sortByOrder';
import { isEmpty } from 'lodash';

export function separateMarkets(markets: LiveMarketItem[] = [], marketsMap: MarketsMap, sportId: string) {
	let separatedMarkets = [] as LiveMarketItem[];

	markets.forEach(({ id, odds, status }, index) => {
		// Find market data form map
		const market = marketsMap[sportId]?.markets?.markets?.[id];
		if (isEmpty(market)) return [];
		const { splitTypes, hasBoundaries, name, oddtypes, order } = market;

		// Return market as it is with added odds data from map
		if (!splitTypes && !hasBoundaries) {
			const fullOdds = odds.map((odd) => ({ ...odd, ...oddtypes[odd.id] }));
			separatedMarkets.push({
				id,
				key: `${id}:${index}`,
				odds: fullOdds,
				status,
				name,
				splitTypes,
				hasBoundaries,
				order,
			});
		}

		// Make new market for each oddtype on it, also construct second oddtype as no win
		if (splitTypes) {
			const fullOdds = odds.map((odd) => ({ ...odd, ...oddtypes[odd.id] }));
			sortByOrder(fullOdds);

			fullOdds.forEach((odd, i) => {
				separatedMarkets.push({
					id,
					key: `${id}:${index}:${i}`,
					odds: [{ ...odd }, { ...odd, name: 'NO WIN', value: 1, result: null }],
					status,
					name: hasBoundaries && odd.specialValue ? `${name}|${odd.specialValue}` : name,
					order,
					splitTypes,
					hasBoundaries,
				});
			});
		}

		// Separate oddtypes by boundaries and form each set make new market
		if (hasBoundaries && !splitTypes) {
			let fullOdds = odds.map(({ id, ...rest }) => {
				const [oddId] = id.split('|');
				const oddMap = oddtypes[oddId];
				return { ...oddMap, id, ...rest };
			});

			const allBoundariesAreNumbers = fullOdds.every((odd) => !isNaN(+odd.specialValue));

			if (allBoundariesAreNumbers) {
				fullOdds.sort((a, b) => {
					if (a.specialValue > b.specialValue) return 1;
					if (a.specialValue < b.specialValue) return -1;
					return 0;
				});
			} else {
				const fullOddsWithSortKey = fullOdds.map((odd) => {
					const { specialValue } = odd;
					const [home, away] = isNaN(+odd.specialValue)
						? specialValue.match(/\d-\d/)
							? specialValue.split('-')
							: specialValue.split(':')
						: [odd.specialValue, 0];
					return { ...odd, sortKey: +away * 100 + +home };
				});

				fullOddsWithSortKey.sort((a, b) => {
					if (a.sortKey > b.sortKey) return 1;
					if (a.sortKey < b.sortKey) return -1;
					return 0;
				});

				fullOdds = fullOddsWithSortKey.map(({ sortKey, ...rest }) => ({ ...rest }));
			}

			const oddsByBoundaries = fullOdds.reduce((acc, current) => {
				acc[current.specialValue] = acc[current.specialValue] ? [...acc[current.specialValue], current] : [current];
				return acc;
			}, {});

			const oddSets = Object.values(oddsByBoundaries) as [LiveOdd[]];
			oddSets.forEach((odds, i) => {
				separatedMarkets.push({
					id,
					key: `${id}:${index}:${i}`,
					odds,
					status,
					order,
					name: `${name}|${odds[0].specialValue}`,
					splitTypes,
					hasBoundaries,
				});
			});
		}
	});

	return separatedMarkets || [];
}

export const sortOn = <T>(prop: string, list: string[]) => {
	const order = list.reduce((obj, key, idx) => Object.assign(obj, { [key]: idx + 1 }), {});
	const getVal = (item: T) => order[item[prop]] || Infinity;

	return (a: T, b: T) => getVal(a) - getVal(b);
};

interface Styles {
	[key: string]: React.CSSProperties;
}
export const styles: Styles = {
	form: {
		justifyContent: 'flex-start',
		width: 'auto',
		marginRight: '20px',
	},
	storno: {
		justifyContent: 'flex-start',
		width: 'auto',
		marginRight: '0px',
		padding: '0px',
	},
};
