import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelectDropdown from '../../../../../components/general/MultiSelectDropdown';
import { haveSameContents } from '../../../../../utils/haveSameContents';

import { useGeo } from '../../context';

interface Props {
	locked: string;
	rowItem: { id: string; value: any; inherited: any };
	specifics: any;
}
export const GeoMultiSelect: FC<Props> = ({ locked, rowItem, specifics }) => {
	const { t } = useTranslation();
	const { updateGeoParameters } = useGeo();
	const { id, value, inherited } = rowItem;
	const selected: string[] = locked ? inherited : value;

	const selectedArray =
		selected === null
			? []
			: haveSameContents(selected, specifics.choices)
			? (['all', ...specifics.choices] as string[])
			: selected;

	const handleSelect = (options: string[]) => {
		const param = options.filter((option) => option !== 'all');
		updateGeoParameters(id, param.length ? param : []);
	};

	return (
		<div className={`value_content flex_direction_row ${locked ? 'disabled' : ''}`}>
			<input
				id={id}
				name={id}
				type="text"
				readOnly
				value={selectedArray.filter((option: string) => option !== 'all')}
				className={`normal full_w ${locked}`}
			/>

			<MultiSelectDropdown
				options={...specifics.choices.map((choice: string) => ({ id: choice, name: choice }))}
				selectedOptions={selectedArray}
				selectOption={handleSelect}
				title={t('general.selectOptions')}
				marginRight={'0'}
			/>
		</div>
	);
};
