import React, { FC } from 'react';
import { ChangeItem } from '../../../types';
import Modal from '../../../../../../../components/Modals';
import { formatDate } from '../../../../../../../utils/getDate';
import { useTranslation } from 'react-i18next';

interface Props {
	changeItem: ChangeItem;
	close: () => void;
}

export const ChangeDetailsModal: FC<Props> = ({ changeItem, close }) => {
	const { t } = useTranslation();
	const changes = changeItem?.change?.changes || {};
	const [date, time] = formatDate(changeItem?.dtCreated, 'date.month.year.', 'hours:minutes');
	const operater = changeItem?.userCreated || '';
	return (
		<Modal
			opened={true}
			size="lg"
			align="center"
			direction="column"
			cancelAction={close}
			header={
				<h2 className="modal_title padding_left_20">
					<span className="padding_right_10 font_">
						{t('usersAdministrations.operator')}: {operater}
					</span>
					<span className="padding_right_10">
						- {date} | {time}
					</span>
				</h2>
			}
			body={
				<>
					<div className="timeline">
						<div className="timeline_item">
							<div className="time_point">{/* <div className="point"></div> */}</div>
							<div className="description uppercase">{t('usersAdministrations.field')}</div>
							<div className="description uppercase">{t('usersAdministrations.from')}</div>
							<div className="description uppercase">{t('usersAdministrations.to')}</div>
						</div>
						{Object.entries(changes).map(([field, { from, to }], index) => (
							<div className="timeline_item" key={index}>
								<div className="time_point">
									<div className="point"></div>
								</div>
								<div className="description">{field}</div>
								<div className="description">{`${from}`}</div>
								<div className="description">{`${to}`}</div>
							</div>
						))}
					</div>
				</>
			}
		/>
	);
};
