// Import config
import { Types, NameTypes } from '../../pages/administration/applicationType/types';

import { axiosInstance, Response } from '../config';

interface TypeParams {
	skip?: number;
	limit?: number;
}

interface AppParams {
	applicationName?: string;
	withoutApplicationTypeOnly?: boolean;
	idProvider: string;
	providerCode: string;
	skip?: number;
	limit?: number;
}

export const applicationsTypes = {
	getTypes(params: TypeParams) {
		return axiosInstance
			.get('administration/external_providers/application_types', { params })
			.then((res) => res.data);
	},

	addType(newType: Types) {
		return axiosInstance
			.post('administration/external_providers/application_types', {
				...newType,
			})
			.then((res) => {
				return res.data;
			});
	},

	updateType(id: number, type: NameTypes) {
		return axiosInstance.post(`administration/external_providers/application_types`, { id, ...type }).then((res) => {
			return res.data;
		});
	},

	deleteType(id: number) {
		return axiosInstance.delete(`/administration/external_providers/application_types/${id}`).then((res) => res.data);
	},

	getApplications(params: AppParams) {
		return axiosInstance.get('/administration/external_providers/applications', { params }).then((res) => res.data);
	},

	updateApplicationsfromProvider(appType: any) {
		return axiosInstance
			.post(`administration/external_providers/update_application_type`, { ...appType })
			.then((res) => {
				return res.data;
			});
	},
};
