import axios from 'axios';
import { TicketItemExtended } from './../../pages/reports/tickets/types';

import { axiosInstance, CANCELED_REQUEST } from './../config';

const CancelToken = axios.CancelToken;
let cancel: any;

export const tickets = {
	getHistoryTicket(id: string): Promise<TicketItemExtended> {
		cancel && cancel(CANCELED_REQUEST);
		return axiosInstance
			.get(`/administration/tickets/${id}`, {
				cancelToken: new CancelToken(function executor(c) {
					cancel = c;
				}),
			})
			.then((res) => res.data);
	},

	modifyTicket(ticket: TicketItemExtended) {
		return axiosInstance
			.post(`/administration/tickets/edit/${ticket.id}`, { systems: ticket.systems, message: '' })
			.then((res) => res.data);
	},

	stornoTicket(id: string, message: string) {
		return axiosInstance.post(`/administration/tickets/withdraw/${id}?message=${message}`).then((res) => res.data);
	},

	manualCashoutOffer(idTicket: string, cashoutAmount: number, dtExpires: string) {
		return axiosInstance.post(`/cashout/create`, { idTicket, cashoutAmount, dtExpires }).then((res) => res.data);
	},

	eventsOddtypesCurrentDetails(payload: any) {
		return axiosInstance.post(`/administration/events/events_oddtype_details/`, payload).then((res) => res.data);
	},
	getTicketPinCode(ticketId: string) {
		return axiosInstance(`/administration/tickets/pin/${ticketId}`).then((res) => res.data);
	},
	getTicketPromotionPinCode(ticketId: string) {
		return axiosInstance(`/administration/tickets/promotion_codes/${ticketId}`).then((res) => res.data);
	},
};
