import { PlayerPropsScore, SpecialMarket } from './../../pages/administration/special-markets/types';
import { axiosInstance } from './../config';
import { MarketItem } from './../../types/MarketsAdministration';
import { OddUpdateType } from './../../pages/prematch/controller/types';
import mockAxios from '../../__mocks__/axios';

// ============ Scheme ============ //

export type BaseColorId = string;
export type ColorId = string;

type GroupField = {
	id: ColorId;
	// name: string;
	// type: string;
	description: string;
};

type Field = {
	group: string;
	groupFields: GroupField[];
};

export type SchemeDTO = {
	baseColors: BaseColorId[];
	themeFields: Field[];
	baseColorFields: Record<BaseColorId, ColorId[]>; // Fallback color map
};

const getScheme = async ({ deviceType }: { deviceType: string }): Promise<SchemeDTO> => {
	return axiosInstance.get(`/administration/themes/schema/${deviceType}`).then((res) => res.data);
};

// ============ Get Theme ============ //

export type ThemeDTO = {
	name: string;
	baseColors: Record<string, string>;
	fieldColors: Record<string, string>;
};
const getTheme = async (themeId: number): Promise<ThemeDTO> => {
	return axiosInstance.get(`/administration/themes/${themeId}`).then((res) => res.data);
};

// ============ Get Theme Previews ============ //
type ThemeFilterData = {
	name?: string;
	deviceType: string;
	skip: number;
	limit: number;
};

export type ThemePreviewDTO = {
	id: number;
	name: string;
	createdBy: string;
	dtUpdated: string | null;
	dtCreated: string;
	updatedBy: string | null;
};
const getThemePreviews = async (params: ThemeFilterData): Promise<{ total: number; themes: ThemePreviewDTO[] }> => {
	return axiosInstance.post(`/administration/themes/filtered_themes`, params).then((res) => res.data);
};

// ============= Edit Theme ================ //
type EditThemePayload = { themeId: number; theme: ThemeDTO };
const editTheme = async (params: EditThemePayload) => {
	return axiosInstance.post(`/administration/themes/${params.themeId}`, params.theme).then((res) => {
		return res.data;
	});
};

// ============= Create Theme ================= //
interface CreateThemePayload extends ThemeDTO {
	deviceType: string;
}

const addTheme = async (newTheme: CreateThemePayload) => {
	return axiosInstance.post(`/administration/themes/`, newTheme).then((res) => {
		return res.data;
	});
};

// ============= Delete Theme ================= //
const deleteTheme = async (themeId: number) => {
	return axiosInstance.delete(`/administration/themes/${themeId}`).then((res) => {
		return res.data;
	});
};

export const themes = {
	getScheme,
	getTheme,
	getThemePreviews,
	editTheme,
	addTheme,
	deleteTheme,
};
