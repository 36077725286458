// Import config
import { axiosInstance, Response } from './../config';
// Import utils
import createMultipart from '../../pages/administration/utils/createMultipart';
// Import types
import { CompetitorItem, NewCompetitorItem } from '../../pages/administration/competitors/types';

export const competitors = {
	getCompetitors(sportId: string, query: string = '', limit: number = 1000, skip: number = 0): Response {
		return axiosInstance
			.get(`/administration/competitors/`, {
				params: { limit, sportId, q: query, skip },
			})
			.then((res) => res.data);
	},
	getFuzzyCompetitors(sportId: string, query: string = '', limit: number = 3): Response {
		return axiosInstance
			.get(`/administration/competitors/fuzzy`, {
				params: { limit, sportId, query },
			})
			.then((res) => res.data);
	},
	getCompetitor(id: string): Response {
		return axiosInstance.get(`/administration/competitors/${id}`).then((res) => res.data);
	},
	getCompetitorPlayers(id: string): Response {
		return axiosInstance.get(`/administration/competitors/${id}/players`).then((res) => res.data);
	},
	createCompetitors(competitor: any): Promise<CompetitorItem> {
		return axiosInstance.post(`/administration/competitors/`, createMultipart(competitor)).then((res) => {
			return res.data;
		});
	},
	updateCompetitors(competitor: NewCompetitorItem, competitorID: string | number): Promise<any> {
		return axiosInstance
			.put(`/administration/competitors/` + competitorID, createMultipart(competitor))
			.then((res) => {
				return res.data;
			});
	},
};
