import React, { FC } from 'react';

interface Props {
	label: string;
	selectedSort: string;
	selectSort: (sort: string) => void;
	ascending: string;
	descending: string;
	labelClass?: string;
	tdClass?: string;
}

export const SortCell: FC<Props> = ({
	label,
	selectedSort,
	selectSort,
	ascending,
	descending,
	labelClass = '',
	tdClass = '',
}) => {
	return (
		<td scope="col" className={`${tdClass}`}>
			<div className="resort_coulmn">
				<span className={`label font_700 ${labelClass}`}>{label}</span>

				{selectedSort !== ascending && selectedSort !== descending ? (
					<i className="mdi mdi-menu-swap" onClick={() => selectSort(ascending)} />
				) : null}

				{selectedSort === ascending ? (
					<i className="mdi mdi-menu-up" onClick={() => selectSort(descending)} />
				) : null}

				{selectedSort === descending ? (
					<i className="mdi mdi-menu-down" onClick={() => selectSort(ascending)} />
				) : null}
			</div>
		</td>
	);
};
interface PropsMulti {
	label: string;
	selectedSort: string[];
	selectSort: (sort: string[]) => void;
	ascending: string;
	descending: string;
	labelClass?: string;
	tdClass?: string;
	divClass?: string;
}

export const SortCellMulti: FC<PropsMulti> = ({
	label,
	selectedSort,
	selectSort,
	ascending,
	descending,
	labelClass = '',
	tdClass = '',
	divClass = '',
}) => {
	return (
		<td scope="col" className={`${tdClass}`}>
			<div className={`resort_coulmn ${divClass}`}>
				<span className={`label font_700 ${labelClass}`}>{label}</span>

				{!selectedSort.includes(ascending) && !selectedSort.includes(descending) ? (
					<i className="mdi mdi-menu-swap" onClick={() => selectSort([ascending])} />
				) : null}

				{selectedSort.includes(ascending) ? (
					<i className="mdi mdi-menu-up" onClick={() => selectSort([descending])} />
				) : null}

				{selectedSort.includes(descending) ? (
					<i className="mdi mdi-menu-down" onClick={() => selectSort([ascending])} />
				) : null}
			</div>
		</td>
	);
};
