import React, { useState, useEffect, FC } from 'react';
import { useUser } from '../../../../context/UserProvider';

interface Props {
	subject?: string;
	bypassCasl?: boolean;
}

export const DropdownWrapper: FC<Props> = ({ children, subject = 'offer', bypassCasl = false }) => {
	const [showDropdown, setDropdown] = useState(false);

	const { user } = useUser();

	useEffect(() => {
		if (user) {
			setDropdown(
				user.can.some((ability) => {
					return ability.subject === subject;
				})
			);
		}
	}, []);

	return <>{(showDropdown || bypassCasl) && children}</>;
};
