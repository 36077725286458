import React, { useState, useEffect, Dispatch } from 'react';
import api from '../../api';
import Dropdown from './Dropdown';
import { useTranslation } from 'react-i18next';
import { Sport } from '../../pages/administration/sports/types';
import { useNotify } from '../../hooks/useNotifications';

interface Props {
	selectedSport: string | number;
	disabled: string;
	selectSport: Dispatch<string>;
	isDisabled?: boolean;
}

export default function SportDropdown({ selectSport, disabled, selectedSport, isDisabled }: Props) {
	const { t, i18n } = useTranslation();
	const { language: ln } = i18n;
	const [sports, setSports] = useState<Sport[]>([]);

	const { error } = useNotify();

	useEffect(() => {
		(async () => {
			try {
				// Fetch the list of sports
				const data = await api.sports.getList('ALL');

				if (!data || !data.length) return;

				setSports(data);
			} catch (err: any) {
				const errorText =
					err?.response?.data.detail?.[0]?.msg || err?.response?.data?.detail || t('toast.error.defaultText');
				error(errorText);
			}
		})();
	}, []);

	return (
		<Dropdown
			items={sports.map((sport) => ({ id: sport.id, name: sport?.name?.[ln] || sport.defaultName }))}
			margins={['0', '0', '0', '20']}
			selectItem={selectSport}
			selectedItem={selectedSport}
			icon={'mdi mdi-menu-down'}
			nonSelectedString={t('general.selectSport')}
			className={disabled}
			listClass="list_6_items"
			isDisabled={isDisabled}
		/>
	);
}
