import React, { FC, useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useData } from '../../../context/DataProvider';
import { Provider } from '../../system/integrations/types';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

interface Props {
	field: string;
}

export const SelectProvider: FC<Props> = ({ field }) => {
	const { providersMap } = useData();

	const { getValues, setValue } = useFormContext();
	const value = getValues(field);

	const [selectedProvider, setSelectedProvider] = useState<string>(value);

	useEffect(() => {
		if (!value) {
			const { id } = providersMap[0];
			setSelectedProvider(id);
			setValue(field, id);
		}
	}, []);

	const handleSelect = (provider: Provider) => {
		setSelectedProvider(provider.name);
		setValue(field, provider.id);
	};

	const [open, setOpen] = useState(false);
	const dropdownClass = open ? 'expanded' : '';

	const dropdownRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(dropdownRef, () => setOpen(false));

	return (
		<div className="selectbox_wrapper ">
			<div
				ref={dropdownRef}
				className={`selectbox_dropdown ${dropdownClass}`}
				onClick={() => {
					setOpen(!open);
				}}
			>
				<div className="selected">
					<div className="selectbox_list_item">
						<div className="select_label">{selectedProvider}</div>
					</div>
					<i className="mdi mdi-chevron-down" />
				</div>

				<div className="selectbox_list">
					{providersMap &&
						providersMap.map((provider) => (
							<div key={provider.id} className="selectbox_list_item" onClick={() => handleSelect(provider)}>
								<div className="select_label">{provider.name}</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};
