import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import { formatDate } from '../../../utils/getDate';
import { useOnlineUser } from '../context/OnlineUserContext';

export const SubHeader: FC = () => {
	const { t } = useTranslation();
	const { onlineUser } = useOnlineUser();

	const {
		email,
		firstName,
		lastName,
		birthDate,
		city,
		country,
		geolocation,
		lastIpAddress = '',
		lastActive = '',
		created,
		nationalId,
		idCard,
		passport,
	} = onlineUser;

	const location = geolocation?.name || '';

	const registrationDate = created ? formatDate(created, 'date.month.year. hours:minutes') : '';
	const lastActiveTime = lastActive ? formatDate(lastActive, 'date.month.year. hours:minutes:seconds') : '';

	return (
		<Fragment>
			<div className="user_overview_details w_1800">
				<div className="col-3">
					<div className="info_row">
						<div className="label">{`${t('usersAdministrations.email')}:`}</div>
						<div className="info">{email}</div>
					</div>
					<div className="info_row">
						<div className="label">{`${t('usersAdministrations.name')}:`}</div>
						<div className="info">{`${firstName} ${lastName}`}</div>
					</div>
				</div>

				<div className="col-3">
					<div className="info_row">
						<div className="label">{`${t('usersAdministrations.birthDate')}:`}</div>
						<div className="info">{birthDate}</div>
					</div>
					<div className="info_row">
						<div className="label">{`${t('usersAdministrations.cityCountry')}:`}</div>
						<div className="info">{`${city}, ${country}`}</div>
					</div>
				</div>

				<div className="col-3">
					<div className="info_row">
						<div className="label">{`${t('usersAdministrations.registrationDate')}:`}</div>
						<div className="info">{registrationDate}</div>
					</div>
					<div className="info_row">
						<div className="label">{`${t('usersAdministrations.baseLocation')}:`}</div>
						<div className="info">{location}</div>
					</div>
				</div>

				<div className="col-2">
					<div className="info_row">
						<div className="label">{`${t('usersAdministrations.lastIp')}:`}</div>
						<div className="info">{lastIpAddress}</div>
					</div>
					<div className="info_row">
						<div className="label">{`${t('usersAdministrations.lastActive')}:`}</div>
						<div className="info">{lastActiveTime}</div>
					</div>
				</div>
				<div className="col-2">
					<div className="info_row">
						<div className="label">{`${t('usersAdministrations.jmbg')}:`}</div>
						<div className="info">{nationalId}</div>
					</div>
					<div className="info_row">
						<div className="label">
							{idCard ? `${t('usersAdministrations.idCard')}:` : `${t('usersAdministrations.passport')}:`}
						</div>
						<div className="info">{idCard ? idCard : passport}</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};
