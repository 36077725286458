import { useReducer } from 'react';
import { reducer, initialState } from './reducer';

export const useFilter = () => {
	const [typesState, dispatch] = useReducer(reducer, initialState);
	const { accountTypes, reportTypes } = typesState;

	const toggleType = (type: string) => {
		dispatch(type);
	};

	return { accountTypes, reportTypes, toggleType };
};
