import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../../api';
import { useNotify } from '../../../../../../hooks/useNotifications';
import { Item } from '../../../../../../types/general';
import { haveSameContents } from '../../../../../../utils/haveSameContents';
import { useGeo } from '../../../context';

interface Props {
	locked: string;
	rowItem: { id: string; value: any; inherited: any };
	specifics: any;
}
export const ReferenceMultiSelect: FC<Props> = ({ locked, rowItem, specifics }) => {
	const { t } = useTranslation();
	const { notify } = useNotify();

	const { updateGeoParameters, geoParameters } = useGeo();

	const { value, inherited } = rowItem;
	const { value_rule, delimiter, uri } = specifics;

	const [values, setValues] = useState<Item[]>([]);
	const [selectedValues, setSelectedValues] = useState<string[]>([]);

	const [errorMessage, setErrorMessage] = useState('');

	const isAppend = value_rule === 'APPEND';
	const disabledList = isAppend && delimiter ? (inherited ? inherited.split(delimiter) : []) : [];

	useEffect(() => {
		(async function getData() {
			try {
				const { values } = await api.geo.getReferenceFieldData({ idGeoEntity: geoParameters.id }, uri);
				setValues(
					values.map(([id, name]: string[]) => ({
						id,
						name,
					}))
				);
				setErrorMessage('');
			} catch (err: any) {
				let errorText = 'toast.error.defaultText';
				if (err.response && err.response.data)
					errorText = err.response.data.detail[0].msg || err.response.data.detail;
				setErrorMessage(errorText);
			}
		})();
	}, [specifics, geoParameters.id]);

	useEffect(() => {
		if (values.length) {
			const selected = locked ? inherited?.split(delimiter) || [] : value?.split(delimiter) || [];
			setSelectedValues(
				haveSameContents(
					selected,
					values.map(({ id }) => id)
				)
					? (['all', ...values.map(({ id }) => id)] as string[])
					: selected
			);
		}
	}, [values, rowItem, locked]);

	const handleChange = async (e: ChangeEvent<HTMLInputElement>, id: string) => {
		try {
			let options = [...selectedValues];
			if (!e.target.checked) options = options.filter((item) => item !== id);
			else options = [...options, id];

			await api.geo.verifyReferenceFieldData(
				{ idGeoEntity: geoParameters.id, refFields: options.join(delimiter) },
				uri
			);
			updateGeoParameters(rowItem.id, options.length ? options.join(delimiter) : undefined);
		} catch (err: any) {
			let errorText = t('toast.error.defaultText');
			if (err.response && err.response.data) errorText = err.response.data.detail[0].msg || err.response.data.detail;
			notify(errorText, 'error', { autoClose: 3000, style: { backgroundColor: '#fd9800' } });
		}
	};

	return (
		<div className={`value_content  ${locked ? 'disabled' : ''}`}>
			{!errorMessage ? (
				<>
					{values.map(({ id, name }, i) => {
						const checked = selectedValues.includes(id);
						const disabled = disabledList.includes(id) ? 'disabled' : '';
						return (
							<div key={`${id}:${name}:${i}`} className={`checkbox_custom col-4 ${disabled}`}>
								<input
									type="checkbox"
									id={`${id}:${name}:${i}`}
									checked={checked || !!disabled}
									onChange={(e) => {
										handleChange(e, id);
									}}
								/>
								<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
								<i className="mdi mdi-checkbox-marked checked"></i>
								<label htmlFor={`${id}:${name}:${i}`} className="right ">
									{name}
								</label>
							</div>
						);
					})}
				</>
			) : (
				<p>{t(errorMessage)}</p>
			)}
		</div>
	);
};
