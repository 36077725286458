import React, { FC, useEffect } from 'react';
import api from '../../../../../api';
import { useGeo } from '../../context';
import { NodeType } from '../../types';
import { TreeItem } from './GeoTreeItem';
import SimpleBarReact from 'simplebar-react';
import { useDebounce } from 'use-debounce/lib';
import icons from '../../../../../consts/icons';
import { handleEntity, transformGeoData } from '../../utils';
import { SimpleLoader } from '../../../../../components/general/Loader';
import { Height } from '../../../../administration/live/components/Height';
import { Context, Item, useTreeOptions } from '../../../../../components/general/Tree';
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../../../../hooks/useNotifications';
import { debounce } from 'lodash';
import getErrorText from '../../../../../utils/getErrorMsg';

export const GeoTree: FC = () => {
	return (
		<SimpleBarReact className="custom_scroll secondary_sidebar  col_border_right" autoHide={false}>
			<Height>
				<FullTree />
			</Height>
		</SimpleBarReact>
	);
};

const FullTree: FC = () => {
	const { selectNode, treeSearch, treeLoader, setTreeLoader, modified, saveWarning } = useGeo();
	const treeOptions = useTreeOptions();
	const searchTreeOptions = useTreeOptions();
	const { items, generateContext, updateActive } = treeOptions;

	const [debouncedText] = useDebounce(treeSearch, 500);

	const { t } = useTranslation();
	const { error } = useNotify();

	// Init Tree on startup
	useEffect(() => {
		const initLocalTree = async () => {
			try {
				setTreeLoader(true);
				const clients = await api.geo.getEntity('CLIENT');

				const clientItems: Item[] = clients.map((client) => ({
					items: [],
					id: client.id,
					type: 'CLIENT',
					expandable: true,
					name: client.name,
					expandIcon: icons.arrowRight,
					contractIcon: icons.arrowDown,
				}));
				treeOptions.updateItems(clientItems);
				setTreeLoader(false);
			} catch (err) {
				error(getErrorText(err) || 'toast.error.defaultText');
			}
		};

		initLocalTree();
	}, [setTreeLoader]);

	useEffect(() => {
		if (debouncedText.length <= 2) return;
		(async () => {
			try {
				setTreeLoader(true);
				const data = await api.geo.searchGeo(debouncedText);

				const { items, expanded } = transformGeoData(data);
				searchTreeOptions.updateItems(items).updateExpanded(expanded).updateActive([]);

				updateActive([]);
				selectNode(null);
				setTreeLoader(false);
			} catch (err: any) {
				error(getErrorText(err) || 'toast.error.defaultText');
			}
		})();
	}, [debouncedText]);

	const treeHandler = async (ctx: Context) => {
		const { item, activate } = ctx;
		const { type, name, id } = item;
		if (modified) return saveWarning();
		switch (type) {
			case 'CLIENT':
				return handleEntity(ctx, selectNode, 'COMPANY');

			case 'COMPANY':
				return handleEntity(ctx, selectNode, 'REGION');

			case 'REGION':
				return handleEntity(ctx, selectNode, 'GROUP');

			case 'GROUP':
				return handleEntity(ctx, selectNode, 'LOCATION');

			case 'LOCATION':
				return handleEntity(ctx, selectNode, 'DEVICE');

			default:
				selectNode({ id, name, entityType: type as NodeType, shortCode: 0 });
				activate();
		}
	};

	return (
		<SimpleLoader loaded={!treeLoader}>
			<div className="tree_view">
				{debouncedText.length > 2
					? searchTreeOptions.items.map((item: Item, index: number) => {
							const ctx = searchTreeOptions.generateContext(`${index}`, item);

							return (
								<TreeItem
									index={index}
									key={item.id}
									nested={false}
									context={ctx}
									handleChange={treeHandler}
									generateContext={searchTreeOptions.generateContext}
								/>
							);
					  })
					: items.map((item: Item, index: number) => {
							const ctx = generateContext(`${index}`, item);

							return (
								<TreeItem
									index={index}
									key={item.id}
									nested={false}
									context={ctx}
									handleChange={treeHandler}
									generateContext={generateContext}
								/>
							);
					  })}
			</div>
		</SimpleLoader>
	);
};
