import { TournamentItem } from './../../administration/tournaments/types';
import { CompetitorItem } from './../../administration/competitors/types';
import { PairingMethods, extract, timeSort } from '../_framework';
import { Item } from '../types';
import api from '../../../api';
import { scrollToBottom } from '../../../utils/scrollToBottom';

type This = PairingMethods<{
	toggleProvidedVisibility: (providedId: string) => void;
	updateProviderItems: (updatedItems: Item[]) => void;
}>;

export default {
	pairProvided(this: This, localId: string, providedId: string) {
		const {
			local,
			provided,
			setLocal,
			setStaged,
			setLocalItem,
			toggleProvidedVisibility,
			setProviderFuzzy,
			setLocalFuzzy,
		} = this;

		const [localItem, localLIst] = extract(local, localId);
		const [providedItem] = extract(provided, providedId);

		if (!localItem || !providedItem) return;

		const stagedItem = {
			...localItem,
			pairedWith: [providedItem],
		};

		setLocal(localLIst);
		setLocalItem({} as Item);
		setLocalFuzzy({} as Item);
		setProviderFuzzy({} as Item);
		toggleProvidedVisibility(providedItem.id);
		setStaged((staged) => [...staged, stagedItem]);

		// Scroll to the bottom of staged list when we add item to it
		setTimeout(() => {
			scrollToBottom('#staged-list');
		}, 200);
	},

	cancelPairing(this: This, localId: string) {
		const { setLocal, staged, setStaged, toggleProvidedVisibility } = this;
		const [stagedItem, updatedStaged] = extract(staged, localId);

		if (!stagedItem) return;
		const { pairedWith, ...localItem } = stagedItem;
		const [providedItem] = pairedWith;

		setStaged(updatedStaged);
		toggleProvidedVisibility(providedItem.id);
		setLocal((local) => timeSort([...local, { ...localItem, pairedWith: [] }]));
	},

	async confirmPairing(this: This, localId: string, pairedId: string, callback: () => void, isLive = true) {
		const { startLoading, stopLoading, staged, setStaged, setPaired, provider } = this;

		const [stagedItem, updatedStaged] = extract(staged, localId);
		if (!stagedItem) return;

		try {
			startLoading(localId);
			await api.mappings.events.linkEvents(provider, localId, pairedId, isLive);
			setPaired((paired) => timeSort([...paired, stagedItem]));
			setStaged(updatedStaged);
		} catch (err) {
		} finally {
			stopLoading(localId);
			callback();
		}
	},

	async unpair(this: This, localId: string, pairedId: string, callback: () => void, isLive = true) {
		const { startLoading, stopLoading, paired, setPaired } = this;
		const { setLocal, provider, toggleProvidedVisibility } = this;

		try {
			startLoading(localId);
			await api.mappings.events.unpairEvents(provider, pairedId, isLive);

			const [pairedItem, updatedPaired] = extract(paired, localId);
			if (!pairedItem) return;

			const { pairedWith, ...localItem } = pairedItem;
			const [providedItem] = pairedWith;

			setPaired(updatedPaired);
			toggleProvidedVisibility(providedItem.id);
			setLocal((local) => timeSort([...local, { ...localItem, pairedWith: [] }]));
		} catch (err) {
		} finally {
			stopLoading(localId);
			callback();
		}
	},

	extractProvidedItem(this: This, providedId: string) {
		const { provided, setProvided } = this;
		const [_, updatedProvided] = extract(provided, providedId);
		setProvided(updatedProvided);
	},

	directlyPairProvided(this: This, importedItem: Item) {
		const { toggleProvidedVisibility, setPaired, setProviderFuzzy } = this;
		setProviderFuzzy({} as Item);
		toggleProvidedVisibility(importedItem.pairedWith[0].id);
		setPaired((paired) => timeSort([...paired, importedItem]));
	},

	/**
	 * Helper function for setting "mapped" property on the provider item to true/false
	 */
	toggleProvidedVisibility(this: This, providedId: string) {
		this.setProvided((provided) =>
			provided.map((item) => {
				if (item.id !== providedId) return item;
				return {
					...item,
					mapped: !item.mapped,
				};
			})
		);
	},

	updateProviderItems(
		this: This,
		providerItem: Item,
		tournamentData: TournamentItem,
		home: CompetitorItem,
		away: CompetitorItem
	) {
		const { provided, setProvided } = this;

		const updatedProviderItems = provided.map((item) => {
			const tournament =
				item.tournament.id === providerItem.tournament.id
					? {
							...item.tournament,
							mapped: true,
							mappedTo: { id: tournamentData.id, name: tournamentData.defaultName },
					  }
					: item.tournament;

			// Update mapped competitors
			let competitors = item.competitors.map((competitor: CompetitorItem) => {
				if (competitor.id === providerItem.competitors[0].id)
					return { ...competitor, mapped: true, mappedTo: { id: home.id, name: home.defaultName } };

				if (competitor.id === providerItem.competitors[1].id)
					return { ...competitor, mapped: true, mappedTo: { id: away.id, name: away.defaultName } };
				return competitor;
			});
			// Return updated item
			return { ...item, competitors, tournament };
		});

		setProvided(updatedProviderItems);
	},
};
