import { axiosInstance } from '../config';
import { TemplateParams } from './types';

export const notificationTemplates = {
	getTemplates(skip: number = 0, limit: number = 100, orderBy: string[] = ['DT_CREATED_ASC'], query: string = '') {
		return axiosInstance
			.post('/support/notification_templates', { skip, limit, orderBy, query: query === '' ? null : query })
			.then((res) => res.data);
	},

	addTemplate(template: TemplateParams) {
		return axiosInstance.post('/support/notification_template/add', template).then((res) => {
			return res.data;
		});
	},

	updateTemplates(id: number, template: TemplateParams) {
		return axiosInstance
			.put(`/support/notification_template`, template, { params: { notification_template_id: id } })
			.then((res) => {
				return res.data;
			});
	},

	deleteTemplate(id: number) {
		return axiosInstance.delete(`/support/${id}`).then((res) => res.data);
	},
};
