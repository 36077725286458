import React, { FC } from 'react';
import { UserBalance } from './UserBalance';
import { UserSum } from '../../users/types';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../utils/getDate';

interface Props {
	userSum: UserSum;
}
export const UserInfo: FC<Props> = ({ userSum }) => {
	const { t } = useTranslation();
	const { email, firstName, lastName, city, created, code, status, country } = userSum.user;
	const [date, time] = formatDate(created || '', 'date.month.year', 'hours:minutes:seconds');
	const active = status === 'ACTIVE' ? t('general.yes') : t('general.no');
	const activeClass = status === 'ACTIVE' ? 'dark_green_text' : 'redish_text';
	return (
		<div className="title_info_block">
			<div className="info_wrapper">
				<div className="general_info info_col">
					<div className="info_row">
						<div className="info_label">{t('usersAdministrations.userName')}</div>
						<div className="info_content">{email}</div>
					</div>

					<div className="info_row">
						<div className="info_label">{t('usersAdministrations.name')}</div>
						<div className="info_content">
							<span className="first_name">{firstName}</span>
							<span className="last_name">{lastName}</span>
						</div>
					</div>

					<div className="info_row">
						<div className="info_label">{t('usersAdministrations.email')}</div>
						<div className="info_content">{email}</div>
					</div>

					<div className="info_row">
						<div className="info_label">{t('usersAdministrations.city')}</div>
						<div className="info_content">{city}</div>
					</div>

					<div className="info_row">
						<div className="info_label">{t('usersAdministrations.country')}</div>
						<div className="info_content">{country}</div>
					</div>
				</div>
				<div className="general_info info_col">
					<div className="info_row">
						<div className="info_label">{t('usersAdministrations.regDate')}</div>
						<div className="info_content">
							<span className="date">{date}</span>
							<span className="time">{time}</span>
						</div>
					</div>

					<div className="info_row">
						<div className="info_label">{t('usersAdministrations.userId')}</div>
						<div className="info_content">{code}</div>
					</div>

					<div className="info_row">
						<div className="info_label">{t('general.active')}</div>
						<div className={`info_content ${activeClass}`}>{active}</div>
					</div>

					{/* <div className="info_row">
						<div className="info_label">{t('usersAdministrations.lastActive')}</div>
						<div className="info_content">
							<span className="date"></span>
							<span className="time"></span>
						</div>
					</div>
					<div className="info_row">
						<div className="info_label">{t('usersAdministrations.lastIp')}</div>
						<div className="info_content">91.150.100.159</div>
					</div> */}
				</div>
				<UserBalance summary={userSum.summary} />
			</div>
		</div>
	);
};
