import React from 'react';
import { Context } from '../types';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

interface Props {
	context: Context;
	handleChange: (ctx: Context) => void;
	draggable: boolean;
	dragHandleProps: DraggableProvidedDragHandleProps | null;
}

export default ({ context, handleChange, draggable, dragHandleProps }: Props) => {
	// Destructure properties from context and item
	const { item, isActive, isExpanded } = context;
	const { name, label, icon, expandable, activeIcon, expandIcon, contractIcon, dragIcon, loading, error } = item;

	// Class for selected non-expandable item
	const activeClass = isActive ? 'tree_item_cont active' : 'tree_item_cont';

	// Set expand icon based on the expanded state (provide fallback for contractIcon)
	const expandIconClass = isExpanded ? contractIcon || expandIcon : expandIcon;

	// Set main icon based on the active state (provide fallback for activeIcon)
	const iconClass = isActive ? activeIcon || icon : icon;

	// If handleChange is not provided set action to toggle() otherwise pass target name to handleChange()
	const action = (target: string) => {
		return () => {
			handleChange({ ...context, target });
		};
	};

	return (
		<div className={activeClass}>
			{/* Render main icon if provided */}
			{expandable && (
				<div className="tree_item_ico" onClick={action('expand')}>
					<i className={`mdi mdi-${expandIconClass}`} />
				</div>
			)}

			{icon && (
				<div className="tree_item_ico" onClick={action('icon')}>
					<i className={`mdi mdi-${iconClass}`} />
				</div>
			)}

			{/* Render drag icon if item is draggable */}
			{draggable ? (
				<div className="tree_item_ico" {...dragHandleProps}>
					<i className={`mdi mdi-${dragIcon || 'drag-vertical'}`} />
				</div>
			) : (
				// If item is not draggable, render span with dragHandleProps to prevent errors
				<span {...dragHandleProps} />
			)}

			{/* Render name of the item */}
			<div className="tree_label" onClick={action('name')}>
				{name}
			</div>

			{/* Render loader */}
			{loading && (
				<div className="tree_item_ico">
					<i className="mdi mdi-rotate-right mdi-spin" />
				</div>
			)}

			{/* Render error */}
			{error && (
				<div className="tree_item_ico tooltip_left">
					<i className="mdi mdi-progress-alert text_red" />
					<span className="tooltip_content red">Invalid Action</span>
					<div />
				</div>
			)}

			{/* Render label */}
			{label && <div className="events_no">{label}</div>}
		</div>
	);
};
