import React, { FC } from 'react';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import { Transaction } from '../../types';
import { useTranslation } from 'react-i18next';
import { TicketItemExtended } from '../../../../reports/tickets/types';

interface Props {
	transactions: Transaction[];
	selectedSort: string;
	selectSort: (sort: string) => void;
	searched: boolean;
	showUserName: boolean;
	selectTicket: React.Dispatch<React.SetStateAction<TicketItemExtended | null>>;
}
export const Table: FC<Props> = ({
	transactions,
	selectedSort,
	selectSort,
	searched,
	showUserName = true,
	selectTicket,
}) => {
	const { t } = useTranslation();

	return (
		<>
			{transactions.length ? (
				<table className="admin_table sticky_header full_w x_scroll__m_0">
					<TableHeader selectedSort={selectedSort} selectSort={selectSort} showUsername={showUserName} />
					<tbody className="t_body">
						{transactions.map((transaction) => (
							<TableRow
								key={transaction.id}
								transaction={transaction}
								showUsername={showUserName}
								selectTicket={selectTicket}
							/>
						))}
					</tbody>
				</table>
			) : !searched ? (
				<div className="margin_right_auto margin_left_auto margin_top_80 blue_text">
					{t('transactions.pressSearch')}
				</div>
			) : (
				<div className="margin_right_auto margin_left_auto margin_top_80 blue_text">
					{t('transactions.noItems')}
				</div>
			)}
		</>
	);
};
