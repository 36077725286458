import { fileNameFromHeaders } from '../../utils/getExportFileName';
import { axiosInstance } from './../config';

interface UsersFilterParams {
	sortKey: string | null;
	idUser: number | null;
	uuidUser: string | null;
	email: string | null;
	nationalId: string;
	firstName: string;
	lastName: string;
	telephone: string;
	locationName: string;
	city: string;
	idLocation: string[] | null;
	reservation: {
		minAmount: number;
		maxAmount: number;
	};
	onlineBalance: {
		minAmount: number;
		maxAmount: number;
	};
	freeBetBalance: {
		minAmount: number;
		maxAmount: number;
	};
	currency: string | null;
	verification_status: string | null;
	limit: number;
	skip: number;
	created: {
		fromValue: string | null;
		toValue: string | null;
	};
}

export const support = {
	getUsers(params: UsersFilterParams) {
		return axiosInstance.post(`/support/users`, params).then(({ data }) => data);
	},
	exportUsers(params: UsersFilterParams): Promise<any> {
		return axiosInstance.post(`/support/users/export`, params).then((response) => {
			return [fileNameFromHeaders(response.headers, 'users-export'), response.data];
		});
	},
};
