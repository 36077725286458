import React, { FC, Fragment, useState } from 'react';
import { StatsBar } from './components/StatsBar';
import { SubHeader } from '../SubHeader';
import { TransactionSums } from './types';
import { GlobalTransactions } from '../../../internet/transactions';
import { useOnlineUser } from '../../context/OnlineUserContext';

export const Transactions: FC = () => {
	const [sums, setSums] = useState<TransactionSums>({
		betIn: 0,
		betOut: 0,
		depositIn: 0,
		depositOut: 0,
		gamingIn: 0,
		gamingOut: 0,
	});

	const {
		onlineUser: { email },
	} = useOnlineUser();

	return (
		<Fragment>
			<GlobalTransactions userEmail={email} setSums={setSums} showUserName={false}>
				<SubHeader />
				<StatsBar {...sums} />
			</GlobalTransactions>
		</Fragment>
	);
};
