import React, { Fragment, FC, useEffect } from 'react';
import { Item } from '../../../types';
import { useTranslation } from 'react-i18next';
import { usePairing } from '../../../_framework';
import { formatDate } from '../../../../../utils/getDate';
import InPortal from '../../../../../components/Modals/InPortal';
import { useKeyboardListener } from '../../../../../hooks/useKeyboardListener';

interface Props {
	confirmItem: Item;
	closeModal: () => void;
	isLive?: boolean;
}

export const ConfirmModal: FC<Props> = ({ confirmItem, closeModal, isLive = true }) => {
	const { t } = useTranslation();
	const { confirmPairing, loading } = usePairing();

	const { id, startTime, pairedWith, tournament, competitors } = confirmItem;
	const [{ id: pairedId, utcScheduled, tournament: pairedTournament }] = pairedWith;

	const [{ name: localHome }, { name: localAway }] = competitors || [{}, {}];
	const [{ name: pairedHome }, { name: pairedAway }] = pairedWith[0].competitors || [{}, {}];

	const [localDate, localTime] = formatDate(startTime, 'date.month.year', 'hours:minutes');
	const [pairedDate, pairedTime] = formatDate(utcScheduled, 'date.month.year', 'hours:minutes');

	const disabled = loading.includes(id);
	const disableClass = disabled ? 'disabled' : '';

	useEffect(() => {
		const el = document.getElementById('pair');
		el?.focus();
	}, []);

	useKeyboardListener('Escape', () => closeModal());
	return (
		<InPortal>
			<div className="modal_cover lg toggle">
				<div className="modal_content fadeInDown animated faster" style={{ minHeight: '0px', width: '800px' }}>
					{/* Header */}
					<button data-testid="close-btn" className="btn transparent close " onClick={closeModal}>
						<i className="mdi mdi-close" />
					</button>
					<div className="modal_head">
						<h2 className="modal_title padding_left_20">{t('eventsPairing.confirm')}</h2>
					</div>

					{/* Body */}
					<div className="modal_body content_start direction_column">
						<div className="divider_horizontal small ">
							<div className="div_label">Local</div>
							<div className="thin_line" style={{ width: '30%' }}></div>
						</div>
						<div
							className="margin_bottom_10 margin_top_10 padding_left_25 padding_right_20"
							style={{ justifyContent: 'space-evenly', flexDirection: 'row', display: 'flex' }}
						>
							<RowItem name={t('general.dateAndTime')} value={`${localDate} | ${localTime}`} />
							<RowItem name={t('general.home')} value={localHome} />
							<RowItem name={t('general.away')} value={localAway} />
							<RowItem name={t('tournamentsPairing.localTournament')} value={tournament.name} />
						</div>

						<div className="divider_horizontal small ">
							<div className="thin_line" style={{ width: '90%' }}></div>
						</div>

						<div
							className="margin_bottom_30 padding_left_25 padding_right_20"
							style={{ justifyContent: 'space-evenly', flexDirection: 'row', display: 'flex' }}
						>
							<RowItem name={t('general.dateAndTime')} value={`${pairedDate} | ${pairedTime}`} />
							<RowItem name={t('general.home')} value={pairedHome} />
							<RowItem name={t('general.away')} value={pairedAway} />
							<RowItem name={t('tournamentsPairing.providerTournament')} value={pairedTournament.name} />
						</div>

						<div className="divider_horizontal small ">
							<div className="div_label">Provider</div>
							<div className="thin_line" style={{ width: '30%' }}></div>
						</div>
					</div>
					{/* Footer */}
					<div data-testid="modal-footer" className="modal_footer">
						<Fragment>
							<button
								disabled={disabled}
								className={`btn md round outline ${disableClass}`}
								onClick={() => closeModal()}
							>
								{t('general.cancel')}
							</button>

							{!disabled ? (
								<button
									id="pair"
									disabled={disabled}
									className={`btn md round green ${disableClass}`}
									onClick={() => {
										confirmPairing(id, pairedId, closeModal, isLive);
									}}
								>
									{t('general.pair')}
								</button>
							) : (
								<button disabled={true} className={`btn md round green`} onClick={() => {}}>
									<i className="mdi mdi-rotate-right mdi-spin" />
								</button>
							)}
						</Fragment>
					</div>
				</div>
			</div>
		</InPortal>
	);
};

interface ItemProps {
	name: string;
	value: string;
}

export const RowItem: FC<ItemProps> = ({ name, value }) => {
	return (
		<div className="padding_10" style={{ width: '25%' }}>
			<div className=" input_label align_center medium direction_column ">
				<small className="margin_bottom_5">{name}</small>
				<strong className="text_center">{value}</strong>
			</div>
		</div>
	);
};
