import React, { FC } from 'react';
import { useGeo } from '../context';

export const DeviceTabs: FC = () => {
	const { selectedNode, geoParameters } = useGeo();

	return (
		<>
			{selectedNode?.name ? (
				<div>
					<h1
						style={{ height: '25px', alignItems: 'flex-start' }}
						className="title_section padding_left_0 margin_right_auto"
					>{`${selectedNode.name} (${selectedNode.entityType})`}</h1>
					<span style={{ fontSize: '11px' }}>{geoParameters?.id || selectedNode.id}</span>
				</div>
			) : null}
		</>
	);
};
