import React, { ReactNode, useContext, useEffect } from 'react';
import { LayoutContext } from './LayoutContext';
import { PanelToggler } from '.';

// Local interface for the component
interface Props {
	onOfferPlan?: boolean;
	title: ReactNode;
	children: ReactNode | ReactNode[];
	hideBorder?: boolean;
	loading?: boolean;
	flexDirection?: 'flex-start' | 'flex-end' | 'space-between';
}

export default ({ title, children, onOfferPlan, hideBorder, flexDirection, loading }: Props) => {
	const { panelToggled, closePanel } = useContext(LayoutContext);
	let panelClass = panelToggled ? 'right_pannel toggle max_width_100' : 'right_pannel';
	const titleHeading = <h2 data-testid="panel-title" className="title" children={title} />;
	const titleComponent = typeof title === 'string' ? titleHeading : title;
	if (onOfferPlan) panelClass = `${panelClass} offer_plan`;

	// Close right panel
	useEffect(() => {
		return () => {
			closePanel();
		};
	}, []);

	return (
		<div className={panelClass}>
			<div className="right_pannel_title">
				{titleComponent}

				<div className={`head_tools ${hideBorder && 'no_border'}`}>
					<PanelToggler />
				</div>
			</div>
			<div
				className={`max_width_100 flex_content right_pannel_content ${loading ? 'disabled' : ''}`}
				style={{ justifyContent: flexDirection }}
			>
				{children}
			</div>
			{/* <div className="hide_desktop" style={{ width: '100%', height: '50px' }}></div> */}
		</div>
	);
};
