import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

interface Props {
	value: string;
}

export const CopyWrapper: FC<Props> = ({ value }) => {
	const { t } = useTranslation();
	const { copy } = useCopyToClipboard();

	return (
		<div className="match_id ellipsis_left">
			{value}
			<span className="ellipsis_content active" onClick={() => copy(value)}>
				{value}
				<span className="tag_item">{t('general.copy')}</span>
			</span>
		</div>
	);
};
