import React, { FC } from 'react';
import { useRiskGroup } from './useRiskGroup';

interface Props {
	idRiskGroup: number;
	style?: string;
}

export const RiskGroupCell: FC<Props> = ({ idRiskGroup, style = '' }) => {
	const group = useRiskGroup(idRiskGroup);
	if (!group) return <td></td>;

	return (
		<td className={`text_right tooltip_right ${style}`}>
			{group?.id || '-'}
			<span className="tooltip_content">{group?.name}</span>
		</td>
	);
};
