import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from '../../../../../types/general';
import { useFormContext, useWatch } from 'react-hook-form';
import Dropdown from '../../../../../components/general/Dropdown';
import { useNotify } from '../../../../../hooks/useNotifications';
import { isEmpty } from 'lodash';

interface Props {
	edit: boolean;
}

export const ChangeUserStatus: FC<Props> = ({ edit }) => {
	const { t } = useTranslation();
	const { warn } = useNotify();

	const { control, setValue, getValues } = useFormContext();
	const userStatusDefault = getValues('status');

	const userStatus = useWatch({
		control,
		name: 'status',
		defaultValue: userStatusDefault,
	});

	const [statuses] = useState<Item[]>(userStatuses);
	const [selectedStatus, setSelectedStatus] = useState('');

	useEffect(() => {
		const status = statuses.find((status) => status.id === userStatus);
		if (status) {
			setSelectedStatus(status.id);
			setValue('status', status.id);
		} else if (userStatus === registeredStatus.id && !isEmpty(selectedStatus)) {
			setSelectedStatus('');
			setValue('status', registeredStatus.id);
		}
	}, [userStatus]);

	const handleChange = (id: string) => {
		// if (userStatus === 'REGISTERED') return warn('User account is not activated');
		setSelectedStatus(id);
		setValue('status', id);
	};

	return (
		<div className={`form_row read_only}`}>
			<label className="input_label col-3 font_700">{t('usersAdministrations.status')}</label>
			<div className={`col-6 ${!edit ? 'disabled_V' : ''}`}>
				<Dropdown
					items={statuses}
					selectedItem={selectedStatus}
					selectItem={handleChange}
					withSearch
					listClass={'left_0'}
					fixedTitle={!selectedStatus ? 'REGISTERED' : ''}
					margins={['0', '0', '0', '0']}
					icon={edit ? 'mdi mdi-menu-down' : undefined}
					highlightLabel={true}
					className="flex_justify_start"
				/>
			</div>
		</div>
	);
};

const userStatuses = [
	{ id: 'ACTIVE', name: 'ACTIVE' },
	// { id: 'REGISTERED', name: 'REGISTERED' },
	{ id: 'INACTIVE', name: 'INACTIVE' },
	{ id: 'DELETED', name: 'DELETED' },
	{ id: 'BLOCKED', name: 'BLOCKED' },
	{ id: 'SUSPENDED_BY_USER', name: 'SUSPENDED BY USER' },
];

const registeredStatus = { id: 'REGISTERED', name: 'REGISTERED' };
