import settings from '../settings.json';
export const host = settings.boapiURL;
export const bpapi = settings.apiURL;

export const ws = settings.wsUrls.wsURL.useMsgpack ? settings.wsUrls.wsURL.urlMsgpack : settings.wsUrls.wsURL.url;
export const useMsgPack = settings.wsUrls.wsURL.useMsgpack;
export const wsRisk = settings.wsUrls.wsURLRisk.useMsgpack
	? settings.wsUrls.wsURLRisk.urlMsgpack
	: settings.wsUrls.wsURLRisk.url;
export const useMsgPackRisk = settings.wsUrls.wsURLRisk.useMsgpack;
export const geoUrl = settings.geoURL;
export const geoToken = settings.geoToken;
export const geoWithCredentials = settings.geoWithCredentials;
