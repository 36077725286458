import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import { SimpleLoader } from '../../../../components/general/Loader';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import { useNotify } from '../../../../hooks/useNotifications';
import { useOnlineUser } from '../../context/OnlineUserContext';
import { SubHeader } from '../SubHeader';
import { Actions } from './components/Actions';
import { AddDocuments } from './components/AddDocuments';
import { VerificationTable } from './components/VerificationTable';
import { VerificationData } from './types';

export const Documents = () => {
	const { t } = useTranslation();
	const {
		onlineUser: { uid, passport, idCard },
	} = useOnlineUser();
	const [loaded, setLoaded] = useState(false);
	const [addDocument, setAddDocument] = useState(false);
	const [verificationData, setVerificationData] = useState<VerificationData | null>(null);

	const { success, error } = useNotify();

	useEffect(() => {
		(async () => {
			try {
				setLoaded(false);
				const data = await api.users.getVerificationData(uid);
				setVerificationData(data);
			} catch (err: any) {
				const errorText =
					err?.response?.data.detail?.[0]?.msg ||
					err?.response?.data?.detail ||
					t('toast.error.verificationLoadError');
				error(errorText);
			} finally {
				setLoaded(true);
			}
		})();
	}, []);

	const openAddDocument = () => {
		setAddDocument(true);
	};

	const closeAddDocument = () => {
		setAddDocument(false);
	};

	return (
		<div className="main_content shrink_1280 w_1800">
			<SubHeader />
			<Actions openAddDocument={openAddDocument} updateVerificationData={setVerificationData} />
			{addDocument ? (
				<AddDocuments
					close={closeAddDocument}
					updateVerificationData={setVerificationData}
					isIdVerification={idCard && !passport ? true : false}
					uid={uid}
				/>
			) : null}
			<SimpleLoader loaded={loaded}>
				<VerificationTable
					verificationData={verificationData}
					isIdVerification={idCard && !passport ? true : false}
				/>
			</SimpleLoader>
		</div>
	);
};
