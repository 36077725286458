import React, { FC, useEffect, useState } from 'react';
import api from '../../../../../../api';
import { useGeo } from '../../../context';
import { useTranslation } from 'react-i18next';
import { Item } from '../../../../../../types/general';
import Dropdown from '../../../../../../components/general/Dropdown';
import { useNotify } from '../../../../../../hooks/useNotifications';

interface Props {
	locked: string;
	specifics: any;
	rowItem: { id: string; value: any; inherited: any };
	maxWidth?: string;
}
export const ReferenceSelect: FC<Props> = ({ rowItem, locked, specifics, maxWidth }) => {
	const { t } = useTranslation();
	const { notify } = useNotify();

	const { updateGeoParameters, geoParameters } = useGeo();

	const { id, value, inherited } = rowItem;
	const { value_rule, delimiter, uri } = specifics;

	const [values, setValues] = useState<Item[]>([]);
	const [selectedValue, setSelectedValue] = useState('');

	const [errorMessage, setErrorMessage] = useState('');
	const disabledList = value_rule === 'APPEND' && delimiter ? (inherited ? inherited.split(delimiter) : []) : [];

	useEffect(() => {
		(async function getData() {
			try {
				const { values } = await api.geo.getReferenceFieldData({ idGeoEntity: geoParameters.id }, uri);
				setValues(
					values.map(([id, name]: string[]) => ({
						id,
						name,
					}))
				);
				setErrorMessage('');
			} catch (err: any) {
				let errorText = 'toast.error.defaultText';
				if (err.response && err.response.data)
					errorText = err.response.data.detail[0].msg || err.response.data.detail;
				setErrorMessage(errorText);
			}
		})();
	}, [specifics, geoParameters.id]);

	useEffect(() => {
		if (values.length) setSelectedValue(locked ? inherited : value);
	}, [values, rowItem, locked]);

	const handleSelect = async (param: string) => {
		try {
			await api.geo.verifyReferenceFieldData({ idGeoEntity: geoParameters.id, refFields: param }, uri);
			updateGeoParameters(id, param);
		} catch (err: any) {
			let errorText = t('toast.error.defaultText');
			if (err.response && err.response.data) errorText = err.response.data.detail[0].msg || err.response.data.detail;
			notify(errorText, 'error', { autoClose: 3000, style: { backgroundColor: '#fd9800' } });
		}
	};

	return (
		<div className={`value_content flex_direction_row ${locked ? 'disabled' : ''}`}>
			{!errorMessage ? (
				<Dropdown
					items={values}
					margins={['0', '0', '0', '0']}
					selectItem={handleSelect}
					selectedItem={selectedValue}
					icon={'mdi mdi-menu-down'}
					nonSelectedString={t('general.selectOption')}
					disabledList={disabledList}
					maxWidth={maxWidth}
				/>
			) : (
				<p>{t(errorMessage)}</p>
			)}
		</div>
	);
};
