import React, { FC } from 'react';
import { Statuses } from '../../hooks/types';
import { useTranslation } from 'react-i18next';

interface Props {
	title: string;
	options: Statuses;
	setFilter: (filter: string) => void;
}

export const FilterOptions: FC<Props> = ({ options, setFilter, title }) => {
	const { t } = useTranslation();
	return (
		<div className="account_type_filter checkbox_filter  margin_right_10 padding_mobile">
			<span className="filter_label align_right">
				{title}
				<br />
				{t('general.type')}
			</span>
			{Object.keys(options).map((option) => {
				const { color, sign, active, tooltip } = options[option];
				return (
					<div key={option} className="checkbox_button_group">
						<div className={`checkbox_custom chek_only medium tooltip_right margin_right_5 ${color}`}>
							<input
								type="checkbox"
								id="for_label_name"
								checked={active}
								onChange={() => {
									setFilter(option);
								}}
							/>

							<span className="check_cover unchecked">{sign}</span>
							<span className="check_cover checked">{sign}</span>
							<span className="tooltip_content">{tooltip}</span>
						</div>
					</div>
				);
			})}
		</div>
	);
};
