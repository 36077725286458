import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTicket } from '../../context/TicketContext';

interface Props {
	showEvents?: 'showAuthorizedEvents' | 'showOriginalEvents' | 'showDiff';
}

export const EventsTableHead: FC<Props> = ({ showEvents }) => {
	const { t } = useTranslation();
	const { screen } = useTicket();

	const showCurrenStake = screen === 'cashout-offer' || screen === 'cashout-risk';
	return (
		<div className="ticket_table_head">
			<div className="cell ticket_code">{t('general.listCode')}</div>
			<div className="cell icon_single_col"></div>
			<div className="cell ticket_event_timestamp_col">{t('general.dateAndTime')}</div>
			<div className="cell sport_icon"></div>
			<div className="cell competetors_col">{`${t('general.home')} - ${t('general.away')}`}</div>
			<div className="cell result_col">{t('general.result')}</div>
			<div className="cell result_col">{t('general.playtime')}</div>
			<div className="cell type_long"></div>
			<div className="cell type_short">{t('general.odd')}</div>
			<div className={`cell icon_single_col`}></div>
			<div className={`cell stake ${showEvents === 'showDiff' ? 'w_123 flex_center' : ''}`}>
				{t('general.stake')}
			</div>
			<div className="cell stake hide_desktop"></div>

			{showCurrenStake && showEvents !== 'showDiff' ? (
				<div className="cell stake_gray">{t('general.currentStake')}</div>
			) : null}
		</div>
	);
};
