import React, { useState, FC, Fragment } from 'react';
import { useGeo } from '../../context';
import Modal from '../../../../../components/Modals/index';

import { useTranslation } from 'react-i18next';

import { Event } from '../BetMedia/Event'
import { Announcement } from '../BetMedia/Announcement'
import { Mix } from '../BetMedia/Mix'


interface Props {
	locked: boolean;
	edit: (val: boolean) => void;
	rowItem: { id: string; value: any; inherited: any };
	dataType: string;
}

export const BetMediaModal: FC<Props> = ({ locked, edit, rowItem, dataType }) => {
	const { t } = useTranslation();

	const { updateGeoParameters } = useGeo();
	const { id, value, inherited } = rowItem;
	const inputValue = locked ? inherited : value;

	let v;
	try {
		v = JSON.parse(inputValue);
	}
	catch (e) {
		v = [];
	}

	const [internalData, setInternalData] = useState(Array.isArray(v) ? v : [])

	const handleChange = () => {
		updateGeoParameters(id, JSON.stringify(internalData));
		edit(false)
	};

	const component = () => {
		switch (dataType) {
			case 'events':
				return <Event data={internalData} setData={(data: []) => setInternalData(data)} />;
			case 'sports':
				return <Announcement data={internalData} setData={(data: []) => setInternalData(data)} />;
			case 'markets':
				return <Mix data={internalData} setData={(data: []) => setInternalData(data)} />;
			default:
				return null;
		}
	}

	return (
		<Fragment>
			<Modal
				size="xxxl"
				opened={true}
				align="start"
				direction="column"
				cancelAction={() => edit(false)}
				sectionClass="admin_tour"
				body={
					<Fragment>
						{component()}
					</Fragment>
				}
				footer={
					<Fragment>
						<button className="btn md round outline toggle_modal_12" onClick={() => { edit(false) }}>
							{t('general.cancel')}
						</button>
						<button className={`btn md round green toggle_modal_12 ${locked ? 'disabled' : ''}`} onClick={handleChange}>
							{t('general.save')}
						</button>
					</Fragment>
				}
			/>
		</Fragment>
	);
};
