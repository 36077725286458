import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
	name: string;
	label: string;
	editable: boolean;
	edit: boolean;
}

export const DescriptionField: FC<Props> = ({ name, label, editable, edit }) => {
	const { register } = useFormContext();

	return (
		<div className={`form_row ${!(editable && edit) ? 'read_only' : ''}`} style={{ alignItems: 'flex-start' }}>
			<label htmlFor={name} className="input_label col-3 ">
				{label}
			</label>
			<div className="col-6">
				<textarea name={name} ref={register} readOnly={!editable || !edit} className="online_user_description" />
			</div>
		</div>
	);
};
