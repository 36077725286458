import { BannerData, BannerType } from '../../pages/administration/banners/types';
import { I_FRAME } from '../../pages/administration/banners/utils';
import { axiosInstance } from '../config';

export interface CreateUpdateBannerPayload {
	name: string; // naziv banera
	priority: number | string | null | undefined;
	description: string; // Opis banera
	bannerType: BannerType; // tip banera
	idEvent?: string; // id događaja (mora biti za EVENT tip)
	idTournament?: string; // id turnira (mora biti za TOURNAMENT tip)
	idCasinoGame?: string; // mora biti za CASINO_GAME tip
	idCasinoTournament?: string; // mora biti za CASINO_TOURNAMENT tip
	promoUrl?: string; // mora biti za PROMO tip
	inheritTopBanner: boolean; // Da li je side baner isti kao glavni baner
	expire?: string;
	schedule?: string;

	topImage?: string | null;
	sideImage?: string | null;
	mobileImage?: string | null;
}

export interface CreateUpdateData {
	topImage: File | string | null;
	sideImage: File | string | null;
	mobileImage: File | string | null;
}

interface ActivateBannerProps {
	idBanner: number;
	active: boolean;
}

export const banners = {
	getBanners(
		skip: number = 0,
		limit: number = 100,
		active: boolean = true,
		orderBy: string
	): Promise<{
		count: number;
		banners: BannerData[];
	}> {
		return axiosInstance
			.get(`/banners/?skip=${skip}&limit=${limit}&active=${active}&orderBy=${orderBy}`)
			.then((res) => res.data);
	},

	checkImageData(data: CreateUpdateData) {
		if (!data.topImage && !data.sideImage && !data.mobileImage) throw { detail: 'No images set' };
		({ detail: 'No images provided' });
	},

	checkIFramesData(params: CreateUpdateBannerPayload) {
		if (!params.topImage && !params.sideImage && !params.mobileImage) throw { detail: 'No iFrames set' };
		({ detail: 'No iFrames provided' });
	},

	addImagesToForm(bodyFormData: FormData, data: CreateUpdateData) {
		['topImage', 'sideImage', 'mobileImage'].forEach((key) => {
			if (data[key]) {
				bodyFormData.append(key, data[key]);
			}
		});
	},

	createBanner(params: CreateUpdateBannerPayload, data: CreateUpdateData): Promise<BannerData> {
		// if (!data.topImage) return Promise.reject('No top image provided!');

		params.priority = params.priority == 0 ? '0' : params.priority;

		if (params.bannerType === I_FRAME) {
			this.checkIFramesData(params);
			return axiosInstance
				.post(
					'/banners/',
					{},
					{
						params,
						headers: {
							Accept: 'application/json',
						},
					}
				)
				.then((res) => res.data);
		}

		this.checkImageData(data);

		const bodyFormData = new FormData();
		this.addImagesToForm(bodyFormData, data);
		return axiosInstance
			.post('/banners/', bodyFormData, {
				params,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => res.data);
	},

	updateBanner(id: number, params: CreateUpdateBannerPayload, data: CreateUpdateData): Promise<BannerData> {
		if (params.bannerType === I_FRAME) {
			this.checkIFramesData(params);
			return axiosInstance
				.put('/banners/', {}, {
					params: {
						...params,
						id,
					},
					headers: {
						Accept: 'application/json',
					},
				})
				.then((res) => res.data);
		}
		this.checkImageData(data);
		const bodyFormData = new FormData();
		this.addImagesToForm(bodyFormData, data);
		return axiosInstance
			.put('/banners/', bodyFormData, {
				params: {
					...params,
					id,
				},
				headers: {
					Accept: 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => res.data);
	},

	activateBanner(params: ActivateBannerProps) {
		return axiosInstance.put('/banners/active', params);
	},

	updateBannerPriority(bannerID: number, priorityNum: number) {
		return axiosInstance
			.put(`/banners/priority_update?id=${bannerID}&priority=${priorityNum}`)
			.then((res) => res.data);
	},
};
