import { ModalRoles } from './../../pages/system/roles/types';
// Import config
import { axiosInstance } from './../config';
import { RoleTemplate } from './../../pages/system/roles/types';
import { BackofficeUser } from '../../pages/system/users/types';

export const SSO = {
	check() {
		return axiosInstance.get('/').then((res) => res);
	},

	getUser() {
		return axiosInstance.get('/oauth/me').then((res) => res.data);
	},
	getUserRoles() {
		return axiosInstance.get('/roles/me').then((res) => res.data);
	},

	logout() {
		return axiosInstance.get('/oauth/logout').then((res) => res);
	},

	getRoleTemplates(): Promise<RoleTemplate[]> {
		return axiosInstance.get(`/roles/role_templates`).then((res) => res.data);
	},
	deleteRolesTemplate(id: number) {
		return axiosInstance.delete(`/roles/role_templates/${id}`).then((response) => response.data);
	},

	createRolesTemplate(template: RoleTemplate) {
		return axiosInstance.post(`/roles/role_templates`, template).then((res) => {
			return res.data;
		});
	},

	updateRolesTemplate(template: RoleTemplate) {
		return axiosInstance.put(`/roles/role_templates`, template).then((res) => {
			return res.data;
		});
	},

	getBackofficeUsers(params: any): Promise<{ count: number; users: BackofficeUser[] }> {
		return axiosInstance.get(`/backoffice_users/`, { params }).then((res) => res.data);
	},

	editUserRoles(user: ModalRoles) {
		return axiosInstance.post(`/backoffice_users/roles`, user).then((res) => {
			return res.data;
		});
	},
	editUserGeoRoles(user: ModalRoles) {
		return axiosInstance.post(`/backoffice_users/geo_entities`, user).then((res) => {
			return res.data;
		});
	},

	toggleUserActiveState(id: string, active: boolean) {
		return axiosInstance.post(`/backoffice_users/active`, { id, active }).then((res) => {
			return res.data;
		});
	},

	toggleUserAsBackofficeOperator(id: string, backofficeOperator: boolean) {
		return axiosInstance.post(`/backoffice_users/backoffice_operator`, { id, backofficeOperator }).then((res) => {
			return res.data;
		});
	},
};
