import { isEqual } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useEmitter } from '../context/SocketProvider';
import { useMounted } from './useMounted';
import { useNotify } from './useNotifications';
import getErrorText from '../utils/getErrorMsg';

export type Updater<D> = <U>(state: D, payload: U) => D;

interface Config<D> {
	default: D;
	init: (dependencies: any[]) => Promise<D>;
	join: string[];
	listen: {
		[key: string]: Updater<D>;
	};
	dependencies?: any[];
}

export function useRealtime<D>({ default: def, init, join, listen, dependencies = [] }: Config<D>) {
	// Data, error and loader
	const { t } = useTranslation();
	const [data, setData] = useState<D>(def);
	const [loading, setLoading] = useState(true);
	const [errorData, setError] = useState<string | null>(null);
	const socket = useEmitter() as SocketIOClient.Socket;
	const [mounted] = useMounted();
	const { error } = useNotify();

	const refresh = async () => {
		if (!mounted) return;
		setLoading(true);
		setError('');
		try {
			const newData = await init(dependencies);
			if (!mounted) return;
			setData(newData);
			setLoading(false);
			setError('');
		} catch (err: any) {
			if (!mounted) return;
			setLoading(false);
			setError(err);
			error(getErrorText(err) || 'toast.error.defaultText');
		}
	};

	// On mount, get initial data
	useEffect(() => {
		refresh();
	}, dependencies);

	const handler = useCallback(
		function (this: Updater<D>, update) {
			if (!mounted) return;
			setData((data: D) => {
				const newData = this(data, update);
				const equal = isEqual(data, newData);
				return equal ? data : newData;
			});
		},
		[setData]
	);

	// Subscribe to events and join the rooms
	useEffect(() => {
		if (!mounted || !socket) return;
		const listeners = Object.entries(listen);
		listeners.forEach(([event, updater]) => socket.on(event, handler.bind(updater)));
		join.forEach((room) => socket.emit('join', room));

		// On un-mount, unsubscribe from events and leave roms
		return () => {
			Object.keys(listen).forEach((event) => socket.off(event, handler));
			join.forEach((room) => socket.emit('leave', room));
		};
	}, [handler, ...Object.entries(listen).flat(), socket]);

	return {
		data,
		error: errorData,
		loading,
		refresh,
		setData,
	};
}
