import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { RadioOption } from '../types';

interface Props {
	name: string;
	label: string;
	options: RadioOption[];
	editable: boolean;
	edit: boolean;
}

export const RadioGroup: FC<Props> = ({ name, label, editable, edit, options }) => {
	const { register } = useFormContext();

	return (
		<div className="form_row">
			<label htmlFor="row_id_17" className="input_label col-3">
				{label}
			</label>
			<div className="col-6 flex_direction_row">
				{options &&
					Array.isArray(options) &&
					options.map(({ label: optionLabel, value }) => (
						<div className="radio_custom label_right fit_content padding_right_5" key={`${optionLabel}_${value}`}>
							<input
								type="radio"
								name={name}
								id={`${name}_${value}`}
								ref={register}
								value={value}
								disabled={!editable || !edit}
							/>
							<i className="mdi mdi-radiobox-blank unchecked"></i>
							<i className="mdi mdi-radiobox-marked text_green checked"></i>
							<label className="right" htmlFor={`${name}_${value}`}>
								{optionLabel}
							</label>
						</div>
					))}
			</div>
		</div>
	);
};
