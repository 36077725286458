import React, { FC, KeyboardEvent } from 'react';

interface Props {
	label?: string;
	value: string | undefined;
	marginBottom?: number;
	marginRight?: number;
	placeholder?: string;
	type: 'text' | 'number' | 'email';
	setValue: (val: string) => void;
	onEnter?: () => void;
}
export const InputFilter: FC<Props> = ({
	type,
	label,
	value,
	marginBottom = 0,
	marginRight = 0,
	setValue,
	placeholder = '',
	onEnter,
}) => {
	const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode !== 13 || !onEnter) return;
		onEnter();
	};
	return (
		<div className={`form_col margin_bottom_${marginBottom} margin_right_${marginRight} padding_mobile`}>
			{label != undefined && (
				<label htmlFor="label" className="input_label">
					{label}
				</label>
			)}

			<input
				id="label"
				type={type}
				value={value}
				placeholder={placeholder}
				className="normal focus full_w mobile_outline"
				onChange={(e) => setValue(e.currentTarget.value)}
				onKeyUp={handleEnter}
			/>
		</div>
	);
};
