import React from 'react';
import { EventItemExtended } from '../types';

interface Props {
	event: EventItemExtended;
}

export default ({ event }: Props) => {
	const { sport, tournament, category } = event;
	return (
		<div className="option_list_item subtitle_wrapper">
			<div className="svg_icon tooltip_right">
				<svg className="e_ico">
					<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${sport.id}`} />
				</svg>
				<span className="tooltip_content">{sport.name}</span>
			</div>
			<span className="subtitle">{`${tournament.name} (${category.name})`}</span>
		</div>
	);
};
