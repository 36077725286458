import { PlanItem } from '../../pages/OffersPlan/types';
import { axiosInstance } from './../config';

export const plans = {
	getPlan(status: string = 'unset', includeTournaments: boolean = true): Promise<PlanItem[]> {
		return axiosInstance
			.get(`/administration/offerplans/${status}?includeTournaments=${includeTournaments}`)
			.then((res) => res.data);
	},
	getPlanById(id: string) {
		return axiosInstance.get(`/administration/offerplans/offer_plan/${id}`).then((res) => res.data);
	},

	createPlan(startDate: string, endDate: string) {
		return axiosInstance
			.post(`/administration/offerplans/?dateTimeStart=${startDate}&dateTimeEnd=${endDate}`)
			.then((res) => {
				return res.data;
			});
	},
	editPlan(id: string, startDate: string, endDate: string, tournaments: []) {
		return axiosInstance
			.put(`/administration/offerplans/${id}?dateTimeStart=${startDate}&dateTimeEnd=${endDate}`, [...tournaments])
			.then((res) => {
				return res.data;
			});
	},
	addTournament(planId: string, addedTournament: any) {
		return axiosInstance.post(`/administration/offerplans/tournaments/${planId}`, addedTournament).then((res) => {
			return res.data;
		});
	},
	removeTournament(planId: string, removedTournament: /* test */ any) {
		return axiosInstance
			.delete(`/administration/offerplans/tournaments/${planId}`, { data: removedTournament })
			.then((res) => {
				return res.data;
			});
	},
	reorderTournaments(planId: string, removedTournament: any) {
		return axiosInstance
			.put(`/administration/offerplans/tournaments/reorder/${planId}`, removedTournament)
			.then((res) => {
				return res.data;
			});
	},
	lockPlan(id: string, extensionId: string = '') {
		return axiosInstance.post(`/administration/offerplans/lock/${id}?extensionId=${extensionId}`).then((res) => {
			return res.data;
		});
	},
	resetPlan(id: string, extensionId: string = '') {
		return axiosInstance.post(`/administration/offerplans/reset/${id}?extensionId=${extensionId}`).then((res) => {
			return res.data;
		});
	},
	markAll(planId: string, data: any) {
		return axiosInstance.post(`/administration/offerplans/tournaments/${planId}/mark_all`, data).then((res) => {
			return res.data;
		});
	},
	demarkAll(planId: string, data: any) {
		return axiosInstance.delete(`/administration/offerplans/tournaments/${planId}/unmark_all`, { data }).then((res) => {
			return res.data;
		});
	},
};
