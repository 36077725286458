import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleSeriesVoucher } from '../../types';

interface Props {
	voucher: SingleSeriesVoucher;
}

export const SubHeader: FC<Props> = ({ voucher }) => {
	const { t } = useTranslation();

	const { id, code, status, name, description, idBonus, currency, type, totalAmount, total } = voucher;

	return (
		<Fragment>
			<div className="user_overview_details">
				<div className="col-2">
					<div className="info_row">
						<div className="label">{`ID:`}</div>
						<div className="info">{id}</div>
					</div>

					<div className="info_row">
						<div className="label">{`${t('vouchers.status')}:`}</div>
						<div className="info">{status}</div>
					</div>
				</div>
				<div className="col-2">
					<div className="info_row">
						<div className="label">{`${t('vouchers.type')}:`}</div>
						<div className="info">{type}</div>
					</div>
					<div className="info_row">
						<div className="label">{`${t('vouchers.code')}:`}</div>
						<div className="info">{`${code}`}</div>
					</div>
				</div>
				<div className="col-2">
					<div className="info_row">
						<div className="label">{`${t('vouchers.currency')}:`}</div>
						<div className="info">{currency}</div>
					</div>
					<div className="info_row">
						<div className="label">{`${t('vouchers.bonus')}:`}</div>
						<div className="info">{idBonus}</div>
					</div>
				</div>
				<div className="col-3">
					<div className="info_row">
						<div className="label">{`${t('vouchers.name')}:`}</div>
						<div className="info">{`${name}`}</div>
					</div>
					<div className="info_row">
						<div className="label">{`${t('vouchers.description')}:`}</div>
						<div className="info">{description}</div>
					</div>
				</div>
				<div className="col-2">
					<div className="info_row">
						<div className="label">{`${t('vouchers.totalAmount')}:`}</div>
						<div className="info">{totalAmount}</div>
					</div>
				</div>
				<div className="col-2">
					<div className="info_row">
						<div className="label">{`${t('vouchers.totalNumber')}:`}</div>
						<div className="info">{total}</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};
