import { axiosInstance as api } from './../config';

interface AddTournamentParams {
	tournamentId: string;
	page: number;
	templateId: string;
	order: number;
	idOddtypes: string[];
}

export type TableField = 'tableOverall' | 'tableHome' | 'tableAway' | 'forms';

export const builder = {
	getLists: (id_offer_plan: string) => api.get(`/lists/?id_offer_plan=${id_offer_plan}`).then((res) => res.data),
	getList: (listId: number) => api.get(`/lists/${listId}/`).then((res) => res.data),

	addList: (data: { name: string; id_offer_plan: string }) =>
		api.post(`/lists/create_list/`, data).then((res) => res.data),

	updateList: (listId: number, data: { name: string }) =>
		api.put(`/lists/update_list/${listId}`, data).then((res) => res.data),

	/**
	 * Pages
	 */
	createPage(list_id: number, order: number) {
		return api.post(`/lists/${list_id}/create_page/?order=${order}`).then((res) => res.data);
	},
	reorderPage(list_id: number, old_order: number, order: number) {
		return api.post(`/lists/${list_id}/reorder_page/?old_order=${old_order}&order=${order}`).then((res) => res.data);
	},
	joinPages(list_id: number, pages: number[]) {
		return api.post(`/lists/${list_id}/join_pages/`, null, { params: { pages } }).then((res) => res.data);
	},
	duplicatePage(list_id: number, order: number) {
		return api.post(`/lists/${list_id}/duplicate_page/?order=${order}`).then((res) => res.data);
	},
	deletePages(list_id: number, pages: number[]) {
		return api.delete(`/lists/${list_id}/delete_pages/`, { params: { pages } }).then((res) => res.data);
	},
	/**
	 * Tournaments
	 */
	addTournament(list_id: number, params: AddTournamentParams) {
		return api.post(`/lists/${list_id}/add_tournament/`, null, { params }).then((res) => res.data);
	},
	reorderTournament(
		list_id: number,
		tournamentId: string,
		sourcePage: number,
		destinationPage: number,
		old_order: number,
		order: number
	) {
		const params = { list_id, tournamentId, sourcePage, destinationPage, old_order, order };
		return api.post(`/lists/${list_id}/edit_tournament/`, null, { params }).then((res) => res.data);
	},
	editTournament(list_id: number, tournamentId: string, page: number, old_order: number, order: number) {
		const path = `/lists/${list_id}/edit_tournament/?tournamentId=${tournamentId}&page=${page}&old_order=${old_order}&order=${order}`;
		return api.post(path).then((res) => res.data);
	},
	editTournamentTables(
		list_id: number,
		tournamentId: string,
		sourcePage: number,
		old_order: number,
		tableField: TableField,
		value: boolean
	) {
		const path = `/lists/${list_id}/edit_tournament/?tournamentId=${tournamentId}&sourcePage=${sourcePage}&old_order=${old_order}&${tableField}=${value}`;
		return api.post(path).then((res) => res.data);
	},
	editTournamentTablesAll(
		list_id: number,
		tournamentId: string,
		sourcePage: number,
		old_order: number,
		value?: boolean
	) {
		const path = `/lists/${list_id}/edit_tournament/?tournamentId=${tournamentId}&sourcePage=${sourcePage}&old_order=${old_order}&tableOverall=${value}&tableHome=${value}&tableAway=${value}&forms=${value}`;
		return api.post(path).then((res) => res.data);
	},
	changeTemplate(list_id: number, sourcePage: number, tournamentId: string, old_order: number, templateId: string) {
		const path = `/lists/${list_id}/edit_tournament/?sourcePage=${sourcePage}&tournamentId=${tournamentId}&old_order=${old_order}&templateId=${templateId}`;
		return api.post(path).then((res) => res.data);
	},
	deleteTournament(list_id: number, tournamentId: string, page: number, order: number) {
		const path = `/lists/${list_id}/delete_tournament/?tournamentId=${tournamentId}&page=${page}&order=${order}`;
		return api.delete(path).then((res) => res.data);
	},
	/**
	 * Events
	 */
	getEvents(list_id: number, params: { tournamentId: string; page: number; order: number; idOddtypes: string[] }) {
		return api.get(`/lists/${list_id}/tournament_events/`, { params }).then((res) => res.data);
	},
	selectEvent(list_id: number, tournamentId: string, page: number, order: number, eventId: string, selected: boolean) {
		const path = `/lists/${list_id}/select_event/?tournamentId=${tournamentId}&page=${page}&order=${order}&eventId=${eventId}&selected=${selected}`;
		return api.post(path).then((res) => res.data);
	},
	reorderEvent(
		list_id: number,
		tournamentId: string,
		page: number,
		order: number,
		eventId: string,
		eventOrder: number
	) {
		const path = `/lists/${list_id}/reorder_event/?tournamentId=${tournamentId}&page=${page}&order=${order}&eventId=${eventId}&eventOrder=${eventOrder}`;
		return api.post(path).then((res) => res.data);
	},
};
