import React, { FC, Fragment, useEffect, useState } from 'react';
import { useInitialMount, usePagination } from '../../../../hooks';
import { derivedTicketStatuses } from '../../../reports/tickets/helpers';
import { TicketItemExtended } from '../../../reports/tickets/types';
import { SubHeader } from '../SubHeader';
import { StatsBar } from './components/StatsBar';
import { Filters } from './components/Filters';
import api from '../../../../api';
import { Height } from '../../../administration/live/components/Height';
import { SimpleLoader } from '../../../../components/general/Loader';
import { TicketModal } from '../../../../components/Modals/TicketModal';
import { TableHeader, TableRow } from '../../../reports/tickets/components';
import { PageFooter } from '../../../../components/layout';
import { FooterDropdown } from '../../../risk-management/controller/components/general';
import { useFilter } from '../../../reports/tickets/hooks/useFilters';
import { Sport } from '../../../administration/sports/types';
import { useOnlineUser } from '../../context/OnlineUserContext';
import { subDays } from 'date-fns';
import { useNotify } from '../../../../hooks/useNotifications';

export const Tickets: FC = () => {
	const { onlineUser } = useOnlineUser();
	const [loading, setLoading] = useState(false);
	const [tickets, setTickets] = useState([] as TicketItemExtended[]);
	const [hoveredTicket, setHovered] = useState<TicketItemExtended | null>(null);
	const [selectedTicket, setSelectedTicket] = useState<TicketItemExtended | null>(null);

	const [unionStatusesOperator, setUnionStatusesOperator] = useState(true);

	const [sports, setSports] = useState<Sport[]>([]);
	const [selectedSport, setSelectedSport] = useState('');

	const [startDate, setStartDate] = useState<Date | null>(subDays(new Date(), 31));
	const [endDate, setEndDate] = useState<Date | null>(null);

	const [selectedTicketStatuses, setSelectedTicketStatuses] = useState(['all', ...derivedTicketStatuses]);

	const itemsPerPage = 20;
	const { from, setTotal, perPage, setPerPage, ...pagination } = usePagination(itemsPerPage);

	const [orderBy, setOrderBy] = useState('DT_REQUESTED_DESC');

	const { ticketTypes, toggleType, activeTypes, accountType } = useFilter();

	const toggleStatusesOperator = () => {
		setUnionStatusesOperator((isUnion) => !isUnion);
	};

	useEffect(() => {
		(async () => {
			const sportData = await api.sports.getList('ALL', true);
			const all = { id: 'all', defaultName: 'All' };
			setSports([all, ...sportData]);
			setSelectedSport('all');
			loadTickets();
		})();
	}, []);

	const initialMount = useInitialMount();
	const { error } = useNotify();
	useEffect(() => {
		if (initialMount) return;
		loadTickets();
	}, [from, perPage, orderBy]);

	const loadTickets = async () => {
		const dtRequestedFrom = startDate ? new Date(startDate.setHours(0, 0, 0, 0)).toISOString() : null;
		const dtRequestedTo = endDate ? new Date(endDate.setHours(23, 59, 59, 999)).toISOString() : null;
		try {
			setLoading(true);

			const { count, tickets } = await api.risk.getTicketsHistory({
				offerPlanList: null,
				listCode: null,
				dtRequestedFrom,
				dtRequestedTo,
				bookmaker: '',
				username: onlineUser.email,
				idLocations: null,
				ticketType: activeTypes(ticketTypes),
				statuses: selectedTicketStatuses.includes('all') ? null : selectedTicketStatuses,
				payInChannel: [],
				idSports: !selectedSport || selectedSport === 'all' ? null : [selectedSport],
				idTournaments: null,
				idOddtypes: null,
				minEvents: null,
				maxEvents: null,
				idBarcode: '',
				minWaitsFor: null,
				maxWaitsFor: null,
				minAmount: null,
				maxAmount: null,
				minGain: null,
				orderBy: orderBy ? orderBy.split('-') : ['WAITS_FOR_ASC', 'DT_REQUESTED_DESC', 'MAX_GAIN_ASC'],
				offset: from,
				limit: perPage,
				accountType: activeTypes(accountType),
				statusesOperator: unionStatusesOperator ? 'OR' : 'AND',
			});
			setTickets(tickets || []);
			setTotal(count);
		} catch (err: any) {
			const errorText = 'Problem with load data';
			error(errorText);
		} finally {
			setLoading(false);
		}
	};

	const closeModal = (ticket: any) => {
		setTickets((tickets) => tickets.map((item) => (item.id === ticket.id ? ticket : item)));
	};

	return (
		<Fragment>
			<div className="main_content w_1800" data-simplebar data-simplebar-auto-hide="false">
				<SubHeader />
				<StatsBar />
				<Filters
					sports={sports}
					selectedSport={selectedSport}
					setSelectedSport={setSelectedSport}
					selectedTicketStatuses={selectedTicketStatuses}
					setSelectedTicketStatuses={setSelectedTicketStatuses}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
					loadTickets={loadTickets}
					ticketTypes={ticketTypes}
					toggleType={toggleType}
					accountType={accountType}
					disabled={loading}
					unionStatusesOperator={unionStatusesOperator}
					toggleStatusesOperator={toggleStatusesOperator}
				/>
				{!loading ? (
					<table className="admin_table sticky_header sticky_footer top_60 full_w ibet_users_table">
						<TableHeader selectedSort={orderBy} selectSort={setOrderBy} />
						<tbody className="t_body">
							{tickets.map((ticket) => (
								<TableRow
									key={ticket.id}
									ticket={ticket}
									selectedTicket={selectedTicket}
									selectTicket={setSelectedTicket}
									setHovered={setHovered}
								/>
							))}
						</tbody>
					</table>
				) : (
					<Height>
						<SimpleLoader />
					</Height>
				)}

				{selectedTicket ? (
					<TicketModal
						selectedTicket={selectedTicket}
						selectTicket={setSelectedTicket}
						screen={'tickets'}
						closeModal={closeModal}
					/>
				) : null}
				{hoveredTicket ? (
					<TicketModal
						screen={'tickets'}
						selectTicket={setHovered}
						selectedTicket={hoveredTicket}
						styles={{ left: '18%', width: '80%', background: 'none' }}
					/>
				) : null}
			</div>
			<PageFooter numberOfItems={<FooterDropdown perPage={perPage} setPerPage={setPerPage} />} {...pagination} />
		</Fragment>
	);
};
