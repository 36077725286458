import { error } from 'console';
import React, { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import Dropdown from '../../../components/general/Dropdown';
import { useData } from '../../../context/DataProvider';
import { useOnlineUser } from '../context/OnlineUserContext';

interface Props {
	userUid: string;
	userTag?: string | undefined;
}

export const RiskTagPreview: FC<Props> = ({ userUid, userTag }) => {
	const { t } = useTranslation();
	const { riskTags } = useData();
	const { onlineUser } = useOnlineUser();
	const { riskTag } = onlineUser;

	const [riskTagValue, setRiskTagValue] = useState<string>('');

	useEffect(() => {
		setRiskTagValue(userTag ?? riskTag ?? '');
	}, [riskTag, userTag]);

	const updateTag = async (id: string) => {
		try {
			await api.risk.riskTag({ riskTagCode: id, userUuid: userUid });
			setRiskTagValue(id);
		} catch (err) {
			error('toast.error.userAutomaticRiskGenericError');
		}
	};

	return (
		<>
			<Dropdown
				items={[
					{ id: '', name: t('general.clearSelected') },
					...riskTags.map(({ code, name, color }) => ({
						id: code,
						name,
						color,
					})),
				]}
				selectedItem={riskTagValue ?? ''}
				selectItem={updateTag}
				listClass={'left_0'}
				icon={'mdi mdi-menu-down'}
				margins={['0', '20', '0', '0']}
				fixedTitle={!riskTagValue ? t('general.tag') : ''}
				setItemColor={true}
				fontSize={'10px'}
			/>
		</>
	);
};
