import React, { FC } from 'react';
import Dropdown from '../../general/Dropdown';
import { AvailableOption } from './types';
import { useTranslation } from 'react-i18next';

interface Props {
	companyId: string | null;
	companyName: string;
	allOptions: AvailableOption[];
	selected: string | number;
	select: (idCompany: string | null, id: number) => void;
	reset: (idCompany: string | null, companyName: string) => void;
}

export const ParameterRow: FC<Props> = ({ companyId, companyName, allOptions, selected, select, reset }) => {
	const { t } = useTranslation();
	return (
		<div className="direction_row margin_bottom_10">
			<div className="column col-6 text-right align_center padding_right_20">
				<div className="label font_400">{companyName}</div>
			</div>
			<div className="column col-4 align_center padding_left_10">
				<Dropdown
					items={allOptions}
					selectedItem={selected}
					selectItem={(id) => {
						select(companyId, +id);
					}}
					margins={['3', '', '', '']}
					nonSelectedString="None selected"
					icon={'mdi mdi-menu-down'}
				/>
			</div>
			<div className="col-2 column align_center padding_left_10 ">
				<div className="tbl_btn red ">
					<i
						className="mdi mdi-close md tooltip_left padding_top_5"
						onClick={() => selected && reset(companyId, companyName)}
					>
						<span className="tooltip_content redish">{t('general.reset')}</span>
					</i>
				</div>
			</div>
		</div>
	);
};
