import { axiosInstance } from '../config';
import { DailyExportTemplate } from '../../pages/Lists/daily-offer/types';
import { TimeAndDailyExportPreview } from './../../pages/Lists/daily-offer/types';

interface CreateParams {
	idListExportTemplate: number;
	sportTemplateMap: {
		idSport: string;
		idTemplate: string;
	}[];
	default: boolean;
}

export const dailyExport = {
	getDailyTemplates(): Promise<DailyExportTemplate[]> {
		return axiosInstance.get('/lists_export_administration/offer_template/daily').then((res) => res.data);
	},

	getDailyTemplate(templateId: number): Promise<DailyExportTemplate> {
		return axiosInstance
			.get(`/lists_export_administration/offer_template/daily/${templateId}`)
			.then((res) => res.data);
	},

	createDailyTemplate(params: CreateParams): Promise<DailyExportTemplate> {
		return axiosInstance.post('/lists_export_administration/offer_template/daily', params).then((res) => res.data);
	},

	updateDailyTemplate(params: DailyExportTemplate): Promise<DailyExportTemplate> {
		return axiosInstance.put('/lists_export_administration/offer_template/daily', params).then((res) => res.data);
	},

	deleteDailyTemplate(templateId: number): Promise<DailyExportTemplate> {
		return axiosInstance
			.delete(`/lists_export_administration/offer_template/daily?id=${templateId}`)
			.then((res) => res.data);
	},

	setDefault(templateId: number): Promise<DailyExportTemplate[]> {
		return axiosInstance
			.post(`/lists_export_administration/offer_template/daily/set_default?id=${templateId}`)
			.then((res) => res.data);
	},

	getDailyExportPreviewLinks(asPdf: boolean): Promise<{ [key: string]: TimeAndDailyExportPreview }> {
		return axiosInstance.get(`/lists/timed_daily_offer?as_pdf=${asPdf}`).then((res) => res.data);
	},
	getTimeExportPreviewLinks(asPdf: boolean): Promise<{ [key: string]: TimeAndDailyExportPreview }> {
		return axiosInstance.get(`/lists/timed_time_offer?as_pdf=${asPdf}`).then((res) => res.data);
	},
};
