import { EmptyPromotion, activePeriods } from './../pages/administration/promotions/types';

export const EmptyPromotionModel: EmptyPromotion = {
	minTicketQuota: null,

	name: {
		sr: '',
		en: '',
		de: '',
		me: '',
		mk: '',
		ba: '',
		us: '',
		ru: '',
	},
	description: {},
	walletType: 'INTERNET',
	currency: 'BAM',
	dtFrom: new Date().toISOString(),
	dtUntil: new Date().toISOString(),
	status: 'ACTIVE',
	id: 0,
	promotionCode: '',
	promotionType: 'TICKET_PROMOTION',
	activeDays: [],
	activePeriods: {} as activePeriods,
	canBeApplied: null,
	canBeAppliedDaily: null,
	minPayIn: null,
	maxPromotionAmount: null,
	fixedPromotionAmount: null,
	allowedInFreeBet: false,
	allowedInSystem: false,
	allowedInPrematch: true,
	allowedInLive: true,
	allowedInPos: true,
	allowedInTerminal: true,
	allowedTournamentIds: [],
	allowedSportIds: [],
	allowedOddIds: [],
	daysActive: 0,
	hoursUntilRedeemable: 0,
	promotionUnlockRules: [
		{
			numberOfEvents: 1,
			percentage: 1,
			minEventQuota: 1.01,
			minPayin: 0,
			tag: null,
			tagImage: null,
			tagImage120: null,
		},
	],
};
