import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useTicket } from '../../context/TicketContext';

export const ManualCahoutInputs = () => {
	const { t } = useTranslation();
	const { cashoutValue, cashoutTime, updateCashoutTime, updateCashoutAmount, screen, ticket } = useTicket();

	const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		const [_, decimals] = value.split('.');
		if (decimals?.length > 2) return;
		if (value && +value < 0) return;
		updateCashoutAmount(value);
	};

	const handleTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		const [_, decimals] = value.split('.');
		if (decimals?.length) return;
		if (value && +value < 1) return;
		updateCashoutTime(value);
	};

	const disabled = ticket?.cashoutData?.authStatus !== 'SENT' && screen !== 'cashout-offer';
	const label = disabled ? t('reports.tickets.approvedAmount') : t('reports.tickets.cashoutAmount');

	const formattedCashoutValue = cashoutValue.replace(',', '');

	const inputValue = disabled
		? ticket.cashoutData?.approvedAmount
			? ticket.cashoutData?.approvedAmount
			: ''
		: formattedCashoutValue;

	return (
		<>
			<div className="footer_info_item ">
				<div className="label flex_justify_end no_border">{label}:</div>
				<div className="content flex_justify_end no_border">
					<input
						disabled={disabled}
						value={inputValue}
						onChange={handleAmountChange}
						className="short tiny"
						type="number"
					/>
				</div>
			</div>

			{screen === 'cashout-offer' ? (
				<div className="footer_info_item  padding_right_10">
					<div className="label flex_justify_end no_border">{t('reports.tickets.cashoutTime')}:</div>
					<div className="content flex_justify_end no_border">
						<input value={cashoutTime} onChange={handleTimeChange} className="short tiny" type="number" />
					</div>
				</div>
			) : null}
		</>
	);
};
