import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModels } from '../../../../../hooks/useModels';
import { useFormContext, useWatch } from 'react-hook-form';
import LangMedia from '../../../../../components/general/LangMedia';
import { FillEmpty } from '../../../../../components/general/FillEmpty';

export const MarketName = () => {
	const { t } = useTranslation();
	const { getValues, control, setValue } = useFormContext();
	const {
		languages,
		media,
		selectedLang,
		selectedMedia,
		selectLang,
		selectMedia: setSelectedMedia,
		fillExtended,
	} = useModels();

	const localisationObj = getValues('localisation');

	const selectMedia = (id: string) => {
		setFillActive(false);
		setSelectedMedia(id);
	};

	const localisation = useWatch({
		control: control,
		name: 'localisation',
		defaultValue: localisationObj,
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let newLocalisation = { ...localisation };
		newLocalisation[selectedLang][selectedMedia] = e.target.value;
		setValue('localisation', newLocalisation);
	};

	const [fillActive, setFillActive] = useState(false);

	const fillLocalization = () => {
		setFillActive(false);
		setValue('localisation', fillExtended(localisation));
	};

	return (
		<div className="row margin_bottom_20 margin_top_10 ">
			<div className="tab_content_wrap">
				<div className="tab_content  market_padding active">
					<input
						id="market-name"
						onChange={handleChange}
						onFocus={() => setFillActive(true)}
						className="normal text_center"
						placeholder={t('marketsAdministrations.marketName')}
						value={(selectedLang && selectedMedia && localisation[selectedLang][selectedMedia]) || ''}
					/>
				</div>
			</div>
			{fillActive && localisation[selectedLang][selectedMedia] ? (
				<FillEmpty styles={{ marginLeft: '310px' }} onClick={fillLocalization} />
			) : null}
			<LangMedia
				media={media}
				marginTop={'10'}
				languages={languages}
				selectedLang={selectedLang}
				selectLang={selectLang}
				selectedMedia={selectedMedia}
				selectMedia={selectMedia}
			/>
		</div>
	);
};
