import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearch, Search } from '../../../../components/Search';

interface Props {
	setSearch: (val: string) => void;
}

export const SearchWrapper: FC<Props> = ({ setSearch }) => {
	const { t } = useTranslation();
	const searchOptions = useSearch(setSearch, '', true);
	return <Search message={t('offersPlan.3characters')} {...searchOptions} />;
};
