import { axiosInstance } from '../config';
import { TemplateParams } from './types';

export const notifications = {
	getUserNotifications(
		userId: number,
		skip: number = 0,
		limit: number = 100,
		orderBy: string[] = ['DATE_TIME_SENT_DESC']
	) {
		return axiosInstance
			.post('/support/notifications/sent_messages', { user_id: userId, skip, limit, orderBy })
			.then(({ data }) => data);
	},

	sendUserNotification(userId: number, notification: TemplateParams) {
		return axiosInstance.post(`/support/send_notification/${userId}`, notification).then(({ data }) => data);
	},
};
