import React, { Component, ReactNode } from 'react';

// Local interface for the component
interface Props {
	loaded?: boolean;
	error?: string | null | ReactNode;
	children?: ReactNode | ReactNode[];
}

interface State {
	error: Error | string | null;
}

// Loader with error boundary
export default class LoaderWithCatch extends Component<Props, State> {
	state = { error: null };

	componentDidCatch(error: Error) {
		this.setState({ error });
	}

	render() {
		const { children, loaded, error = this.state.error } = this.props;

		if (error) return <div className="loader activate">{error}</div>;

		if (loaded) return children;

		return (
			<div data-testid="loader-gif" className="loader activate">
				<img src="/assets/img/loader.gif" className="loader_img" />
			</div>
		);
	}
}
