import { TableItem } from './../../pages/Lists/Tables/types';
import { axiosInstance } from './../config';

export const tables = {
	getTables({
		sportId = '',
		q = '',
		offset = 0,
		limit = 100
	}: {
		sportId?: string | number;
		q?: string;
		offset?: number;
		limit?: number;
	}) {
		return axiosInstance
			.get(`/lists/tables?sportId=${sportId}&q=${q}&offset=${offset}&limit=${limit}`)
			.then((res) => res.data);
	},
	editTable(table: TableItem) {
		return axiosInstance
			.put(`/lists/tables/${table.id}`, {
				...table
			})
			.then((res) => {
				return res.data;
			});
	},
	addTable(newTable: any) {
		return axiosInstance
			.post(`/lists/tables`, {
				...newTable
			})
			.then((res) => {
				return res.data;
			});
	},
	deleteTable(tableId: string) {
		return axiosInstance.delete(`/lists/tables/${tableId}`).then((res) => {
			return res.data;
		});
	}
};
