import i18n from '../../../localisation/i18n';

export interface TicketStatusAdditionalDataMap {
	[key: string]: TicketStatusAdditionalData;
}

export type TicketStatusAdditionalData = {
	color: string;
	sign: string;
	icon: string;
	name: string;
	id: string;
	order: number;
	number: number;
	isDerived?: boolean;
};

export const derivedTicketStatuses = [
	'OPENED',
	'PLACED',
	'WINNING',
	'LOSING',
	'REFUNDED',
	'CLOSED',
	'PAID_OUT',
	'NON_PAID_OUT',
	'CASHOUT_PAID_OUT',
	'PAID_OUT_ERROR',
	'AUTHORISED',
	'ACCEPTED_AUTHORISATION',
	'MODIFIED_AUTHORISATION',
	'DENIED_AUTHORISATION',
	'WITHOUT_AUTHORISATION',
	'WITHDRAWN',
];

export const ticketNumberStringMap = {
	0: 'REQUESTED',
	1: 'PLACED',
	2: 'REFUNDED',
	3: 'WINNING',
	4: 'LOSING',
	5: 'INVALID',
	6: 'DELETED',
	7: 'PAID_OUT',
};

export const ticketStatuses: TicketStatusAdditionalDataMap = {
	REQUESTED: {
		id: 'REQUESTED',
		color: '',
		sign: 'NR',
		icon: 'mdi-timer-off',
		order: 0,
		name: i18n.t('ticketFilters.REQUESTED'),
		number: 0,
	},
	PLACED: {
		id: 'PLACED',
		color: 'blueish blue',
		sign: 'P',
		icon: 'mdi-play',
		order: 1,
		name: i18n.t('ticketFilters.PLACED'),
		number: 1,
	},
	REFUNDED: {
		id: 'REFUNDED',
		color: 'orange',
		sign: 'R',
		icon: 'mdi-stop',
		order: 2,
		name: i18n.t('ticketFilters.REFUNDED'),
		number: 2,
	},
	WINNING: {
		id: 'WINNING',
		color: 'green',
		sign: 'W',
		icon: 'mdi-stop',
		order: 3,
		name: i18n.t('ticketFilters.WINNING'),
		number: 3,
	},
	LOSING: {
		id: 'LOSING',
		color: 'red redish redish_text',
		sign: 'L',
		icon: 'mdi-alpha-p-circle',
		order: 4,
		name: i18n.t('ticketFilters.LOSING'),
		number: 4,
	},
	INVALID: {
		id: 'INVALID',
		color: 'redish red',
		sign: '',
		icon: '',
		order: 5,
		name: i18n.t('ticketFilters.INVALID'),
		number: 5,
	},
	DELETED: {
		id: 'DELETED',
		color: 'redish red',
		sign: '',
		icon: '',
		order: 6,
		name: i18n.t('ticketFilters.DELETED'),
		number: 6,
	},
	PAID_OUT: {
		id: 'PAID_OUT',
		color: 'green',
		sign: '',
		icon: '',
		order: 8,
		name: i18n.t('ticketFilters.PAID_OUT'),
		number: 7,
	},

	// Derived statuses
	NON_PAID_OUT: {
		id: 'NON_PAID_OUT',
		color: 'gray',
		sign: '',
		icon: '',
		order: 9,
		name: i18n.t('ticketFilters.NON_PAID_OUT'),
		number: 8,
		isDerived: true,
	},
	CASHOUT_PAID_OUT: {
		id: 'CASHOUT_PAID_OUT',
		color: 'gray',
		sign: '',
		icon: '',
		order: 10,
		name: i18n.t('ticketFilters.CASHOUT_PAID_OUT'),
		number: 9,
		isDerived: true,
	},
	PAID_OUT_ERROR: {
		id: 'PAID_OUT_ERROR',
		color: 'gray',
		sign: '',
		icon: '',
		order: 11,
		name: i18n.t('ticketFilters.PAID_OUT_ERROR'),
		number: 10,
		isDerived: true,
	},
	WITHOUT_AUTHORISATION: {
		id: 'WITHOUT_AUTHORISATION',
		color: 'gray',
		sign: '',
		icon: '',
		order: 11,
		name: i18n.t('ticketFilters.WITHOUT_AUTHORISATION'),
		number: 10,
		isDerived: true,
	},
	MODIFIED_AUTHORISATION: {
		id: 'MODIFIED_AUTHORISATION',
		color: 'gray',
		sign: '',
		icon: '',
		order: 11,
		name: i18n.t('ticketFilters.MODIFIED_AUTHORISATION'),
		number: 10,
		isDerived: true,
	},
	ACCEPTED_AUTHORISATION: {
		id: 'ACCEPTED_AUTHORISATION',
		color: 'gray',
		sign: '',
		icon: '',
		order: 11,
		name: i18n.t('ticketFilters.ACCEPTED_AUTHORISATION'),
		number: 10,
		isDerived: true,
	},
	AUTHORISED: {
		id: 'AUTHORISED',
		color: 'gray',
		sign: '',
		icon: '',
		order: 11,
		name: i18n.t('ticketFilters.AUTHORISED'),
		number: 10,
		isDerived: true,
	},
	DENIED_AUTHORISATION: {
		id: 'DENIED_AUTHORISATION',
		color: 'gray',
		sign: '',
		icon: '',
		order: 11,
		name: i18n.t('ticketFilters.DENIED_AUTHORISATION'),
		number: 10,
		isDerived: true,
	},
	WITHDRAWN: {
		id: 'WITHDRAWN',
		color: 'blue',
		sign: '',
		icon: '',
		order: 12,
		name: i18n.t('ticketFilters.WITHDRAWN'),
		number: 10,
		isDerived: true,
	},
};

const emptyData: TicketStatusAdditionalData = {
	id: '',
	color: '',
	sign: '',
	icon: '',
	name: '',
	order: 100,
	number: 100,
};

export const getTicketStatusAdditionalData = (status: string) => {
	const ticketAdditionalData = ticketStatuses[status] || { ...emptyData, name: status, id: status };
	return ticketAdditionalData;
};
