import React, { FC, useState } from 'react';
import InPortal from '../InPortal';
import { EventsTableBody } from './components/events/EventsTableBody';
import { TicketProps, TicketProvider, useTicket } from './context/TicketContext';
import {
	EventsTableHead,
	HeaderActions,
	ManualCahoutInputs,
	ManualOfferButton,
	TicketFooter,
	TicketHeader,
	TicketStorno,
	TicketSubHeader,
} from './components';
import { CashoutRiskActions } from './components/cashout/CashoutRiskActions';

export const TicketModal: FC<TicketProps> = ({
	selectedTicket,
	selectTicket,
	styles = undefined,
	screen,
	closeModal,
	authorizeCashout,
	similarTicketInitialShown = false,
}) => {
	return (
		<TicketProvider
			selectedTicket={selectedTicket}
			selectTicket={selectTicket}
			styles={styles}
			screen={screen}
			closeModal={closeModal}
			authorizeCashout={authorizeCashout}
			similarTicketInitialShown={similarTicketInitialShown}
		>
			<Modal />
		</TicketProvider>
	);
};

const Modal = () => {
	const { styles, screen, ticket } = useTicket();
	const { cashoutData } = ticket;

	const actionsComponent = () => {
		return screen === 'tickets' ? (
			<TicketStorno />
		) : screen === 'cashout-offer' ? (
			<ManualOfferButton />
		) : screen === 'cashout-risk' && cashoutData?.authStatus === 'SENT' && cashoutData?.payoutStatus !== 'EXPIRED' ? (
			<CashoutRiskActions />
		) : (
			<div />
		);
	};

	const [showEvents, setShowEvents] = useState<'showAuthorizedEvents' | 'showOriginalEvents' | 'showDiff'>(
		'showAuthorizedEvents'
	);

	return (
		<InPortal>
			<div className={`modal_cover max_96 xxxl toggle`} style={styles}>
				<div className="modal_content fadeInDown animated faster x_scroll__m_0">
					<HeaderActions />
					{/* Body */}
					<div className="modal_body  content_start direction_column user_ticket w_1800">
						<TicketHeader showEvents={showEvents} />
						<TicketSubHeader showEvents={showEvents} setShowEvents={setShowEvents} />
						<div className="sustems_wrapper">
							<EventsTableHead showEvents={showEvents} />
							<EventsTableBody showEvents={showEvents} />
						</div>
						{/* Footer */}
						<TicketFooter readOnly actions={actionsComponent()} showEvents={showEvents}>
							{screen !== 'tickets' ? <ManualCahoutInputs /> : null}
						</TicketFooter>
					</div>
				</div>
			</div>
		</InPortal>
	);
};
