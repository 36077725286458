import { setDate, setHours, setMinutes } from 'date-fns';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import Modal from '../../../../../components/Modals';
import { Navigation } from '../../../../../components/Navigation';
import { Item } from '../../../../../types/general';
import { Dropdown } from '../../../../pairing/components';
import { useOnlineUser } from '../../../context/OnlineUserContext';
import DatePicker from 'react-datepicker';
import { CreateReservationPayload } from '../../../../../api/administrations/users';
import { useNotify } from '../../../../../hooks/useNotifications';
import { Reservation } from '../types';

interface Props {
	close: () => void;
	updateReservation: (reservation: Reservation | null) => void;
}

export const CreateReservation: FC<Props> = ({ close, updateReservation }) => {
	const { t } = useTranslation();
	const { success, warn } = useNotify();
	const {
		onlineUser: { uid },
		refetchUser,
	} = useOnlineUser();

	const locationId = 'LOCATION';
	const bankId = 'BANK';

	const tabs = [
		{ id: locationId, name: 'Location' },
		{ id: bankId, name: 'Bank' },
	];

	const tommorrow = setMinutes(setHours(setDate(new Date(), new Date().getDate() + 1), 10), 0);

	const [createLock, setCreateLock] = useState(false);

	const [amount, setAmount] = useState<number | null>(null);
	const [availableTime, setAvailableTime] = useState<Date>(tommorrow);
	const [expirationTime, setExpirationTime] = useState<Date>(setDate(tommorrow, tommorrow.getDate() + 7));

	const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		if (value && +value < 1) return setAmount(null);
		setAmount(+value);
	};
	const [selectedTab, setSelectedTab] = useState<'BANK' | 'LOCATION'>(locationId);

	const [selectedLocation, setSelectedLocation] = useState('');

	const selectLocation = (id: string) => {
		setSelectedLocation(id);
	};

	const selectTab = (tabId: 'BANK' | 'LOCATION') => {
		setSelectedTab(tabId);
	};
	const [isDisabled, setIsDisabled] = useState(false);

	const create = async () => {
		if (createLock) return;
		if (expirationTime < availableTime) return warn(t('usersAdministrations.expirationError'), { autoClose: 4000 });
		setCreateLock(true);

		const payload: CreateReservationPayload = {
			userUuid: uid,
			idLocation: selectedLocation,
			instanceType: 'ONLINE',
			dtAvailable: availableTime.toISOString(),
			dtExpires: expirationTime.toISOString(),
			reservationPayoutType: selectedTab,
		};
		if (amount) {
			payload.reservedAmount = amount;
		}
		try {
			setIsDisabled(true);
			const reservation = await api.users.createReservation(payload);
			updateReservation(reservation);
			success(t('toast.success.creationSuccessful', { entity: t('usersAdministrations.reservation') }));
			setCreateLock(false);
			refetchUser();
			close();
		} catch (err: any) {
			const errorText =
				err?.response?.data.detail?.[0]?.msg || err?.response?.data?.detail || t('toast.error.defaultText');
			warn(errorText);

			setCreateLock(false);
		} finally {
			setIsDisabled(false);
		}
	};
	return (
		<Modal
			opened={true}
			size="md"
			align="start"
			direction="column"
			cancelAction={close}
			header={<h2 className="modal_title padding_left_20">{t('usersAdministrations.createReservation')}</h2>}
			body={
				<>
					<Navigation
						tabs={tabs}
						selectedTab={selectedTab}
						selectTab={selectTab}
						paddingLeft={20}
						paddingRight={20}
					/>
					<Form
						selectedLocation={selectedLocation}
						selectLocation={selectLocation}
						amount={amount}
						handleAmountChange={handleAmountChange}
						availableTime={availableTime}
						setAvailableTime={setAvailableTime}
						expirationTime={expirationTime}
						setExpirationTime={setExpirationTime}
					/>
				</>
			}
			footer={
				<>
					<button className="btn md round outline " onClick={close}>
						{t('general.cancel')}
					</button>

					{!isDisabled ? (
						<>
							<button className="btn md round green " onClick={create}>
								{t('general.create')}
							</button>
						</>
					) : (
						<button disabled={true} className={`btn md round green`} onClick={() => {}}>
							<i className="mdi mdi-rotate-right mdi-spin" />
						</button>
					)}
				</>
			}
		/>
	);
};

interface TabProps {
	amount: number | null;
	handleAmountChange: (event: ChangeEvent<HTMLInputElement>) => void;
	selectedLocation?: string;
	selectLocation?: (locationId: string) => void;
	availableTime?: Date;
	expirationTime?: Date;
	setAvailableTime?: (date: Date) => void;
	setExpirationTime?: (date: Date) => void;
}

const Form: FC<TabProps> = ({
	amount,
	handleAmountChange,
	selectedLocation = '',
	selectLocation = () => {},
	availableTime = new Date(),
	expirationTime = setDate(new Date(), new Date().getDate() + 7),
	setAvailableTime = (date) => {},
	setExpirationTime = (date) => {},
}) => {
	const { t } = useTranslation();
	const [locations, setLocations] = useState<Item[]>([]);

	const {
		onlineUser: { company, idGeolocation },
	} = useOnlineUser();

	useEffect(() => {
		if (!company?.id) return;
		(async function getGroupsAndLocations() {
			try {
				const locationsData = (await api.geo.getEntitySubNodeByType(company.id, 'COMPANY', 'LOCATION')) || [];
				setLocations(locationsData.map(({ id, name }) => ({ id, name })));
				if (idGeolocation) {
					selectLocation(idGeolocation);
				}
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);
	return (
		<div className="tab_content_wrap">
			<div className="tab_content active">
				<div className="flex_column margin_top_20 margin_left_20">
					<span className="margin_bottom_5">{t('general.amount')}</span>
					<input
						type="number"
						className="col-7 normal text_center"
						value={amount === null ? '' : amount}
						onChange={handleAmountChange}
					/>
				</div>
				<div className="row flex_justify_end margin_left_20 margin_bottom_20">
					<span className="font_11px">{t('usersAdministrations.emptyReservationAmount')}</span>
				</div>
				<div className="direction_column margin_left_20 margin_bottom_20">
					<span className="margin_bottom_5">{t('usersAdministrations.availableFrom')}</span>
					<div className="col-7 one_line_form">
						<DatePicker
							selected={availableTime}
							onChange={setAvailableTime}
							showTimeSelect
							timeFormat="HH:mm"
							timeIntervals={15}
							timeCaption="time"
							dateFormat="dd.MM.yy HH:mm"
							calendarClassName={'date-and-time'}
							className="normal col-12 text_center"
							wrapperClassName="col-12"
							minDate={new Date()}
							minTime={setHours(setMinutes(new Date(), 0), 0)}
							maxTime={setHours(setMinutes(new Date(), 59), 23)}
						/>
					</div>
				</div>
				<div className="direction_column margin_left_20 margin_bottom_20">
					<span className="margin_bottom_5">{t('usersAdministrations.expires')}</span>
					<div className="col-7 one_line_form">
						<DatePicker
							selected={expirationTime}
							onChange={setExpirationTime}
							showTimeSelect
							timeFormat="HH:mm"
							timeIntervals={15}
							timeCaption="time"
							dateFormat="dd.MM.yy HH:mm"
							calendarClassName={'date-and-time'}
							className="normal col-12 text_center"
							wrapperClassName="col-12"
							minDate={availableTime}
							minTime={setHours(setMinutes(new Date(), 0), 0)}
							maxTime={setHours(setMinutes(new Date(), 59), 23)}
						/>
					</div>
				</div>
				<div className="direction_column padding_right_50" style={{ marginBottom: 180 }}>
					<span className="margin_left_20 margin_bottom_5">{t('general.location')}</span>
					<Dropdown
						items={locations}
						selectedItem={selectedLocation}
						selectItem={selectLocation}
						withSearch
						listClass={'left_0'}
						fixedTitle={!selectedLocation ? t('general.location') : ''}
						margins={['10', '20', '0', '20']}
						icon="mdi mdi-menu-down"
						highlightLabel={!!selectedLocation}
						className="flex_justify_start margin_bottom_20"
					/>
				</div>
			</div>
		</div>
	);
};
