import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useData } from '../../../../../context/DataProvider';
import { ProviderData } from '../../types';
import './styles.css';

interface Props {
	providerData: ProviderData | null;
	filterBy: 'prematchData' | 'liveData';
	selectFlag: (flag: string) => void;
	deleteProvider: (id: string) => void;
}

export const ProviderFlags: FC<Props> = ({ providerData, filterBy, selectFlag, deleteProvider }) => {
	const { t } = useTranslation();
	const { providersMap } = useData();

	const providers = useMemo(() => {
		const providerDataArray = providersMap
			.filter((provider) => provider[filterBy])
			.map((provider) => {
				return { ...provider, data: providerData?.[provider.id] || null };
			});

		return providerDataArray;
	}, [providersMap, providerData]);

	const redish = 'mdi mdi-close-circle-outline activated provider_flag--icon red';
	const green = 'mdi mdi-checkbox-marked-circle-outline activated provider_flag--icon';

	return (
		<div className="wrapper_flags wrap" style={{ overflowY: 'auto' }}>
			{providers.map((item) => {
				const { data, id } = item;

				if (!data)
					return (
						<div key={id} className="provider">
							<div className="provider_header">
								<h3 className="padding_right_10">{id}</h3>
								<i
									className="mdi mdi-pencil font_16px provider_flag--icon blue"
									onClick={() => selectFlag(id)}
								></i>
							</div>
						</div>
					);

				const { matchTracker, betBuilder, stats, externalId } = data;

				return (
					<div key={id} className="provider">
						<div className="provider_header">
							<h3 className="padding_right_20">{id}</h3>
							<i
								className="mdi mdi-pencil font_16px provider_flag--icon blue"
								onClick={() => selectFlag(id)}
							></i>
							<i
								className="mdi mdi-close font_18px provider_flag--icon blue "
								onClick={() => deleteProvider(id)}
							></i>
						</div>

						{matchTracker !== null ? (
							<div className="provider_flag ">
								<div>{t('general.mt')}</div>
								<div>
									<i className={`${matchTracker ? green : redish}`}></i>
								</div>
							</div>
						) : null}

						{betBuilder !== null ? (
							<div className="provider_flag ">
								<div>{t('general.bb')}</div>
								<div>
									<i className={`${betBuilder ? green : redish}`}></i>
								</div>
							</div>
						) : null}

						{stats !== null ? (
							<div className="provider_flag ">
								<div>{t('general.stats')}</div>
								<div>
									<i className={`${stats ? green : redish}`}></i>
								</div>
							</div>
						) : null}

						{externalId ? (
							<div className="provider_flag" style={{ alignSelf: 'center', marginTop: 'auto' }}>
								<div>{externalId}</div>
							</div>
						) : null}
					</div>
				);
			})}
		</div>
	);
};
