import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserConfig } from '../../../../context/UserConfigProvider';
import { SortCell } from '../../../reports/tickets/components/SortCell';
import { ONLINE_SUPPORT_USERS_COLUMNS } from '../../../../consts/userConfig';

interface Props {
	selectedSort: string;
	selectSort: (sort: string) => void;
}

export const TableHeader: FC<Props> = ({ selectedSort, selectSort }) => {
	const { t } = useTranslation();
	const { isColumnVisible } = useUserConfig();
	return (
		<thead className="t_head">
			<tr>
				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.ID) && <td scope="col">{t('general.userId')}</td>}
				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.USERNAME) && <td scope="col">{t('general.username')}</td>}
				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.EMAIL) && <td scope="col">{t('general.email')}</td>}
				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.POLITICIAN) && <td scope="col"></td>}
				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.NATIONAL_ID) && <td scope="col">{t('general.jmbg')}</td>}
				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.NAME) && <td scope="col">{t('general.name')}</td>}
				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.LOCATION) && (
					<SortCell
						ascending="LOCATION_ASC"
						descending="LOCATION_DESC"
						label={t('onlineSupport.users.locationName')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}

				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.ONLINE_BALANCE) && (
					<SortCell
						ascending="BALANCE_ASC"
						descending="BALANCE_DESC"
						label={t('onlineSupport.users.onlineBalance')}
						selectedSort={selectedSort}
						selectSort={selectSort}
						labelClass="margin_left_auto"
					/>
				)}

				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.FB_BALANCE) && (
					<SortCell
						ascending="FREE_BET_BALANCE_ASC"
						descending="FREE_BET_BALANCE_DESC"
						label={t('onlineSupport.users.fbBalance')}
						selectedSort={selectedSort}
						selectSort={selectSort}
					/>
				)}

				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.RESERVATION) && (
					<SortCell
						ascending="RESERVATION_AMOUNT_ASC"
						descending="RESERVATION_AMOUNT_DESC"
						label={t('onlineSupport.users.reservation')}
						selectedSort={selectedSort}
						selectSort={selectSort}
						labelClass="margin_left_auto"
					/>
				)}

				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.CURRENCY) && <td scope="col">{t('general.currency')}</td>}

				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.TELEPHONE) && <td scope="col">{t('general.telephone')}</td>}

				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.CITY) && <td scope="col">{t('general.city')}</td>}
				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.ADDRESS) && <td scope="col">{t('general.address')}</td>}
				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.CREATED) && (
					<SortCell
						ascending="CREATED_ASC"
						descending="CREATED_DESC"
						label={t('general.createdAt')}
						selectedSort={selectedSort}
						selectSort={selectSort}
						labelClass="margin_left_auto"
					/>
				)}
				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.DESCRIPTION) && (
					<td scope="col">{t('general.description')}</td>
				)}
				{isColumnVisible(ONLINE_SUPPORT_USERS_COLUMNS.VERIFIED) && (
					<td scope="col" className="text_right">
						{t('general.verified')}
					</td>
				)}
			</tr>
		</thead>
	);
};
