import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { host } from './host';
import history from '../router/history';
import { toast } from 'react-toastify';

// Declare response type
export type Response = Promise<any> | AxiosPromise<any>;

export const axiosInstance = axios.create({
	baseURL: host,
	headers: { 'Content-Type': 'application/json' },
	paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
	withCredentials: true,
});

export const CANCELED_REQUEST = 'CANCELED_REQUEST';

axiosInstance.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error?.message === CANCELED_REQUEST) return Promise.reject(CANCELED_REQUEST);
		if (error.response.status === 403) {
			history.push('/forbiden');
		}

		if (error.response.status === 401) {
			const text = error?.response?.data?.detail.split(':').join('\n');
			toast.error(text, {
				position: 'top-right',
				hideProgressBar: true,
				closeOnClick: true,
				autoClose: 4000,
				style: { backgroundColor: '#e35454' },
			});
		}
		return Promise.reject(error);
	}
);
