import { ModalSize } from '../../Modals/types';
import i18n from './../../../localisation/i18n';

export type Tab = 'general' | 'params' | 'players';

interface TabItem {
	id: Tab;
	text: string;
	modalSize?: ModalSize;
	direction?: string;
}

export const navigationItems: TabItem[] = [
	{ id: 'general', text: i18n.t('modalNav.general') },
	{ id: 'params', text: i18n.t('modalNav.params'), modalSize: 'lg', direction: 'row' },
];
