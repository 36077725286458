import React, { ReactNode, Fragment, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAbility } from '../../../../casl/AbilityContext';

// Local interface for the component
interface Props {
	to: string;
	exact?: boolean;
	svg?: string;
	icon?: string;
	children?: ReactNode;
	text?: string;
	subject?: string;
}

export default ({ to, exact = false, svg, icon, children, text, subject }: Props) => {
	const [can, setCan] = useState(true);
	const { ability } = useAbility();

	useEffect(() => {
		if (subject) setCan(ability.can('read', subject));
	}, []);

	return (
		<Fragment>
			{can ? (
				<li>
					<NavLink to={to} exact={exact}>
						{svg ? (
							<span className="svg_icon">
								<svg className="e_ico">
									<use xlinkHref={'/assets/svg/svg_sprite.svg#' + svg} />
								</svg>
							</span>
						) : (
							<i className={'mdi mdi-' + icon} />
						)}
						<span className="label">{text ? text : children}</span>
					</NavLink>
				</li>
			) : null}
		</Fragment>
	);
};
