import React, { FC, useState } from 'react';
import api from '../../../../../api';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/Modals';
import { useNotify } from '../../../../../hooks/useNotifications';
import { useOnlineUser } from '../../../context/OnlineUserContext';
import { useFormContext } from 'react-hook-form';
import NotificationModal from '../../../../../components/general/NotificationModal';
import Delete from '../../../../../utils/svg/Delete';
import { emailIsValid } from '../../../../../utils/validateEmail';
import { useCopyToClipboard } from '../../../../../hooks/useCopyToClipboard';

interface Props {
	close: () => void;
	newEmail: string;
	setNewEmail: (newEmail: string) => void;
}

export const EditEmailModal: FC<Props> = ({ close, setNewEmail, newEmail }) => {
	const { onlineUser, setOnlineUser } = useOnlineUser();
	const { t } = useTranslation();
	const { success, warn } = useNotify();
	const { setValue } = useFormContext();
	const [confirmModal, setConfirmModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const { copy } = useCopyToClipboard();
	const copyValue = () => {
		copy(onlineUser.email || '');
	};

	const save = async () => {
		try {
			setLoading(true);
			await api.users.updateEmail(onlineUser.uid, newEmail);
			setOnlineUser({ ...onlineUser, email: newEmail });
			setValue('email', newEmail);
			success(t('general.emailUpdated'));
			setNewEmail('');
			close();
		} catch (err: any) {
			const errorText =
				err?.response?.data.detail?.[0]?.msg ||
				err?.response?.data?.detail ||
				err?.detail ||
				t('toast.error.defaultText');
			warn(errorText);
			setConfirmModal(false);
		} finally {
			setLoading(false);
		}
	};
	const validateEmail = () => {
		try {
			const isValidEmail = emailIsValid(newEmail.trim());
			if (isValidEmail && onlineUser.email !== newEmail) {
				setConfirmModal(true);
			} else {
				throw 'Invalid email';
			}
		} catch (error: any) {
			warn(error);
		}
	};

	return (
		<>
			<Modal
				size={'xl'}
				align={'start'}
				direction={'row'}
				opened={true}
				sectionClass={'admin_tour '}
				zIndex={'over_1'}
				cancelAction={close}
			>
				<div className="modal_content fadeInDown animated faster">
					<div className="modal_head">
						<div className={`tab_nav padding_left_20 padding_right_20 `}>
							<h2 className="modal_title padding_left_25">{t('general.updateEmail')}</h2>
						</div>
					</div>
					<div data-testid="modal-body" className="modal_body direction_column align_center">
						<div className="row margin_bottom_20">
							<div className=" text-right">
								<div className="label">{t('general.currentEmail')} </div>
							</div>

							<div className="column">
								<input
									value={onlineUser.email}
									disabled
									className="normal right_input"
									name="venue"
									type="text"
									autoComplete="off"
									title={'Current Email'}
								/>
								<i
									style={{ color: '#6e7884', fontWeight: 'normal' }}
									className="mdi mdi-content-copy copy accordion_switch_11 tooltip_right margin_left_10"
									onClick={() => copyValue()}
								>
									<span className="tooltip_content blueish">{t('general.copy')}</span>
								</i>
							</div>
						</div>
						<div className="row margin_bottom_20">
							<div className=" text-right">
								<div className="label">{t('general.newEmail')} </div>
							</div>

							<div className="column">
								<input
									value={newEmail}
									className="normal right_input"
									name="venue"
									type="text"
									autoComplete="off"
									title={'New Email'}
									onChange={(e) => {
										setNewEmail(e.target.value);
									}}
								/>
							</div>
						</div>
					</div>
					{
						<div data-testid="modal-footer" className={`modal_footer col_border_top`}>
							<button className="btn md round outline" onClick={close}>
								{t('general.cancel')}
							</button>
							<button type="button" className={'btn md round green'} onClick={() => validateEmail()}>
								{t('general.save')}
							</button>
						</div>
					}
				</div>
			</Modal>
			{confirmModal ? (
				<NotificationModal
					opened={!!confirmModal}
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={() => setConfirmModal(false)}
					confirmAction={() => save()}
					zIndex={'over_1'}
					loading={loading}
				>
					<div className="margin_20">
						<div className="context_icon margin_top_40">{<Delete />}</div>
						<h3 className="modal_message text_center"> {t('general.areYouSureEmail')} </h3>
						<p className="modal_message text_center margin_top_20"> {t('general.currentEmail')}</p>
						<p className="modal_message text_center margin_top_10 red_text">{onlineUser.email}</p>
						<p className="modal_message text_center margin_top_20"> {t('general.newEmail')}</p>
						<p className="modal_message text_center margin_top_10  margin_bottom_20 green_text">{newEmail}</p>
					</div>
				</NotificationModal>
			) : null}
		</>
	);
};
