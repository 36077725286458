import { setHours, setMinutes } from 'date-fns';
import React, { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import NotificationModal from '../../../../../components/general/NotificationModal';
import Modal from '../../../../../components/Modals';
import { useNotify } from '../../../../../hooks/useNotifications';
import { formatDate } from '../../../../../utils/getDate';
import Delete from '../../../../../utils/svg/Delete';
import { useOnlineUser } from '../../../context/OnlineUserContext';
import { Reservation } from '../types';

interface Props {
	initialAvailableTime: string;
	initialExpirationTime: string;
	close: () => void;
	updateReservation: (reservation: Reservation) => void;
}

export const UpdateTime: FC<Props> = ({
	initialAvailableTime = '',
	initialExpirationTime = '',
	close,
	updateReservation,
}) => {
	const {
		onlineUser: { uid },
	} = useOnlineUser();
	const { t } = useTranslation();
	const [availableTime, setAvailableTime] = useState<Date | null>(new Date(initialAvailableTime + 'Z'));
	const [expirationTime, setExpirationTime] = useState<Date | null>(new Date(initialExpirationTime + 'Z'));
	const [confirmModal, setConfirmModal] = useState(false);

	const [disabledModal, setDisabledModal] = useState(false);

	const openConfirmModal = () => {
		if (!availableTime || !expirationTime) return;
		if (expirationTime < availableTime) return warn(t('usersAdministrations.expirationError'), { autoClose: 4000 });
		setConfirmModal(true);
	};

	const closeConfirmModal = () => {
		setConfirmModal(false);
	};

	const { success, warn } = useNotify();

	const save = async () => {
		const payload = {
			userUuid: uid,
			dtAvailable: availableTime?.toISOString() || '',
			dtExpires: expirationTime?.toISOString() || '',
		};
		try {
			setDisabledModal(true);
			const reservationData = await api.users.updateReservationInfo(payload);
			success(t('toast.success.updateSuccessful', { entity: t('usersAdministrations.reservationAvailableTime') }));
			updateReservation(reservationData);
			closeConfirmModal();
			close();
		} catch (err) {
			warn(t('toast.warn.updateProblem', { entity: t('usersAdministrations.reservationAvailableTime') }), {
				autoClose: 5000,
			});
			closeConfirmModal();
		} finally {
			setDisabledModal(false);
		}
	};

	const timeFormat = 'date.month.year. hours:minutes';

	const updateDisabled =
		availableTime?.getTime() === new Date(initialAvailableTime + 'Z')?.getTime() &&
		expirationTime?.getTime() === new Date(initialExpirationTime + 'Z').getTime();

	return (
		<>
			<Modal
				size="md"
				opened={true}
				align="start"
				direction="column"
				cancelAction={close}
				body={
					<div className="margin_top_40">
						<div className="direction_column margin_left_20 margin_bottom_20">
							<span className="margin_bottom_5">{t('usersAdministrations.availableFrom')}</span>
							<div className="col-7 one_line_form">
								<DatePicker
									selected={availableTime}
									onChange={setAvailableTime}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="time"
									dateFormat="dd.MM.yy HH:mm"
									calendarClassName={'date-and-time'}
									className="normal col-12 text_center"
									wrapperClassName="col-12"
									minDate={new Date()}
									minTime={setHours(setMinutes(new Date(), 0), 0)}
									maxTime={setHours(setMinutes(new Date(), 59), 23)}
								/>
							</div>
						</div>
						<div className="direction_column margin_left_20" style={{ marginBottom: 270 }}>
							<span className="margin_bottom_5">{t('usersAdministrations.expires')}</span>
							<div className="col-7 one_line_form">
								<DatePicker
									selected={expirationTime}
									onChange={setExpirationTime}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="time"
									dateFormat="dd.MM.yy HH:mm"
									calendarClassName={'date-and-time'}
									className="normal col-12 text_center"
									wrapperClassName="col-12"
									minDate={availableTime}
									minTime={setHours(setMinutes(new Date(), 0), 0)}
									maxTime={setHours(setMinutes(new Date(), 59), 23)}
								/>
							</div>
						</div>
					</div>
				}
				footer={
					<>
						<button className="btn md round outline" onClick={close}>
							{t('general.cancel')}
						</button>
						<button
							type="submit"
							className={`btn md round green ${updateDisabled ? 'disabled' : ''}`}
							onClick={openConfirmModal}
						>
							{t('general.update')}
						</button>
					</>
				}
			/>
			{confirmModal && (
				<NotificationModal
					opened={true}
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={closeConfirmModal}
					confirmAction={save}
					loading={disabledModal}
				>
					<div className="context_icon margin_top_40">
						<Delete />
					</div>
					<h4 className="modal_message capitalize text_center padding_10 font_13px">
						{t('usersAdministrations.confirmUpdate', {
							availableFrom: formatDate(initialAvailableTime || '', timeFormat),
							availableTo: formatDate(availableTime?.toString().slice(0, -1) || '', timeFormat),
							expirationFrom: formatDate(initialExpirationTime || '', timeFormat),
							expirationTo: formatDate(expirationTime?.toString().slice(0, -1) || '', timeFormat),
						})}
					</h4>
				</NotificationModal>
			)}
		</>
	);
};
