import React, { FC, useState } from 'react';
import api from '../../../../../../api';
import { useGeo } from '../../../context';
import { useTranslation } from 'react-i18next';
import { ReferenceModal } from './ReferenceModal';
import { useNotify } from '../../../../../../hooks/useNotifications';

interface Props {
	locked: string;
	rowItem: { id: string; value: any; inherited: any };
	specifics: any;
}

export const ReferenceModalTrigger: FC<Props> = ({ locked, rowItem, specifics }) => {
	const { t } = useTranslation();
	const { notify } = useNotify();

	const { geoParameters } = useGeo();

	const [open, setOpen] = useState(false);

	const { id, value, inherited } = rowItem;
	const inputValue = locked ? inherited : value;

	const [pasteValue, setPasteValue] = useState('');

	const handlePaste = async () => {
		try {
			const text = await navigator.clipboard.readText();
			if (text) {
				await api.geo.verifyReferenceFieldData({ idGeoEntity: geoParameters.id, refFields: text }, specifics.uri);
			}

			setPasteValue(text);
			setOpen(true);
		} catch (err: any) {
			let errorText = t('toast.error.defaultText');
			if (err.response && err.response.data) errorText = err.response.data.detail[0].msg || err.response.data.detail;
			notify(errorText, 'error', { autoClose: 3000, style: { backgroundColor: '#fd9800' } });
		}
	};

	const closeModal = () => {
		setOpen(false);
		setPasteValue('');
	};

	const openModal = async function getData() {
		try {
			await api.geo.getReferenceFieldData({ idGeoEntity: geoParameters.id }, specifics.uri);
			setOpen(true);
		} catch (err: any) {
			let errorText = t('toast.error.defaultText');
			if (err.response && err.response.data) errorText = err.response.data.detail[0].msg || err.response.data.detail;
			notify(errorText, 'error', { autoClose: 3000, style: { backgroundColor: '#fd9800' } });
		}
	};

	return (
		<div className={`value_content ${locked}`} style={{ position: 'relative' }}>
			<input
				id={id}
				name={'id'}
				type="text"
				readOnly
				value={inputValue || ''}
				className={`normal col-12 padding_right_50 ${locked}`}
			/>
			<i
				className="mdi mdi-pencil edit tooltip_right"
				style={{ position: 'absolute', right: 30, top: 5 }}
				onClick={openModal}
			>
				<span className="tooltip_content green">{t('general.edit')}</span>
			</i>
			<i
				className="mdi mdi-content-paste tooltip_right"
				style={{ position: 'absolute', right: 5, top: 5 }}
				onClick={handlePaste}
			>
				<span className="tooltip_content green">{t('general.paste')}</span>
			</i>
			{open ? (
				<ReferenceModal
					rowItem={{ ...rowItem, value: pasteValue || value }}
					specifics={specifics}
					close={closeModal}
				/>
			) : null}
		</div>
	);
};
