import React, { useState, useEffect, useMemo, FC } from 'react';
import { useDebounce } from 'use-debounce/lib';
import api from '../../../../../../api';
import { SimpleLoader } from '../../../../../../components/general/Loader';
import { Header, PageFooter } from '../../../../../../components/layout';
import Modal from '../../../../../../components/Modals/index';
import { Search, useSearch } from '../../../../../../components/Search';
import { usePagination } from '../../../../../../hooks';
import { useAxios } from '../../../../../../hooks/useAxios';
import { SportFilter } from '../../../../../pairing/components';
import { SelectedTournament, TournamentItemExtended } from '../../../types';
import { useTranslation } from 'react-i18next';
import { Sport } from '../../../../sports/types';
import { SportIcon } from '../../../../sports/components/SportIcon';

interface Props {
	sports: Sport[];
	defaultSport: { id: string; defaultName: string };
	setTournaments: (items: SelectedTournament[]) => void;
	selected: SelectedTournament[];
	closeModal: () => void;
}

export const TournamentsSelector: FC<Props> = ({ defaultSport, setTournaments, selected, closeModal, sports }) => {
	const { t } = useTranslation();

	const [selectedTournaments, setSelectedTournaments] = useState(selected);
	const [selectedSport, selectSport] = useState<string>(defaultSport.id || 'fb');

	const [searchValue, setSearchValue] = useState<string>('');
	const searchOptions = useSearch(setSearchValue);
	const [debouncedText] = useDebounce(searchValue, 600);

	const { from, setTotal, perPage, setPerPage, ...pagination } = usePagination(30);

	const {
		data: { total, tournaments },
		loading,
	} = useAxios<{
		total: number;
		tournaments: TournamentItemExtended[];
	}>({
		apiFunction: async function getTour() {
			if (!selectedSport) return [];
			return await api.tournaments.getTournaments({
				sportId: selectedSport,
				query: debouncedText.length > 2 ? debouncedText : '',
				limit: perPage,
				skip: from,
			});
		},

		initialData: { total: 0, tournaments: [] },
		dependencies: [debouncedText, selectedSport, perPage, from],
	});

	useEffect(() => {
		setTotal(total);
	}, [total]);

	const check = (tournament: TournamentItemExtended) => {
		const { id, defaultName, category, sport } = tournament;
		const selected = {
			id,
			defaultName,
			category: { id: category.id, name: category.defaultName },
			sport: { id: sport.id, name: sport.defaultName },
		};
		setSelectedTournaments([...selectedTournaments, selected]);
	};

	const uncheck = (id: string) => {
		setSelectedTournaments((prev) => prev.filter((item) => item.id !== id));
	};

	const filteredTournaments = useMemo(() => {
		if (!tournaments || !tournaments.length) return [];
		return tournaments.filter((tour) => !selectedTournaments.some((item) => tour.id === item.id));
	}, [tournaments, selectedTournaments]);

	return (
		<Modal
			size="xxxl"
			opened={true}
			align={'start'}
			direction={'row'}
			zIndex={'over_3'}
			header={
				<Header
					panel
					leftTool={
						<SportFilter
							sports={sports}
							selectedSport={selectedSport}
							selectSport={selectSport}
							direction="left"
							margin="left_0"
						/>
					}
				>
					<Search message={t('offersPlan.3characters')} {...searchOptions} />
				</Header>
			}
			body={
				<SimpleLoader loaded={!loading}>
					<table className="admin_table sticky_header full_w">
						<TableHeader />
						<tbody className="t_body">
							{selectedTournaments.map((selected) => (
								<SelectedTableRow key={selected.id} item={selected} uncheck={uncheck} sports={sports} />
							))}

							{selectedTournaments.length ? (
								<div className="divider_horizontal small label_left ltr_direction">
									<div className="div_label">{t('tournamentsAdministration.selectTour')}</div>
									<div className="thin_line"></div>
								</div>
							) : null}

							{tournaments && tournaments.length
								? filteredTournaments.map((tournament) => (
										<TableRow key={tournament.id} item={tournament} check={check} sports={sports} />
								  ))
								: null}
						</tbody>
					</table>
				</SimpleLoader>
			}
			footer={
				<>
					<PageFooter removeSize={false} {...pagination} />
					<button className="btn md round outline" onClick={() => closeModal()}>
						{t('general.cancel')}
					</button>

					<button type="submit" className="btn md round green" onClick={() => setTournaments(selectedTournaments)}>
						{t('general.save')}
					</button>
				</>
			}
		/>
	);
};

const TableHeader: FC = ({}) => {
	const { t } = useTranslation();

	return (
		<thead className="t_head">
			<tr data-testid="head-row">
				<th>
					<div className="resort_coulmn"></div>
				</th>
				<th>{t('tournamentsAdministration.tournamentName')}</th>
				<th className="text_center">{t('tournamentsAdministration.sport')}</th>
				<th>{t('tournamentsAdministration.state')}</th>
			</tr>
		</thead>
	);
};

interface RowProps {
	item: TournamentItemExtended;
	check: (tournament: TournamentItemExtended) => void;
	sports: Sport[];
}

export const TableRow: FC<RowProps> = ({ item, check, sports }) => {
	const { defaultName, category } = item;
	const sport = sports.find((sport) => sport.id === item.sport.id)!;
	return (
		<tr data-testid="body-row">
			<td className="">
				<div className="checkbox_custom chek_only tooltip_right">
					<input type="checkbox" checked={false} onChange={() => {}} onClick={() => check(item)} />
					<i className="mdi mdi-checkbox-blank-outline unchecked" />
					<i className="mdi mdi-checkbox-marked checked" />
					<span className="tooltip_content">{'select'}</span>
				</div>
			</td>
			<td onClick={() => check(item)}>{defaultName}</td>

			<td className="text_center">
				<SportIcon sport={sport} tooltip="left" />
			</td>
			<td>{category.defaultName}</td>
		</tr>
	);
};

interface SelectedRowProps {
	item: SelectedTournament;
	uncheck: (id: string) => void;
	sports: Sport[];
}

export const SelectedTableRow: FC<SelectedRowProps> = ({ item, uncheck, sports }) => {
	const { id, defaultName, category } = item;
	const sport = sports.find((sport) => sport.id === item.sport.id)!;
	return (
		<tr data-testid="body-row">
			<td className="">
				<div className="checkbox_custom chek_only tooltip_right">
					<input type="checkbox" checked={true} onChange={() => {}} onClick={() => uncheck(id)} />
					<i className="mdi mdi-checkbox-blank-outline unchecked" />
					<i className="mdi mdi-checkbox-marked checked" />
					<span className="tooltip_content">{'select'}</span>
				</div>
			</td>
			<td onClick={() => uncheck(id)}>{defaultName}</td>

			<td className="text_center">
				<SportIcon sport={sport} tooltip="left" />
			</td>
			<td>{category.name}</td>
		</tr>
	);
};
