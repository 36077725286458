import React, { FC, useState, useContext, createContext } from 'react';
import { Roles } from '../pages/system/roles/types';

export interface UserAbilities {
	subject: string;
	actions: string[];
}

export interface User {
	email: string;
	name: string;
	casl: string[];
	email_verified: boolean;
	family_name: string;
	given_name: string;
	preferred_username: string;
	sub: string;
	can: UserAbilities[];
}

export const fakeUser = {
	name: 'Vlado',
	email: 'alea@alea.com',
	email_verified: false,
	family_name: 'Aleic',
	given_name: 'Alea',
	preferred_username: 'alea@alea.com',
	sub: 'cdb53024-6648-4e99-a540-99347a13d50c',
	casl: ['admin', 'result', 'risk', 'offer'],
	can: [
		{ actions: ['read'], subject: 'admin' },
		{ actions: ['read'], subject: 'result' },
		{ actions: ['read'], subject: 'risk' },
		{ actions: ['read'], subject: 'offer' },
	],
};

export interface UserContextProps {
	user: User | null;
	roles: Roles | null;
	superUser: boolean;
	login: (user: User, roles: Roles, superuser: boolean) => void;
	logout: () => void;
}

const userContextDefaultValues: UserContextProps = {
	user: null,
	roles: null,
	superUser: false,
	login: (user: User, roles: Roles, superuser: boolean) => {},
	logout: () => {},
};

const UserContext = createContext(userContextDefaultValues);

const UserProvider: FC = (props) => {
	const [user, setUser] = useState<User | null>(null);
	const [roles, setRoles] = useState<Roles | null>(null);
	const [superUser, setSuperUser] = useState(false);

	const login = (user: User, userRoles: Roles, superuser: boolean) => {
		setUser({ ...user });
		setRoles({ ...userRoles });
		setSuperUser(superuser);
	};

	const logout = () => {
		setUser(null);
	};

	return <UserContext.Provider value={{ user, login, logout, roles, superUser }} {...props} />;
};

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
