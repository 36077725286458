import { TournamentItem, TournamentItemExtended } from '../pages/administration/tournaments/types';

export const emptyTournament: TournamentItemExtended = {
	id: '',
	sport: {
		id: '',
		defaultName: '',
	},
	category: {
		id: '',
		defaultName: '',
	},
	priority: null,
	providerOffer: 'EXE',
	providerTable: 'EXE',
	providerResult: '',
	providerOdds: 'EXE',
	promo: '',
	relegationZone: '',
	active: true,
	timeCreation: '',
	timeModify: '',
	defaultName: '',
	name: {
		srb: '',
		en: '',
		de: '',
	},
	shortName: {
		srb: '',
		en: '',
		de: '',
	},
	description: {},
	icons: {
		web: '',
		terminal: '',
		mobile: '',
		list: '',
	},
	maxNumEvents: null,
	paymentDelay: 300,
	paymentDelayLive: 0,
	liveBettingDelay: 0,
	canCombineTournaments: true,
	canCombineLive: true,
	blockedForInternet: false,
	blockedForTerminal: false,
	blockedForAll: false,
	canCashout: true,
	inSingle: false,
	checkSimilarTickets: false,
	blockCombinationTournaments: [],
	prematchAutoRiskValue: null,
	liveAutoRiskValue: null,
	idRiskCategory: null,
	parameters: {
		authorizations: [],
		conditions: [],
		limits: [],
	},
	providerStandingsIdPhase: null,
	providerStandingsIdSeason: null,
};

export const temporaryTournament: TournamentItem = {
	id: 'temp',
	sport: {
		id: 'tempSport',
		defaultName: '',
	},
	category: {
		id: '',
		defaultName: '',
	},
	priority: null,
	providerOffer: 'EXE',
	providerTable: 'EXE',
	providerResult: 'EXE',
	providerOdds: 'EXE',
	promo: '',
	relegationZone: '',
	active: true,
	timeCreation: '',
	timeModify: '',
	defaultName: '',
	name: {
		srb: '',
		en: '',
		de: '',
	},
	shortName: {
		srb: '',
		en: '',
		de: '',
	},
	icons: {
		web: '',
		terminal: '',
		mobile: '',
		list: '',
	},
	description: {},
	canCombineTournaments: true,
	providerStandingsIdPhase: null,
	providerStandingsIdSeason: null,
};

export const temporarySpecialTournament: TournamentItem = {
	id: 'tempSpecial',
	sport: {
		id: 'tempSport',
		defaultName: '',
	},
	category: {
		id: '',
		defaultName: '',
	},
	priority: null,
	providerOffer: 'EXE',
	providerTable: 'EXE',
	providerResult: 'EXE',
	providerOdds: 'EXE',
	promo: '',
	relegationZone: '',
	active: true,
	timeCreation: '',
	timeModify: '',
	defaultName: '',
	name: {
		srb: '',
		en: '',
		de: '',
	},
	shortName: {
		srb: '',
		en: '',
		de: '',
	},
	icons: {
		web: '',
		terminal: '',
		mobile: '',
		list: '',
	},
	description: {},
	canCombineTournaments: true,
	providerStandingsIdPhase: null,
	providerStandingsIdSeason: null,
};
