import React, { FC, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import { Node } from '../../../parameters/geo/types';
import Dropdown from '../../../../components/general/Dropdown';
import MultiSelectDropdown from '../../../../components/general/MultiSelectDropdown';
import { useNotify } from '../../../../hooks/useNotifications';
import getErrorText from '../../../../utils/getErrorMsg';

interface Props {
	selectedLocations: string[];
	selectLocations: (locations: string[]) => void;
	instanceType?: string;
	width?: string;
	direction?: 'left_0' | 'right_0' | 'center_0' | 'down' | undefined;
	listClass?: string;
	filteredCompany?: string;
	filteredGroup?: string;
	filteredLocation?: string;
}

export const GeoFilters: FC<Props> = ({
	selectedLocations,
	selectLocations,
	instanceType = 'LANDBASE',
	width,
	direction = 'left_0',
	listClass,
	filteredCompany,
	filteredGroup,
	filteredLocation,
}) => {
	const { t } = useTranslation();
	const [companies, setCompanies] = useState<Node[]>([]);
	const [selectedCompany, setSelectedCompany] = useState('');
	const [groups, setGroups] = useState<Node[]>([]);
	const [selectedGroup, setSelectedGroup] = useState('');
	const [locations, setLocations] = useState<Node[]>([]);
	const { error } = useNotify();

	useEffect(() => {
		(async function getCompanies() {
			try {
				const data = (await api.geo.getEntity('COMPANY', instanceType)) || [];
				setCompanies(data);
			} catch (err) {
				error(getErrorText(err) || 'toast.error.defaultText');
			}
		})();
	}, []);

	useEffect(() => {
		if (!selectedCompany) {
			selectLocations([]);
			setSelectedGroup('');
		} else {
			(async function getGroupsAndLocations() {
				try {
					setSelectedGroup('');
					const groupsData = (await api.geo.getEntitySubNodeByType(selectedCompany, 'COMPANY', 'GROUP')) || [];
					setGroups(groupsData);

					getLocations(selectedCompany, 'COMPANY', 'LOCATION');
				} catch (err) {
					error(getErrorText(err) || 'toast.error.defaultText');
				}
			})();
		}
	}, [selectedCompany]);

	useEffect(() => {
		if (!selectedGroup && !selectedCompany) return;
		if (selectedCompany && !selectedGroup) getLocations(selectedCompany, 'COMPANY', 'LOCATION');
		else getLocations(selectedGroup, 'GROUP', 'LOCATION');
	}, [selectedGroup]);

	const getLocations = useCallback(async (id: string, entity: string, subEntity: string) => {
		try {
			const locationsData = (await api.geo.getEntitySubNodeByType(id, entity, subEntity)) || [];
			setLocations(locationsData);

			if (locationsData.length) selectLocations(['all', ...locationsData.map((location) => location.id)]);
			else selectLocations([]);
		} catch (err) {
			error(getErrorText(err) || 'toast.error.defaultText');
		}
	}, []);

	useEffect(() => {
		if (filteredLocation) selectLocations([filteredLocation]);
	}, [locations, filteredLocation]);

	useEffect(() => {
		if (filteredGroup) setSelectedGroup(filteredGroup);
	}, [groups, filteredGroup]);

	useEffect(() => {
		if (filteredCompany) setSelectedCompany(filteredCompany);
	}, [companies, filteredCompany]);

	return (
		<>
			<Dropdown
				items={[{ id: '', name: t('general.clearSelected') }, ...companies]}
				selectedItem={selectedCompany}
				selectItem={setSelectedCompany}
				withSearch
				listClass={listClass}
				fixedTitle={!selectedCompany ? t('general.company') : ''}
				margins={['0', '0', '0', '0']}
				icon={'mdi mdi-menu-down'}
				highlightLabel={!!selectedCompany}
				width={width}
				direction={'down'}
			/>
			{selectedCompany ? (
				<Dropdown
					items={[{ id: '', name: t('general.clearSelected') }, ...groups]}
					selectedItem={selectedGroup}
					selectItem={setSelectedGroup}
					withSearch
					listClass={listClass}
					fixedTitle={!selectedGroup ? t('general.group') : ''}
					margins={['0', '10', '0', '0']}
					icon={'mdi mdi-menu-down'}
					highlightLabel={!!selectedGroup}
					width={width}
					direction={direction ?? ''}
				/>
			) : null}

			{selectedCompany ? (
				<MultiSelectDropdown
					title={t('general.locations')}
					options={locations}
					selectedOptions={selectedLocations}
					selectOption={selectLocations}
					marginRight={'10'}
					withSearch
					width={width}
					direction={direction ?? ''}
				/>
			) : null}
		</>
	);
};
