import numeral from 'numeral';

const sr = 'sr';

//@ts-ignore
numeral.register('locale', sr, {
	delimiters: {
		thousands: '.',
		decimal: ',',
	},
});
numeral.defaultFormat('0,0.00');

export const toFixed = (value: number) => numeral(value).format();

export const toMoneyFormat = (value: number) => {
	return numeral(value).format();
};
