import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { formatDate } from '../../../../../utils/getDate';

interface Props {
	name: string;
	label: string;
	displayTime?: boolean;
}

export const DateValueFiled: FC<Props> = ({ name, label, displayTime = true }) => {
	const { getValues, control } = useFormContext();

	const value = getValues(name);

	const defaultValue = value !== null ? new Date(getValues(name)) : null;

	const date = useWatch({
		control,
		defaultValue,
		name,
	});

	const dateFormat = `date.month.year ${displayTime ? '| hours:minutes' : ''}`;
	const dateValue = date ? formatDate(date?.toString(), dateFormat) : '';

	return (
		<div className={`form_row read_only`}>
			<label htmlFor={name} className="input_label col-3">
				{label}
			</label>
			<div className="col-7">
				<span>
					{date !== null ? <input type="text" className="normal focus full_w" value={dateValue} disabled /> : ''}
				</span>
			</div>
		</div>
	);
};
