import { VoidTimeframe } from '../../pages/administration/live/types';
import { axiosInstance } from './../config';

interface Params {
	offerPlanId: string;
	skip?: number;
	limit?: number;
	orderBy: string;
	q: string;
	liveEventStatus: string[];
	eventStatus: string[];
	sportId: string;
	providerId: string;
}

export const liveEvents = {
	getLiveEvents(params: Params) {
		return axiosInstance.get(`/administration/live/filtered_events`, { params }).then((res) => res.data);
	},
	updateMarket(eventId: string, params: { status: string; splitTypes: boolean; oddIds: string[] }) {
		return axiosInstance.post(`administration/live/market/${eventId}`, null, { params }).then((res) => res.data);
	},
	getLiveEvent(id: string, idOfferPlan: string) {
		return axiosInstance.get(`/administration/live/event/${id}?idOfferPlan=${idOfferPlan}`).then((res) => res.data);
	},
	togglePayments(status: string, providers: string[]) {
		return axiosInstance.put(`/administration/live/events/${status}`, providers).then((res) => res.data);
	},
	setEventLiveStatus(id: string, status: string) {
		return axiosInstance.put(`/administration/live/event/${id}?liveStatus=${status}`).then((res) => res.data);
	},
	setEventMarketStatus(eventId: string, idMarket: string, status: string) {
		return axiosInstance
			.post(`/administration/live/market/${eventId}?idMarket=${idMarket}&status=${status}`)
			.then((res) => res.data);
	},
	setAllEventMarketStatuses(eventId: string, status: string) {
		return axiosInstance.post(`/administration/live/markets/${eventId}?status=${status}`).then((res) => res.data);
	},
	updateOddResult(odd: { id: string; value: number; idEvent: string; result: number; specialValue: string | null }) {
		return axiosInstance.post(`/administration/live/update_odd_result`, odd).then((res) => res.data);
	},
	updateEventStatus(id: string, status: string) {
		return axiosInstance.put(`/administration/live/status/${id}?status=${status}`).then((res) => res.data);
	},
	updateEventStartTime(id: string, utc_scheduled: string) {
		return axiosInstance
			.put(`/administration/live/start_time/${id}?utc_scheduled=${utc_scheduled}`)
			.then((res) => res.data);
	},
	createVoidTimeframe(eventId: string, timeframe: VoidTimeframe) {
		return axiosInstance.post(`/administration/void_timeframes/event/${eventId}`, timeframe).then((res) => res.data);
	},

	getAllVoidTimeframes(eventId: string) {
		return axiosInstance.get(`/administration/void_timeframes/event/${eventId}`).then((res) => res.data);
	},

	deleteVoidTimeframe(timeframeId: string) {
		return axiosInstance.delete(`/administration/void_timeframes/${timeframeId}/`).then((res) => res.data);
	},

	changeEventProivder(idEvent: string, idProvider: string, live = false) {
		return axiosInstance
			.put(`/administration/live/change_provider?idEvent=${idEvent}&idProvider=${idProvider}&live=${live}`)
			.then((res) => res.data);
	},

	replayMsgsExefeed(date: string) {
		return axiosInstance
			.post(`/administration/live/replay_messages_exefeed?last_update_utc=${date}`)
			.then((res) => res.data);
	},
};
