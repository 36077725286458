import { BannerData, BannerType } from './types';
import i18n from '../../../localisation/i18n';

export const PROMO = 'PROMO';
export const TICKET = 'TICKET';
export const EVENT = 'EVENT';
export const TOURNAMENT = 'TOURNAMENT';
export const CASINO_GAME = 'CASINO_GAME';
export const CASINO_TOURNAMENT = 'CASINO_TOURNAMENT';
export const I_FRAME = 'I_FRAME';

export const bannerTypes: {
	id: BannerType;
	name: string;
}[] = [
	{ id: PROMO, name: i18n.t('banners.promo') },
	{ id: TICKET, name: i18n.t('banners.ticket') },
	{ id: EVENT, name: i18n.t('banners.event') },
	{ id: TOURNAMENT, name: i18n.t('banners.tournament') },
	{ id: CASINO_GAME, name: i18n.t('banners.casinoGame') },
	{ id: CASINO_TOURNAMENT, name: i18n.t('banners.casinoTournament') },
	{ id: I_FRAME, name: i18n.t('banners.iFrame') },
];

export const emptyBanner: BannerData = {
	name: '',
	bannerType: PROMO,
	inheritTopBanner: false,
	banner: '',
	sideBanner: '',
	active: false,
};
