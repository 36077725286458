interface Order {
	order: number;
}

export function sortByOrder<T extends Order>(list: T[]) {
	list.sort((a: T, b: T) => {
		if (a.order! < b.order!) return -1;
		if (a.order! > b.order!) return 1;
		return 0;
	});
	return list;
}
