import { isEmpty, debounce } from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGeoFilters } from '../../context/GeoFiltersProvider';
import { Dropdown } from '../../pages/pairing/components';
import Button from './Button';
import MultiSelectDropdown from './MultiSelectDropdown';

interface Props {
	flexDirection?: 'flexDirectionColumn' | undefined;
}

export const GeoFilters: FC<Props> = ({ flexDirection }) => {
	const { t } = useTranslation();
	const {
		companies,
		selectedCompany,
		selectCompany,
		regions,
		selectedRegion,
		selectRegion,
		groups,
		selectedGroup,
		selectGroup,
		locations,
		selectedLocations,
		selectLocations,
		clearAll,
	} = useGeoFilters();
	return (
		<div className={`${flexDirection} justify_center align_center`}>
			<Dropdown
				items={companies}
				selectedItem={selectedCompany}
				selectItem={selectCompany}
				margins={['0', '20', '0', '10']}
				icon="mdi mdi-menu-down"
				nonSelectedString={t('geo.selectCompany')}
				withSearch
			/>
			<Dropdown
				items={regions}
				selectedItem={selectedRegion}
				selectItem={selectRegion}
				className={`${isEmpty(regions) ? 'disabled' : ''}`}
				margins={['0', '20', '0', '10']}
				icon="mdi mdi-menu-down"
				nonSelectedString={t('geo.selectRegion')}
				withSearch
			/>
			<Dropdown
				items={groups}
				selectedItem={selectedGroup}
				selectItem={selectGroup}
				className={`${isEmpty(groups) ? 'disabled' : ''}`}
				margins={['0', '20', '0', '10']}
				icon="mdi mdi-menu-down"
				nonSelectedString={t('geo.selectGroup')}
				withSearch
			/>
			<MultiSelectDropdown
				title={t('geo.selectLocations')}
				className={`${isEmpty(locations) ? 'disabled' : ''} margin_right_20`}
				options={locations}
				selectedOptions={selectedLocations}
				selectOption={selectLocations}
			/>
			<Button name={t('general.clear')} onClick={debounce(clearAll, 500)} className="red outline" />
		</div>
	);
};
