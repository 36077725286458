import { useState } from 'react';

export default function useTarget() {
	const [ target, setTarget ] = useState<string>('');
	const switchTarget = (newTarget: string) => setTarget(newTarget === target ? '' : newTarget);

	return {
		target,
		setTarget,
		switchTarget
	};
}
