import { axiosInstance } from './../config';
import { AuthParam } from '../../pages/parameters/authorization/types';

export const authParams = {
	getAuthParams(offset: number = 0, limit: number = -1): Promise<AuthParam[]> {
		return axiosInstance
			.get(`administration/auth_parameters/?offset=${offset}&limit=${limit}`)
			.then((response) => response.data.authParameters);
	},
	createAuthParam(authParam: AuthParam): Promise<AuthParam> {
		return axiosInstance.post('administration/auth_parameters/', authParam).then((response) => response.data);
	},

	updateAuthParam(authParam: AuthParam): Promise<AuthParam> {
		return axiosInstance
			.put(`administration/auth_parameters/${authParam.id}/`, authParam)
			.then((response) => response.data);
	},
};
