import { regular, live, internet, cashier, terminal, FB, INTERNET } from './actions';
import { State, Action } from './types';

export const initialState: State = {
	ticketTypes: {
		regular: {
			color: 'blueish',
			sign: 'R',
			active: true,
			tooltip: 'regular',
		},
		live: {
			color: 'green',
			sign: 'L',
			active: true,
			tooltip: 'live',
		},
	},
	payInTypes: {
		internet: {
			color: 'yellow',
			sign: 'I',
			active: true,
			tooltip: 'internet',
		},
		cashier: {
			color: 'gray',
			sign: 'C',
			active: true,
			tooltip: 'cashier',
		},
		terminal: {
			color: 'redish',
			sign: 'T',
			active: true,
			tooltip: 'terminal',
		},
	},
	accountType: {
		FB: {
			color: 'orange',
			sign: 'FB',
			active: true,
			tooltip: 'Free Bet',
		},
		INTERNET: {
			color: 'red',
			sign: 'I',
			active: true,
			tooltip: 'INTERNET',
		},
	},
};

export function reducer(state: State, action: Action): State {
	const { ticketTypes, payInTypes, accountType } = state;
	switch (action) {
		case regular:
			return {
				...state,
				ticketTypes: {
					...ticketTypes,
					regular: { ...ticketTypes.regular, active: !ticketTypes.regular.active },
				},
			};

		case live:
			return {
				...state,
				ticketTypes: {
					...ticketTypes,
					live: { ...ticketTypes.live, active: !ticketTypes.live.active },
				},
			};
		case internet:
			return {
				...state,
				payInTypes: {
					...payInTypes,
					internet: { ...payInTypes.internet, active: !payInTypes.internet.active },
				},
			};
		case cashier:
			return {
				...state,
				payInTypes: {
					...payInTypes,
					cashier: { ...payInTypes.cashier, active: !payInTypes.cashier.active },
				},
			};
		case terminal:
			return {
				...state,
				payInTypes: {
					...payInTypes,
					terminal: { ...payInTypes.terminal, active: !payInTypes.terminal.active },
				},
			};
		case FB:
			return {
				...state,
				accountType: {
					...accountType,
					FB: { ...accountType.FB, active: !accountType.FB.active },
				},
			};
		case INTERNET:
			return {
				...state,
				accountType: {
					...accountType,
					INTERNET: { ...accountType.INTERNET, active: !accountType.INTERNET.active },
				},
			};

		default:
			return state;
	}
}
