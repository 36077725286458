export const extendedStatuses = {
	WINNING: {
		color: 'green',
		sign: 'W',
		text: 'winning',
	},
	LOSING: {
		color: 'red',
		sign: 'L',
		text: 'losing',
	},
	CASHBACK: {
		color: 'orange',
		sign: 'CB',
		text: 'cashback',
	},
	PAID_OUT: {
		color: 'blue',
		sign: 'P',
		text: 'paid_out',
	},
	PAID_OUT_ERROR: {
		color: 'gray',
		sign: 'P',
		text: 'paid_out_error',
	}
};

export interface TicketStatusData {
	color: string;
	sign: string;
	text: string;
}
