import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelectDropdown from '../../../../../components/general/MultiSelectDropdown';
import api from '../../../../../api';
import { Item } from '../../../../../types/general';
import { useNotify } from '../../../../../hooks/useNotifications';
import getErrorText from '../../../../../utils/getErrorMsg';

interface Props {
	selectedLocations: string[];
	selectLocations: (locations: string[]) => void;
}

export const LocationsDropdown: FC<Props> = ({ selectedLocations, selectLocations }) => {
	const { t } = useTranslation();
	const { error } = useNotify();
	const [locations, setLocations] = useState<Item[]>([]);

	useEffect(() => {
		(async function getTransactionsFilters() {
			try {
				const data = await api.transactions.getLocations();
				setLocations(data.map(({ id, name }: any) => ({ id: id, name: name })));
			} catch (err) {
				error(getErrorText(err) || 'toast.error.defaultText');
			}
		})();
	}, []);

	return (
		<MultiSelectDropdown
			title={t('general.locations')}
			marginRight={'10'}
			options={locations}
			selectedOptions={selectedLocations}
			selectOption={selectLocations}
			withSearch
		/>
	);
};
