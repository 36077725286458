import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import NotificationModal from '../../../../../components/general/NotificationModal';
import { useNotify } from '../../../../../hooks/useNotifications';
import Delete from '../../../../../utils/svg/Delete';
import { useOnlineUser } from '../../../context/OnlineUserContext';
import { VerificationData } from '../types';

interface Props {
	openAddDocument: () => void;
	updateVerificationData: (data: VerificationData | null) => void;
}

type Actions = 'DELETE' | 'VERIFY';

export const Actions: FC<Props> = ({ openAddDocument, updateVerificationData }) => {
	const { t } = useTranslation();
	const { onlineUser, setOnlineUser } = useOnlineUser();

	const { success, warn } = useNotify();
	const { uid, dtAccountVerified } = onlineUser;

	const [confirmModal, setConfirmModal] = useState<Actions | null>(null);

	const deleteDocuments = async () => {
		try {
			await api.users.deleteVerificationData(uid);
			success('toast.success.documentsDeleted');
			updateVerificationData(null);
		} catch (err: any) {
			const errorText =
				err?.response?.data.detail?.[0]?.msg || err?.response?.data?.detail || t('toast.error.defaultText');
			warn(errorText);
		}
	};

	async function verifyUser() {
		try {
			await api.users.verifyAccount(uid);
			setOnlineUser({ ...onlineUser, dtAccountVerified: new Date().toISOString() });
			success(t('toast.success.userVerified'));
		} catch (err: any) {
			warn(err?.response?.data?.detail || t('toast.warn.userVerifyError'));
		}
	}

	const actionsMap = {
		DELETE: {
			action: deleteDocuments,
			text: t('usersAdministrations.deleteDocumentsConfirm'),
		},
		VERIFY: {
			action: verifyUser,
			text: t('usersAdministrations.verifyConfirm'),
		},
	};

	return (
		<div className="shrink_1280 flex_direction_row padding_20">
			<div className="col-1"></div>
			<div className="col-10 flex_direction_row padding_right_40">
				<button className="btn lg blue outline margin_left_auto " onClick={openAddDocument}>
					{t('usersAdministrations.addDocuments')}
				</button>
				<button className="btn lg red outline margin_left_10 " onClick={() => setConfirmModal('DELETE')}>
					{t('usersAdministrations.deleteDocuments')}
				</button>
				{/* <button className="btn lg blue outline">Posalji aktivacioni link</button>
				<button className="btn lg blue outline">Posalji link za reset lozinke</button>
				<button className="btn lg blue outline">Timeline</button> */}
				{!dtAccountVerified ? (
					<button className="btn lg green_bold margin_left_40" onClick={() => setConfirmModal('VERIFY')}>
						{t('usersAdministrations.verifyAccount')}
					</button>
				) : null}
			</div>
			<div className="col-1"></div>
			{confirmModal != null ? (
				<NotificationModal
					opened={true}
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={() => {
						setConfirmModal(null);
					}}
					confirmAction={() => {
						actionsMap[confirmModal]?.action();
						setConfirmModal(null);
					}}
					zIndex={'over_3'}
				>
					<div className="context_icon margin_top_40">
						<Delete />
					</div>
					<h2 className="modal_message capitalize text_center">{t('general.areSure')}</h2>
					<p className="text_center margin_top_10">{actionsMap[confirmModal]?.text}</p>
				</NotificationModal>
			) : null}
		</div>
	);
};
