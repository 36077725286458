import settings from '../settings.json';
import { remapMinified } from '../utils/remapper';

const { socketMinifactionEnabled = true } = settings;

export const parse = (string: string) => {
	const data = convert(string);
	const mapped = remap(data);
	return mapped;
};

const convert = (string: string) => {
	try {
		return JSON.parse(string);
	} catch {
		return string;
	}
};

const remap = (message: any) => {
	try {
		const type = message?.type;
		const data = message?.data;
		if (socketMinifactionEnabled) {
			const mapped = remapMinified(type, data);
			return { data: mapped, type };
		} else return message;
	} catch (err) {
		console.error(err);
		return message;
	}
};
