import { FieldMap, GeoParameter } from './../../pages/parameters/geo/types';
import axios from 'axios';
import { geoUrl, geoToken, geoWithCredentials } from './../host';
import { GeoSearchResponse, HorizontalTab, Node } from '../../pages/parameters/geo/types';
import { axiosInstance } from '../config';
import qs from 'qs';

interface CopyInstructions {
	to_entity: string;
	group: string;
	subgroup: string;
}

export const geoAxios = axios.create({
	baseURL: geoUrl,
	headers: { 'Content-Type': 'application/json', Authorization: geoToken },
	paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
	withCredentials: geoWithCredentials,
});

export const geo = {
	getCompanies() {
		return axiosInstance.get(`geo/?geoType=COMPANY`).then((response) => response.data);
	},
	getEntity(entity: string, instanceType: string = 'LANDBASE'): Promise<Node[]> {
		return geoAxios
			.get(`${geoUrl}geo/?geoType=${entity}&instanceType=${instanceType}`)
			.then((response) => response.data);
	},
	getSingleEntity(id: string): Promise<Node> {
		return geoAxios.get(`${geoUrl}geo/${id}`).then((response) => response.data);
	},
	getEntityParameters(entity: string, group: string, subgroup: string): Promise<GeoParameter> {
		return geoAxios
			.get(`${geoUrl}geo/${entity}/parameters?group=${group}&subgroup=${subgroup}`)
			.then((response) => response.data);
	},
	getEntityParametersForOnline(idParent: string, group: string, subgroup: string): Promise<GeoParameter> {
		return geoAxios
			.get(
				`${geoUrl}geo/get_sub_node_parameters_by_instance_type/?id_parent=${idParent}&group=${group}&subgroup=${subgroup}&instance_type=ONLINE`
			)
			.then((response) => response.data);
	},

	getEntityParametersForUser(userUuid: string, group: string, subgroup: string): Promise<GeoParameter> {
		return geoAxios
			.get(`${geoUrl}geo/user/${userUuid}/parameters?group=${group}&subgroup=${subgroup}`)
			.then((response) => response.data);
	},

	getEntityParametersForRiskGroup(
		idParent: string,
		group: string,
		subgroup: string,
		idUserGeolocation?: string
	): Promise<GeoParameter> {
		return geoAxios
			.get(
				`${geoUrl}geo/risk_group/${idParent}/parameters?group=${group}&subgroup=${subgroup}${
					idUserGeolocation ? `&idUserGeolocation=${idUserGeolocation}` : ``
				}`
			)
			.then((response) => response.data);
	},

	getTabs(): Promise<HorizontalTab[]> {
		return geoAxios.get(`${geoUrl}geo/parameter_structure/?convert_to_list=true`).then((response) => response.data);
	},
	getTabsRiskGroup(): Promise<HorizontalTab[]> {
		return geoAxios
			.get(`${geoUrl}geo/parameter_structure/?convert_to_list=true&forRiskGroup=true`)
			.then((response) => response.data);
	},

	getFieldMap(): Promise<FieldMap> {
		return geoAxios.get(`${geoUrl}geo/parameter_fields_mappings/`).then((response) => response.data);
	},

	getFieldMapRiskGrup(): Promise<FieldMap> {
		return geoAxios
			.get(`${geoUrl}geo/parameter_fields_mappings/?forRiskGroup=true`)
			.then((response) => response.data);
	},

	getEntitySubNodes(id: string, entity: string): Promise<Node[]> {
		return geoAxios.get(`${geoUrl}geo/${id}/sub_nodes?geoType=${entity}`).then((response) => response.data);
	},
	searchGeo(searchValue: string): Promise<GeoSearchResponse[]> {
		return geoAxios.get(`${geoUrl}geo/search/?query=${searchValue}`).then((response) => response.data);
	},
	getGeoParameters(id: string, params: { group: string; subgroup: string }) {
		return geoAxios.get(`${geoUrl}geo/${id}/parameters`, {params}).then((response) => response.data);
	},

	updateGeoParameters(id: string, params: { group: string; subgroup: string; parameters: { [key: string]: any } }) {
		return geoAxios.post(`${geoUrl}geo/${id}/parameters`, params).then((response) => response.data);
	},
	updateGeoParametersRiskGroup(
		id: string,
		params: { group: string; subgroup: string; idUserGeolocation?: string; parameters: { [key: string]: any } }
	) {
		return geoAxios.post(`${geoUrl}geo/risk_group/${id}/parameters`, params).then((response) => response.data);
	},

	updateUserGeoParameters(
		userUuid: string,
		params: { group: string; subgroup: string; parameters: { [key: string]: any } }
	) {
		return geoAxios.post(`${geoUrl}geo/user/${userUuid}/parameters`, params).then((response) => response.data);
	},
	copyParametersFromLandbaseToOnline(instructions: CopyInstructions) {
		return geoAxios.post(`${geoUrl}geo/copy_parameters_data/`, instructions).then((response) => response.data);
	},
	updateDescription(params: { parameter_key: string; description: string }) {
		return geoAxios.post(`${geoUrl}geo/update_description/`, params).then((response) => response.data);
	},
	getEntitySubNodeByType(id: string, entity: string, subEntity: string): Promise<Node[]> {
		return geoAxios
			.get(`${geoUrl}geo/get_sub_nodes_by_type/${id}?entity_type=${entity}&sub_node_entity_type=${subEntity}`)
			.then((response) => response.data);
	},

	// Dynamic Components calls

	getPaymentProvidersParams(subgroup: string) {
		return geoAxios
			.post(`${geoUrl}reference/payment_providers/subgroups/${subgroup}`)
			.then((response) => response.data);
	},

	getReferenceFieldData(params: any, uri: string) {
		return geoAxios.post(`${geoUrl}${uri.substring(1)}`, params).then((response) => response.data);
	},
	verifyReferenceFieldData(params: any, uri: string) {
		return geoAxios.post(`${geoUrl}${uri.substring(1)}/verify`, params).then((response) => response.data);
	},

	getCsvFieldData(params: any, uri: string) {
		return geoAxios.post(`${geoUrl}${uri.substring(1)}`, params).then((response) => response.data);
	},

	createEntityWithUUID(id: string, params: { [key: string]: any }) {
		return geoAxios.post(`${geoUrl}geo/create/${id}`, params).then((response) => response.data);
	},
	getBetMediaTokens() {
		return geoAxios.get(`${geoUrl}geo/bet_media/tokens`).then((response) => response.data);
	},
	deleteBetMediaToken(device: string) {
		return geoAxios
			.delete(`${geoUrl}geo/bet_media/token`, { data: { id_device: device } })
			.then((response) => response.data);
	},
	getBetMediaHeartbeats() {
		return geoAxios.get(`${geoUrl}geo/bet_media/heartbeats`).then((response) => response.data);
	},
};
