import { useEffect, useRef } from 'react';
import { LiveEvent, LiveEventExtended } from '../types';
import { isFunction } from 'lodash';

import settings from '../../../../settings.json';

export const eventChangeUpdatedUpdateDelay = settings.stopInactiveLiveEvents.timeToCheckEvents || 5000;

export const eventChangeUpdatedMaxDelay = settings.stopInactiveLiveEvents.timeThreshold || 120000;

type LiveData = {
	total: number;
	events: LiveEvent[];
};

export const useCheckNonActiveEvents = (
	setEventsCallback: React.Dispatch<
		React.SetStateAction<{
			total: number;
			events: LiveEvent[];
		}>
	>
) => {
	const checkLastEventUpdateTimeIntervalRef = useRef<NodeJS.Timeout | null>(null);


	useEffect(() => {
		if (!settings.stopInactiveLiveEvents.active) return;
		if (!checkLastEventUpdateTimeIntervalRef.current && isFunction(setEventsCallback)) {
			checkLastEventUpdateTimeIntervalRef.current = setInterval(() => {
				setEventsCallback((data) => {
					let needsUpdate = false;
					const currentDate = Date.now();
					const mappedEvents = data?.events?.map((event) => {
						const eventTime =
							event.eventUpdateTimeStamp ?? new Date(event.oddsDataUpdated + 'Z').getTime() ?? Date.now();
						if (
							currentDate - (eventTime as number) > eventChangeUpdatedMaxDelay &&
							event.status == 'RUNNING' &&
							event.liveStatus == 'LIVE' &&
							!excludedPeriods.includes(event.period)
						) {
							needsUpdate = true;
							return { ...event, status: 'STOPPED', updateDelayed: true };
						} else {
							return event;
						}
					});
					if (needsUpdate) {
						return { total: data.total, events: mappedEvents ?? [] };
					} else return data;
				});
			}, eventChangeUpdatedUpdateDelay);
		}
		return () => {
			if (checkLastEventUpdateTimeIntervalRef.current) clearInterval(checkLastEventUpdateTimeIntervalRef.current);
			checkLastEventUpdateTimeIntervalRef.current = null;
		};
	}, [setEventsCallback]);
};

export const excludedPeriods = settings.stopInactiveLiveEvents.pausePeriods || [
	'HALF_TIME',
	'OVERTIME',
	'EXTRA_TIME_HALF_TIME',
	'FIRST_QUARTER_END',
	'SECOND_QUARTER_END',
	'THIRD_QUARTER_END',
	'FOURTH_QUARTER_END',
	'FIRST_SET_END',
	'SECOND_SET_END',
	'THIRD_SET_END',
	'FOURTH_SET_END',
	'FIFTH_SET_END',
	'SIXTH_SET_END',
	'SEVENTH_SET_END',
	'FIRST_PERIOD_END',
	'SECOND_PERIOD_END',
	'THIRD_PERIOD_END',
	'FOURTH_PERIOD_END',
	'FIFTH_PERIOD_END',
	'SIXTH_PERIOD_END',
	'SEVENTH_PERIOD_END',
	'EIGHTH_PERIOD_END',
	'NINTH_PERIOD_END',
	'FIRST_MAP_END',
	'SECOND_MAP_END',
	'THIRD_MAP_END',
	'FOURTH_MAP_END',
	'FIFTH_MAP_END',
	'FINISHED',
	'INTERRUPTED',
	'ABANDONED',
	'RETIRED',
	'PAUSED',
	'DELAYED',
	'POSTPONED',
];

export const checkPeriod = (status: string, liveStatus: string, period: string) => {
	if (status == 'RUNNING' && liveStatus == 'LIVE' && !excludedPeriods.includes(period)) return true;
	else return false;
};

export const checkTimeDifference = (currentDate: number, time: number) => {
	try {
		const difference = currentDate - time;
		return difference > eventChangeUpdatedMaxDelay;
	} catch (error) {
		return false;
	}
};
