import React from 'react';
import { DeviceTabs } from '.';
import { useGeo } from '../context';

import { useTranslation } from 'react-i18next';

import { ModifiedActions } from './ModifiedActions';
import { SearchWrapper } from './SearchWrapper';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';

export const GeoHeader = () => {
	const { t } = useTranslation();
	useDocumentTitle(t('geo.title'));

	const { selectedInstance, modified, selectedNode, treeLoader } = useGeo();
	const { setTreeSearch, setSearch, setSelectedInstance, copyLandbaseToOnline } = useGeo();

	const disabledClass = modified ? 'disabled' : '';

	const treeLoaderClass = treeLoader ? 'disabled' : '';

	return (
		<div className={`main_head ${treeLoaderClass} `}>
			<div className="sidebar_head">
				<div className={`margin_left_20 ${disabledClass}`}>
					<SearchWrapper setSearch={setTreeSearch} />
				</div>

				<div className="divider_vert margin_left_auto"></div>
			</div>

			<div className="content_head padding_left_20 ">
				<DeviceTabs />

				<div className={`${disabledClass} margin_left_auto`} style={{ display: 'flex', alignItems: 'center' }}>
					{selectedNode ? (
						<>
							<button
								className={`btn blue light_blue md margin_left_40 ${
									selectedInstance === 'LANDBASE' ? '' : 'outline_thin'
								}`}
								onClick={() => setSelectedInstance('LANDBASE')}
							>
								{t('geo.landbase')}
							</button>
							<button
								className={`btn blue md light_blue margin_right_20 ${
									selectedInstance === 'ONLINE' ? '' : 'outline_thin'
								}`}
								onClick={() => setSelectedInstance('ONLINE')}
							>
								{t('geo.online')}
							</button>

							{/* <button
								style={{ opacity: selectedInstance === 'ONLINE' ? 1 : 0 }}
								className={`btn orange md margin_right_20 `}
								onClick={copyLandbaseToOnline}
								disabled={selectedInstance !== 'ONLINE'}
							>
								{t('geo.copyLandbase')}
							</button> */}
						</>
					) : null}

					<SearchWrapper setSearch={setSearch} />
				</div>
				<ModifiedActions />
			</div>
		</div>
	);
};
