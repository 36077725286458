import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { sportsArray } from '../../../consts/icons';
import { Sport } from '../../administration/sports/types';

interface Props {
	sports: Sport[];
	selectSport: (sportId: string) => void;
	selectedSport: string;
	direction: string;
	margin: string;
	listClass?: string;
	disabled?: boolean;
	sportsToggled?: boolean;
	setIsDisabledSearch?: (isDisabledSearchtId?: boolean) => void | undefined;
}

export default ({
	sports = [],
	selectSport,
	selectedSport,
	direction,
	margin,
	listClass = '',
	disabled,
	setIsDisabledSearch,
}: Props) => {
	const { i18n } = useTranslation();
	const { language: ln } = i18n;

	const [sportsToggled, toggleSports] = useState(false);
	const [sportDisplay, setSportDisplay] = useState<Sport | null>(null);

	const [hideMobileDropdown, setHideMobileDropdown] = useState(true);

	const toggleSportsList = () => {
		toggleSports((x) => !x);
		setHideMobileDropdown((x) => !x);
	};

	const toggleClass = sportsToggled
		? `sport_filter_expand from_${direction} margin_${margin} medium toggle`
		: `sport_filter_expand from_${direction} margin_${margin} medium`;

	useEffect(() => {
		if (setIsDisabledSearch !== undefined) {
			setIsDisabledSearch(sportsToggled);
		}
	}, [sportsToggled]);

	useEffect(() => {
		const selected: Sport | undefined = sports.find((sport) => `${sport.id}` === selectedSport);
		if (selected) setSportDisplay(selected);
	}, [selectedSport]);

	const handleChange = (id: string) => {
		if (selectedSport !== id) selectSport(id);
	};

	const sportIcon = (sport: Sport | null) => {
		const { id = '', icons = {} } = sport || {};

		const iconSrc = icons?.web || '';
		const defaultIcon = iconSrc ? <img src={iconSrc} className="td_flag" /> : <i className="mdi mdi-soccer-field" />;

		return (
			<>
				{sportsArray.includes(id!) ? (
					<svg className="e_ico">
						<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${id!}`} />
					</svg>
				) : (
					defaultIcon
				)}
			</>
		);
	};

	return (
		<div className={`${toggleClass}  ${disabled ? 'disabled' : ''}`} onClick={() => toggleSportsList()}>
			<span className="tool_item tooltip_right selected">
				{sportIcon(sportDisplay)}
				<span className="tooltip_content">{sportDisplay?.name?.[ln] || sportDisplay?.defaultName}</span>
			</span>

			<div className={`sport_filter_list sport_filter_mobile ${listClass} ${hideMobileDropdown ? 'hide' : null}`}>
				{sports.map((sport) => (
					<span key={sport.id} className="tool_item tooltip_right" onClick={() => handleChange(`${sport.id}`)}>
						{sportIcon(sport)}
						<span className="tooltip_content">{sport?.name?.[ln] || sport?.defaultName}</span>
					</span>
				))}
			</div>
		</div>
	);
};
