const getErrorText = (err: any) => {
	let errorText = undefined;
	if (err?.response?.data?.detail) {
		const msg = err.response.data.detail[0]?.msg || err.response.data.detail;
		if (typeof msg === 'string') errorText = msg;
	}

	if (!errorText) console.error(err); // If errorText not found

	return errorText;
};

export default getErrorText;
