import { round, values } from 'lodash';

type TicketGain = {
	minValue: number;
	maxValue: number;
};
export type TaxesValue = {
	minTax: number;
	maxTax: number;
};

export const calculateTaxForPlayedTicket = (
	ticketGain: TicketGain,
	ticketAmount: number,
	geoParams: any,
	globalData: any
) => {
	const taxValue = { minTax: 0, maxTax: 0 };
	const taxes = geoParams;

	const amountField =
		globalData.parameterMappings.PAY_OUT_TAX_LEVELS?.field_type_specifics?.pydantic_fields[0] ?? 'amount';
	const taxField = globalData.parameterMappings.PAY_OUT_TAX_LEVELS?.field_type_specifics?.pydantic_fields[1] ?? 'tax';
	if (!taxes) {
		return taxValue;
	}

	const nonTaxes = (taxes.DEDUCTIBLE_PAY_OUT_AMOUNT ?? 0) + (taxes.EXCLUDE_PAY_IN_FROM_PAY_OUT_TAX ? ticketAmount : 0);
	const maxGainWithTaxes = ticketGain.maxValue - nonTaxes;
	const minGainWithTaxes = ticketGain.minValue - nonTaxes;

	const parsedPayOutParams = values(taxes.PAY_OUT_TAX_LEVELS).sort(
		(tax1: any, tax2: any) => (+tax2[amountField] as number) - +tax1[amountField]
	);

	const taxMaxGain = parsedPayOutParams.find((amount: any) => (+amount[amountField] as number) <= maxGainWithTaxes);
	const taxMinGain = parsedPayOutParams.find((amount: any) => (+amount[amountField] as number) <= minGainWithTaxes);

	const roundedMaxPart = round(round(maxGainWithTaxes * (1 - +(taxMaxGain?.[taxField] ?? 0)), 10), 2);
	const finalMaxTax = round(maxGainWithTaxes - roundedMaxPart, 2);

	const roundedMinPart = round(round(minGainWithTaxes * (1 - +(taxMinGain?.[taxField] ?? 0)), 10), 2);
	const finalMinTax = round(minGainWithTaxes - roundedMinPart, 2);

	if (ticketGain.minValue > 0) {
		taxValue.minTax = finalMinTax > finalMaxTax ? finalMaxTax : finalMinTax;
	}

	taxValue.maxTax = finalMaxTax > 0 ? finalMaxTax : 0;

	return taxValue;
};
