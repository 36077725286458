import React from 'react';
import TreeItem from './Item';
import { Item, Context, Options } from '../types';

interface Props {
	options: Options;
	handleChange: (ctx: Context) => void;
}

export default (props: Props) => {
	const { handleChange, options } = props;
	const { items, generateContext } = options;

	return (
		<div className="tree_view">
			{items.map((item: Item, index: number) => {
				const ctx = generateContext(`${index}`, item);

				return (
					<TreeItem
						index={index}
						key={item.id}
						nested={false}
						context={ctx}
						handleChange={handleChange}
						generateContext={generateContext}
					/>
				);
			})}
		</div>
	);
};
