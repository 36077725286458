import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/general/Button';
import { toMoneyFormat } from '../../../../../utils/toFixed';
import { DepositWithdrawModal } from './DepositWithdrawModal';

interface Props {
	id?: number;
	name: string;
	balance: number;
	currency: string;
	blocked: number;
	walletType: string;
	refetchWalletBalance: () => void;
}

export const WalletBalance: FC<Props> = ({
	id = null,
	name = '',
	balance = 0,
	currency = '',
	blocked = 0,
	walletType,
	refetchWalletBalance,
}) => {
	const { t } = useTranslation();
	const available = balance - blocked;

	const [depositWithdrawData, setDepositWithdrawData] = useState(null) as any;

	const handleWalletClick = () => {
		if (walletType === 'INTERNET') setDepositWithdrawData({ walletType, transactionSubtypes: ['BANK', 'BONUS'] });
		else if (walletType === 'FB') setDepositWithdrawData({ walletType, transactionSubtypes: ['BONUS'] });
	};
	return (
		<div className="col-3 padding_20">
			<h3 className="title text_right col-4 padding_right_10">{name}</h3>

			<div className="form_row read_only">
				<label htmlFor="row_id_13" className="input_label col-4 text_right">
					{t('general.id')}
				</label>
				<div className="col-7">
					<input
						type="text"
						id="row_id_13"
						className="normal focus full_w text_right"
						value={id?.toString()}
						onChange={() => {}}
					/>
				</div>
			</div>
			<div className="form_row read_only">
				<label htmlFor="row_id_13" className="input_label col-4 text_right">
					{t('usersAdministrations.accountBalance')}
				</label>
				<div className="col-7">
					<input
						type="text"
						id="row_id_13"
						className="normal focus full_w text_right"
						value={toMoneyFormat(balance, '0.00')}
						onChange={() => {}}
					/>
				</div>
			</div>

			<div className="form_row read_only">
				<label htmlFor="row_id_16" className="input_label col-4 text_right">
					{t('usersAdministrations.currency')}
				</label>
				<div className="col-7">
					<input
						type="text"
						id="row_id_16"
						className="normal focus full_w text_right"
						value={currency}
						onChange={() => {}}
					/>
				</div>
			</div>

			<div className="form_row read_only padding_top_40">
				<label htmlFor="row_id_20" className="input_label col-4 text_right">
					{t('usersAdministrations.blocked')}
				</label>
				<div className="col-7">
					<input
						type="text"
						id="row_id_20"
						className="normal focus full_w text_right"
						value={toMoneyFormat(blocked, '0.00')}
						onChange={() => {}}
					/>
				</div>
			</div>

			<div className="form_row read_only ">
				<label htmlFor="row_id_19" className="input_label col-4 text_right">
					{t('usersAdministrations.available')}
				</label>
				<div className="col-7">
					<input
						type="text"
						id="row_id_19"
						className="normal focus full_w text_right"
						value={toMoneyFormat(available)}
						onChange={() => {}}
					/>
				</div>
			</div>
			<div className="form_row read_only align_center flex_justify_center">
				<div className="col-7 align_center">
					<Button name={'Deposit / Withdraw'} className="btn sm blue" onClick={handleWalletClick} />
				</div>
			</div>
			{depositWithdrawData ? (
				<DepositWithdrawModal
					depositWithdrawData={depositWithdrawData}
					refetchWalletBalance={refetchWalletBalance}
					close={() => setDepositWithdrawData(null)}
				/>
			) : null}
		</div>
	);
};
