import { useTranslation } from 'react-i18next';
import { toast, ToastOptions, TypeOptions } from 'react-toastify';

const defaultOptions: ToastOptions = {
	position: 'top-right',
	hideProgressBar: true,
	closeOnClick: true,
	autoClose: 2000,
};

export function useNotify() {
	const { t } = useTranslation();

	const notify = (text: string, type: TypeOptions = 'error', options: ToastOptions = defaultOptions) => {
		return toast[type](text, { ...defaultOptions, ...options });
	};

	const success = (text: string, options: ToastOptions = defaultOptions) => {
		return toast.success(t(text), { ...defaultOptions, style: { backgroundColor: '#7fbd22' }, ...options });
	};
	const error = (text: string, options: ToastOptions = defaultOptions) => {
		return toast.error(t(text), { ...defaultOptions, style: { backgroundColor: '#e35454' }, ...options });
	};

	const warn = (text: string, options: ToastOptions = defaultOptions) => {
		return toast.warning(t(text), { ...defaultOptions, style: { backgroundColor: '#fd9800' }, ...options });
	};

	const info = (text: string, options: ToastOptions = defaultOptions) => {
		return toast.info(t(text), { ...defaultOptions, style: { backgroundColor: '#19a6ea' }, ...options });
	};

	return { notify, success, error, warn, info };
}

