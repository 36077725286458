import React, { FC } from 'react';
import { useGeo } from '../../context';

interface Props {
	id: string;
}

export const RowDescription: FC<Props> = ({ id }) => {
	const { fieldMap, updateDescription } = useGeo();
	const { description } = fieldMap[id];

	return (
		<div className="accordion_collapse">
			<div className="range_title_option padding_right_20"></div>
			<div className="range_input">
				<div
					contentEditable="true"
					onBlur={(e) => {
						if (
							e.currentTarget &&
							e.currentTarget.textContent !== null &&
							description !== e.currentTarget.textContent
						) {
							updateDescription(id, e.currentTarget.textContent);
						}
					}}
					suppressContentEditableWarning={true}
					className="msg blue"
				>
					{description}
				</div>
			</div>
		</div>
	);
};
