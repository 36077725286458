import React, { FC, Dispatch, SetStateAction, useState, useMemo } from 'react';
import api from '../../../../api';
import { useData } from '../../../../context/DataProvider';
import { useTranslation } from 'react-i18next';
import getDate from '../../../../utils/getDate';
import Delete from '../../../../utils/svg/Delete';
import { PrematchEvent, PrematchOdd } from '../types';
import { useNotify } from '../../../../hooks/useNotifications';
import NotificationModal from '../../../../components/general/NotificationModal';
import { NOT_RUNNING } from '../../../../consts/strings';
import { oddsFormat } from '../../../../utils/oddsFormat';
import { Sport } from '../../../administration/sports/types';
import { SportIcon } from '../../../administration/sports/components/SportIcon';
import { getEventStatusAdditionalData } from '../../../administration/Events/helpers';
import { ProvidersFilter } from '.';
import { Provider } from '../../../system/integrations/types';
import { ProviderFlagsCell } from './ProviderFlags';

// Local props interface
interface Props {
	event: PrematchEvent;
	updateActive: (id: string) => void;
	updateTopOffer: (id: string) => void;
	selectEvent: Dispatch<SetStateAction<string>>;
	selectedRow: string;
	selectRow: Dispatch<SetStateAction<string>>;
	sports: Sport[];
	selectModalEvent: ((event: PrematchEvent) => void) | undefined;
	changeActiveProvider: (idEvent: string, providerData: Provider) => void;
	selectedSport?: string;
}

export const TableRow: FC<Props> = ({
	event,
	selectEvent,
	updateActive,
	selectedRow,
	selectRow,
	updateTopOffer,
	sports,
	selectModalEvent,
	changeActiveProvider,
	selectedSport,
}) => {
	const { t } = useTranslation();
	const { error } = useNotify();
	const { favoriteTips } = useData();
	const [open, setOpen] = useState(false);
	const { id, sport, competitors, scheduled, tournament, listCode, providerData } = event;
	const { status, active, markets, provider, topOffer, otherProviders } = event;

	const { id: sportId } = sport;
	const [date, time] = getDate(scheduled).split(' ');
	const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];

	const odds = markets.reduce((acc, current) => {
		return [...acc, ...current.odds.flat()];
	}, [] as PrematchOdd[]);

	var oddsMap = odds.reduce((map, odd) => {
		const [id, boundary] = odd.id.split('|');
		if (!boundary || (boundary && odd.specialValueOrder === 0)) map[id] = odd;
		return map;
	}, {});

	const handleSelect = () => {
		if (selectModalEvent) return;
		selectEvent(id);
		selectRow(id);
	};

	const select = () => {
		if (selectModalEvent) {
			selectModalEvent(event);
		} else {
			selectRow(selectedRow === id ? '' : id);
		}
	};

	const toggleActive = async () => {
		try {
			if (activeDisabled() && !active) throw t('toast.error.eventStarted');
			await api.events.toggleActive(id, !active);
			updateActive(id);
			selectRow(id);
		} catch (err: any) {
			error(err?.response?.data?.detail || err);
		} finally {
			setOpen(false);
		}
	};

	const toggleTopOffer = async () => {
		try {
			if (started()) throw t('toast.error.eventStarted');
			await api.events.toggleTopOffer(id, !topOffer);
			updateTopOffer(id);
			selectRow(id);
		} catch (err: any) {
			error(err?.response?.data?.detail || err);
			selectRow(selectedRow === id ? '' : id);
		}
	};

	const tooltipText = active ? t('general.active') : t('general.disabled');
	const tooltipTopOffer = topOffer ? t('prematch.controller.inTopOffer') : t('prematch.controller.outTopOffer');

	const started = () => new Date(`${scheduled}Z`).getTime() < Date.now();
	const activeDisabled = () => status !== NOT_RUNNING || started();
	const favoriteTipsSport = selectedSport === 'all' ? 'all' : sportId;

	// Default array in this format is temporary until we have all sports in system
	const { oddtypes = ['-', '-', '-'], hendikep = false, boundary = false } = favoriteTips[favoriteTipsSport] || {};

	const sportItem = sports.find((item) => item.id === sportId) ?? sport;
	const emptyOdd = 'emptyOdd';

	const displayOddTypes = useMemo(() => {
		const _oddTypes = oddtypes.filter((odd: string) => odd.startsWith('o' + event.sport.id));

		if (_oddTypes.length > 2) return _oddTypes;

		if (_oddTypes.length === 0 && selectedSport === 'all') {
			_oddTypes.splice(1, 0, emptyOdd, emptyOdd);
		} else if (_oddTypes.length === 0) {
			_oddTypes.splice(1, 0, emptyOdd, emptyOdd, emptyOdd);
		}
		if (selectedSport === 'all') {
			_oddTypes.splice(1, 0, emptyOdd);
		}

		return _oddTypes;
	}, [event.sport, oddtypes]);

	const { color, name } = getEventStatusAdditionalData(status);

	return (
		<>
			<tr className={`${selectedRow === id ? 'blue_row' : ''}`}>
				<td onClick={select} onDoubleClick={handleSelect}>
					{listCode || ''}
				</td>
				<td className="time_stamp" onClick={select} onDoubleClick={handleSelect}>
					<span className="date font_700">{date}</span>
					<span className="time"> {time}</span>
				</td>

				<td className="font_700 text_right" onClick={select} onDoubleClick={handleSelect}>
					{home}
				</td>
				<td className="text_center vs" onClick={select} onDoubleClick={handleSelect}>
					vs
				</td>
				<td className="font_700" onClick={select} onDoubleClick={handleSelect}>
					{away}
				</td>

				<td className="text_center" onClick={select} onDoubleClick={handleSelect}>
					<SportIcon sport={sportItem} tooltip="left" />
				</td>

				<td onClick={select} onDoubleClick={handleSelect}>
					{tournament.name}
				</td>

				<td onClick={select} onDoubleClick={handleSelect}>
					<span className={`tag_item ${color}`}>{name}</span>
				</td>

				{!selectModalEvent
					? displayOddTypes.map((id: string, i: number) => {
							if (id === emptyOdd) {
								return <td key={`${emptyOdd}_${event.id}_${i}`}>-</td>;
							}
							const odd = oddsMap[id];
							const key = `${id}:${i}`;
							return (
								<td key={key}>
									{odd?.specialValue ? <span>{odd.specialValue + '| '}</span> : null}

									<span>{odd && odd.value ? oddsFormat(+odd.value) : '-'}</span>
								</td>
							);
					  })
					: null}

				<td className="">
					<div className="checkbox_custom chek_only tooltip_left">
						<input
							type="checkbox"
							onClick={() => setOpen(true)}
							checked={active}
							onChange={() => {}}
							disabled={activeDisabled() || !!selectModalEvent}
						/>
						<i className="mdi mdi-checkbox-blank-outline unchecked" />
						<i className="mdi mdi-checkbox-marked checked" />
						<span className="tooltip_content">{tooltipText}</span>
					</div>
				</td>
				<td className="">
					<div className="checkbox_custom chek_only tooltip_left">
						<input
							type="checkbox"
							onClick={toggleTopOffer}
							checked={topOffer}
							onChange={() => {}}
							disabled={activeDisabled() || !!selectModalEvent}
						/>
						<i className="mdi mdi-checkbox-blank-outline unchecked" />
						<i className="mdi mdi-checkbox-marked checked" />
						<span className="tooltip_content">{tooltipTopOffer}</span>
					</div>
				</td>
				{providerData ? (
					<ProviderFlagsCell
						providerData={providerData}
						filterBy={'prematchData'}
						handleSelect={select}
						handleDblClick={handleSelect}
					/>
				) : (
					<td onClick={select} onDoubleClick={handleSelect}>
						<div className=" checkbox_custom chek_only tooltip_left text_center align_center flex_justify_end">
							<span>-</span>
						</div>
					</td>
				)}

				<td onClick={select} onDoubleClick={handleSelect} className="text_center">
					{otherProviders?.length ? (
						<ProvidersFilter
							selectProvider={() => {}}
							selectProviderItem={(item: Provider) => {
								if (item.id === provider?.id) return;
								changeActiveProvider(id, item);
							}}
							selectedProvider={provider?.id || ''}
							connectedList={otherProviders}
						/>
					) : null}
				</td>
			</tr>
			{open ? (
				<NotificationModal
					opened={!!open}
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={() => setOpen(false)}
					confirmAction={toggleActive}
				>
					<div className="context_icon margin_top_40">
						<Delete />
					</div>
					<h2 className="modal_message capitalize text_center">{t('general.areSure')}</h2>
				</NotificationModal>
			) : null}
		</>
	);
};
