import React from 'react';

export default () => {
	return (
		<svg className="svg_graphic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 158 130">
			<path
				fill="#E35454"
				d="M105.8,69.2c-1.3,0-2.5-0.5-3.5-1.5c-1.9-1.9-1.9-5.1,0-7l8.7-8.7c5.2-5.2,5.2-13.6,0-18.8 c-5.2-5.2-13.6-5.2-18.8,0l-8.7,8.7c-1.9,1.9-5.1,1.9-7,0c-1.9-1.9-1.9-5.1,0-7l8.7-8.7c9.1-9.1,23.8-9.1,32.9,0 C127,35.2,127,50,118,59l-8.7,8.7C108.3,68.7,107,69.2,105.8,69.2z"
			/>
			<path
				fill="#E35454"
				d="M56.7,110.6c-6,0-11.9-2.3-16.4-6.8c-4.4-4.4-6.8-10.2-6.8-16.4s2.4-12,6.8-16.4l8.7-8.7 c1.9-1.9,5.1-1.9,7,0c1.9,1.9,1.9,5.1,0,7L47.3,78c-2.5,2.5-3.9,5.9-3.9,9.4s1.4,6.9,3.9,9.4c5.2,5.2,13.6,5.2,18.8,0l8.7-8.7 c1.9-1.9,5.1-1.9,7,0s1.9,5.1,0,7l-8.7,8.7C68.6,108.3,62.7,110.6,56.7,110.6z"
			/>
			<path
				fill="#E35454"
				d="M97.4,121c-2.7,0-5-2.2-5-5v-13.2c0-2.7,2.2-5,5-5s5,2.2,5,5V116C102.3,118.7,100.1,121,97.4,121z"
			/>
			<path
				fill="#E35454"
				d="M130.2,88.2H117c-2.7,0-5-2.2-5-5s2.2-5,5-5h13.2c2.7,0,5,2.2,5,5S132.9,88.2,130.2,88.2z"
			/>
			<path
				fill="#E35454"
				d="M60.9,32.1c-2.7,0-5-2.2-5-5V14c0-2.7,2.2-5,5-5s5,2.2,5,5v13.2C65.8,29.9,63.6,32.1,60.9,32.1z"
			/>
			<path fill="#E35454" d="M41.3,51.7H28.1c-2.7,0-5-2.2-5-5s2.2-5,5-5h13.2c2.7,0,5,2.2,5,5S44,51.7,41.3,51.7z" />
		</svg>
	);
};
