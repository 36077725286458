import React, { useState, useEffect, Fragment, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';

import { temporaryTournament } from '../../../../../models/tournamentsAdministration';
import { CompetitorItem } from '../../../../administration/competitors/types';
import { TournamentItem } from '../../../../administration/tournaments/types';
import { CategoryItem } from '../../../../administration/categories/types';

import Modal from '../../../../../components/Modals';
import TournamentSelect from '../../../components/TournamentSelect';
import CompetitorSelect from '../../../components/CompetitorSelect';
import { DateSelect } from './DateSelect';
import { usePairing } from '../../../_framework';
import { Item } from '../../../types';

import { SimpleLoader } from '../../../../../components/general/Loader';
import { select } from '../../../../../utils';

interface Props {
	tournamentsSimple: TournamentItem[];
	setTournamentsSimple: (tournamentsSimple: TournamentItem[]) => void;
	isLive?: boolean;
}

export default ({ tournamentsSimple, setTournamentsSimple, isLive = true }: Props) => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [loadingAllData, setLoadingAll] = useState(false);

	const [localTournament, setLocalTournament] = useState<string>('');
	const [homeCompetitor, setHomeCompetitor] = useState<CompetitorItem | null>(null);

	const [awayCompetitor, setAwayCompetitor] = useState<CompetitorItem | null>(null);

	const { provider, providerItem, setProviderItem, directlyPairProvided, updateProviderItems } = usePairing();

	const closeModal = () => {
		setProviderItem({} as Item);
		setLocalTournament('');
		setHomeCompetitor(null);
		setAwayCompetitor(null);
	};

	const [categories, setCategories] = useState<CategoryItem[]>([]);
	const [tournaments, setTournaments] = useState<TournamentItem[]>([]);

	useEffect(() => {
		(async function initModalData() {
			if (!Object.keys(providerItem).length) return;
			const sportId = providerItem.sport.mappedTo.id;
			try {
				setLoadingAll(true);
				const { categories } = await api.categories.getCategories(sportId);
				setCategories(categories);

				const tournamentData = tournamentsSimple?.filter((tournament) => tournament?.sport?.id === sportId);

				setTournaments([...tournamentData, temporaryTournament]);
				setLoadingAll(false);

				setTimeout(() => {
					const { competitors, tournament } = providerItem;
					const [home, away] = competitors;
					const focus = !tournament.mapped
						? 'local-tournament'
						: !home.mapped
						? 'home'
						: !away.mapped
						? 'away'
						: 'pairing-import';

					select(focus);
				}, 200);
			} catch (error) {
				console.error(error);
			}
		})();
	}, [providerItem]);

	const map = async () => {
		try {
			setLoading(true);
			let tournamentId = localTournament;
			let tournamentData: TournamentItem | null = null;
			let home = homeCompetitor;
			let away = awayCompetitor;

			// If we created new tournament localy and selected it, send api call
			const tournament = tournaments.find((item) => item.id === tournamentId);
			if (tournament) {
				if (tournamentId === 'temp') {
					const data = await api.tournaments.createTournament({
						...tournament,
						categoryId: tournament.category.id,
					});
					tournamentId = `${data.id}`;
					tournamentData = data;
					setTournamentsSimple([...tournamentsSimple, tournamentData]);
				} else {
					tournamentData = tournament;
				}
			}
			// If we created new home competitor localy and selected it, send api call
			if (home && home.type === 'home') {
				const { sport, type, ...rest } = home;
				const data = await api.competitors.createCompetitors({ ...rest, sportId: sport.id });
				home = data;
			}

			// If we created new away competitor localy and selected it, send api call
			if (away && away.type === 'away') {
				const { sport, type, ...rest } = away;
				const data = await api.competitors.createCompetitors({ ...rest, sportId: sport.id });
				away = data;
			}

			if (providerItem && home && away) {
				// Make map request
				const { status, data } = await api.mappings.events.importEvents(
					provider,
					providerItem.id,
					tournamentId,
					`${home.id}`,
					`${away.id}`,
					isLive
				);
				if (status === 200) {
					// Update provided data mapped fields
					updateProviderItems(providerItem, tournamentData, home, away);

					// Add item to paired data
					const [providerHome, providerAway] = providerItem.competitors;

					const mappedTournament = {
						...providerItem.tournament,
						mapped: true,
						mappedTo: { id: tournamentData?.id, name: tournamentData?.defaultName },
					};
					const mappedCompetitors = [
						{ ...providerHome, mapped: true, mappedTo: { id: home.id, name: home.defaultName } },
						{ ...providerAway, mapped: true, mappedTo: { id: away.id, name: away.defaultName } },
					];

					directlyPairProvided({
						id: data.imported[0]?.mappedTo.id,
						pairedWith: [{ ...providerItem, competitors: mappedCompetitors, tournament: mappedTournament }],
						startTime: providerItem.utcScheduled,
						name: providerItem.name,
						sport: providerItem.sport.mappedTo,
						tournament: { id: tournamentData?.id, name: tournamentData!.defaultName },
						category: { id: tournamentData!.category.id, name: tournamentData!.category.defaultName },
						listCode: data.imported[0]?.meta.listCode,
						competitors: [
							{ id: home.id, name: home.defaultName },
							{ id: away.id, name: away.defaultName },
						],
					});
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
			closeModal();
		}
	};

	const saveOnEnter = (e: KeyboardEvent<HTMLButtonElement>) => {
		if (e.keyCode !== 13 || loading) return;
		map();
	};

	const selectNext = (type: string) => {
		setTimeout(() => {
			if (type === 'competitor') select(!homeCompetitor ? 'home' : !awayCompetitor ? 'away' : 'pairing-import');
			else if (type === 'home' && !awayCompetitor) select('away');
			else select('pairing-import');
		}, 100);
	};

	// Disable import button if tournament or competitors are  empty
	const isDisabled = !localTournament || !homeCompetitor || !awayCompetitor;
	const disableClass = isDisabled ? 'disabled' : '';

	return Object.keys(providerItem).length ? (
		<Modal
			opened={true}
			size={'lg'}
			align={'start'}
			direction={'column'}
			zIndex={'over_1'}
			cancelAction={() => closeModal()}
			header={<h2 className="modal_title padding_left_20">{t('eventsPairing.import')}</h2>}
			body={
				<SimpleLoader loaded={!loadingAllData}>
					<div className="direction_column padding_left_25 padding_right_20 ">
						<TournamentSelect
							tournaments={tournaments}
							categories={categories}
							setTournaments={setTournaments}
							selectTournament={setLocalTournament}
							providerItem={providerItem}
							select={selectNext}
						/>
						<CompetitorSelect
							type={t('general.home')}
							selectCompetitor={setHomeCompetitor}
							providerItem={providerItem}
							select={selectNext}
						/>
						{providerItem.competitors && providerItem.competitors[1] && (
							<CompetitorSelect
								type={t('general.away')}
								selectCompetitor={setAwayCompetitor}
								providerItem={providerItem}
								select={selectNext}
							/>
						)}
						<DateSelect startTime={providerItem.utcScheduled} />
					</div>
				</SimpleLoader>
			}
			footer={
				<Fragment>
					<button className={`btn md round outline ${loading ? 'disabled' : ''}`} onClick={() => closeModal()}>
						{t('general.cancel')}
					</button>

					<button
						id="pairing-import"
						onKeyUp={saveOnEnter}
						disabled={isDisabled}
						className={`btn md round green ${disableClass}`}
						onClick={() => !loading && map()}
					>
						{loading ? <i className="mdi mdi-rotate-right mdi-spin" /> : `${t('general.import')}`}
					</button>
				</Fragment>
			}
		/>
	) : null;
};
