import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { sortIconHelper, sortTypeHelper } from '../../../../../utils/sorting';

interface Props {
	orderByDateSent: string;
	setOrderByDateSent: Dispatch<SetStateAction<string>>;
	orderByDateExpire: string;
	setOrderByDateExpire: Dispatch<SetStateAction<string>>;
}

export const TableHeader: FC<Props> = ({
	orderByDateSent,
	setOrderByDateSent,
	orderByDateExpire,
	setOrderByDateExpire,
}) => {
	const { t } = useTranslation();
	return (
		<thead className="t_head">
			<tr data-testid="head-row">
				<th>
					<span className="font_400">{t('userNotifications.columnTitle')}</span>
				</th>

				<th scope="col">
					<span className="label display_inline">{t('userNotifications.columnDateSent')}</span>
					<i
						data-testid="resortClass"
						className={`mdi ${sortIconHelper[orderByDateSent]}`}
						onClick={() => setOrderByDateSent(sortTypeHelper[orderByDateSent])}
					/>
				</th>

			
				<th>
					<span className="font_400">{t('userNotifications.columnSentBy')}</span>
				</th>
				<th scope="col">
					<span className="label display_inline">{t('userNotifications.columnDateExpire')}</span>
					<i
						data-testid="resortClass"
						className={`mdi ${sortIconHelper[orderByDateExpire]}`}
						onClick={() => setOrderByDateExpire(sortTypeHelper[orderByDateExpire])}
					/>
				</th>
				
				<th>
					<span className="font_400">{t('userNotifications.columnIsRead')}</span>
				</th>
				<th>
					<span className="font_400">{t('userNotifications.columnIsRead')}</span>
				</th>
				<th>&nbsp;</th>
			</tr>
		</thead>
	);
};

export default TableHeader;
