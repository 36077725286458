import i18n from './../../../localisation/i18n';

export const allowedEntityColumnsByShowTypeFilter = {
	company: ['company'],
	region: ['company', 'region'],
	group: ['company', 'region', 'group'],
	location: ['shortLocationCode','company', 'region', 'group', 'location'],
};

export const payinTypes = [
	{ id: 'all', name: i18n.t('general.all') },
	{ id: 'live', name: i18n.t('general.live') },
	{ id: 'prematch', name: i18n.t('general.prematch') },
];

export const paymentMethods = [
	{ id: 'all', name: i18n.t('general.all') },
	{ id: 'cash', name: i18n.t('general.cash') },
	{ id: 'nocash', name: i18n.t('general.noCash') },
];
export const transactionTypes = [
	{ id: 'INTERNET', name: i18n.t('general.internet') },
	{ id: 'FREE_BET', name: i18n.t('general.freeBet') },
	{ id: 'POS', name: i18n.t('general.pos') },
	{ id: 'TERMINAL', name: i18n.t('general.terminal') },
];

export const instanceTypes = [
	{ id: 'all', name: i18n.t('general.all') },
	{ id: 'pos', name: i18n.t('general.pos') },
	{ id: 'online', name: i18n.t('general.online') },
	{ id: 'terminal', name: i18n.t('general.terminal') },
];

export const accountTypes = [
	{ id: 'all', name: i18n.t('general.all') },
	{ id: 'cash', name: i18n.t('general.cash') },
	{ id: 'free_bet', name: i18n.t('general.freeBet') },
];
