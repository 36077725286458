import React from 'react';
import { useGeo } from '../context';
import { GeoRow } from './Fields/GeoRow';

export const GeoContent = () => {
	const { geoParameters, fieldMap, searchValue, selectedDevice, selectedGroup } = useGeo();
	const dontFilter =
		searchValue.length < 3 ||
		(selectedDevice && selectedDevice.key
			? selectedDevice.key.toLowerCase().includes(searchValue.toLowerCase())
			: true) ||
		(selectedGroup && selectedGroup.key ? selectedGroup.key.toLowerCase().includes(searchValue.toLowerCase()) : true);

	return (
		<div className="geo_content_wrap">
			<div className="form_set geo_form">
				{Object.keys(geoParameters).length ? (
					Object.entries(geoParameters.entity_params)
						.filter((item) =>
							!dontFilter ? fieldMap[item[0]].label.toLowerCase().includes(searchValue.toLowerCase()) : true
						)
						.sort((a, b) => fieldMap[a[0]].position - fieldMap[b[0]].position)
						.map((item) => {
							const [id, value] = item;
							const inherited = geoParameters.inherited_params[id];
							return <GeoRow key={id} rowItem={{ id, value, inherited }} />;
						})
				) : (
					<div>Please select node-device-group</div>
				)}
			</div>
			<div className="empty_div"></div>
		</div>
	);
};
