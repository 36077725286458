import React, { ChangeEvent, Fragment } from 'react';
import { AutomaticFilters } from '../../types';
import { useTranslation } from 'react-i18next';
import { useEmitter } from 'use-emitter';
import { useUser } from '../../../../../context/UserProvider';

interface Props {
	automaticFilters: AutomaticFilters;
	toggleAuto: (type: string) => void;
	setAuto: (type: string, val: string) => void;
}

export default ({ toggleAuto, setAuto, automaticFilters }: Props) => {
	const { t } = useTranslation();
	const { autoAssign, autoAuthorize, autoReject } = automaticFilters;
	const socket = useEmitter() as SocketIOClient.Socket;
	const { user } = useUser();

	const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
		socket?.emit('auto_assign', { id: user?.sub, value: target.checked }, (emited: boolean) => {
			if (emited) toggleAuto('autoAssign');
		});
	};

	return (
		<Fragment>
			<label className="switch automatic_ticket_assignment">
				<input className="switch_mark" type="checkbox" checked={autoAssign.active} onChange={handleChange} />
				<span className="slider" />
				<span className="checkbox_label">
					{t('general.automatic')}
					<br />
					{t('general.ticketAssign')}
				</span>
			</label>

			<label className="switch automatic_authorization">
				<input
					className="switch_mark"
					type="checkbox"
					checked={autoAuthorize.active}
					onChange={() => toggleAuto('autoAuthorize')}
				/>
				<span className="slider" />
				<span className="checkbox_label">
					<span className="on_label">
						<input
							type="number"
							id="authorize"
							placeholder="..."
							value={autoAuthorize.value}
							onChange={(e) => {
								setAuto('autoAuthorize', e.target.value);
							}}
						/>
					</span>
					<span className="off_label">
						Automatski Autorizuj
						<br />
						Uplate manje od
					</span>
				</span>
			</label>

			<label className="switch automatic_authorization">
				<input
					className="switch_mark"
					type="checkbox"
					checked={autoReject.active}
					onChange={() => toggleAuto('autoReject')}
				/>
				<span className="slider" />
				<span className="checkbox_label">
					<span className="on_label">
						<input
							type="number"
							id="authorize-2"
							placeholder="..."
							value={autoReject.value}
							onChange={(e) => {
								setAuto('autoReject', e.target.value);
							}}
						/>
					</span>
					<span className="off_label">
						Automatski odbaci
						<br />
						Uplate Vece Od
					</span>
				</span>
			</label>
		</Fragment>
	);
};
