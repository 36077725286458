import React, { FC, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Dropdown } from '../../../pairing/components';
import { useTranslation } from 'react-i18next';
import { useInitialMount } from '../../../../hooks';

interface Props {
	providers: { id: string; name: string }[];
}

export const SelectProvider: FC<Props> = ({ providers }) => {
	const { t } = useTranslation();
	const initialMount = useInitialMount();

	const { getValues, setValue } = useFormContext();
	const { id, providerResult } = getValues();

	const [selectedProvider, setSelectedProvider] = useState<string>(providerResult);
	const [selectedItem, selectItem] = useState(providerResult);

	useEffect(() => {
		if (!id && !initialMount) {
			setValue('providerResult', '');
			setSelectedProvider('');
		}
	}, []);

	const handleSelect = (id: string | number) => {
		const { name, id: providerId } = providers.find((provider) => provider.id === id)!;

		setSelectedProvider(!id ? '' : name);
		selectItem(providerId);
		setValue('providerResult', providerId);
	};

	return (
		<Dropdown
			zIndex={'z_10'}
			items={providers}
			selectedItem={selectedItem}
			fixedTitle={selectedProvider || t('Select provider')}
			selectItem={handleSelect}
			margins={['0']}
			icon={'mdi mdi-menu-down'}
			withSearch
			listClass="list_6_items z_10"
		/>
	);
};
