import React, { FC, useState, ChangeEvent } from 'react';
import api from '../../../../api';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/Modals';
import { useNotify } from '../../../../hooks/useNotifications';
import { LimitsData, UpadateLimitsData } from '../limits/types';

interface Props {
	code: string;
	close: () => void;
	isDisabled: boolean;
	setIsDisabled: (isDisabled: boolean) => void;
	setSelectedLimit: (selectedLimit: LimitsData | null) => void;
	setModalIsClosed: (modalIsClosed: boolean) => void;
	closedModal: boolean;
	selectedLimit: LimitsData;
}

const LimitsModal: FC<Props> = ({
	code,
	close,
	isDisabled,
	setIsDisabled,
	setSelectedLimit,
	selectedLimit,
	closedModal,
	setModalIsClosed,
}) => {
	const { t, i18n } = useTranslation();
	const { success, error, warn } = useNotify();

	const inputDays = selectedLimit.periodInDays;
	const [inputAmount, setInputAmont] = useState(selectedLimit.amount || '');

	const setAmountLimitHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setInputAmont(event.target.value);
		event.preventDefault();
	};

	const save = async () => {
		let data = {
			limits: [
				{
					amount: inputAmount,
					periodInDays: inputDays,
				},
			],
			type: selectedLimit.type,
		} as UpadateLimitsData;

		try {
			setIsDisabled(true);

			if (!inputAmount) warn(t('usersAdministrations.FillAllInputs'));

			const create = await api.users.updateLimitsData(+code, data);
			setModalIsClosed((closedModal = !closedModal));
			success(t('toast.success.limitUpdated', { entity: t('applicationType.singleName') }));
			setSelectedLimit(null);
		} catch (err: any) {
			console.log(err);
			const errorText =
				err?.response?.data.detail?.[0]?.msg ||
				err?.response?.data?.detail ||
				err?.detail ||
				t('toast.error.defaultText');
			warn(errorText);
		} finally {
			setIsDisabled(false);
		}
	};

	return (
		<>
			<Modal
				size={'xl'}
				align={'start'}
				direction={'row'}
				opened={true}
				sectionClass={'admin_tour '}
				zIndex={'over_1'}
				cancelAction={close}
			>
				<div className="modal_content fadeInDown animated faster">
					<div className="modal_head">
						<div className={`tab_nav padding_left_20 padding_right_20 `}>
							<h2 className="modal_title padding_left_25">{t('usersAdministrations.editLimit')}</h2>
						</div>
					</div>
					<div data-testid="modal-body" className="modal_body modal-body_mobile direction_column align_center">
						<div className="row margin_bottom_20 align_right direction_column">
							<div className=" text-left">
								<div className="label col-12 margin_left_10">
									{t('usersAdministrations.inLastNumberOfDays')}
								</div>
							</div>
							<div className="column">
								<input
									value={inputDays}
									className="normal right_input"
									name="venue"
									type="number"
									autoComplete="off"
									disabled={true}
								/>
							</div>
						</div>
						<div className="row margin_bottom_20 direction_column">
							<div className=" text-left">
								<div className="label col-12 margin_left_10">{t('general.balance')}</div>
							</div>
							<div className="column">
								<input
									value={inputAmount}
									onChange={setAmountLimitHandler}
									className="normal right_input"
									name="venues"
									type="number"
									autoComplete="off"
								/>
							</div>
						</div>
					</div>
					{
						<div data-testid="modal-footer" className={`modal_footer modal-footer_mobile col_border_top`}>
							<button className="btn md round outline" onClick={close}>
								{t('general.cancel')}
							</button>
							<button
								type="submit"
								className={`btn md round green ${isDisabled ? 'disabled' : ''}`}
								onClick={save}
							>
								{t('general.save')}
							</button>
						</div>
					}
				</div>
			</Modal>
		</>
	);
};

export default LimitsModal;
