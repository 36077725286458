import { OnlineUser } from './types';

export const tabs: string[] = [
	'details',
	'wallets',
	'tickets',
	'transactions',
	'parameters',
	'bonuses',
	'documents',
	'notifications',
	'limits',
	'loging',
];

export const defaultTab = 'details';

export const bonusTabs = [
	{ id: 'geo', name: 'Geo bonuses' },
	{ id: 'active', name: 'Activated bonuses' },
];

export const onlineUserDefault: OnlineUser = {
	username: '',
	email: '',
	firstName: '',
	lastName: '',
	city: '',
	address: '',
	zip: '',
	gender: '',
	nationalId: '',
	idCard: '',
	passport: '',
	birthDate: '',
	telephone: '',
	status: '',
	idGeolocation: '',
	depositInSum: 0,
	depositOutSum: 0,
	ticketInSum: 0,
	ticketOutSum: 0,
	id: 0,
	uid: '',
	code: '',
	created: '',
	updated: null,
	lastActive: null,
	geolocation: {
		name: '',
		entityType: '',
		idGroup: '',
		city: '',
		address: '',
		telephoneNum: '',
		coordinates: [],
		params: {},
		id: '',
	},
	currency: '',
	gamingInSum: 0,
	gamingOutSum: 0,
	numberOfTickets: 0,
	numberOfWinningTickets: 0,
	riskTag: null,
	lastIpAddress: null,
	averageEventNumber: null,
	averageMaxQuota: null,
	internetBalance: 0,
	freeBetBalance: 0,
	reservedAmount: 0,
	changeHistory: [],
	company: null,
	dtVerified: '',
	dtAccountVerified: '',
	accountVerifiedBy: '',
	selfBanned: false,
	dtSelfBannedFrom: null,
	dtSelfBannedTo: null,
	comment: null,
	country: '',
	bankAccount: null,
	hasVerificationData: null,
};
