import React, { FC, ChangeEvent } from 'react';

import { useGeo } from '../../../context';

interface Props {
	locked: string;
	rowItem: { id: string; value: any; inherited: any };
}
export const ReferenceField: FC<Props> = ({ locked, rowItem }) => {
	const { updateGeoParameters } = useGeo();
	const { id, value, inherited } = rowItem;
	const inputValue = locked ? inherited : value;

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const val = e.currentTarget.value === '' ? undefined : e.currentTarget.value;
		updateGeoParameters(id, val);
	};
	return (
		<div className="value_content">
			<input
				id={id}
				name={id}
				type="text"
				onChange={handleChange}
				value={inputValue == null ? '' : inputValue}
				className={`normal full_w ${locked}`}
			/>
		</div>
	);
};
