export default {
	arrowUp: 'chevron-up',
	arrowRight: 'chevron-right',
	arrowDown: 'chevron-down',
	arrowLeft: 'chevron-left',
	circle: 'circle-medium',
	drag: 'drag',
	dragVertical: 'drag-vertical',
	checkbox: 'checkbox-blank-outline',
	checkboxMarked: 'checkbox-marked',
	loading: 'rotate-right mdi-spin',
	lock: 'lock',
};

export const sportsArray = [
	'fb',
	'bb',
	'tn',
	'ih',
	'vb',
	'af',
	'hb',
	'tt',
	'vf',
	'bm',
	'bv',
	'rb',
	'ft',
	'sn',
	'dt',
	'bs',
	'bx',
	'go',
	'ol',
	'rg',
	'wp',

	'es',
	'ef',
	'cr',
	'mm',

	'bf',
	'3x3',
	'rg',
	'b20',

	'bfs',
	'3x3s',
	'crs',
	'ess',
	'mms',
	'efs',

	'fbs',
	'bbs',
	'tns',
	'ihs',
	'vbs',
	'afs',
	'hbs',
	'tts',
	'vfs',
	'bms',
	'bvs',
	'rbs',
	'fts',
	'sns',
	'dts',
	'bss',
	'bxs',
	'gos',
	'ols',
	'rgs',
	'wps',
	'all',
];
