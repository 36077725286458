import { EventSpecial, EventSpecialData } from './EventSpecial/types';
import { PlayerProps, PlayerPropsModel } from './PlayerProps/types';
import { MixEvent, MixSpecial } from './SpecialMix/types';

export const playerPropsModelTemplate: PlayerPropsModel = {
	idEvent: '',
	idSport: '',
	idSpecialSport: '',
	idHome: '',
	idAway: '',
	utcScheduled: '',
	listCode: 0,
	idOfferPlan: '',
	offerPlanStatus: '',
	markets: {},
	idSpecialTournament: '',
	homePlayers: [],
	awayPlayers: [],
};

export const emptyPlayerProp: PlayerProps = {
	idParentEvent: '',
	idOfferPlan: '',
	idSpecialTournament: '',
	idHome: '',
	idAway: '',
	homeProps: [],
	awayProps: [],
};

export const emptyEventSpecial: EventSpecial = {
	idParentEvent: '',
	idOfferPlan: '',
	idSpecialTournament: '',
	markets: [],
};

export const eventSpecialDataTemplate: EventSpecialData = {
	idSpecialEvent: '',
	utcScheduled: '',
	listCode: 0,
	active: false,
	markets: [],
};

export const emptyMixSpecial: MixSpecial = {
	idParentEvent: '',
	idOfferPlan: '',
	idSpecialTournament: '',
	mixSpecials: [],
};

export const confirmActions = { save: 'save', cancel: 'cancel' };
