import { EmptyUserBonus } from './../pages/internet/bonuses/types';

export const userBonusEmptyModel: EmptyUserBonus = {
	id: '',
	bonusCode: '',
	name: {
		de: '',
		en: '',
		srb: '',
	},
	description: {},
	unlockDescription: {},
	bonusType: 'DEPOSIT',
	currency: 'BAM',
	status: 'ACTIVE',
	minDeposit: '',
	maxValue: '',
	fixedValue: '',
	percentage: null,
	depositNumForPayout: null,
	depositDayLimit: null,
	minQuotaPerOddType: '',
	minNumberOfEvents: '',
	minTicketQuota: '',
	promoCode: '',
	dtFrom: new Date().toISOString(),
	dtUntil: new Date().toISOString(),
	activeAt: new Date().toISOString(),
	canBeApplied: '',
	canBeAppliedDaily: '',
	validForDays: '',
	bettingMultiplier: '',
	userCategories: [],
	isProviderBonus: false,
	externalProviderStatus: 'SHARED',
	withOnlineWallet: false,
	withFreeBetWallet: false,
	depositAccounts: [],
	idTournaments: [],
	bettingUnlockContributions: [],
	idEvents: [],
	idSports: [],
	bettingBonusLevel: 'SPORT',
	allowedLiveOffer: true,
	allowedPrematchOffer: true,
	notes: '',
	payoutWalletType: 'INTERNET',
	targetAmount: 'DEPOSIT_AMOUNT',
};
