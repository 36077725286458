import React, { FC, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CategoryItem } from '../../categories/types';
import { Dropdown } from '../../../pairing/components';
import { useTranslation } from 'react-i18next';
import { useInitialMount } from '../../../../hooks';

interface Props {
	categories: CategoryItem[];
}

export const SelectCategory: FC<Props> = ({ categories }) => {
	const { t, i18n } = useTranslation();
	const { language: ln } = i18n;
	const initialMount = useInitialMount();

	const { getValues, setValue } = useFormContext();
	const { id, category } = getValues();

	const [selectedCategory, setSelectedCategory] = useState<string>(category.defaultName);
	const [selectedItem, selectItem] = useState(category.id);

	useEffect(() => {
		if (!id && !initialMount) {
			setValue('category.id', '');
			setValue('category.defaultName', '');
			setSelectedCategory('');
		}
	}, [categories]);

	const handleSelect = (id: string | number) => {
		const { name, defaultName, id: catId } = categories.find((cat) => cat.id === id)!;

		setSelectedCategory(name?.[ln] || defaultName);
		selectItem(catId);
		setValue('category.id', catId);
		setValue('category.defaultName', name?.[ln] || defaultName);
	};

	const items = categories.map((cat) => ({
		id: cat.id,
		name: cat.name?.[ln] || cat.defaultName,
	}));

	return (
		<Dropdown
			zIndex={"z_10"}
			items={items}
			selectedItem={selectedItem}
			fixedTitle={selectedCategory || t('Select category')}
			selectItem={handleSelect}
			margins={['0']}
			icon={'mdi mdi-menu-down'}
			withSearch
			listClass="list_6_items"
		/>
	);
};
