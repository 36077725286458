import { useMemo } from 'react';
import { useData } from '../../../context/DataProvider';

export function useRiskGroup(id: number | null | undefined) {
	const { riskGroups } = useData();

	const group = useMemo(() => {
		return riskGroups.find((group) => group.id === id);
	}, [id, riskGroups]);

	return group;
}
