import { axiosInstance } from './../config';

interface UpdateParams {
	id: string;
	value: number | undefined;
	result?: number;
	oddtypeStatus?: number;
}

interface Params {
	q: string;
	nonBonus?: boolean;
	oddsType?: string[];
	offset?: number;
	limit?: number;
	orderBy: string;
	localisation?: string;
	sportId: string;
}

export const bonuses = {
	getBonusEvents(params: Params) {
		return axiosInstance.get(`administration/events/bonus?`, { params }).then((response) => response.data);
	},

	getSingleBonusEvent(eventId: string) {
		return axiosInstance.get(`administration/events/bonus/${eventId}`).then((response) => response.data);
	},

	updateBonusEvent(eventId: string, params: UpdateParams[]) {
		return axiosInstance
			.put(`administration/events/bonus/odd/${encodeURIComponent(eventId)}`, params)
			.then((response) => response.data);
	},

	deleteBonusEvent(eventId: string) {
		return axiosInstance.delete(`administration/events/bonus/${eventId}`).then((response) => response.data);
	},
};
