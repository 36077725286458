import { TemplateItem } from './../pages/Lists/templates/types';
import { TableItem } from './../pages/Lists/Tables/types';

export const emptyTable: TableItem = {
	id: '',
	name: {
		en: '',
		de: '',
		srb: '',
	},
	note: {
		en: '',
		de: '',
		srb: '',
	},
	sport: {
		id: '',
		name: '',
	},
	label: {
		en: '',
		de: '',
		srb: '',
	},
	markets: [],
	hasResultsColumn: false,
};

export const emptyTemplate: TemplateItem = {
	id: '',
	name: {
		en: '',
		de: '',
		srb: '',
	},
	sport: {
		id: '',
		name: '',
	},
	tables: [],
};
