import React, { FC, KeyboardEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { trim } from 'lodash';
import { useNotify } from '../../../../../../hooks/useNotifications';
import Modal from '../../../../../../components/Modals';
import Dropdown from '../../../../../../components/general/Dropdown';
import { Template } from '../../../../../online-support/templates/types';
import { TemplateParams } from '../../../../../../api/online-support/types';
import { useOnlineUser } from '../../../../context/OnlineUserContext';
import getErrorText from '../../../../../../utils/getErrorMsg';
import api from '../../../../../../api';
import { Loader, useLoaded } from '../../../../../../components/general/Loader';

export interface Props {
	notificationsToResend?: TemplateParams;
	updateNotifications: () => void;
	closeModal: () => void;
}

export const NotificationModal: FC<Props> = ({ notificationsToResend, updateNotifications, closeModal }) => {
	const { t } = useTranslation();
	const { success, warn } = useNotify();
	const { onlineUser } = useOnlineUser();
	const [templates, setTemplates] = useState<Template[]>([]);
	const [systemVisibilityTime, setSystemVisibilityTime] = useState<number>();
	const [systemVisibilityTimeLoaded, setSystemVisibilityTimeLoaded] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const loaded = useLoaded([templates, systemVisibilityTimeLoaded]);

	useEffect(() => {
		(async function getTemplates() {
			try {
				const data = await api.notificationTemplates.getTemplates();
				setTemplates(data?.listNotificationTemplates);
			} catch (err) {
				warn(getErrorText(err) || 'toast.error.defaultText');
			}
		})();
	}, []);

	useEffect(() => {
		if (!onlineUser.uid) return;
		(async function getUserRiskParams() {
			try {
				const data = await api.geo.getEntityParametersForUser(onlineUser.uid, 'ONLINE', 'INBOX_MESSAGE');
				if (data.entity_params.NOTIFICATIONS_TIME_VISIBILITY !== null) {
					setSystemVisibilityTime(data.entity_params.NOTIFICATIONS_TIME_VISIBILITY);
				} else if (data.inherited_params.NOTIFICATIONS_TIME_VISIBILITY !== null) {
					setSystemVisibilityTime(data.inherited_params.NOTIFICATIONS_TIME_VISIBILITY);
				}
				setSystemVisibilityTimeLoaded(true);
			} catch (err) {
				warn(getErrorText(err) || 'toast.error.defaultText');
			}
		})();
	}, [onlineUser]);

	const { register, handleSubmit, errors, reset } = useForm<TemplateParams>({
		defaultValues: notificationsToResend,
	});

	const [seletedTemplate, setSelectedTemplate] = useState<Template>();
	const selectItemTemplate = (seletedItemId: string) => {
		const template = templates.find(({ id }) => id && id === parseInt(seletedItemId));
		if (template) {
			setSelectedTemplate(template);
			reset({
				title: template.title,
				content: template.content,
				visibilityTime: template.visibilityTime || systemVisibilityTime,
			});
		}
	};

	const onKeyDownForm: KeyboardEventHandler<HTMLFormElement> = (event) => {
		const element = event.target as HTMLElement;
		if (event.key === 'Enter' && element.tagName !== 'TEXTAREA') {
			event.preventDefault();
		}
	};

	useEffect(() => {
		const values = Object.values(errors);
		if (values.length) {
			values.forEach(({ message }) => {
				message && warn(message);
			});
		}
	}, [errors]);

	const creatNotification = async (data: TemplateParams) => {
		try {
			setIsDisabled(true);
			const notification = await api.notifications.sendUserNotification(onlineUser.id, {
				...data,
				visibilityTime: data.visibilityTime || systemVisibilityTime,
			});
			success(t('toast.success.creationSuccessful', { entity: t('userNotifications.singleName') }));
			updateNotifications();
			window.requestAnimationFrame(() => closeModal());
		} catch (err) {
			warn(getErrorText(err) || 'toast.error.defaultText');
		} finally {
			setIsDisabled(false);
		}
	};

	const titleFieldWhitespace = t('toast.warn.whitespaceProblem', { entity: t('userNotifications.filedTitle') });
	const contentFieldWhitespace = t('toast.warn.whitespaceProblem', { entity: t('userNotifications.fieldContent') });
	const visibilityTimeFieldMustBeInteger = t('toast.warn.mustBeInteger', {
		entity: t('userNotifications.fieldVisibilityTime'),
	});

	return (
		<Modal
			size={'md'}
			align={'start'}
			direction={'column'}
			opened={true}
			sectionClass={'admin_tour '}
			zIndex={'over_1'}
			cancelAction={closeModal}
		>
			<Loader loaded={loaded}>
				<form
					className="modal_content fadeInDown animated faster"
					onSubmit={handleSubmit(creatNotification)}
					onKeyDown={onKeyDownForm}
				>
					<div className="modal_content fadeInDown animated faster">
						<div className="modal_head">
							<div className={`tab_nav padding_left_20 padding_right_20 `}>
								<h2 className="modal_title padding_left_25">
									{notificationsToResend
										? t('userNotifications.modalResendTitle')
										: t('userNotifications.modalSendTitle')}
								</h2>
							</div>
						</div>
						<div
							data-testid="modal-body"
							className="modal_body direction_column content_start padding_left_20 padding_right_20"
						>
							{!notificationsToResend && (
								<div className="row padding_bottom_20">
									<div className="col">
										<Dropdown
											items={templates.map(({ id, title }) => ({ id: `${id}`, name: title || '' }))}
											selectedItem={(seletedTemplate && `${seletedTemplate.id}`) || ''}
											selectItem={selectItemTemplate}
											margins={['0', '0', '0', '0']}
											icon={'mdi mdi-menu-down'}
											fixedTitle={
												(seletedTemplate && `${seletedTemplate.title}`) ||
												t('userNotifications.filedTemplate')
											}
										/>
									</div>
								</div>
							)}
							<div className="row padding_bottom_20">
								<div className="col label">{t('userNotifications.filedTitle')}</div>
								<div className="col">
									<input
										type="text"
										name="title"
										className="normal right_input"
										autoComplete="off"
										ref={register({
											setValueAs: (value) => trim(value),
											required: {
												value: true,
												message: t('toast.warn.mandatoryField', {
													entity: t('userNotifications.filedTitle'),
												}),
											},
											validate: (value) => (!trim(value).length ? titleFieldWhitespace : undefined),
										})}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col label">{t('userNotifications.fieldContent')}</div>
							</div>
							<div className="row padding_bottom_20">
								<textarea
									className="resize_no"
									name="content"
									rows={6}
									autoComplete="off"
									ref={register({
										setValueAs: (value) => trim(value),
										required: {
											value: true,
											message: t('toast.warn.mandatoryField', {
												entity: t('userNotifications.fieldContent'),
											}),
										},
										validate: (value) => (!trim(value).length ? contentFieldWhitespace : undefined),
									})}
								></textarea>
							</div>
							<div className="row padding_bottom_20">
								<div className="col label">{t('userNotifications.fieldVisibilityTime')}</div>
								<div className="col">
									<input
										type="number"
										name="visibilityTime"
										className="normal right_input"
										autoComplete="off"
										step="any"
										ref={register({
											valueAsNumber: true,
											min: {
												value: 1,
												message: t('toast.warn.positiveVal', {
													entity: t('userNotifications.fieldVisibilityTime'),
												}),
											},
											validate: (value) =>
												!isNaN(value) && value % 1 !== 0 ? visibilityTimeFieldMustBeInteger : undefined,
										})}
									/>
								</div>
							</div>
						</div>
						<div data-testid="modal-footer" className="modal_footer col_border_top">
							<button type="button" className="btn md round outline" onClick={closeModal}>
								{t('general.cancel')}
							</button>
							<button type="submit" className={`btn md round green ${isDisabled ? 'disabled' : ''}`}>
								{t('general.save')}
							</button>
						</div>
					</div>
				</form>
			</Loader>
		</Modal>
	);
};
