import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelectDropdown from '../../../../../components/general/MultiSelectDropdown';

interface Props {
	providers: { id: string; name: string }[];
	selectedProviders: string[];
	selectProviders: (providers: string[]) => void;
}

export const ProvidersDropdown: FC<Props> = ({ selectedProviders, selectProviders, providers }) => {
	const { t } = useTranslation();

	const providerChangeHandler = (selectedProviderIds: string[]) => {
		let filteredData = [...selectedProviderIds];

		// current checked item id
		let checkedItem = selectedProviderIds.filter((provider) => !selectedProviders.includes(provider)).toString();
		if (checkedItem.length > 0) {
			let providerData = getProviderById(checkedItem);
			if (providerData != undefined) {
				if (!filteredData?.includes(providerData?.id)) filteredData.push(providerData?.id);

				if (!providerData?.name.includes(':')) {
					providers.forEach((provider) => {
						if (
							provider.name.includes(providerData?.name.toLocaleLowerCase() ?? '') &&
							!filteredData.includes(provider?.id)
						)
							filteredData.push(provider.id);
					});
				}
			}
			selectProviders(filteredData);
			return;
		}
		//current unchecked item id
		let uncheckedItem = selectedProviders.filter((provider) => !selectedProviderIds.includes(provider)).toString();
		if (uncheckedItem.length > 0) {
			let providerData = getProviderById(uncheckedItem);
			if (providerData != undefined) {
				if (!providerData?.name.includes(':')) {
					providers.forEach((provider) => {
						if (provider.name.includes(providerData?.name?.toLocaleLowerCase() ?? ''))
							filteredData = filteredData.filter((item) => item != provider?.id);
					});
				} else {
					filteredData = filteredData.filter((item) => item != providerData?.id);
				}
			}
			selectProviders(filteredData);
		}
	};
	const getProviderById = (providerId: string): { id: string; name: string } | undefined => {
		return providers.find(({ id }) => id === providerId?.toString());
	};

	return (
		<MultiSelectDropdown
			title={t('transactions.gameProvider')}
			marginRight={'10'}
			options={providers}
			selectedOptions={selectedProviders}
			selectOption={providerChangeHandler}
			withSearch
		/>
	);
};
