import React, { FC } from 'react';
import { ReferenceField } from './ReferenceField';
import { ReferenceSelect } from './ReferenceSelect';
import { ReferenceFieldAppend } from './ReferenceFieldAppend';
import { ReferenceMultiSelect } from './ReferenceMultiSelect';
import { ReferenceModalTrigger } from './ReferenceModalTrigger';

interface Props {
	locked: string;
	rowItem: { id: string; value: any; inherited: any };
	specifics: any;
}
export const ReferenceWrapper: FC<Props> = ({ locked, rowItem, specifics }) => {
	const { data_type, value_rule } = specifics;
	const component = () => {
		switch (data_type) {
			case 'str':
				return <ReferenceField locked={locked} rowItem={rowItem} />;
			case 'text':
				return value_rule && value_rule === 'APPEND' ? (
					<ReferenceFieldAppend locked={locked} rowItem={rowItem} />
				) : (
					<ReferenceField locked={locked} rowItem={rowItem} />
				);
			case 'select':
				return <ReferenceSelect locked={locked} rowItem={rowItem} specifics={specifics} maxWidth="400px" />;
			case 'multi_select':
				return <ReferenceMultiSelect locked={locked} rowItem={rowItem} specifics={specifics} />;
			case 'modal':
				return <ReferenceModalTrigger locked={locked} rowItem={rowItem} specifics={specifics} />;
			case 'multi_modal':
				return <ReferenceModalTrigger locked={locked} rowItem={rowItem} specifics={specifics} />;

			default:
				return null;
		}
	};
	return component();
};
