import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import { SimpleLoader } from '../../../../components/general/Loader';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import { useNotify } from '../../../../hooks/useNotifications';
import { useOnlineUser } from '../../context/OnlineUserContext';
import { SubHeader } from '../SubHeader';
import { CentralInfo } from './components/CentralInfo';
import { Reservation } from './components/Reservation';
import { Reservation as ReservationType, WalletData } from './types';

export const Wallets: FC = () => {
	const {
		onlineUser: { uid },
	} = useOnlineUser();

	const [walletData, setWalletData] = useState<WalletData | null>(null);
	const [loaded, setLoaded] = useState(false);

	const { t } = useTranslation();
	const { error } = useNotify();

	const [refresh, setRefresh] = useState(Date.now());
	const refetchWalletBalance = () => {
		setRefresh(Date.now());
	};

	useEffect(() => {
		(async () => {
			try {
				const data = await api.users.getWalletData(uid);
				setWalletData(data);
			} catch (err) {
				error(t('usersAdministrations.loadingError'), { autoClose: 5000 });
			} finally {
				setLoaded(true);
			}
		})();
	}, [refresh]);

	const updateReservation = (reservation: ReservationType | null) => {
		setWalletData({
			wallets: walletData?.wallets || [],
			reservation,
			lockedDeposits: walletData?.lockedDeposits || null,
		});
		refetchWalletBalance();
	};

	return (
		<div className="main_content w_1800" data-simplebar data-simplebar-auto-hide="false">
			<SubHeader />
			<SimpleLoader loaded={loaded}>
				<CentralInfo
					wallets={walletData?.wallets}
					lockedDeposits={walletData?.lockedDeposits || null}
					refetchWalletBalance={refetchWalletBalance}
				/>
				<Reservation reservation={walletData?.reservation || null} updateReservation={updateReservation} />
			</SimpleLoader>
		</div>
	);
};
