import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import { useNotify } from '../../../../../hooks/useNotifications';

interface Props {
	idLocation: string;
}
export const ForceLocationAuth: FC<Props> = ({ idLocation }) => {
	const { t } = useTranslation();
	const [forceAuth, setAuthForce] = useState<boolean>(false);
	const [forceAuthDisabled, setAuthForceDisabled] = useState<boolean>(false);
	const [forceLiveAuth, setLiveAuthForce] = useState<boolean>(false);
	const [forceLiveAuthDisabled, setLiveAuthForceDisabled] = useState<boolean>(false);
	const { error } = useNotify();

	useEffect(() => {
		if (!idLocation) return;
		(async function getUserRiskParams() {
			try {
				const data = await api.geo.getEntityParameters(idLocation, 'RISK', 'AUTOMATIC_RISK');
				if (data.entity_params.RISK_FORCE_AUTHORISATION !== null) {
					setAuthForce(data.entity_params.RISK_FORCE_AUTHORISATION);
				} else if (data.inherited_params.RISK_FORCE_AUTHORISATION !== null) {
					setAuthForce(data.inherited_params.RISK_FORCE_AUTHORISATION);
				}
				if (data.entity_params.RISK_FORCE_LIVE_AUTHORISATION !== null) {
					setLiveAuthForce(data.entity_params.RISK_FORCE_LIVE_AUTHORISATION);
				} else if (data.inherited_params.RISK_FORCE_LIVE_AUTHORISATION !== null) {
					setLiveAuthForce(data.inherited_params.RISK_FORCE_LIVE_AUTHORISATION);
				}
			} catch (err) {
				console.error(err);
			}
		})();
	}, [idLocation]);

	const toggleAuth = async () => {
		try {
			setAuthForceDisabled(true);
			await api.geo.updateGeoParameters(idLocation, {
				group: 'RISK',
				subgroup: 'AUTOMATIC_RISK',
				parameters: { RISK_FORCE_AUTHORISATION: !forceAuth },
			});
			setAuthForce(!forceAuth);
		} catch (err) {
			error('toast.error.locationAutomaticRiskGenericError');
		} finally {
			setAuthForceDisabled(false);
		}
	};

	const toggleLiveAuth = async () => {
		try {
			setLiveAuthForceDisabled(true);
			await api.geo.updateGeoParameters(idLocation, {
				group: 'RISK',
				subgroup: 'AUTOMATIC_RISK',
				parameters: { RISK_FORCE_LIVE_AUTHORISATION: !forceLiveAuth },
			});
			setLiveAuthForce(!forceLiveAuth);
		} catch (err) {
			error('toast.error.userAutomaticRiskGenericError');
		} finally {
			setLiveAuthForceDisabled(false);
		}
	};

	return (
		<>
			<div className={`ticket_info_item ${forceAuthDisabled ? 'disabled' : ''}`}>
				<div className="auto_open margin_right_30">
					<div className="checkbox_custom tiny_label dark">
						<input type="checkbox" id="forceAuth" checked={forceAuth} onClick={toggleAuth} onChange={() => {}} />
						<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
						<i className="mdi mdi-checkbox-marked checked"></i>
						<label htmlFor="forceAuth" className="right ">
							{t('general.forceLocationAuth')}
						</label>
					</div>
				</div>
			</div>
			<div className={`ticket_info_item ${forceLiveAuthDisabled ? 'disabled' : ''}`}>
				<div className="auto_open margin_right_30">
					<div className="checkbox_custom tiny_label dark">
						<input
							type="checkbox"
							id="forceLiveAuth"
							checked={forceLiveAuth}
							onClick={toggleLiveAuth}
							onChange={() => {}}
						/>
						<i className="mdi mdi-checkbox-blank-outline unchecked"></i>
						<i className="mdi mdi-checkbox-marked checked"></i>
						<label htmlFor="forceLiveAuth" className="right ">
							{t('general.forceLiveLocationAuth')}
						</label>
					</div>
				</div>
			</div>
		</>
	);
};
