import translationEN from './en/translation.json';
import translationSR from './sr/translation.json';

export default {
	en: {
		translation: translationEN,
	},
	sr: {
		translation: translationSR,
	},
};
