import React, { FC, useEffect, useMemo, useState } from 'react';
import { AccordionToggler } from '../../components/';
import useToggler from '../../../../hooks/useToggler';
import { usePairing, timeSort } from '../../_framework';
import { ProviderItem } from './ProviderItem';
import { PairedItem } from './PairedItem';
import { LocalItem } from './LocalItem';
import api from '../../../../api';
import { Item } from '../../types';
import { SimpleLoader } from '../../../../components/general/Loader';
import { ConfirmModal } from './Modal/ConfirmModal';
import Fuse from 'fuse.js';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

interface Props {
	booked: boolean;
	setDisabled: (booked: boolean) => void;
	live?: boolean;
	startDate?: Date | null;
	endDate?: Date | null;
	selectedTournament?: string;
}

export const PairingLayout: FC<Props> = ({
	booked,
	setDisabled,
	live = true,
	startDate = null,
	endDate = null,
	selectedTournament,
}) => {
	const [accordionExpanded, toggleAccordion, closeAccordion] = useToggler(true);
	const [scrollToIndex, setIndex] = useState(0);
	const [scrollToLocal, setLocalIndex] = useState(0);
	const pairing = usePairing();
	const {
		sport,
		provider,
		update,
		setLocalItem,
		localItem,
		localFuzzy,
		setLocalFuzzy,
		providerFuzzy,
		setProviderFuzzy,
		refreshed,
	} = pairing;
	const { local, setLocal, provided, setProvided, paired, setPaired, staged, setStaged } = pairing;

	const [localLoading, setLocalLoading] = useState(false);
	const [localError, setLocalError] = useState('');

	const [providedLoading, setProvidedLoading] = useState(false);
	const [providedError, setProvidedError] = useState('');

	const [confirmItem, setConfirmItem] = useState<Item | null>(null);
	const closeModal = () => setConfirmItem(null);

	const sportId = sport === 'all' ? '' : sport;

	const cache = React.useRef(
		new CellMeasurerCache({
			fixedWidth: true,
			defaultHeight: 100,
		})
	);
	const localCache = React.useRef(
		new CellMeasurerCache({
			fixedWidth: true,
			defaultHeight: 100,
		})
	);

	useEffect(() => {
		if (!paired.length) {
			closeAccordion();
		}
	}, [paired]);

	// Local data
	useEffect(() => {
		if (!sport || !provider) {
			setPaired([]);
			setLocal([]);
			return;
		}

		(async function initLocalEvents() {
			setLocalLoading(true);
			setLocalError('');
			setPaired([]);
			setLocal([]);
			setLocalIndex(0);
			setLocalFuzzy({} as Item);

			try {
				let startDate = new Date();
				startDate.setHours(startDate.getHours() - 3);

				const { localData } = await api.mappings.events.getEvents({
					sportId,
					providerId: provider,
					live,
					utcDatetimeStart: startDate,
				});

				const pairedData = localData.filter(({ pairedWith }: Item) => pairedWith.length);
				const localDataFiltered = localData.filter(({ pairedWith }: Item) => !pairedWith.length);
				setPaired(() => timeSort(pairedData));
				setLocal(() => timeSort(localDataFiltered));
			} catch (err) {
				setLocalError(`${err}`);
				setPaired([]);
				setLocal([]);
			} finally {
				setLocalLoading(false);
			}
		})();
	}, [update, refreshed]);

	// Provider data
	useEffect(() => {
		if (!sport || !provider) {
			setProvided([]);
			return;
		}

		if ((!live && !startDate) || (!live && !endDate) || (!live && startDate! > endDate!)) return;

		(async function initProviderEvents() {
			setProvidedError('');
			setProvidedLoading(true);
			setProvided([]);
			setStaged([]);
			setIndex(0);
			setProviderFuzzy({} as Item);

			let _startDate = new Date();
			_startDate.setHours(_startDate.getHours() - 3);

			let _endDate = new Date();
			_endDate.setHours(_endDate.getHours() + 26);

			let _startDatePrematch = startDate ? new Date(startDate.setHours(0, 0, 0, 0)).toISOString() : null;

			const todayYear = new Date().getFullYear();
			const todayMonth = new Date().getMonth();
			const todayDay = new Date().getDate();

			const selectedYear = startDate?.getFullYear();
			const selectedMonth = startDate?.getMonth();
			const selectedDay = startDate?.getDate();

			if (todayDay === selectedDay && todayMonth === selectedMonth && todayYear === selectedYear) {
				_startDatePrematch = startDate ? new Date().toISOString() : null;
			}

			let _endDatePrematch = endDate ? new Date(endDate.setHours(23, 59, 59, 999)).toISOString() : null;

			const utc_datetime_start = live ? _startDate : _startDatePrematch;
			const utc_datetime_end = live ? _endDate : _endDatePrematch;

			try {
				setDisabled(true);
				const { events = [] } = await api.mappings.events.getProviderEvents(provider, {
					id_sport: sportId,
					booked,
					utc_datetime_start,
					utc_datetime_end,
				});
				const provided = events.map((event: any) => {
					const [{ name: home }, { name: away }] = event.competitors || [{}, {}];

					const name = `${home} - ${away}`;
					return { ...event, name };
				});
				setProvided(() => timeSort(provided, 'utcScheduled'));
			} catch (err: any) {
				setProvidedError(`${err}`);
				setProvided([]);
			} finally {
				setProvidedLoading(false);
				setDisabled(false);
			}
		})();
	}, [update, booked, refreshed, startDate, endDate]);

	// Add provided data to paired item for unpair purposes
	useEffect(() => {
		// Reset selected local item
		if (!local.find((item) => item.id === localItem.id)) {
			setLocalItem({} as Item);
		}
		setPaired((paired: Item[]) => {
			return paired.map(({ pairedWith, ...item }) => {
				const updatedPW = pairedWith.map((item: any) => {
					const providedItem = provided.find(({ id }: any) => item.id === id);
					return providedItem || item;
				});

				return { ...item, pairedWith: updatedPW };
			});
		});
	}, [local]);

	const fuseOptions = {
		includeScore: true,
		isCaseSensitive: false,
		threshold: 0.5,
		distance: 99999999,
		includeMatches: true,
		minMatchCharLength: 4,
		keys: ['name'],
	};

	const filteredProvided = useMemo(() => {
		if (Object.keys(localFuzzy).length) {
			const fuse = new Fuse(provided, fuseOptions);
			const results = fuse.search(localFuzzy.name);
			return results.map((res) => res.item).filter(({ mapped, sport }) => !mapped && sport.mapped);
		}
		return provided.filter(({ mapped, sport }) => !mapped && sport.mapped);
	}, [provided, localFuzzy]);

	const filteredLocal = useMemo(() => {
		if (Object.keys(providerFuzzy).length) {
			const fuse = new Fuse(local, fuseOptions);
			const { name } = providerFuzzy;
			return fuse.search(name).map((res) => res.item);
		}
		return local;
	}, [providerFuzzy, local]);

	// Set lists of selected item to that item only so we avoid scroll back to the top to see opposite list
	const localList = Object.keys(localFuzzy).length ? [localItem] : filteredLocal;

	let providerList = Object.keys(providerFuzzy).length ? [providerFuzzy] : filteredProvided;

	if (!live && selectedTournament && selectedTournament !== 'all') {
		providerList = providerList.filter((item) => item.tournament.id === selectedTournament);
	}

	const stagedCss = !accordionExpanded ? { maxHeight: '220px', marginBottom: '20px', overflowY: 'auto' } : ({} as any);

	return (
		<div className="main_content">
			<div className="scroll_container">
				<div className="scroll_content" style={{ height: '100%', overflowY: 'auto' }}>
					<div className="channel_connection no_header">
						{/* Divider - accordion toggler */}
						{!accordionExpanded || paired.length > 15 ? (
							<AccordionToggler expanded={accordionExpanded} toggle={toggleAccordion} />
						) : null}

						{/* Filter connected list based on the searchValue and render it */}
						<div className={accordionExpanded ? 'list_wrapper' : 'list_wrapper toggle'}>
							{paired.map((item, i) => (
								<PairedItem key={`${item.id}-${i}`} {...item} provider={provider} isLive={live} />
							))}
						</div>

						{/* Divider - accordion toggler */}
						{accordionExpanded ? <AccordionToggler expanded={true} toggle={closeAccordion} /> : null}

						{/* Render confirmation list */}
						<div id="staged-list" className="list_wrapper" style={stagedCss}>
							{staged.map((item, i) => (
								<PairedItem
									key={`${item.id}-${i}`}
									{...item}
									isStaged
									setConfirmItem={setConfirmItem}
									provider={provider}
									isLive={live}
								/>
							))}
						</div>

						{/* Unpaired lists */}
						{!accordionExpanded ? (
							<div className="list_wrapper_not_connected">
								<SimpleLoader loaded={!localLoading && !providedLoading} error={localError || providedError}>
									{/* Filter local list based on the searchValue and render it */}
									<div className="local_list_wrapper">
										<AutoSizer>
											{({ width, height }) => (
												<List
													width={width}
													height={height}
													className="hide_scroll"
													rowHeight={localCache.current.rowHeight}
													deferredMeasurementCache={localCache.current}
													rowCount={localList.length}
													scrollToIndex={scrollToLocal}
													rowRenderer={({ key, index, style, parent }) => {
														const localItem = localList[index];

														return (
															<CellMeasurer
																key={key}
																cache={localCache.current}
																parent={parent}
																columnIndex={0}
																rowIndex={index}
															>
																<LocalItem
																	item={localItem}
																	style={style}
																	index={index}
																	setIndex={setLocalIndex}
																	provider={provider}
																/>
															</CellMeasurer>
														);
													}}
												/>
											)}
										</AutoSizer>
									</div>

									{/* Vertical divider */}
									<div className="divider" />

									{/* Filter provider list based on the searchValue and render it */}
									<div className="provider_list_wrapper">
										<AutoSizer>
											{({ width, height }) => (
												<List
													width={width}
													height={height}
													className="hide_scroll"
													rowHeight={cache.current.rowHeight}
													deferredMeasurementCache={cache.current}
													rowCount={providerList.length}
													scrollToIndex={scrollToIndex}
													rowRenderer={({ key, index, style, parent }) => {
														const providerItem = providerList[index];

														return (
															<CellMeasurer
																key={key}
																cache={cache.current}
																parent={parent}
																columnIndex={0}
																rowIndex={index}
															>
																<ProviderItem
																	item={providerItem}
																	style={style}
																	index={index}
																	setIndex={setIndex}
																/>
															</CellMeasurer>
														);
													}}
												/>
											)}
										</AutoSizer>
									</div>
								</SimpleLoader>
							</div>
						) : null}
						{confirmItem && <ConfirmModal confirmItem={confirmItem} closeModal={closeModal} isLive={live} />}
					</div>
				</div>
			</div>
		</div>
	);
};
