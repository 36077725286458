import React, { useEffect, FunctionComponentElement, ReactNode, FC, useState } from 'react';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { useLayout } from './LayoutContext';
import PanelToggler from './PanelToggler';
import RightPanel from './RightPanel';

// Local interface for the component
interface Props {
	title?: string;
	panelToggled?: boolean;
	leftTool?: FunctionComponentElement<ReactNode>;
	leftToolClass?: string;
	rightTool?: FunctionComponentElement<ReactNode>;
	headTools?: FunctionComponentElement<ReactNode>;
	customTools?: FunctionComponentElement<ReactNode>;
	panel?: boolean;
	children?: ReactNode;
	zIndex?: string;
	hideLogo?: boolean;
	allowMobile?: boolean;
	flexDirection?: 'flex-start' | 'flex-end' | 'space-between';
	widthClass?: string;
	hidden?: boolean;
	disabled?: boolean;
}

const Header: FC<Props> = ({
	title,
	panel,
	leftTool,
	rightTool,
	headTools,
	children,
	leftToolClass,
	zIndex = 'z_10',
	hideLogo = false,
	allowMobile,
	flexDirection,
	customTools,
	widthClass,
	hidden,
	disabled,
}) => {
	const { panelToggled } = useLayout();

	// Set header class based on the state of the right panel
	const headerClass = panel && panelToggled ? 'main_head right_pannel_toggle' : 'main_head';

	// Set the title of the tab to provided title
	useEffect(() => {
		document.title = title ? title : 'Alea Betting Platform';
	}, []);

	const isMobileView = useCheckMobileScreen();

	const [showWebHeader, setShowWebHeader] = useState(true);

	useEffect(() => {
		if (allowMobile && isMobileView) setShowWebHeader(false);
		else setShowWebHeader(true);
	}, [isMobileView]);

	const hiddenClass = hidden ? 'hidden_content' : '';
	const disabledClass = disabled ? 'disabled' : '';
	const hiddenLogoClass = hideLogo ? 'hidden_logo' : '';

	return (
		<>
			<div data-testid="header" className={`${headerClass} ${zIndex} ${widthClass} ${hiddenClass} ${disabledClass}`}>
				{!hideLogo ? (
					<a className="logo">
						<img src="/assets/img/alea_logo_sign.png" alt="alea logo" />
					</a>
				) : null}
				{title && showWebHeader && <h1 className={`title_section ${hiddenLogoClass}`}>{title}</h1>}
				<div className="action_tools_left">
					<div className={`wrapper ${leftToolClass}`}>{showWebHeader ? leftTool && leftTool : null}</div>
				</div>

				<div className="action_tools_right">
					<div className="wrapper no_border">{showWebHeader ? rightTool && rightTool : null}</div>
				</div>

				<div className="head_tools">
					<div className="wrapper no_border">
						{showWebHeader ? (
							<>
								{headTools && headTools}
								{children && children}
							</>
						) : (
							<PanelToggler />
						)}
					</div>
				</div>
			</div>
			{!showWebHeader ? (
				<RightPanel title={'MENU'} flexDirection={flexDirection}>
					<div className="mobile_border_center">
						{headTools && headTools}
						{children && children}
					</div>
					{leftTool ? <div className="mobile_border_center">{leftTool}</div> : null}
					{rightTool ? <div className="mobile_border_center">{rightTool}</div> : null}
					{customTools ? <div className="mobile_border_center">{customTools}</div> : null}
				</RightPanel>
			) : null}
		</>
	);
};

export default Header;
