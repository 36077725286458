import React, { FC } from 'react';
import SimpleBar from 'simplebar-react';
import { ChangeHistory } from '../../types';
import { TimelineItem } from './components/TimelineItem';

interface Props {
	changeHistory?: ChangeHistory;
}

export const ActionsTimeline: FC<Props> = ({ changeHistory = [] }) => {
	return (
		<div className="shrink_1280 padding_20 margin_top_40 col_border_top">
			<SimpleBar className="timeline max_height_300">
				{changeHistory?.map((changeItem) => (
					<TimelineItem key={changeItem?.id} changeItem={changeItem} />
				))}
				<div className="empty_div"></div>
			</SimpleBar>
		</div>
	);
};
