import React, { useState, Dispatch, SetStateAction, KeyboardEvent } from 'react';
import Modal from '../../Modals';
import { Tab } from './helpers';
import api from '../../../api/index';
import { EventItemExtended } from './types';
import { ModalSize } from '../../Modals/types';
import { useTranslation } from 'react-i18next';
import { useAxios } from '../../../hooks/useAxios';
import { Navigation } from './components/Navigation';
import { useModels } from '../../../hooks/useModels';
import { DescriptionForm, Params } from './components';
import { FormProvider, useForm } from 'react-hook-form';
import TournamentInfo from './components/TournamentInfo';
import CompetitorSwitch from './components/CompetitorSwitch';
import { SimpleLoader as Loader } from '../../general/Loader';

interface Props {
	eventId: string;
	editEventId: Dispatch<SetStateAction<string>>;
	updateEvent: (event: EventItemExtended) => void;
}

export default ({ editEventId, eventId, updateEvent }: Props) => {
	const { t } = useTranslation();
	const { mapExtended } = useModels();

	const [tab, setTab] = useState<Tab>('general');

	const [modalSize, setModalSize] = useState<ModalSize>('md');
	const [direction, setDirection] = useState('column');

	const { data: event, loading } = useAxios<EventItemExtended>({
		apiFunction: async function getEvent() {
			const data = await api.events.getSingleEvent(eventId);
			return { ...data, description: mapExtended(data.description) };
		},
		initialData: {} as EventItemExtended,
		dependencies: [editEventId],
	});

	const methods = useForm<EventItemExtended>({
		defaultValues: { ...event },
		shouldUnregister: false,
	});

	const onSubmitForm = (data: EventItemExtended) => {
		updateEvent({
			...data,
			autoRiskValue: data.autoRiskValue ? +data.autoRiskValue : null,
			liveBettingDelay: data.liveBettingDelay ? +data.liveBettingDelay : null,
		});
	};

	function onKeyDown(event: KeyboardEvent<HTMLFormElement>) {
		//@ts-ignore
		if ((event.charCode || event.keyCode) === 13 && event?.target?.type !== 'textarea') {
			event.preventDefault();
		}
	}

	// Tab switcher
	const component = () => {
		switch (tab) {
			case 'params':
				return <Params setModalSize={setModalSize} />;

			default:
				return (
					<>
						<div className="row direction_column">
							<TournamentInfo event={event} />
							<CompetitorSwitch competitors={event.competitors} />
							<div className="option_list_item subtitle_wrapper margin_left_5" style={{ height: '32px' }}>
								<div>{`${t('general.listCode')} :`}</div>
								<span className="subtitle">{event.listCode || '-'}</span>
							</div>
							<div className="option_list_item subtitle_wrapper margin_left_5" style={{ height: '32px' }}>
								<div>{`${t('integrations.provider')} id:`}</div>
								<span className="subtitle">{event.idExternal || '-'}</span>
							</div>

							<DescriptionForm />
						</div>
					</>
				);
		}
	};

	return (
		<Modal
			opened={true}
			size={modalSize}
			align={'start'}
			direction={direction}
			sectionClass={'admin_tour'}
			cancelAction={() => editEventId('')}
		>
			<Loader loaded={!loading}>
				{Object.keys(event).length ? (
					<FormProvider {...methods}>
						<form
							className="modal_content fadeInDown animated faster"
							onSubmit={methods.handleSubmit(onSubmitForm)}
							onKeyDown={onKeyDown}
						>
							<div className="modal_head">
								<div className={`tab_nav padding_left_20 padding_right_20 `}>
									<Navigation
										tab={tab}
										setTab={setTab}
										setModalSize={setModalSize}
										setDirection={setDirection}
									/>
								</div>
							</div>
							<div data-testid="modal-body" className={`modal_body content_start direction_${direction}`}>
								{component()}
							</div>

							<div data-testid="modal-footer" className={`modal_footer col_border_top`}>
								<button className="btn md round outline toggle_modal_12" onClick={() => editEventId('')}>
									{t('general.cancel')}
								</button>
								<button className={`btn md round green toggle_modal_12 `} onClick={() => onSubmitForm}>
									{t('general.save')}
								</button>
							</div>
						</form>
					</FormProvider>
				) : null}
			</Loader>
		</Modal>
	);
};
