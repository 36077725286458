import { FormikValues } from 'formik';
import { PlayerProps, PlayerPropsResult, Scores } from '../../pages/playerPropsResults/types';
import { Market, ResultMain, Results } from '../../pages/results/types';
import { axiosInstance, Response } from './../config';

interface Params {
	offerId: string | null;
	sportId?: string;
	tournamentId?: string;
	status?: string | string[];
	liveStatus?: string;
	orderBy?: string;
	q?: string;
	skip?: number;
	limit?: number;
	datetimeTo: Date | string | null;
	datetimeFrom: Date | string | null;
	results: boolean | null;
}

interface UpdateMarketStatusParams {
	splitTypes: boolean;
	oddIds: string[];
	status: string;
}

interface UpdateResultsProps {
	result: ResultMain;
	status: string;
	utcScheduled: string;
}

interface ResultOddProps {
	id: string;
	value: number | null;
	specialValue: number;
	result: number;
	idEvent: string;
}
export interface UpdateMultipleMarketsBody {
	id_event: string;
	live: boolean;
	odd_results: OddResult[];
}

interface OddResult {
	id: string;
	value: number | null;
	result: number;
	oddtypeStatus?: number;
	manualStatus?: string | null;
}

export const results = {
	getResults({
		offerId,
		sportId = '',
		tournamentId = '',
		status = '',
		liveStatus = '',
		orderBy = 'listCode',
		q = '',
		skip = 0,
		limit = 1000,
		datetimeTo,
		datetimeFrom,
		results,
	}: Params): Response {
		return axiosInstance
			.get(`/administration/result/result_filter/`, {
				params: {
					offer_plan_id: offerId,
					sportId,
					tournamentId,
					status,
					liveStatus,
					order_by: orderBy,
					q,
					skip,
					limit,
					datetimeTo,
					datetimeFrom,
					results,
				},
			})
			.then((res) => res.data);
	},

	getEventResults(eventId: string, offerPlanId: string): Promise<Results> {
		return axiosInstance
			.get(`/administration/result/${eventId}${offerPlanId ? `?idOfferPlan=${offerPlanId}` : ''}`)
			.then((res) => res.data);
	},

	updateEventResults(eventId: string, body: UpdateResultsProps) {
		return axiosInstance.put(`/administration/result/${eventId}`, body);
	},

	resultOdd(planId: string, body: ResultOddProps) {
		return axiosInstance
			.post(`/administration/result/update_odd_result?idOfferPlan=${planId}`, body)
			.then((res) => res.data);
	},

	resultMultipleEvents(body: UpdateMultipleMarketsBody[]) {
		return axiosInstance.post('/administration/result/multiple_event_results', body).then((res) => res.data);
	},

	updateAllMarkets(eventId: string, status: string) {
		return axiosInstance.post(`/administration/result/markets/${eventId}?status=${status}`).then((res) => res.data);
	},

	updateMarketStatus(eventId: string, params: UpdateMarketStatusParams) {
		return axiosInstance.post(`/administration/result/market/${eventId}`, null, { params }).then((res) => res.data);
	},

	resultEvent(eventId: string, values: FormikValues) {
		return axiosInstance.put(`/administration/result/${eventId}`, { result: values }).then((res) => {
			return res.data;
		});
	},

	changeEventStatus(eventId: string, status: string) {
		return axiosInstance.post(`/administration/events/change_status/${eventId}/`, { status }).then((res) => res.data);
	},
	changeEventPeriod(eventId: string, status: string) {
		return axiosInstance.post(`/administration/events/period/${eventId}/`, { status }).then((res) => res.data);
	},

	getPlayerPropsResults({
		idSpecialSport,
		idTournament,
		idParentEvent,
		listCode,
		idOfferPlan,
		dtFrom,
		dtTo,
		orderBy,
	}: {
		idSpecialSport: string;
		idTournament: string;
		idParentEvent: string;
		listCode: string;
		idOfferPlan: string;
		dtFrom: Date | string;
		dtTo: Date | string;
		orderBy?: string;
	}): Promise<PlayerProps[]> {
		const path = `/administration/result/player_props/results?idSpecialSport=${idSpecialSport}&idTournament=${idTournament}&idParentEvent=${idParentEvent}&listCode=${listCode}&idOfferPlan=${idOfferPlan}&dtFrom=${dtFrom}&dtTo=${dtTo}`;
		const pathByOrder = orderBy ? path.concat(`&orderBy=${orderBy}`) : path;
		return axiosInstance.get(pathByOrder).then((res) => res.data);
	},

	resultPlayerProps(payload: PlayerPropsResult[]): Promise<[{ markets: Market; scores: Scores }]> {
		return axiosInstance.post('/administration/result/player_props/multi_result', payload).then((res) => res.data);
	},
};
