import { isEmpty } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import Button from '../../../../components/general/Button';
import { Loader } from '../../../../components/general/Loader';
import Dropdown from '../../../../components/general/Dropdown';
import NotificationModal from '../../../../components/general/NotificationModal';
import { Header, PageContentWrapper } from '../../../../components/layout';
import { useNotify } from '../../../../hooks/useNotifications';
import { openInNewTab } from '../../../../utils/newTab';
import { DailyExportTemplate, TimeAndDailyExportPreview } from '../types';
import { DailyOfferModal } from './Modal';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import getErrorText from '../../../../utils/getErrorMsg';

interface Props {
	time?: boolean;
}

export const DailyOfferExport: FC<Props> = ({ time = false }) => {
	const { t } = useTranslation();
	const [loaded, setLoaded] = useState(true);

	const { warn, error } = useNotify();

	const [dailyExportTemplates, setDailyExportTemplates] = useState<DailyExportTemplate[]>([]);
	const [printTemplates, setPrintTemplates] = useState<{ id: number; name: string }[]>([]);

	const [modalOpened, setModalOpened] = useState(false);
	const [selectedForEdit, setSelectedForEdit] = useState<DailyExportTemplate | null>(null);

	useEffect(() => {
		(async () => {
			try {
				const dailyTemplates = await (time
					? api.timeExport.getTimeTemplates() //load time offer export templates
					: api.dailyExport.getDailyTemplates()); //load daily offer export templates
				setDailyExportTemplates(dailyTemplates);
				const printTemplates = await api.print.getPrintTemplates();
				setPrintTemplates(printTemplates.map(({ id, name }) => ({ id, name })));
			} catch (err) {
				error(getErrorText(err) || 'toast.error.defaultText');
			} finally {
				setLoaded(true);
			}
		})();
	}, []);

	const openModal = () => {
		setModalOpened(true);
	};

	const closeModal = (template: DailyExportTemplate | null) => {
		if (template && !isEmpty(template)) {
			if (dailyExportTemplates.find(({ id }) => id === template?.id)) {
				setDailyExportTemplates((templates) =>
					templates.map((element) => (element?.id === template?.id ? template : element))
				);
			} else setDailyExportTemplates((templates) => [...templates, template]);
		}
		setModalOpened(false);
		setSelectedForEdit(null);
	};

	const updateDailyExportTemplates = (newTemplates: DailyExportTemplate[]) => {
		if (newTemplates && !isEmpty(newTemplates)) {
			setDailyExportTemplates(newTemplates);
		}
	};

	const removeDailyExportTemplate = (templateId: number) => {
		if (typeof templateId === 'number') {
			setDailyExportTemplates((templates) => templates.filter(({ id }) => id !== templateId));
		}
	};

	const selectForEdit = (template: DailyExportTemplate) => {
		setSelectedForEdit(template);
		setModalOpened(true);
	};
	const [exportAs, setExportAs] = useState('excel');
	const [exportPreviewData, setExportPreviewData] = useState<TimeAndDailyExportPreview[] | null>(null);
	const [exporting, setExporting] = useState(false);

	const getExportPreviewData = async () => {
		try {
			setExporting(true);
			const asPdf = exportAs === 'pdf';
			const exportData = await (time
				? api.dailyExport.getTimeExportPreviewLinks(asPdf)
				: api.dailyExport.getDailyExportPreviewLinks(asPdf));
			const data = Object.values(exportData) as TimeAndDailyExportPreview[];
			setExportPreviewData(data);
		} catch (err) {
			warn(getErrorText(err) || 'toast.error.defaultText');
		} finally {
			setExporting(false);
		}
	};
	return (
		<Loader loaded={loaded}>
			<Header
				title={time ? t('listBuilder.export.timeOffer') : t('listBuilder.export.dailyOffer')}
				leftTool={
					<Button
						onClick={openModal}
						name={t('general.add')}
						className={'md outline light_blue margin_right_15'}
					/>
				}
			>
				<Dropdown
					items={[
						{ id: 'excel', name: 'Excel' },
						{ id: 'pdf', name: 'Pdf' },
					]}
					selectedItem={exportAs}
					selectItem={setExportAs}
					margins={['0', '20', '0', '40']}
					icon="mdi mdi-menu-down"
					fixedTitle={t('listBuilder.export.exportAs')}
				/>
				<button className="btn lg blue" onClick={getExportPreviewData} disabled={exporting}>
					{exporting ? <i className="mdi mdi-rotate-right mdi-spin" /> : t('general.exportPreview').toUpperCase()}
				</button>
			</Header>
			<PageContentWrapper>
				<table className="admin_table sticky_header full_w">
					<TableHeader />
					<tbody className="t_body">
						{!isEmpty(dailyExportTemplates) &&
							dailyExportTemplates?.map((dailyExportTemplate, index) => (
								<TableRow
									key={`${dailyExportTemplate?.id}${index}`}
									dailyExportTemplate={dailyExportTemplate}
									printTemplates={printTemplates}
									updateDailyExportTemplates={updateDailyExportTemplates}
									removeDailyExportTemplate={removeDailyExportTemplate}
									selectForEdit={selectForEdit}
									time={time}
								/>
							))}
					</tbody>
				</table>
			</PageContentWrapper>
			{modalOpened ? <DailyOfferModal close={closeModal} dailyExportTemplate={selectedForEdit} time={time} /> : null}
			{exportPreviewData ? (
				<NotificationModal
					opened={true}
					cancelAction={() => {
						setExportPreviewData(null);
					}}
					size="sm_360"
				>
					<h2 className="text_center margin_top_50" style={{ lineHeight: 1.1 }}>
						{t('general.exportLinks')}
					</h2>
					<div className="align_center direction_column margin_top_50 padding_left_10 padding_right_10">
						{exportPreviewData.map((item) => {
							const { link, name, id } = item;
							return (
								<Button
									key={id}
									name={name}
									className="sm blue margin_bottom_15"
									onClick={() => openInNewTab(link)}
								/>
							);
						})}
					</div>
				</NotificationModal>
			) : null}
		</Loader>
	);
};
