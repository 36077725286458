import React from 'react';

// Local interface for the component
interface Props {
	name: string;
	className?: string;
	onClick: () => void;
	onBlur?: () => void;
	type?: 'button' | 'submit' | 'reset';
	isDisabled?: boolean;
}
/**
 * Button component can recive this classes:
 
 * .lg .md (size of buttons)
 * .round  (round edges)
 * .outline (just border without fill)
 
 * and list of colors:
   .transparent
   .light
   .red
   .green
   .orange
   .blue
   .light_blue
 */
export default ({ name, className, onClick, onBlur, type = 'submit', isDisabled = false }: Props) => {
	return (
		<button
			type={type}
			onBlur={onBlur}
			data-testid="button"
			className={`btn ${className} ${isDisabled ? 'disabled' : ''}`}
			onClick={onClick}
		>
			{name}
		</button>
	);
};
