import { CategoryItem } from '../categories/types';
import { Sport } from '../sports/types';
import FormData from 'form-data';
import { NewTournamentItem } from '../tournaments/types';

// Type of data function accepts
type Data = CategoryItem | Sport | NewTournamentItem;

/**
 * Creates multipart structure from object
 * @param { Data } object
 * @returns { FormData }
 */
export default (data: Data) => {
	// Create new form data and copy the data object
	const form = new FormData();
	const object = {
		...data,
		icons: {
			...data.icons
		}
	};

	// Extract images from object, add them to form data
	// and set their values on the object to the empty string
	Object.entries(object.icons).forEach(([ key, value ]) => {
		if (value && typeof value === 'object') {
			form.append(key, value);
			object.icons[key] = '';
		}
	});

	// Append JSON representation of the data to the form data
	form.append('data', JSON.stringify(object));

	// Return created form data
	return form;
};
