import { axiosInstance } from '../config';
import { Ticket } from '../../pages/reports/tickets-report/types';
import { fileNameFromHeaders } from '../../utils/getExportFileName';

export const ticketsReport = {
	getTicketsReport(params: Ticket) {
		return axiosInstance.post(`/reporting/tickets`, params).then((res) => res.data);
	},

	getTicketsExport(params: Ticket): Promise<any> {
		return axiosInstance.post(`/reporting/tickets_export`, params).then((res) => res.data);
	},
};
