import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	onClick: () => void;
	styles: CSSProperties;
}

export const FillEmpty: FC<Props> = ({ styles, onClick }) => {
	const { t } = useTranslation();
	return (
		<button
			type="button"
			title={t('general.fillEmptyDesc')}
			className="btn sm round blue margin_top_10"
			onClick={onClick}
			style={styles}
		>
			{t('general.fillEmpty')}
		</button>
	);
};
