import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../utils/getDate';
import { useFormContext } from 'react-hook-form';

export const CompetitorForm: FC = () => {
	const { t } = useTranslation();

	const { register, getValues } = useFormContext();

	const { id, timeCreation, timeModify } = getValues();

	return (
		<div className="col-7 mid_col direction_column padding_left_20 padding_right_20 padding_top_20 col_border_right">
			{id && (
				<div className="row margin_bottom_10">
					<div className="column text-right">
						<div className="label">{t('general.id')}</div>
					</div>
					<div className="column">
						<span>{id}</span>
					</div>
				</div>
			)}

			<div className="row margin_bottom_20">
				<div className="column text-right">
					<div className="label">{t('competitorsAdministration.stadium')}</div>
				</div>
				<div className="column">
					<input ref={register} className="normal right_input" name="venue" type="text" />
				</div>
			</div>
			<div className="row margin_bottom_20">
				<div className="column text-right">
					<div className="label">{t('competitorsAdministration.state')}</div>
				</div>
				<div className="column">
					<input ref={register} className="normal right_input" name="country" type="text" />
				</div>
			</div>
			<div className="row margin_bottom_20">
				<div className="column text-right">
					<div className="label">{t('competitorsAdministration.city')}</div>
				</div>
				<div className="column">
					<input ref={register} className="normal right_input" name="city" type="text" />
				</div>
			</div>

			<div className="row margin_bottom_20 double_row">
				<div className="column text-right">
					<div className="label"> {t('tournamentsAdministration.creationTime')}</div>
				</div>
				<div className="column">
					<span>{formatDate(timeCreation, 'date.month.year')}</span>
					<span>{formatDate(timeCreation, 'hours:minutes')}</span>
				</div>
			</div>
			<div className="row margin_bottom_20 double_row">
				<div className="column text-right">
					<div className="label"> {t('tournamentsAdministration.timeOfEditing')}</div>
				</div>
				<div className="column">
					<span>{formatDate(timeModify, 'date.month.year')}</span>
					<span>{formatDate(timeModify, 'hours:minutes')}</span>
				</div>
			</div>
		</div>
	);
};
