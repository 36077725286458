import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	checked: boolean;
	toggle: (checked: boolean) => void;
	name: string;
	onLabel: string;
	offLabel: string;
	className?: string;
	isDisabled?: boolean;
}

export const Switch: FC<Props> = ({ checked, toggle, name, onLabel, offLabel, className, isDisabled }) => {
	const { t } = useTranslation();

	const handleCheckbox = async (e: ChangeEvent<HTMLInputElement>) => {
		toggle(e.target.checked);
	};

	return (
		<label className={`switch on_off ${className} ${isDisabled ? 'disabled' : ''}`}>
			<input className="switch_mark" type="checkbox" checked={checked} onChange={handleCheckbox} />
			<span className="slider"></span>
			<span className="checkbox_label">
				<span className="on_label green_02_text font_700 tooltip_right">
					{t(`${name}`)}
					<span className="tooltip_content font_400 green">{t(`${onLabel}`)}</span>
				</span>

				<span className="off_label redish_text font_700 tooltip_right">
					{t(`${name}`)}
					<span className="tooltip_content font_400 redish">{t(`${offLabel}`)}</span>
				</span>
			</span>
		</label>
	);
};
