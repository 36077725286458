import React, { useState, useEffect, FC } from 'react';
import { SportFilter as SF, Dropdown } from '../../components';
import { Search as SearchComponent, useSearch } from '../../../../components/Search';
import { usePairing } from '../../_framework';
import api from '../../../../api';

// Import types
import { Sport } from '../../../administration/sports/types';
import { Provider } from '../../types';
import { useTranslation } from 'react-i18next';
import getErrorText from '../../../../utils/getErrorMsg';
import { useNotify } from '../../../../hooks/useNotifications';
/**
 * SportFilter
 */
interface SportProps {
	initialSport?: string;
}

export const SportFilter: FC<SportProps> = ({ initialSport = 'all' }) => {
	const [sports, setSports] = useState<Sport[]>([]);
	const { sport, setSport, provider, setUpdate } = usePairing();
	const { error } = useNotify();

	const sportFilterSet = (sportId: string) => {
		setSport(sportId);
		setUpdate(Date.now());
	};

	useEffect(() => {
		(async function initSportAndProviders() {
			if (provider === '') return;
			try {
				const sportData = await api.sports.getList('REGULAR', true, provider);

				setSports([
					{
						id: 'all',
						defaultName: 'All',
					},
					...sportData,
				]);
				if (sport !== 'all' && !sportData.some((element) => element.id === sport)) setSport(initialSport);
			} catch (err) {
				error(getErrorText(err) || 'toast.error.defaultText');
			} finally {
				setUpdate(Date.now());
			}
		})();
	}, [provider]);

	return <SF sports={sports} selectedSport={sport} selectSport={sportFilterSet} direction="left" margin="right_15" />;
};

/**
 * Providers
 */

interface ProviderProps {
	prematch?: boolean;
}

export const Providers: FC<ProviderProps> = ({ prematch = false }) => {
	const [providers, setProviders] = useState<Provider[]>([]);
	const { provider, setProvider } = usePairing();
	const { error } = useNotify();

	useEffect(() => {
		(async function initSportAndProviders() {
			try {
				const providerData = (await api.integrations.getProviders()) || [];

				if (!prematch) {
					const liveProviders = providerData.filter(({ liveData }: any) => liveData);
					const providers = liveProviders.map(({ id, name }: any) => ({ id, name }));
					const { id: firstProvider } = providers[1] || {};

					setProviders(providers);
					setProvider(firstProvider);
				} else {
					setProviders(providerData.filter(({ id }: Provider) => id !== 'EXELIVE'));
					setProvider('EXE_SETTLED');
				}
			} catch (err) {
				error(getErrorText(err) || 'toast.error.defaultText');
			}
		})();
	}, []);

	return (
		<Dropdown
			items={providers}
			selectedItem={provider}
			selectItem={setProvider}
			margins={['0', 'auto', '0', '0']}
			icon={'mdi mdi-menu-down'}
		/>
	);
};

/**
 * Booked checkbox
 */
interface Props {
	booked: boolean;
	setBooked: (booked: boolean) => void;
	disabled?: boolean;
}
export const Booked: FC<Props> = ({ booked, setBooked, disabled }) => {
	const { t } = useTranslation();
	return (
		<label className={`switch on_off margin_right_10 ${disabled ? 'disabled' : ''}`}>
			<input
				className="switch_mark"
				type="checkbox"
				id="show_booked_matches"
				checked={booked}
				onChange={({ target }) => setBooked(target.checked)}
			/>

			<span className="checkbox_label">
				<span className="on_label green_02_text font_700 tooltip_left">
					{t('general.booked')}

					<span className="tooltip_content font_400">{t('general.showAllMatches')}</span>
				</span>
				<span className="off_label redish_text font_700 tooltip_left">
					{t('general.all')}
					<span className="tooltip_content font_400">{t('general.showBooked')}</span>
				</span>
			</span>
			<span className="slider"></span>
		</label>
	);
};

/**
 * Search
 */
export const Search = () => {
	const { t } = useTranslation();
	const { setSearchValue } = usePairing();
	const searchOptions = useSearch(setSearchValue);

	return <SearchComponent message={t('offersPlan.3characters')} {...searchOptions} />;
};
/**
 * Refresh
 */
export const Refresh = () => {
	const { t } = useTranslation();
	const { refresh } = usePairing();

	return (
		<span className="tool_item green_text_hover green_bgish_hover" onClick={refresh}>
			<i className="mdi mdi-refresh tooltip_left">
				<span className="tooltip_content">{t('general.refresh')}</span>
			</i>
		</span>
	);
};
