import React, { FC } from 'react';
interface Props {
	label: string;
	info: string;
}

export const InfoRow: FC<Props> = ({ label, info }) => {
	return (
		<div className="info_row">
			<div className="label">{label}</div>
			<div className="info">{info}</div>
		</div>
	);
};
