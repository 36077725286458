import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

interface Props {
	startDate: Date | null;
	endDate: Date | null;
	minDate?: Date | null;
	maxDate?: Date | null;
	setStartDate: (date: Date | null) => void;
	setEndDate: (date: Date | null) => void;
	className?: string;
	datePickerClassName?: string;
}

export const DateRange: FC<Props> = ({
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	minDate,
	maxDate,
	className,
	datePickerClassName = 'width_90',
}) => {
	const isMobileView = useCheckMobileScreen();

	return (
		<div className={`input_form_group timeframe w_radio_select margin_right_10 ${className}`}>
			<DatePicker
				dateFormat="dd.MM.yy"
				placeholderText="dd.MM.yy"
				selected={startDate}
				selectsStart
				startDate={startDate}
				isClearable
				endDate={endDate}
				minDate={minDate}
				maxDate={maxDate || endDate}
				onChange={(date) => setStartDate(date)}
				showMonthDropdown
				showYearDropdown
				dropdownMode="select"
				className={datePickerClassName}
				withPortal={isMobileView ? true : false}
			/>

			<span className="separator vert_line">
				<span className="line"></span>
			</span>
			<DatePicker
				dateFormat="dd.MM.yy"
				placeholderText="dd.MM.yy"
				selected={endDate}
				selectsEnd
				isClearable
				startDate={startDate}
				endDate={endDate}
				onChange={(date) => setEndDate(date)}
				maxDate={maxDate}
				minDate={minDate}
				showMonthDropdown
				showYearDropdown
				dropdownMode="select"
				className={datePickerClassName}
				withPortal={isMobileView ? true : false}
			/>
		</div>
	);
};
