import React, { ChangeEvent, FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import api from "../../../../../api"
import { MarketItem } from "../../../../../types/MarketsAdministration";

interface Props {
	data: any;
	setData: any;
	sport: any;
}

type SelectedMarket = {
	market: string;
	group: number;
}

export const Markets: FC<Props> = ({ data, setData, sport }) => {
	const { i18n } = useTranslation();
	const { language: ln } = i18n;

	const [markets, setMarkets] = useState<MarketItem[]>([])
	const [selectedMarkets, setSelectedMarkets] = useState<SelectedMarket[]>(data.find(m => m.sport === sport).markets)

	const updateGroup = (id: string, event: ChangeEvent<HTMLInputElement>) => {
		const group = Number(event.target.value)
		setSelectedMarkets(selectedMarkets => [...selectedMarkets.map(selected => selected.market !== id ? selected : { ...selected, group })])
	}

	const selectMarket = (id: string) => {
		setSelectedMarkets(selectedMarkets => [...selectedMarkets, { market: id, group: 0 }])
	}

	const removeMarket = (id: string) => {
		setSelectedMarkets(selectedMarkets => [...selectedMarkets.filter(selected => selected.market !== id)])
	}

	const moveUpMarket = (id: string) => {
		setSelectedMarkets(selectedMarkets => {
			const index = selectedMarkets.findIndex(m => m.market === id);
			if (index === 0) return [...selectedMarkets];

			const copy = [...selectedMarkets];
			[copy[index], copy[index - 1]] = [copy[index - 1], copy[index]]

			return copy;
		})
	}

	const moveDownMarket = (id: string) => {
		setSelectedMarkets(selectedMarkets => {
			const index = selectedMarkets.findIndex(m => m.market === id);
			if (index === selectedMarkets.length - 1) return [...selectedMarkets];

			const copy = [...selectedMarkets];
			[copy[index], copy[index + 1]] = [copy[index + 1], copy[index]]

			return copy;
		})
	}

	useEffect(() => {
		async function fetchData() {
			const response = await api.markets.getMarkets({ sportId: sport, isLive: true })
			setMarkets(response.markets)
		}
		fetchData()
	}, [sport])

	useEffect(() => {
		data.find(m => m.sport === sport).markets = selectedMarkets;
		setData(data)
	}, [selectedMarkets, sport])

	return (
		<div className="col-6">
			<div className="flex_direction_row">
				<div className="col-6">
					<table className="admin_table full_w">
						<thead className="t_head">
							<tr>
								<th colSpan={2}
								>
									Market
								</th>
							</tr>
						</thead>
						<tbody className="t_body">
							{markets.filter(market => !selectedMarkets.find(m => market.id === m.market)).map((market) => (
								<tr key={market.id}>
									<td>{market.localisation[ln]['betMedia']}</td>
									<td className="actions_cell" width={50}>
										<div className="td_actions">
											<div className="tbl_btn green">
												<i onClick={() => { selectMarket(market.id) }} className="mdi mdi-chevron-right" />
											</div>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="col-6">
					<table className="admin_table full_w">
						<thead className="t_head">
							<tr>
								<th
									colSpan={5}
								>
									Market
								</th>
							</tr>
						</thead>
						<tbody className="t_body">
							{selectedMarkets.map(id => markets.find(market => market.id === id.market)).filter(market => market).map((market, i) => (
								<tr key={market.id}>
									<td className="actions_cell" width={50}>
										<div className="td_actions">
											<div className="tbl_btn green">
												<i onClick={() => { removeMarket(market.id) }} className="mdi mdi-chevron-left" />
											</div>
										</div>
									</td>
									<td>{market.localisation[ln]['betMedia']}</td>
									<td className="actions_cell" width={20}>
										<div className="td_actions">
											<input type="number" defaultValue={selectedMarkets.find(m => m.market === market.id).group} onChange={(e) => { updateGroup(market.id, e) }} className="short tiny" min={0} required />
										</div>
									</td>
									<td className="actions_cell" width={50}>
										<div className="td_actions">
											{i > 0 && (
												<div className="tbl_btn green">
													<i onClick={() => { moveUpMarket(market.id) }} className="mdi mdi-chevron-up" />
												</div>
											)}
										</div>
									</td>
									<td className="actions_cell" width={50}>
										<div className="td_actions">
											{i < selectedMarkets.length - 1 && (
												<div className="tbl_btn green">
													<i onClick={() => { moveDownMarket(market.id) }} className="mdi mdi-chevron-down" />
												</div>
											)}
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}