import { Provider, SelectedProvider } from './../../pages/system/integrations/types';
import { axiosInstance, Response } from './../config';
import { id } from '../../types/general';
import { ProviderData } from './../../pages/prematch/controller/types';
import { ProviderEvent } from '../../pages/prematch/controller/components/ProviderFlags/ProviderFlagModal';

export const integrations = {
	getProviders(): Response {
		return axiosInstance.get('/administration/integrations/').then((res) => res.data);
	},
	createProvider(provider: SelectedProvider): Promise<Provider> {
		return axiosInstance.post(`/administration/integrations/create`, provider).then((res) => {
			return res.data;
		});
	},
	updateProvider(provider: SelectedProvider): Promise<Provider> {
		return axiosInstance.put(`/administration/integrations/edit/${provider.id}`, provider).then((res) => {
			return res.data;
		});
	},
	removeProvider(providerKey: id): Promise<Provider> {
		return axiosInstance.delete(`/administration/integrations/delete/${providerKey}`).then((res) => {
			return res.data;
		});
	},
	refreshProviderData(providerKey: id) {
		return axiosInstance.post(`/administration/integrations/refresh-data/${providerKey}`).then((res) => {
			return res.status;
		});
	},

	getProviderEvents({
		providerId,
		utcScheduled,
		home,
		away,
	}: {
		providerId: string;
		utcScheduled: string;
		home: string;
		away: string;
	}): Promise<{ events: ProviderEvent[] }> {
		return axiosInstance
			.get(
				`/administration/integrations/extended/events/fuzzy/${providerId}?utcScheduled=${utcScheduled}&home=${home}&away=${away}`
			)
			.then((res) => res.data);
	},

	updateProviderFlagsData(idEvent: string, payload: ProviderData): Promise<{ providerData: ProviderData }> {
		return axiosInstance
			.post(`/administration/events/event_provider_data`, { providerData: payload, idEvent })
			.then((res) => {
				return res.data;
			});
	},

	getLiveFlags(): Promise<{ liveFlags: { ['key']: string } }> {
		return axiosInstance.get('/administration/integrations/flags').then((res) => res.data);
	},
};
