import { UpdateTag } from '.';
import { Category } from './../../pages/risk-management/categories/types';
import { axiosInstance } from './../config';

export const categories = {
	getRiskCategories() {
		return axiosInstance(`/risk/categories`).then((res) => res.data);
	},

	updateCategory(category: Category) {
		return axiosInstance
			.put(`/risk/categories/${category.id}`, {
				...category,
			})
			.then((res) => {
				return res.data;
			});
	},
	createCategory(category: Category) {
		return axiosInstance
			.post(`/risk/categories`, {
				...category,
			})
			.then((res) => {
				return res.data;
			});
	},
	deleteCategory(id: number | string) {
		return axiosInstance.delete(`/risk/categories/${id}`).then((res) => {
			return res.data;
		});
	},
	forceAuth(user_uuid: string) {
		return axiosInstance.post(`/risk/force_auth/${user_uuid}`).then((response) => response.data);
	},
	forceLiveAuth(user_uuid: string) {
		return axiosInstance.post(`/risk/force_live_auth/${user_uuid}`).then((response) => response.data);
	},
	riskTag(tag: UpdateTag) {
		return axiosInstance
			.post(`/risk/user_risk_tag/`, {
				...tag,
			})
			.then((res) => {
				return res.data;
			});
	},
};
