import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import { SelectedEvent } from '../types';
import { useTranslation } from 'react-i18next';
import { ModalSize } from '../../../Modals/types';
import { CombinedEvents } from './CombinedEvents';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNotify } from '../../../../hooks/useNotifications';
import { RHFCheckbox } from '../../../../pages/administration/components/RHFCheckbox';
import { SelectedTournament } from '../../../../pages/administration/tournaments/types';
import { CombinedTournaments } from '../../../../pages/administration/tournaments/components/Modal/Components/CombinedTournaments';
import { useParamProps } from '../../../Modals/ParametersModal/useParamProps';
import { ParametersModal } from '../../../Modals/ParametersModal';
import { ParameterRow } from '../../../../pages/administration/components/ParameterRow';

interface Props {
	setModalSize: (size: ModalSize) => void;
}

export const Params: FC<Props> = ({ setModalSize }) => {
	const { t } = useTranslation();
	const [col, setCol] = useState(12);
	const [combineTournaments, setCombineTournaments] = useState(false);
	const [combineEvents, setCombineEvents] = useState(false);

	const { register, errors, control, getValues } = useFormContext();

	const { handleOnClick, paramType, closeParametersModal, getGlobal, loading, setLoading, ...otherProps } =
		useParamProps();

	const param = 'tournamentsAdministration.params';

	const tournaments: SelectedTournament[] = getValues('blockCombinationTournaments');
	const events: SelectedEvent[] = getValues('blockCombinationEvents');

	const blockedTournaments = useWatch({
		control,
		name: 'blockCombinationTournaments',
		defaultValue: tournaments,
	});
	const blockedEvents = useWatch({
		control,
		name: 'blockCombinationEvents',
		defaultValue: events,
	});

	const handleCombineTournaments = (event: ChangeEvent<HTMLInputElement>) => {
		setCombineTournaments(event.target.checked);
		if (!combineEvents) {
			setCol(event.target.checked ? 7 : 12);
			setModalSize(event.target.checked ? 'xl' : 'lg');
		}
	};
	const handleCombineEvents = (event: ChangeEvent<HTMLInputElement>) => {
		setCombineEvents(event.target.checked);
		if (!combineTournaments) {
			setCol(event.target.checked ? 7 : 12);
			setModalSize(event.target.checked ? 'xl' : 'lg');
		}
	};

	const { warn } = useNotify();

	useEffect(() => {
		if (Object.keys(errors).length) {
			Object.keys(errors).forEach((error) => warn(t('toast.warn.positiveVal', { entity: error })));
		}
	}, [errors]);

	return (
		<>
			<div
				className={`col-${col} mid_col direction_column padding_left_100 padding_right_10 padding_top_20 col_border_right`}
			>
				<div className="row margin_bottom_20 ">
					<div className="column text-right">
						<div className="label"> {t(`${param}.conditions`)}</div>
					</div>
					<div className="column flex">
						<ParameterRow
							clickHandler={() => handleOnClick('conditions')}
							global={getGlobal('conditions')}
							loading={loading}
						/>
					</div>
				</div>
				<div className="row margin_bottom_20 ">
					<div className="column text-right">
						<div className="label"> {t(`${param}.limits`)}</div>
					</div>
					<div className="column flex">
						<ParameterRow
							clickHandler={() => handleOnClick('limits')}
							global={getGlobal('limits')}
							loading={loading}
						/>
					</div>
				</div>
				<div className="row margin_bottom_20 ">
					<div className="column text-right">
						<div className="label"> {t(`${param}.authParams`)}</div>
					</div>
					<div className="column flex">
						<ParameterRow
							clickHandler={() => handleOnClick('authorizations')}
							global={getGlobal('authorizations')}
							loading={loading}
						/>
					</div>
				</div>

				<div className="row margin_bottom_20">
					<div className="column text-right">
						<div className="label">{t(`authParams.riskValue`)}</div>
					</div>
					<div className="column flex">
						<input
							style={{ width: '25%' }}
							name="autoRiskValue"
							type="number"
							step="any"
							ref={register({
								min: 1,
							})}
						/>
					</div>
				</div>

				<div className="row margin_bottom_20">
					<div className="column text-right">
						<div className="label">{t('tournamentsAdministration.params.liveBettingDelay')}</div>
					</div>
					<div className="column flex">
						<input
							style={{ width: '25%' }}
							name="liveBettingDelay"
							type="number"
							step="any"
							ref={register({
								min: 0,
							})}
						/>
					</div>
				</div>

				<RHFCheckbox label={t(`prematch.controller.topOffer`)} name="topOffer" />
				<RHFCheckbox label={t(`${param}.blockInternet`)} name="blockedForInternet" />
				<RHFCheckbox label={t(`${param}.blockTerminal`)} name="blockedForTerminal" />
				<div className="row margin_bottom_20 double_row">
					<div className="column text-right">
						<div className="label">{`${t(`${param}.combineWithOtherTournaments`)} (${
							blockedTournaments.length
						})`}</div>
					</div>
					<div className="column flex">
						<div className="checkbox_custom chek_only tooltip_right">
							<input
								name="combine"
								type="checkbox"
								checked={combineTournaments}
								onChange={handleCombineTournaments}
							/>
							{!combineTournaments ? <i className="mdi mdi-checkbox-blank-outline unchecked" /> : null}
							{combineTournaments ? <i className="mdi mdi-checkbox-marked checked" /> : null}
						</div>
					</div>
				</div>
				<div className="row margin_bottom_20 double_row">
					<div className="column text-right">
						<div className="label">{`${t(`${param}.combineWithOtherEvents`)} (${blockedEvents.length})`}</div>
					</div>
					<div className="column flex">
						<div className="checkbox_custom chek_only tooltip_right">
							<input name="combine" type="checkbox" checked={combineEvents} onChange={handleCombineEvents} />
							{!combineEvents ? <i className="mdi mdi-checkbox-blank-outline unchecked" /> : null}
							{combineEvents ? <i className="mdi mdi-checkbox-marked checked" /> : null}
						</div>
					</div>
				</div>
			</div>
			{combineTournaments || combineEvents ? (
				<div
					className="col-5 mid_col direction_column padding_left_10 padding_right_10 padding_top_10 "
					style={{ overflowY: 'auto', overflowX: 'hidden' }}
				>
					{combineTournaments ? <CombinedTournaments /> : null}
					{combineEvents ? <CombinedEvents /> : null}
				</div>
			) : null}
			{!!paramType && <ParametersModal close={closeParametersModal} parameterType={paramType} {...otherProps} />}
		</>
	);
};
