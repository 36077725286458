import React, { FC, useState, KeyboardEvent, useEffect, useMemo } from 'react';
import SimpleBar from 'simplebar-react';
import { OddsTable } from './OddsTable';
import { MarketName } from './MarketName';
import { PlayerPropsScore, SpecialMarket } from '../../types';
import { Sport } from '../../../sports/types';
import { useTranslation } from 'react-i18next';
import { MarketOptions } from './MarketOptions';
import { SelectSport } from '../../../components';
import Modal from '../../../../../components/Modals';
import { ModalSize } from '../../../../../components/Modals/types';
import { useForm, FormProvider, SubmitHandler, useWatch } from 'react-hook-form';
import api from '../../../../../api';
import { SelectPlayerPropsScore } from './SelectPlayerPropsScore';
import { useNotify } from '../../../../../hooks/useNotifications';

interface Props {
	sports: Sport[];
	onClose: () => void;
	editing: SpecialMarket;
	onSubmit: (values: any) => void;
	disabledBtn?: boolean;
}

export const SpecialMarketModal: FC<Props> = ({ editing, onClose, onSubmit, sports, disabledBtn }) => {
	const { t, i18n } = useTranslation();
	const { language: ln } = i18n;
	const { warn } = useNotify();

	const [selectedMarket] = useState(editing);

	const [playerPropsScore, setPlayerPropsScore] = useState<PlayerPropsScore[] | null>(null);
	const [selectedPropsScores, setSelectedPropsScores] = useState<string[]>([]);

	const [modalSize] = useState<ModalSize>('md');

	const methods = useForm<SpecialMarket>({
		defaultValues: { ...selectedMarket },
		shouldUnregister: false,
	});

	const onSubmitForm = (data: SubmitHandler<SpecialMarket>) => {
		onSubmit({ ...data, idPlayerProps: selectedPropsScores.filter((id) => id !== 'all').map((id) => +id) });
	};

	function onKeyDown(event: KeyboardEvent<HTMLFormElement>) {
		//@ts-ignore
		if ((event.charCode || event.keyCode) === 13 && event?.target?.type !== 'textarea') {
			event.preventDefault();
		}
	}

	// Prevent form submit if required fields not populated
	const form = useWatch({
		control: methods.control,
		name: ['sport.id', 'localisation', 'oddtypes', 'playerProps'],
	});

	const isDisabled = !form['sport.id'].length || !form['localisation'][ln]['web'].length || !form.oddtypes.length;
	const disableClass = isDisabled ? 'disabled' : '';

	useEffect(() => {
		const sport = sports.find(({ id }) => id === form['sport.id']);
		if (!sport) return;
		(async function getPlayerPropsScore() {
			try {
				const { playerPropsScores: data } = await api.markets.getPlayerPropsScores(sport.idParent!);
				setPlayerPropsScore(data.map((item) => ({ ...item, id: `${item.id}` })));
				if (editing.id) setSelectedPropsScores(editing?.idPlayerProps || []);
				else setSelectedPropsScores([]);
			} catch (err: any) {
				warn(err?.response?.data?.detail || 'toast.error.defaultText');
				setPlayerPropsScore(null);
				setSelectedPropsScores([]);
			}
		})();
	}, [form['sport.id']]);

	const selectedScores = useMemo(() => {
		if (!playerPropsScore) return [];
		const scores: PlayerPropsScore[] = [];
		selectedPropsScores.forEach((score) => {
			const prop = playerPropsScore.find((prop) => prop.id === `${score}`);
			if (prop) scores.push(prop);
		});
		return scores;
	}, [selectedPropsScores, playerPropsScore]);

	return (
		<>
			{Object.keys(selectedMarket).length ? (
				<Modal size={modalSize} align={'start'} direction={'column'} opened={true}>
					<FormProvider {...methods}>
						<form
							className="modal_content fadeInDown animated faster"
							onSubmit={methods.handleSubmit(onSubmitForm)}
							onKeyDown={onKeyDown}
						>
							<div data-testid="modal-body" className="modal_body content_start direction_row">
								<SimpleBar
									className="col-12 mid_col direction_column  padding_right_0 padding_top_10 "
									style={{ overflowY: 'auto', overflowX: 'hidden' }}
								>
									<h2 className="modal_title margin_top_10  padding_left_25">
										{editing.id ? t('marketsAdministrations.edit') : t('marketsAdministrations.add')}
									</h2>
									<div className="row margin_bottom_10 margin_top_20  padding_left_20">
										<SelectSport sports={sports} />
									</div>
									{playerPropsScore && form['playerProps'] ? (
										<div className="margin_bottom_0 margin_top_0  padding_left_30 align_center">
											<div className="align_center  width_90_perc"></div>
											<SelectPlayerPropsScore
												playerPropsScore={playerPropsScore}
												selectedPropsScores={selectedPropsScores}
												selectPropsScores={setSelectedPropsScores}
											/>
										</div>
									) : null}
									<MarketOptions />
									<MarketName />
									<div className="row margin_bottom_20 margin_top_20 ">
										<OddsTable canAddNewTypes selectedScores={selectedScores} />
									</div>
								</SimpleBar>
							</div>

							<div data-testid="modal-footer" className={`modal_footer col_border_top`}>
								<button className="btn md round outline" onClick={() => onClose()}>
									{t('general.cancel')}
								</button>

								<button
									type="submit"
									className={`btn md round green ${disableClass} ${disabledBtn ? 'disabled' : ''}`}
									disabled={isDisabled}
								>
									{t('general.save')}
								</button>
							</div>
						</form>
					</FormProvider>
				</Modal>
			) : null}
		</>
	);
};
