import React from 'react';
import { render } from 'react-dom';
import AppRouter from './router/index';
import ability from './casl/ability';
import './localisation/i18n';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './context/UserProvider';
import { SocketProvider } from './context/SocketProvider';
import { DataProvider } from './context/DataProvider';
import { ToastContainer } from 'react-toastify';
import { AbilityContext } from './casl/AbilityContext';
import 'simplebar/src/simplebar.css';

const App = () => (
	<AbilityContext.Provider value={ability}>
		<UserProvider>
			<DataProvider>
				<SocketProvider>
					<AppRouter />
					<ToastContainer />
				</SocketProvider>
			</DataProvider>
		</UserProvider>
	</AbilityContext.Provider>
);

const root = document.getElementById('root');
render(<App />, root);
