import React, { useEffect, FC, Dispatch, SetStateAction } from 'react';
import InPortal from '../../../../components/Modals/InPortal';
import { useTranslation } from 'react-i18next';
import { OddTypeItem } from '../../../../types/markets';

interface Props {
	prematchMax: string;
	setPrematchMax: Dispatch<SetStateAction<string>>;
	prematchMaxSingle: string;
	setPrematchMaxSingle: Dispatch<SetStateAction<string>>;
	liveMax: string;
	setLiveMax: Dispatch<SetStateAction<string>>;
	liveMaxSingle: string;
	setLiveMaxSingle: Dispatch<SetStateAction<string>>;
	cancelAction: () => void;
	confirmAction: () => void;
	selectedOddtypes: OddTypeItem[];
	referentCurrency: string;
}

export const CustomLimitModal: FC<Props> = ({
	prematchMax,
	setPrematchMax,
	prematchMaxSingle,
	setPrematchMaxSingle,
	liveMax,
	setLiveMax,
	liveMaxSingle,
	setLiveMaxSingle,
	cancelAction,
	confirmAction,
	selectedOddtypes,
	referentCurrency,
}) => {
	const { t } = useTranslation();

	const hasPrematch = selectedOddtypes.some((odddtype) => odddtype.id.startsWith('o'));
	const hasLive = selectedOddtypes.some((odddtype) => odddtype.id.startsWith('l'));

	useEffect(() => {
		const el = document.getElementById('confirm');
		el?.focus();
	}, []);

	const disabledButton =
		(hasPrematch && (!prematchMax || !prematchMaxSingle)) || (hasLive && (!liveMax || !liveMaxSingle))
			? 'disabled'
			: '';

	const handleInput = (event: React.ChangeEvent<HTMLInputElement>, action: Dispatch<SetStateAction<string>>) => {
		const value = event.target.value;
		if (+value < 0 || isNaN(+value)) return;
		// Prevent input field to 2 decimal places
		const [_, decimals] = value.split('.');
		if (decimals?.length > 2) return;
		action(value);
	};

	return (
		<InPortal>
			<div className="modal_cover s toggle">
				<div className="modal_content fadeInDown animated faster">
					{/* Header */}
					<button data-testid="close-btn" className="btn transparent close " onClick={cancelAction}>
						<i className="mdi mdi-close" />
					</button>

					{/* Body */}
					<div className="modal_body content_start direction_column padding_left_30 padding_right_30 padding_top_40">
						<div className="row margin_top_20 align_center">
							<span className="col-5">{t('limits.prematchMax')}</span>
							<input
								className={`col-7 normal`}
								value={prematchMax ? prematchMax : ''}
								onChange={(event) => handleInput(event, setPrematchMax)}
								disabled={!hasPrematch}
							/>
							<span className="align_center font_11px margin_left_10 padding_right_10">{referentCurrency}</span>
						</div>
						<div className="row margin_top_20 align_center">
							<span className="col-5">{t('limits.prematchMaxSingle')}</span>
							<input
								className={`col-7 normal`}
								value={prematchMaxSingle ? prematchMaxSingle : ''}
								onChange={(event) => handleInput(event, setPrematchMaxSingle)}
								disabled={!hasPrematch}
							/>
							<span className="align_center font_11px margin_left_10 padding_right_10">{referentCurrency}</span>
						</div>
						<div className="row margin_top_20 align_center">
							<span className="col-5">{t('limits.liveMax')}</span>
							<input
								className={`col-7 normal`}
								value={liveMax ? liveMax : ''}
								onChange={(event) => handleInput(event, setLiveMax)}
								disabled={!hasLive}
							/>
							<span className="align_center font_11px margin_left_10 padding_right_10">{referentCurrency}</span>
						</div>
						<div className="row margin_top_20 align_center">
							<span className="col-5">{t('limits.liveMaxSingle')}</span>
							<input
								className={`col-7 normal`}
								value={liveMaxSingle ? liveMaxSingle : ''}
								onChange={(event) => handleInput(event, setLiveMaxSingle)}
								disabled={!hasLive}
							/>
							<span className="align_center font_11px margin_left_10 padding_right_10">{referentCurrency}</span>
						</div>
					</div>

					{/* Footer */}
					<div data-testid="modal-footer" className="modal_footer">
						<button onClick={cancelAction} className={`btn lg round outline`}>
							{t('general.cancel')}
						</button>

						<button id="confirm" onClick={confirmAction} className={`btn lg round light_blue ${disabledButton}`}>
							{t('general.add')}
						</button>
					</div>
				</div>
			</div>
		</InPortal>
	);
};
