import { axiosInstance } from '../config';
import { Transaction } from '../../pages/reports/payment-transactions/types';

export const paymentTransactions = {
	getPaymentProviderTransactions(params: Transaction) {
		return axiosInstance.post('/reporting/payment_provider_transactions', params).then(({ data }) => data);
	},

	exportPaymentProviderTransactions(params: Transaction) {
		return axiosInstance.post('/reporting/payment_provider_transactions_export', params).then(({ data }) => data);
	},
};
