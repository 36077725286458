import React, { FC } from 'react';
import { Sport } from '../types';
import { sportsArray } from '../../../../consts/icons';
import { useTranslation } from 'react-i18next';

interface Props {
	sport: Sport;
	tooltip?: 'left' | 'right';
}
export const SportIcon: FC<Props> = ({ sport, tooltip }) => {
	const { i18n } = useTranslation();
	const { language: ln } = i18n;
	const { id, defaultName, special = false, idParent, icons, name } = sport;
	const iconId = special ? `${idParent}s` : id;
	const defaultIcon = icons?.web ? (
		<img src={icons.web} className="td_flag" />
	) : (
		<i className="mdi mdi-soccer-field" />
	);

	return (
		<div className={`svg_icon tooltip_${tooltip}`}>
			{sportsArray.includes(iconId!) ? (
				<svg className="e_ico">
					<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${iconId!}`} />
				</svg>
			) : (
				defaultIcon
			)}
			{tooltip ? <span className="tooltip_content">{name?.[ln] || defaultName || name}</span> : null}
		</div>
	);
};
