import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTicket } from '../../context/TicketContext';

export const CashoutRiskActions = () => {
	const { t } = useTranslation();
	const { authorize } = useTicket();

	return (
		<>
			<div className="padding_10 flex_direction_row flex_justify_end margin_left_auto">
				<button className={`btn lg red  padding_left_60 padding_right_60`} onClick={() => authorize('reject')}>
					{t('cashout.actions.reject')}
				</button>
			</div>
			<div className="padding_10 flex_direction_row flex_justify_end margin_left_auto">
				<button className={`btn lg green  padding_left_60 padding_right_60`} onClick={() => authorize('approve')}>
					{t('cashout.actions.approve')}
				</button>
			</div>
		</>
	);
};
